import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert'
import './index.js'
import Multiselect from '../Multiselect';
import AMLinked_sku_structure from '../AMLinked_sku_structure';

const HWKeyValuePair_relative= ({open, item_json, idx, single_item_json, set_item_json, item_json_condition, use_item_json_condition}) => {

    const alert = useAlert()
    const [selected_tag_1_array ,set_selected_tag_1_array ] =useState([])
    const [values_json ,set_values_json ] =useState([])
    
    const [selected_comp , set_selected_comp] = useState([]);
    const [selected_sub_comp,set_selected_sub_comp] =useState([])
    const [selected_property,set_selected_property] =useState([])

    useEffect(() => {
        if(single_item_json){
            console.log('single_item_json =======>',single_item_json)   
        }
	},[single_item_json])

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='relativecomponent'){
            set_selected_comp(value)
            set_selected_sub_comp([])
            set_selected_property('')
            update_item_json(idx,'relativesubcomponent',[]) 
            update_item_json(idx,'relativeproperty', [])
        }
        if(operator==='relativesubcomponent'){
            set_selected_sub_comp(value)
            set_selected_property([])
            update_item_json(idx,'relativeproperty',[])    
        }
        if(operator==='condition_type'){
            if(value==='relative'){
                update_item_json(idx,'relativecomponent',item_json_condition[0].component) 
                update_item_json(idx,'relativesubcomponent', item_json_condition[0].subcomponent)
                update_item_json(idx,'relativeproperty',[{id:'sku',name:'SKU'}])
            }else{
                update_item_json(idx,'relativecomponent',[]) 
                update_item_json(idx,'relativesubcomponent', [])
                update_item_json(idx,'relativeproperty',[])
            }
        }
    }

    // useEffect(() =>{
	// 	if(item_json){
    //         if(position_type==='condition'){
	// 			console.log('item_json_condition======>',item_json)
	// 		}else if(position_type==='action'){
	// 			console.log('item_json_action======>',item_json)
	// 		}else{
	// 			console.log('item_json_applicable======>',item_json)
	// 		}
    //         console.log('item_json_final======>',JSON.stringify(item_json))
	// 	}
	// },[item_json])

    // useEffect(() => {	
	// 	if(use_item_json_condition===false){
	// 		item_json.relativecomponent=[]
    //         item_json.relativesubcomponent=[]
    //         item_json.relativeproperty=[]
    //         item_json.condition_type='absolute'
	// 	}
	// },[use_item_json_condition])

    // useEffect(() => {	
	// 	if(use_item_json_condition===false){
	// 		update_item_json(idx,'condition_type','absolute') 
    //         update_item_json(idx,'relativecomponent',[]) 
    //         update_item_json(idx,'relativesubcomponent', [])
    //         update_item_json(idx,'relativeproperty',[])
	// 	}
	// },[use_item_json_condition])

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_comp([])
        set_selected_sub_comp([])
        set_selected_property('')
    }

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    const fn_check_null = (x) => {
        if(x===null){
            alert.error('value of '+selected_property?selected_property.name+' is no longer available.':'')
        }
        return x;
    }

    return(
        <Row style={{ margin:0, marginTop:'15px', width:'100%'}}>
                
                    <Col xs={2} style={{display:'flex', alignItems:"center"}}>
                    </Col>
                    <Col xs={2} style={{alignItems:"center"}}>
                        <div style={{marginBottom:'5px', fontSize:"12px"}}>Type</div>
                        <Input 
                            style={{fontSize:'12px'}}
                            // className={allow_edit?'':'opacity_div'} 
                            type="select" placeolder='0' 
                            id='hw_rule_linked_sku_action_type'
                            value={item_json && item_json[idx]?item_json[idx].condition_type:'absolute'} 
                            onChange={(e) =>onclick_item('condition_type',e.target.value)}
                        >
                            <option value='absolute'>Absolute</option>
                            <option value='relative'>Relative</option>
                        </Input>
                    </Col>
                
            {
                item_json && item_json[idx] && item_json[idx].condition_type ==='relative'?
                <React.Fragment>
                    {/* <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        {
                            component_options_json && component_options_json.length?
                                <Multiselect
                                    options={component_options_json}
                                    selectedValues={selected_comp}
                                    onSelect={(selectedList) => onclick_item('relativecomponent', selectedList)}
                                    onRemove={(selectedList) => onclick_item('relativecomponent', selectedList)}
                                    displayValue="name" // Property name 
                                    id={'select_comp'+idx}
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        {
                            selected_comp && selected_comp.length && selected_comp[0].sub_component && selected_comp[0].sub_component.length?
                                <Multiselect
                                    options={selected_comp[0].sub_component}
                                    selectedValues={selected_sub_comp}
                                    onSelect={(selectedList) => onclick_item('relativesubcomponent', selectedList)}
                                    onRemove={(selectedList) => onclick_item('relativesubcomponent', selectedList)}
                                    displayValue="name" // Property name 
                                    id={'select_'+idx}
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                        {
                            selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].properties &&  selected_sub_comp[0].properties.length ?
                                <Multiselect
                                    options={selected_sub_comp[0].properties? (selected_sub_comp && selected_sub_comp[0] && selected_sub_comp[0].id==='skirting'?selected_sub_comp[0].properties.filter(x=>x.id!='length'):selected_sub_comp[0].properties):[]} 
                                    selectedValues={selected_property}
                                    onSelect={(selectedList) => onclick_item('relativeproperty', selectedList)}
                                    onRemove={(selectedList) => onclick_item('relativeproperty', selectedList)}
                                    displayValue="name" // Property name 
                                    id={'select_'+idx}
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col> 
                    <Col xs={2} style={{alignItems:"center"}}>
                        <div style={{marginBottom:'5px', fontSize:"12px"}}>Index</div>
                        <Input 
                            style={{fontSize:'12px'}}
                            // className={allow_edit?'':'opacity_div'} 
                            type="select" placeolder='0' 
                            value={item_json && item_json[idx]?Number(item_json[idx].type):''} 
                            onChange={(e) =>update_item_json(idx,'type',Number(e.target.value))}
                        >
                            <option value='absolute'>Absolute</option>
                            <option value='dynamic'>Dynamic</option>
                        </Input>
                    </Col> */}
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        {
                            item_json && item_json[0] && item_json[0].relativecomponent && item_json[0].relativecomponent[0]?
                                <Input value={item_json[0].relativecomponent[0].name?item_json[0].relativecomponent[0].name:''} 
                                    disabled type="text"
                                    id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} 
                                /> 
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        {
                            item_json && item_json[0] && item_json[0].relativesubcomponent && item_json[0].relativesubcomponent[0]?
                                <Input value={item_json[0].relativesubcomponent[0].name?item_json[0].relativesubcomponent[0].name:''} 
                                    disabled type="text"
                                    id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} 
                                />   
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                        {
                            item_json && item_json[0] && item_json[0].relativeproperty && item_json[0].relativeproperty[0]?
                               <Input value={item_json[0].relativeproperty[0].name?item_json[0].relativeproperty[0].name:''} 
                                    disabled type="text"
                                    id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} 
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col> 
                    <Col xs={2} style={{alignItems:"center"}}>
                        <div style={{marginBottom:'5px', fontSize:"12px"}}>Index</div>
                        <Input 
                            style={{fontSize:'12px'}}
                            // className={allow_edit?'':'opacity_div'} 
                            type="select" placeolder='0' 
                            value={item_json && item_json[idx]?item_json[idx].index_value:''} 
                            onChange={(e) =>update_item_json(idx,'index_value',e.target.value)}
                        >
                            <option value='per_set'>Per Set</option>
                        </Input>
                    </Col>
                </React.Fragment>
                :''
            }
        </Row>
    )
}

export default HWKeyValuePair_relative;

