import React , { useState , useEffect }from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Card, CardHeader, CardBody, Input, CardFooter, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './header.css';
import general_fetch from '../../utils/fetch';
import { useAlert } from 'react-alert'
import UploadFile from "../../components/UploadFile";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DeleteNotification from "../DeleteNotification";
import useStateCallback from '../../utils/useStateCallback.js';
import Country_list from '../../utils/country';
import PricingTermsConditions from "../PricingTermsConditions";
import io from 'socket.io-client';
import EscapeWarning from '../../components/EscapeWarning';
import remove_underscore_wtih_first_cap from "../../utils/remove_underscore_wtih_first_cap";


const View_User_Profile = ({open, handleclose, user}) => {
	useEffect(() => {
		console.log("user", user)
	}, [user]);

	// let roles = [{id: 'designer', role: 'Designer'} , {id: 'accounts_manager', role: 'Account Manager'}, {id: 'catalog_manager', role: 'Catalog Manager'}, {id: 'project_manager', role: 'Project Manager'}, {id: 'sales_admin', role: 'Sales Admin'}]

	return (
		<Modal isOpen={open} toggle={handleclose} className='modal-dialog-centered'>
			<ModalHeader toggle={handleclose}>User Profile</ModalHeader>
			<ModalBody>
				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>User Name</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>{user ? ((user.first_name?user.first_name:'-')+' '+(user.last_name?user.last_name:'')) : "Name not Found"}</div>
				</div>

				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>Email ID</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>{user ? user.email : "Email Not Found"}</div>
				</div>

				<div style={{display:'flex', alignItems:'center', padding:'16px'}}>
					<span style={{flex:'2', display:'flex', alignItems:'center'}}>Access Level</span>
					<div style={{ flex:'3', border:'1px solid rgba(197, 199, 207)', borderRadius:'4px', padding:'8px 16px', color:'rgba(197, 199, 207)'}}>{user ? user.role.map(item => (
						<span>{remove_underscore_wtih_first_cap(item.id)} | </span>
					)): ''}</div>
				</div>

			</ModalBody>
		</Modal>
	)
}

const Edit_price_settings_modal = ({user, open , handleclose, store_details, get_store_details}) => {

	const [tandc_text, set_tandc_text] = useState('');
	const [tandc_array, set_tandc_array] = useState([]);
    const [make_bold, set_make_bold] = useState(null)

	const [show_escape_warning, set_show_escape_warning] = useState(false);
	const [discard_changes, set_discard_changes] = useState(false);

	const alert = useAlert();

	const onclick_update_pricing_details = async () => {
		if((tandc_array && tandc_array.length==1) || is_valid_add_key(tandc_array)){
			try {
				console.log("tandc", JSON.stringify(tandc_array))
				var updates = JSON.stringify({ boq_terms_and_conditions: JSON.stringify(tandc_array)})
				var body = {updates:updates}
				var response = await general_fetch({ url: 'store/update_info' , body });
				alert.success('Store Pricing T & C text successfully updated')	
				onclick_handleclose();	
				get_store_details()	
			} catch(err) {
				alert.error('API call failed')
				console.log(err);
			}
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const is_valid_add_key = () => {
		if(tandc_array && tandc_array.length > 0){
			for(let i=0;i<tandc_array.length;i++){
				// console.log("printing tandc", tandc_array[i][0])
				if(tandc_array[i][0].text==='' || tandc_array[i][1].text==='')
					return false
			}
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const is_valid_add_key_1 = () => {
		if(tandc_array && tandc_array.length > 0){
			// console.log("printing tandc", tandc_array[0][0])
			if(tandc_array[0][0].text==='' || tandc_array[0][1].text==='')
				return false
			return true
		}else{
			// console.log("printing tandc true")
			return true
		}
	}

	const onclick_handleclose =() => { 
		handleclose()
		set_make_bold(null)
		set_tandc_text('')
	}

	const add_tandc_row = () => {
		if(is_valid_add_key()){
			let a = [...tandc_array]
			let temp_row = [{text:'',bold:false},{text:'',bold:false}]
			a.push(temp_row)
			set_tandc_array(a)
		}else{
			alert.error("Empty values are not allowed !")
		}
	}

	const update_tandc_row = (idx1, idx2, key, value) => {
		let a = [...tandc_array]
		if(key=='text')
		{
			a[idx1][idx2].text = value
		}
		if(key=='bold'){
			a[idx1][idx2].bold = value
		}
		set_tandc_array(a)
	}

	const delete_tandc_row = (idx) => {
		let a = [...tandc_array]
		a.splice(idx,1)
		set_tandc_array(a)
	}

	useEffect(() => {
		if(tandc_array && tandc_array.length==0){
			add_tandc_row()
		}
	},[tandc_array])

	useEffect(() => {
		if(store_details){
			// set_tandc_array(store_details?store_details.boq_terms_and_conditions:'')	
			let temp_array = store_details.boq_terms_and_conditions;
			if (temp_array) {
				try {
					temp_array = JSON.parse(temp_array);
					if (!temp_array || !temp_array.length) {
						temp_array=[];
					}
				} catch (err) {
					console.error("BOQ not json")
					temp_array = [];
				}
			} else {
				temp_array = [];
			}
			set_tandc_array(temp_array)
		}
	},[store_details, open])

	useEffect(() => {
		console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])


	return(
		<Modal className="Margin_64px_top" size="am" isOpen={open} toggle={() => set_show_escape_warning(true)}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={() => set_show_escape_warning(false)}/>
			<Card className='global_modal_height_for_pricing_settings'>
				<CardHeader className='global_modal_header'>
					<span>Manage Store Pricing Settings</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}><span>{tandc_array && tandc_array.length > 0 && is_valid_add_key_1(tandc_array)?'Edit the':'Add'}</span><span style={{fontWeight:800}}> Terms </span><span>&</span><span style={{fontWeight:800}}> Conditions </span><span>to be printed in the pricing quotations</span></div>
								<div style={{marginTop:'20px'}}></div>
								<div style={{display:'flex'}}>
									<span style={{flex:1, marginLeft:'5%', fontSize:'14px'}}>Store Terms</span>
									<span style={{flex:3, marginLeft:'8%', fontSize:'14px'}}>Store Conditions</span>
								{/* <button style={{flex:1, fontSize:'14px', color:'#2688F7'}}>+ Add new Column</button> */}
								</div>
								{/* <textarea style={{width:'100%',height:'150px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Text..." type="text" value={tandc_text} onChange={e => set_tandc_text(e.target.value)}/> */}
								{
									tandc_array && tandc_array.length ? tandc_array.map((tandc_row,idx) => {
										return(
											<PricingTermsConditions tandc_row = {tandc_row} row_index={idx} update_tandc_row = {update_tandc_row} delete_tandc_row = {delete_tandc_row} make_bold={make_bold} set_make_bold={set_make_bold}/>
										)
									}):''
								}
							</div>
						</Col>
						
					</Row>
					<Row>
						<span style={{color:'#2688F7', fontSize:'14px', cursor:'pointer', fontWeight:'700', marginTop:'1%', marginLeft:'3%'}} onClick = {() => add_tandc_row()}>+ Add more terms</span>
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button className='blue_button' type="button" onClick={onclick_update_pricing_details}>Update</Button>
				</CardFooter>
			</Card>  
		</Modal>
	)
}

const Edit_store_profile = ({user, open , handleclose, onclick_logout, store_details, get_store_details}) => {

	const [file, set_file] = useState('');
	const [store_name, set_store_name] = useState('');
	const [store_type, set_store_type] = useState('');
	const [image_path, set_image_path] = useState('');

	const [gst_number , set_gst_number] = useState('')
	const [address_line1 , set_address_line1] = useState('')
	const [address_line2 , set_address_line2] = useState('')
	const [city , set_city] = useState('')
	const [address_state , set_address_state] = useState('')
	const [country , set_country] = useState('')
	const [zipcode , set_zipcode] = useState('')
	
	const [show_escape_warning, set_show_escape_warning] = useState(false);
	const [discard_changes, set_discard_changes] = useState(false);
	
	const alert = useAlert()
	const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });

	const onclick_update_store_logo = async () => {
		if(file){
			try {
				var fd = new FormData();
				fd.append('upl', file, file.name)
				var resp = await general_fetch({ url: 'store/upload_logo', body:fd, is_form_data:true});
				alert.success('Logo successfully uploaded')	
				onclick_handleclose();	
				get_store_details()	
			} catch(err) {
				alert.error('Error while uploading logo or logo missing')
				console.log(err);
			}
		}else{
			alert.error('Please upload store logo')
		}
	}

	const onclick_update_name = async () => {
		try {
			let location = {
				...(city ? { city } : {}),
				...(address_line1 ? { line_1: address_line1 } : {}), 
				...(address_line2 ? { line_2: address_line2 } : {}),
				...(address_state ? { state: address_state } : {}),
				...(country ? { country } : {}),
				...(zipcode ? { pin_code: zipcode } : {}),
			}
			let updates = {
				...(store_name ? { name: store_name } : {}),
				...(gst_number ? { gst_number } : {}),
			}
			location = Object.keys(location).length > 0 ? JSON.stringify(location) : null;
			updates = Object.keys(updates).length > 0 ? JSON.stringify(updates) : null;
			if (!location && !updates) {
				alert.error('Please enter atleast one field to update')
				return;
			}
			let body = {location,updates}
			let response = await general_fetch({ url: 'store/update_info' , body });
			if(response){
				window['analytics'].group(store_details.id, {
					name: store_details.name,
					company_id: store_details.id,
					plan: store_details.plan_type
				})
			}
			alert.success('Store Details successfully updated')	
			onclick_handleclose();	
			get_store_details()	
		} catch(err) {
			alert.error('failed to update store details')
			console.error(err);
		}
	}

	const onclick_handleclose =() => { 
		handleclose()
		set_store_name('')
		set_image_path('')
		set_store_type('')
		set_file()

		set_gst_number('')
		set_address_line1('')
		set_address_line2('')
		set_city('')
		set_address_state('')
		set_country('')
		set_zipcode('')
		//set_show_escape_warning(false)
	}

	useEffect(() => {
		if(file){
			console.log(file)
			set_image_path('')
		}
	},[file])

	useEffect(() => {
		console.log("country", country)
	}, [country]);

	useEffect(() => {
		if(store_details){
			set_store_name(store_details?store_details.name:'')
			set_store_type(store_details?store_details.type:'')
			set_image_path(store_details && store_details.logo_path?(global.config.server_path+store_details.logo_path):'')

			set_gst_number(store_details.gst_number);
			set_address_line1(store_details.location ? store_details.location.line_1: '');
			set_address_line2(store_details.location ? store_details.location.line_2: '');
			set_city(store_details.location ? store_details.location.city: '');
			set_address_state(store_details.location ? store_details.location.state: '');
			set_zipcode(store_details.location ? store_details.location.pin_code: '');
			set_country(store_details.location ? store_details.location.country: '');
		}
	},[store_details, open])

	useEffect(() => {
		console.log("discard", discard_changes)
		if(discard_changes == true){
			onclick_handleclose()
			set_discard_changes(false)
		}
	},[discard_changes])

	const submit = (yes_del, no_del, message, nonowned) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: message,
          customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} nonowned={nonowned}/>
                </div>
            );
          }
        });
    };

	const delete_account = async () => {
		try {
			var resp = await general_fetch({ url: 'user/delete_own_account'});
			alert.success('User successfully Deleted')
			onclick_logout()
			window.location.href=global.config.auth_redirect_path
		} catch(err) {
			alert.error('Cannot Delete Your account')
			console.log(err);
		}
	}

	const onclick_update_store = () => {
		// if(file){
		// 	onclick_update_store_logo()
		// }
		if(file && file.size <= 2500000){
			onclick_update_store_logo()
			// fd.append('upl', file, file.name)
		}else if(file){
			alert.error('Please select a file of size less than 2.5MB')
			return
		}
		onclick_update_name()
	}

	return(
		<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={() => set_show_escape_warning(true)}>
			<EscapeWarning open = {show_escape_warning} set_discard_changes={set_discard_changes} handleClose={() => set_show_escape_warning(false)}/>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<span>Organization Profile</span>
					<i onClick={onclick_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row style={{marginBottom:"10px"}}>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Organization Name *</div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Store Name" type="text" value={store_name} onChange={e => set_store_name(e.target.value)}/>
							</div>
						</Col>
						{
							['india','IN'].includes(country)? (
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>GST no. / Vat no. / Sales no.</div>
										<Input value={gst_number} onChange={(e) => set_gst_number(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="GST no. / Vat no. / Sales no." type="text" />
									</div>
								</Col>
							):''
						}		
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Organization Type</div>
								<Input disabled style={{textTransform:'capitalize',width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#f2f2f2', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Store Name" type="text" value={store_type}/>
							</div>
						</Col>	
					</Row>
					<Row style={{margin:"20px 0px 10px"}}>
						<Col style={{padding:0}}>
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
								
							</div>
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col style={{fontSize:'16px'}}>
							Organization Address
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 1 *</div>
								<Input value={address_line1} onChange={(e) => set_address_line1(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 1" type="text" />
							</div>
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Address Line 2</div>
								<Input value={address_line2} onChange={(e) => set_address_line2(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Line 2" type="text" />
							</div>
						</Col>
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>City *</div>
								<Input value ={city} onChange={(e) => set_city(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="City" type="text" />
							</div>
						</Col>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Province / State*</div>
								<Input value ={address_state} onChange={(e) => set_address_state(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'State':'Province'} type="text" />
							</div>
						</Col>		
					</Row>
					<Row style={{marginBottom:"10px"}}>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Zip code / Pin code *</div>
								<Input value ={zipcode} onChange={(e) => set_zipcode(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder={country=='india'?'Zip':'Pin'} type="text" />
							</div>
						</Col>
						<Col xs={6}>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Country *</div>
								<Input value ={country} onChange={(e) => set_country(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Country" type="select">
									{
										Country_list.map((country, idx) => (
											<option key={idx} value={country.code}>{country.name}</option>
										))
									}
								</Input>
							</div>
						</Col>		
					</Row>

					<Row style={{margin:"20px 0px 10px"}}>
						<Col style={{padding:0}}>
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
								
							</div>
						</Col>
					</Row>

					<Row style={{marginBottom:"5px"}}>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'16px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '30px'}}>Upload Store Logo</div>
								<UploadFile id="id_upload_file" set_file={set_file} accept='.jpg,.png,.jpeg, .gif'></UploadFile>	
								{
									file && file.size>2500000?
									<div style={{paddingTop:'7px',fontSize:'12px', color:'red', letterSpacing: '-0.04px', lineHeight: '20px'}}>File size exceeded, Please select a file of size less than 2.5 MB</div>
									:
									!file?<div style={{paddingTop:'7px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Please select a file of size less than 2.5 MB</div>:''
								}
							</div>
						</Col>	
					</Row>
					<Row>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{file && file.name?file.name:''}</div>
							</div>
						</Col>	
					</Row>

					{
						image_path?
						<Row style={{marginBottom:"5px"}}>
							<Col>
								<div>
									<img style={{height:'50px', width:'50px'}} src={image_path}/>
								</div>
							</Col>	
						</Row>
					:''}

					<Row style={{margin:"20px 0px 10px"}}>
						<Col style={{padding:0}}>
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)'}}>
								
							</div>
						</Col>
					</Row>

					{
						store_details && store_details.type==='professional'?
						<Row style={{margin:"10px 0px 8px"}}>
							<Col style={{padding:"0px"}}>
								<div>
									<span 
										onClick ={() => {onclick_handleclose();submit(()=>delete_account(), ()=>{},'Are you sure you want to delete your account? If you click on Delete, you will permanently lose access to all your data.')}}
										title='Clicking on Delete will permanently delete your account and lose access to all your data.' 
										style={{fontSize:'16px', color:'red', lineHeight: '20px', cursor:'pointer',color:'rgb(245, 89, 78)'}}
									>Delete Account</span>
								</div>
							</Col>	
						</Row>
						:''
					}
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					<Button className='blue_button' type="button" onClick={onclick_update_store}>Update</Button>
				</CardFooter>
			</Card>  
		</Modal>
	)
}

const Header = ({user, store_details, get_store_details}) => {

	const alert = useAlert()
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [show_edit_store_profile, set_show_edit_store_profile] = useState(false);
	const [design_link, set_design_link ] = useState('/design.infurnia.com/')
	// const [store_details, set_store_details] = useState('');	
	const [show_price_setting_modal, set_show_price_setting_modal] = useState(false);
	const [show_user_profile, set_show_user_profile] = useState(false);
	const [initialised, set_initialised] = useState(false);
	const [page_loader,set_page_loader] = useStateCallback({
        show: false,
        text: "Please wait ..."
    });
  	const toggle = () => setDropdownOpen(prevState => !prevState);

	// const get_store_details = async () => {
    //     try {
	// 		set_page_loader({
	// 			show: true,
	// 			text: "Fetching store details ..."
	// 		})
    //         var resp = await general_fetch({ url: 'store/get_info'});
    //         // set_store_details(resp? resp:{});
	// 		set_page_loader({
	// 			show: false,
	// 			text: ""
	// 		})
    //     } catch(err) {
    //         console.log(err);
    //     }
    // }

	const onclick_logout = async () => {
		try {		
            let resp = await general_fetch({ url: 'user/logout'});

			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				window.location.href = `https://login.${domain}.${extension}/?target=admin`;
			} else {
				window.location.href=global.config.auth_redirect_path;
			}

			alert.success('Logout successfull')	
			window.$globalSocket.emit('log_out');
			window.$globalSocket.close()
            // window.alert('This session is being logged out because another session of the same user has been detected');
		} catch(err) {
			alert.error('Error while logout')
			console.log(err);
		}
	}

	useEffect(()=>{
		if(global){
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				set_design_link(`https://design.${domain}.${extension}/`);
			} else {
				set_design_link(global.config.website_url)
			}
		}
		// get_store_details()
	},[])

	useEffect(() =>{
        if(store_details){
            set_initialised(true)
        }
    },[store_details])

	const close_edit_store_profile = () => {
		set_show_edit_store_profile(false)
	}
	const close_price_setting_modal = () => {
		set_show_price_setting_modal(false)
	}
	const close_user_profile_modal = () => {
		set_show_user_profile(false)
	}

	const checkout_for_acc_role = () => {
		var roles = user && user.role && user.role.length ?user.role.map(x=>x.id):''
		if(roles && roles.length){
			return roles.includes('accounts_manager')
		}else{
			alert.error('Please acontact your admin to assign account manager role.')
		}
	}
	const open_edit_store_profile = () => {	
		if(checkout_for_acc_role()){
			set_show_edit_store_profile(true)
		}else{
			alert.error('Only Account Manager can access Store profile settings.')
		}
	}
	const open_price_setting = () => {
		if(checkout_for_acc_role()){
			set_show_price_setting_modal(true)
		}else{
			alert.error('Only Account Manager can access Store pricing settings.')
		}
	}

	return (
		<React.Fragment>
			<Edit_store_profile alert={alert} user={user} open={show_edit_store_profile} handleclose={close_edit_store_profile} onclick_logout={onclick_logout} store_details={store_details} get_store_details={get_store_details}/>
			<Edit_price_settings_modal user={user} open={show_price_setting_modal} handleclose={close_price_setting_modal} store_details={store_details} get_store_details={get_store_details}/>
			<View_User_Profile user={user} open={show_user_profile} handleclose={close_user_profile_modal}/>
			<Card style={{height:'100%', width:'100%', borderRadius:0}}>
				
				<div style={{height:'100%', width:'100%',display:'flex', alignItems:'center',justifyContent:'space-between'}}>
					<span style={{height:'40px', width:'auto', marginLeft:'20px'}}>
                    {
                        initialised?
                        <img height='100%' width='auto' alt="Infurnia" 
                            src={store_details && store_details.logo_path?global.config.server_path+store_details.logo_path 
                                :
                                "../../../assets/img/brand/blackLogo.png"
                            }
                        />
                        :''
                    }
                    </span>
					<div style={{display:'flex', alignItems:'center'}}>
						<a target='_blank' style={{ cursor: 'pointer', marginRight:'10px', width: 60 }} className='design_onhover'></a>
						<a href={design_link} target='_blank' style={{marginRight:'10px'}} className='design_onhover'>Design Portal</a>
						<Dropdown isOpen={dropdownOpen} toggle={toggle}>
							<DropdownToggle className='admin_onhover' style={{backgroundColor:'white', color:'black', border:0, display:'flex', alignItems:'center', marginRight:'15px'}} caret>
								{/* <span style={{display:'flex', alignItems:'center',justifyContent:'flex-end'}}> */}
									{/* <span style={{marginRight:'20px'}}><i style={{cursor:'pointer', fontWeight: 1100}} className='fa fa-bell'/></span> */}
									<span  style={{cursor:'pointer', display:'flex', alignItems:'center', marginRight:'10px'}}>
										<div
											className="circle"
											// alt="..."
											// src="/assets/img/icons/avatar.png"
											style={{height:'24px', width:'24px'}}>
												<p className="circle-inner">{user && user.first_name ? user.first_name.trim()[0] : ''}</p>
											</div>
										<span className="mb-0 text-sm" style={{fontSize:'14px',color:'black'}}>
											Admin
										</span>
									</span>
								{/* </span> */}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={open_edit_store_profile}>Organization Profile</DropdownItem>
								<DropdownItem onClick={open_price_setting}>Org Pricing Terms</DropdownItem>
								<DropdownItem onClick={() => set_show_user_profile(true)}> User Profile </DropdownItem>
								<DropdownItem onClick={onclick_logout}>Logout</DropdownItem>
							</DropdownMenu>
						</Dropdown>
    				</div>
	        	</div>
	        	
			</Card>
		</React.Fragment>
	);
}

export default Header;