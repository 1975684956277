import React , { useState , useEffect }from 'react';
import {Modal, Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import build_path from "../../utils/path";
import LazyLoad from 'react-lazyload';

const ImgLoader = () => {
    return(
        <div style={{height:'100%', width:"100%",boder:'1px solid #f9f9f9', padding:'10px',backgroundColor:'#f2f2f2'}}></div>
    )
} 

const Choose_existing_texture = ({open , handle_close, set_return_texture_details , texture_json, is_material ,set_page_loader}) => {

	// const [display_texture_json , set_display_texture_json] = useState([]);
	// const [index_value , set_index_value] = useState(0);
	
    // useEffect(() => {
    //     var x= [];
    //     if(texture_json && open || texture_json && index_value){
    //         for (var i=index_value ; i<index_value+20; i++){
    //             x.push(texture_json[i])
    //         }
    //         set_display_texture_json(x)
    //     }
    // },[open, index_value])

    // useEffect(() => {
    //     if(display_texture_json){
    //         console.log(display_texture_json)
    //     }
    // },[display_texture_json])

    const onclick_handleclose = () => {
        handle_close()
        // set_display_texture_json([])
        // set_index_value(0)
    }

    const onclick_image = (texture) => {
        set_return_texture_details(texture)
        onclick_handleclose()
    }

	return(
        <Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={onclick_handleclose}>
            <Card className='global_modal_height_for_mdlgxl'>
                <CardHeader className='global_modal_header'>
                        <span>{is_material?'Choose Material':'Choose texture'}</span>
                        <i onClick={onclick_handleclose} style={{cursor:'pointer'}} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body'>
                    <Row style={{margin:0}}>
                    {
                        texture_json && texture_json.length ? texture_json.map((texture, idx) =>
                            <Col key={idx} xs={3} style={{height:'150px', border:'1px solid rgba(0, 0, 0, 0.125)', textAlign:'center', padding:'5px'}}>
                                <LazyLoad overflow={true} placeholder={<ImgLoader/>} style={{height:'100%', width:'100%'}}>
                                    <img onClick={() =>onclick_image(texture)} style={{maxWidth:'100%', maxHeight:'calc(100% - 30px)', height:"200px"}} 
                                        src ={texture && texture.display_pic && texture.display_pic.image? 
                                            build_path(global.config.static_path, texture.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) 
                                            : 
                                            texture && texture.Image && texture.Image.image? build_path(global.config.server_path, texture.Image.image): global.config.image_not_found}
                                    />
                                    <div style={{textTransform:'capitalize', height:'30px', fontSize:'12px', display:'flex', alignItems:'center',justifyContent:'center', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{texture.name?texture.name:texture.id}</div>
                                </LazyLoad>
                            </Col>
                        ):''
                    }
                    </Row>
                </CardBody>
           
                <CardFooter className='global_modal_footer'>
                </CardFooter>
            </Card>    
        </Modal>
    )
}

export default Choose_existing_texture;

// src ={type==='texture'?
//                                         texture && texture.Image && texture.Image.image? build_path(global.config.server_path, texture.Image.image) : global.config.image_not_found
//                                         :
//                                         texture && texture.display_pic && texture.display_pic.image? build_path(global.config.server_path, texture.display_pic.image) : global.config.image_not_found
//                                     }