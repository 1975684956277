import React , { useState , useEffect, useRef }from 'react';
import general_fetch from '../../../utils/fetch';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardHeader, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import UploadFile from "../../../components/UploadFile";
import build_path from "../../../utils/path";
import take_sku_snapshot from '../../../utils/take_sku_snapshot.js';
import { useAlert } from 'react-alert'
import './sku_groups.css';
import Add_new_material from '../../../components/Add_new_material';
import Choose_existing_material from '../../../components/Choose_existing_material';
import Upload3d_modal_modal from "../../../components/Upload_3d_modal/";
import CardFooter from 'reactstrap/lib/CardFooter';
import { confirmAlert } from 'react-confirm-alert';
import Common_tag_component from '../../../components/Common_tag_component';
import ManageSalesSku from './ManageSalesSku';
import KeyValuePair from '../../../components/KeyValuePair';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import Linked_sku_struct from './Linked_sku_struct';
import CustomBOM from './custom_bom';
import SingleSelect from '../../../components/SingleSelect';
import Property_Tab from '../../../components/Add_new_material/PropertyTab';
import RuleNameCloneModal from '../../../components/RuleNameCloneModal';
import remove_underscore_wtih_first_cap from '../../../utils/remove_underscore_wtih_first_cap';
import DragDropComponent from '../../../components/DragDropCompoment';
import DCRulesDialogBox from '../../DesignConstraint/DCRulesDialogBox';
import name_sort from '../../../utils/name_sort';
import Pegasus_Paginator from '../../../components/Pegasus_Paginator';

const rule_type_json_dc_rules = {
	drawer_to_accessory: {
		id:'drawer_to_accessory',
		name:'Drawer to Accessory',
		source:{
			component_id:'drawer',
			component_name:'Drawer',
			subcomponent_id:'drawer',
			subcomponent_name:'Drawer',
			property:[
				{
					id:'drawer_id',
					name:'Drawer ID',
					type:'multiselect',
				}
			]
		},
		dest:{
			component_id:'drawer',
			component_name:'Drawer',
			subcomponent_id:'drawer_organiser',
			subcomponent_name:'Drawer Organiser',
			property:[
				{
					id:'tag',
					name:'Tag',
					type:'multiselect',
				}
			]
		},
		source_options:[
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'drawer',
				subcomponent_name:'Drawer',
				property:[
					{
						id:'drawer_id',
						name:'Drawer ID',
						type:'multiselect',
					}
				]
			},
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'tandem_box',
				subcomponent_name:'Tandem Box',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'sku',
						name:'Sku',
						type:'multiselect',
					}
				]
			}
		],
		destination_options:[
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'drawer_organiser',
				subcomponent_name:'Drawer Organiser',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'sku',
						name:'Sku',
						type:'multiselect',
					}
				]
			},
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'tandem_box',
				subcomponent_name:'Tandem Box',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'sku',
						name:'Sku',
						type:'multiselect',
					}
				]
			},
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'basket',
				subcomponent_name:'Basket',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'sku',
						name:'Sku',
						type:'multiselect',
					}
				]
			},
			{
				component_id:'drawer',
				component_name:'Drawer',
				subcomponent_id:'channel',
				subcomponent_name:'Channel',
				property:[
					{
						id:'tag',
						name:'Tag',
						type:'multiselect',
					},
					{
						id:'sku',
						name:'Sku',
						type:'multiselect',
					}
				]
			},
		]
	}
}

const component_tags_json_dc_rules = [

]

// const Filler = ({ initialized }) => (
// 	<Table className="align-items-center table-flush text-center" responsive>
// 		<thead className="thead-light text-center">
// 			<tr>
// 				{ initialized ? (
// 					<th scope="col">
// 						<span><b>No 3D Model  </b></span><br/> Please Upload obj and MTL file for 3d model.<br /> &nbsp;
// 					</th>
// 				):(
// 					<th scope="col">
// 						<Row>
// 							<Col xs={12} className="text-center">
// 								<img src="/load2.gif" />
// 							</Col>
// 							<Col xs={12} className="text-center">
// 								<span ><b>Fetching Model  </b></span><br/> <br /> Please wait...<br /> <br />
// 							</Col>
// 						</Row>
// 					</th>
// 				)}
// 			</tr>
// 		</thead>
// 	</Table>	
// );
const styles = {
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
};

const SkuLinkedRules = ({user, submit_for_delete_confirm, onclick_handle_close_final, set_page_loader, selected_sku_properties, all_division_tree_data, is_owned_sku}) => {
	const [rules, set_rules ] = useState([]);
	const [show_rule_Dialog_Box, set_show_rule_Dialog_Box] = useState(false);
	const [active_rule, set_active_rule] = useState('');
	const [search_string , set_search_string] = useState('');
	const [display_rules , set_display_rules] = useState([]);
	const [key , set_key] = useState('');
	const [filtered_display_rules, set_filtered_display_rules] = useState([]);
	const [ last_item_order , set_last_item_order ] = useState(0);
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [tags , set_tags] = useState([]);
	const [ sales_channels, set_sales_channels ] = useState([]);
	const [drawer_ids_from_component_file, set_drawer_ids_from_component_file] = useState();

	const fetch_rules_details = async() => {
		if(selected_sku_properties && selected_sku_properties.id){
			try{
				let identifiers = JSON.stringify({ id : selected_sku_properties.id})
            	let body = {identifiers}
				let resp = await general_fetch({ url: 'sku/get', body})
				if(resp && resp.length && resp[0].linked_rules) set_rules(resp[0].linked_rules)
			}catch(err){
				console.log(err);
			}
		}
	}

	const fetch_tags_details = async () => {
        try {
        var tags_details = await general_fetch({ url: 'tag/get'});
		console.log("tag DesignConstraint")
        set_tags(tags_details);
        } catch(err) {
        console.log(err);
        }
    }

	const fetch_sales_channel_details = async () => {
		try {
			var resp = await general_fetch({ url: 'sales_channel/get'});
			var sales_channel_details= resp && resp.length ? resp.sort(name_sort):[]
			set_sales_channels(sales_channel_details);
		} catch(err) {
			console.log(err);
		}
	}

	const filter_rule = () => 
	{
		var filtered_brands = [...rules];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		console.log(filtered_brands)
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => (brand.name.toLowerCase().includes(lower_search_string)|| (brand.description ? brand.description.toLowerCase().includes(lower_search_string):false) || (rule_type_json_dc_rules[brand.rule_type] ? rule_type_json_dc_rules[brand.rule_type].name.toLowerCase().includes(lower_search_string):false)))
		}
		set_display_rules(filtered_brands)
	}

	const open_rule_update_dialog = ({ item, mode }) => {
		console.log("item=====>", item)
		set_show_rule_Dialog_Box(true);
		set_active_rule(item);
		set_key(mode);
	}

	const close_rule_Dialog_Box = () => {
		set_show_rule_Dialog_Box(false);
		set_active_rule('');
		set_key('');
	}

	const open_rule_add_dialog = () => {
		set_show_rule_Dialog_Box(true);
		set_key('add');
	}

	const onclick_delete_rule = async(id) => {
		try {
			var body={id:id}
			var response = await general_fetch({ url: 'automation_rule/remove', body})
			alert.success('Rule Deleted sucessfully')
			fetch_rules_details()
		} catch(err) {
			console.log(err);
			alert.error('Please check input format.')
		}
	}

	const onclick_suffle_rule = async(active_rule, new_order) => {
		try {
			var body = {
				rule_type: 'design_constraint',
				data:[{id:active_rule.id,order:new_order}]
			};
			var response = await general_fetch({ url: 'automation_rule/update_order', body});	
			alert.success('Rule Updated Successfully !')
			fetch_rules_details()
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_drawer_ids_from_component_file = (component_file) => {
		// Used to cache the partition data for speedup
		let partition_to_partition_id_map = {}
	
		// Returns id and name of the current partition
		function find_partition_id(partition){
			try{
				if(!partition){
					console.log("Partition is Undefined")
					return {}
				}
				let partition_id = partition.id
				let current_partition_detail = {}
	
				// If already present in the cache
				if(partition_id in partition_to_partition_id_map){
					return partition_to_partition_id_map[partition_id]
				}
				
				// Fetch the parent of the partition
				const parent_of_partition = component_file.filter(o=> o.id == partition.container_component_id)[0]
				
				if(!parent_of_partition){
					console.log("Parent of Partition doesn't exist for partition",partition)
					return {}
				}
				// If the parent is cabinet or corner unit(default partition)
				if(parent_of_partition.type != "partition"){
					current_partition_detail.id = ""
					current_partition_detail.name = "Partition 1"
					partition_to_partition_id_map[partition_id] = current_partition_detail
					return current_partition_detail;
				}
				
				// Get all other partitions in the same level and sort based on partition id
				let all_peer_partitions = component_file.filter(o=> o.container_component_id == partition.container_component_id && o.type == "partition")
				all_peer_partitions.sort(function(a,b){return a.data.partition_id < b.data.partition_id})
				
				// get the partition index of current partition
				const current_partition_index = all_peer_partitions.map(o=> o.id).indexOf(partition.id) + 1
				let current_partition_id = ((partition.data.partitionType == "Vertical") ? "H" : "V") + current_partition_index.toString()
				
				// Fill the details of current partition
				let parent_partition_details = find_partition_id(parent_of_partition)
				if(!parent_partition_details){
					console.log("Parent Partition Details are not present for partition",parent_of_partition)
					return {}
				}
				current_partition_detail.id = parent_partition_details.id + current_partition_id
				current_partition_detail.name = parent_partition_details.name + "_" + (partition.data.partition_id+1).toString()
				partition_to_partition_id_map[partition_id] = current_partition_detail
				return partition_to_partition_id_map[partition_id]
			}catch(err){
				console.error("Error in parsing partition",partition)
				return {}
			}
		}
	
		// Return ID and name of current drawer
		function find_drawer_id(drawer){
			try{
				let drawer_detail = {}
	
				// Get the drawer system
				let drawer_system = component_file.filter(o=> o.id == drawer.container_component_id && o.type == "drawer_system")[0]
				if(!drawer_system){
					console.log("No Drawer System found for Drawer",drawer)
					return {}
				}
	
				// Get all drawers of the same level and sort according to the drawer order id
				let all_peer_drawers = component_file.filter(o=> o.container_component_id == drawer_system.id && o.type == "drawer")
				all_peer_drawers.sort(function(a,b){return a.data.drawer_order_id < b.data.drawer_order_id})
				
				// find the current drawer index
				let current_drawer_index = all_peer_drawers.map(o=> o.id).indexOf(drawer.id) + 1
				
				// Get the partition of the current drawer system
				let current_partition = component_file.filter(o=> o.id == drawer_system.container_component_id && o.type == "partition")[0]
				
				if(!current_partition){
					console.log("No Partition found for Drawer", drawer)
					return {}
				}
	
				// Get the details of partition
				let partition_detail = find_partition_id(current_partition)
				
				if(!partition_detail){
					console.log("No Partition Detail found for Drawer",drawer)
					return {}
				}
	
				// Fill the details of drawer
				drawer_detail.id = partition_detail.id + "D" + current_drawer_index.toString()
				drawer_detail.name = drawer_detail.id + " (" + partition_detail.name + " Drawer " + current_drawer_index.toString() + ")"
				return drawer_detail
			}catch(err){
				console.log("Error in Parsing Drawer",drawer)
				return {}
			}
		}
		
		// Get all drawers in component file and find drawer details
		try{
			var drawers = component_file.filter(o=> o.type == "drawer")
			var drawer_ids = drawers.map(find_drawer_id).filter(ele => ele && ele.id && ele.name)
			return drawer_ids
		}catch(err){
			console.log("Error in Parsing Drawers")
			return []
		}
	}

	useEffect(() => {
		if(selected_sku_properties && selected_sku_properties.linked_rules && selected_sku_properties.linked_rules.length){
			set_rules(selected_sku_properties.linked_rules)
		}
		if(selected_sku_properties && selected_sku_properties.component_file){
			set_drawer_ids_from_component_file(fetch_drawer_ids_from_component_file(selected_sku_properties.component_file))
		}
	}, [selected_sku_properties]);

	useEffect(() => {
		if(!tags.length){
			fetch_tags_details();
		}
		if(!sales_channels.length){
			fetch_sales_channel_details();
		}
	}
	, [user]);

	useEffect(() => {
		set_display_rules(rules)
	}, [rules]);

	useEffect(() => {
		filter_rule();
	}, [search_string, rules]);

	useEffect(() => {
		console.log('display_rules', display_rules)
	}, [display_rules]);

	const Single_table_row = ({item, index, start_index, end_index, open_rule_update_dialog, onclick_delete_rule, submit_for_delete_confirm, user, fetch_rules}) => {
		const [show_name_clone_modal, set_show_name_clone_modal] = useState(false);
		const [active_rule, set_active_rule] = useState('');
		const alert = useAlert();

		const clone_rule = async(rule_json, new_name) => {
			try{
				let body = {
					name: new_name,
					description: rule_json.description,
					order: rule_json.order+1,
					type: rule_json.type,
					rule_type: rule_json.rule_type,
					rule_logic: rule_json.rule_logic,
					active: false,
					condition_combination_logic: rule_json.condition_combination_logic,
					action_combination_logic: rule_json.action_combination_logic,
					applicability:[],
					conditions: rule_json.conditions,
					actions: rule_json.actions,

					sku_linked_rule: true,
					sku_id: selected_sku_properties.id
				}

				let resp = await general_fetch({url:'automation_rule/create', body})
				fetch_rules()
				alert.success('Rule cloned Successfully !')
			}catch(err){
				alert.error("Rule clone failed !")
				console.log(err)
			}
		}

		const open_name_clone_modal = (rule_json) => {
			set_active_rule(rule_json)
			set_show_name_clone_modal(true)		
		}

		const close_name_clone_modal = () => {
			set_active_rule('')
			set_show_name_clone_modal(false)
		}
		useEffect(() => {
			console.log('start_index end_index', start_index, end_index)
		}, [start_index, end_index]);

		return (
			// {
			// display_rules && display_rules.length && display_rules.map((rule, index) => (
			<tr key={index} style={{backgroundColor:'white', display:/*(index+1>=start_index && index<end_index)?*/'flex'}} className='tr_style display_onhover'>
				<RuleNameCloneModal open={show_name_clone_modal} handleClose={close_name_clone_modal} rule_json={active_rule} clone_rule={clone_rule} />
				<td className='td_style flex_property' style={{flex:1}}>
						<div className='drag_option' style={{display:'flex', alignItems:'center', color:'#808080'}}>
							<i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/>
							<i className='fa fa-ellipsis-v'/>
							<span style={{marginLeft:'10px'}}>{index+1} .</span>
						</div>
					</td>
				<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.name}</span></td>
				<td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.description?item.description:'-'}</span></td>
				<td className='td_style flex_property' style={{flex:5}}>{item.rule_type?remove_underscore_wtih_first_cap(item.rule_type):'-'}</td>
				<td className='td_style flex_property' style={{flex:5}}>{item.active==1?'True':'False'}</td>
				{/* <td className='td_style flex_property' style={{flex:5}}><span className='table_text_overflow'>{item.created_at}</span></td> */}
				
				<td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:2, paddingRight:'20px'}}>
					{
						user.store_id ==item.store_id ?(
							<span className='hide'>
								<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'view' })} className='fa fa-eye'/>
								{
									is_owned_sku?
									<>
										<i style={{marginRight:'15px'}} onClick ={() => open_rule_update_dialog({ item, mode: 'edit' })} className='fa fa-edit'/>
										<img src={'/assets/img/icons/clone_icon.svg'} onClick={() => {open_name_clone_modal(item)}} width='14px' height='14px' style={{marginRight:'15px'}}/>
										<i 
											onClick={()=>submit_for_delete_confirm(()=>onclick_delete_rule(item.id), ()=>{}, 'Are you sure you want to delete this Rule? Rule Name - '+ item.name)}
											className='fa fa-trash'
										/>
									</>:''
								}
							</span>
						):''
					}
				</td>
				{/* <td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:'1', paddingRight:"20px"}}>
					{
						user.store_id ==item.store_id ?(
							<span className='hide'>
								
							</span>
						):''
					}
				</td> */}
			</tr>
		// ))}
	)
	}

	const Rules_Table = () => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{flex:1}}><span>S.No</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Rule Name</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Description</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Rule type</span></th>
				<th className='th_style flex_property' style={{flex:5}}>
					<span style={{paddingRight:'8px'}}>Active</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						{/* <i title='Filter' style={{color:sort_value?COLORS.blue6:''}} onClick={() => set_is_filter_open(!is_filter_open)} className='fa fa-filter arrow_onhover_1'/> */}
						{/* <i title='Descending' onClick={() => set_sort_value(1)} className='fa fa-caret-down arrow_onhover_2'/> */}
					</span>
				</th>
				{/* <th className='th_style flex_property' style={{border:0, flex:5}}><span>Created at</span></th> */}
				<th className='th_style' style={{border:0, flex:2, paddingRight:"20px"}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
				<DragDropComponent items_from_backened={display_rules} onclick_suffle_rule={onclick_suffle_rule} set_items_from_backened={set_display_rules} Single_component={Single_table_row} start_index={start_index} end_index={end_index} user={user} open_rule_update_dialog={open_rule_update_dialog} submit_for_delete_confirm={submit_for_delete_confirm} onclick_delete_rule={onclick_delete_rule} fetch_rules={fetch_rules_details}/>
			</tbody>
		</table>
	);

	return(
		<>
			<CardBody className='global_modal_body'>
				<Row style={{padding:'10px 0px', color:'#22272e'}}>
					<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
						Viewing {display_rules && display_rules.length ?display_rules.length:'0'} Design Constraint Rules
					</Col>
					<Col xs={6} style={{textAlign:'right'}}>
					{
						is_owned_sku?
						<button onClick={open_rule_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Design Constriant Rule</span></button>
						:''
					}
					</Col>
				</Row>
				<Row style={{height:'calc(100% - 90px)'}}>
					<Col style={{height:'100%'}}>
						<Card style={{borderRadius: 0, height:'100%'}}>
							<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name, Description, Type' /></div>
							<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_rules && display_rules.length ? (<Rules_Table />) : <div className='flex_center'>No Rules to display</div>}</div>
						</Card>
					</Col>
				</Row>
				<Row style={{height:'3px'}}>
					<Col>
					</Col>
				</Row>
				<Row>
					<Col>	
						{/* <Pegasus_Paginator 
							id='design-paginator'
							end_index={end_index}
							set_end_index={set_end_index}
							start_index={start_index}
							set_start_index={set_start_index}
							all_projects={display_rules && display_rules.length ? display_rules.length : '0'}
						/> */}
					</Col>
				</Row>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='white_button' style={{margin:0}} onClick={onclick_handle_close_final}> Cancel </Button>
			</CardFooter>
			<DCRulesDialogBox 
				open={show_rule_Dialog_Box} 
				component_options_json={component_tags_json_dc_rules} 
				rule_type_json={rule_type_json_dc_rules} 
				handleClose={close_rule_Dialog_Box} 
				active_rule={active_rule} 
				user={user} 
				fetch_rules_details={fetch_rules_details} 
				mode={key} 
				set_page_loader={set_page_loader} 
				tags={tags}
				sales_channels={sales_channels}
				last_item_order={last_item_order}	
				sku_linked_rule={true}
				sku_id = {selected_sku_properties.id}
				drawer_ids_from_component_file = {drawer_ids_from_component_file}
				all_division_tree_data = {all_division_tree_data}
			/>
		</>
	)
}

const Tab_Structure = ({open, confirmation_alert, fetch_modals,set_page_loader,texture_json, fetch_material_template, material_template, set_material_template , brands, fetch_sku_groups_details, user, orignal_tags, sku_category_id, sku_category_name, sku_category_type, active_division_id ,active_group_name, updated_image, set_updated_image, updated_top_view_image, set_updated_top_view_image, alert, materials_json, modal_path, onclick_close_complete_edittab, fetch_sku_get_details, selected_sku_properties, all_sales_channels, show_sales_channel_sku_modal, is_allow_material, is_allow_material_and_depth, fetch_sku_tags, tags_assigned_inherited, all_inherited_tags, all_division_tree_data, sku_eligible_tags, get_sku_eligible_tags, all_allowed_key, send_analytics_event, submit_for_delete_confirm}) => {

	const [ updated_brand , set_updated_brand ] = useState("none");
	const [ updated_placement , set_updated_placement ] = useState('none');
	const [ updated_name , set_updated_name ] = useState('')
	const [ sku_serial_no , set_sku_serial_no ] = useState('')
	const [ updated_model , set_updated_model ] = useState('')
	const [ height , set_height ] = useState(undefined)
	const [ width , set_width ] = useState(undefined)
	const [ depth , set_depth ] = useState(undefined)
	const [ is_owned , set_is_owned ] = useState(false)

	// const [ max_height , set_max_height ] = useState(undefined)
	// const [ max_width , set_max_width ] = useState(undefined)
	// const [ max_depth , set_max_depth ] = useState(undefined)
	// const [ min_height , set_min_height ] = useState(undefined)
	// const [ min_width , set_min_width ] = useState(undefined)
	// const [ min_depth , set_min_depth ] = useState(undefined)
	const [	tabs , set_tabs ] = useState(1)
	const [	sku_order , set_sku_order ] = useState(0)

	const [orignal_tags_copy , set_orignal_tags_copy] = useState([]);
	const [sku_tag_selected_id , set_sku_tag_selected_id] = useState("none");
	const [file, set_file] = useState('')

	const [ show_Upload3d_modal_modal , set_show_Upload3d_modal_modal ] = useState(false);
	const [ show_add_new_material , set_show_add_new_material ] = useState(false);
	const [ show_choose_existing_material , set_show_choose_existing_material ] = useState(false);

	// const [upload_missing_file , set_upload_wmissing_file] = useState('')
	const [addnew_chooseexisting_flag , set_addnew_chooseexisting_flag] = useState('add')

	const [ return_confirm_low_resp_data , set_return_confirm_low_resp_data ] = useState('');
	// const [ return_confirm_high_id , set_return_confirm_high_id ] = useState('');
	// const [ return_confirm_high_model , set_return_confirm_high_model ] = useState('');
	// const [ return_confirm_low_model , set_return_confirm_low_model ] = useState('');
	// const [ choose_material_details , set_choose_material_details ] = useState('');
	const [ return_material_details, set_return_material_details ] = useState('');
	const [ scale_value, set_scale_value ] = useState(undefined);
	const [ current_material_details, set_current_material_details ] = useState('');
	const [ material_mode, set_material_mode ] = useState('');
	const [ active_material_template, set_active_material_template ] = useState('');
	const [ additional_json_item, set_additional_json_item ] = useState([]);
	const [ additional_json_item_default, set_additional_json_item_default ] = useState([]);
	const [ additional_json_item_custom, set_additional_json_item_custom ] = useState([]);
	const [ additional_json_item_not_priced, set_additional_json_item_not_priced ] = useState([]);
	const [ is_ap_open, set_is_ap_open ] = useState(false);
	const [advanced_parameters_collapse, set_advanced_parameters_collapse] = useState(false);
	const [properties, set_properties] = useState([]);
	const [editing_current_material, set_editing_current_material] = useState(false)
	const [base_texture_url, set_base_texture_url] = useState('')

	const myRef = useRef(null)

	// const [allowed_keys, set_allowed_keys] = useState(null);

	// if(sku_category_type === 'Cabinet')
	// {
	// 	let all_allowed_key_array = Object.entries(all_allowed_key).map(entry => {return {[entry[0]]: entry[1]}});
	// 	let temp_allowed_keys_array = all_allowed_key_array.filter((x) => ['cabinet_type'].includes(x.id));
		// let temp_allowed_keys = {
		// 	back_panel_height:{
		// 	id:'back_panel_height',
		// 	name:'Back Panel Height',
		// 	type:'number'
		// 	}
		// };
	// 	for(let i = 0; i < temp_allowed_keys_array.length; i++) {
	// 		temp_allowed_keys.push(temp_allowed_keys_array[i]);
	// 	}
	// 	set_allowed_keys(temp_allowed_keys);
	// }
	// const filter_allowed_keys = (arr) => {
	// 	let all_allowed_key_array = Object.entries(all_allowed_key).map(entry => {return {[entry[0]]: entry[1]}});
	// 		let temp_allowed_keys_array = all_allowed_key_array.filter((x) => arr.includes(Object.keys(x)[0]));
	// 		let temp_allowed_keys = {};
	// 	console.log("temp_allowed_keys", all_allowed_key_array, sku_category_type);
	// 	for(let i = 0; i < temp_allowed_keys_array.length; i++) {
	// 		let obj = temp_allowed_keys_array[i];
	// 		const key_array = Object.keys(obj);
	// 		const key = key_array[0];
	// 		temp_allowed_keys[key] =  obj[key];
	// 		console.log("temp_allowed_keys", temp_allowed_keys);
	// 	}
	// 	set_allowed_keys(temp_allowed_keys);
	// }
	// useEffect(() => {
	// 	if(sku_category_type === 'cabinet_furniture'){
	// 		filter_allowed_keys(['cabinet_type', 'corner_unit_type', 'use_original_dimensions_for_pricing', 'ply_finish_display']);
	// 	}
	// 	else if(sku_category_type === 'wardrobe_furniture'){
	// 		filter_allowed_keys(['cabinet_type','use_original_dimensions_for_pricing']);
	// 	}
	// 	else {
	// 		set_allowed_keys(all_allowed_key);
	// 	}
	// },[])

	useEffect(() => {
		console.log('updated top view image --->> ', updated_top_view_image);
	}, [ updated_top_view_image ])

	useEffect(() => {
		if(open && selected_sku_properties){
			set_tabs(selected_sku_properties && (user.store_id == selected_sku_properties.store_id)?tabs:1)
		}
	}, [ open ,selected_sku_properties])

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};
	
	const onclick_handle_close_final = () => {
		onclick_close_complete_edittab()
		set_file('')
		set_tabs(1)
		set_updated_brand("none");
		set_updated_placement('none');
		set_updated_name('')
		set_sku_serial_no('')
		set_updated_model('')
		set_height(undefined)
		set_width(undefined)
		set_depth(undefined)
		// set_max_height(undefined)
		// set_max_width(undefined)
		// set_max_depth(undefined)
		// set_min_height(undefined)
		// set_min_width(undefined)
		// set_min_depth(undefined)
		// set_upload_missing_file('')
		set_addnew_chooseexisting_flag('add')
		set_show_Upload3d_modal_modal(false);
		set_show_add_new_material(false);
		set_show_choose_existing_material(false);
		set_return_confirm_low_resp_data('')
		// set_choose_material_details('')
		set_return_material_details('')
		set_sku_order(0)
		set_scale_value(undefined)
		set_material_mode('')
		set_additional_json_item([])
		set_additional_json_item_custom([])
		set_additional_json_item_default([])
		set_is_ap_open(false)
	}

	useEffect(() => {
		if(selected_sku_properties){
			set_is_owned(selected_sku_properties && user.store_id === selected_sku_properties.store_id?true:false)
			set_updated_name(selected_sku_properties.name?selected_sku_properties.name:'')
			set_sku_serial_no(selected_sku_properties.serial_number)
			set_updated_model(selected_sku_properties.model_no?selected_sku_properties.model_no:'')
			console.log("setting brand", selected_sku_properties.brand_id, brands[selected_sku_properties.brand_id])
			set_updated_brand(selected_sku_properties.brand_id?selected_sku_properties.brand_id:'none')
			if(selected_sku_properties.placement_id) {
				set_updated_placement(selected_sku_properties.placement_id)
			}
			set_updated_image(selected_sku_properties.display_pic ? selected_sku_properties.display_pic.image:"")
			if(is_allow_material){
				set_height(selected_sku_properties.height ? selected_sku_properties.height :undefined)
			}
			if(is_allow_material_and_depth){
				set_depth(selected_sku_properties.depth ? selected_sku_properties.depth :undefined)
			}
			set_width(selected_sku_properties.width ? selected_sku_properties.width :undefined)
			set_depth(selected_sku_properties.depth ? selected_sku_properties.depth :undefined)
			// set_max_height(selected_sku_properties.max_height ? selected_sku_properties.max_height :undefined)
			// set_max_width(selected_sku_properties.max_width ? selected_sku_properties.max_width :undefined)
			// set_max_depth(selected_sku_properties.max_depth ? selected_sku_properties.max_depth :undefined)
			// set_min_height(selected_sku_properties.min_height ? selected_sku_properties.min_height :undefined)
			// set_min_width(selected_sku_properties.min_width ? selected_sku_properties.min_width :undefined)
			// set_min_depth(selected_sku_properties.min_depth ? selected_sku_properties.min_depth :undefined)
			set_sku_order(selected_sku_properties.order ? selected_sku_properties.order :0)
			// set_tabs(selected_sku_properties && user.store_id == selected_sku_properties.store_id?tabs:4)
			if(is_allow_material || is_allow_material_and_depth) get_current_material_details()
			set_updated_top_view_image(selected_sku_properties.top_view_colour ? selected_sku_properties.top_view_colour.image:"")
			if(selected_sku_properties.component_file){
				var element = selected_sku_properties.component_file.find(o => o.type === "cabinet" || o.type === "corner_unit" || o.type === "obj_unit")
				if (element){
					console.log("height setting", element.data)
					set_height((element && element.data && element.data.height) ? element.data.height :undefined)
					set_width((element && element.data && element.data.width) ? element.data.width :undefined)
					set_depth((element && element.data && element.data.depth) ? element.data.depth :undefined)
					set_updated_placement((element && element.data && element.data.attached_plane_type) ? element.data.attached_plane_type :'none')
				}
			}
			if(selected_sku_properties.additional_properties){
				var x_default = [], x_custom=[], x_not_priced=[];
				
				selected_sku_properties.additional_properties.map(x_item => {
					// if(Object.keys(all_allowed_key).includes(x_item.key) && all_allowed_key[x_item.key].applicability && all_allowed_key[x_item.key].applicability.includes(sku_category_type)){
					if(Object.keys(all_allowed_key).includes(x_item.key)){
						x_default.push(x_item)
					}else if(x_item.key == 'no_pricing_enabled' || x_item.key == 'no_pricing_custom_text'){
						x_not_priced.push(x_item)
					}else{
						x_custom.push(x_item)
					}
				})
				
				set_additional_json_item_default(x_default)
				set_additional_json_item_custom(x_custom)
				set_additional_json_item_not_priced(x_not_priced)
			}
		}
	},[ selected_sku_properties , is_allow_material,is_allow_material_and_depth ])

	useEffect(() => {
		if(is_allow_material){

		}else{
			if(tabs==3 && modal_path){
				var x = window.document.getElementById('canvas_div')
				try{
					// if(modal_path && modal_path.low_model_3d && modal_path.low_model_3d.new_model){

						window.show3DModel(modal_path,'canvas_div', global.config.server_path.slice(0,-1));
					// }
					// if(modal_path && modal_path.low_model_3d && !modal_path.low_model_3d.new_model){
					// 	window.show_3DModel_for_obj(modal_path,'canvas_div', global.config.server_path.slice(0,-1));
					// }
				}catch(err){
					console.log(err)
				}
			}
		}
	},[tabs])

	useEffect(() => {
		if(modal_path){
			console.log(modal_path)
			set_scale_value(modal_path.low_model_3d?modal_path.low_model_3d.scale:'')
		}
	},[modal_path])

	const update_sku_properties = async (custom_bom_data) => {

		console.log("item_json=====>a", additional_json_item_default)
		if(additional_json_item_default && additional_json_item_default.length>1 && !is_valid_add_key(additional_json_item_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(additional_json_item_custom && additional_json_item_custom.length>1 && !is_valid_add_key(additional_json_item_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}
		else if(selected_sku_properties && updated_name ){
			try {
				var x_addi_prop = [];
				additional_json_item_default.map(x => {
					if(additional_json_item_default[0].key){
						x_addi_prop.push(x)
					}
				})
				additional_json_item_custom.map(x => {
					if (additional_json_item_custom[0].key) {
						x_addi_prop.push(x)
					}
				}) 
				
				if (custom_bom_data && Object.keys(custom_bom_data).length) {
					let edited = false;
					x_addi_prop.map(x => {
						if (x.key === 'custom_bom') {
							x.value = custom_bom_data;
							edited = true;
						}
					})
					if (!edited) {
						x_addi_prop.push({ key: "custom_bom", value: custom_bom_data })
					}
				}
				additional_json_item_not_priced.map(x => {
					if(additional_json_item_not_priced[0].key){
						x_addi_prop.push(x)
					}
				}) 

				selected_sku_properties && selected_sku_properties.component_file && selected_sku_properties.component_file.map(o =>{
					if(o.type === "cabinet" || o.type === "corner_unit" || o.type === "obj_unit"){
						o.data.width = Number(width);
						o.data.height = Number(height);
						o.data.depth = Number(depth);
						o.data.attached_plane_type = updated_placement;
					}
				})
				var brand_value=updated_brand==='none'?'':updated_brand;
				var identifiers = JSON.stringify({id : selected_sku_properties.id})
				// var updates = JSON.stringify({name : updated_name, order:sku_order , model_no : updated_model, placement_id :updated_placement , brand_id : brand_value, height:height?height:undefined , width:width?width:undefined , depth:depth?depth:undefined, max_height:max_height?max_height:undefined , max_width:max_width?max_width:undefined , max_depth:max_depth?max_depth:undefined, min_height:min_height?min_height:undefined , min_width:min_width?min_width:undefined , min_depth:min_depth?min_depth:undefined})
				if(is_allow_material){
					var updates = JSON.stringify({name : updated_name, order:sku_order , model_no : updated_model, placement_id :sku_category_type==='panel_core_hardware' ? (updated_placement==='none' ? null : updated_placement) : null , brand_id : brand_value, height:height?height:undefined, depth:depth?depth:undefined, width:width?width:undefined, additional_properties:JSON.stringify(x_addi_prop)})
				}else{
					var updates = JSON.stringify({name : updated_name, order:sku_order , model_no : updated_model, placement_id :updated_placement==='none'?null:updated_placement , brand_id : brand_value, component_file:selected_sku_properties.component_file, additional_properties:JSON.stringify(x_addi_prop)})
				}
				var body = {identifiers , updates}
				var reponse_for_update = await general_fetch({ url: 'sku/update' , body });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "properties", "sku_property_edited")

				alert.success("Sku Updated Successfully")
				fetch_sku_get_details()
			} catch(err) {
				alert.error("Failed to update sku")
				console.log(err);
			}
		}else{
			alert.error("Missing Details")
		}	
	}

	const onclick_update_sku_image = async () => {
		if( selected_sku_properties && file ){
			try {
				var fd = new FormData();
				var identifiers = JSON.stringify({id : selected_sku_properties.id})
				var updates = JSON.stringify({id : selected_sku_properties.id})
				fd.append('identifiers', identifiers)
				fd.append('updates', updates)
				file && fd.append('upl', file, file.name)
				var resp = await general_fetch({ url: 'sku/update', body:fd, is_form_data:true });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "display_image", "sku_property_edited")
				set_file('');
				fetch_sku_get_details();
			}catch(err) {
				console.log(err);
			}
		}else{
		}
	}

	const onclick_update_sku_top_view_image = async () => {
		if( selected_sku_properties && file ){
			try {
				// var fd = new FormData();
				// var identifiers = JSON.stringify({id : selected_sku_properties.id})
				// var updates = JSON.stringify({id : selected_sku_properties.id})
				// fd.append('identifiers', identifiers)
				// fd.append('updates', updates)
				// file && fd.append('upl', file, file.name)
				var resp = await window.upload_image({ type: 'colour', sku_id: selected_sku_properties.id, upl: file });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "top_view_image", "sku_property_edited")
				set_file('');
				fetch_sku_get_details();
			}catch(err) {
				console.log(err);
			}
		}else{
		}
	}
	
	const onClick_choose_existing_material = () => {
		open_choose_existing_material();
		set_return_material_details('')
	}
	
	const onclick_restore_template_values = () => {
        get_current_material_details()
        // set_material_mode('edit')
    }

	// const on_click_upload_missing_images = async () => {
	// 	if( upload_missing_file && modal_path ){
	// 		try {
	// 			var fd = new FormData();
	// 			fd.append('upl', upload_missing_file)
	// 			fd.append('low_model_3d_id', modal_path.low_model_3d && modal_path.low_model_3d.id?modal_path.low_model_3d.id:"");
	// 			fd.append('high_model_3d_id', modal_path.low_model_3d && modal_path.low_model_3d.id?modal_path.low_model_3d.id:"");
	// 			set_page_loader({
	// 				show:true,
	// 				text:'Uploading missing images'
	// 			})
	// 			var resp = await general_fetch({ url: 'file_transfer/upload_missing_images', body:fd, is_form_data:true });
	// 			get_missing_images();
	// 			set_page_loader({
	// 				show:false,
	// 				text:''
	// 			})
	// 			alert.success('SKU updated successfully')
	// 			set_upload_missing_file('')
	// 		}catch(err) {
	// 			console.log(err);
	// 			alert.error('Error , Not able to update SKU')
	// 		}
	// 	}else{
	// 		alert.error('upload correct file or .obj, .mtl files missing ')
	// 	}
	// }

	const onclick_set_scale = async() => {
		if(scale_value && modal_path && modal_path.low_model_3d ){
			try{
				set_page_loader({
					show:true,
					text:'Updating SKU Scale'
				})
				// var updates = JSON.stringify({scale:scale_value})
				var resp_low = await general_fetch({ url: 'model/update_asset', body:{model_id : modal_path.low_model_3d.id,scale:scale_value}});
				// var resp_high = await general_fetch({ url: 'model/update_parameters', body:{identifiers:JSON.stringify({id : modal_path.high_model_3d.id}) , updates, type:'high'}})				
				alert.success('SKU Scale updated successfully')
				set_scale_value(undefined)
				set_page_loader({
					show:false,
					text:''
				})
			}catch(err){
				console.log(err)
				set_page_loader({
					show:true,
					text:'Please refresh page'
				})
				alert.error('SKU Scale failed')
			}
		}else{
			alert.info('Please Enter scale value.')
		}
	}

	const update_sku_tag_assigned = async (selected_tag_array) => {
		if(selected_sku_properties && sku_tag_selected_id ){
			try {
				var tag_array = selected_tag_array.map(x => x.id)
				var body = {ids: [selected_sku_properties.id] , tag_ids: tag_array}
				set_page_loader({
					show:true,
					text:'Adding tag please wait ...'
				})
				var response_for_add_tag = await general_fetch({ url: 'sku/attach_tags' , body});
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "tags", "sku_property_edited")

				set_page_loader({
					show:false,
					text:''
				})
				fetch_sku_tags();
				set_sku_tag_selected_id("none")
				alert.success('Tags updated successfully')
			} catch(err) {
				set_page_loader({
					show:false,
					text:''
				})
				alert.error('Error in adding tags')
				console.log(err);
			}
		}else{
			alert.error('No Tags Selected')
		}
	}

	const update_sku_tag_detached = async (selected_tag_array) => {
		try {
			var tag_array = selected_tag_array.map(x => x.id)
			var body = {sku_id: selected_sku_properties.id , tag_id: tag_array}
			set_page_loader({
				show:true,
				text:'Removing tag please wait ...'
			})
			var response_for_del_sale = await general_fetch({ url: 'sku/attach_tags' , body});
			set_page_loader({
				show:false,
				text:''
			})
			alert.success('Tags successfully removed')
			fetch_sku_tags()
		} catch(err) {
			alert.error('Error in removing tags')
			console.log(err);
		}
	}

// 	identifiers: {"id":"e096d1057223f3ca"}
// updates: {"material_id":"56e6a657b1140046"}
// store_id: d5f46a1e6952e309

	const onclick_update_for_update_sku = async(return_material_details) => {
		try {
			if(return_material_details){
				var body = {identifiers:JSON.stringify({id:selected_sku_properties.id}), updates:JSON.stringify({material_id:return_material_details.id})}
				set_page_loader({
					show:true,
					text:'Updating materials ...'
				})
				var response_for_del_sale = await general_fetch({ url: 'sku/update' , body});
				set_page_loader({
					show:false,
					text:''
				})
				alert.success('Materials attatched to SKU successfully')
				fetch_sku_get_details()
			}
		} catch(err) {
			set_page_loader({
				show:false,
				text:''
			})
			alert.error('Error in updating materials')
			console.log(err);
		}
	}

	useEffect(() =>{
		if(return_confirm_low_resp_data){
			alert.success('Model files loaded successfully')
		}
	},[return_confirm_low_resp_data])

	const onClick_submit_3dmodel = async (id) => {
		if( id && selected_sku_properties){
			try {
				// set_page_loader({
				// 	show:true,
				// 	text:'Updating SKU with model'
				// })
				var identifiers = JSON.stringify({id : selected_sku_properties.id})
				var updates = JSON.stringify({low_model_3d_id:id})
				var resp = await general_fetch({ url: 'sku/update', body:{identifiers , updates} });
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "3D_model", "sku_property_edited")
				return_confirm_low_resp_data && take_sku_snapshot(return_confirm_low_resp_data.path, return_confirm_low_resp_data.mtl_path, global.config.server_path, return_confirm_low_resp_data.glb_src, return_confirm_low_resp_data.scale, selected_sku_properties.id)
				fetch_sku_get_details();
				onclick_handle_close_final()
				alert.success('SKU updated successfully')
				set_page_loader({
					show:false,
					text:''
				})
			}catch(err) {
				console.log(err);
				alert.error('Error , Not able to add SKU. Please refresh and upload model files again.')
				set_page_loader({
					show:true,
					text:'Please refresh'
				})
			}
		}else{
			alert.error('Please refresh and upload model files again')
		}
	}

	const close_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(false)
	}
	const open_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(true)
	}

	const close_add_new_material = () =>{
		set_show_add_new_material(false)
		set_material_mode('')
	}
	const open_add_new_material = () =>{
		set_show_add_new_material(true)
		// set_choose_material_details('')
		set_material_mode('add')
	}

	const close_choose_existing_material = () =>{
		set_show_choose_existing_material(false)
	}
	const open_choose_existing_material = () =>{
		set_show_choose_existing_material(true)
	}

	const get_current_material_details = async() => {
		try{
			//loader
			var identifiers = JSON.stringify({id:selected_sku_properties.material_id})
			var attributes = JSON.stringify({})
			var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
			set_active_material_template(response && response.length ?response[0]:'')
			console.log("get material", response)
			let texture_id = response[0].properties.map
			
			var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[texture_id]})}})
        	if(texture_get) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
		}catch(err){
			console.log(err)
		}
	}

	const get_return_material_details = async(id) => {
		try{
			//loader
			var identifiers = JSON.stringify({id:id})
			var attributes = JSON.stringify({})
			var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
			set_return_material_details(response && response.length ?response[0]:[])
			let texture_id = response[0].properties.map
			var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[texture_id]})}})
        	if(texture_get) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
			console.log("material_mode",material_mode)
            if(material_mode == 'add'){
				onclick_update_for_update_sku(response && response.length ?response[0]:[])
				set_material_mode('edit')
			}
		}catch(err){
			console.log(err)
		}
	}

	const onclick_update_material = async () => {
        console.log("Updated template", active_material_template)
		if(active_material_template){
			try {
                console.log("properties",properties)
                let bp = properties && properties.length && properties.reduce((final, elem)=> {
                    return {
                        ...final, [elem.label]: elem.final_value
                    }
                }, {});
                console.log("bp",bp)
                console.log('active_material_template',active_material_template);
                var body={
                    name:'material', 
                    properties:JSON.stringify(bp), 
                    material_template_id: active_material_template.material_template.id
                }
                let material_id = null;
				if(material_mode == 'add'){
					let resp = await general_fetch({ url: 'material/add', body });
					material_id = resp ? resp.id :''
				}else{
					material_id = active_material_template.id
					body['id']= material_id
					let resp = await general_fetch({ url: 'material/update', body });
				}
				send_analytics_event('track', user.id, selected_sku_properties.name, selected_sku_properties.id, sku_category_type, "material", "sku_property_edited")
                console.log('material_id ===> ', material_id);
                get_return_material_details(material_id)
				set_editing_current_material(false)
				alert.success("Material details Updated successfully !")
			}catch(err) {
				console.log(err);
				alert.error('Error , Unable to Update Material')
			}
		}else{
			alert.error('Please fill all required details')
		}
	}

	const onchange_material_template = (id) => {
        if(id){
            var selected_template = material_template && material_template.find( x => x.id===id )
            console.log('setting updated template ---> ', id, selected_template, active_material_template);
            // set_updated_template(selected_template?selected_template:'')
            // set_material_mode('update_template')
            if(active_material_template){
				set_active_material_template(selected_template? { ...active_material_template, material_template: selected_template } : { ...active_material_template, material_template: null });
			}	
			else{
				if(selected_template){
					let props = selected_template.properties
					let temp_props = props && props.length && props.reduce((final, elem)=> {
						return {
							...final, [elem.label]: elem.value.value
						}
					}, {});
					set_active_material_template({...selected_template, properties:temp_props, material_template:selected_template})
					set_material_mode('add')
					console.log("selected_template active ",{...selected_template, properties:temp_props, material_template:selected_template})
				}
			}
        }
    }

	useEffect(() => {
		if(materials_json && current_material_details){
			console.log('current_material_details=>',current_material_details)

		}
	},[current_material_details])

	const onClick_edit_current_material = () => {
		set_show_add_new_material(true)
		set_material_mode('edit')
	} 

	useEffect(() => {
		if(active_material_template){
			var x=active_material_template.material_template ? active_material_template.material_template:{};
			x.properties = x.properties && x.properties.length ? x.properties.map( property => {
				return {...property, final_value:active_material_template.properties?active_material_template.properties[property.label]:null, wrong_value_flag:false}
			}):[]
			set_properties(x.properties)
		}
	}, [active_material_template]);

	useEffect(() => {
		if(is_ap_open){
			let t = myRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [is_ap_open]);

	
	return(
		<Card className=''>
			{/* <Add_new_material 
				set_return_material_details={set_return_material_details} 
				get_current_material_details={get_current_material_details} 
				active_material_template={active_material_template} 
				material_mode={material_mode} 
				set_material_mode={set_material_mode} 
				return_material_details={return_material_details} 
				open={show_add_new_material} 
				handle_close={close_add_new_material} 
				fetch_material_template={fetch_material_template} 
				material_template={material_template} 
				texture_json={texture_json} 
				set_page_loader={set_page_loader}
			/> */}
			<Choose_existing_material open={show_choose_existing_material} handle_close={close_choose_existing_material} is_material={true} set_return_texture_details={set_return_material_details} texture_json={materials_json}/>
			<Upload3d_modal_modal 
				open={show_Upload3d_modal_modal} 
				handleclose={close_Upload3d_modal_modal} 
				set_return_confirm_low_resp_data={set_return_confirm_low_resp_data}
				return_confirm_low_resp_data={return_confirm_low_resp_data}
				active_division_id={active_division_id}
				set_page_loader={set_page_loader}
				onClick_submit_3dmodel={onClick_submit_3dmodel}
				edit_flag={true}
			/>
			{/* <Row>
				<Col className = "text-Left">
					<span style={{fontFamily: 'Source_Sans_Pro-SemiBold',fontSize: '16px', color: '#234361', letterSpacing: '-0.04px'}}>Details for {selected_sku_properties && selected_sku_properties.name ? selected_sku_properties.name :''}</span>
				</Col>	
			</Row> */}
			<CardHeader className='global_modal_header'>
				<span>
					SKU Properties of <span styles={{textTransform: 'capitalize'}}>{selected_sku_properties && selected_sku_properties.name ? selected_sku_properties.name :''}</span>
				</span>
				<i style={{cursor:'pointer'}} id='close_edit_sku_properties_modal' onClick={onclick_close_complete_edittab} className='fa fa-times'/>
			</CardHeader>
					
			<div className="nav-wrapper" style={{padding:'10px 15px'}}>
				<Nav 
					style={{display:'flex', alignItems:'center'}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>
					<NavItem>
						<NavLink
							aria-selected={tabs === 1}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 1
							})}
							onClick={e => toggleNavs(e, 1)}
							href="#pablo"
							role="tab"
						>
							<span>Properties</span>
						</NavLink>
					</NavItem>
					{
						selected_sku_properties && user.store_id === selected_sku_properties.store_id && !is_allow_material?
							<NavItem>
								<NavLink
									aria-selected={tabs === 2}
									className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
									})}
									onClick={e => toggleNavs(e, 2)}
									href="#pablo"
									role="tab"
								>
									<span>Display Image</span>
								</NavLink>
							</NavItem>
						:""
					}
					{
						selected_sku_properties && user.store_id === selected_sku_properties.store_id?
						<NavItem>
							<NavLink
								aria-selected={tabs === 3}
								className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 3
								})}
								onClick={e => toggleNavs(e , 3)}
								href="#pablo"
								role="tab"
							>
								<span>{is_allow_material?(sku_category_type==='panel_core_hardware' ?'Material / Display Image':'Material'):'3D Model'}</span>
							</NavLink>
						</NavItem>
						:''
					}
					<NavItem>
						<NavLink
							aria-selected={tabs === 4}
							className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 4
							})}
							onClick={e => toggleNavs(e , 4)}
							href="#pablo"
							role="tab"
						>
							<span id='edit_sku_tags_nav_item'>Tags</span>
						</NavLink>
					</NavItem>

					<NavItem>
						<NavLink
							aria-selected={tabs === 5}
							className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 5
							})}
							onClick={e => toggleNavs(e , 5)}
							href="#pablo"
							role="tab"
						>
							<span id='edit_sku_sales_channels_nav_item'>{"Pricing(Sales Channel)"}</span>
						</NavLink>
					</NavItem>
					{
						selected_sku_properties && user.store_id === selected_sku_properties.store_id?
							<NavItem>
								<NavLink
									aria-selected={tabs === 6}
									className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 6
									})}
									onClick={e => toggleNavs(e, 6)}
									href="#pablo"
									role="tab"
								>
									<span>Top View Image</span>
								</NavLink>
							</NavItem>
						:""
					}
					{
						selected_sku_properties && user.store_id === selected_sku_properties.store_id && (['hardware','accessory','finish'].includes(active_division_id) || (selected_sku_properties.additional_properties && selected_sku_properties.additional_properties.length && selected_sku_properties.additional_properties.filter(x => x.key == 'cabinet_type' && x.value == 'smart_cube').length))?
						<NavItem>
							<NavLink
								aria-selected={tabs === 7}
								className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 7
								})}
								onClick={e => toggleNavs(e , 7)}
								href="#pablo"
								role="tab"
							>
								<span>Linked SKUs</span>
							</NavLink>
						</NavItem>
						:''
					}
					{
						sku_category_type == 'custom_furniture_furniture' ?
						<NavItem>
							<NavLink
								aria-selected={tabs === 8}
								className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 8
								})}
								onClick={e => toggleNavs(e , 8)}
								href="#pablo"
								role="tab"
							>
								<span>Manage Custom BOM</span>
							</NavLink>
						</NavItem>
						:''
					}
					{
						sku_category_type == 'cabinet_furniture' || sku_category_type == 'wardrobe_furniture' ?
						<NavItem>
							<NavLink
								aria-selected={tabs === 9}
								className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 9
								})}
								onClick={e => toggleNavs(e , 9)}
								href="#pablo"
								role="tab"
							>
								<span>Linked Rules</span>
							</NavLink>
						</NavItem>
						:''
					}
				</Nav>
			</div>
			<TabContent activeTab={"tabs" + tabs}>

				<TabPane tabId="tabs1" style={{fontSize:'12px'}}>
					<CardBody className='global_modal_body'>	
						<Row>
							<Col>
								<FormGroup>
									<label className="label_margin_5px">Name *</label>
									<Input id='sku_name_input' className='input_box' type="text" value ={updated_name} 
										onChange = {is_owned?(e) => set_updated_name(e.target.value):null} 
										disabled={is_owned?false:true}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup >
									<label className="label_margin_5px">Model No. / SKU Code</label>
									<Input id='model_no_input' className='input_box' type="text" value={updated_model?updated_model:''} 
										onChange = {is_owned?(e) => set_updated_model(e.target.value):null} 
										disabled={is_owned?false:true}
									/>
								</FormGroup>
							</Col>	
						</Row>
						<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
						<Row>
							<Col xs={6}>
								<FormGroup>
									<label className="label_margin_5px">Viewing Order</label>
									<Input id='sku_order_input' className='input_box' type="number" value ={sku_order} 
										onChange = {is_owned?(e) => set_sku_order(Number(e.target.value ? e.target.value : 0)):null} 
										disabled={is_owned?false:true}
									/>
								</FormGroup>
							</Col>
							<Col xs={6}>
								<FormGroup>
									<label className="label_margin_5px">Serial No.</label>
									<Input className='input_box' type="number" value ={sku_serial_no} 
										disabled
									/>
								</FormGroup>
							</Col>
						</Row>
						{
							is_allow_material?(
								<div>
									<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
									<Row>
										<Col>
											<div style={{fontFamily: 'Source_Sans_Pro-SemiBold',fontSize: '14px', color: '#234361', letterSpacing: '-0.04px'}}>Product Dimensions (mm)</div>
										</Col>	
									</Row>
									{/* <Row style={{marginTop:"5px"}}>
										<Col>
											<FormGroup >
												<label className="label_margin_0px"  >Depth</label>
												<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
											</FormGroup>
										</Col>
									</Row> */}
									<Row style={{marginTop:"5px"}}>
										<Col>
											<FormGroup >
												<label className="label_margin_0px" style={{marginBottom:'5px'}} >Thickness</label>
												<Input className='input_box' type="number" placeolder='0' value={height?height:undefined} 
													onChange = {is_owned?(e) => set_height(e.target.value):''}
													disabled={is_owned?false:true} id='sku_height_input'
												/>
											</FormGroup>
										</Col>
										{ 
											is_allow_material_and_depth?
											<Col>
												<FormGroup >
													<label className="label_margin_0px" style={{marginBottom:'5px'}} >{sku_category_type==='edge_band_hardware'?'Tape Width':'Height'}</label>
													<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} 
														onChange = {is_owned?(e) => set_depth(e.target.value):null} 
														disabled={is_owned?false:true} id='sku_depth_input'
													/>
												</FormGroup>
											</Col>
											:''
										}
									</Row>
									{
										sku_category_type == 'tile_finish' ?
										<Row style={{marginTop:'16px'}}>
											<Col>
												<FormGroup>
													<label className='label_margin_0px' style={{marginBottom:'5px'}}>Width</label>
													<Input className='input_box' type="number" placeolder='0' value={width?width:undefined}
														onChange = {is_owned?(e) => set_width(e.target.value):''}
														disabled={is_owned?false:true} id='sku_width_input'
													/>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<label className='label_margin_0px' style={{marginBottom:'5px'}}>Height</label>
													<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined}
														onChange = {is_owned?(e) => set_depth(e.target.value):''}
														disabled={is_owned?false:true} id='sku_depth_input'
													/>
												</FormGroup>
											</Col>
										</Row>:''
									}
								</div>
							):(
								<React.Fragment>
								{
									selected_sku_properties && selected_sku_properties.component_file?
									<div>
										<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
										<Row>
											<Col>
												<div style={{fontFamily: 'Source_Sans_Pro-SemiBold',fontSize: '14px', color: '#234361', letterSpacing: '-0.04px'}}>Product Dimensions (mm)</div>
											</Col>	
										</Row>
										<Row style={{marginTop:"5px"}}>
											<Col>
												<FormGroup >
													<label className="label_margin_0px">{sku_category_type === 'shutter_hardware' ? 'Thickness' : 'Height'}</label>
													<Input className='input_box' type="number" placeolder='0' value={height?height:undefined} 
														onChange = {is_owned?(e) => set_height(e.target.value):null} 
														disabled={is_owned?false:true} id='sku_height_input'
													/>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<label className="label_margin_0px">Width</label>
													<Input className='input_box' type="number" placeolder='0' value ={width?width:undefined} 
														onChange = {is_owned?(e) => set_width(e.target.value):null}
														disabled={is_owned?false:true} id='sku_width_input'
													/>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup >
													<label className="label_margin_0px">{sku_category_type === 'shutter_hardware' ? 'Height' : 'Depth'}</label>
													<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} 
														onChange = {is_owned?(e) => set_depth(e.target.value):null} 
														disabled={is_owned?false:true} id='sku_depth_input'
													/>
												</FormGroup>
											</Col>
										</Row>
										{/* <Row style={{marginTop:"5px"}}>
												<Col>
													<FormGroup>
														<label className="label_margin_0px"  >Min-width</label>
														<Input className='input_box' type="number" value ={min_width?min_width:undefined} onChange = {(e) => set_min_width(e.target.value) } />
													</FormGroup>
												</Col>
												<Col>
													<FormGroup >
														<label className="label_margin_0px"  >Min-depth</label>
														<Input className='input_box' type="number" value={min_depth?min_depth:undefined} onChange = {(e) => set_min_depth(e.target.value) } />
													</FormGroup>
												</Col>
												<Col>
													<FormGroup >
														<label className="label_margin_0px"   >Min-height</label>
														<Input className='input_box' type="number" value={min_height?min_height:undefined} onChange = {(e) => set_min_height(e.target.value) } />
													</FormGroup>
												</Col>
											</Row> */}
											{/* <Row style={{marginTop:"5px"}}>
												<Col>
													<FormGroup>
														<label className="label_margin_0px"  >Max-width</label>
														<Input className='input_box' type="number" value ={max_width?max_width:undefined} onChange = {(e) => set_max_width(e.target.value) } />
													</FormGroup>
												</Col>
												<Col>
													<FormGroup >
														<label className="label_margin_0px"  >Max-depth</label>
														<Input className='input_box' type="number" value={max_depth?max_depth:undefined} onChange = {(e) => set_max_depth(e.target.value) } />
													</FormGroup>
												</Col>
												<Col>
													<FormGroup >
														<label className="label_margin_0px"   >Max-height</label>
														<Input className='input_box' type="number" value={max_height?max_height:undefined} onChange = {(e) => set_max_height(e.target.value) } />
													</FormGroup>
												</Col>
											</Row> */}
									</div>
									:''
								}
								</React.Fragment>
							)
						}
						<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
						<Row>
							{
								active_division_id==='finish'?'':
								<Col>
									<FormGroup >
										<label className="label_margin_5px"   >Placement</label>
										<Input
											className='edit_properties_dropdown' 
											type='select' id='sku_placement_input'
											variant="outlined"
											value={updated_placement}
											onChange={is_owned?(e) => set_updated_placement(e.target.value):null}
											disabled={is_owned?false:true}
										>
											<option value='none'>Select</option>
											<option value='base'>Base</option>
                                            <option value='base_corner'>Base Corner</option>
                                            <option value='wall'>Wall</option>
                                            <option value='wall_corner'>Corner Wall</option>
                                            <option value='ceiling'>Ceiling</option>
                                            <option value='default'>Default</option>
										</Input>
									</FormGroup>
								</Col>
							}
							<Col>
								<FormGroup>
									<label className="label_margin_5px"  >Brand</label>
									<Input 
										className='edit_properties_dropdown'
										type='select' id='sku_brand_input'
										variant="outlined"
										value={updated_brand}
										onChange={is_owned?(e) => set_updated_brand(e.target.value):null}
										disabled={is_owned?false:true}
									>
											<option value="none">None</option>
										{
											brands.map((list_value,idx)=>
												<option key={idx} value={list_value.id}>{list_value.name}</option>
											)	
										}
									</Input>
								</FormGroup>
							</Col>	
						</Row>
						{/* Keyvalue pair */}
						<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
						{
						// is_owned?
							<div>
								<div style={{fontWeight:600, cursor:'pointer'}} onClick={()=>set_is_ap_open(!is_ap_open)}>
									<span style={{marginRight:'15px',fontSize:'16px'}}>Additional Properties</span>
									<i className={is_ap_open?'fa fa-caret-up':'fa fa-caret-down'}/>
								</div>
								{
									is_ap_open?
									<div style={{display:is_ap_open?'block':'none', pointerEvents:is_owned?'auto':'none'}} ref={myRef}>
										<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key} sku_category_type = {sku_category_type} disable_input={!is_owned}/>
										<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom' all_allowed_key={all_allowed_key} sku_category_type = {sku_category_type} disable_input={!is_owned}/>
									</div>
									:''
								}
							</div>
							// :''
						}
					</CardBody>
					<CardFooter className='global_modal_footer'>									
						<Button className='white_button' onClick={onclick_handle_close_final}> Cancel </Button>
						<Button className='blue_button'  onClick = {() => update_sku_properties()}>Update Details</Button>
					</CardFooter>
				</TabPane>

				<TabPane tabId="tabs2">
					<CardBody className='global_modal_body'>
						<Row>
							<Col style ={{textAlign: "center", height:'300px'}}>
								<img style={{maxHeight:"100%", maxWidth: "100%"}} src={updated_image ? build_path(global.config.server_path , updated_image) : global.config.image_not_found} />	
							</Col>	
						</Row><br/>
							
						<Row>
							<Col>
								<FormGroup>
									<label className="label_margin_5px" >Update Image</label><br />
									<UploadFile id="id_upload_file" set_file={set_file} accept='.jpg,.png,.jpeg' />	
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<label className="label_margin_5px" style={{color:"orange"}} >{file ? file.name : "No file selected"}</label>
							</Col>
						</Row>	
					</CardBody>
					<CardFooter className='global_modal_footer'>									
						<Button className='white_button' onClick={onclick_handle_close_final}> Cancel </Button>
						<Button className='blue_button' onClick = {onclick_update_sku_image}> Update Image </Button>
					</CardFooter>
				</TabPane>

				<TabPane style={{height:'100%', width:'100%'}} tabId="tabs3">
					<CardBody className='global_modal_body'>
					{
						is_allow_material?(	
							// <Row>
							// 	<Col xs={3} style={{textAlign:'center'}}/>
							// 	<Col xs={6} style={{textAlign:'center'}}>
							// 		<Row style={{marginBottom:'10px'}}>
							// 			<Col>
							// 				<span style={{fontSize:'18px', fontWeight:'500'}}>Edit Current Material</span>
							// 			</Col>
							// 		</Row>
							// 		<Row style={{marginBottom:'10px'}}>
							// 			<Col xs={2}></Col>
							// 			<Col xs={8}>
							// 				<div style={{border:'1px solid #ddd', padding:'4px'}}>
							// 					<div style={{height:'140px', display:'flex', alignItems:'center',justifyContent:'center'}}>
							// 						<img style={{maxHeight:"100%", maxWidth: "100%"}} src={updated_image ?  build_path(global.config.server_path , updated_image) : global.config.image_not_found} />
							// 					</div>
							// 					<div style={{padding:'5px', backgroundColor:'#F7F7F7',display:'flex', justifyContent:'space-between', alignItems:'center'}}>
							// 						<span style={{fontSize:'14px', textTransform:'capitalize'}}>{active_material_template && active_material_template.material_template && active_material_template.material_template.blend_material?active_material_template.material_template.blend_material:''}</span>
							// 						<span onClick={onClick_edit_current_material} style={{cursor:'pointer'}} ><i className='fa fa-cog faa-spin animated-hover'/></span>
													
							// 					</div>
							// 				</div>
											
							// 			</Col>
							// 			<Col xs={2}></Col>
							// 		</Row>
							// 	</Col>
							// 	<Col xs={3} style={{textAlign:'center'}}/>
							// 	{/* <Col xs={6} style={{textAlign:'center'}}>
							// 		<Row style={{marginBottom:'20px'}}>
							// 			<Col>
							// 				<span style={{fontSize:'18px', fontWeight:'500'}}>Replace Current Material</span>
							// 			</Col>
							// 		</Row>
							// 		<Row style={{marginBottom:'20px'}}>
							// 			<Col xs={2}></Col>
							// 			<Col xs={8}>
							// 				<Input style={{fontSize:'13px'}} value={addnew_chooseexisting_flag} onChange={(e) => set_addnew_chooseexisting_flag(e.target.value)} type='select'>
							// 					<option value='add'>Add new Material</option>
							// 					<option value='choose' disabled>Choose Existing Material</option>
							// 				</Input>
							// 			</Col>
							// 			<Col xs={2}></Col>
							// 		</Row>
							// 		<Row style={{marginBottom:'10px'}}>
							// 			<Col>
							// 				{ 
							// 					addnew_chooseexisting_flag==='add'?
							// 					<Button onClick={open_add_new_material}  className='blue_button'>Add new Material</Button>
							// 					:
							// 					<Button onClick={onClick_choose_existing_material}  className='blue_button'>Choose Existing Material</Button>	
							// 				}
							// 			</Col>
							// 		</Row>
							// 		{
							// 			return_material_details ?
							// 			<Row style={{marginBottom:'3px', marginTop:'30px'}}>
							// 				<Col style={{textAlign:"center"}}>
							// 					<span><b>New Material Name</b> : {return_material_details.name}</span>
							// 				</Col>
							// 			</Row>
							// 			:''
							// 		}
							// 		{
							// 			return_material_details ?
							// 			<Row style={{marginBottom:'3px'}}>
							// 				<Col style={{textAlign:"center", color:'green'}}>
							// 					Click on <b>Save Changes</b> to confirm changes.
							// 				</Col>
							// 			</Row>
							// 			:''
							// 		}
							// 		{
							// 			return_material_details ?
							// 			<Row style={{marginBottom:'3px'}}>
							// 				<Col style={{textAlign:"center", color:'green'}}>
							// 					<Button disabled={return_material_details?false:true} onClick={return_material_details?()=> onclick_update_for_update_sku():null} className='blue_button'>Save Changes</Button>
							// 				</Col>
							// 			</Row>
							// 			:''
							// 		}
							// 	</Col> */}
							// </Row>
							<>
								<div className='flex_property' style={{justifyContent:'space-between'}}>
									<div style={{fontSize:'16px'}}>Material Properties</div>
									{
										editing_current_material?'':
										<div onClick={() => set_editing_current_material(true)} className='flex_property div_box' style={{cursor:'pointer', fontSize:'14px', width:'180px', height:'30px'}}>
											<i style={{marginRight:'10px'}} className='fa fa-pencil-alt'/><span>Edit current material</span>
										</div>
									}
								</div>
								<div className='div_box' style={{width:'1160px', height:editing_current_material?'338px':'380px', marginTop:'10px', borderBottom:editing_current_material?'1px solid white':'1px solid #C5C7CF'}}>
									<div style={{flex:'1', display:'flex', flexDirection:'column', padding:'5px 20px 5px 5px'}}>
										<div style={{fontSize:'14px', marginBottom:'5px'}}>Material Preview</div>
										<img width='265px' height='200px' style={{marginBottom:'20px'}} src={base_texture_url ?  build_path(global.config.server_path , base_texture_url) : '/assets/img/icons/empty_image.png'} />
										{/* <div style={{fontSize:'14px', marginBottom:'5px'}}>Material Name</div>
										<Input  type='text' style={{height:'32px',pointerEvents:editing_current_material?'auto':'none'}}/> */}
									</div>
									<div style={{borderLeft:'1px solid #E6E9EE', height:editing_current_material?'320px':'340px', marginTop:'10px'}} />
									<div style={{flex:'3', padding:'10px 20px 20px 20px', overflow:'auto'}}>
										<div style={{fontSize:'14px', marginBottom:'5px'}}>Select Type of Material Template</div>
										<div style={{width:'50%',  pointerEvents:editing_current_material?'auto':'none' }}>
											<SingleSelect 
												options={material_template ? material_template :[]}
												selectedValue = {active_material_template && active_material_template.material_template ? active_material_template.material_template :null}
												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
												placeholder = {"Select Material Type"}
												displayValue = {'name'}
											/>
										</div>
										{
											properties && properties.length && properties.find(o => o.label === 'map') ? 
											<Property_Tab 
												key={'map'} 
												property={properties.find(o => o.label === 'map')} 
												properties={properties} 
												set_properties={set_properties}
												set_page_loader={set_page_loader}
												texture_json={texture_json}
												input_size = 'small'
												set_base_texture_url = {set_base_texture_url}
												disabled_input = {!editing_current_material}
												// final_update_fn={final_update_fn}
											/>:'' 
										}
										<div className='flex_property'>
											<span onClick={() => set_advanced_parameters_collapse(!advanced_parameters_collapse)} style={{marginRight:'10px', cursor:'pointer', fontSize:'14px', marginTop:'10px'}}><span style={{}}>Advanced Parameters</span> <i style = {{marginRight:'10px', marginLeft:'10px'}} className={advanced_parameters_collapse ? 'fa fa-angle-down' : 'fa fa-angle-up'}/></span>
											<hr style={{width:'624px', marginTop:'28px'}}/>
										</div>
										<div>
										{
											!advanced_parameters_collapse?
											<div>
											{
												properties && properties.length ? properties.map((property, idx) =>{
                                                    if(property.label != 'map'){
                                                        return(
                                                            <Property_Tab 
                                                                key={idx} 
                                                                property={property} 
                                                                properties={properties} 
                                                                set_properties={set_properties}
                                                                set_page_loader={set_page_loader}
                                                                texture_json={texture_json}
                                                                input_size = 'small'
                                                                set_base_texture_url = {set_base_texture_url}
																disabled_input = {!editing_current_material}
                                                                // final_update_fn={final_update_fn}
                                                            />
                                                        )
                                                    }
                                                } 
												)  
												:''
											}
											</div>:''
										}
										</div>
									</div>
								</div>
								{
									editing_current_material?
									<div className='flex_property' style={{width:'1160px', backgroundColor:'white', height:'48px', border:'1px solid #C5C7CF', borderTop:' 1px solid #E6E9EE', justifyContent:'flex-end'}}>
										{/* <hr style={{selfAlign:'center', width:'80%'}} /> */}
										<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
											<span onClick={onclick_restore_template_values} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer', border:'1px solid #C5C7CF', borderRadius:'4px', justifyContent:'center', fontSize:'13px', marginRight:'10px'}}>Reset Changes</span>
											<span onClick={()=>{onclick_update_material()}} className='flex_property' style={{width:'100px', height:'32px', cursor:'pointer',  border:'1px solid #C5C7CF', borderRadius:'4px', backgroundColor:'#0078FF',justifyContent:'center', fontSize:'13px', color:'white', marginRight:'10px'}}>Save Changes</span>
										</div>
									</div>:''
								}
							</>
						):(
							<div>			
								<Row style={{height:'100%', width:'100%', margin:0}}>
									<Col id='canvas_div' xs={6} style ={{textAlign: "center", backgroundColor:'white', height:'300px', width:'100%'}}>
										{
											modal_path?'':
											<span style={{display:modal_path===''?'flex':'none', height:"100%", width:'100%', justifyContent:"center", alignItems:'center'}}><span>Please add model files.</span></span>
										}
									</Col>
									<div id='canvas_div_2' xs={6} style ={{textAlign: "center", backgroundColor:'white', height:'300px', width:'100%', display: 'none'}}/>
									<Col xs={6} style={{padding:'0px 15px'}}>
										<Row style={{margin:0}}>
											<Col style ={{display:'flex', alignItems:'center', justifyContent:"flex-start", marginBottom:'20px', padding:0}}>
												<Button disabled={modal_path===''?false:true} className='blue_button' onClick={modal_path===''?open_Upload3d_modal_modal:null} style={{marginRight:"15px"}}>Add Model</Button>
												<Button disabled={modal_path===''?true:false} className='blue_button' onClick={modal_path===''?null:open_Upload3d_modal_modal} style={{marginRight:"15px"}}>Change Model</Button>
											</Col>	
										</Row>
										{
											modal_path===''?'':
											<div>
												<Row style={{margin:0}}>
													<Col style ={{textAlign: "center",  display:'flex', alignItems:'center', marginBottom:'20px', padding:0}}>
														<span style={{flex:2, textAlign:'left'}}>Scale</span>
														<span style={{flex:8}}><Input value={scale_value} onChange={(e) => set_scale_value(e.target.value)} type='number'/></span>
														<span style={{flex:2, textAlign:'right'}}><Button onClick={onclick_set_scale} className='blue_button' style={{minWidth:'auto'}}>Set</Button></span>
													</Col>	
												</Row>
											</div>
										} 
									</Col>	
								</Row>
							</div>
						)
					}
					</CardBody>
					<CardFooter className="global_modal_footer">
						
					</CardFooter>
				</TabPane>
				<TabPane tabId="tabs4" >
					<Common_tag_component 
						type='sku' 
						all_tags={sku_eligible_tags} 
						seperated_inherited_tags={tags_assigned_inherited}
						all_inherited_tags={all_inherited_tags}
						assigned_tags={selected_sku_properties && tags_assigned_inherited && tags_assigned_inherited[selected_sku_properties.id] && tags_assigned_inherited[selected_sku_properties.id].sku_tags && tags_assigned_inherited[selected_sku_properties.id].sku_tags.length?tags_assigned_inherited[selected_sku_properties.id].sku_tags:[]} 
						active_item={selected_sku_properties} 
						attach_tag_fn={update_sku_tag_assigned} 
						detatch_tag_fn={update_sku_tag_detached}
						handleclose={()=>{}}
					/>
				</TabPane>
				<TabPane tabId="tabs5">
					{
						tabs===5?
							<ManageSalesSku 
								fetch_flag={show_sales_channel_sku_modal} 	
								handleClose={onclick_handle_close_final}
								user={user} 
								confirmation_alert={confirmation_alert}
								set_tabs={set_tabs}
								set_page_loader={set_page_loader}
								active_group_name={active_group_name} 
								active_division_id={active_division_id}
								all_sales_channels={all_sales_channels}	 
								fetch_sku_get_details={fetch_sku_get_details}
								selected_sku_properties={selected_sku_properties}
								sku_category_type={sku_category_type}
								item_json={additional_json_item_not_priced} 
								set_item_json={set_additional_json_item_not_priced}
								update_sku_properties = {update_sku_properties}
								send_analytics_event={send_analytics_event}
							/>
						:''
					}
				</TabPane>

				<TabPane tabId="tabs6">
					<CardBody className='global_modal_body'>
						<Row>
							<Col style ={{textAlign: "center", height:'300px'}}>
								<img style={{maxHeight:"100%", maxWidth: "100%"}} src={updated_top_view_image ? build_path(global.config.server_path , updated_top_view_image) : global.config.image_not_found} />	
							</Col>	
						</Row><br/>
						<Row>
							<Col>
								<FormGroup>
									<label className="label_margin_5px" >Update Image</label><br />
									<UploadFile id="id_upload_file_top_view" set_file={set_file} accept='.jpg,.png,.jpeg' />	
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<label className="label_margin_5px" style={{color:"orange"}} >{file ? file.name : "No file selected"}</label>
							</Col>
						</Row>
					</CardBody>	
					
					<CardFooter className='global_modal_footer'>
						<Button className='white_button' onClick={onclick_handle_close_final}> Cancel </Button>
						<Button className='blue_button' onClick = {onclick_update_sku_top_view_image}>Update Top View Image</Button>
					</CardFooter>
				</TabPane>

				<TabPane tabId="tabs7">
					{
						tabs===7?
							<Linked_sku_struct	
								handleClose={onclick_handle_close_final}
								user={user}
								set_page_loader={set_page_loader}	 
								fetch_sku_get_details={fetch_sku_get_details}
								selected_sku_properties={selected_sku_properties}
								all_division_tree_data={all_division_tree_data}
								active_division_id={active_division_id}
								sku_category_type={sku_category_type}
							/>:''
					}
				</TabPane>

				<TabPane tabId="tabs8">
					{
						tabs===8?
							<CustomBOM	
								handleClose={onclick_handle_close_final}
								user={user}
								set_page_loader={set_page_loader}	 
								fetch_sku_get_details={fetch_sku_get_details}
								selected_sku_properties={selected_sku_properties}
								all_division_tree_data={all_division_tree_data}
								active_division_id={active_division_id}
								sku_category_type={sku_category_type}
								update_sku_properties={update_sku_properties}
							/>:''
					}
				</TabPane>
				<TabPane tabId='tabs9'>
					<SkuLinkedRules 
						user={user} 
						submit_for_delete_confirm={submit_for_delete_confirm} 
						onclick_handle_close_final={onclick_handle_close_final} 
						set_page_loader={set_page_loader} 
						selected_sku_properties = {selected_sku_properties}
						all_division_tree_data = {all_division_tree_data}
						is_owned_sku ={is_owned}
					/>
				</TabPane>
			</TabContent>	
		</Card>
	);
}

const EditSkuPropertiesTab = ({ confirmation_alert, set_page_loader, brands, fetch_material_template, material_template, set_material_template, active_division_id, active_group_name, orignal_tags, sku_category_id, sku_category_name, sku_category_type, open , handleClose , furniture ,user  , fetch_sku_groups_details, materials_json, texture_json, selected_sku_properties, fetch_sku_get_details, all_sales_channels, show_sales_channel_sku_modal, all_allowed_key, send_analytics_event, submit_for_delete_confirm}) => {

	const alert = useAlert()
	const [ updated_image , set_updated_image ] = useState('')
	const [ updated_top_view_image , set_updated_top_view_image ] = useState('')
	const [modal_path , set_modal_path] = useState('')
	const [tags_assigned_inherited , set_tags_assigned_inherited] = useState('')
	const [ sku_eligible_tags , set_sku_eligible_tags] = useState('')
	const [all_inherited_tags , set_all_inherited_tags] = useState('')
	// const [missing_image_list_low , set_missing_image_list_low] = useState('')
	const [is_allow_material , set_is_allow_material] = useState(false)
	const [is_allow_material_and_depth , set_is_allow_material_and_depth] = useState(false)
	const [all_division_tree_data , set_all_division_tree_data] = useState('')

	const fetch_sku_tags = async () => {
		if(selected_sku_properties ){
			try {
				var resp = await general_fetch({ url: 'sku/get_tags', body:{ids:[selected_sku_properties.id]}});
				set_tags_assigned_inherited(resp)
			}catch(err) {
				console.log(err);
			}
		}else{
			
		}
	}

	const get_sku_eligible_tags = async(id) => {
        try { 
            let response = await general_fetch({ url: 'sku/get_eligible_tags', body: { id: id}});
            set_sku_eligible_tags(response && response.length ? response: []);

        }
        catch(err) {
            console.error(err);
        }
    }

	const onclick_get_cat = async() => {
        try {
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories'});	
			set_all_division_tree_data(response)
        } catch(err) {
            console.log(err);
            // alert.success('Please contact admin')
        }
    }

	useEffect(()=>{
		if(tags_assigned_inherited){
			var x_inherit = [];
			tags_assigned_inherited && tags_assigned_inherited[selected_sku_properties.id] && tags_assigned_inherited[selected_sku_properties.id].sku_category_tags && tags_assigned_inherited[selected_sku_properties.id].sku_category_tags.length && tags_assigned_inherited[selected_sku_properties.id].sku_category_tags.map(x => x_inherit.push(x));
			tags_assigned_inherited && tags_assigned_inherited[selected_sku_properties.id] && tags_assigned_inherited[selected_sku_properties.id].sku_sub_category_tags && tags_assigned_inherited[selected_sku_properties.id].sku_sub_category_tags.length && tags_assigned_inherited[selected_sku_properties.id].sku_sub_category_tags.map(x => x_inherit.push(x));
			tags_assigned_inherited && tags_assigned_inherited[selected_sku_properties.id] && tags_assigned_inherited[selected_sku_properties.id].sku_group_tags && tags_assigned_inherited[selected_sku_properties.id].sku_group_tags.length && tags_assigned_inherited[selected_sku_properties.id].sku_group_tags.map(x => x_inherit.push(x));
			set_all_inherited_tags(x_inherit)
		}else{
			set_all_inherited_tags([])
		}
	},[tags_assigned_inherited])

	const fetch_modals = async () => {
		if(selected_sku_properties){
			try {
				var body = {sku_id : selected_sku_properties.id}
				var reponse = await general_fetch({ url: 'sku/fetch_models' , body });
				console.log(reponse)
				reponse && reponse.default && reponse.default.low_model_3d && reponse.default.low_model_3d ? set_modal_path(reponse.default):set_modal_path('')
				// alert.success("successfull")
			} catch(err) {
				//alert.error("fail")
				console.log(err);
			}
		}else{
			//alert.error("Missing Details")
		}
	}

	useEffect(() => {
		if(selected_sku_properties){
			fetch_modals();
		}
		if(active_division_id==='finish'|| (active_division_id==='hardware'&& (sku_category_type==='panel_core_hardware' || sku_category_type==='mirror_hardware'))){
			set_is_allow_material(true)
		}
		if(active_division_id==='hardware' && (sku_category_type==='skirting_hardware'||sku_category_type==='edge_band_hardware')){
			set_is_allow_material_and_depth(true)
			set_is_allow_material(true)
		}
		fetch_sku_tags()
		selected_sku_properties && get_sku_eligible_tags(selected_sku_properties.id)
		if(open && ((selected_sku_properties && user.store_id === selected_sku_properties.store_id && (['hardware','accessory','finish'].includes(active_division_id) || (selected_sku_properties.additional_properties && selected_sku_properties.additional_properties.length && selected_sku_properties.additional_properties.filter(x => x.key == 'cabinet_type' && x.value == 'smart_cube').length))) || 
			sku_category_type == 'custom_furniture_furniture' || sku_category_type == 'cabinet_furniture' || sku_category_type == 'wardrobe_furniture'))
		onclick_get_cat()
	},[selected_sku_properties, open])

	const onclick_close_complete_edittab = () => {
		handleClose();
		set_updated_image('')
		set_updated_top_view_image('')
		set_all_inherited_tags([])
		set_modal_path('')
		set_is_allow_material(false)
		set_is_allow_material_and_depth(false)
		set_tags_assigned_inherited('')
		fetch_sku_groups_details()
		// set_missing_image_list_low()
	}

	

	return (
			
		<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={onclick_close_complete_edittab}>
			{/* <div style={{backgroundColor:'#F2F4F6', borderRadius:'5px', minHeight:'100%'}}>
				<div style={{borderRadius:'5px 5px 0px 0px', height:'56px', width:'100%', padding:'15px', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', backgroundColor:'white'}}>
					
					<div style={{height:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontFamily: 'Source_Sans_Pro-SemiBold'}}>
							SKU Properties of <span styles={{textTransform: 'capitalize', fontFamily: 'Source_Sans_Pro-SemiBold !important'}}>{active_group_name ? active_group_name:'Unnamed'}</span>
						</span>
						<span>
							<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onclick_close_complete_edittab}>
								<span aria-hidden={true}>×</span>
							</button>
						</span>
					</div>
				</div> */}
		
			<Tab_Structure 
				// get_missing_images={get_missing_images} 
				confirmation_alert={confirmation_alert}
				fetch_material_template={fetch_material_template} 
				material_template={material_template} 
				set_material_template={set_material_template}
				brands={brands} 
				active_division_id={active_division_id} 
				active_group_name={active_group_name} 
				orignal_tags={orignal_tags} 
				sku_category_id={sku_category_id}
				sku_category_name={sku_category_name} 
				sku_category_type={sku_category_type} 
				user={user} 
				fetch_sku_groups_details={fetch_sku_groups_details} 
				materials_json={materials_json} 
				modal_path={modal_path} 
				onclick_close_complete_edittab={onclick_close_complete_edittab} 
				updated_image={updated_image} 
				set_updated_image={set_updated_image}
				updated_top_view_image={updated_top_view_image} 
				set_updated_top_view_image={set_updated_top_view_image}
				alert={alert}
				set_page_loader={set_page_loader}
				fetch_modals={fetch_modals}
				fetch_sku_get_details={fetch_sku_get_details}
				selected_sku_properties={selected_sku_properties}
				show_sales_channel_sku_modal={show_sales_channel_sku_modal} 
				all_sales_channels={all_sales_channels}
				all_inherited_tags={all_inherited_tags}
				texture_json={texture_json}
				tags_assigned_inherited={tags_assigned_inherited}
				fetch_sku_tags={fetch_sku_tags}
				is_allow_material={is_allow_material}
				is_allow_material_and_depth={is_allow_material_and_depth}
				all_division_tree_data={all_division_tree_data}
				open={open}
				sku_eligible_tags={sku_eligible_tags}
				get_sku_eligible_tags={get_sku_eligible_tags}
				all_allowed_key = {all_allowed_key}
				send_analytics_event={send_analytics_event}
				submit_for_delete_confirm = {submit_for_delete_confirm}
			/>
		</Modal>
	);
}

export default EditSkuPropertiesTab ;