import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { useAlert } from 'react-alert'
//import AlertBox from "../../components/AlertBox/";

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};

const BrandDialogBox = ({open , handleClose , brand ,user , fetch_brand_details , mode}) => {

	const[brand_id , set_brand_id] = useState("");
	const[brand_name , set_brand_name] = useState("");
	const alert = useAlert()
	
	// const[open_alert_box , set_open_alert_box] = useState(false);
	// const[current_state_alert , set_current_state_alert] = useState("");

	useEffect (() => {
		if(brand){
			set_brand_id(brand.id)
			set_brand_name(brand.name)
		}
	},[brand]);


	const add_name = async () => {
		if(brand_name){
			try {
				var body = {name : brand_name}
				var response = await general_fetch({ url: 'brand/add', body});
				// set_current_state_alert("successfull")
				// set_open_alert_box(true)
				alert.success('Brand Added Successfully')
				fetch_brand_details();
				onclick_handleclose()
				set_brand_name(null)
			} catch(err) {
				// set_current_state_alert("fail")
				// set_open_alert_box(true) 
				console.log(err);
			}
		}else{
			// set_current_state_alert("missing")
			// set_open_alert_box(true)
			alert.info('Please enter Brand Name')
		}
	}

	const update_name = async () => {
		if(brand_id&&brand_name){//backened call not present
			try {
				var identifiers = {store_id : user.store_id , id:brand_id}
				var updates = {name : brand_name}
				var body = {identifiers , updates}
				var response = await general_fetch({ url: 'brand/update', body});
				// set_current_state_alert("successfull")
				// set_open_alert_box(true)
				fetch_brand_details();
				onclick_handleclose()
				set_brand_name(null)
			} catch(err) { 
				// set_current_state_alert("fail")
				// set_open_alert_box(true)
				console.log(err);
			}
		}else{
			// set_current_state_alert("missing")
			// set_open_alert_box(true)
		}	
	}

	// const close_alert_box = () => {
	// 	set_open_alert_box(false)
	// 	set_current_state_alert(null)
	// }

	const onclick_handleclose = () => {
		set_brand_name("")
		set_brand_id("")
		handleClose()
	}

  	return (
		<Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleclose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>
						<div style={{width:'100%', display:'flex'}}>
							<div>{(mode=='add') ? "Add Brand" : "Update Brand - "}</div>
							<div title={brand && mode=='add' ? null : brand.name} className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{brand && mode=='add' ? null : brand.name}</div>
						</div>
						<i onClick={onclick_handleclose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>
							<div>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name</div>
								<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Brand Name" type="text" value={brand_name} onChange={e => set_brand_name(e.target.value)}/>
							</div>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleclose}> Cancel </Button>
					{ 
						mode == 'update' ?
						<Button className='blue_button' type="button" onClick={update_name}>Update</Button> : 
						<Button className='blue_button' type="button" onClick={add_name}>Add</Button>
					}
				</CardFooter>
			</Card>  
		</Modal>
  	);
}

export default BrandDialogBox ; 