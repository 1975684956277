import React, { useState, useEffect } from 'react';
import './index.css'

const SingleSelect = ({options , placeholder , onItemClick , selectedValue , displayValue}) => {
    const [open_dropdown, set_open_dropdown] = useState(false);
    const [options_copy, set_options_copy] = useState([]);
    const [options_display, set_options_display] = useState([]);
    const [search_string, set_search_string] = useState('');
    const [is_selected, set_is_selected] = useState(false);
    const [options_width, set_options_width] = useState('');

    const toggle_dropdown = () => {
        set_open_dropdown(!open_dropdown)
        set_search_string('')
    }

    const option_item_onclick = (item) => {
        item.selected = !item.selected
        console.log("Check" , item["selected"], item)
        if(item.selected){
            //console.log("inside if")
            onItemClick(item)
            toggle_dropdown()
            set_search_string("")
            set_is_selected(true)
        }else{
            
            onItemClick('')
            set_is_selected(false)
        }
    }

    // useEffect(() => {
    //     console.log("selected value" , selectedValue)
    // }, [selectedValue]);

    useEffect(() => {
        var width_component_container = document.getElementById('component_container_outer')?document.getElementById('component_container_outer').offsetWidth:200
        set_options_width(width_component_container)
    });

    useEffect(() => {

        var x_copy = JSON.parse(JSON.stringify(options))
        var options_modified

        if(selectedValue){
            set_is_selected(true)
            options_modified = x_copy.map(x => {
                var isSelected = x.id === selectedValue.id
                return{...x, selected:isSelected}
            })
        }else{
            set_is_selected(false)
            options_modified = x_copy.map(x => {
                return{...x, selected:false}
            })
        }

        
        set_options_copy(options_modified)
        set_options_display(options_modified)
        
    }, [options , selectedValue]);

    useEffect(() => {
        var filtered_list = JSON.parse(JSON.stringify(options_copy))
        var lower_search_string = search_string?search_string.toLowerCase():''
        if(lower_search_string){
            filtered_list = filtered_list.filter(item => item[displayValue].toLowerCase().includes(lower_search_string))
        }
        set_options_display(filtered_list)
        console.log("options" , filtered_list)
    }, [search_string, options_copy]);

    try{
        return ( 
            <div>
                <div className='inf_outer_div' onClick={toggle_dropdown} style={{display:open_dropdown?'block':'none'}}></div>
                <div id='component_container_outer' className='component_container_outer'>
                    {
                        open_dropdown ? <input className='search_component' value={search_string} onChange={(e) => set_search_string(e.target.value)} placeholder="Type to Search" type='select'></input>
                        :
                        is_selected ? <span onClick={toggle_dropdown} className='single_select_selected_item'>{options_copy.find(item => item.selected)[displayValue]}</span> 
                        :
                        <span onClick={toggle_dropdown} style={{width:'90%', padding:'5px', margin:'5px' , fontSize:'12px' , color:'#676878'}} > {placeholder?placeholder: "Select an Option"} </span>

                    }
                    <i className={open_dropdown?'fa fa-angle-up':'fa fa-angle-down'} onClick={toggle_dropdown}></i>
                </div>
                <div className='options_container_singleselect' style={{display:open_dropdown?'block':'none' , width:options_width}}>
                    {
                        options_display && options_display.length ? options_display.map(item => (
                            <div className='options_template' style={{display:"flex", backgroundColor:item.selected?"#e2effd":""}}>
                                <span className='option_item' onClick={() => {option_item_onclick(item)}} style={{backgroundColor:item.selected?"#e2effd":""}}>{item?item[displayValue]:''}</span>
                                <i className='fa fa-check' style={{color:"#23C16B" , display:item.selected?"block":"none" , width:'16px', fontSize:'10px', alignSelf:'center'}}></i>
                            </div>
                            
                        ))
                        :
                        <div style={{padding:'5px', margin:'5px'}}>No Items Found</div>
                    }
                </div>
            </div>
         );
    }catch(err){
        console.log(err)
        return 'Erorr Inside Single Select';
    }
    
}
 
export default SingleSelect;