import React , { useState, useEffect } from 'react';
import Moment from 'react-moment';
import general_fetch from '../../utils/fetch';
import DesignViewModal from './DesignViewModal';
import ProjectEditModal from './ProjectEditModal';
import FilterModal from './FilterModal';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, UncontrolledAlert, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";

import Toolbar from '../../components/Toolbar/';
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import Main_Grid from '../../components/Main_Grid/';
import { Link } from "react-router-dom";
import name_sort from '../../utils/name_sort';
import date_sort from '../../utils/date_sort';
import desc_name_sort from '../../utils/desc_name_sort';
import desc_date_sort from '../../utils/desc_date_sort';
import {CSVLink} from "react-csv";
// import { CircularProgressbar } from 'react-circular-progressbar';
// import {
// 	buildStyles
//   } from "react-circular-progressbar";
// import 'react-circular-progressbar/dist/styles.css';
// import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
// import { easeQuadInOut } from "d3-ease";
// import AnimatedProgressProvider from "./AnimatedProgressProvider";
// import ChangingProgressProvider from "./ChangingProgressProvider";
import './index.css'
import { useAlert } from 'react-alert';
import generic_sort from '../../utils/generic_sort';

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};


const Designs = ({user, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_active_division_id_sidebar, design_templates, fetch_design_templates, set_page_loader}) => {

// const DesignOutputProgress = ({open, index, project_id}) => {
// 	// console.log("show progress", open)
// 	return(
// 		open == project_id?
// 		<div className='design_output_progress' >Download in Progress
// 		</div>
// 		:''
// 	)
// }


    const [ initialized, set_initialized ] = useState(false);
	const [ fetch_progress, set_fetch_progress ] = useState("40");
	const [ projects, set_projects ] = useState([]);
	const [ users, set_users ] = useState([]);
	const [ design_view_modal_open, set_design_view_modal_open ] = useState(false);
	const [ project_edit_modal_open, set_project_edit_modal_open ] = useState(false);
	const [ active_project, set_active_project ] = useState({});
	const [ display_projects , set_display_projects ] = useState([]);
	const [sorted_projects, set_sorted_projects] = useState([]);
	const [ selected_designers, set_selected_designers ] = useState([]);
	const [ selected_designers_by_email, set_selected_designers_by_email ] = useState([]);
	//const [ current_page , set_current_page ] = useState(1);
	//const [ entry_per_page, set_entry_per_page ] = useState(1);
	// const [ all_pages, set_all_pages ] = useState([]);
	const [ filter_modal_open, set_filter_modal_open ] = useState(false);
	const [ search_string, set_search_string] = useState('');
	const [ status_options, set_status_options] = useState([]);
	const [ selected_status, set_selected_status] = useState([]);
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	const [ projects_csv, set_projects_csv ] = useState([]);
	const [show_design_output_progress, set_show_design_output_progress] = useState(null);
	const [project_id_name_mapping, set_project_id_name_mapping] = useState({});
	const [project_branch_level_data, set_project_branch_level_data] = useState([])
	const [eligible_commands, set_eligible_commands] = useState([])
	const [download_started, set_download_started] = useState(false)
	const [download_ended, set_download_ended] = useState(false)
	const [downloading_project, set_downloading_project] = useState();
	const [downloading_design, set_downloading_design] = useState();
	const [downloading_design_branch, set_downloading_design_branch] = useState();
	const [tabs , set_tabs ] = useState(1)

	const [ display_projects_del , set_display_projects_del ] = useState([]);
	const [ projects_del , set_projects_del ] = useState([]);
	const [ sorted_projects_del , set_sorted_projects_del ] = useState([]);

	

	const alert = useAlert()

	const fetch_project_details = async () => {
		try {
			var project_timer = setInterval(() => {
				var x = Number(fetch_progress);
				console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
				set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			}, 200);
			set_page_loader({
				show:true,
				text:'fetching projects ...'
			})
			var resp = await general_fetch({ url: 'project/get_all' });
			set_page_loader({show:false})
			var userData = await general_fetch({url: 'user/get_all'});

			console.log("userData", userData)
			set_initialized(true);
			var temp_mapping = {};
			console.log("resp ", resp)
			var projects_list = resp && resp.length ?resp.map(x => {
				// console.log("check" , userData.find(y => y.id === x.designer.id).role.includes("catalog_manager"))
				temp_mapping[x.id] = {
					name:x.name, 
					owner:x.designer.name, email:x.designer.email, 
					order_id: x.order_id,
					designer:userData.find(y => y.id === x.designer.id)?.role.includes("designer") ?? false, 
					account_manager:userData.find(y => y.id === x.designer.id)?.role.includes("accounts_manager") ?? false, 
					project_manager:userData.find(y => y.id === x.designer.id)?.role.includes("project_manager") ?? false, 
					catalog_manager:userData.find(y => y.id === x.designer.id)?.role.includes("catalog_manager") ?? false 
				}; 
				console.log('temp_mapping', temp_mapping);
				return {
					...x,
					date_for_sorting:x.updated_at, 
					name_for_sorting:x.designer?x.designer.name:''
				}
			}):[];
			set_project_id_name_mapping(temp_mapping)
			// var projects_csv_list = resp && resp.length ? resp.map(x => {return {Name: x.name, Order: x.order_id, Status: x.project_status, Owner: x.designer.name, Last_Updated: x.updated_at}}):[];
			console.log(projects_list);
			set_projects(projects_list);
			// set_projects_csv(projects_csv_list);
			console.log('project_list',projects_list);
			set_sorted_projects(projects_list);
			set_display_projects(projects_list);

			var resp_del = await general_fetch({ url: 'project/get_deleted' });
			var projects_list_del = resp_del && resp_del.length ?resp_del.map(x => {
				// console.log("check" , userData.find(y => y.id === x.designer.id).role.includes("catalog_manager"))
				return {
					...x,
					date_for_sorting:x.updated_at, 
					name_for_sorting:x.designer?x.designer.name:''
				}
			}):[];
			set_projects_del(projects_list_del);
			set_sorted_projects_del(projects_list_del);
			set_display_projects_del(projects_list_del);

			clearInterval(project_timer);
		} catch(err) {
			console.log(err);
		}
	}

	const compare = (a,b) => {
		if(a['Project name'] == b['Project name']){
			if(a['Design name'] == b['Design name']){
				return a['Branch name'] > b['Branch name'] ? 1 : -1
			}
			return a['Design name'] > b['Design name'] ? 1 : -1
		}
		return a['Project name'] > b['Project name'] ? 1 : -1
	}

	const fetch_branch_level_data_for_csv = async(projects_ids_list) => {
		if(projects_ids_list && projects_ids_list.length){
			let resp = await general_fetch({url: 'design/get_branches_from_projects', body:{project_ids: projects_ids_list, find_csv_data:true}})
			console.log("branchest", resp)
			console.log("test_role" , project_id_name_mapping)
			var projects_csv_list = [];
			var branch_level_data =  resp && resp.length? resp.map(x => {x.branches && x.branches.map(single_branch => {var obj = { 'Project name': project_id_name_mapping[x.project_id].name,
																															  'Project id': project_id_name_mapping[x.project_id].order_id,
																															  'Designer name': project_id_name_mapping[x.project_id].owner,
																															  'Designer email':project_id_name_mapping[x.project_id].email,
																															  'Designer':project_id_name_mapping[x.project_id].designer,
																															  'Account Manger':project_id_name_mapping[x.project_id].account_manager,
																															  'Project Manager':project_id_name_mapping[x.project_id].project_manager,
																															  'Catalog Manager':project_id_name_mapping[x.project_id].catalog_manager,
																															  'Design name': x.name,
																															  'Created At': x.created_at,
																															  'Locking Date': x.locked ? x.locking_date : '',
																															  'Additional Properties': x.additional_properties && x.additional_properties.length ? JSON.stringify(x.additional_properties).replaceAll(',', ' || ') : '',
																															  'Branch name': single_branch.name,
																															  'Branch id': single_branch.id,
																															  'Design id': single_branch.design_id,
																															  'Number of Version Saves': single_branch.version_saves_count,
																															  'Pricing Quotation Download Count': single_branch.pdf_core_design_successful_downloads + single_branch.only_pdf_json_to_xlsx_successful_downloads,
																															  'Locked': x.locked,
																															  'Approved': single_branch.approved,
																															  'Updated at': single_branch.updated_at}
																															  projects_csv_list.push(obj)})}):''
			projects_csv_list.sort(compare);
			set_projects_csv(projects_csv_list)
						
		}else{
			alert.error("There are no projects to download");
		}
	}

	const fetch_branch_level_data_for_design_output = async(projects_ids_list) => {
		if(projects_ids_list && projects_ids_list.length){
			let resp = await general_fetch({url: 'design/get_branches_from_projects', body:{project_ids: projects_ids_list}})
			console.log("branchest", resp)
			console.log("test_role" , project_id_name_mapping)
			let temp_project_branch_level_data = [...project_branch_level_data];
			let branch_level_data =  resp && resp.length? resp.map(x => {x.branches && x.branches.map(single_branch => {
				let new_obj = { 'project_name': project_id_name_mapping[x.project_id].name,
								'project_id': x.project_id,
								'branch_name': single_branch.name,
								'branch_id': single_branch.id,
								'design_name': x.name,
								'design_id': single_branch.design_id}

				let existing_obj = temp_project_branch_level_data.find(o => o['branch_id'] == single_branch.id)
				if(existing_obj){
					new_obj['downloading_pricing_quotation'] = existing_obj['downloading_pricing_quotation']
					existing_obj = new_obj
				}else{
					temp_project_branch_level_data.push(new_obj)
				}
			})}):''
			set_project_branch_level_data(temp_project_branch_level_data)
						
		}else{
			alert.error("There are no projects to download");
		}
	}

	const fetch_project_status_options = async () => {
		try {
			var status_op = await general_fetch({ url: 'project_status/get' });
			set_status_options(status_op);
		} catch(err) {
			console.log(err);
		}
	}

	const show_design_view_modal = project => {
		set_design_view_modal_open(true);
		set_active_project(project);
	}

	const close_design_view_modal = () => {
		set_design_view_modal_open(false);
		set_active_project({});
	}

	const show_project_edit_modal = (project) => {
		set_project_edit_modal_open(true);
		set_active_project(project);
	}

	const close_project_edit_modal = () => {
		set_project_edit_modal_open(false);
		set_active_project({});
	}

	const open_filter_modal = () => {
		set_filter_modal_open(true);
	}
	const close_filter_modal = () => {
		set_filter_modal_open(false);
	}

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const onclick_sorting_arrows = (button_name, type) => {
		var test;

		if(tabs == 1){

			var x_display_list = JSON.parse(JSON.stringify(display_projects))
			var x = x_display_list.sort((a,b) => generic_sort(a,b,button_name,type==='asc'?1:-1))
			// if(button_name==='owner' && type==='asc'){
				// 	var x = x_display_list.sort(name_sort)
				// }else if(button_name==='owner' && type==='desc'){
					// 	var x = x_display_list.sort(desc_name_sort)
					// }else if(button_name==='updated_at' && type==='asc'){
						// 	var x = x_display_list.sort(date_sort)
						// }else if(button_name==='updated_at' && type==='desc'){
							// 	var x = x_display_list.sort(desc_date_sort)	
							// }
							set_sorted_projects(x)
							set_display_projects(x);
		}

		if(tabs == 2){
			var x_display_list_del = JSON.parse(JSON.stringify(display_projects_del))
			var x_del = x_display_list_del.sort((a,b) => generic_sort(a,b,button_name,type==='asc'?1:-1))
			
			set_sorted_projects_del(x_del)
			set_display_projects_del(x_del);
		}

		// var projects_csv_list = x && x.length ? x.map(y => {return {Name: y.name, Order: y.order_id, Status: y.project_status, Owner: y.designer.name, Last_Updated: y.updated_at}}):[];
		// set_projects_csv(projects_csv_list);
	}

	const filter_projects = () => {

		var filtered_projects = [ ...sorted_projects ];
		var filtered_projects_del = [ ...sorted_projects_del ];
		console.log('filtering projects ---> ' + search_string);
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if (lower_search_string) {
			filtered_projects = filtered_projects.filter( project => project.name && project.name.toLowerCase().includes(lower_search_string))
			filtered_projects_del = filtered_projects_del.filter( project => project.name && project.name.toLowerCase().includes(lower_search_string))
		}
		if (selected_designers && selected_designers.length) {
			var designer_ids = selected_designers.map(x => x.id)
			filtered_projects = filtered_projects.filter( project => designer_ids.includes(project.designer_id))
			filtered_projects_del = filtered_projects_del.filter( project => designer_ids.includes(project.designer_id))
		}
		if (selected_designers_by_email && selected_designers_by_email.length) {
			var designer_ids_bt_email = selected_designers_by_email.map(x => x.id)
			filtered_projects = filtered_projects.filter( project => designer_ids_bt_email.includes(project.designer_id))
			filtered_projects_del = filtered_projects_del.filter( project => designer_ids_bt_email.includes(project.designer_id))
		}
		if (selected_status && selected_status.length) {
			var status_ids = selected_status.map(x => x.id)
			filtered_projects = filtered_projects.filter( project => status_ids.includes(project.project_status_id) )
			filtered_projects_del = filtered_projects_del.filter( project => status_ids.includes(project.project_status_id) )
		}
		set_display_projects(filtered_projects)
		set_display_projects_del(filtered_projects_del)
		// var projects_csv_list = filtered_projects && filtered_projects.length ? filtered_projects.map(y => {return {Name: y.name, Order: y.order_id, Status: y.project_status, Owner: y.designer.name, Last_Updated: y.updated_at}}):[];
		// set_projects_csv(projects_csv_list);
	}

	// const update_show_design_output_progress = (idx, value) => {
	// 	let a = [...show_design_output_progress]
	// 	a[idx] = value
	// 	set_show_design_output_progress(a)
	// }

	const update_project_branch_level_data = (branch_id, key, value) => {
		let a = [...project_branch_level_data]
		let obj = a.find(o => o['branch_id'] == branch_id)
		if(obj){
			obj[key] = value
		}
		set_project_branch_level_data(a)
	}

	const fetch_eligible_commands = async() => {
		try{
			let resp = await general_fetch({url:'production_detail/get_eligible_commands'})
			console.log("eligible commands", resp)
			if(resp){
				set_eligible_commands(resp)
			}
		}catch(err){
			console.log(err)
		}
	}


	useEffect(() => {
		filter_projects();	
	}, [search_string]);

	useEffect(() => {
		if(display_projects && display_projects.length){
			let projects_ids_list = display_projects.length && display_projects.map(x => x.id)
			fetch_branch_level_data_for_csv(projects_ids_list)
			// console.log("branches", branch_level_data)
		}

	},[display_projects])

	useEffect(() => {
		if (!initialized) {
			fetch_project_details();
		}
		if(status_options && !status_options.length){
			fetch_project_status_options()
		}
		console.log("before", fetch_design_templates)
		fetch_eligible_commands()
		set_active_tab('project_hub')
		set_active_division_id_sidebar('design_hub')
		send_analytics_event('auto', 'Design', 'Design', 1, window.location.href)
	}, []);

	useEffect(() => {
		console.log("deisgn-templates---->", design_templates)
	},[design_templates])

	useEffect(() => {
		if (active_project && active_project.id) {
			set_active_project(projects.filter( x => x.id == active_project.id )[0])
		}
		let temp_array = []
		let t = projects && projects.length && projects.map(x => temp_array.push(false))
		// set_show_design_output_progress(temp_array)
	}, [ projects ]); 

	useEffect(() => {
		if(projects && projects.length && project_id_name_mapping){
			let projects_ids_list = projects.length && projects.map(x => x.id)
			fetch_branch_level_data_for_design_output(projects_ids_list)
		}
	}, [projects, project_id_name_mapping]);

	useEffect(() => {
		console.log("show progress box", show_design_output_progress)
	},[show_design_output_progress])

	useEffect(() => {
		if(download_started){
			const temp = setTimeout(() => set_download_started(false), 5000)
		}
	}, [download_started]);

	useEffect(() => {
		if(download_ended){
			const temp = setTimeout(() => set_download_ended(false), 5000)
		}
	}, [download_ended]);

	const Project_Table = ({display_projects, deleted}) => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6'}}>
				<th className='th_style flex_property' style={{flex:'5'}}>
					<span style={{paddingRight:'8px'}}>Name</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('name', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('name', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'3'}}>
					<span style={{paddingRight:'8px'}}>Order</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('order_id', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('order_id', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'4'}}><span>Status</span></th>
				<th className='th_style flex_property' style={{justifyContent:'flex-start', flex:'4'}}>
					<span style={{paddingRight:'8px'}}>Owner</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Ascending order as per owner name' onClick={() => onclick_sorting_arrows('name_for_sorting', 'asc')} className='fa fa-caret-up arrow_onhover_1'/>
						<i title='Descending order as per owner name' onClick={() => onclick_sorting_arrows('name_for_sorting', 'desc')} className='fa fa-caret-down arrow_onhover_2'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'4'}}>
					<span style={{paddingRight:'10px'}}>Last Updated</span>
					<span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
						<i title='Latest date on top' onClick={() => onclick_sorting_arrows('updated_at', 'asc')} style={{height:'10px'}} className='fa fa-caret-up arrow_onhover_3'/>
						<i title='Latest date on bottom' onClick={() => onclick_sorting_arrows('updated_at', 'desc')} className='fa fa-caret-down arrow_onhover_4'/>
					</span>
				</th>
				<th className='th_style flex_property' style={{flex:'1', borderRight:'0'}}></th>
				<th className='th_style flex_property' style={{flex:'1', borderRight:'0'}}></th>
				<th className='th_style flex_property' style={{flex:'1', borderRight:'0'}}></th>
				<th className='th_style flex_property' style={{flex:'1'}}></th>
				{/* <th className='th_style flex_property' style={{border:0, flex:'6'}}><span>Designs</span></th> */}
				{/* <th className='th_style' style={{border:0}}><span></span></th>
				<th className='th_style' style={{border:0}}><span></span></th>
				<th className='th_style' style={{border:0, paddingRight:"20px"}}><span></span></th> */}
			</tr>
			<tbody style={{display:'block', height:'calc(100% - 40px)', overflow:'auto', width:'100%'}}>
				{
					display_projects && display_projects.length && display_projects.map((project, index) => {

						let filtered_branch_level_data = project_branch_level_data && project_branch_level_data.length ? project_branch_level_data.filter(x => x.project_id == project.id):''
						let show_in_progress = false
						let show_in_queue = false
						for(let i=0;i<filtered_branch_level_data.length;i++){
							// console.log("filtered btanch level data", filtered_branch_level_data)
							if(filtered_branch_level_data[i]['downloading_outputs'])
								show_in_progress = true
							if(filtered_branch_level_data[i]['in_queue'])
								show_in_queue = true
						}

						return (
							<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none', position:'relative'}} className='tr_style display_onhover'>
								{/* <DesignOutputProgress open={show_design_output_progress} index = {index} project_id={project.id}/> */}
								<td className='td_style flex_property' style={{flex:'5'}}><span className='table_text_overflow'>{project.name?project.name:'-'}</span></td>
								<td className='td_style flex_property' style={{flex:'3'}}>{project.order_id ? project.order_id :'-'}</td>
								<td className='td_style flex_property' style={{flex:'4'}}>{(project.project_status && project.project_status.status) ? project.project_status.status :'-'}</td>
								<td className='td_style flex_property' style={{flex:'4'}}>{project.designer ? project.designer.name ? project.designer.name : "User ID - " + project.designer.id : 'N/A'}</td>
								<td className='td_style flex_property' style={{flex:'4'}}><Moment format="DD/MM/YYYY">{project.updated_at}</Moment></td>
								{/* <td className='td_style flex_property change_color' style={{flex:'6'}}>-</td> */}
								<td className='td_style flex_property ' style={{flex :'1'}}></td>
								{
									(show_in_progress || show_in_queue) && !deleted?
									<>
										<td className='td_style flex_property' id={'download_progress_icon'+index} style={{cursor:'pointer',flex:'1'}} onMouseOver={()=>set_show_design_output_progress(project.id)} onMouseLeave={() => {set_show_design_output_progress(null)}}>
											<i style={{color:'#2688F6',fontSize:'19px'}} className="fa fa-spinner"></i>
										</td>
										{
											show_design_output_progress == project.id ?
											<div id="tooltip" class="bottom" style={{ position:'absolute',zIndex:'2',top:'10px', left:document.getElementById('download_progress_icon'+index)?document.getElementById('download_progress_icon'+index).getBoundingClientRect().left-270+'px':'0px'}}>
												<div class="tooltip-arrow" style={{left:'15%'}} />
												<div class="tooltip-label" style={{height:'auto',width:'auto',borderRadius:'8px', padding:'12px 15px', textAlign:'left', fontStyle:'italic'}}>
													<div>
													{
														show_in_progress?
														<>
															<div style={{fontSize:'14px'}}>Downloads in progress </div>
															{
																filtered_branch_level_data && filtered_branch_level_data.length ? filtered_branch_level_data.map(x => (
																	(x['downloading_outputs'])?<div style={{paddingTop:'5px',fontSize:'14px',fontWeight:'900'}}>{x.design_name + '-' + x.branch_name}</div>:''
																)):''
															}
														</>:''
													}
													{
														show_in_queue?
														<>
															<div style={{fontSize:'14px', marginTop:show_in_progress?'10px':'0px'}}>Downloads in queue </div>
															{
																filtered_branch_level_data && filtered_branch_level_data.length ? filtered_branch_level_data.map(x => (
																	(x['in_queue'])?<div style={{paddingTop:'5px',fontSize:'14px',fontWeight:'900'}}>{x.design_name + '-' + x.branch_name}</div>:''
																)):''
															}
														</>:''
													}
													</div>
												</div>
											</div>
											:''

										}
									</>
									:
									<td className='td_style flex_property ' style={{flex :'1'}}> </td>
								}
								{/* <td className='td_style flex_property x1' style={{justifyContent:'flex-end'}}>
									<span className='hide'>
										<i onClick ={() => show_design_view_modal(project)} className='fa fa-eye'/>
									</span>
								</td> */}
								{
									!deleted ? 
										<td className='td_style flex_property x1' style={{flex :'1'}}> 
											<span className='hide'><i onClick ={() => show_project_edit_modal(project)} className='fa fa-edit'/></span>
										</td>
									:
										<td className='td_style flex_property ' style={{flex :'1'}}> </td>
								}
								{
									!deleted ?
										<td className='td_style flex_property' style={{flex:'1', cursor:'pointer'}}>
											<span className='display_onhover_ellipsis' onClick ={() => show_design_view_modal(project)} style={{width:'25px', height:'25px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'4px',cursor:'pointer'}}><i style={{color:'grey', justifyContent:'flex-end'}} className='fa fa-ellipsis-v'/></span>
										</td>
									:
										<td className='td_style flex_property ' style={{flex :'1'}}> </td>

								}

							</tr>
						)
					})
				}
			</tbody>
		</table>
	);

	const Project_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span ><b>No projects to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span ><b>Fetching all projects in store  </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>	
    );

	try{
		return (
			<Main_Grid style={{position:'relative'}} active_tab="design_hub" user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {tabs == 1 ? display_projects && display_projects.length ?display_projects.length:'0' : display_projects_del && display_projects_del.length ?display_projects_del.length:'0'} {tabs == 1 ? " Projects" : " Deleted Projects"}
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
					<CSVLink data = {projects_csv} filename={store_details.name} style={{pointerEvents: projects_csv && projects_csv.length ? 'auto' : 'none'}} >
						<button style={{...styles.add_brand_style, marginRight:'15px'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center', opacity:projects_csv && projects_csv.length ? '1' : '0.5'}}><i style={{fontSize:'10px', paddingRight:'10px'}} className='fa fa-download'/><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>Download CSV</span></span></button>
					</CSVLink>
	        		<button onClick={open_filter_modal} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}><i style={{fontSize:'10px', paddingRight:'10px'}} className='fa fa-filter'/><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>Filters</span></span></button>
	        		<span style={{ zIndex:'1', position:'absolute', right:'15px', top:'38px'}}>
							<FilterModal 
								open = {filter_modal_open} 
								handle_close={close_filter_modal} 
								selected_designers={selected_designers} 
								set_selected_designers={set_selected_designers} 
								projects={projects} 
								filter_projects={filter_projects}
								selected_status={selected_status} 
								set_selected_status={set_selected_status}
								status_options={status_options}
								selected_designers_by_email={selected_designers_by_email}
								set_selected_designers_by_email={set_selected_designers_by_email}
							/>
						</span>
					</Col>
				</Row>
				<Row style={{height:'calc(100% - 90px)'}}>
					<Col style={{height:'100%'}}>
						<Card style={{borderRadius: 0, height:'100%'}}>
							<Nav 
								style={{display:'flex', alignItems:'center'}}
								className="flex-md-row"
								id="tabs-icons-text" 
								pills
								role="tablist"
							>
								<NavItem>
									<NavLink
										aria-selected={tabs === 1}
										style = {{marginLeft:'10px'}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 1
										})}
										onClick={e => {toggleNavs(e, 1)/*; set_active_tab(0)*/}}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>Projects</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 2}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
										})}
										onClick={e => {toggleNavs(e, 2)/*; set_active_tab(1)*/}}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>Deleted Projects</span>
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==1 ? 'block' : 'none'}}>
								<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
									<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'10px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Project Name' /></div>
									<div id='design-paginator' style={{height:"calc(100% - 40px)"}}>{display_projects && display_projects.length ? (<Project_Table display_projects = {display_projects} />) : (<Project_Filler />)}</div>
								</TabPane>
							</TabContent>
							<TabContent activeTab={"tabs" + tabs} id='design-paginator' style={{height:'calc(100% - 40px)', display: tabs==2 ? 'block' : 'none'}}>
								<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
									<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'10px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string} onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Project Name' /></div>
									<div id='design-paginator' style={{height:"calc(100% - 40px)"}}>{display_projects_del && display_projects_del.length ? (<Project_Table display_projects = {display_projects_del}  deleted/>) : (<Project_Filler />)}</div>
								</TabPane>
							</TabContent>
						</Card>
					</Col>
				</Row>
				<Row style={{height:'3px'}}>
					<Col>
					</Col>
				</Row>
				<Row>
					<Col>	
						<Pegasus_Paginator 
							id='design-paginator'
							all_projects={tabs == 1 ? display_projects && display_projects.length ? display_projects.length : '0' : display_projects_del && display_projects_del.length ? display_projects_del.length : '0'}
							end_index={end_index}
							set_end_index={set_end_index}
							start_index={start_index}
							set_start_index={set_start_index}
						/>
					</Col>
				</Row>
				<DesignViewModal 
					open = {design_view_modal_open} 
					handleClose = {close_design_view_modal} 
					project={active_project} 
					user= {user} 
					fetch_project_details = {fetch_project_details} 
					design_templates = {design_templates} 
					project_branch_level_data = {project_branch_level_data} 
					update_project_branch_level_data = {update_project_branch_level_data} 
					eligible_commands = {eligible_commands} 
					set_download_started={set_download_started} 
					set_download_ended={set_download_ended}
					set_downloading_project = {set_downloading_project}
					set_downloading_design = {set_downloading_design}
					set_downloading_design_branch = {set_downloading_design_branch}
				/>
				<ProjectEditModal open = {project_edit_modal_open} handleClose = {close_project_edit_modal} project={active_project} user= {user} fetch_project_details = {fetch_project_details}/>
				
				{
					
					(download_started || download_ended) ?
					<UncontrolledAlert style={{position:'fixed', bottom:0, right:0, backgroundColor:download_ended? '#CCF4E3':'white', margin:'20px', textAlign:'left', zIndex:'10001'}}>
						{console.log("download started", download_ended, download_started)}
					{
						download_started ? 
						<div style={{width:'470px', height:'65px', backgroundColor:'white', borderRadius:'3px', padding:'10px 10px 10px 5px', color:'black'}}>
							<span style={{fontWeight:'900',fontSize:'14px'}}><i style={{color:'#0747A6', marginRight:'10px',fontSize:'16px'}} className='fa fa-info-circle' />{downloading_design+ ' - ' + downloading_design_branch}</span><span style={{fontSize:'14px'}}> from {downloading_project} processing to download ...</span>
							<div style={{marginLeft:'25px', marginTop:'4px',fontSize:'14px'}}>Download might take 3 to 15 min, Email will be sent when the file is ready.</div>
						</div>:''
					}
					{
						download_ended ?
						<div style={{width:'470px', height:'65px', backgroundColor:'#CCF4E3', borderRadius:'3px', padding:'10px 10px 10px 5px', color:'black'}}>
							<div style={{fontSize:'16px'}}><i style={{color:'green', marginRight:'15px'}} className='fa fa-check-circle'/><span style={{marginBottom:'4px'}}>Download Complete</span></div>
							<span style={{fontWeight:'900', marginLeft:'32px', fontSize:'14px'}}>{downloading_design+ ' - ' + downloading_design_branch}</span><span style={{fontSize:'14px'}}> from {downloading_project} succesfully downloaded.</span>
							{/* <div style={{marginLeft:'32px'}}><span onClick={() => {window.open(global.config.server_path+final_output_file_path, "_blank")}} style={{color:'#0078FF',cursor:'pointer'}}>click here</span> to view the file</div> */}
							{/* <div style={{marginLeft:'32px', marginTop:'1px',fontSize:'14px'}}>Open your downloads to view the file</div> */}
						</div>:''
					}
					</UncontrolledAlert>:''
				}
			</Main_Grid>
		);
	}catch(err){

		console.error("error in project",err)
	}
}

export default Designs;

// 							<Row style={styles.header_row} className="text-center">
// 								<Col xs="12" className="text-center">
// 									<Pegasus_Paginator 
// 										id='design-table-paginator'
// 										current_page={current_page}
// 										set_current_page={set_current_page}
// 										all_pages={all_pages}
// 									/>
// 								</Col>
// 							</Row>
	//					</div>

	//<FilterModal open = {filter_modal_open}/>