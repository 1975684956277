import React , { useState , useEffect }from 'react';
import {Card, Col, Progress, Table, Row, ModalHeader, ModalFooter, Button,Modal, ModalBody, CardHeader, CardBody, CardFooter, Input} from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'

const RuleNameCloneModal = ({open, handleClose, rule_json, clone_rule}) =>{

    const [new_rule_name, set_new_rule_name] = useState();
    const alert = useAlert()

    const onClick_submit = () => {
        clone_rule(rule_json, new_rule_name)
        handleClose()
    }

    useEffect(() => {
        if(rule_json && rule_json.name) set_new_rule_name("Copy of "+rule_json.name)
    }, [rule_json]);

    return(
        <Modal isOpen={open} size='sm' style={{marginTop:'200px'}} toggle={handleClose}>
            <CardHeader className='global_modal_header'>
				Name the Clone rule
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handleClose} />
			</CardHeader>
			<CardBody style={{height:'150px', backgroundColor:'#F5F6F8', padding:'20px 60px'}}>
				<span style={{}}>Rule Name</span>
				<Input value={new_rule_name} placeholder={'New Rule Name'} onChange={(e) => set_new_rule_name(e.target.value)} style={{marginTop:'5px'}}/>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='blue_button' disabled={new_rule_name?false:true} onClick={onClick_submit} >Submit</Button>
			</CardFooter>
        </Modal>
    )
}

export default RuleNameCloneModal