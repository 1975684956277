import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import AlertBox from "../../components/AlertBox/";

const OptionsDialogBox = ({open , handleClose , sales_channel ,user , fetch_sales_channel_details }) => {

	const styles = {
		table_header: {
			fontWeight: "bold",
			fontSize: 'small',
		},
    }; 

	const delete_store_subscription = async (id) => {
		try {
			var body = {id: id , sales_channel_id : sales_channel.id}
			var response = await general_fetch({ url: 'sales_channel/remove_store', body});
			fetch_sales_channel_details();
			
		} catch(err) {
			console.log(err);
		}
	}

	const Table_Structure = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					<th><span style={styles.table_header}><b>#</b></span><br /></th>
					<th><span style={styles.table_header}><b>Store Name</b></span><br /></th>
					<th><span style={styles.table_header}><b>Store Id</b></span><br /></th>
					<th><span style={styles.table_header}><b>Options</b></span><br /></th>
				</tr>
			</thead>
			<tbody style={{display:'block'}}>
				{sales_channel && sales_channel.subscribers && sales_channel.subscribers.length>0 && sales_channel.subscribers.map((subs,index) => (
					<tr> key={index}
						<td>{index+1}</td>
						<td>{subs.name}</td>
						<td>{subs.id}</td>
						<td>
							<Button className="btn-sm" color="primary" onClick ={() => delete_store_subscription(subs.id)}>Delete</Button>
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	)

  	return (
	  	<div>
		  	<Modal className="Margin_64px_top" isOpen={open} size="lg" toggle={handleClose}>
				<Card className='global_modal_height_for_mdlgxl'>
					<CardHeader className='global_modal_header'>
						<span>
							Manage PSN - {sales_channel.name}
						</span>
						<i style={{cursor:'pointer'}} onClick={handleClose} className='fa fa-times'/>
					</CardHeader>
					<CardBody className='global_modal_body'>
						<Row>
							<Col>
								<Table_Structure />
							</Col>
						</Row>			
					</CardBody>
					<CardFooter className='global_modal_footer'>
						<Button className='white_button' style={{margin:0}} type="button" onClick={handleClose}> Close </Button>
					</CardFooter>
				</Card>
			</Modal>
		</div>	
  	);
}

export default OptionsDialogBox ; 
