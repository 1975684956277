import React , { useState , useEffect, useRef }from 'react';
import GroupInventoryCard from '../SKU_Groups/GroupInventoryCard';

const BulkSelectedSKUsPreview = ({user, original_sku_array, sub_cats_included, bulk_selected_hierarchical_data, sku_category_name, no_of_bulk_selected_skus_in_subcat}) => {
    
    const [show_grp_preview, set_show_grp_preview] = useState({});


    const update_show_grp_preview = (sub_cat_id, value) => {
        let a = {...show_grp_preview}
        a[sub_cat_id] = value
        set_show_grp_preview(a)
    }

    useEffect(() => {
        if(sub_cats_included && sub_cats_included.length){
            let a = {...show_grp_preview}
            for(let i=0;i<sub_cats_included.length;i++) a[sub_cats_included[i]] = true
            set_show_grp_preview(a)
        }
    }, [sub_cats_included]);

    return(
        <>
            <div className='flex_property' style={{justifyContent:'space-between', backgroundColor:'white', height:'40px', fontSize:'15px'}}>
                <span style={{marginLeft:'25%'}}>Total <span style={{fontWeight:'900'}}>{original_sku_array ? original_sku_array.length : 0} SKUs </span>Selected</span>
                <span>from <span style={{fontWeight:'900'}}>{sub_cats_included.length} {sub_cats_included.length==1 ? 'SubCategory' : 'SubCategories'}</span></span>
                <span style={{marginRight:'25%'}}>in Category<span style={{fontWeight:'900'}}> {sku_category_name}</span></span>
            </div>
            <div /*className='bulk_edit_preview_parent'*/ >
                {/* <div className='bulk_edit_preview_left_child'>
                    <span>Sub Categories</span>
                    {

                    }
                </div> */}
                <div className='bulk_edit_preview_right_child'>
                {
                    bulk_selected_hierarchical_data && bulk_selected_hierarchical_data.length ? bulk_selected_hierarchical_data.map((single_sub_category, idx) => (
                        <div className='flex_property groups_container_bulk_edit_preview' style={{marginTop:idx==0?'10px':''}}>
                            <div className='col-12 flex_property sub_cat_name_bulk_preview' onClick={() => update_show_grp_preview(single_sub_category.id, !(show_grp_preview[single_sub_category.id]))}>
                                <span style={{fontWeight:'900'}}>{single_sub_category.name+'\xa0'+'('+single_sub_category.sku_groups.length+' Groups and '+no_of_bulk_selected_skus_in_subcat[single_sub_category.id]+' SKUs'+')'}</span>
                                <i className={show_grp_preview[single_sub_category.id]?'fa fa-caret-up':'fa fa-caret-down'}/>
                            </div>
                            {
                                show_grp_preview[single_sub_category.id] && single_sub_category.sku_groups && single_sub_category.sku_groups.length ? single_sub_category.sku_groups.map((single_group) => (
                                    <div key={idx} className='col-12 col-sm-6 col-md-4 col-lg-3' style={{padding:'5px'}}>
                                        <GroupInventoryCard
                                            sku = {single_group.sku}
                                            title = {single_group.name}
                                            user = {user}
                                            sku_group = {single_group}
                                            border_animation_group = ''
                                            showing_bulk_edit_preview = {true}
                                        />
                                    </div>
                                    )):''
                            }
                        </div>
                    )):''
                }
                </div>
            </div>
        </>
    )
}
export default BulkSelectedSKUsPreview;