const split_cat_type = (args, additional_arg) => args.map((cat_type, i) => {
    var x= cat_type.display_name.split('_');
    var x= x && x.length?x.map(p=>p.charAt(0).toUpperCase() + p.slice(1)):''
    var str = '';
    if(additional_arg==='rule'){
        for(let j=0;j<x.length;j++){
            if(j<x.length-1){
                str=str+x[j]+' ';
            }else{
                str=str+x[j];
            }
        }
    }else{
        for(let j=0;j<x.length-1;j++){
            if(j<x.length-2){
                str=str+x[j]+' ';
            }else{
                str=str+x[j];
            }
        }
    }
    cat_type.display_name= additional_arg == 'with_division' ? str + '(' + x[x.length -1] + ')' : str;
    return cat_type;
})

export default split_cat_type;