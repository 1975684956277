import React , { useState , useEffect }from 'react';
import ErrorSVG from '../../alert_svgs/error.svg'
import SuccessSVG from '../../alert_svgs/success.svg'
import WarningSVG from '../../alert_svgs/warning.svg'
import HelpSVG from '../../alert_svgs/help.svg'
import InfoSVG from '../../alert_svgs/information.svg'
// import {Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";


// const AlertBox = ({open_copy , handleClose , current_state , message_ext}) => {

// 	//const [open_1 , set_open_1] = useState(open_copy);
// 	//const [open , set_open] = useState(false);
// 	const [message , set_message] = useState(null);
// 	const [vertical , set_vertical] = useState("bottom");
// 	const [horizontal , set_horizontal] = useState("right");
// 	const [color_type , set_color_type] = useState(null);
// 	// const [transition, set_transition] = useState(undefined);

// 	// if()
// 	// if(!vertical){
// 	// 	vertical="top"
// 	// }
// 	// if(!horizontal){
// 	// 	horizontal="right"
// 	// }

// 	// useEffect(() => {
// 	// 	if(open_copy)
// 	// 	{
	
// 	// 		handleClick(TransitionDown)
// 	// 	}
// 	// },[open_copy])
// 	const decider = () => {
// 		if (current_state == "successfull"){
// 			set_message("Request Successfull")
// 			set_color_type("success")
// 		}else if (current_state == "missing"){
// 			set_message("Please fill all required details")
// 			set_color_type("warning")
// 		}else if(current_state == "fail") {
// 			set_message("Request failed")
// 			set_color_type("error")
// 		}else{
// 			set_message(message_ext)
// 			set_color_type("error")
// 		}
// 	}

// 	useEffect(() => {
// 		decider();
// 	},[current_state])
	

// 	// const handleClick = (Transition) => () => {
// 	//     set_transition(() => Transition);
// 	//     set_open(true);
// 	// };

// 	return('')
// }

// export default AlertBox;

// // <Alert onClose={handleClose} severity={color_type}>
// // 		    	{message}
// // 		  	</Alert>

const AlertTemplate = ({ style, options, message, close }) => {
	const [is_short_alert, set_is_short_alert] = useState(false)

	useEffect(() => {
		if(message && message.length<=40){
			set_is_short_alert(true)
		}
	}, [message]);

    return(
        <div style={style}>
            { 
                options.type==='info' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFFFFF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.25)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'#0747A6', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-info-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?message:''}</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'18px'}} />
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>Info</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'14px', marginTop:'-16px'}} />
                                 </div>
                        }
                        </span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='success' && 
                <div id='alert_main_div' style={{backgroundColor:'#CCF4E3',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'green', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-check-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?message:''}</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'18px'}} />
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>Success</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'14px', marginTop:'-16px'}} />
                                 </div>
                        }
                        </span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='error' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFEDEF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
                    <div style={{fontSize:'18px',}}><img height='28px' width='28px' src={ErrorSVG} style={{ marginRight:'10px', marginLeft:'0px'}}/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'2px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?message:''}</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'18px'}} />
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>Error</div>
                                    <i className='fa fa-times' onClick={close} style={{cursor:'pointer', fontWeight:'600', fontSize:'14px', marginTop:'-16px'}} />
                                 </div>
                        }
                        </span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='status' && 
                <div id='alert_main_div' style={{backgroundColor:"transparent",marginTop:120,fontSize:"12px",display:'flex' ,color:'black', padding:'15px', margin:'15px', width:'420px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.25)', zIndex:'1000001'}}>
                    <span style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>{message?message:"No Message"}</span>
                </div>
            }
        </div>
    )
}

AlertTemplate.defaultProps = {
    style : {},
    options: {},
    close:{}
};

export default AlertTemplate;