import React , { useState , useEffect }from 'react';
import general_fetch from '../../../utils/fetch';
import {Modal, Button, Input, Card, CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'


const AddNewGroup = ({ open, handleClose, user, fetch_sku_groups_details, sub_category_id, set_border_animation_group }) => {

	const[group_name , set_group_name] = useState('');
	const[group_order , set_group_order] = useState(0);
	const alert = useAlert();

	const add_group_name = async() => {
		if(group_name){
			try {
				if(group_name.length > 200){
					console.log('Group name shouldnt exceed 200 charac')
					alert.error("Group name shouldn't exceed 200 characters")
					return
				}
				var body = { name:group_name, sku_sub_category_id:sub_category_id, order:group_order }
				var resp = await general_fetch({ url: 'sku_group/add', body })
				alert.success('Group Added Successfully')
				if(set_border_animation_group) set_border_animation_group(resp ? resp.id : '')
				fetch_sku_groups_details()
				onclick_handleClose()
			} catch(err) {
				alert.error('Request Failed')
				console.log(err);
			}
		}else{
			alert.info('Please Enter Name')
		}
	}

	const onclick_handleClose = () => {
		set_group_name("")
		set_group_order("")
		handleClose()
	}

	return (
		<Modal className='Margin_64px_top' size="sm" isOpen={open} toggle={onclick_handleClose}>
			<Card className='global_modal_height_for_xs'>
				<CardHeader className='global_modal_header'>	
					<span>Add SKU Group</span>
					<i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				<CardBody className='global_modal_body'>
					<Row>
						<Col>
							<div style={{marginBottom:"3px"}}>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Group Name</div>
								<Input id='input_group_name' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" value={group_name} onChange={e => set_group_name(e.target.value)}/>
							</div>
						</Col>	
					</Row>
					<Row style={{marginTop:"5px"}}>
						<Col>
							<div style={{marginBottom:"3px"}}>
								<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Order</div>
								<Input id='input_group_order' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Order" type="number" min={0} value={group_order} onChange={e => set_group_order(Number(e.target.value ? e.target.value : 0))}/>
							</div>
						</Col>	
					</Row>
				</CardBody>

				<CardFooter className='global_modal_footer'>
					<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
					<Button id='submit_group_button' className='blue_button' type="button" onClick={add_group_name}>Add</Button>
				</CardFooter>
			</Card>  
		</Modal>
	);
}

export default AddNewGroup ; 
