import React , { useState , useEffect }from 'react';
import {Modal, Alert,FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import UploadFile from '../UploadFile';
import general_fetch from '../../utils/fetch';
import Choose_existing_texture from '../Choose_existing_material';
import { useAlert } from 'react-alert';
import Property_Tab from './PropertyTab';
import SingleSelect from '../SingleSelect';

const Add_new_material = ({open , handle_close, final_update_fn, show_restore_button, get_current_material_details, material_template, active_material_template, material_mode, set_material_mode, fetch_material_template , materials_json, texture_json,  set_return_material_details,set_page_loader, onclick_update_for_update_sku, update_sku_array, sku_array_idx, base_texture_url, set_base_texture_url}) => {

	const [updated_template , set_updated_template] = useState('');
	const [updated_color , set_updated_color] = useState("");
	const [show_advanced_properties , set_show_advanced_properties] = useState(true);
    const alert = useAlert()
	const [blender_properties , set_blender_properties] = useState([]);
    const [properties, set_properties] = useState([]);
    const [ active_material_template_details , set_active_material_template_details ] = useState(false);

    useEffect(() => {
        if(material_template && material_template.length && open && material_mode=='add'){
            set_updated_template(material_template[0])
            console.log("Entered HERE", material_template)
        }
    },[open])

    useEffect(() => {
        if(active_material_template && material_mode==='edit' || material_mode==='update_template'){
            set_updated_template(active_material_template)
        }
    },[active_material_template, material_mode])

    const remove_common = (arr1, arr2) => {
        if(arr1 && arr1.length===0 && arr2 && arr2.length===0){
            return [];
        }else if(arr1 && arr1.length===0){
            return arr2
        }else if(arr2 && arr2.length===0){
            return arr1
        }else{
            var arr2_name = arr2.map(val => val.name)
            var arr3 = arr1.filter(val1 => !(arr2_name.includes(val1.name)))
            return arr2.concat(arr3) 
        }
    }

    useEffect(() => {
        if(updated_template){
            if (material_mode==='edit') {
                var x=updated_template.material_template ? updated_template.material_template:{};
                x.properties = x.properties && x.properties.length ? x.properties.map( property => {
                    return {...property, final_value:updated_template.properties?updated_template.properties[property.label]:null, wrong_value_flag:false}
                }):[]
                set_properties(x.properties)
            }else{   
                var x=updated_template;
                x.properties = x.properties && x.properties.length ? x.properties.map( property => {
                    return {...property, final_value: property.value.value!=null?property.value.value:'' ,wrong_value_flag:false}
                }):[]
                set_properties(x.properties)
            }
        }
    },[updated_template])

    useEffect(() => {
        console.log("properties",properties)
    },[properties])


    const onclick_add_material = async () => {
        console.log("Updated template", updated_template)
		if( updated_template){
			try {
                console.log("properties",properties)
                var bp = properties && properties.length && properties.reduce((final, elem)=> {
                    return {
                        ...final, [elem.label]: elem.final_value
                    }
                }, {});
                console.log("bp",bp)
                console.log('updated_template',updated_template);
                var body={
                    name:'material', 
                    properties:JSON.stringify(bp), 
                    material_template_id: material_mode==='edit' ? updated_template.material_template.id : updated_template.id
                }
                let material_id = null;
                if (material_mode==='edit' || material_mode === 'update_template') {
                    material_id = updated_template.id
                    body['id']= material_id
                    let resp = await general_fetch({ url: 'material/update', body });
                } else {
                    let resp = await general_fetch({ url: 'material/add', body });
                    material_id = resp.id;
                }
                console.log('material_id ===> ', material_id);
                get_return_material_details(material_id)
                if(update_sku_array && sku_array_idx>=0) update_sku_array(sku_array_idx, 'material_id', material_id)
				onclick_handleclose();
				
			}catch(err) {
				console.log(err);
				alert.error('Error , Unable to add Material')
			}
		}else{
			alert.error('Please fill all required details')
		}
	}

    const get_return_material_details = async(id) => {
		try{
			//loader
			var identifiers = JSON.stringify({id:id})
			var attributes = JSON.stringify({})
			var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
			set_return_material_details(response && response.length ?response[0]:[])
            // onclick_update_for_update_sku(response && response.length ?response[0]:[])
		}catch(err){
			console.log(err)
		}
	}

    const onclick_handleclose = () => {
        handle_close()
        set_updated_template('')
        set_updated_color('')
        set_show_advanced_properties(true)
        
    }

    const onchange_material_template = (id) => {
        if(id){
            var selected_template = material_template && material_template.find( x => x.id===id )
            console.log('setting updated template ---> ', id, selected_template);
            if(material_mode == 'edit') set_updated_template(selected_template? { ...updated_template, material_template: selected_template } : { ...updated_template, material_template: null });
            if(material_mode == 'add') set_updated_template(selected_template? selected_template : null);
        }
    }

    const onclick_restore_template_values = () => {
        get_current_material_details()
        set_material_mode('edit')
    }

	return(
        <Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={onclick_handleclose} style={{}}>
           <Card className='global_modal_height_for_mdlgxl'>
                <CardHeader className='global_modal_header'>
                    <span>{material_mode==='add'?'Add Material':'Edit Material'}</span>
                    <i onClick={onclick_handleclose} style={{cursor:'pointer'}} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body'>
                    {/* <Row>
                        <Col>
                            <FormGroup>
                                <label className="label_margin_5px">Material Template</label>
                                <Input className='input_box' type="select" value ={material_mode==='edit'?updated_template.material_template && updated_template.material_template.id:updated_template.id} onChange = {(e) => onchange_material_template(e.target.value)}>
                                    {
                                        material_template && material_template.length && material_template.map((template, idx) => (
                                            <option key={idx} value={template.id}>{template.name}</option>
                                        ))
                                    }        
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row style={{marginTop:'15px'}}>
                        <Col>
                            <b onClick={() => set_show_advanced_properties(!show_advanced_properties)} className="label_margin_5px">
                                <span style={{marginRight:'20px'}}>Advanced Parameters</span>
                                <span>{show_advanced_properties?<i className='fa fa-caret-up'/>:<i className='fa fa-caret-down'/>}</span>
                            </b>
                        </Col>
                    </Row>
                    {
                        show_advanced_properties?
                            <div>
                                <Row>
                                    <Col>
                                        <Border_line/>
                                        <label style={{fontFamily: 'Source_Sans_Pro-SemiBold'}} className="label_margin_5px">Render Properties</label>
                                    </Col>
                                </Row>
                                {
                                    properties && properties.length ? properties.map((property, idx) =>
                                        <Property_Tab 
                                            key={idx} 
                                            property={property} 
                                            properties={properties} 
                                            set_properties={set_properties}
                                            set_page_loader={set_page_loader}
                                            texture_json={texture_json}
                                            final_update_fn={final_update_fn}
                                        /> 
                                    )  
                                    :''
                                }
                            </div>
                        :''
                    }         */}
								<div className='div_box' style={{width:'760px', height:'420px'}}>
									<div style={{flex:'1', display:'flex', flexDirection:'column', padding:'5px 20px 5px 5px'}}>
										<div style={{fontSize:'14px', marginBottom:'5px'}}>Material Preview</div>
										{/* <img  style={{maxHeight:"100%", maxWidth: "100%", marginBottom:'20px'}}  /> */}
                                        <img width='165px' height='120px' style={{marginBottom:'20px'}} src={base_texture_url ? (global.config.server_path.slice(0,-1) + base_texture_url) : '/assets/img/icons/empty_image.png'} />
										{/* <div style={{fontSize:'14px', marginBottom:'5px'}}>Material Name</div>
										<Input  type='text' style={{height:'32px',pointerEvents:editing_current_material?'auto':'none'}}/> */}
									</div>
									<div style={{borderLeft:'1px solid #E6E9EE', height:'380px', marginTop:'10px'}} />
									<div style={{flex:'3', padding:'10px 20px 20px 20px', overflow:'auto' }}>
										<div style={{fontSize:'14px', marginBottom:'5px'}}>Select Type of Material Template</div>
										<div style={{width:'94%'}}>
										{
                                            material_mode == 'add'?
                                            <SingleSelect 
												options={material_template ? material_template :[]}
												selectedValue = {updated_template ? updated_template :null}
												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
												placeholder = {"Select Material Type"}
												displayValue = {'name'}
											/>:
                                            <SingleSelect 
												options={material_template ? material_template :[]}
												selectedValue = {updated_template && updated_template.material_template ? updated_template.material_template :null}
												onItemClick = {(selectedValue) => {onchange_material_template(selectedValue.id)}}
												placeholder = {"Select Material Type"}
												displayValue = {'name'}
											/>
                                        }
										</div>
                                        {
                                            properties && properties.length && properties.find(o => o.label === 'map') ? 
                                            <Property_Tab 
                                                key={'map'} 
                                                property={properties.find(o => o.label === 'map')} 
                                                properties={properties} 
                                                set_properties={set_properties}
                                                set_page_loader={set_page_loader}
                                                texture_json={texture_json}
                                                input_size = 'small'
                                                set_base_texture_url = {set_base_texture_url}
                                                // final_update_fn={final_update_fn}
                                            />:'' 
                                        }
										<div className='flex_property'>
											<span onClick={() => set_show_advanced_properties(!show_advanced_properties)} style={{marginRight:'10px', cursor:'pointer', fontSize:'14px', marginTop:'10px'}}><span style={{}}>Advanced Parameters</span> <i style = {{marginRight:'10px', marginLeft:'10px'}} className={!show_advanced_properties ? 'fa fa-angle-down' : 'fa fa-angle-up'}/></span>
											<hr style={{width:'324px', marginTop:'28px'}}/>
										</div>
										<div>
										{
											show_advanced_properties?
											<div>
											{
												properties && properties.length ? properties.map((property, idx) =>{
                                                    if(property.label != 'map'){
                                                        return(
                                                            <Property_Tab 
                                                                key={idx} 
                                                                property={property} 
                                                                properties={properties} 
                                                                set_properties={set_properties}
                                                                set_page_loader={set_page_loader}
                                                                texture_json={texture_json}
                                                                input_size = 'small'
                                                                set_base_texture_url = {set_base_texture_url}
                                                                // final_update_fn={final_update_fn}
                                                            />
                                                        )
                                                    }
                                                } 
												)  
												:''
											}
											</div>:''
										}
										</div>
									</div>
								</div>
                </CardBody>
           
                <CardFooter className='global_modal_footer'>
                {!show_restore_button?<Button onClick={onclick_restore_template_values} className='blue_button' style={{marginRight:'10px'}}>Restore previous</Button>:''}
                    <Button onClick={()=>{onclick_add_material()}} className='blue_button'>{material_mode==='add' ?'Add':'Update'}</Button>
                </CardFooter>
            </Card>    
        </Modal>
    )
}

export default Add_new_material;