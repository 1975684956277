import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import build_path from '../../utils/path';
import {FormGroup, Label, Modal, CardImg, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import AlertBox from "../../components/AlertBox/";

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	categories_div: {
		height:'40px', 
		paddingLeft:'20px', 
		display:'flex', 
		alignItems:'center',
		color:'#435A6F',
		textTransform:'capitalize',
	},
	text_elipsis: {
	  whiteSpace: 'nowrap',
	  overflowX: 'auto',
	},
	category_dropdown_style: {
		height: '18px',
	    fontSize: '14px',
	    backgroundColor: 'unset',
	    border: 'none',
	    padding: 0,
	    display: 'flex',
	    alignItems: 'center',
	    cursor:'pointer',
	}
};
const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const Group_collapse  = ({tag, unchecked_group_array, set_unchecked_group_array, checked_group_array, set_checked_group_array, sku_group, user, checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [show_collapse, set_show_collapse] = useState(false)
	// const [active_group_id, set_active_group_id] = useState('')
	// const [active_group_name, set_active_group_name] = useState('')
	const [	checked_map_sku, set_checked_map_sku] =	useState(false);
	const [ group_checked, set_group_checked ] = useState('');
	const [c_unc_indicator_group, set_c_unc_indicator_group] =	useState('');
	const [stop_initial_group, set_stop_initial_group] = useState(false);
	const [display, set_display] = useState('');

	const open_close_collapse = () => {
		if(sku_group){
			// set_active_group_id(sku_group.id)
			// set_active_group_name(sku_group.name)
			set_show_collapse(!show_collapse)
		}
	}

	const on_click_group_checked = (e) => {
		e.stopPropagation()
		set_group_checked(!group_checked)
		set_stop_initial_group(true)
	}

	const check_group_ticked = () => {
		if(sku_group && sku_group.sku && sku_group.sku.length && checked_sku_array && checked_sku_array.length){
			var check_ticked = sku_group.sku.map((x) => checked_sku_array.includes(x.id))
			var check_ticked_1 = check_ticked && check_ticked.filter((x) => x==false)
			if(check_ticked_1 && check_ticked_1.length==0){
				set_group_checked(true)
			}else{
				set_group_checked(false)
			}
		}
	}

	useEffect(() => {
		console.log('checked_group_array =========>',checked_group_array)
		console.log('unchecked_group_array =========>', unchecked_group_array)
	}, [display])	

	useEffect(() => {
		if(sku_group && stop_initial_group ){
			if(!group_checked){
				var check = checked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'unchecked')
				set_stop_initial_group(false)
			}
			if(group_checked){
				var check = unchecked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'checked')
				set_stop_initial_group(false)
			}
		}
	},[group_checked])

	useEffect(() => {	
		if(!group_checked && c_unc_indicator_group=='unchecked'){
			var x = unchecked_group_array;
			x.push(sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
		if(!group_checked && c_unc_indicator_group=='delete'){
			var x = checked_group_array.filter((x1) => x1!=sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='checked'){
			var x = checked_group_array;
			x.push(sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='delete'){
			var x = unchecked_group_array.filter((x1) => x1!=sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_group])

	// useEffect(() => {
	// 	if(checked_sku_array && checked_sku_array.length){
	// 		check_group_ticked()
	// 		console.log('checked_sku_array ====>', checked_sku_array)
	// 	}
	// }, [checked_sku_array])

	// useEffect(() => {
	// 	if(unchecked_sku_array && unchecked_sku_array.length){
	// 		// console.log('unchecked_sku_array ====>', unchecked_sku_array)
	// 	}
	// }, [unchecked_sku_array])

	// useEffect(() => {
	// 	if(checked_group_array && checked_group_array.length){
	// 		console.log('checked_group_array ====>', checked_group_array)
	// 	}
	// }, [checked_group_array])

	// useEffect(() => {
	// 	if(unchecked_group_array && unchecked_group_array.length){
	// 		console.log('unchecked_group_array ====>', unchecked_group_array)
	// 	}
	// }, [unchecked_group_array])

	useEffect(() => {
		if(sku_group && sku_group.Tags && sku_group.Tags.length && tag){
			var check = sku_group.Tags.filter(x => x==tag.id)
			if(check && check.length==1){
				set_group_checked(true)
			}
		}else{
			set_group_checked(false)
		}
	}, [sku_group])

	return(
		<div>
			<div onClick={open_close_collapse} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
				<FormGroup style={{padding:0}} check >
					<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
						<Input style={{margin:0}} type="checkbox" checked={group_checked}  onClick={(e) => on_click_group_checked(e)} />
					</Label> 
				</FormGroup>
				<span style={{textTransform:'capitalize',paddingLeft:'24px'}}>{sku_group.name}</span>
			</div>
			<div >
				<Row style={{display:show_collapse?'flex':'none', alignItems:'center', margin:0, width:'100%'}}>
				{
					sku_group && sku_group.sku && sku_group.sku.length && sku_group.sku.map((sku_item, idx) => (
						<Single_sku key={idx} tag={tag} stop_initial_group={stop_initial_group} c_unc_indicator_group={c_unc_indicator_group} checked_group_array={checked_group_array} group_checked={group_checked} check_group_ticked={check_group_ticked} user={user} sku_item={sku_item} set_checked_sku_array={set_checked_sku_array} checked_sku_array={checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/>
					))
				}
				</Row>
			</div>
		</div>

	)
}

const Single_sku  = ({check_group_ticked, tag, stop_initial_group, c_unc_indicator_group, checked_group_array, group_checked, user, sku_item , checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [checked_map_sku, set_checked_map_sku] =	useState('');
	const [c_unc_indicator_sku, set_c_unc_indicator_sku] =	useState('');
	const [stop_initial, set_stop_initial] = useState(false);

	const [display, set_display] = useState('');

	

	const onclick_sku_checkbox = () => {
		set_checked_map_sku(!checked_map_sku)	
		set_stop_initial(true)
		check_group_ticked()
	}
	// useEffect(() => {
	// 	if(checked_sku_array && checked_sku_array.length){
	// 		
	// 		console.log('checked_sku_array ====>', checked_sku_array)
	// 	}
	// }, [checked_sku_array])

	// useEffect(() => {
	// 	if(unchecked_sku_array && unchecked_sku_array.length){
	// 		console.log('unchecked_sku_array ====>', unchecked_sku_array)
	// 	}
	// }, [unchecked_sku_array])

	
	useEffect(() => {
		if(stop_initial_group){
			if(group_checked){
				set_checked_map_sku(true)
			}else{
				set_checked_map_sku(false)
			}
		}
	}, [group_checked])

	useEffect(() => {
			console.log('checked_sku_array ====>',checked_sku_array)
			console.log('unchecked_sku_array ====>', unchecked_sku_array)
	}, [display])

	// useEffect(() => {
	// 	check_group_ticked()
	// }, [checked_sku_array])

	useEffect(() => {
		if(sku_item && sku_item.id && stop_initial ){
			if(!checked_map_sku){

				var check = checked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'unchecked')
				set_stop_initial(false)
			}
			if(checked_map_sku){

				var check = unchecked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'checked')
				set_stop_initial(false)
			}
		}
	},[checked_map_sku])

	useEffect(() => {
		if(!checked_map_sku && c_unc_indicator_sku=='unchecked'){
			var x = unchecked_sku_array;
			x.push(sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
		if(!checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = checked_sku_array.filter((x1) => x1!=sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='checked'){
			var x = checked_sku_array;
			x.push(sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = unchecked_sku_array.filter((x1) => x1!=sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_sku])

	useEffect(() => {
		if(sku_item && sku_item.Tags && sku_item.Tags.length && tag){
			var check = sku_item.Tags.filter(x => x.id==tag.id)
			if(check && check.length==1){
				set_checked_map_sku(true)
			}
		}else{
			set_checked_map_sku(false)
		}
	},[sku_item])

	return(
		<Col xs={2} style={{padding:'10px'}}>
			<div style={{width:'100%', height:'130px', border: '1px solid #D5DAE0', borderRadius: '4px'}}>
				<div style={{padding:'10px', height:'28px', fontSize:'12px', backgroundColor: '#F8F9FA', borderRadius: '4px 4px 0 0', display:'flex', alignItems:'center' }}>
					<FormGroup style={{padding:0}} check >
						<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
							<Input checked={checked_map_sku} onClick={() =>onclick_sku_checkbox(sku_item)} style={{margin:0}} type="checkbox" />
						</Label>
					</FormGroup>
					<span style={{textTransform:'capitalize',paddingLeft:'24px', ...styles.text_elipsis}}>{sku_item.name ? sku_item.name : ''}</span>
				</div>
				<div style={{width:'100%',border: '1px solid #F2F4F6'}}></div>
				<div style={{height:'calc(100% - 50px)', backgroundColor: 'rgba(255,255,255,0.10)'}}><img style={{height:'100%', width:'100%', borderRadius: '0 0 4px 4px'}} src={sku_item.display_pic && sku_item.display_pic.image ? build_path(global.config.static_path, sku_item.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) : global.config.image_not_found}/></div>
			</div>
		</Col>
	)
}

const ManageDialogBox = ({open , handleClose , tag  , user , fetch_tags_details}) => {

	const [divisions , set_divisions] = useState([])
	const [division_selected , set_division_selected] = useState('')
	const [sku_categories , set_sku_categories] = useState([])
	const [show_categories , set_show_categories] = useState(false)
	// const [checked_map, set_checked_map] =	useState({}); 
	const [ initialized, set_initialized ] = useState(false);
	const [category_selected , set_category_selected] = useState('')
	const [sku_sub_categories , set_sku_sub_categories] = useState('')
	const [sub_category_selected, set_sub_category_selected] =useState('')
	const [sku_groups , set_sku_groups] = useState('')
	const [group_selected, set_group_selected] =useState('')
	const [ category_checked, set_category_checked ] = useState('');

	const [	checked_sku_array, set_checked_sku_array] =	useState([]);
	const [	unchecked_sku_array, set_unchecked_sku_array] =	useState([]);
	const [	checked_group_array, set_checked_group_array] =	useState([]);
	const [	unchecked_group_array, set_unchecked_group_array] =	useState([]);
	
	const [	checked_category_array, set_checked_category_array] =	useState([]);
	const [	unchecked_category_array, set_unchecked_category_array] =	useState([]);
	const [	checked_sub_category_array, set_checked_sub_category_array] =	useState([]);
	const [	unchecked_sub_category_array, set_unchecked_sub_category_array] =	useState([]);

	// useEffect(() => {
	// 		console.log('checked_sku_array jsbhjabjd ====>', 1,)
	// }, [checked_sku_array])

	// useEffect(() => {
	// 		//console.log('unchecked_sku_array ====>', unchecked_sku_array)
	// }, [unchecked_sku_array])

	const fetch_divisions = async () => {
			try{
				var division_details = await general_fetch({ url: 'sku_division/get'}) 
				var only5_division = division_details && division_details.length && division_details.filter((x) => x.id != "appliance")
				var only5_division_1 = only5_division.filter((x) => x.id != "exterior")
				var only5_division_2 = only5_division_1.filter((x) => x.id != "furnishing")
				only5_division && set_divisions(only5_division_2);
				console.log('all divisions=============> ',only5_division_2);
			}
			catch(err){
				console.log(err);
			}
	}

	const fetch_categories = async () => {
		try{
			var identifiers = JSON.stringify({store_id : user.store_id , sku_division_id:division_selected});
			var attributes = JSON.stringify({});
			var body = { identifiers , attributes }
			var category_details = await general_fetch({ url: 'sku_category/get_active' , body }) 
			if (!initialized) {
				set_initialized(true);
			}
			set_sku_categories(category_details);
		}
		catch(err){
			console.log(err);
		}
	}

	const fetch_sku_sub_categories = async () => {
		try {
			// set_page_loader({
	  //           show: true,
	  //           text: 'Fetching sub-categories...',
	  //       })
	  		var sku_category_id=category_selected ? category_selected.id:'';
			var identifiers = JSON.stringify({sku_category_id});
			var attributes = JSON.stringify({});
			var response = await general_fetch({ url: 'sku_sub_category/get_active_sku_new' , body: { identifiers , attributes } });
			response && set_sku_sub_categories(response);
			// set_page_loader({
	  //           show: false,
	  //           text: '',
   //      	});
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_sku_groups = async () => {
		try {
			// set_page_loader({
	        //     show: true,
	        //     text: 'Fetching groups...',
	        // })
			var sku_sub_category_id= sub_category_selected ? sub_category_selected.id :'';
			var identifiers = JSON.stringify({ store_id: user.store_id, sku_sub_category_id });
			var attributes = JSON.stringify({});
			var response = await general_fetch({ url: 'sku_group/get_active_sku' , body: { identifiers , attributes } });
			var depricated_filter_output = response.filter(x => x.depricated !=1)
			//var resp = depricated_filter_output.sort(custom_sort)
			set_initialized(true)
			set_sku_groups(depricated_filter_output);
			//set_sku_groups_display(resp)
			// set_page_loader({
	        //     show: false,
	        //     text: '',
	        // })
		} catch(err) {
			console.log(err);
		}
	}

	useEffect( () => {
		if(!divisions.length && open)
	  		fetch_divisions();
  	},[open]);

	useEffect( () => {
		if(!division_selected){
	   		divisions && divisions.length && set_division_selected(divisions[0].id);
		}
  	},[divisions]);

	useEffect(() => {
		if(division_selected){
			fetch_categories();
			set_sku_categories('')
			set_sku_sub_categories('')
			set_sku_groups('')
			set_category_selected('')
			set_sub_category_selected('')
		}
	}, [division_selected])

	useEffect( () => {
		if(!category_selected){
			sku_categories && sku_categories.length && set_category_selected(sku_categories[0]);
		}
  	},[sku_categories]);

	useEffect(() => {
		if(category_selected){
			fetch_sku_sub_categories();
			set_sku_sub_categories('')
			set_sku_groups('')
			set_sub_category_selected('')
		}
	}, [category_selected])

	useEffect( () => {
		if(!sub_category_selected){
			sku_sub_categories && sku_sub_categories.length && set_sub_category_selected(sku_sub_categories[0]);
		}
  	},[sku_sub_categories]);

	useEffect(() => {
		if(sub_category_selected){
			fetch_sku_groups();
		}
	}, [sub_category_selected])

	// const attach_eligible_tag = async (category_id) => {
	// 	try{
	// 		debugger
	// 		var sku_category_id = [category_id];
	// 		var tag_id = tag.id
	// 		var body = { sku_category_id , tag_id }
	// 		var response = await general_fetch({ url: 'sku_category/attach_eligible_tag' , body })
	// 		fetch_tags_details();
	// 		set_category_checked(null)
	// 	}
	// 	catch(err){
	// 		console.log(err);
	// 	}
	// }

	// const unattach_eligible_tag = async (category_id) => {
	// 	try{
	// 		debugger
	// 		var sku_category_id = [category_id];
	// 		var tag_id = tag.id
	// 		var body = { sku_category_id , tag_id }
	// 		var response = await general_fetch({ url: 'sku_category/unattach_eligible_tag' , body })
	// 		fetch_tags_details();
	// 		set_category_checked(null)
	// 	}
	// 	catch(err){
	// 		console.log(err);
	// 	}
	// }

	const on_click_handleClose = () => {

		set_checked_sku_array([])
		set_checked_group_array([])
		set_unchecked_sku_array([])
		set_unchecked_group_array([])

		set_division_selected('')
		set_category_selected('')
		set_sub_category_selected('')
		set_group_selected('')
		set_divisions('')
		set_sku_categories('')
		set_sku_sub_categories('')
		set_sku_groups('')
		// set_checked_map({})
		handleClose()
	}

	const onClick_division = (division) => {
		if(division){
			set_division_selected(division.id)
			set_show_categories(!show_categories)
		}
	}

	const onclick_category = (category) => {
		if(category){
			set_category_selected(category)
			set_show_categories(!show_categories)
		}
	}

	const onclick_sub_category = (sku_sub_category) => {
		set_sub_category_selected(sku_sub_category)
	}


	// function on_division_change(e){
	// 	set_categories([])
	// 	set_initialized(false)
	// 	set_division_selected(e.target.value)
	// }

	// const checkbox_change = (category_id) => {
	// 	debugger
	// 	set_category_checked(category_id)
	// 	set_checked_map({ ...checked_map, [category_id]: !checked_map[category_id] })
 //  	};

 //  	useEffect(() => {
 //  		debugger
 //  		if(checked_map[category_checked]){
	// 		attach_eligible_tag(category_checked);	
	// 	}
	// 	if(checked_map[category_checked] == false){
	// 		unattach_eligible_tag(category_checked);
	// 	}
 //  	},[checked_map])

  


	// useEffect(() => {
	// 	var tmp_flags = {};

	// 	if (tag && tag.eligible_categories && categories && categories.length){
	// 		var ids = tag.eligible_categories.map(x => x.id);
	// 		categories.map((category) => {
	// 			if (ids.includes(category.id)) {
	// 				tmp_flags[category.id] = true;
	// 			} else {
	// 				tmp_flags[category.id] = false;
	// 			}
	// 		});
	// 		set_checked_map(tmp_flags);
	// 	}		
	// },[categories]);

	return (
		<Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={on_click_handleClose}>
			<div style={{width:'100%', height:'660px'}}>	
				<div className="modal-header" style={{paddingLeft:'20px', paddingRight:'20px', height:'56px', width:'100%', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
					<div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361', fontFamily: 'Source_Sans_Pro-SemiBold'}}>Manage Tag Eligibility - {tag && tag.tag ? tag.tag : 'No tag'}</span>
						<i onClick={on_click_handleClose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
					</div>		
				</div>

				<div className="modal-body" style={{height:'calc(100% - 56px)', width:'100%', padding:0}}>
					
					<div style={{paddingTop:'10px', width:'240px', height:'calc(100% - 74px)', backgroundColor: 'rgba(255,255,255,0.00)', boxShadow: 'inset -1px 0 0 0 #F2F4F6'}}>
						{
							divisions && divisions.length && divisions.map((single_division, idx) => (
								<div key={idx} onClick={() => onClick_division(single_division)} className={single_division.id==division_selected ? 'division_active' : 'division_hover'} style={styles.categories_div}>{single_division.division}</div>
							))
						}
					</div>
					<div style={{position:'absolute', bottom:'0px', left:'0px' ,width:'240px', height:'74px'}}>		
					</div>
					<div style={{display:show_categories?'block':'none', position:'absolute', top:'10px', left:'240px' ,width:'230px', height:'440px', zIndex:'1', overflowY:'auto', backgroundColor:"white", boxShadow: '0 0 1px 0 rgba(67,90,111,0.47)'}}>		
						<div style={{height:'40px', fontSize:'18px', padding:'0px 20px', display:'flex', alignItems:'center', textTransform:"capitalize", color:"#37414B "}}>{division_selected?division_selected:''}</div>
						{
							sku_categories && sku_categories.length && sku_categories.map((category, idx) => (
								<div key={idx} onClick={() => onclick_category(category)} style={{ height:'28px', fontSize:'14px', padding:'0px 20px', borderRadius:'5px', display:'flex', alignItems:'center', color:'#37414B'}}>{category.name}</div>
							))
						}
					</div>
					<div style={{position:'absolute', top:'0px', left:'240px' ,width:'calc(100% - 240px)', height:'100%'}}>		
						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
							<input type='checkbox' style={{marginRight:'10px'}} />
							<span style={{fontSize:'14px',fontFamily: 'Source_Sans_Pro-SemiBold', color: '#234361', letterSpacing: '-0.05px', lineHeight: '18px'}}>Sub-categories for : {category_selected && category_selected.name ? category_selected.name:''}</span>&nbsp;	
						</div>
						<div style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
							{
								sku_sub_categories && sku_sub_categories.length && sku_sub_categories.map((sub_category, idx) => (
									<span key={idx} onClick={() => onclick_sub_category(sub_category)} className={sub_category.id==sub_category_selected.id ? 'sub_category_active' : ''} style={{height:'28px', fontSize:'12px', padding:'0px 20px', borderRadius:'5px', display:'flex', alignItems:'center', color:'#435A6F'}}>{sub_category.name}</span>
								))
							}
						</div>
						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
							<input type='checkbox' style={{marginRight:'10px'}} />	
							<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#234361'}}>Groups for : {sub_category_selected && sub_category_selected.name ? sub_category_selected.name:''}<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#234361'}}></span></span>
						</div>
						<div style={{height:'calc(100% - 130px)', overflow:'auto'}}>
							{
								sku_groups && sku_groups.length && sku_groups.map((group, idx) => (
									<Group_collapse key={idx} tag={tag} unchecked_group_array={unchecked_group_array} set_unchecked_group_array={set_unchecked_group_array} checked_group_array={checked_group_array} set_checked_group_array={set_checked_group_array} sku_group={group} user={user} checked_sku_array={checked_sku_array} set_checked_sku_array={set_checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/>
								))
							}
						</div>
					</div>
					
				</div>
			</div>
		</Modal>
	);
		
}

export default ManageDialogBox;

// <div>
// 			<AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />
// 			<Modal className="modal-dialog-centered" isOpen={open} size="xl" toggle={on_click_handleClose} >
// 				<div className="modal-header">
// 					<Row style={{width:"100%"}}>
// 						<Col xs="6" >
// 							<h4 className="modal-title" id="modal-title-default">
// 								Manage Tag Eligibility - {tag.tag}
// 							</h4>
// 						</Col>	
// 						<Col xs="6" >
// 							<Input 
// 								type='select'
// 								variant = "outlined"
// 								fullWidth
// 								value={division_selected}
// 								onChange={(e) => on_division_change(e)}
// 							>
// 								{
// 									divisions && divisions.length && divisions.map((list_value)=>(
// 										<option value={list_value.id}>{list_value.division? list_value.division:''}</option>
// 									))	
// 								}      	
// 							</Input>
// 						</Col>
// 					</Row>		
// 					<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={on_click_handleClose}>
// 						<span aria-hidden={true}>×</span>
// 					</button>
// 				</div>
// 				<div className="modal-body">
// 					<Row>
// 						{
// 							categories && categories.length ?
// 							(
// 								categories.length && categories.map((category) => (
// 									<Col xs={3} style={{ paddingTop: 10 }} >
// 										<Card>
// 								          	<CardImg style={styles.image_fixed_height} onError={e => e.target.src=global.config.image_not_found} src={category["display_pic.image"] ? build_path(global.config.server_path, category["display_pic.image"]) : global.config.image_not_found} top />
// 								          	<CardHeader className = "bg-secondary p-0" >
// 												<Row>
// 													<Col className = "text-left pt-2" md ="8" style = {styles.text_elipsis}>
// 														<b>{category.name}</b>
// 													</Col>
// 													<Col md ="4" className = "text-right">
// 											            <Input
// 											            	type='checkbox'
// 															color="primary"
// 															inputProps={{ 'aria-label': 'secondary checkbox' }}
// 															checked={checked_map[category.id] ? checked_map[category.id] : false}
// 															onChange={() => checkbox_change(category.id)}
// 											   			/>
// 											   		</Col>	
// 											   	</Row>		
// 											</CardHeader>
// 								        </Card>
// 									</Col>
// 								))
// 							):(
// 								<Filler initialized={initialized} />
// 							)		
// 						}
// 					</Row>			
// 				</div>
// 				<div className="modal-footer">
// 					<Button className="ml-auto" color="link" data-dismiss="modal" type="button" onClick={on_click_handleClose}> Close </Button>
// 				</div>
// 			</Modal>
// 		</div>


// <Dropdown className='x1' isOpen={category_dropdown_open} toggle={() => set_category_dropdown_open(!category_dropdown_open)}>
// 								<DropdownToggle style={styles.category_dropdown_style} >
// 									<span style={{fontFamily: 'Source_Sans_Pro-SemiBold', color: '#1070CA' , display:'flex', alignItems:'center'}}>
// 										<span style={{paddingRight:'5px'}}>{afw_active_category_name ? afw_active_category_name :''}</span>
// 										<i className='fa fa-caret-down'/>
// 									</span>
// 								</DropdownToggle>
// 								<DropdownMenu className='sku_dropdown_list'>
// 								{
// 									afw_categories && afw_categories.length && afw_categories.map((category) => (
// 										<DropdownItem className={category.id==afw_active_category_id ? 'sub_category_active sku_dropdown_list_item':'sku_dropdown_list_item'} onClick={() => onclick_afw_category(category)}><span style={{textTransform:'capitalize'}}>{category.name}</span></DropdownItem>
// 									))
// 								}
// 								</DropdownMenu>
// 							</Dropdown>

// {
// 								afw_groups && afw_groups.length && afw_groups.map((sku_group) => (
// 									<Group_collapse sku_group={sku_group} user={user}/>
// 								))

// 							}