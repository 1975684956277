const name_sort = ( a,b ) => {
	if(a.name_for_sorting==''|| a.name_for_sorting===null||a.name_for_sorting){
		console.log('name_for_sorting')
		if (!a.name_for_sorting) {
			return -1;
		}
		if (!b.name_for_sorting) {
			return 1;
		}
		if(a.name_for_sorting.toLowerCase() < b.name_for_sorting.toLowerCase()) { 
			return -1;
		}
		if(a.name_for_sorting.toLowerCase() > b.name_for_sorting.toLowerCase()) { 
			return 1;
		}
		return 0;
	}else{
		console.log('name')
		if (!a.name) {
			return -1;
		}
		if (!b.name) {
			return 1;
		}
		if(a.name.toLowerCase() < b.name.toLowerCase()) { 
			return -1;
		}
		if(a.name.toLowerCase() > b.name.toLowerCase()) { 
			return 1;
		}
		return 0;
	}
}

export default name_sort;