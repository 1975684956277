import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

// reactstrap components
import {DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Navbar, Nav, Container, Media} from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import './style.css';

const Dummy_Filters = () => (<></>);

const Toolbar = ({ heading, Filters, search_action, crumbs, show_crumbs, active_crumb_index }) => {

	const [ search_string, set_search_string ] = useState(null);
	const [ redirect_url, set_redirect_url ] = useState(null);
  	const [ redirect, set_redirect ] = useState(false);
  	const [ collapse, set_collapse ] = useState(false);
	const history = useHistory();

  	const redirect_page = url => {
  		console.log(url);
  		history.push(url)
		// set_redirect_url(url);
		// set_redirect(true);
  	}


  	useEffect(() => {
  		console.log('resetting redirect params')
		set_redirect(false);
		set_redirect_url(null);
  	}, [])

  	useEffect( () => {
  		search_action(search_string);
  	}, [ search_string ])

  	if (redirect) {
		return (<Redirect push to={redirect_url} />)
  	} else {

		return (
			<>
				<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
					<Container fluid>
						{show_crumbs && (
							<div >
						      <Breadcrumb className="shadow-lg rounded" tag="nav" listTag="div">
						        <BreadcrumbItem  className="h4 text-white text-uppercase" style={{ cursor: 'pointer', marginBottom: 0 }} onClick={() => redirect_page('/home')}>Home</BreadcrumbItem>
						        {crumbs.map((crumb, idx) => (
						        	<BreadcrumbItem 
										key={idx}  
						        		className={"text-uppercase h4 " + (idx == active_crumb_index ? "text-secondary" : "text-white")} 
						        		style={{ cursor: (idx == active_crumb_index) ? "default" : "pointer", marginBottom: 0 }} 
						        		onClick={() => !(idx == active_crumb_index) ? redirect_page(crumb.url) : ''}
						        		// tag="a"
						        		// href={crumb.url} 
						        		active={idx == active_crumb_index}
					        		>
						        		{crumb.name}
					        		</BreadcrumbItem>
					        	))}
						      </Breadcrumb>
						    </div>			
						)}
						{/* </Link> */}
						<div className="form-inline mr-3 d-none d-md-flex  ml-lg-auto d-lg-inline-block">
							<Filters />
						</div>
						<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-0">
						  	<FormGroup className="mb-0" >
						    	<InputGroup className="input-group-alternative">
						      		<InputGroupAddon addonType="prepend">
						       			<InputGroupText>
						          			<i className="fas fa-search" />
						       			</InputGroupText>
						     		</InputGroupAddon>
					     			<Input placeholder="Search"  value={search_string ? search_string : ''} onChange={e => set_search_string(e.target.value.toLowerCase())} type="text" />
								    <InputGroupAddon addonType="append">
								        <InputGroupText style={{ cursor: 'pointer'}}>
								          	<i className="fas fa-times" onClick={() => set_search_string(null)}/>
								        </InputGroupText>
								     </InputGroupAddon>
					    		</InputGroup>
					  		</FormGroup>
						</Form>
						<Nav className="align-items-center d-none d-md-flex" navbar>
			              	<UncontrolledDropdown nav>
				                <DropdownToggle className="pr-0" nav>
				                  	<Media className="align-items-center">
					                    <span className="avatar avatar-sm rounded-circle">
					                      <img
					                        alt="..."
					                        src="/assets/img/icons/avatar.png"
					                      />
					                    </span>
				                    	<Media className="ml-2 d-none d-lg-block">
					                      	<span className="mb-0 text-sm font-weight-bold">
					                        
					                      	</span>
				                    	</Media>
				                    </Media>
				                </DropdownToggle>
				                <DropdownMenu className="dropdown-menu-arrow" right>
					                  	<DropdownItem className="noti-title" header tag="div">
				                   			<h6 className="text-overflow m-0">Welcome!</h6>
					                  	</DropdownItem>
					                  	<DropdownItem to="/home/user-profile" tag={Link}>
					                    	<i className="ni ni-single-02" />
					                    	<span>My profile</span>
					                  	</DropdownItem>
					                  	<DropdownItem to="/home/meetings" tag={Link}>
					                   		<i className="ni ni-calendar-grid-58" />
					                    	<span>Meetings</span>
					                  	</DropdownItem>
					                  	<DropdownItem divider />
					                  	<DropdownItem >
						                    <i className="ni ni-user-run" />
						                    <span>Logout</span>
					                  	</DropdownItem>
			                	</DropdownMenu>
			              	</UncontrolledDropdown>
			            </Nav>
					</Container>
				</Navbar>
			</>
		);
	}
}

Toolbar.defaultProps = { 
	Filters: Dummy_Filters,
	crumbs: [],
	show_crumbs: false,
	search_action: () => console.log('not supported by page')
}


export default Toolbar;

//{this.props.user_details ? this.props.user_details.name : null}