import React, { useState, useEffect } from 'react';
import {FormGroup, Button, Form,Modal, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import StatusDialogBox from '../../views/ProjectStatuses/StatusDialogBox';

const DeleteStatus = ({yes_del, no_del, text, onClose, nonowned, button_name}) => {
    const [key, set_key] = useState(null);
	const [open_status_Dialog_Box, set_open_status_Dialog_Box] = useState(false);

    

    return(
        <div className='custom-ui'>
          <div style={{border:"1px solid #dee2e6", width:'600px', backgroundColor:'#F1F2F3', zIndex:10000}}>
              <div className="modal-header">
                  <h5 className="modal-title" id="modal-title-notification">
                      <span style={{color:'#627386'}}>Your attention is required</span>
                  </h5>
                  <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={()=>{no_del(); onClose()}}>
                      <span aria-hidden={true}>×</span>
                  </button>
              </div>
              <div className="modal-body">
                  <div className="py-3 text-center">
                      <h4 style={{color:'#627386', fontSize:'18px'}} className="heading">{text?text:'Selected Status will be Re-assigned'}<div>Current Status will Deleted Permanently</div></h4>
                  </div>
              </div>
              <div className="modal-footer">
                  <Button className="white_button" type="button" onClick={()=>{no_del(); onClose()}}>
                      Close
                  </Button>
                  <Button className="red_button" type="button" onClick = {()=>{yes_del(); onClose()}}>
                      {button_name?button_name:(nonowned==='non-owned'?'Remove':'Delete')}
                  </Button>
              </div>
          </div>
      </div>
    ); 
}
export default DeleteStatus;