import React, { useState, useEffect } from 'react';

import { Card, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row } from "reactstrap";

const Pegasus_Paginator = ({end_index , set_end_index, start_index , set_start_index, id, all_projects, current_page_entry, set_current_page_entry, all_pages }) => {
	// try {

		// useEffect(() => {
		// 	if (!initialized) {
		// 		var debounced_initialization = setTimeout(() => {
		// 			set_initial_scroll_params();
		// 			window.clearTimeout(debounced_initialization);
		// 		}, 700)
		// 	}
		// }, [])

		// useEffect(() => {
		// 	if (current_page && document.getElementById(id + '-item-' + current_page)) {
		// 		document.getElementById(id + '-item-' + current_page).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		// 	}
		// }, [ current_page ]);

		// const set_initial_scroll_params = () => {
		//    	try {
		//    		var element = window.document.getElementById(id);
		//    		// console.log('initind ---> ', element.clientWidth, element.scrollWidth)
		//    		if (element.scrollWidth > element.clientWidth) {
		//    			// set_show_forward(true);
		//    		}
		//    	} catch (err) {
		//    		console.log("error setting init params ---> ", err);
		//    	}
		// }

		// const [ start_index , set_start_index ] = useState(1);
		// const [ end_index , set_end_index ] = useState('');
		const [ entry_per_page , set_entry_per_page ] = useState(false);
		const [ remainder , set_remainder ] = useState('');

		useEffect(() => {
			var element = document.getElementById('design-paginator');
			var height = element && element.offsetHeight;
			//console.log('Height is'+height);
			var actual_height = height-80;
			var actual_no_of_entry_allowed_per_page = Math.floor(actual_height/40);
			
			console.log('actual_no_of_entry_allowed_per_page', actual_no_of_entry_allowed_per_page)
			set_entry_per_page(actual_no_of_entry_allowed_per_page);	
		}, [ id ])

		const onclick_left_button = () => {
			if(start_index>entry_per_page){
				set_start_index(start_index-entry_per_page)
				if(end_index==all_projects){
					set_end_index(end_index-remainder)
				}else{
					set_end_index(end_index-entry_per_page)
				}
			}
		}

		const onclick_right_button = () => {
			if(end_index<all_projects){
				set_start_index(start_index+entry_per_page)
				if((end_index+entry_per_page)>all_projects){
					set_end_index(all_projects)
				}else{
					set_end_index(end_index+entry_per_page)
				}
			}
		}

		useEffect(() => {
			var int_all_projects = all_projects ? parseInt(all_projects) :0;
			var remain = int_all_projects ? int_all_projects%entry_per_page:0;
			//console.log('remain is============> remain'+remain);
			set_remainder(remain)
			set_start_index(all_projects ==0?0:1)
			if(all_projects>entry_per_page){
				set_end_index(entry_per_page)
				console.log('set_end_index 1', entry_per_page)
			}else{
				console.log('set_end_index', all_projects)
				set_end_index(all_projects)
			}
		},[all_projects])

		useEffect(() => {
			//console.log(start_index,end_index)
		},[start_index, end_index])

		return (
			<Card style={{display: "flex", flexDirection:'row', alignItems:'center', justifyContent:'center', padding:'6px', borderRadius:0, borderTop:0}}>
				
				<span onClick={onclick_left_button} style={{cursor:'pointer', height:'14px', width:'14px', borderRadius:'50%', backgroundColor:'#22A5F7', display: "flex", alignItems:'center', justifyContent:'center', marginRight:'10px'}} aria-label="Previous" >
					<i style={{fontSize:'10px',color:'white'}} className="fa fa-angle-left" /><span className="sr-only">Previous</span>
				</span> &nbsp;
					
				<span style={{fontSize:'14px'}}>{start_index} - {end_index} of {all_projects}</span>&nbsp;
				
				<span onClick={onclick_right_button} style={{cursor:'pointer', height:'14px', width:'14px', borderRadius:'50%', backgroundColor:'#22A5F7', display: "flex", alignItems:'center', justifyContent:'center', marginLeft:'10px'}} aria-label="Next" >
					<i style={{fontSize:'10px',color:'white'}}className="fa fa-angle-right" /> <span className="sr-only">Next</span>
				</span>
				
				<span style={{fontSize:'14px', paddingLeft:'30px'}}>{entry_per_page? entry_per_page :''} per page</span>
			</Card>
		);
	// } catch(err) {
	// 	console.log('error in Pegasus_Paginator ---> ', err);
	// 	return ''
	// }

}

export default Pegasus_Paginator;

// <Pagination id={id}>
				// 	<div style={{ display: 'flex' }} >
				// 		{all_pages && all_pages.map(page => (
				// 			<PaginationItem id={id + '-item-' + page} className={(current_page == page ? "active" : '')}>
				// 				<PaginationLink onClick={() => set_current_page(page)}>{page}</PaginationLink>
				// 			</PaginationItem>
				// 		))}
				// 	</div>
				// </Pagination>