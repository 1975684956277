
import React , { useState , useEffect }from 'react';
import {Input, CardBody, Col, Row, CardFooter, Button } from "reactstrap";
import { useAlert } from 'react-alert'; 
import '../../views/Catalogue/SKU_Groups/sku_groups.css';
import Multiselect from '../Multiselect';
import custom_sort from '../../utils/custom_sort';
import general_fetch from '../../utils/fetch';
import HWKeyValuePair_relative from '../HWKeyValuePair_relative/' 

const Single_item = ({ update_input_value, add_new_item_json, item, idx, all_division_tree_data, item_json, set_item_json, set_remove_loader, already_selected_sku_groups,item_json_condition, set_item_json_condition, component_options_json, valid_inputs, rule_input_values, designer_inputs, all_brands, show_add_button }) => {

	// const [all_divisions ,set_all_divisions ] =useState([]);
	const alert = useAlert()
	const [all_category ,set_all_category ] =useState([]);
	const [all_sub_category ,set_all_sub_category ] =useState([]);
	const [all_groups ,set_all_groups ] =useState([]);
	const [all_skus ,set_all_skus ] =useState([]);

	const [selected_div_array ,set_selected_div_array ] =useState([]);
	const [selected_cat_array ,set_selected_cat_array ] =useState([]);
	const [selected_sub_cat_array ,set_selected_sub_cat_array ] =useState([]);
	const [selected_group_array ,set_selected_group_array ] =useState([]);
	const [selected_sku_array ,set_selected_sku_array ] =useState([]);
	const [initialized ,set_initialized ] =useState(false);

	const [ designer_input_map, set_designer_input_map ] = useState({});
	const [ all_brand_map, set_all_brand_map ] = useState({});

	const [ current_rule_input_values, set_current_rule_input_values ] = useState([]);

	const [allow_edit ,set_allow_edit ] = useState(false);
	const [use_item_json_condition ,set_use_item_json_condition ] = useState(false);


	// useEffect(() => {
    //     if(all_division_tree_data && all_division_tree_data.length){
    //         console.log('all_division_tree_data======>',all_division_tree_data)
	// 		set_all_divisions(all_division_tree_data)
    //     }
    // },[all_division_tree_data])

	const item_json_condition_contain_floor = () => {		
		if(item_json_condition && item_json_condition.length){
			for(let i=0; i<item_json_condition.length; i++){
				if(item_json_condition[i].component && item_json_condition[i].component[0] && item_json_condition[i].component[0].id==='floor'){
					return true;
				}else if(item_json_condition[i].component==='floor'){
					return true;
				}
			}
		}
		return false
	}

	const item_json_condition_contain_striplight = () => {
		if(item_json_condition && item_json_condition.length){
			console.log('item_json_condition', item_json_condition)
			for(let i=0; i<item_json_condition.length; i++){
				if(item_json_condition[i].subcomponent && item_json_condition[i].subcomponent[0] && item_json_condition[i].subcomponent[0].id  === 'strip_light'){
					return true;
				}else if(item_json_condition[i].subcomponent==='strip_light'){
					return true;
				}
			}
		}
		return false
	}
	
	useEffect(() => {
		if(item_json_condition && item_json_condition.length && (item_json_condition_contain_floor() || item_json_condition_contain_striplight())){
			set_use_item_json_condition(true)
		}else{
			set_use_item_json_condition(false)
		}
	},[item_json_condition])

	useEffect(() => {
		set_designer_input_map(designer_inputs.reduce((final, elem) => ({
			...final,
			[elem.id]: elem
		}), {}));
	},[ designer_inputs ])

	useEffect(() => {
		set_all_brand_map(all_brands.reduce((final, elem) => ({	
			...final,
			[elem.id]: elem
		}), {}));
	},[all_brands])

	// useEffect(() => {	
	// 	if(use_item_json_condition===false){
	// 		update_item_json(idx,'condition_type','absolute') 
    //         update_item_json(idx,'relativecomponent',[]) 
    //         update_item_json(idx,'relativesubcomponent', [])
    //         update_item_json(idx,'relativeproperty',[])
	// 	}
	// },[use_item_json_condition])
	
	// useEffect(() => {	
	// 	if(use_item_json_condition===false && item_json && item_json.length){
	// 		item_json.map((x_item, index) => {	
	// 			item_json[index].relativecomponent=[]
	// 			item_json[index].relativesubcomponent=[]
	// 			item_json[index].relativeproperty=[]
	// 			item_json[index].condition_type='absolute'
	// 		})
	// 	}
	// },[use_item_json_condition])

	const fetch_sku_groups = async (id) => {
		try {
			console.time('get_groups response')
			var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id:id } });
			console.timeEnd('get_groups response')
			var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
			set_all_groups(resp)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if(item){
			set_selected_div_array(item.division)
			set_selected_cat_array(item.category)
			set_selected_sub_cat_array(item.sub_cat)
			set_selected_group_array(item.group)
			set_selected_sku_array(item.sku)
			// set_allow_edit(item.sku && item.sku.length?false:true)
		}        
    },[item])

	useEffect(() => {
		if(item && item.rule_input_value_id){
			console.log('setting current rule input values ==>> ', rule_input_values.filter(x => x.id === item.rule_input_value_id)[0]?.value ?? []);
			set_current_rule_input_values(rule_input_values.filter(x => x.id === item.rule_input_value_id)[0]?.value ?? []);
		}
	}, [ rule_input_values ]);

	useEffect(() => {
		console.log('current_rule_input_values',current_rule_input_values)
		current_rule_input_values.map((current_rule_input_value, index) => {
			console.log('options --->> ', designer_input_map[current_rule_input_value.rule_input_id].valid_values.map((x, idx) => ({ name: x, id:idx })));
			console.log('selected ---->>> ', [{name: current_rule_input_value.value}]);
		});
	} ,[ current_rule_input_values ]);

	useEffect(() => {
		if(all_division_tree_data && all_division_tree_data.length && selected_div_array && selected_div_array.length ){
			console.log('Division execution started')
			update_item_json(idx, 'division', selected_div_array)
			var x_id = selected_div_array.map(x => x.id)[0]
			set_all_category( all_division_tree_data.find(x => x.id===x_id)?all_division_tree_data.find(x => x.id===x_id).sku_category:[])
			console.log('Division execution ended')
		}
    },[all_division_tree_data,selected_div_array])

	useEffect(() => {
		if( all_category && all_category.length && selected_cat_array && selected_cat_array.length){
			console.log('Cat execution started')
			update_item_json(idx, 'category', selected_cat_array)
			var x_id = selected_cat_array.map(x => x.id)[0]
			set_all_sub_category( all_category.find(x => x.id===x_id) ?all_category.find(x => x.id===x_id).sku_sub_category:[])
		}      
    },[all_category,selected_cat_array])

	useEffect(() => {
		if (initialized) {
			if(all_sub_category && all_sub_category.length && selected_sub_cat_array && selected_sub_cat_array.length){
				update_item_json(idx, 'sub_cat', selected_sub_cat_array)
				var x_id = selected_sub_cat_array.map(x => x.id)[0]
				if(x_id){
					fetch_sku_groups(x_id)
				}else{
					set_all_groups([])
				}
			}        
		} else {
			if (already_selected_sku_groups && selected_sub_cat_array && selected_sub_cat_array.length) {
				// var x_groups_list = Object.keys(already_selected_sku_groups) && Object.keys(already_selected_sku_groups).length ? Object.keys(already_selected_sku_groups).filter(x => {
				// 	if(x===selected_sub_cat_array[0].id){
				// 		console.log("MAJOR CONDITION SAT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
				// 		return already_selected_sku_groups[x]
				// 	}
				// }) :[] 
				let x_groups_list = already_selected_sku_groups?.[selected_sub_cat_array[0].id]??[];
				console.log("MAJOR LOG ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", x_groups_list);
				set_all_groups(x_groups_list);
			}
		}
    },[already_selected_sku_groups, all_sub_category, selected_sub_cat_array])

	useEffect(() => {
		if(all_groups && all_groups.length && selected_group_array && selected_group_array.length){
			update_item_json(idx, 'group', selected_group_array)
			var x_id = selected_group_array.map(x => x.id)[0]
			set_all_skus(all_groups.find(x => x.id===x_id)?all_groups.find(x => x.id===x_id).sku:[])
		}        
    },[all_groups, selected_group_array])

	useEffect(() => {
		if(all_skus && all_skus.length && selected_sku_array && selected_sku_array.length){
			update_item_json(idx, 'sku', selected_sku_array)
			console.log('skuexecuted')
		}
		if(idx===item_json.length-1){
			if(selected_sku_array && selected_sku_array.length){
				set_remove_loader(true)
			}
		}
    },[selected_sku_array])

	const onselect_multi_options = (property, selectedList_1) => {
		// update_item_json(idx,property, selectedList_1)
        if(property==='division'){
			set_selected_div_array(selectedList_1)
			update_item_json(idx, 'division', selectedList_1)
			reset_for_div('division')
		}else if(property==='category'){
			set_selected_cat_array(selectedList_1)
			update_item_json(idx, 'category', selectedList_1)
			reset_for_div('category')
		}else if(property==='sub_cat'){
			set_selected_sub_cat_array(selectedList_1)
			set_initialized(true);
			update_item_json(idx, 'sub_cat', selectedList_1)
			reset_for_div('sub_cat')
		}else if(property==='group'){
			set_selected_group_array(selectedList_1)
			update_item_json(idx, 'group', selectedList_1)
			reset_for_div('group')
		}else{
			set_selected_sku_array(selectedList_1)
			update_item_json(idx, 'sku', selectedList_1)
		}
	}

	const reset_for_div = (property) => {
		if(property==='division'){
			set_all_category([]);
			set_selected_cat_array([]);
			update_item_json(idx, 'category', [])
		}
		if(property==='category' || property==='division'){	
			set_all_sub_category([]);
			set_selected_sub_cat_array([]);
			set_initialized(true);
			update_item_json(idx, 'sub_cat', [])
		}
		if(property==='sub_cat' || property==='category' || property==='division'){	
			set_all_groups([]);
			set_selected_group_array([]);
			update_item_json(idx, 'group', [])
		}
		if(property==='group' || property==='sub_cat' || property==='category' || property==='division'){	
			set_all_skus([]);
			set_selected_sku_array([]);
			update_item_json(idx, 'sku', [])
		}
	}

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		let rule_input_value_map = rule_input_values.reduce((final, elem) => ({
			...final,
			[elem.id]: false
		}), {});
		a = a.sort((a, b) => (a.rule_input_value_id > b.rule_input_value_id || a.rule_input_value_id == b.rule_input_value_id || !b.rule_input_value_id || !a.rule_input_value_id) ? 1 : -1);
		a = a.map((item, idx) => {
			let show_conditional_elements = false;
			if (!rule_input_value_map[item.rule_input_value_id]) {
				rule_input_value_map[item.rule_input_value_id] = true;
				show_conditional_elements = true;
			}
			return {
				...item,
				show_conditional_elements,
				show_addition_element: a[idx + 1] && a[idx + 1].rule_input_value_id == item.rule_input_value_id ? false : true
			}
		});
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
    }



	useEffect(() => {
		console.log('rule	inputs values',rule_input_values, item)       
    },[rule_input_values])

	return(
		<>
			{/* {
				item.show_conditional_elements && item.rule_input_value_id && current_rule_input_values.length ? (
					<Row style={{margin:0,marginTop:idx===0?'10px':'20px', fontSize:'12px', color:'425A70'}}>
						<Col style={{display:'flex', alignItems:'center'}}>
							Designer Input values:
						</Col>
					</Row>
				)
				:''
			} */}
			{/* {console.log("hw copy 1", all_brand_map, designer_input_map, current_rule_input_values)} */}
			{
				item.show_conditional_elements && item.rule_input_value_id && current_rule_input_values.length ? (
					<Row style={{margin:0,marginTop:idx===0?'10px':'40px'}}>
						{current_rule_input_values.map((current_rule_input_value, idx) => (
							current_rule_input_value ? (
								<Col xs={3} style={{marginBottom:'3px'}}>

									<span style={{fontSize:'12px', color:'425A70'}}>{designer_input_map[current_rule_input_value.rule_input_id] ? designer_input_map[current_rule_input_value.rule_input_id].name : ''}</span>
									<Input type='select' style={{height:'32px',overflowY:'auto',fontSize:'12px'}} value={current_rule_input_value.value} onChange={(e) => update_input_value(item.rule_input_value_id, current_rule_input_value.rule_input_id, e.target.value) }>
										{designer_input_map[current_rule_input_value.rule_input_id] && designer_input_map[current_rule_input_value.rule_input_id].type != 'custom' ? (

											designer_input_map[current_rule_input_value.rule_input_id].valid_values.map((valid_value, idx) => (
												<option value={all_brand_map[valid_value].id}>{all_brand_map[valid_value].name}</option>
											))
										) : (
											designer_input_map[current_rule_input_value.rule_input_id].valid_values.map((valid_value, idx) => (
												<option value={valid_value}>{valid_value}</option>	
											))
										)}
									</Input>
								</Col>
							):''
						))}
					</Row>
				)
				: ''
			}
			{
				idx===0?
				<div>
					<Row style={{margin:0,marginTop:'10px', fontSize:'12px', color:'425A70'}}>
						<Col xs={1} style={{display:'flex', alignItems:'center'}}>
							Sl no.
						</Col>
						<Col xs={1} style={{display:'flex', alignItems:"center",paddingLeft:0}}>
							Division 
						</Col>
						<Col xs={2} style={{display:'flex', alignItems:"center"}}>
							Category 
						</Col>
						<Col xs={2} style={{display:'flex', alignItems:"center"}}>
							Sub Category
						</Col>
						<Col xs={2} style={{display:'flex', alignItems:"center"}}>
							Group 
						</Col>
						<Col xs={2} style={{display:'flex', alignItems:"center"}}>
							SKU
						</Col>
						<Col xs={1} style={{display:'flex', alignItems:"center", padding:0}}>
							Quantity
						</Col>
						<Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>	
						</Col>
					</Row>
				</div>
				:''
			}
			<Row key={idx} style={{margin:0,marginTop:'10px'}}>
				<Col xs={1} style={{display:'flex', alignItems:'center'}}>
					{idx+1}
				</Col>
				<Col xs={1} style={{display:'flex', alignItems:"center", paddingLeft:0}}>
					<Multiselect
						style={{backgroundColor:'white', width:'227px'}}
						options={all_division_tree_data && all_division_tree_data.length? JSON.parse(JSON.stringify(all_division_tree_data.filter(x => x.id==='hardware'))):[]} 
						selectedValues={selected_div_array?selected_div_array:[]}
						onSelect={(selectedList)=>onselect_multi_options('division', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('division', selectedList)} 
						displayValue="id" // Property name 
						selectionLimit={1}
						id={'action_linked_sku_structure_division_'+idx}
						placeholder='Select'
						// disable={allow_edit?false:true}
					/> 
				</Col>
				<Col xs={2} style={{display:'flex', alignItems:"center"}}>
					<Multiselect
						style={{backgroundColor:'white', width:'227px'}}
						options={all_category && all_category.length? JSON.parse(JSON.stringify(all_category)):[]} 
						selectedValues={selected_cat_array?selected_cat_array:[]}
						onSelect={(selectedList)=>onselect_multi_options('category', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('category', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'action_linked_sku_structure_category_'+idx}
						placeholder='Select'
						// disable={allow_edit?false:true}
					/> 
				</Col>
				<Col xs={2} style={{display:'flex', alignItems:"center"}}>
					<Multiselect
						style={{backgroundColor:'white', width:'227px'}}
						options={all_sub_category && all_sub_category.length? JSON.parse(JSON.stringify(all_sub_category)):[]} 
						selectedValues={selected_sub_cat_array?selected_sub_cat_array:[]}
						onSelect={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'action_linked_sku_structure_sub_cat_'+idx}
						placeholder='Select'
						// disable={allow_edit?false:true}
					/> 
				</Col>
				<Col xs={2} style={{display:'flex', alignItems:"center"}}>
					<Multiselect
						style={{backgroundColor:'white', width:'227px'}}
						options={all_groups && all_groups.length? JSON.parse(JSON.stringify(all_groups)):[]} 
						selectedValues={selected_group_array?selected_group_array:[]}
						onSelect={(selectedList)=>onselect_multi_options('group', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('group', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'action_linked_sku_structure_group_'+ idx}
						placeholder='Select'
						// disable={allow_edit?false:true}
					/> 
				</Col>
				<Col xs={2} style={{display:'flex', alignItems:"center"}}>
					<Multiselect
						style={{backgroundColor:'white', width:'227px'}}
						options={all_skus && all_skus.length? JSON.parse(JSON.stringify(all_skus)):[]} 
						selectedValues={selected_sku_array?selected_sku_array:[]}
						onSelect={(selectedList)=>onselect_multi_options('sku', selectedList)} 
						onRemove={(selectedList)=>onselect_multi_options('sku', selectedList)} 
						displayValue="name" // Property name 
						selectionLimit={1}
						id={'action_linked_sku_structure_sku_'+ idx}
						placeholder='Select'
						// disable={allow_edit?false:true}
					/> 
				</Col>
				<Col xs={1} style={{display:'flex', alignItems:"center", padding:0}}>
					<Input 
						style={{fontSize:'12px'}}
						// className={allow_edit?'':'opacity_div'} 
						type="number" placeolder='0' 
						id={'action_linked_sku_structure_quantity_'+ idx}
						value={item_json && item_json[idx]?Number(item_json[idx].value):''} 
						onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value',Number(e.target.value)):alert.error('Negative Number not allowed')}
					/>
				</Col>
				<Col xs={1} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
					{/* <i style={{cursor:'pointer', marginRight:'10px'}} onClick={()=> set_allow_edit(!allow_edit)} className='fa fa-edit' /> */}
					<span><i style={{cursor:'pointer' ,color:'#F0656F'}} onClick={() =>delete_item_json(idx)} className='fa fa-trash'/></span>
				</Col>
				{
					use_item_json_condition?
						
						<HWKeyValuePair_relative component_options_json={component_options_json} single_item_json={item}  set_item_json={set_item_json} item_json={item_json} idx={idx} item_json_condition={item_json_condition} use_item_json_condition={use_item_json_condition}/>
						
					:''
				}
			</Row>
			{ item.show_addition_element ? (
				<Row style={{margin:0}} key={'add_button'+item_json.length + idx}>
					<Col style={{textAlign:'left', paddingBottom:'10px'}}>
						<span onClick={() => add_new_item_json(item.rule_input_value_id)}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>{valid_inputs.length ? 'Add SKU +' : (show_add_button ? 'Add SKU +' : '')}</span>
					</Col>
				</Row>
			):''}
		</>
	)
}

const HWLinked_sku_structure = ({ add_new_input_value, update_input_value, item_json, set_item_json, all_division_tree_data,item_json_condition, set_item_json_condition, component_options_json, valid_inputs, designer_inputs, all_brands, rule_input_values, open }) => {

	const [skus_ansistry ,set_skus_ansistry ] =useState([]);
	const [remove_loader ,set_remove_loader ] =useState(false);
	const [sku_group_initial_options ,set_sku_group_initial_options ] = useState([]);
	const alert = useAlert()

	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i].sku && item_json[i].sku.length<1 ){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

    const add_new_item_json = (rule_input_value_id) => {
		// let show_conditional_elements = false;
		// if (!rule_input_value_id) {
		// 	show_conditional_elements = true;
		// }
		console.log('adding new item json', rule_input_value_id);
		rule_input_value_id = rule_input_value_id ? rule_input_value_id : add_new_input_value();
		console.log('adding new item json !!! ', rule_input_value_id);
		
		if(is_valid_add_key(item_json)){
			let a = [ ...item_json.map(x => ({ ...x, show_addition_element: x.rule_input_value_id == rule_input_value_id ? false : x.show_addition_element })) ];
			a.push({
				rule_input_value_id,
				condition_type:'absolute',
				component:[], 
				subcomponent:[], 
				property:[],
				relativecomponent:[], 
				relativesubcomponent:[], 
				relativeproperty:[], 
				operator:'equal', 
				division:[], 
				category:[], 
				sub_cat:[], 
				group:[], 
				sku:[], 
				range_max:'', 
				range_min:'', 
				value:'', 
				tag:[], 
				value_combination_logic:'and', 
				index_value:'per_set',
				show_conditional_elements: false,
				show_addition_element: false
			})
			
			let rule_input_value_map = rule_input_values.reduce((final, elem) => ({
				...final,
				[elem.id]: false
			}), {});
			a = a.sort((a, b) => (a.rule_input_value_id > b.rule_input_value_id || a.rule_input_value_id == b.rule_input_value_id || !b.rule_input_value_id || !a.rule_input_value_id) ? 1 : -1);
			a = a.map((item, idx) => {
				let show_conditional_elements = false;
				if (!rule_input_value_map[item.rule_input_value_id]) {
					rule_input_value_map[item.rule_input_value_id] = true;
					show_conditional_elements = true;
				}
				return {
					...item,
					show_conditional_elements,
					show_addition_element: a[idx + 1] && a[idx + 1].rule_input_value_id == item.rule_input_value_id ? false : true
				}
			});
			// a.push({division:[], category:[], sub_cat:[], group:[], sku:[], quantity:[]})
			set_item_json(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

	// useEffect(() => {
	// 	set_item_json(item_json.map((item, idx) => {
	// 		if (!item.rule_input_value_id) {
	// 			return { ...item, rule_input_value_id: rule_input_values[0].id }
	// 		} else {
	// 			return item
	// 		}	
	// 	}))
	// }, [rule_input_values])

    useEffect(() =>{
		console.log("changed item json ---> ", item_json)
		if(item_json && !item_json.length && open){
			add_new_item_json()
		}else{
			var x_sku_ids = item_json && item_json.length ?item_json.map(x => x.sku_id) :[];
			console.log("h/w rules item json while ancestry fetch --> ", item_json)
			if(x_sku_ids && x_sku_ids.length){
				let ancestors = fetch_ansisters(x_sku_ids);
			}
		}
	},[item_json])

	const onclick_handleclose =() => {

	}

	const fetch_ansisters = async(sku_ids) => {
		// console.log('HW linked list=================================> ',sku_ids)
		var response =[]
		if(sku_ids && sku_ids.length && sku_ids[0]){
			response = await general_fetch({ url: 'inventory/get_sku_ancestry' , body: { sku_id:sku_ids } });
		}
		set_skus_ansistry(response && response.length?response:[]);
		return response;
	}

	const fetch_group_and_skus = async sku_sub_category_id => {
		try{
			var response = await general_fetch({ url: 'inventory/get_groups_for_multiple_sub_categories' , body: { sku_sub_category_id } });
			set_sku_group_initial_options(response)
		}catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		if(skus_ansistry && skus_ansistry.length && item_json && item_json && item_json.length){
			let sku_sub_category_ids = skus_ansistry.map(x => x.sku_sub_category_data[0].id);
			fetch_group_and_skus(sku_sub_category_ids)
			var x_single_item = item_json.map((x_lskus,idx) => {
				var x = skus_ansistry.find(x => x.id===x_lskus.sku_id)
				console.log("h/w linked sku",x,skus_ansistry, item_json);
				return({
					division:[{id:'hardware'}],
					category:x?.sku_category_data??null,
					sub_cat:x?.sku_sub_category_data??null,
					group:x?.sku_group_data??null,
					sku:x?.sku_data??null,
					value:item_json[idx]?item_json[idx].value:'',

					relativecomponent:item_json[idx]?item_json[idx].relativecomponent:'',
					relativesubcomponent:item_json[idx]?item_json[idx].relativesubcomponent:'',
					relativeproperty:item_json[idx]?item_json[idx].relativeproperty:'',
					index_value:item_json[idx]?item_json[idx].index_value:'',
					condition_type:item_json[idx]?item_json[idx].condition_type:'absolute',

					rule_input_value_id: x_lskus.rule_input_value_id,
					show_conditional_elements: x_lskus.show_conditional_elements,
					show_addition_element: x_lskus.show_addition_element,
				})
			});
			console.log('updated item json', x_single_item)
			set_item_json(x_single_item);
		}        
    },[skus_ansistry])

	return(
		<div style={{backgroundColor:'white', paddingTop:'15px'}}>
			{
				item_json && item_json.length?item_json.map((x,idx) => 
					<Single_item 
						item={x} 
						key={x.id?x.id:idx} 
						idx={idx} 
						set_remove_loader={set_remove_loader} 
						item_json={item_json} 
						set_item_json={set_item_json} 
						all_division_tree_data={all_division_tree_data}
						already_selected_sku_groups={sku_group_initial_options}
						item_json_condition={item_json_condition}
						set_item_json_condition={set_item_json_condition}
						component_options_json={component_options_json}
						valid_inputs={valid_inputs}
						designer_inputs={designer_inputs}
						all_brands={all_brands}
						rule_input_values={rule_input_values}
						add_new_item_json={add_new_item_json}
						update_input_value={update_input_value}
						show_add_button={idx == item_json.length-1}
					/>
				):''
			}
			{/* {
				remove_loader?''
				:
				<div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
					<div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
						<i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>Please Wat</span>
					</div>
				</div>
			} */}
			{valid_inputs && valid_inputs.length ? (
				<Row style={{margin: 0, marginTop:10, borderTop: '2px rgb(0, 123, 255) solid'}}>
					<Col style={{textAlign:'left', paddingBottom:'10px', paddingTop: 6}}>
						<span onClick={() => add_new_item_json()}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>
							Add Designer Input Condition +
						</span>
					</Col>
				</Row>
			):''}
		</div>
	)
} 

export default HWLinked_sku_structure;
