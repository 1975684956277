import React , { useState , useEffect, useRef }from 'react';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row } from "reactstrap";

const ChooseEditingMode = ({user, original_sku_array, sub_cats_included, bulk_selected_hierarchical_data, sku_category_name, no_of_bulk_selected_skus_in_subcat, set_choosing_editing_mode, set_editing_common_props, set_editing_individual_props}) => {
    return(
        <CardBody style={{backgroundColor:'#F5F6F8', height:'calc(100% - 84px)'}}>
            <div className='flex_property' style={{justifyContent:'space-between', backgroundColor:'white', height:'40px', fontSize:'15px', margin:'12px 64px', padding:'0px 16px'}}>
                <span className='flex_property'>
                    <span> {'Total '} <span style={{fontWeight:'900'}}>{original_sku_array ? original_sku_array.length : 0} SKUs</span> selected from, <span style={{fontWeight:'900'}}>{sub_cats_included.length} {sub_cats_included.length==1 ? 'SubCategory' : 'SubCategories'}</span>, in Category<span style={{fontWeight:'900'}}> {sku_category_name}</span></span>
                </span>
            </div>
            <div className='flex_property' style={{marginTop:'64px', justifyContent:'center', fontSize:'24px'}}>
                Choose how you want to edit the Selected SKUS
            </div>   
            <div className='flex_property' style={{marginTop:'24px', justifyContent:'center'}}>
                <div className='editing_mode_div_box' style={{marginRight:'64px'}} onClick={() => {set_choosing_editing_mode(false);set_editing_individual_props(true)}}>
                    {/* <div className='choosing_editing_mode'>Individual SKUs</div>
                    <div className='flex_property' style={{justifyContent:'space_between',width:'100%'}}>
                        <div>Selected SKUs</div>
                        <div>Property to Edit</div>
                    </div>
                    <div className='flex_property' style={{justifyContent:'space_between',width:'100%', border:'0.769231px solid #E6E9EE', height:'40px'}}>
                        <div>SKU1</div>
                        <div style={{height:'32px', backgroundColor:"#E6E9EE" }}></div>
                    </div> */}
                    <img
                        alt="..."
                        src="/assets/bulk_edit/individual_1.png"
                        style={{width:'44vh', height:'44vh', position:'absolute', top:'-1px', left:'-1px'}}
                    />
                </div>
                <div className='editing_mode_div_box' onClick={() => {set_choosing_editing_mode(false);set_editing_common_props(true)}}>
                    {/* <div className='choosing_editing_mode'>All SKUs at once</div>
                    <div className='flex_property' style={{justifyContent:'space-between'}}>
                        <div>Property</div>
                        <div>Applied to all SKUs</div>
                    </div> */}
                    <img
                        alt="..."
                        src="/assets/bulk_edit/common.png"
                        style={{width:'44vh', height:'44vh', position:'absolute', top:'-1px', left:0}}
                    />
                </div>
            </div>
        </CardBody>
    )
}
export default ChooseEditingMode