const generic_sort = ( a,b,field,order=1 ) => {
	if(a[field]==''|| a[field]===null||a[field]){
		// console.log([field]')
		if (!a[field]) {
			return -1*order;
		}
		if (!b[field]) {
			return 1*order;
		}
		if(a[field].toLowerCase() < b[field].toLowerCase()) { 
			return -1*order;
		}
		if(a[field].toLowerCase() > b[field].toLowerCase()) { 
			return 1*order;
		}
		return 0;
	}
    
    return -1*order;
}

export default generic_sort;