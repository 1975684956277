import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import { confirmAlert } from 'react-confirm-alert';
import RoomTypeDialogueBox from './RoomTypeDialogueBox';
import UnsubscribeNotification from './UnsubscribeNotification';
import SubscribeDialogBox from './SubscribeDialogBox';
import classnames from "classnames";
import DetectableOverflow from 'react-detectable-overflow';

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
	text_elipsis: {
		color:'grey',
		width:'500px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	  },
};

const Filler = ({ initialized }) => {
	const [fetch_progress, set_fetch_progress] = useState(60);
	return (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span>&nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching Room Types </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;
					</th>
				)}
			</tr>
		</thead>
	</Table>	
	)
};

const RoomType = ({user, store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event}) => {
	const [default_room_types , set_default_room_types] = useState([]);
    const [display_default_roomtypes , set_display_default_roomtypes] = useState([]);
	const [display_custom_roomtypes , set_display_custom_roomtypes] = useState([]);
	const [custom_room_types , set_custom_room_types] = useState([]);
	const [search_string_default , set_search_string_default] = useState(null);
	const [search_string_custom , set_search_string_custom] = useState(null);
	const [initialized_default , set_initialized_default] = useState(false);
	const [initialized_custom , set_initialized_custom] = useState(false);
	const [open_roomtype_Dialog_Box, set_open_roomtype_Dialog_Box] = useState(false);
	const [open_subscribe_Dialog_Box, set_open_subscribe_Dialog_Box] = useState(false);
	const [key , set_key] = useState(null);
	const [default_mode, set_default_mode] = useState('default')
	const [custom_mode, set_custom_mode] = useState('custom')
    const [active_room_type, set_active_room_type] = useState(null);
	const [active_room_type_row, set_active_room_type_row] = useState(null);
	const [tabs , set_tabs ] = useState(1)
    const [sku_categories, set_sku_categories] = useState(null);
	const [show_full_list, set_show_full_list] = useState(false);
	const [over_flow, set_over_flow] = useState(false)

	const alert = useAlert()


	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};



	// useEffect(() => {
	// 	console.log("over", "def", over_flow_default, "cus", over_flow_custom)
	// },[over_flow_default, over_flow_custom])

	const compare = (a,b) => {
        if(a.sku_division_id == b.sku_division_id)
            return a.sku_category_name > b.sku_category_name? 1:-1
        return a.sku_division_id > b.sku_division_id? 1 : -1
    }

	const fetch_categories = async() => {
        try{
            var response = await general_fetch({ url: 'inventory/get_categories', body:  {sku_division_id:["furniture", "accessory", "hardware", "building", "finish", "template"] }} );
            var resp = response && response.length? response.filter(x => x.sku_category_name && x.sku_category_name.length):[];
            console.log("resp", resp)
            resp.sort(compare)
            console.log("resp2", resp)
            set_sku_categories(resp);
        } catch(err) {
            console.log(err);
        }
    }

	const fetch_roomTypes_details = async () => {
        try {
            var resp = await general_fetch({ url: 'room_type/get'});
			console.log("rooms", resp)
            let default_room_types_temp = resp && resp.length ? resp.filter(x => x.global):''
			console.log("rooms1", default_room_types_temp)
			set_default_room_types(default_room_types_temp)
			let custom_room_types_temp = resp && resp.length ? resp.filter(x => !x.global):''
			console.log("rooms2", custom_room_types_temp)
			set_custom_room_types(custom_room_types_temp)
        } catch(err) {
            console.log(err);
        }
    }

	const filter_roomtypes = (room_types_given, search_string, set_display_roomtypes) => {
		var filtered_roomTypes =[...room_types_given]
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_roomTypes = filtered_roomTypes.filter( room_types => room_types.room_type.toLowerCase().includes(lower_search_string))
		}
		set_display_roomtypes(filtered_roomTypes)
	}

	useEffect(() => {
		set_display_default_roomtypes(default_room_types);
		// let temp = default_room_types && default_room_types.length ? default_room_types.map(x => over_flow_default.push(false)):[]
		// set_over_flow_default(temp)
	},[default_room_types])

	useEffect(() => {
		set_display_custom_roomtypes(custom_room_types);
		// let temp = custom_room_types && custom_room_types.length ? custom_room_types.map(x => over_flow_custom.push(false)):[]
		// set_over_flow_custom(temp)
		// console.log("overt", over_flow_custom)
	},[custom_room_types])

	useEffect(() => {
		filter_roomtypes(default_room_types, search_string_default, set_display_default_roomtypes);
	}, [search_string_default]);

	useEffect(() => {
		filter_roomtypes(custom_room_types, search_string_custom, set_display_custom_roomtypes);
	}, [search_string_custom]);

	useEffect(() => {
		if (!initialized_default && display_default_roomtypes && display_default_roomtypes.length) {
			set_initialized_default(true);
		}
	}, [display_default_roomtypes]);

	useEffect(() => {
		if (!initialized_custom && display_custom_roomtypes && display_custom_roomtypes.length) {
			set_initialized_custom(true);
		}
	}, [display_custom_roomtypes]);

    useEffect(() => {
        fetch_roomTypes_details();
        fetch_categories();
		set_active_tab('preferences_settings');
		set_active_division_id_sidebar('RoomType')
		console.log("open subs",open_subscribe_Dialog_Box)
		send_analytics_event('auto', 'Room Type', 'Room Type', 1, window.location.href)
		// if(all_category_type_data && !all_category_type_data.length){
		// 	fetch_categories_type()
		// }
	}, []);

	const close_roomType_Dialog_Box = () => {
		set_open_roomtype_Dialog_Box(false);
		set_active_room_type({})
		set_key(null);
	}

	const show_roomType_add_dialog = () => {
		set_open_roomtype_Dialog_Box(true);
		set_key('add');
	}

    const show_roomType_update_dialog = (type) => {
        set_active_room_type(type);
		set_open_roomtype_Dialog_Box(true);
		set_key('edit');
	}

	const show_subscribe_dialog = () => {
		set_open_subscribe_Dialog_Box(true);
	}

	const close_subscribe_dialog = () => {
		set_open_subscribe_Dialog_Box(false);
	}

	const remove_room_type = async(id) => {
		try{
			var resp = await general_fetch({url: 'room_type/delete', body : {id: id}})
			alert.success("successfully deleted")
			fetch_roomTypes_details();
		} catch(err) {
			alert.error("Request Failed")
			console.log(err)
		}
	}

	const unsubscribe_room_type = async(id) => {
		try{
			var resp = await general_fetch({url: 'room_type/unsubscribe', body : {id: id}})
			alert.success("successfully unsubscribed")
			fetch_roomTypes_details();
		} catch(err) {
			alert.error("Request Failed")
			console.log(err)
		}
	}

	const subscribe_room_type = async(room_type) => {
        if(room_type && room_type != 'none'){
            try{
                console.log("entered", room_type)
                var resp = await general_fetch({url: 'room_type/subscribe', body : {id: room_type.id}})
                alert.success("successfully subscribed")
                fetch_roomTypes_details()
            } catch(err) {
                alert.error("Request Failed")
                console.log(err)
            }
        }else {
            alert.error("Choose a Room Type to Subscribe")
        }
	}

	const submit_for_unsubscribe_confirm = (yes_del, no_del, message) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: message,
          customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <UnsubscribeNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message}/>
                </div>
            );
          }
        });
    };

	const RoomType_Table = ({display_roomtypes, tab}) => {
		console.log("rooms3", display_roomtypes, "key", sku_categories)
		return (<table style={{height:'100%'}} className="text-left flex_property table_style">
            <thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
				{
					tab=='default'?
					<th className='th_style flex_property' style={{marginLeft: '26px',flex:'1'}}><span>Room Type</span></th>
					:
					<th className='th_style flex_property' style={{marginLeft: '1px',flex:'1'}}><span>Room Type</span></th>
				}
				<th className='th_style flex_property' style={{marginLeft:'0px',flex:'10'}}><span>Categories </span></th>
				{
					tab=='default'?
					<th className='th_style flex_property' style={{marginLeft: '0px', flex:'1'}}><span>Actions</span></th>
					:
					<th className='th_style flex_property' style={{marginLeft: 0, flex:'0.7', alignItems:'centre'}}><span>Actions</span></th>
				}
			</tr>
			</thead>
            
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
            {
                display_roomtypes && display_roomtypes.length && display_roomtypes.map((single_roomType, idx) => {return(
                    <tr className={'tr_style display_onhover'} onClick = {() => {set_show_full_list(!show_full_list);set_active_room_type_row(single_roomType)}}>
					{
						tab == 'default'?
						<span style={{margin:'0px 0px 0px 18px', cursor:'pointer'}}><i style={{color:single_roomType.subscribed?'#47B881':'grey', fontSize:10}} className='fa fa-circle'/></span>
						:''
					}
					<td className='td_style flex_property' style={{flex:'1'}}><span className='table_text_overflow'>{single_roomType.room_type}</span></td>   
					{
						show_full_list && active_room_type_row && active_room_type_row.id == single_roomType.id?
						<td className='x2' style={{flex:'10', fontSize:'14px', marginLeft:'1.5%'}}><span className='hide' style={{color:'grey'}} onClick = {() => {set_show_full_list(!show_full_list)}} onMouseLeave = {()=>set_show_full_list(!show_full_list)}>
						{single_roomType.category_maps && single_roomType.category_maps.length>0 && single_roomType.category_maps.map((cat,idx) =>{let s = '';console.log("maps",cat);return(
								sku_categories && sku_categories.find(o=>o.sku_category_id == cat.sku_category_id) ?
									idx == 0?
									s += String(sku_categories ? sku_categories.find(o=>o.sku_category_id == cat.sku_category_id).name:'') 
									:
									s += '\xa0\xa0'+'|'+'\xa0\xa0' + String(sku_categories ? sku_categories.find(o=>o.sku_category_id == cat.sku_category_id).name:'') 
							 	:''
							) })
						}
						</span></td>
						:
						<td className='x2' style={{flex:'10', fontSize:'14px', marginLeft:'1.5%'}}>
							<span style={styles.text_elipsis} className='hide'>
								{/* <DetectableOverflow onChange={()=>{set_overflow_json_fn(single_roomType.id)}}> */}
								<DetectableOverflow onChange={()=>{set_over_flow(single_roomType.id)}}>
								{
									single_roomType.category_maps && single_roomType.category_maps.length>0 && single_roomType.category_maps.map((cat,idx) =>{let s = '';return(
										sku_categories && sku_categories.find(o=>o.sku_category_id == cat.sku_category_id) ?
											idx == 0?
											s += String(sku_categories ? sku_categories.find(o=>o.sku_category_id == cat.sku_category_id).name:'') 
											:
											s += '\xa0\xa0'+'|'+'\xa0\xa0' + String(sku_categories ? sku_categories.find(o=>o.sku_category_id == cat.sku_category_id).name:'')
										:''
									) })
								}	
								</DetectableOverflow>
							</span>
							{
								over_flow==single_roomType.id?
								<span className='hide'>more</span>
								:''
								
							}
						</td>
					}
					{
						tab=='default'?
						<td>
							<span className='td_style flex_property x1' style={{ flex:'1', alignItems:'left'}}>
							{
							global.config.global_roomtypes_allowed_users.includes(String(user.id))?
								<>
								<span className='hide' style={{marginRight:'20px', cursor:'pointer', color:'#1E90FF'}}><i onClick ={() => show_roomType_update_dialog(single_roomType)} className='fa fa-edit' aria-hidden="true"/></span>
								<span className='hide' style={{marginRight:'20px', cursor:'pointer', color:'red'}}><i onClick ={() => submit_for_delete_confirm(()=>remove_room_type(single_roomType.id), ()=>{}, 'This might affect existing designs, Click Delete to Confirm')} className='fa fa-trash'/></span>
								<span style={{marginRight:'20px', cursor:'pointer'}}><i style={{marginRight:'0px' ,color:single_roomType.subscribed?'#1E90FF':'grey'}} onClick ={single_roomType.subscribed?() => submit_for_unsubscribe_confirm(()=>unsubscribe_room_type(single_roomType.id), ()=>{}, 'This might affect existing designs, Click Unsubscribe to Confirm'):()=>subscribe_room_type(single_roomType)} className='fa fa-eye'/></span>

								</>
							:
								<span style={{marginRight:'20px', cursor:'pointer'}}><i style={{marginRight:'45px' ,color:single_roomType.subscribed?'#1E90FF':'grey'}} onClick ={single_roomType.subscribed?() => submit_for_unsubscribe_confirm(()=>unsubscribe_room_type(single_roomType.id), ()=>{}, 'This might affect existing designs, Click Unsubscribe to Confirm'):()=>subscribe_room_type(single_roomType)} className='fa fa-eye'/></span>
							}
							</span>
						</td>
						:
						<td>
							<span className='td_style flex_property x1' style={{ flex:'1', alignItems:'left'}}>
							<span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={() => show_roomType_update_dialog(single_roomType)} className='fa fa-edit' aria-hidden="true"/></span>
							<span className='hide' style={{marginRight:'20px', cursor:'pointer', color:'red'}}><i onClick ={() => submit_for_delete_confirm(()=>remove_room_type(single_roomType.id), ()=>{}, 'This might affect existing designs, Click Delete to Confirm')} className='fa fa-trash'/></span>
							</span>
						</td>
					}
                    </tr>
                )})
            }
            </tbody>
        </table>)
	}

    return (
        <Main_Grid active_tab="RoomType" store_details={store_details} get_store_details={get_store_details}>
			{/* <SubscribeDialogBox open = {open_subscribe_Dialog_Box} room_types = {room_types} fetch_roomTypes_details = {fetch_roomTypes_details} handleClose = {close_subscribe_dialog} /> */}
			<RoomTypeDialogueBox open = {open_roomtype_Dialog_Box} fetch_roomTypes_details = {fetch_roomTypes_details} handleClose = {close_roomType_Dialog_Box} mode = {key} active_room_type = {active_room_type} sku_categories={sku_categories} default_room_types = {default_room_types} toggleNavs = {toggleNavs} user={user}/>
			
            <Row style={{padding:'10px 10px', color:'#22272e'}}>
					<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
						{tabs==1 ? (custom_room_types && custom_room_types.length ? 'Viewing custom room types' : 'Viewing Infuria predefined room types'): (custom_room_types && custom_room_types.length ? 'Viewing Infuria predefined room types' : 'Viewing custom room types')}
					</Col>

					<Col xs={6} style={{textAlign:'right'}}>
					<button onClick = {() => show_roomType_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Custom Room Type</span></button>
					</Col>
                </Row>
                <Row style={{height:'calc(100% - 90px)'}}>
                    <Col style={{height:'100%'}}>
                        <Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>
                            
							<Nav 
								style={{display:'flex', alignItems:'center'}}
								className="flex-md-row"
								id="tabs-icons-text" 
								pills
								role="tablist"
							>
								<NavItem>
									<NavLink
										aria-selected={tabs === 1}
										style = {{marginLeft:'10px'}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 1
										})}
										onClick={e => toggleNavs(e, 1)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{custom_room_types && custom_room_types.length?'Custom Room-Types':'Infurnia Room-Types'}</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 2}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
										})}
										onClick={e => toggleNavs(e, 2)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{custom_room_types && custom_room_types.length?'Infurnia Room-Types':'Custom Room-Types'}</span>
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={custom_room_types && custom_room_types.length?'tabs2':'tabs1'} style={{fontSize:'12px'}}>
									<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string_default(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Room type' /></div>
                            		<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_default_roomtypes.length ? (<RoomType_Table display_roomtypes={display_default_roomtypes} tab={default_mode}/>) : (<Filler initialized={initialized_default}/>)}</div>
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={custom_room_types && custom_room_types.length?'tabs1':'tabs2'} style={{fontSize:'12px'}}>
									<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string_custom(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Room type' /></div>
                            		<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_custom_roomtypes.length ? (<RoomType_Table display_roomtypes={display_custom_roomtypes} tab={custom_mode}/>) : (<Filler initialized={initialized_custom}/>)}</div>
								</TabPane>
							</TabContent>
                        </Card>
                    </Col>
                </Row>
        </Main_Grid>
    )
}

export default RoomType;