const take_sku_snapshot = async (obj_half_src, mtl_half_src, server_path, glb_half_src, scale, sku_id) => {
    try{
        console.log(window)
        var obj_src = obj_half_src?server_path + obj_half_src: null;
        var glb_src = glb_half_src ? server_path + glb_half_src : null;
        var mtl_src = mtl_half_src?server_path + mtl_half_src: null;
        console.log(obj_src,glb_src,mtl_src)
        let { top_colour_image, top_grayscale_image } = await window.postUploadImageExtraction({obj_src, mtl_src, server_path, glb_src, scale})
        console.log('initiating upload')
        console.log('initiating upload====>',top_colour_image,top_grayscale_image)

        top_colour_image = window.dataURLtoBlob(top_colour_image)
        top_grayscale_image = window.dataURLtoBlob(top_grayscale_image)
        return await Promise.all([ window.upload_image({ type: 'colour', sku_id, upl: top_colour_image }), window.upload_image({ type: 'grayscale', sku_id, upl: top_grayscale_image }) ]);
    }catch(err){
        console.log(err)
    }
}

export default take_sku_snapshot;