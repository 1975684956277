import React , { useState, useEffect } from 'react';
import Moment from 'react-moment';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

import Toolbar from '../../components/Toolbar/';
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import Main_Grid from '../../components/Main_Grid/';
import { Link } from "react-router-dom";
import name_sort from '../../utils/name_sort';
import date_sort from '../../utils/date_sort';
import desc_name_sort from '../../utils/desc_name_sort';
import desc_date_sort from '../../utils/desc_date_sort';
import { useAlert } from 'react-alert';

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};

const Filler = ({ initialized }) => {
	const [fetch_progress, set_fetch_progress] = useState(60);
	return (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Design Templates to list  </b></span>&nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching Design Templates</b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;
					</th>
				)}
			</tr>
		</thead>
	</Table>	
	)
};

const DesignTemplates = ({user, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_active_division_id_sidebar, designer_id_mapping, design_templates, fetch_design_templates}) => {
    
    const [search_string , set_search_string] = useState(null);
	const [initialized , set_initialized] = useState(false);
    // const [design_templates, set_design_templates] = useState(null)
    const [display_design_templates, set_display_design_templates] = useState(null)
    // const [designer_id_mapping, set_designer_id_mapping] = useState({})
    const alert = useAlert()

    // const fetch_design_templates = async() => {
    //     try{
    //         let response = await general_fetch({url:'design/get_template_branches'})
    //         console.log("templates", response, 'kk',designer_id_mapping)
    //         let template_branch_ids_array = response && response.store_templates && response.store_templates.length ? response.store_templates.map(x => x.id) :''
    //         let resp = await general_fetch({url: 'design/get_by_branch', body:{design_branch_id:template_branch_ids_array}})
    //         let design_templates_temp = resp && resp.length && resp.map(x => { return {'design_branch_id':x.design_branch_id,'project_name':x.Project.name,'project_id':x.project_id, 'owner':designer_id_mapping[x.designer_id] ,'locked':x.locked, 'last_updated':x.updated_at, 'design_name':x.name}})
    //         console.log("template", design_templates_temp)
    //         set_design_templates(design_templates_temp)
    //     }catch(err){
    //         alert.error("Request Failed")
    //     }
    // }

    const filter_designTemplates = () => {
		var filtered_templates =design_templates && [...design_templates]
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string && filtered_templates && filtered_templates.length){
			filtered_templates = filtered_templates.filter( design_templates => design_templates.design_name.toLowerCase().includes(lower_search_string))
		}
		set_display_design_templates(filtered_templates)
	}

    const unmark_template = async(design_branch_id) => {
        try{
            let resp = await general_fetch({url:'design/unmark_branch_as_template', body:{design_branch_id}})
            alert.success("Unmarked as Template")
            fetch_design_templates()
        }catch(err){
            alert.error("Unmarking Failed")
        }
    }

    useEffect(() => {
        fetch_design_templates()
    },[designer_id_mapping])

    useEffect(() => {
        console.log("design_templates --->", design_templates)
        set_display_design_templates(design_templates)
    },[design_templates])

    useEffect(() => {
        console.log("init", display_design_templates)
		// if (!initialized && display_design_templates && display_design_templates.length) {
		// 	set_initialized(true);
		// }
        if(display_design_templates != null){
		    set_initialized(true);
        }

	}, [display_design_templates]);

    useEffect(() => {
        filter_designTemplates()
    },[search_string])

    useEffect(() => {
        set_active_tab('project_hub')
		set_active_division_id_sidebar('design_templates')
        fetch_design_templates()
        
    },[])

    const DesignTemplates_Table = () => (
        <table style={{height:'100%'}} className="text-left flex_property table_style">
            <thead style={{width:"100%"}}>
                <tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>Design Template name</span></th>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>Design Branch name</span></th>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>From Project</span></th>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>Owner</span></th>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>Status</span></th>
                    <th className='th_style flex_property' style={{flex:'6'}}><span>Last Update</span></th>
                    <th className='th_style flex_property' style={{flex:'8'}}><span>Actions</span></th>
                </tr>
            </thead>

            <tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
            {
                display_design_templates && display_design_templates.length ? display_design_templates.map((single_template,idx)=>(
                    <tr className='tr_style display_onhover'>
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_template.design_name}</span></td>  
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_template.branch_name}</span></td>  
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_template.project_name}</span></td>  
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_template.owner}</span></td>  
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_template.locked?'Locked':'Not Locked'}</span></td>  
                        <td className='td_style flex_property' style={{flex:'6'}}><Moment format="DD/MM/YYYY">{single_template.updated_at}</Moment></td>  
                        <td className='td_style flex_property x1' style={{flex:'8'}}><span className='table_text_overflow hide' onClick = {() => unmark_template(single_template.design_branch_id)}>Unmark as Template</span></td>  
                    </tr>
                )):''
            }
            </tbody>
        </table>
    )   

    try{
        return (
            <Main_Grid active_tab="Project Statuses" user={user} store_details={store_details} get_store_details={get_store_details}>
                <Row style={{padding:'20px 10px', color:'#22272e'}}>
                    <Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
                    {
                        design_templates && design_templates.length == 1?
                        'Viewing 1 Design Template'
                        :
                        <>Viewing {design_templates && design_templates.length ? design_templates.length : '0'} Design Templates</>
                    }
                    </Col>
                </Row>
                
                <Row>
                <Col style={{height:'100%'}}>
                    <Card style={{borderRadius: 0, height:'100%'}}>
                        <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Design Template name' /></div>
                        <div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_design_templates && display_design_templates.length ? (<DesignTemplates_Table/>) : (<Filler initialized={initialized}/>)}</div>
                    </Card>
                </Col>
                </Row>
            </Main_Grid>
        )
    }catch(err){
        console.log("templates errr", err)
    }
}

export default DesignTemplates;