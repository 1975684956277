import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'

const ImportWarning = ({open, onclick_handleClose}) => {
    return (
        <div>
            <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={() => onclick_handleClose()}>
                <Card className='global_modal_height_for_xs'>
                    <CardHeader className='global_modal_header'>
                        Warning!
                    </CardHeader>
                    <CardBody className='global_modal_body'> 
                        Add additional categories to the imported list or use predefined Infurnia Room Types
                    </CardBody>
                    <CardFooter className='global_modal_footer'>
                        <Button className='blue_button' type="button" onClick={() => onclick_handleClose()}>OK</Button>
                    </CardFooter>
                </Card>
            </Modal>
        </div>
    )
}

export default ImportWarning