import React, { useState , useEffect, isValidElement, useRef } from 'react';
import {Modal, FormGroup, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Fade, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from '../SKU_Groups/GroupInventoryCard';
import Group_ListView from '../SKU_Groups/Group_ListView';
import { useAlert } from 'react-alert'
import '../SKU_Groups/sku_groups.css'
import LazyLoad from 'react-lazyload';
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import { act } from 'react-dom/test-utils';
import Upload3d_modal_modal from '../../../components/Upload_3d_modal';
import Add_new_material from '../../../components/Add_new_material';
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import AddSubCategory from '../SKU_Groups/AddSubCategory';
import AddNewGroup from '../SKU_Groups/AddNewGroup';
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import KeyValuePair from '../../../components/KeyValuePair';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import './bulk_edit.css'
import * as Sentry from "@sentry/react";
import BulkSelectedSKUsPreview from './BulkSelectedSKUsPreview';
import EditCommonProperties from './EditCommonProperties';
import ChooseEditingMode from './ChooseEditingMode';
import EditIndividualProperties from './EditIndividualProperties';

const BulkEditModal = ({open, handleClose, set_page_loader, user, orignal_tags, bulk_selected_skus, no_of_bulk_selected_skus_in_subcat, sku_category_name, sku_category_id, sku_category_type, active_division_id, no_of_bulk_selected_skus_in_grp, sku_sub_categories, exit_bulk_selection_mode}) => {

    const [original_sku_array, set_original_sku_array] = useState([]);
    const [edited_sku_array, set_edited_sku_array] = useState([]);
    const [bulk_selected_hierarchical_data, set_bulk_selected_hierarchical_data] = useState([]);
    const [sub_cats_included, set_sub_cats_included] = useState([])
    const [preview_mode, set_preview_mode] = useState(true);
    const [choosing_editing_mode, set_choosing_editing_mode] = useState(false);
    const [editing_common_props, set_editing_common_props] = useState(false);
    const [editing_individual_props, set_editing_individual_props] = useState(false);
    const [individual_props_confirmation_mode, set_individual_props_confirmation_mode] = useState();
    const [common_props_confirmation_mode, set_common_props_confirmation_mode] = useState();
    const [all_sales_channels, set_all_sales_channels] = useState([]);
    const [eligible_tags, set_eligible_tags] = useState();
    const [is_allow_material, set_is_allow_material] = useState();
    const [is_allow_material_and_depth, set_is_allow_material_and_depth] = useState();
    const [all_brands, set_all_brands] = useState();
    const [tags_at_all_levels, set_tags_at_all_levels] = useState();
    const [original_sales_channels, set_original_sales_channels] = useState({});
    const [original_prices, set_original_prices] = useState({});
    const [active_attempt_id, set_active_attempt_id] = useState();
    const [category_types_data, set_category_types_data] = useState(null)
    const [default_pricing_unit, set_default_pricing_unit] = useState('per_unit')
    const [default_pricing_dimension, set_default_pricing_dimension] = useState('')
    const [pricing_dimension_options, set_pricing_dimension_options] = useState([]);
    const alert = useAlert()
    
    // const [division_tree, set_division_tree] = useState([]);


    // const fetch_division_tree = async() => {
    //     try{
    //         set_page_loader({
    //             show:true,
    //             text:'Please wait...'
    //         })
    //         let resp = general_fetch({url:'inventory/get_all_sub_categories'})
    //         if(resp) set_division_tree(resp)
    //         set_page_loader({
    //             show:false
    //         })
    //     }catch(err){
    //         console.log(err)
    //     }
    // }
    const fetch_groups = async() => {
        try{
            set_page_loader({
                show:true,
                text:'Please wait...'
            })
            let resp = await general_fetch({url:'inventory/get_groups', body: { sku_sub_category_id:sub_cats_included }})
            
            if(resp && resp.length){
                let temp_hierarchical_data = {}
                for(let i=0;i<resp.length;i++){
                    // console.log('final_json_data', resp, resp[i])
                    let single_group = resp[i]
                    if(no_of_bulk_selected_skus_in_grp[single_group.id] > 0){
                        let sub_cat_id = single_group.sku_sub_category_id
                        let skus = single_group.sku
                        let new_skus = []
                        if(skus && skus.length){
                            for(let j=0;j<skus.length;j++){
                                // console.log('final_json_data3', resp[i])
                                if(bulk_selected_skus[skus[j].id])
                                    new_skus.push(skus[j])
                            }
                        }
                        single_group.sku = new_skus
                        console.log('final_json_data3',skus)
                        if(temp_hierarchical_data[sub_cat_id]){
                            temp_hierarchical_data[sub_cat_id].push(single_group)
                        }else{
                            temp_hierarchical_data[sub_cat_id] = [single_group]
                        } 
                    }
                }

                let temp_hierarchical_data_array = []
                let myArr = Object.keys(temp_hierarchical_data)
                if(myArr && myArr.length){
                    for(let i = 0; i < myArr.length; i++){
                        let sub_cat = sku_sub_categories && sku_sub_categories.length ? sku_sub_categories.find(o => o.id == myArr[i]) : ''
                        let json_data = {name: sub_cat ? sub_cat.name : '', id: myArr[i], sku_groups: temp_hierarchical_data[myArr[i]]}
                        temp_hierarchical_data_array.push(json_data)
                        console.log('final_json_data', json_data)
                    }
                }
                set_bulk_selected_hierarchical_data(temp_hierarchical_data_array)
            }
            set_page_loader({
                show:false
            })
        }catch(err){
            console.log(err)
        }
    }

    const fetch_sku_details = async(sku_ids) => {
        try{
            let resp = await general_fetch({url:'sku/get', body: {identifiers:JSON.stringify({id:sku_ids})}})
            set_original_sku_array(resp)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_sales_channel_details = async() => {
		try {
			let resp = await general_fetch({ url: 'sales_channel/get'});
			set_all_sales_channels(resp);
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_eligible_tags = async() => {
        try{
            let resp = await general_fetch({url:'sku_category/get_eligible_tags', body:{id: sku_category_id}})
            set_eligible_tags(resp)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_brand_details = async () => {
		try {
			var brand_details = await general_fetch({ url: 'brand/get'});
			set_all_brands(brand_details);
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_tags_at_all_levels = async(sku_ids) => {
        try{
            let resp = await general_fetch({url:'sku/get_tags', body:{ids:[sku_ids]}})
            set_tags_at_all_levels(resp)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_original_sales_channels = async(sku_ids) => {
        try{
            var identifiers = JSON.stringify({store_id : user.store_id , sku_id : sku_ids})
			var attributes = JSON.stringify({})
			var body = {identifiers , attributes}
            let resp = await general_fetch({url:'sales_channel/get', body})
            set_original_sales_channels(resp)
        } catch(err){
            console.log(err)
        }
    }

    const fetch_original_prices = async(sku_ids) => {
        try{
            let resp = await general_fetch({url:'sku/get_all_prices_new', body:{id:sku_ids}})
            set_original_prices(resp)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_category_types_data = async() => {
        let response = await general_fetch({url:'sku_category_type/get', body:{identifiers:JSON.stringify({sku_division_id:active_division_id})}})
        set_category_types_data(response)
    }

    const create_attempt_and_trigger = async(final_sku_array) => {
        try{
            if(sku_category_id && final_sku_array && final_sku_array.length){
                let resp = await general_fetch({url:'sku_bulk_operation/create_attempt', body:{sku_category_id, type:'update'}})
                let attempt_id = resp.id

                let response = await general_fetch({url:'sku_bulk_operation/trigger_attempt', body: {bulk_operation_attempt_id:attempt_id, data:final_sku_array}})
                onclick_handleClose()
                exit_bulk_selection_mode()
                alert.success("Bulk Edit Successfull !")
            }
        }catch(err){
            alert.error("Bulk Edit Failed !")
            console.log(err)
        }
    }

    const onclick_handleClose = () => {
        set_original_sku_array([])
        set_original_sales_channels({})
        set_original_prices({})
        set_bulk_selected_hierarchical_data([])
        set_sub_cats_included([])
        set_preview_mode(true)
        set_choosing_editing_mode(false)
        set_editing_common_props(false)
        set_editing_individual_props(false)
        set_individual_props_confirmation_mode(false)
        set_common_props_confirmation_mode(false)
        set_is_allow_material(false)
        set_is_allow_material_and_depth(false)
        set_default_pricing_unit('none')
        set_default_pricing_dimension(null)
        set_pricing_dimension_options([])
        handleClose()
    }

    useEffect(() => {
        let sku_ids=[]
        if(bulk_selected_skus && open){
            let myArr = Object.keys(bulk_selected_skus)
			if(myArr && myArr.length){
				for(let i = 0; i < myArr.length; i++){
					if(bulk_selected_skus[myArr[i]]){
						sku_ids.push(myArr[i])
					}
				}
			}
            fetch_sku_details(sku_ids)
            fetch_tags_at_all_levels(sku_ids)
            fetch_original_sales_channels(sku_ids)
            fetch_original_prices(sku_ids)
            fetch_category_types_data()
        }
    }, [bulk_selected_skus, open]);

    useEffect(() => {
        let temp=[]
        if(no_of_bulk_selected_skus_in_subcat && open){
            let myArr = Object.keys(no_of_bulk_selected_skus_in_subcat)
            if(myArr && myArr.length){
                for(let i = 0; i < myArr.length; i++){
                    if(no_of_bulk_selected_skus_in_subcat[myArr[i]])
                        temp.push(myArr[i])
                }
            }
            set_sub_cats_included(temp)
        }
    }, [no_of_bulk_selected_skus_in_subcat, open]);

    useEffect(() => {
        if(open && sku_category_id)fetch_eligible_tags()
    }, [sku_category_id, open]);

    useEffect(() => {
        if(open) fetch_brand_details()
    }, [open]);


    // useEffect(() => {
    //     if(division_tree && division_tree.length && bulk_selected_skus){
    //         let division_data = division_tree.find(o => o.id == active_division_id)
    //         let category_data =  division_data && division_data.sku_category && division_data.sku_category.length ? division_data.sku_category.find(o => o.id == sku_category_id) : {}
    //         let sub_category_data = category_data && category_data.sku_sub_category ? category_data.sku_sub_category : []

    //         if(sub_category_data && sub_category_data.length){
    //             for(let i=0;i<sub_category_data.length;i++){
    //                 let single_sub_category = sub_category_data[i]

    //             }
    //         }
    //     }
    // }, [division_tree, bulk_selected_skus]);

    useEffect(() => {
        if(open){
            fetch_sales_channel_details()
        }
    }, [open]);

    useEffect(() => {
        if(sub_cats_included && sub_cats_included.length){
            fetch_groups()
        }
    }, [sub_cats_included]);

    useEffect(() =>{
		if (!open) {
			return;
		}
        if(active_division_id && sku_category_type){
            if(active_division_id==='finish'|| (active_division_id==='hardware'&& (sku_category_type==='panel_core_hardware' || sku_category_type==='mirror_hardware'))){
                set_is_allow_material(true)
            }
            if(active_division_id==='hardware' && (sku_category_type==='skirting_hardware'||sku_category_type==='edge_band_hardware')){
                set_is_allow_material_and_depth(true)
                set_is_allow_material(true)
            }
        }
	},[active_division_id, sku_category_type, open])

    useEffect(() => {
        if(sku_category_type && category_types_data){
            let temp = category_types_data.find(o => o.id == sku_category_type)
            if(temp){
                set_default_pricing_unit(temp.pricing_unit)
                set_default_pricing_dimension(temp.pricing_dimension)
                let options = []
                let temp_pricing_dimension_options = temp.pricing_dimension_options
                if(temp_pricing_dimension_options && temp_pricing_dimension_options.values && temp_pricing_dimension_options.values.length){
                    temp_pricing_dimension_options.values.map((pricing_dimension) => {
                        if (pricing_dimension == 'per_unit_length') {
                            options = options.concat([{id:'metre', text:'Metre'},{id:'millimetre', text:'Millimetre'} ,{id:'foot', text:'Foot'}]);
                        } else if (pricing_dimension == 'per_unit_area') {
                            options = options.concat([{id:'sq_metre', text:'Square Meter'},{id:'sq_millimetre', text:'Square Millimetre'} ,{id:'sq_foot', text:'Square Foot'}]);
                        } else if (pricing_dimension == 'per_unit_volume') {
                            options = options.concat([{id:'cu_metre', text:'Cubic Meter'},{id:'cu_millimetre', text:'Cubic Millimetre'} ,{id:'cu_foot', text:'Cubic Foot'}]);
                        } else if (pricing_dimension == 'per_unit_weight') {
                            options = options.concat([{id:'kilogram', text:'kilogram'},{id:'gram', text:'Gram'} ,{id:'pound', text:'Pound'}])
                        }else if (pricing_dimension == 'per_set'){
                            options = options.concat([{id:'per_set', text:'Per Set'}])
                        } else {
                            options = options.concat([{id:'per_unit', text:'Per Unit'}])
                        }
                        console.log('set_pricing_dimension_options', options, pricing_dimension);
                    })
                }
                set_pricing_dimension_options(options)
            }
        }
    },[sku_category_type, category_types_data])

    return(
        <Modal className='bulk_edit_modal' isOpen={open} toggle={() => {onclick_handleClose()}}>
            <Card className='bulk_edit_card'>
                <div style={{display:preview_mode?'block':'none', height:'100%'}}>
                    <CardHeader className='global_modal_header'>
                        Selection Preview for Bulk Edit
                        <i onClick={onclick_handleClose} style={{cursor:'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <CardBody style={{backgroundColor:'#F5F6F8', height:'calc(100% - 84px)'}}>
                        <BulkSelectedSKUsPreview
                            user = {user}
                            original_sku_array = {original_sku_array} 
                            sub_cats_included = {sub_cats_included} 
                            bulk_selected_hierarchical_data = {bulk_selected_hierarchical_data} 
                            sku_category_name = {sku_category_name}
                            no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
                        />
                    </CardBody> 
                    <CardFooter className='global_modal_footer'>
                        <Button className='white_button' onClick={onclick_handleClose}>Go back to selection</Button>
                        <Button className='blue_button' onClick={() => {set_preview_mode(false);set_choosing_editing_mode(true)}}>Proceed to editing</Button>
                    </CardFooter>
                </div>

                <div style={{display:choosing_editing_mode?'block':'none', height:'100%'}}>
                    <CardHeader className='global_modal_header'>
                        Bulk Edit
                        <i onClick={onclick_handleClose} style={{cursor:'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <ChooseEditingMode
                        user = {user}
                        original_sku_array = {original_sku_array} 
                        sub_cats_included = {sub_cats_included} 
                        bulk_selected_hierarchical_data = {bulk_selected_hierarchical_data} 
                        sku_category_name = {sku_category_name}
                        no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
                        set_choosing_editing_mode = {set_choosing_editing_mode}
                        set_editing_common_props = {set_editing_common_props}
                        set_editing_individual_props = {set_editing_individual_props}
                    />
                    <CardFooter className='global_modal_footer'>
                        <Button className='white_button' onClick={() => {set_choosing_editing_mode(false);set_preview_mode(true)}}>Go back</Button>
                    </CardFooter>
                </div>

                <div style={{display:(editing_common_props || common_props_confirmation_mode)?'block':'none', height:'100%'}}>
                    <CardHeader className='global_modal_header'>
                        Bulk Edit
                        <i onClick={onclick_handleClose} style={{cursor:'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <EditCommonProperties
                        user = {user}
                        orignal_tags = {orignal_tags}
                        eligible_tags = {eligible_tags}
                        all_sales_channels = {all_sales_channels}
                        all_brands = {all_brands}
                        original_sku_array = {original_sku_array} 
                        original_sales_channels = {original_sales_channels}
                        original_prices = {original_prices}
                        sub_cats_included = {sub_cats_included} 
                        bulk_selected_hierarchical_data = {bulk_selected_hierarchical_data} 
                        sku_category_name = {sku_category_name}
                        active_division_id = {active_division_id}
                        sku_category_type = {sku_category_type}
                        no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
                        edited_sku_array = {edited_sku_array}
                        set_edited_sku_array = {set_edited_sku_array}
                        is_allow_material = {is_allow_material}
                        is_allow_material_and_depth = {is_allow_material_and_depth}
                        tags_at_all_levels = {tags_at_all_levels}
                        set_choosing_editing_mode = {set_choosing_editing_mode}
                        editing_common_props = {editing_common_props}
                        set_editing_common_props = {set_editing_common_props}
                        common_props_confirmation_mode = {common_props_confirmation_mode}
                        set_common_props_confirmation_mode = {set_common_props_confirmation_mode}
                        create_attempt_and_trigger = {create_attempt_and_trigger}
                    />
                </div>

                <div style={{display:(editing_individual_props || individual_props_confirmation_mode)?'block':'none', height:'100%'}}>
                    <CardHeader className='global_modal_header'>
                        Bulk Edit
                        <i onClick={onclick_handleClose} style={{cursor:'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <EditIndividualProperties
                        user = {user}
                        orignal_tags = {orignal_tags}
                        eligible_tags = {eligible_tags}
                        all_sales_channels = {all_sales_channels}
                        all_brands = {all_brands}
                        original_sku_array = {original_sku_array} 
                        original_sales_channels = {original_sales_channels}
                        original_prices = {original_prices}
                        sub_cats_included = {sub_cats_included} 
                        bulk_selected_hierarchical_data = {bulk_selected_hierarchical_data} 
                        sku_category_name = {sku_category_name}
                        active_division_id = {active_division_id}
                        sku_category_type = {sku_category_type}
                        no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
                        edited_sku_array = {edited_sku_array}
                        set_edited_sku_array = {set_edited_sku_array}
                        is_allow_material = {is_allow_material}
                        is_allow_material_and_depth = {is_allow_material_and_depth}
                        tags_at_all_levels = {tags_at_all_levels}
                        set_choosing_editing_mode = {set_choosing_editing_mode}
                        editing_individual_props = {editing_individual_props}
                        set_editing_individual_props = {set_editing_individual_props}
                        individual_props_confirmation_mode = {individual_props_confirmation_mode}
                        set_individual_props_confirmation_mode = {set_individual_props_confirmation_mode}
                        create_attempt_and_trigger = {create_attempt_and_trigger}
                        default_pricing_unit = {default_pricing_unit}
                        default_pricing_dimension = {default_pricing_dimension}
                        pricing_dimension_options = {pricing_dimension_options}
                    />
                </div>

                {/* <div style={{display:confirmation_mode?'block':'none', height:'100%'}}>
                    <CardHeader className='global_modal_header'>
                        Bulk Edit
                        <i onClick={onclick_handleClose} style={{cursor:'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <CardBody style={{backgroundColor:'#F5F6F8', height:'calc(100% - 84px)'}}>

                    </CardBody>
                    <CardFooter className='global_modal_footer'>
                        <Button className='white_button' onClick={() => {set_confirmation_mode(false)}}>Go back</Button>
                        <Button className='blue_button'>Publish changes and exit</Button>
                    </CardFooter>
                </div> */}
            </Card>
        </Modal>
    )
}

export default BulkEditModal;
