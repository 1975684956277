import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'
import './index.js';
import Multiselect from '../Multiselect';
import MultipleSkuSelection from '../MultipleSkuSelection';

const applicble_properties_options = [
    {id:'tag', name:'Tag',type:'multiselect'},
    {id:'height', name:'Height',type:'number'},
    {id:'width', name:'Width',type:'number'},
    {id:'depth', name:'Depth',type:'number'},
    {id:'no_condition', name:'No Condition',type:'no_condition'}
]


const DCKeyValuePair = ({ open, item_json, idx, rule_type_json, single_item_json, set_item_json, rule_type, mode, tags, sales_channels, position_type, combination_logic, set_combination_logic, fixed_component_id, component_options_json, set_show_wrong_configuration_modal, drawer_ids_from_component_file, all_division_tree_data, set_page_loader }) => {


    const alert = useAlert()
    const [ component_selection_enabled, set_component_selection_enabled ] = useState(true);
    const [ selected_comp , set_selected_comp ] = useState('');
    const [ selected_sub_comp, set_selected_sub_comp ] =useState('');
    const [ selected_property, set_selected_property ] =useState([])

    const [ component_options, set_component_options ] = useState([]);
    const [ sub_component_options, set_sub_component_options ] = useState([]);
    const [ properties_options, set_properties_options ] =useState([])

    const [values_array, set_values_array] =useState([])
    const [selected_tag_1_array,set_selected_tag_1_array] = useState([])
    const [selected_sales_channel_array,set_selected_sales_channel_array] = useState([])
    const [prop_disabling,set_prop_disabling] = useState(false);
    const [selected_value, set_selected_value] = useState([]);
    const [selected_sku_array, set_selected_sku_array] = useState();
    // const [selected_drawer_ids_array, set_selected_drawer_ids_array] = useState([]);

    useEffect(() => {
        console.log("sales channels recs", sales_channels);
        if (selected_property && selected_property.length && selected_property[0].id==='sales_channel' && sales_channels && sales_channels.length) {
            // set_prop_disabling(true)
            set_values_array(sales_channels)
        }
    }, [sales_channels])

    useEffect(() => { 
        console.log('item_json_source_k', item_json, "single item", single_item_json, "rule type json", rule_type_json, "rule type", rule_type)
        if(single_item_json){
            console.log('single_item_json Major lOg=======>',single_item_json)
            if(position_type==='applicable'){
                console.log('in applicable=======>',single_item_json, component_options_json)

                // set_selected_sub_comp('cabinet')
                // set_selected_comp('cabinet')
                // set_properties_options(applicble_properties_options)
                // var x_selected_property = single_item_json.property && applicble_properties_options && applicble_properties_options.length? applicble_properties_options.filter(x => x.id===single_item_json.property):[]
                // set_selected_property(x_selected_property)
                // if(x_selected_property && x_selected_property[0] && x_selected_property[0].id==='tag'){
                //     var x_values = single_item_json.tag && single_item_json.tag.length && tags.length ? tags.filter(x => single_item_json.tag.includes(x.id)):[]
                //     set_selected_tag_1_array(x_values)
                //     set_values_array(tags)
                // }

                if (component_options_json && component_options_json.length) {
                    let sub_comp_sel = (single_item_json.subcomponent && single_item_json.subcomponent.length) ? single_item_json.subcomponent : component_options_json[0].sub_component_options[0].id;
                    let comp_sel = single_item_json.component && single_item_json.component.length ? single_item_json.component : component_options_json[0].id;
                    console.log("applicable component_options_json", component_options_json, sub_comp_sel, comp_sel);
                    set_component_options(component_options_json);
                    set_selected_sub_comp(sub_comp_sel);
                    set_selected_comp(comp_sel);
                } else {
                    console.error('no options available for component applicable');
                }
            }
            
            console.log("before if", rule_type)
            if(rule_type && rule_type_json[rule_type]){
                console.log("entered if ", rule_type)
                if(position_type==='source') {
                    if (rule_type_json[rule_type].source_options && rule_type_json[rule_type].source_options.length) {
                        let component_options_map = rule_type_json[rule_type].source_options.reduce((final, elem) => ({
                            ...final,
                            [elem.component_id]: {
                                id: elem.component_id,
                                name: elem.component_name,
                                sub_component_options: rule_type_json[rule_type].source_options.filter((y, idx, arr) => (y.component_id === elem.component_id)).map(y => ({
                                    id: y.subcomponent_id,
                                    name: y.subcomponent_name,
                                    property: y.property
                                }))
                            }
                        }), {});
                        console.log("FSET Value ==> ", component_options_map, Object.values(component_options_map))
                        set_component_options(Object.values(component_options_map));
                    } else {
                        set_component_options([{
                            id: rule_type_json[rule_type].source.component_id,
                            name: rule_type_json[rule_type].source.component_name,
                            sub_component_options: [{
                                id: rule_type_json[rule_type].source.subcomponent_id,
                                name: rule_type_json[rule_type].source.subcomponent_name,
                                property: rule_type_json[rule_type].source.property
                            }]
                        }]);
                    }
                    set_selected_sub_comp((single_item_json.subcomponent && single_item_json.subcomponent.length) ? single_item_json.subcomponent : rule_type_json[rule_type].source.subcomponent_id)
                    set_selected_comp(single_item_json.component && single_item_json.component.length ? single_item_json.component : rule_type_json[rule_type].source.component_id)
                }
                if(position_type==='dest'){
                    if (rule_type_json[rule_type].destination_options && rule_type_json[rule_type].destination_options.length) {
                        set_component_options(rule_type_json[rule_type].destination_options.map(x => ({
                            id: x.component_id,
                            name: x.component_name,
                            sub_component_options: rule_type_json[rule_type].destination_options.filter((y, idx, arr) => (y.component_id === x.component_id) ).map(y => ({
                                id: y.subcomponent_id,
                                name: y.subcomponent_name,
                                property: y.property
                            }))
                        })));
                    } else {
                        console.log("interesting ---> ", rule_type_json[rule_type])
                        set_component_options([{
                            id: rule_type_json[rule_type].dest.component_id,
                            name: rule_type_json[rule_type].dest.component_name,
                            sub_component_options: [{
                                id: rule_type_json[rule_type].dest.subcomponent_id,
                                name: rule_type_json[rule_type].dest.subcomponent_name,
                                property: rule_type_json[rule_type].dest.property
                            }]
                        }]);
                    }
                    console.log("setting sub_component -- .>> ", (single_item_json.subcomponent && single_item_json.subcomponent.length) ? single_item_json.subcomponent : rule_type_json[rule_type].dest.subcomponent_id);
                    set_selected_sub_comp((single_item_json.subcomponent && single_item_json.subcomponent.length) ? single_item_json.subcomponent : rule_type_json[rule_type].dest.subcomponent_id)
                    set_selected_comp(single_item_json.component && single_item_json.component.length ? single_item_json.component : rule_type_json[rule_type].dest.component_id)
                }
            }
        }
	},[ single_item_json, component_options_json ])


    useEffect(() =>{
        if (position_type === 'source') {
            console.log("change source--->> ", selected_comp, selected_sub_comp, sub_component_options, selected_property, component_options);
        }
        if(selected_comp && selected_comp.length && component_options && component_options.length){
            console.log("finger groove", component_options, selected_comp, component_options.filter(option => option.id === selected_comp)[0], component_options.filter(option => option.id === selected_comp))
            update_item_json(idx,'component',selected_comp);

            let temp = component_options.filter(option => option.id === selected_comp)
            
            if(temp && temp.length){
                console.log("printing_temp", temp)
                set_sub_component_options(temp[0].sub_component_options);
            }else{
                set_show_wrong_configuration_modal(true)
                return
            }
            if (position_type === 'source') {
                console.log("change source setting sub_component options ---> ", component_options.filter(option => option.id === selected_comp)[0].sub_component_options);
            }
            set_sub_component_options(component_options.filter(option => option.id === selected_comp)[0].sub_component_options);
            if (!selected_sub_comp || !selected_sub_comp.length) {
                set_selected_sub_comp(component_options.filter(option => option.id === selected_comp)[0].sub_component_options[0].id);
            }
            if (sub_component_options && sub_component_options.length && !sub_component_options.filter(option => option.id === selected_sub_comp).length) {

                set_selected_sub_comp(component_options.filter(option => option.id === selected_comp)[0].sub_component_options[0].id);
            }
        }
        if(selected_sub_comp && selected_sub_comp.length && sub_component_options && sub_component_options.length){
            update_item_json(idx,'subcomponent',selected_sub_comp)
            if (position_type === 'source') {
                console.log('change source selected sub component option --> ', sub_component_options.filter(option => option.id === selected_sub_comp)[0]);
            }
            try {
                set_properties_options(sub_component_options.filter(option => option.id === selected_sub_comp)[0].property)
            } catch(err) {
                console.error(err);
                console.log("ERROR LOGS  ___>>>>> ", selected_comp, selected_sub_comp, sub_component_options, selected_property, component_options);
            }
        }
        if(selected_property && selected_property.length){
            update_item_json(idx,'property',selected_property)
        }
	},[selected_sub_comp, selected_comp, selected_property, sub_component_options])

    useEffect(() =>{
        if (properties_options && properties_options.length) {
            console.log("properties options changed ---> ", properties_options, single_item_json);
            let x_selected_property = [];
            if (single_item_json.property) {
                x_selected_property = properties_options.filter(option => option.id === single_item_json.property);
            } else {
                x_selected_property = properties_options[0].id;
            }
            
            console.log("x_selected_property ---> ", x_selected_property);
            set_selected_property(x_selected_property);
            if(x_selected_property && x_selected_property[0] && x_selected_property[0].id==='no_condition'){
                set_prop_disabling(true)
            }
            if(x_selected_property && x_selected_property[0] && x_selected_property[0].id==='tag'){
                let x_values = single_item_json.tag && single_item_json.tag.length && tags.length ? tags.filter(x => single_item_json.tag.includes(x.id)):[]
                set_selected_tag_1_array(x_values)
                set_values_array(tags)
                console.log("x_selected_property tagggg---> ", x_selected_property, item_json);
                update_item_json(idx,'tag',x_values)
            }

            if(x_selected_property && x_selected_property[0] && x_selected_property[0].type==='singleselect' && x_selected_property[0].options &&  x_selected_property[0].options.length){
                let options = x_selected_property[0].options;
                let x_values = single_item_json.value ? options.filter(x => single_item_json.value == x.id):[]
                console.log("SETTING IN ", options, x_values);
                set_selected_value(x_values)
                set_values_array(options)
                update_item_json(idx,'value',x_values[0].id)
            }

            console.log("sales channel check ---> ", x_selected_property, x_selected_property && x_selected_property[0], x_selected_property && x_selected_property[0] && x_selected_property[0].id==='sales_channel');
            if(x_selected_property && x_selected_property[0] && x_selected_property[0].id==='sales_channel'){
                let x_values = single_item_json.sales_channel && single_item_json.sales_channel.length && sales_channels.length ? sales_channels.filter(x => single_item_json.sales_channel.includes(x.id)):[]
                set_selected_sales_channel_array(x_values)
                console.log('inside sales channel');
                console.log("sales channel array ---> ", x_values);
                set_values_array(sales_channels)
                update_item_json(idx,'sales_channel',x_values)
            }

            if(x_selected_property && x_selected_property[0] && x_selected_property[0].id === 'drawer_id' && drawer_ids_from_component_file && drawer_ids_from_component_file.length){
                let options = drawer_ids_from_component_file
                let values = JSON.parse(single_item_json.value)
                let x_values = values && values.length && options && options.length ? options.filter(x => values.includes(x.id)):[]
                set_selected_value(x_values)
                set_values_array(drawer_ids_from_component_file)
                update_item_json(idx,'value',x_values && x_values.length ? x_values : [])
            }

            if(x_selected_property && x_selected_property[0] && x_selected_property[0].id === 'sku'){
                let values = JSON.parse(JSON.stringify(single_item_json.sku))
                console.log('single_item_json', single_item_json)
                get_sku_ancestry(single_item_json.sku)
            }
        }
    }, [ properties_options ]);
    

    const get_sku_ancestry = async(sku_ids) => {
        if(sku_ids && sku_ids.length){
            try{
                let resp = await general_fetch({url:'inventory/get_sku_ancestry', body:{sku_id: sku_ids}})
                let temp_skus = resp && resp.length ? resp.map(single_sku => {return {category: single_sku.sku_category_data[0].id, sub_category: single_sku.sku_sub_category_data[0].id, group: single_sku.sku_group_data[0].id, id: single_sku.id}}) : []
                set_selected_sku_array(temp_skus)
                // update_item_json(idx, 'sku', temp_skus)
            }catch(err){
                console.log(err)
            }
        }
    }

    const change_sub_component = sub_component_option_id => {
        let sub_component_option = sub_component_options.filter(x => x.id == sub_component_option_id)[0];
        console.log("trying change sc --->> ", sub_component_option_id)
        set_selected_sku_array([])
        if (sub_component_option) {
            console.log("change sc --> ", sub_component_option, sub_component_option.id, sub_component_option.property)
            set_selected_sub_comp(sub_component_option.id);
            // set_properties_options(sub_component_option.property);
        } else {
            throw new Error("Something went wrong while applying sub component option id -- > ", sub_component_option_id);
        }
    }

    const change_component = component_option_id => {
        let component_option = component_options.filter(x => x.id == component_option_id)[0];
        if (component_option) {
            console.log('component option --->> ', component_option);
            set_selected_comp(component_option.id);
        } else {
            throw new Error("Something went wrong while applying component option id -- > ", component_option_id);
        }
    }

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='property'){
            if(operator==='property'){
                set_selected_property(value) 
                value && value.length && value[0].id==='tag' && set_values_array(tags)
                value && value.length && value[0].type==='singleselect' && set_values_array(value[0].options)
                console.log("set sales channel options", value, value && value.length && value[0].id==='sales_channel');
                if (value && value.length && value[0].id==='sales_channel') {
                    console.log("set sales channel options inside", sales_channels);
                    set_values_array(sales_channels)
                }
                value && value.length &&value[0].type==='range' && update_item_json(idx,'operator','range')
                set_prop_disabling(value && value.length && value[0].id==='no_condition'?true:false)
                
            }
            update_item_json(idx,'value', [])
            update_item_json(idx,'operator','equal')
            set_selected_tag_1_array([])
            console.log('item_json======>',item_json)
        }
        if(operator==='sales_channel'){
            set_selected_sales_channel_array(value)
            // console.log('item_json======>',item_json)
        }
        if(operator==='tag'){
            set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
        if(operator==='singleselect'){
            console.log("drawer_id SETTING VALUE ___>>>> ", value)
            update_item_json(idx,'value', value && value.length && value[0].id? value[0].id : [])
            set_selected_value(value);
        }
        if(operator == 'drawer_id'){
            console.log("drawer_id SETTING VALUE ___>>>> ", value)
            update_item_json(idx,'value', value && value.length ? value : [])
            set_selected_value(value)
        }
        
    }

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    useEffect(() => {
        console.log("editing sales channel tag options", values_array);
    }, [ values_array ])

    useEffect(() => {
        console.log("FIXED COMPONENT --->> ", fixed_component_id);
        if (fixed_component_id && component_options && component_options.length) {
            if (component_options.find(x => x.id === fixed_component_id)) {     
                set_selected_comp(fixed_component_id);
                set_component_selection_enabled(false);
            } else {
                set_component_selection_enabled(true);
            }
        }
    },[fixed_component_id, component_options])

    const handleClose_for_mf__source = () => {
        set_selected_sub_comp('')
        set_selected_comp('')
        set_selected_property('')
        set_prop_disabling(false)
    }

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    useEffect(() => {
        console.log('item_json 1 =====>', item_json)
    }, [item_json]);

    useEffect(() => {
        console.log('single_item_json', single_item_json)
    }, [single_item_json]);

    useEffect(() => {
        if(selected_sku_array) update_item_json(idx, 'sku', selected_sku_array)
    }, [selected_sku_array]);

    return(
        <div style={{ display:'flex', alignItems:'center', marginLeft:position_type==='source' || position_type ==='applicable'?'-50px':0, ...(mode=='view' ? { pointerEvents: 'none' } : {}) }}>
            
            {
                position_type==='source' || position_type ==='applicable'?
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{width:'50px', height:'120px',borderBottom:idx!=0?'1px solid #DDDDDD':'' , borderLeft:idx!=0?'1px solid #DDDDDD':''}}>

                    </div>
                    <div style={{width:'50px', height:'120px', borderTop:item_json.length===idx+1?'':'1px solid #DDDDDD' , borderLeft:item_json.length===idx+1?"":'1px solid #DDDDDD'}}>
                                
                    </div>
                    {
                        item_json.length===idx+1?"":
                        <Input style={{fontSize:'12px', height:'30px', width:'70px', marginLeft:'-35px'}} type="select"
                            id={position_type+'_combination_logic_'+idx}
                            value={combination_logic}
                            onChange={(e) =>set_combination_logic(e.target.value)}
                        >
                            <option value='and'>And</option>
                            <option value='or'>OR</option>
                        </Input>
                    }
                </div>
                :''
            }
            
            <CardBody style={{width:'100%',padding:'15px 20px', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', minHeight:'195px'}}>
                
                <Row style={{marginBottom:'10px'}}>
                    <Col xs={11}>
                        <div style={{fontSize:'16px', color:'black'}}>{position_type == 'dest' ? "Action " : "Condition "} {idx+1}</div>
                    </Col>
                    <Col xs={1} style={{ textAlign:'right'}}>
                        <i onClick={() => delete_item_json(idx)} style={{ color:'#F0656F',fontSize:'14px',cursor:'pointer'}} className='fa fa-trash'/>
                    </Col>
                </Row>

                <Row key={idx} style={{ margin:0}}>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        {
                            <Input 
                                id={position_type+'_component_'+idx} 
                                style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} 
                                type="select"
                                value={selected_comp}
                                disabled={position_type == 'source' ? false : component_selection_enabled ? false : true}
                                onChange={e => change_component(e.target.value)}
                            >
                                {component_options && component_options.length && component_options.map(x => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </Input>
                        }
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        {
                            <Input 
                                id={position_type+'_sub_component_'+idx} 
                                style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} 
                                type="select"
                                value={selected_sub_comp}
                                onChange={e => change_sub_component(e.target.value)}
                            >
                                {sub_component_options && sub_component_options.length && sub_component_options.map(x => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </Input>
                        }
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                            {
                                properties_options && properties_options.length ?
                                    <Multiselect
                                        id={position_type+'_property_'+idx}
                                        options={properties_options} 
                                        selectedValues={selected_property}
                                        onSelect={(selectedList) => onclick_item('property', selectedList)}
                                        onRemove={(selectedList) => onclick_item('property', selectedList)}
                                        displayValue="name" // Property name 
                                        // id={'select_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />  
                                :
                                <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                    <option>Choose a value</option>
                                </Input>
                            }
                    </Col>
                    
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Operator</div>
                        <Input id={position_type+'_operator_'+idx} 
                            style={{opacity:prop_disabling?.5:1,fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto'}} type="select" 
                            value={item_json[idx]?item_json[idx].operator:'none'}
                            onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                            disabled={prop_disabling?true:false}
                            onClick={(e) =>update_item_json(idx,'operator', e.target.value)}
                        >
                            {
                                selected_property && selected_property.length && selected_property[0].type==='number' ?
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='less'>Less Than</option>
                                    <option value='greater'>Greater Than</option>
                                    <option value='lessequal'>Less Than Equal</option>
                                    <option value='greaterequal'>Greater Than Equal</option>
                                    <option value='range'>Range</option>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='notequal'>Not Equal</option>
                                </React.Fragment>
                            }
                        </Input>
                    </Col>
                </Row>
                <Row style={{margin:'16px 10px 10px 100px', marginLeft:selected_property && selected_property.length && selected_property[0].id == 'sku' ? '0px' : '100px'}}>
                    <Col style={{fontSize:'12px', color:'#425A70', padding:'0px'}}>
                        {
                            selected_property && selected_property.length &&selected_property[0].id==='sku'?
                            ''
                            :(
                                selected_property && selected_property.length && selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator=='range' && item_json[idx]?
                                <div style={{marginBottom:'5px'}}>Min-Max</div>
                                :
                                <div style={{marginBottom:'5px'}}>Value</div>
                            )
                        }
                        {
                            (!prop_disabling) && selected_property && selected_property.length && item_json && item_json.length?
                            <React.Fragment>
                                {
                                    selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator!='range' && item_json[idx]?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                        placeholder='Enter a value'
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value', Number(e.target.value)):alert.error('Negative Number not allowed')}
                                    />
                                    :''
                                }
                                {
                                    selected_property[0].type==='number' && item_json[idx] && item_json[idx].operator && item_json[idx].operator==='range' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_min_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='minvalue'
                                                    title='min value'
                                                    value={item_json[idx]?item_json[idx].min_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'min_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                                <Input id={position_type+'_max_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='max value'
                                                    title='max value'
                                                    value={item_json[idx]?item_json[idx].max_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'max_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].id==='tag' || selected_property[0].id==='sales_channel' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'70px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <div style={{width:'calc(100% - 70px)'}}>
                                                    <Multiselect
                                                        style={{backgroundColor:'white'}}
                                                        options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                                        selectedValues={selected_property[0].id==="tag"?selected_tag_1_array : selected_sales_channel_array}
                                                        onSelect={(selectedList) => onclick_item(selected_property[0].id, selectedList)}
                                                        onRemove={(selectedList) => onclick_item(selected_property[0].id, selectedList)}
                                                        displayValue={selected_property[0].id==="tag"?'tag':'name'} // Property name 
                                                        id={position_type+'_value_'+idx}
                                                        placeholder= {selected_property[0].id==="tag"?'Select Tag':'Select Sales Channel'}
                                                    /> 
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].type==='singleselect' ?
                                        <Multiselect
                                            style={{backgroundColor:'white'}}
                                            options={values_array && values_array.length? JSON.parse(JSON.stringify(values_array)):[]} 
                                            selectedValues={selected_value}
                                            onSelect={(selectedList) => onclick_item('singleselect', selectedList)}
                                            onRemove={(selectedList) => onclick_item('singleselect', selectedList)}
                                            displayValue='name' // Property name 
                                            id={position_type+'_value_'+idx}
                                            placeholder= 'Select Option'
                                            selectionLimit={1}
                                        /> 
                                    :''
                                }
                                {
                                    selected_property[0].id === 'drawer_id'?
                                    <Multiselect
                                        style={{backgroundColor:'white'}}
                                        options = {drawer_ids_from_component_file && drawer_ids_from_component_file.length ? JSON.parse(JSON.stringify(drawer_ids_from_component_file)):[]}
                                        selectedValues={selected_value}
                                        onSelect={(selectedList) => onclick_item('drawer_id', selectedList)}
                                        onRemove={(selectedList) => onclick_item('drawer_id', selectedList)}
                                        displayValue = 'name'
                                        id={position_type+'_value_'+idx}
                                    />:''
                                }
                                {
                                    selected_property[0].id === 'sku' ?
                                    <MultipleSkuSelection
                                        sku_array = {selected_sku_array ? selected_sku_array : []}
                                        set_sku_array = {set_selected_sku_array}
                                        all_division_tree_data = {all_division_tree_data}
                                        selected_sub_comp = {selected_sub_comp}
                                        set_page_loader = {set_page_loader}
                                    />:''
                                }
                            </React.Fragment>
                            :
                            <Input disabled={prop_disabling?true:false} id={'box3'+idx} style={{opacity:prop_disabling?.5:1 ,height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                <option value='none'>Choose a value</option>
                            </Input>    
                        }
                    </Col>
                </Row>
            </CardBody>
        </div>
    )
}

export default DCKeyValuePair;

