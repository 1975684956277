import React, { useState, useEffect } from 'react';
import { Modal, CardFooter,Button, Card, CardHeader, CardBody, Input, NavItem, NavLink, Row, Col, TabPane , TabContent ,Nav} from "reactstrap";
import { useAlert } from 'react-alert';

const Show_invalid_item = ({item_array, type, all_sku_data}) => {

    console.log('item_array',item_array,all_sku_data)

    return(
        <div style={{backgroundColor:'white',padding:'10px 15px', fontSize:'16px'}}>
            <div style={{textTransform:'capitalize', fontWeight:600}}>
                {type} : 
            </div>
            <div style={{display:'flex', justifyContent:'space-between', textTransform:'capitalize', margin:'10px 15px 0px 15px', fontWeight:600, fontSize:'14px'}}>
                <span>{type} Name</span>
                <span>Tag Name</span>
            </div>
            
            {
                item_array && item_array.length ? item_array.map((x_item, idx) => 
                    <div key={idx} style={{display:'flex', justifyContent:'space-between', margin:'10px 15px 0px 15px', fontSize:'12px'}}>
                        {/* <span>
                            {type='category'?x_item.sku_category_id:''}
                            {type='sub_category'?x_item.sku_sub_category_id:''}
                            {type='group'?x_item.sku_group_id:''}
                            {type='sku'?x_item.sku_id:''}
                        </span> */}
                        <span>
                            {type='category'?x_item.sku_category_name:''}
                            {type='sub category'?x_item.sku_sub_category_name:''}
                            {type='group'?x_item.sku_group_name:''}
                            {type='SKU'?x_item.sku_name:''}
                        </span>
                        {/* <span>{x_item.tag_id}</span> */}
                        <span>{x_item.tag_name}</span>
                    </div>
                ):''
            }
        </div>
    )
}

const Category_edit_confirm_modal = ({open, handle_close , confirm_onclick_fn, all_sku_data,modal_type}) => {

    const onclick_confirm  = async() => {
        try{

            confirm_onclick_fn()
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        console.log(open)
    },[open])

    const onclick_handleclose = () => {
        handle_close()
    }

    return (
        <Modal size="lg" className='Margin_64px_top' isOpen={open} toggle={onclick_handleclose}>

            <Card className='global_modal_height_for_mdlgxl' style={{boxShadow: '0 0 1px 0 rgba(67,90,111,0.47)', borderRadius: '5px'}}>
                <CardHeader className='global_modal_header'>
                        <span style={{fontSize:'14px', color:'black'}}>
                            Confirm Changes
                        </span>
                        <span onClick={onclick_handleclose} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <i style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
                        </span>
                </CardHeader>
                    
                <CardBody className='global_modal_body'>
                        {
                            all_sku_data && !all_sku_data.sku_category.length && !all_sku_data.sku_sub_category.length && !all_sku_data.sku_group.length && !all_sku_data.sku.length?
                                <div>Category type has changed but there are no tags those are affected.<div>Please click on proceed.</div></div>
                                :
                                <div>Category type has changed. All existing tags(mentioned below) that are not eligible for this category type will be irreversibly removed. Are you sure you want to proceed?</div>
                        }
                        {
                            all_sku_data?
                            <div>
                                {all_sku_data.sku_category && all_sku_data.sku_category.length?<Show_invalid_item all_sku_data={all_sku_data}item_array={all_sku_data.sku_category} type='category'/>:''}
                                {all_sku_data.sku_sub_category && all_sku_data.sku_sub_category.length?<Show_invalid_item item_array={all_sku_data.sku_sub_category} type='sub category'/>:''}
                                {all_sku_data.sku_group && all_sku_data.sku_group.length? <Show_invalid_item item_array={all_sku_data.sku_group} type='group'/>:''}
                                {all_sku_data.sku && all_sku_data.sku.length?  <Show_invalid_item item_array={all_sku_data.sku} type='SKU'/>:''}
                            </div>
                            :'No Data to show'
                        }
                </CardBody>

                <CardFooter className='global_modal_footer'>
                    <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
                        <Button className='white_button' style={{fontSize:'14px', marginRight:'10px'}} type="button" onClick={onclick_handleclose}> Cancel </Button>
                        <Button className='red_button' style={{fontSize:'14px'}} type="button" onClick={onclick_confirm}>Proceed</Button>
                    </div>		
                </CardFooter>
		
            </Card>
		</Modal>
    ) 
}

export default Category_edit_confirm_modal;