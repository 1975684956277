import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert'
import './index.js'
import Multiselect from '../Multiselect';
import AMLinked_sku_structure from '../AMLinked_sku_structure';
import AMKeyValuePair_relative from '../AMKeyValuePair_relative';


const visibility_options =[
    {
        id:'visible',
        name:'Visible',
    },{
        id:'non_visible',
        name:'Non_Visible',
    }

]

const AMKeyValuePair_source = ({open, component_options_json, item_json, idx, single_item_json, set_item_json , mode, all_division_tree_data, tags, all_category, all_sub_category, all_sales_ch, set_combination_logic, combination_logic, is_back_button, position_type, shelf_type_values, all_brands}) => {

    const alert = useAlert()
    const [selected_tag_1_array ,set_selected_tag_1_array ] =useState([])
    const [values_json ,set_values_json ] =useState([])
    
    const [selected_comp , set_selected_comp] = useState([]);
    const [selected_sub_comp,set_selected_sub_comp] =useState([])
    const [selected_property,set_selected_property] =useState([])

    const [linked_item_json,set_linked_item_json] =useState([])

    // const [relative_selected_comp , set_relative_selected_comp] = useState([]);
    // const [relative_selected_sub_comp,set_relative_selected_sub_comp] =useState([])
    // const [relative_selected_property,set_relative_selected_property] =useState([])

    useEffect(() => {
        if(single_item_json){
            console.log('single_item_json =======>',single_item_json)

            //components and subcomponents
            var x_component = component_options_json.filter(x =>x.id===single_item_json.component)
            var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===single_item_json.subcomponent):{}
            var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===single_item_json.property):{}
            var linked_skus = single_item_json && single_item_json.sku && single_item_json.sku.length?single_item_json.sku.map(x => {
				return {
					sku_id:x,
					quantity:''
				}
			}):[] 

            set_linked_item_json(linked_skus)
            set_selected_comp(x_component)
            set_selected_sub_comp(x_sub_component)
            set_selected_property(x_selected_property)

            single_item_json.component=x_component;
            single_item_json.subcomponent=x_sub_component;
            single_item_json.property=x_selected_property;


            //values and tags
            var x_selected_value_list = [];
            if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='tag'){
                x_selected_value_list = tags && tags.length ?tags.filter(x => single_item_json.tag.includes(x.id)):[]
                set_values_json(tags)
                set_selected_tag_1_array(x_selected_value_list)
                single_item_json.tag=x_selected_value_list
            }
            if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='shelf_type'){
                x_selected_value_list = shelf_type_values.filter(x => single_item_json.value.includes(x.id))
                set_values_json(shelf_type_values)
                set_selected_tag_1_array(x_selected_value_list)
                single_item_json.value=x_selected_value_list
            }
            if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='brand'){
                var x_values = JSON.parse(single_item_json.value)
                x_selected_value_list = all_brands && all_brands.length?all_brands.filter(x => x_values.includes(x.id)):[]
                set_values_json(all_brands)
                set_selected_tag_1_array(x_selected_value_list)
                single_item_json.value=x_selected_value_list
            }
            if(x_selected_property && x_selected_property.length && x_selected_property[0].id==='sales_channel'){
                var x_values = single_item_json.sales_channel
                x_selected_value_list = all_sales_ch && all_sales_ch.length?all_sales_ch.filter(x => x_values.includes(x.id)):[]
                set_values_json(all_sales_ch)
                set_selected_tag_1_array(x_selected_value_list)
                single_item_json.sales_channel=x_selected_value_list
            }

        }
	},[single_item_json])

    useEffect(() => {
        console.log('linked item json ', linked_item_json, item_json, single_item_json);
        if(linked_item_json && linked_item_json.length && linked_item_json[0].sku && linked_item_json[0].sku.length){
            update_item_json(idx,'sku', linked_item_json[0].sku)
        }
    },[linked_item_json])

    useEffect(() => {
        if (selected_comp && selected_comp.length && selected_comp[0].id === 'custom_panel_standalone') {
            onclick_item('type', 'relative');
        }
    }, [ selected_comp ])

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='type'){
            // var x_unit = component_options_json.filter(x => x.id===value[0].id)
            if(value==='absolute'){
                update_item_json(idx,'relativecomponent',[]) 
                update_item_json(idx,'relativesubcomponent',[]) 
                update_item_json(idx,'relativeproperty', [])
            }
            if(value==='relative'){
                update_item_json(idx,'division',[])
                update_item_json(idx,'category',[])
                update_item_json(idx,'sub_cat', [])
                update_item_json(idx,'group',[])
                update_item_json(idx,'sku',[])
            }
            // console.log('item_json======>',item_json)
        }
        if(operator==='component'){
            // var x_unit = component_options_json.filter(x => x.id===value[0].id)
            set_selected_comp(value)
            set_selected_sub_comp([])
            set_selected_property('')
            update_item_json(idx,'subcomponent',[]) 
            update_item_json(idx,'property', [])
            // console.log('item_json======>',item_json)
        }
        if(operator==='subcomponent'){
            // var x_sub_unit = selected_comp.sub_component.filter(x => x.id===value[0].id)
            set_selected_sub_comp(value)
            set_selected_property([])
            update_item_json(idx,'property',[])
            // console.log('item_json======>',item_json)
        }
        if(operator==='property'){
            // var x_property = selected_sub_comp.properties.filter(x => x.id===value[0].id) 
            value && value.length && value[0].id==='tag' && set_values_json(tags)
            value && value.length && value[0].id==='shelf_type' && set_values_json(shelf_type_values)
            console.log('shelf_type======>',value)
            set_selected_property(value)
            update_item_json(idx,'operator','equal')
            // console.log('item_json======>',item_json)
        }
        if(operator==='value'){
            selected_property && selected_property.length && ['shelf_type','brand'].includes(selected_property[0].id) && set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
        if(operator==='tag'){
            selected_property && selected_property.length && ['tag'].includes(selected_property[0].id) && set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
        if(operator==='sales_channel'){
            selected_property && selected_property.length && ['sales_channel'].includes(selected_property[0].id) && set_selected_tag_1_array(value)
            // console.log('item_json======>',item_json)
        }
    }

    useEffect(() =>{
		if(item_json){
            if(position_type==='condition'){
				console.log('item_json_condition======>',item_json)
			}else if(position_type==='action'){
				console.log('item_json_action======>',item_json)
			}else{
				console.log('item_json_applicable======>',item_json)
			}
            console.log('item_json_final======>',JSON.stringify(item_json))
		}
	},[item_json])

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_sub_comp([])
        set_selected_property('')
        set_selected_tag_1_array ([])
        set_values_json ([])
    }

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    const fn_check_null = (x) => {
        if(x===null){
            alert.error('value of '+selected_property?selected_property.name+' is no longer available.':'')
        }
        return x;
    }

    // useEffect(() => {
    //     if(selected_property){
    //         if(['tag','all','all_except_tag'].includes(selected_property.id)){
    //             set_values_json(tags)
    //             var tags_list = tags && tags.length && single_item_json.tags && single_item_json.tags.length ? tags.filter(x => single_item_json.tags.includes(x.id)) :[]
    //             set_selected_tag_1_array(tags_list && tags_list.length?tags_list.filter(x=>fn_check_null(x)):[])
    //         }
    //     }
    // },[selected_property, tags])

    return(
        <div style={{display:'flex', alignItems:'center', marginLeft:position_type!='action'?'-50px':'0px'}}>
            
            {
                position_type!='action'?
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{width:'50px', height:'125px',borderBottom:idx!=0?'1px solid #DDDDDD':'' , borderLeft:idx!=0?'1px solid #DDDDDD':''}}>

                    </div>
                    <div style={{width:'50px', height:'125px', borderTop:item_json.length===idx+1?'':'1px solid #DDDDDD' , borderLeft:item_json.length===idx+1?"":'1px solid #DDDDDD'}}>
                                
                    </div>
                    {
                        item_json.length===idx+1?"":
                        <Input style={{fontSize:'12px', height:'30px', width:'70px', marginLeft:'-35px'}} type="select"
                            id={position_type+'_combination_logic_'+idx}
                            value={combination_logic}
                            onChange={(e) =>set_combination_logic(e.target.value)}
                        >
                            <option value='and'>And</option>
                            <option value='or'>OR</option>
                        </Input>
                    }
                </div>
                :''
            }
            
            <CardBody style={{padding:'15px 20px', overflow:'unset', backgroundColor:'white', marginBottom:item_json && item_json.length-1===idx?'0px':'20px', height:'250px'}}>
                {
                    <Row style={{marginBottom:'10px'}}>
                        <Col xs={11}>
                            <div style={{fontSize:'16px', color:'black'}}>{position_type==='action'?'Action':'Condition'} {idx+1}</div>
                        </Col>
                        <Col xs={1} style={{ textAlign:'right'}}>
                            <i onClick={() => delete_item_json(idx)} style={{color:'red',fontSize:'14px',cursor:'pointer'}} className='fa fa-trash'/>
                        </Col>
                    </Row>
                }
                <React.Fragment>
                    {
                        position_type==='applicable'?'':
                        <Row style={{marginBottom:'10px'}}>
                            <Col xs={2} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center'}}>
                                Condition Type:
                            </Col>
                            <Col xs={3} style={{fontSize:'12px', color:'#425A70', display:'flex', alignItems:'center'}}>
                                <Input style={{fontSize:'12px', height:'30px'}} type="select"
                                    id = {position_type+'_condition_type_'+idx}
                                    value={item_json[idx]?item_json[idx].type:'none'}
                                    onChange={(e) =>onclick_item('type', e.target.value)}
                                >
                                    {!selected_comp || !selected_comp.length || selected_comp[0].id != 'custom_panel_standalone' ? <option value='absolute'>Absolute</option>:''}
                                    <option value='relative'>Relative</option>
                                </Input>
                            </Col>
                        </Row>
                    }
                </React.Fragment>
                <Row key={idx} style={{ margin:0}}>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>Component</div>
                        {
                            component_options_json && component_options_json.length?
                                <Multiselect
                                    options={component_options_json}
                                    selectedValues={selected_comp}
                                    onSelect={(selectedList) => onclick_item('component', selectedList)}
                                    onRemove={(selectedList) => onclick_item('component', selectedList)}
                                    displayValue="name" // Property name 
                                    id={position_type+'_component_'+idx}
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                        <div style={{marginBottom:'5px'}}>SubComponent</div>
                        {
                            selected_comp && selected_comp.length && selected_comp[0].sub_component && selected_comp[0].sub_component.length?
                                <Multiselect
                                    options={selected_comp[0].sub_component}
                                    selectedValues={selected_sub_comp}
                                    onSelect={(selectedList) => onclick_item('subcomponent', selectedList)}
                                    onRemove={(selectedList) => onclick_item('subcomponent', selectedList)}
                                    displayValue="name" // Property name 
                                    id={position_type+'_sub_component_'+idx} 
                                    placeholder='Search Value'
                                    selectionLimit={1}
                                />  
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                                <option>Choose a value</option>
                            </Input>
                        }
                    </Col>
                    <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Property</div>
                            {
                                selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].properties &&  selected_sub_comp[0].properties.length ?
                                    <Multiselect
                                        options={selected_sub_comp[0].properties} 
                                        selectedValues={selected_property}
                                        onSelect={(selectedList) => onclick_item('property', selectedList)}
                                        onRemove={(selectedList) => onclick_item('property', selectedList)}
                                        displayValue="name" // Property name 
                                        id={position_type+'_property_'+idx}
                                        placeholder='Search Value'
                                        selectionLimit={1}
                                    />  
                                :
                                <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                    <option>Choose a value</option>
                                </Input>
                            }
                    </Col>
                    
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                        <div style={{marginBottom:'5px'}}>Operator</div>
                        <Input id={position_type+'_operator_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', opacity:selected_property && selected_property[0] && selected_property[0].id==='no_condition'?.5:1}} type="select" 
                            value={item_json[idx]?item_json[idx].operator:'none'}
                            onChange={(e) =>update_item_json(idx,'operator', e.target.value)}
                            disabled={selected_property && selected_property[0] && selected_property[0].id==='no_condition'?true:false}
                        >
                            {
                                selected_property && selected_property.length && selected_property[0].type==='number' && item_json && item_json.length ?
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    {['countertop'].includes(item_json[idx].subcomponent[0].id)?'':<option value='less'>Less Than</option>}
                                    {['countertop'].includes(item_json[idx].subcomponent[0].id)?'':<option value='greater'>Greater Than</option>}
                                    {['countertop'].includes(item_json[idx].subcomponent[0].id)?'':<option value='lessequal'>Less Than Equal</option>}
                                    {['countertop'].includes(item_json[idx].subcomponent[0].id)?'':<option value='greaterequal'>Greater Than Equal</option>}
                                    <option value='range'>Range</option>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <option value='equal'>Equal</option>
                                    <option value='notequal'>Not Equal</option>
                                    {/* {item_json && item_json.length && item_json[idx].property[0] && ['ply_sku','finish_sku','edgeband_sku', 'sku'].includes(item_json[idx].property[0].id)?'':<option value='notequal'>Not Equal</option>} */}
                                </React.Fragment>
                            }
                        </Input>
                    </Col>
                
                    <Col xs={2} style={{fontSize:'12px', color:'#425A70', marginTop:'10px', padding:'0px', display:'flex', alignItems:'flex-start'}}>
                        
                    </Col>

                    
                    <Col xs={10} style={{fontSize:'12px', color:'#425A70', marginTop:'10px', padding:'0px'}}>
                        {
                            selected_property && selected_property.length &&['sku', 'ply_sku','edgeband_sku', 'finish_sku'].includes(selected_property[0].id)?
                            ''
                            :
                            item_json[idx].operator==='range'?'min-max':<div style={{marginBottom:'5px'}}>Value</div>}
                        {
                            selected_property && selected_property.length && item_json && item_json.length?
                            <React.Fragment>
                                {
                                    ['thickness','height','width','depth'].includes(selected_property[0].id) && item_json[idx].operator!='range'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                        placeholder='Enter a value' 
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'value', Number(e.target.value)):alert.error('Negative Number not allowed')}
                                    />
                                    :''
                                }
                                {
                                    ['thickness','height','width','depth'].includes(selected_property[0].id) && item_json[idx].operator==='range'?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_min_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='min-value'
                                                    title='min value'
                                                    value={item_json[idx]?item_json[idx].min_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'min_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                                <Input id={position_type+'_max_value_'+idx} style={{height:'100%',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="number"
                                                    placeholder='max-value'
                                                    title='max value'
                                                    value={item_json[idx]?item_json[idx].max_value:''}
                                                    onChange={(e) =>Number(e.target.value)>=0?update_item_json(idx,'max_value',Number(e.target.value)):alert.error('Negative Number not allowed')}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].id=='shelf_type' && item_json[idx]?
                                        <Multiselect
                                            options={values_json && values_json.length? JSON.parse(JSON.stringify(values_json)):[]} 
                                            selectedValues={selected_tag_1_array}
                                            onSelect={(selectedList) => onclick_item('value', selectedList)}
                                            onRemove={(selectedList) => onclick_item('value', selectedList)}
                                            displayValue="name" // Property name 
                                            id={position_type+'_value_'+idx}
                                            placeholder='Search Value'
                                            selectionLimit={1}
                                        />
                                    :''
                                }
                                {
                                    selected_property[0].id=='visibility' && item_json[idx]?
                                        <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                            placeholder='Enter a value'
                                            value={item_json[idx]?item_json[idx].value:''}
                                            onChange={(e)=>update_item_json(idx,'value', e.target.value)}
                                        >
                                            <option value={null}>Choose a value</option>
                                            <option value='visible'>Visible</option>
                                            <option value='non_visible'>Non Visible</option>
                                        </Input>
                                    :''
                                }
                                {
                                    selected_property && selected_property.length && selected_property[0].id==='prelam_type'?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value='none'>Select Option</option>
                                        <option value='prelam_internal'>Prelam Internal</option>
                                        <option value='prelam_both'>Prelam Both</option>
                                        <option value='postlam'>Postlam</option>
                                    </Input>
                                    :''
                                }
                                {
                                    selected_property[0].id === 'panel_type' ?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value='none'>Select Option</option>
                                        <option value='shelf'>Shelf</option>
                                        <option value='fixed_shelf'>Fixed Shelf</option>
                                        <option value='adjustable_shelf'>Adjustable Shelf</option>
                                        <option value='vertical_partition_panel'>Vertical Partition Panel</option>
                                        <option value='skirting_panel'>Skirting Panel</option>
                                        <option value='exposed_panel'>Exposed Panel</option>
                                        <option value='carcass_panel'>Carcass Panel</option>
                                        <option value='shutter_panel'>Shutter Panel</option>
                                    </Input>
                                    :''
                                }
                                {
                                    selected_property[0].id === 'drawer_construction_type' ?
                                    <Input id={position_type+'_value_'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select"
                                        value={item_json[idx]?item_json[idx].value:''}
                                        onChange={(e) =>update_item_json(idx,'value', e.target.value)}
                                    >
                                        <option value='none'>Select Option</option>
                                        <option value='drawer_box'>Box Type</option>
                                        <option value='normal'>Normal</option>
                                    </Input>
                                    :''
                                }
                                {
                                    selected_property[0].id=='no_condition' && item_json[idx]?
                                        <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70', opacity:selected_property && selected_property[0] && selected_property[0].id==='no_condition'?.5:1}} type="text"
                                            placeholder='No value Required'
                                            disabled
                                        />
                                    :''
                                }
                                {
                                    selected_property[0].id==='tag' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'80px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'147px'}}
                                                    options={values_json && values_json.length? JSON.parse(JSON.stringify(values_json)):[]} 
                                                    selectedValues={selected_tag_1_array}
                                                    onSelect={(selectedList) => onclick_item('tag', selectedList)}
                                                    onRemove={(selectedList) => onclick_item('tag', selectedList)}
                                                    displayValue={selected_property[0].id==="tag"?'tag':'name'} // Property name 
                                                    id={position_type+'_value_'+idx}
                                                    placeholder= {selected_property[0].id==="tag"?'Select Tag':'Select Sales Channel'}
                                                /> 
                                            </div>
                                        </React.Fragment>
                                    :''
                                }

                                {
                                    selected_property[0].id==='brand' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'80px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'147px'}}
                                                    options={all_brands && all_brands.length? JSON.parse(JSON.stringify(all_brands)):[]} 
                                                    selectedValues={selected_tag_1_array}
                                                    onSelect={(selectedList) => onclick_item('value', selectedList)}
                                                    onRemove={(selectedList) => onclick_item('value', selectedList)}
                                                    displayValue='name' // Property name 
                                                    id={position_type+'_value_'+idx}
                                                    placeholder= 'Select Brand'
                                                /> 
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    selected_property[0].id==='sales_channel' ?
                                        <React.Fragment>
                                            <div style={{display:'flex'}}>
                                                <Input id={position_type+'_value_combination_logic_'+idx} style={{fontSize:'12px', color:'#425A70',height:'32px',overflowY:'auto', width:'80px'}} type="select" 
                                                    value={item_json[idx]?item_json[idx].value_combination_logic:'none'}
                                                    onChange={(e) =>update_item_json(idx,'value_combination_logic', e.target.value)}
                                                    title='Select Logic between property values'
                                                >    
                                                    <option value='and'>And</option>
                                                    <option value='or'>Or</option>
                                                </Input>
                                                <Multiselect
                                                    style={{backgroundColor:'white', width:'147px'}}
                                                    options={all_sales_ch && all_sales_ch.length? JSON.parse(JSON.stringify(all_sales_ch)):[]} 
                                                    selectedValues={selected_tag_1_array}
                                                    onSelect={(selectedList) => onclick_item('sales_channel', selectedList)}
                                                    onRemove={(selectedList) => onclick_item('sales_channel', selectedList)}
                                                    displayValue='name' // Property name 
                                                    id={position_type+'_value_'+idx}
                                                    placeholder= 'Select Brand'
                                                /> 
                                            </div>
                                        </React.Fragment>
                                    :''
                                }
                                {
                                    ['sku','ply_sku','edgeband_sku','finish_sku'].includes(selected_property[0].id) && item_json[idx] && item_json[idx].type==='absolute'?
                                        <AMLinked_sku_structure item_json={linked_item_json} set_item_json={set_linked_item_json} idx={idx} mode={mode} all_division_tree_data={all_division_tree_data} position_type={position_type} original_idx={idx}/>
                                    :''
                                }
                                {
                                    selected_property[0].id==='sku' && item_json[idx] && item_json[idx].type==='relative'?
                                        <AMKeyValuePair_relative item_json={item_json} set_item_json={set_item_json} component_options_json={component_options_json} open={open} position_type={position_type} idx={idx} single_item_json={single_item_json} mode={mode}/>
                                    :''
                                }
                            </React.Fragment>
                            :
                            <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                                <option value='none'>Choose a value</option>
                            </Input>    
                        }
                    </Col>
                </Row>
            </CardBody>
        </div>
    )
}

export default AMKeyValuePair_source;

