import React , { useState, useEffect } from 'react';
import {Card, Col, Progress, Table, Row, ModalHeader, ModalFooter, Button,Modal, ModalBody } from "reactstrap";


const Filter = ({is_filter_open, set_is_filter_open, set_sort_value}) => {
    const [sort, set_sort] = useState(0);

    return (
        <Modal isOpen={is_filter_open} size='sm' toggle={() => set_is_filter_open(!is_filter_open)} className='modal-dialog-centered'>
            <ModalHeader toggle={() => set_is_filter_open(!is_filter_open)}>
                Filter
            </ModalHeader>
            <ModalBody>
                <div onClick={() => set_sort(1)} >
                    <input type="radio" checked = {sort == 1 ? true : false} />
                    <span style={{padding : '6px 12px', marginLeft:'16px', cursor: 'pointer'}}>Only Active</span>
                </div>

                <div onClick={() => set_sort(2)} >
                    <input type="radio" checked = {sort == 2 ? true : false} />
                    <span style={{padding : '6px 12px', marginLeft:'16px', cursor:'pointer'}}>Only Inactive</span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className='white_button' onClick={() => {set_sort_value(0); set_sort(0); set_is_filter_open(false)}}>
                    Reset Filter
                </Button>

                <Button className='blue_button' onClick={() => {set_sort_value(sort); set_is_filter_open(false)}}>
                    Apply Filter
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default Filter;