import React, { useState, useEffect, isValidElement } from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Switch from "react-switch";
import './index.css';
import custom_sort from '../../utils/custom_sort';


// const Single_item_for_quotation_setting_tab_only = ({ item, set_columns, columns }) => {

//     const checkbox_onchange_for_fields = (item) => {
//         if(columns && columns['1ABC'].items){
//             var fields_copy = JSON.parse(JSON.stringify(columns['1ABC'].items));
//             var field = fields_copy.find(o => o.id === item.id);

//             if(field){
//                 field.checked = !field.checked;
//             }
//             set_columns(fields_copy);
//         }
// 	}

//     return(
//         <div style={{height:'35px', display:'flex', alignItems:'center', width:'100%'}}>
// 			<div style={{marginRight:'15px'}}><i className='fa fa-ellipsis-v'/></div>
// 			<div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
// 				<div style={{textTransform:'capitalize', fontSize:'14px'}}>{item.display_name}</div>
// 				<Switch 
// 					// onChange={() => {checkbox_onchange_for_fields(item)}} 
// 					checked={item.checked}
// 					uncheckedIcon={false}
// 					checkedIcon={false}
// 					offColor='#e6e6e6'
// 					offHandleColor='#808080'
// 					onColor='#0066ff'
// 					onfHandleColor='#b3d1ff'
// 					height={15}
// 					width={30}	
// 				/>
// 				{/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight:'15px'}} /> */}
// 			</div>
// 		</div>
//     )
// }

// const Boq_fields = ({ item, set_columns, columns }) => {

    // const checkbox_onchange_for_fields = (item) => {
    //     if(columns && columns['1ABC'].items){
    //         var fields_copy = JSON.parse(JSON.stringify(columns['1ABC'].items));
    //         var field = fields_copy.find(o => o.id === item.id);

    //         if(field){
    //             field.checked = !field.checked;
    //         }
    //         set_columns(fields_copy);
    //     }
// 	}

//     return(
//         <div className='layer_modal_button_hover' style={{fontSize:'12px',width:'100%',cursor:'pointer',listStyle: 'none', textTransform:'capitalize',color:'black', padding:'10px 0px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}} >
//             {item.text?item.text:''}
// 		</div>
//     )
// }

// const DragDropComponent = ({items_from_backened, modal_name}) => {
const DragDropComponent = ({items_from_backened, set_items_from_backened ,Single_component,start_index, end_index, open_rule_update_dialog, onclick_delete_rule, submit_for_delete_confirm, user,onclick_suffle_rule, fetch_rules}) => {

    const [columns, set_columns] = useState([])

    const columns_fron_backened = {
        '1ABC': {
            name:'Todo',
            items:items_from_backened
        },
    }

    useEffect(() => {
        if(items_from_backened){
            set_columns(columns_fron_backened)
        }
    },[items_from_backened])

    useEffect(() => {
        if(columns && columns['1ABC'] && columns['1ABC'].items && columns['1ABC'].items.length){
            console.log(columns)
        }
    },[columns])

    const onDragEnd = (result) => {
        if(!result.destination) return;
        const {source, destination} = result;
        console.log("source" , source , destination)
        // if(source.droppableId!=destination.droppableId){
        //     const source_column = columns[source.droppableId]
        //     const dest_column = columns[destination.droppableId]
        //     const source_items = [...source_column.items]
        //     const dest_items = [...dest_column.items]
        //     const [removed] = source_items.splice(source.index,1);
        //     dest_items.splice(destination.index,0,removed);
        //     set_columns({...columns,[source.droppableId]:{...source_column,items:source_items},[destination.droppableId]:{...dest_column,items:dest_items}})
        // }else{
            const column = columns[source.droppableId]
            console.log("col", column);
            const copied_items = [...column.items.sort(custom_sort)]
            console.log(copied_items)
            var new_order = 0;
            if(result.destination.index===0){
                var new_order = copied_items[result.destination.index].order - 1024
            }else if(result.destination.index===copied_items.length-1){
                var new_order = copied_items[result.destination.index].order + 1024
            }else if(result.destination.index < result.source.index){
                console.log("source here")
                var new_order = (copied_items[result.destination.index].order + copied_items[result.destination.index -1].order)/2;
            }else{
                console.log("source there")
                var new_order = (copied_items[result.destination.index].order + copied_items[result.destination.index +1].order)/2;
            }
            onclick_suffle_rule(copied_items[result.source.index],new_order)
            copied_items[result.source.index].order = new_order;
            const [removed] = copied_items.splice(source.index,1);
            copied_items.splice(destination.index,0,removed);
            set_columns({...columns,[source.droppableId]:{...column,items:copied_items}})
            set_items_from_backened(copied_items)

        // }
    } 


    return(
        <div style={{display:'flex'}}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                { 
                    columns && Object.entries(columns) && Object.entries(columns).length? Object.entries(columns).map(([id, column], index) =>{
                        return(    
                                <div style={{width:'100%'}} key={index}>
                                    <Droppable key={id} droppableId={id}>
                                        {(provided, snapshot) => {
                                            return(
                                                <div 
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor:snapshot.isDraggingOver?'#fafafa':'#fafafa',
                                                        width:'100%',
                                                        minHeight:'auto'
                                                    }}
                                                >
                                                    {
                                                        column && column.items && column.items.length? column.items.sort(custom_sort).map((item, index) =>{
                                                            return(
                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided, snapshot) => {
                                                                        console.log("item", item);
                                                                        return(
                                                                            <div 
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                                style={{
                                                                                    userSelect:'none',
                                                                                    backgroundColor:snapshot.isDragging?'lightgrey':'white',
                                                                                    width:'100%',
                                                                                    // padding:"5px 20px",
                                                                                    // height:35,
                                                                                    fontSize:'14px',
                                                                                    color:'black',
                                                                                    display:'flex',
                                                                                    alignItems:'center',
                                                                                    cursor:'inherit',
                                                                                    pointerEvents:'painted',
                                                                                    ...provided.draggableProps.style
                                                                                }}
                                                                                className='single_column'
                                                                            >
                                                                                <Single_component item={item} index={index} start_index={start_index} end_index={end_index} user={user} open_rule_update_dialog={open_rule_update_dialog} submit_for_delete_confirm={submit_for_delete_confirm} onclick_delete_rule={onclick_delete_rule} fetch_rules = {fetch_rules}/>
                                                                            </div>
                                                                        )

                                                                    }}
                                                                </Draggable>
                                                            )
                                                        })
                                                        :''
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            )
                                        }}
                                    </Droppable>
                                </div>
                        )
                    })
                    :''
                }
            </DragDropContext>
        </div>
    )
}

export default DragDropComponent;