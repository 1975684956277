import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, UncontrolledDropdown, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import UploadFile from "../UploadFile";
import { useAlert } from 'react-alert'
import Switch from "react-switch";

// const MTLFile = require('mtl-file-parser');

const Upload3d_modal_modal = ({open, handleclose, active_division_id, set_return_confirm_low_resp_data, return_confirm_low_resp_data, set_page_loader, onClick_submit_3dmodel, edit_flag, update_sku_array, sku_array_idx}) => {

	// const [show_high_modal_button, set_show_high_modal_button] = useState(false);
	const [	primary_file, set_primary_file] = useState('');
	const [ mtl_file , set_mtl_file ] = useState("");
	const [ additional_files , set_additional_files ] = useState([]);
	const [ primary_file_type, set_primary_file_type ] = useState('');
	const [ mtl_text, set_mtl_text ] = useState(null)
	const [ expected_texture_files, set_expected_texture_files ] = useState(null)
	const alert = useAlert();

	// const confirm_upload_fn = async () => {	
	// 	try{
	// 		var data = new FormData();
	// 		if((primary_file_type!='obj' && primary_file) || (primary_file_type==='obj' && primary_file && mtl_file)){
	// 			data.append('file', primary_file); 
	// 			if (mtl_file) {
	// 				data.append('mtl_file', mtl_file)
	// 			}
	// 			if (additional_files && Object.keys(additional_files).length>1) {
	// 				Object.keys(additional_files).map(json_key =>{
	// 					data.append('additional_file_'+(Number(json_key)+1), additional_files[json_key])
	// 				})
	// 			}
	// 			data.append('high', false);
	// 			set_page_loader({
	// 				show:true,
	// 				text:'Please wait.. Uploading SKU model'
	// 			})
	// 			var resp = await general_fetch({url:'model/upload_asset', body:data, is_form_data:true, req_resp:true})
	// 			set_page_loader({
	// 				show:false,
	// 				text:''
	// 			})
	// 			set_return_confirm_low_resp_data(resp && resp.data?resp.data.id:'')
	// 		}else{
	// 			set_page_loader({
	// 				show:true,
	// 				text:'Error in uploading ,please refresh'
	// 			})
	// 			alert.info('Please Select required file in the format allowed')
	// 		}				
	// 	}catch(err){
	// 		console.log(err)
	// 	}	
	// }

	const validate_model_filename = (filename) => {
        var re = /^[._a-zA-Z0-9 ]+$/
		return re.test(filename)
	}

	const upload_asset = async () => {
		console.log("entered upload")
		try {
			if((primary_file_type!='obj' && primary_file) || (primary_file_type==='obj' && primary_file && mtl_file)) {
				console.log("entered upload",primary_file.size, mtl_file.size)
				if(primary_file.size > 45000000 || mtl_file.size > 10000000)
				{
					alert.error("File size exceeded")
					return
				}
				if(!validate_model_filename(primary_file.name))
				{
					alert.error("Invalid Filename")
					return
				}

				if(mtl_file && !validate_model_filename(mtl_file.name)){
					alert.error("Invalid Filename")
					return
				}
				let temp_file_name = primary_file.name.replaceAll(' ', '_')
				console.log("temp file name", temp_file_name, primary_file)
				let temp_file = new File([primary_file], temp_file_name)
				console.log("temp file name", temp_file, primary_file)

				// set_primary_file(temp_file)
				let data = new FormData();
				data.append('file', temp_file); 
				if (mtl_file) {
					let temp_mtl_file_name = mtl_file.name.replaceAll(' ', '_')
					let temp_mtl_file = new File([mtl_file], temp_mtl_file_name)
					data.append('mtl_file', temp_mtl_file)
				}
				console.log('Uploading asset --->' ,additional_files,temp_file ,mtl_file)
				if (additional_files && Object.keys(additional_files).length) {
					let temp = 0;
					Object.keys(additional_files).map((json_key,idx) =>{
						if((additional_files[json_key] && additional_files[json_key].size > 10000000)){
							alert.error("File size exceeded")
							temp = 1
						}
						if((primary_file_type == 'obj' && additional_files[json_key] && additional_files[json_key].name != expected_texture_files[idx])){
							alert.error("File name not matched")
							temp = 1
						}
						if((primary_file_type=='obj' && !additional_files[json_key])){
							temp = 2
						}
						data.append('additional_file_'+(Number(json_key)+1), additional_files[json_key])
					})
					if(temp){
						if(temp == 2) alert.error("Upload all required textures")
						return
					}
				}
				data.append('high', false);
				data.append('format', primary_file_type)
				set_page_loader({
					show:true,
					text:'Please wait.. Uploading SKU model'
				})
				var resp = await general_fetch({url:'model/upload_asset', body:data, is_form_data:true, req_resp:true})
				console.log(` resp from asset upload --> ${resp}`);
				set_page_loader({
					show:false,
					text:''
				})
				set_return_confirm_low_resp_data(resp && resp.data?resp.data:'')
				if(update_sku_array && sku_array_idx>=0){
					console.log("upload 3d model")
					update_sku_array(sku_array_idx, 'low_model_3d_id' ,resp && resp.data?resp.data.id:'')
				}
				onclick_close_upload_3d_modal()
				if(edit_flag){
					onClick_submit_3dmodel(resp && resp.data?resp.data.id:'')
				}
			} else {
				set_page_loader({
					show:true,
					text:'Error in uploading ,please refresh'
				})
				alert.info('Please Select required file in the format allowed')
			}	
		} catch(err) {
			console.log(`error in uploading asset ---> ${err}`);		
			alert.error("error in uploading asset")	
		}
	}

	const onclick_close_upload_3d_modal = () => {
		handleclose()	
		set_primary_file('')
		set_mtl_file('')
		set_additional_files([])
		set_primary_file_type('')
	}

	useEffect(() =>{
		if(primary_file){
			var file = primary_file.name;
			var file_array = file.split('.')
			var file_type = file_array[file_array.length-1]
			if(primary_file){
				set_primary_file_type(file_type)
			}	
		}else{
			set_primary_file_type('')
		}
	},[primary_file])

	useEffect(() => {
		if(primary_file_type==='obj' && primary_file && mtl_file){
			let temp_arr = expected_texture_files && expected_texture_files.length ? expected_texture_files.map(x => null):''
			console.log("temp_arr", temp_arr);
			set_additional_files(temp_arr)
		}else if(primary_file && (primary_file_type=='gltf' || primary_file_type=='blend')){
			set_additional_files([])

			console.log("primary file type changed", primary_file)

		}
	},[primary_file_type, mtl_file, expected_texture_files])

	const add_additional_file = () => {
		// var current_length = Object.keys(additional_files).length
		let a = [...additional_files]
		a.push(null)
		set_additional_files(a)
	}

	const update_additional_file = (index, value) =>{
		// set_additional_files({...additional_files,[index]:value});
		let a = [...additional_files]
		a[index] = value
		set_additional_files(a)
	}

	// const delete_additional_file = (index) =>{
	// 	var x ={...additional_files}
	// 	delete x[index];
	// 	set_additional_files(x);
	// }

	useEffect(() => {
		console.log("Additional files", additional_files, primary_file_type)
		if((primary_file_type=='gltf' || primary_file_type=='blend') && Object.keys(additional_files) && Object.keys(additional_files).length){
			var add_entry=true;
			Object.keys(additional_files).map((json_idx)=>{
				if(!additional_files[json_idx]){
					add_entry=false;
				}
			})
			if(add_entry){
				add_additional_file()
			}
		}else if((primary_file_type=='gltf' || primary_file_type=='blend')){
			add_additional_file()
		}
	},[additional_files])

	useEffect(() => {
		if(mtl_text){
			console.log("mtl text1", mtl_text)
			let names = mtl_text.split(/\s|\/|\\|:/)
			console.log("mtl text2", names)
			let filtered_names = names && names.length ? names.filter(x => x.includes('.png') || x.includes('.jpg') || x.includes('.jpeg')):''
			console.log("mtl text3", filtered_names)
			let unique_names = [...new Set(filtered_names)]
			console.log("mtl text4", unique_names)
			set_expected_texture_files(unique_names)

			let lines = mtl_text.split(/\r?\n/)
			console.log("mtl lines", lines)

			let new_mtl_text = ""
			let temp = lines && lines.length ? lines.map((x,idx) => {
				if(x.includes('.png') || x.includes('.jpg') || x.includes('.jpeg')){
					let words = x.split(" ")
					console.log("words", words)
					let edited_text = ""
					let t = words && words.length ? words.map((y,index) => {
						if(y.includes('.png') || y.includes('.jpg') || y.includes('.jpeg')){
							let smaller_words = y.split(/\/|\\|:/)
							let tempp = smaller_words && smaller_words.length ? smaller_words.map(z => {
								if(z.includes('.png') || z.includes('.jpg') || z.includes('.jpeg'))
									edited_text += z
							}):''
						}else{
							edited_text += y
							edited_text += " "
						}
					}):''

					new_mtl_text += edited_text
				}else{
					new_mtl_text += x
				}
				if(idx != lines.length-1)new_mtl_text += '\n'
			}):''

			console.log("new mtl tetx", new_mtl_text, mtl_text)
			let uploadBlob = new Blob([new_mtl_text])
			let new_mtl_file = new File([uploadBlob],mtl_file.name)
			set_mtl_file(new_mtl_file)
			console.log("mtl text5", new_mtl_file)
		}
	},[mtl_text])

	useEffect(() => {
		console.log("mtl file changed", mtl_file)
	},[mtl_file])

	return(
		<Modal className="Margin_64px_top" size="md" isOpen={open} toggle={onclick_close_upload_3d_modal}>
			<Card style= {{height: (mtl_file && primary_file_type=='obj') ? '750px' :(primary_file && (primary_file_type=='gltf'||primary_file_type=='blend') ? '600px' : (primary_file && primary_file_type=='obj' ? '485px' : '300px')) }}>
				<CardHeader className='global_modal_header'>
					<span>Add 3D Model</span>
					<i onClick={onclick_close_upload_3d_modal} style={{ cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>
				
				{/* <CardBody className='global_modal_body'>
					<Row style={{marginBottom:"10px"}}>
						<Col xs={4}>
							<div style={{marginBottom:'3px'}}>Choose 3D Model File *<br/>(.blend .gltf .glb .obj )</div>
						</Col>
						<Col xs={4}>
							<UploadFile accept=".blend,.glb,.gltf,.obj" id="id_upload_file_low" button_name='Choose File' set_file={set_primary_file} />
							{
								primary_file && primary_file.size>10000000?
								<div style={{paddingTop:'7px',fontSize:'12px', color:'red', letterSpacing: '-0.04px', lineHeight: '20px'}}>Selected file is larger than 45 MB (Please select a file of size less than 45 MB)</div>
								:
								!primary_file?<div style={{paddingTop:'7px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Please select a file of size less than 45 MB</div>:''
							}
						</Col>
						<Col xs={4}>
							<div style={{marginBottom:'3px'}}>{primary_file ? primary_file.name : "No file chosen"}</div>
						</Col>
					</Row>
					{
						primary_file_type==='obj'?
						<Row style={{marginBottom:"10px"}}>
							<Col xs={4}>
							<div style={{marginBottom:'3px'}}>Choose MTL File *<br/>(.mtl)</div>
							</Col>
							<Col xs={2}>
								<UploadFile accept=".mtl" id="id_upload_file_mtl_low" button_name='Choose File' set_file={set_mtl_file} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}} />
							</Col>
							<Col xs={6}>
							<div style={{marginBottom:'3px'}}>{mtl_file ? mtl_file.name : ""}</div>
							</Col>
						</Row>:''
					}
					{	
						Object.keys(additional_files).length ? Object.keys(additional_files).map((json_key,idx) => (
							<Row key={idx} style={{marginBottom:"10px, width:'100%"}}>
								<Col xs={4}>
									<div style={{marginBottom:'3px'}}>Additional Texture files<br/>(.jpg .jpeg .png)</div>
								</Col>
								<Col xs={2}>
									<UploadFile accept=".jpg,.jpeg,.png" id={"id_upload_file_high" + idx} button_name='Choose File' update_additional_file={update_additional_file} additional_files_idx={json_key} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}} />
								</Col>
								<Col xs={6}>
									<div style={{marginBottom:'3px'}}>{additional_files[json_key]?additional_files[json_key].name:'No Name'}</div>
								</Col>
								<Col xs={1}>
									<Button onClick={()=>delete_additional_file(json_key)} className='red_button' style={{minWidth:'unset'}}><i style={{fontSize:'12px'}} className='fa fa-trash'/></Button>
								</Col>
							</Row>
						))
						:''
					}
				</CardBody> */}
				<CardBody style={{backgroundColor:'#F5F6F8'}}>
					<div className='upload-3dModel-box-header'>
						Upload 3D Model file
						<i style={{marginLeft:'42%', color:'green'}} className="fa-solid fa-circle-check"></i>
					</div>
					<div className='upload-3dModel-box-body'>
						<span style={{display:'flex', alignItems:'center'}}>
							<span style={{flex:'1'}}>
								<UploadFile accept=".blend,.glb,.obj" id="id_upload_file_low" button_name='Upload' set_file={set_primary_file} />
								<div style={{marginLeft:'3px'}}>{primary_file ? <span style={{fontSize:'12px'}}><i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey'}} /><span>{' '}</span>{primary_file.name} </span>: <span style={{opacity:'0.5'}}><i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey'}} /><span style={{fontSize:'12px'}}> {'No file chosen'}</span></span>}</div>
							</span>
							<span style={{flex:'3'}}>
								<div>Format: .Obj, .blend, .glb</div>
								{/* <div style={{marginBottom:'4%'}}>Please select a file less than 40 Mb</div> */}
								{
								primary_file && primary_file.size>45000000?
								<div style={{color:'red', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>File size exceeded, Please select a file of size less than 40 MB</div>
								:
								<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>Please select a file of size less than 40 MB</div>
								}
								{
									primary_file && primary_file.name && !validate_model_filename(primary_file.name)?
									<div style={{color:'red', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>Invalid Filename, Filename can only have alphanumeric characters</div>
									:
									<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}></div>
								}
							</span>
						</span>
					</div>

					{
						primary_file && primary_file_type == 'obj'?
						<>
							<div className='upload-3dModel-box-header' style={{marginTop:'4%'}}>
								Upload Material file
								{/* <span style={{marginLeft:'42%', fontWeight:'100', fontSize:'14px'}}><i class="fa fa-check-circle"></i></span> */}
							</div>
							<div className='upload-3dModel-box-body'>
								<span style={{display:'flex', alignItems:'center'}}>
									<span style={{flex:'1'}}>
										<UploadFile accept=".mtl" id="id_upload_file_mtl_low" button_name='Upload' set_file={set_mtl_file} set_text = {set_mtl_text} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}} />
										<div style={{marginLeft:'3px'}}>{mtl_file ? <span style={{fontSize:'12px'}}><i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey'}} /><span>{' '}</span>{mtl_file.name} </span>: <span style={{opacity:'0.5'}}><i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey'}} /><span style={{fontSize:'12px'}}> {'No file chosen'}</span></span>}</div>
									</span>
									<span style={{flex:'3'}}>
										<div>Format: .mtl</div>
										{console.log("mtl",mtl_file)}
										{
										mtl_file && mtl_file.size>10000000?
										<div style={{color:'red', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>File size exceeded, Please select a file of size less than 10 MB</div>
										:
										<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>Please select a file of size less than 10 MB</div>
										}
										{
										mtl_file && mtl_file.name && !validate_model_filename(mtl_file.name)?
										<div style={{color:'red', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}>Invalid Filename, Filename can only have alphanumeric characters</div>
										:
										<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px', marginBottom:'14px'}}></div>
										}
									</span>
								</span>
							</div>
						</>
						:''
					}

					{
						(mtl_file && primary_file_type=='obj') || primary_file_type=='gltf' || primary_file_type=='blend' ?
						<>
							<div className='upload-3dModel-box-header' style={{marginTop:'4%'}}>
								Upload Texture file
								<span style={{marginLeft:'42%', fontWeight:'100', fontSize:'14px'}}>Please select files of size less than 10Mb</span>
							</div>
							<div className='upload-3dModel-box-body' style={{height:'240px', overflowY:'scroll'}}>
							<span>{primary_file_type == 'obj'?'Upload these required texture files':'Upload required texture files'} </span>
							<div style={{height:'7%'}}></div>
							{
								primary_file_type == 'obj' && expected_texture_files && expected_texture_files.length ? expected_texture_files.map((texture_file_name, idx) => (
									<Row className='display_onhover' style={{marginBottom:'5%'}}>
										<Col xs={2}>
											<UploadFile accept=".jpg,.jpeg,.png" id={"id_upload_file_high" + idx} button_name='Upload' update_additional_file={update_additional_file} additional_files_idx={idx} expected_name={texture_file_name} additional_files = {additional_files} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}} />
										</Col>
										<Col xs={9} style={{paddingTop:'1%', fontSize:'13px'}}>
											<i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey',opacity:additional_files[idx]?1:0.5}} />
											<span style={{opacity:additional_files[idx]?1:0.5}}>{additional_files[idx]? '':'Upload'} {additional_files[idx]?additional_files[idx].name:texture_file_name}</span>
											{
												additional_files[idx] && additional_files[idx].name!=texture_file_name ? <span style={{color:'red'}}> {'(Expecting "'} {texture_file_name}{'")'} </span>:''
											}
											{
												additional_files[idx] && additional_files[idx].name==texture_file_name && additional_files[idx].size>10000000?<span style={{color:'red'}}>{'(File size exceeded 10MB)'}</span>:''
											}
										</Col>
										<Col xs={1} className='x1'>
											<span className='hide'><i onClick ={()=> {update_additional_file(idx,null)}} style={{color:'red', marginTop:'40%'}} className='fa fa-trash'/></span>
										</Col>
									</Row>
								)):''
							}
							{/* {console.log(primary_file_type, additional_files, additional_files.length)} */}
							{
								(primary_file_type == 'gltf' || primary_file_type == 'blend') && additional_files && additional_files.length ? additional_files.map((addl_file,idx) =>{
									// {console.log(primary_file_type, additional_files, additional_files.length)};
									return (
									<Row className='display_onhover' style={{marginBottom:'5%'}}>
										<Col xs={2}>
											<UploadFile accept=".jpg,.jpeg,.png" id={"id_upload_file_high" + idx} button_name='Upload' update_additional_file={update_additional_file} additional_files_idx={idx} additional_files = {additional_files} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}} />
										</Col>
										<Col xs={9} style={{paddingTop:'1%', fontSize:'13px'}}>
											<i class="fa fa-paperclip" aria-hidden="true" style={{color:'grey',opacity:additional_files[idx]?1:0.5}} />
											<span style={{opacity:additional_files[idx]?1:0.5}}>{additional_files[idx]? additional_files[idx].name :'No file selected'}</span>
											{
												additional_files[idx] && additional_files[idx].size>10000000?<span style={{color:'red'}}>{'(File size exceeded 10MB)'}</span>:''
											}
										</Col>
										<Col xs={1} className='x1'>
											<span className='hide'><i onClick ={()=> {update_additional_file(idx,null)}} style={{color:'red', marginTop:'40%'}} className='fa fa-trash'/></span>
										</Col>
									</Row>
									)}):''
							}
							</div>
						</>
						:''
					}
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button 
						disabled={((primary_file && primary_file_type!='obj')||(primary_file && mtl_file && primary_file_type==='obj'))?false:true}  
						onClick={((primary_file && primary_file_type!='obj')||(primary_file && mtl_file && primary_file_type==='obj')) ? upload_asset : null} 
						className='blue_button' type="button" >
						Confirm Uploads
					</Button>
				</CardFooter>
			</Card> 
		</Modal>
	);
}

export default Upload3d_modal_modal;