const filter_duplicate = (input_array,type) => {
    var x_input_array = []
    var map1 = new Map();
    // x_input_array = [...new Set(input_array[type])];
    input_array.map((x_item, idx) => {
        if(!map1.has(x_item[type])){
            map1.set(x_item[type], idx);
            x_input_array.push(x_item)
        }	
    })
    return x_input_array;
}

export default filter_duplicate;