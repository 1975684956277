import React, { useState , useEffect, isValidElement, useRef } from 'react';
import {Modal, FormGroup, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Fade, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import { useAlert } from 'react-alert'
import './sku_groups.css'
import LazyLoad from 'react-lazyload';
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import { act } from 'react-dom/test-utils';
import Upload3d_modal_modal from '../../../components/Upload_3d_modal';
import Add_new_material from '../../../components/Add_new_material';
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import AddSubCategory from './AddSubCategory';
import AddNewGroup from './AddNewGroup';
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import KeyValuePair from '../../../components/KeyValuePair';
import is_valid_add_key from '../../../utils/is_valid_add_key';
import Linked_sku_structure from '../../../components/Linked_sku_structure';
import ReferenceSkuModal from './ReferenceSkuModal';
import * as Sentry from "@sentry/react";
import {Tooltip} from 'antd'
const { uuid } = require('uuidv4');



const PublishConfirmationModal = ({open, handleClose, bulk_upload, final_sku_array, active_category}) => {

    const [checked_export, set_checked_export] = useState(true)

    const downloadJSONFile = () => {
        let temp = {data: final_sku_array}
        let text = JSON.stringify(temp)

        let uploadBlob = new Blob([text])
        let json_file = new File([uploadBlob], 'Published_SKUs.json')
        const element = document.createElement("a");
        element.href = URL.createObjectURL(json_file)
        element.download = 'Published_SKUs.json'
        document.body.appendChild(element); 
        element.click();
    }

    useEffect(() => {
        console.log("checked export", checked_export)
    },[checked_export])

    return(
        <Modal style={{marginTop:'20vh',width:'500px'}} size="md" isOpen={open} toggle={handleClose}>
            <Card style = {{height:'250px'}}>
                <CardHeader className='global_modal_header'>
					<span>Publish Confirmation</span>
				</CardHeader>

                <CardBody style={{backgroundColor:'#F5F6F8'}}>
                    <div style={{marginTop:'3%', marginBottom:'5%'}}>Publishing <span style={{fontWeight:'900'}}>{final_sku_array && final_sku_array.length ? (final_sku_array.length ==1 ? '1 SKU' : final_sku_array.length + ' SKUs') :''}</span> in the Category <span style={{fontWeight:'900'}}>{active_category && active_category.name}</span></div>
                    <span style={{marginLeft:'4%'}}><Input style={{marginTop:'1.2%'}} type='checkbox' checked={checked_export} onClick={() => {set_checked_export(!checked_export)}} /> Export the copy of published file</span>
                </CardBody>

                <CardFooter className='global_modal_footer'>
                    <Button className='blue_button' type="button" onClick = {() => {bulk_upload(); if(checked_export)downloadJSONFile(); handleClose()}}>
                        Confirm
                    </Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

const AdditionalPropsModal = ({open, handleClose, sku_name, additional_props, set_additional_props}) => {
    
    const [additional_props_default, set_additional_props_default] = useState([]);
    const [additional_props_custom, set_additional_props_custom] = useState([]);
    const alert = useAlert()

    useEffect(() => {
        if(open && additional_props){
            console.log("additional_props", additional_props)
            var x_default = [], x_custom=[];
            
            additional_props.map(x_item => {
                if(Object.keys(all_allowed_key).includes(x_item.key)){
                    x_default.push(x_item)
                }else{
                    x_custom.push(x_item)
                }
            })
        }  
        set_additional_props_default(x_default)
        set_additional_props_custom(x_custom)
    }, [open, additional_props]);

    const update_additional_props = () => {
        try{
            if(additional_props_default && additional_props_default.length>1 && !is_valid_add_key(additional_props_default)){
                alert.error("Empty or Duplicate key values not allowed in default properties.")
            }else if(additional_props_custom && additional_props_custom.length>1 && !is_valid_add_key(additional_props_custom)){
                alert.error("Empty or Duplicate key values not allowed in custom properties.")
            }else{
                var x_addi_prop = [];
                additional_props_default.map(x => {
                    if(additional_props_default[0].key){
                        x_addi_prop.push(x)
                    }
                })
                additional_props_custom.map(x => {
                    if (additional_props_custom[0].key) {
                        x_addi_prop.push(x)
                    }
                }) 
                set_additional_props(x_addi_prop)
                set_additional_props_default([])
                set_additional_props_custom([])
                handleClose()
            }
        }catch(err){
            console.log(err)
            alert.error("Additional Properties Updation Failed !")
            Sentry.captureException(err)
        }
    }

    return (
        <Modal className='Margin_64px_top' size='md' isOpen={open} toggle={handleClose}>
            <Card>
                <CardHeader className='global_modal_header'>
                    <span style={{ fontWeight:'900'}}>{sku_name ? sku_name + ' -'+'\xa0' :''} Additional Properties</span>
					<i style={{cursor:'pointer'}} onClick={handleClose} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body'>
                    <KeyValuePair item_json={additional_props_default} set_item_json={set_additional_props_default} mode='default' all_allowed_key={all_allowed_key}/>
                    <KeyValuePair item_json={additional_props_custom} set_item_json={set_additional_props_custom} mode='custom' all_allowed_key={all_allowed_key}/>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button className='blue_button' onClick={update_additional_props}>Done</Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

const LinkedSkuModal = ({open, handleClose, sku_name, selected_sku_properties, all_division_tree_data, active_division_id, sku_category_type, set_linked_skus}) => {
    
    const [item_json, set_item_json] = useState([]);
    const alert = useAlert()

	const is_valid_add_key = (item_json) => {
		if (sku_category_type == 'drawer_system_collection_hardware' || sku_category_type == 'basket_collection_hardware') {
			return true;
		}
		if(item_json && item_json[0] && item_json[0].division && !item_json[0].division.length){
			return true;
		}else{
			if(item_json && item_json.length){
				for(var i=0;i<item_json.length;i++){
					if(item_json[i].sku && item_json[i].sku.length<1 ){
						return false;
					}
					var sku_ids = item_json.map(x => x.sku && x.sku[0]?x.sku[0].id:[])
					if(sku_category_type!='panel_core_hardware' && item_json[i].sku && item_json[i].sku.length && sku_ids.filter(x=> x===item_json[i].sku[0].id).length>=2){
						return false;
					}
				}
				return true;
			}else{
				return true;
			}
		}
	}

	const check_for_ply_finish = (linked_skus) => {
		const map1 = new Map();
		if(sku_category_type==='panel_core_hardware'){
			if(linked_skus && linked_skus.length>=4){
				return true
			}else if(linked_skus && linked_skus.length===1){
				return false;
			}else{
				var x_bool = false;
				linked_skus.map((x_item, idx) => {
					if(!x_bool){
						if(map1.has(x_item.type)){
							x_bool=true
						}else{
							map1.set(x_item.type, idx);
						}
					}
				})
				if(x_bool){
					return true;
				}else{
					return false;
				}
			}
		}else{
			return false
		}
	}

	const check_for_cabinet_shutter = (linked_skus) => {
		var x_ind = 0;
		if(sku_category_type==='shutter_hardware'){
			if(linked_skus && linked_skus.length>=4){
				return true
			}else if(linked_skus && linked_skus.length===3){
				if(linked_skus[0].type === linked_skus[1].type && linked_skus[0].type === linked_skus[2].type && linked_skus[1].type === linked_skus[2].type){
					x_ind = 1; 
				}
			}else if(linked_skus && linked_skus.length===2){
				if(linked_skus[0].type === linked_skus[1].type){
					x_ind = 1; 
				}
			}
		}
		if(x_ind===1){
			return true
		}else{
			return false
		}
	}

    const update_linked_skus = () => {
        if(item_json && item_json.length>0 && !is_valid_add_key(item_json)){
			alert.error("Empty or Duplicate sku values not allowed.")
		}else{
			try {
                    let linked_skus = item_json.map((x, idx) => {
                        return {
                            id:x.sku[0]?x.sku[0].id:'',
                            quantity:x.quantity,
                            type: (sku_category_type == 'drawer_system_collection_hardware' || sku_category_type == 'basket_collection_hardware') ? idx : (x.type?(x.type[0] && x.type[0].id?x.type[0].id:x.type):'')
                        }
                    })
                    if(check_for_ply_finish(linked_skus)){
                        alert.error("Maximum of Only three SKUs are allowed, with each having different type value.")
                    }else if(check_for_cabinet_shutter(linked_skus)){
                        alert.error("Maximum of Only three SKUs are allowed, with each having different type.")
                    }else{
                        set_linked_skus(linked_skus)
                        alert.success('Linked SKU Successfully Updated.')
                        onclick_handleClose()
                    }
			} catch(err) {
				console.log(err);
			}
		}
    }

    const onclick_handleClose = () => {
        set_item_json([])
        handleClose()
    }
    
    return(
        <Modal className='Margin_64px_top' size='xl' isOpen={open} toggle={onclick_handleClose}>
            <CardHeader className='global_modal_header'>
                <span style={{ fontWeight:'900'}}>{sku_name ? sku_name + ' -'+'\xa0' :''} Linked SKUs</span>
                <i style={{cursor:'pointer'}} onClick={onclick_handleClose} className='fa fa-times'/>
            </CardHeader>
            <CardBody className='global_modal_body'>
                <Linked_sku_structure item_json={item_json} set_item_json={set_item_json} all_division_tree_data={all_division_tree_data} selected_sku_properties={selected_sku_properties} active_division_id={active_division_id} sku_category_type={sku_category_type}/>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button className='blue_button' onClick={update_linked_skus}>Done</Button>
            </CardFooter>
        </Modal>
    )
}

const SaveDraftWarning = ({open, handleClose, close_bulk_create_modal, save_as_draft}) => {
    return (
        <div>
            <Modal style ={{marginTop:'17%', height:'150px', width:'360px'}} size="sm" isOpen={open} toggle={handleClose}>
                <Card>
                    {/* <CardHeader className='global_modal_header'>
                        Warning!
                    </CardHeader> */}
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingLeft:'4%', paddingDown:'10%'}}>Do you want to save your changes as a draft?</div>
                        <div style={{paddingLeft:'21%', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => close_bulk_create_modal()}>No</Button>
                            <Button className='blue_button' type="button" onClick={() => {save_as_draft() ;close_bulk_create_modal()}}>Yes</Button>
                        </div>
                    </div>
                    {/* <CardFooter className='global_modal_footer'>
                        <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                        <Button className='blue_button' type="button" onClick={() => {set_discard_changes(true);handleClose()}}>Discard Changes</Button>
                    </CardFooter> */}
                </Card>
            </Modal>
        </div>
    )
}

const UploadDisplayImage = ({ id, set_file, file_size, styles, accept, set_display_image, index}) => {

	const onchange_filename = (e) => {
        var file = e.target.files[0];
        set_display_image(index, file)
        e.stopPropagation();
	}

	const onclick_upload_button = () => {
		document.getElementById(id).click();
		// document.getElementById(id).value = null
		console.log("filessss",document.getElementById(id).files)
	}

	return(
		<div style={{marginLeft:'54px' ,boxSizing:'border-box', border:'1px dashed #A6A6A6', borderRadius:'2px', cursor:'pointer'}}>
			<span  onClick = {onclick_upload_button}><span style={{margin:'10px'}}>+ JPG/PNG</span></span>
			<Input id={id} type="file" onChange={(e) => onchange_filename(e)} style={{display:"none"}} accept={accept}/>
		</div>	
	)
}

const NameCloneModal = ({open, handleClose, duplicate_sku_row, sku_array_idx, existing_sku_name}) =>{

    const [new_sku_name, set_new_sku_name] = useState();
    const alert = useAlert()

    const onClick_submit = () => {
        if(new_sku_name && new_sku_name.length > 200){
			alert.error("SKU name shouldn't exceed 200 characters")
			return
		}
        duplicate_sku_row(sku_array_idx, new_sku_name)
        handleClose()
    }

    useEffect(() => {
        if(existing_sku_name) set_new_sku_name("Copy of "+existing_sku_name)
    }, [existing_sku_name]);

    return(
        <Modal isOpen={open} size='sm' style={{marginTop:'200px'}}>
            <CardHeader className='global_modal_header'>
				Name the Clone SKU
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handleClose} />
			</CardHeader>
			<CardBody style={{height:'150px', backgroundColor:'#F5F6F8', padding:'20px 60px'}}>
				<span style={{}}>SKU Name</span>
				<Input value={new_sku_name} placeholder={'New SKU Name'} onChange={(e) => set_new_sku_name(e.target.value)} style={{marginTop:'5px'}}/>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='blue_button' disabled={new_sku_name?false:true} onClick={onClick_submit} >Submit</Button>
			</CardFooter>
        </Modal>
    )
}

const BulkCreateModal = ({open, user, handleClose, active_category, active_division_id, sku_array, set_sku_array, add_new_rows, update_sku_array, delete_sku_row ,can_add_new_rows, update_sku_array_sales_channel,set_page_loader, using_import_file_method, using_edit_draft_method,get_unique_sku_id,create_new_attempt,active_attempt_id, viewing_published_skus}) => {
    const [sku_sub_categories, set_sku_sub_categories] = useState(null)
    const [sku_groups_json, set_sku_groups_json] = useState({})
    const [all_sales_channels, set_all_sales_channels] = useState(null)
    const [selected_sales_channels_arrays, set_selected_sales_channels_arrays] = useState([])
    const [category_types_data, set_category_types_data] = useState(null)
    const [default_pricing_unit, set_default_pricing_unit] = useState(null)
    const [default_pricing_dimension, set_default_pricing_dimension] = useState(null)
	const [show_Upload3d_modal_modal , set_show_Upload3d_modal_modal ] = useState(false);
	const [return_confirm_low_resp_data , set_return_confirm_low_resp_data ] = useState('');
    const [sku_array_idx, set_sku_array_idx] = useState(null)
	const [is_allow_material , set_is_allow_material] = useState(false)
	const [ return_material_details , set_return_material_details ] = useState('');
	const [ show_add_new_material , set_show_add_new_material ] = useState(false);
	const [ material_mode , set_material_mode ] = useState(undefined)
	const [	material_template, set_material_template ] = useState('');
	const [	texture_json, set_texture_json ] = useState([]);
	const [	materials_json, set_materials_json ] = useState([]);
    const [show_publish_confirmation_modal, set_show_publish_confirmation_modal] = useState(false)
    const [final_sku_array, set_final_sku_array] = useState([])
    const [selected_row, set_selected_row] = useState(null)
    const [hovered_row, set_hovered_row] = useState(null)
    const [checkbox_json, set_checkbox_json] = useState({})
    const [main_checkbox, set_main_checkbox] = useState(false)
    const [highlight_empty_fields, set_highlight_empty_fields] = useState(false)
    const [isHovered, set_isHovered] = useState({});
    const [show_ellipsis_options, set_show_ellipsis_options] = useState(null)
    const [ellipsis_options_height, set_ellipsis_options_height] = useState(0)
    const [ellipsis_options_left, set_ellipsis_options_left] = useState(0)
    const [scroll_number,set_scroll_number] = useState(0)
    const [delayHandler, setDelayHandler] = useState(null)
	const [	show_add_sub_category, set_show_add_sub_category ] = useState(false);
	const [ sub_cat_mode, set_sub_cat_mode ] = useState('');
	const [ open_add_new_group, set_open_add_new_group ] = useState(false)
    const [current_sub_cat_id, set_current_sub_cat_id] = useState(null)
    const [show_save_draft_warning, set_show_save_draft_warning] = useState();
    const [already_fetched, set_already_fetched] = useState(false)
    const [show_additional_props_modal, set_show_additional_props_modal] = useState(false);
    const [show_linked_skus_modal, set_show_linked_skus_modal] = useState(false);
    const [show_reference_sku_modal, set_show_reference_sku_modal] = useState();
    const [all_division_tree_data , set_all_division_tree_data] = useState('')
    const [show_name_clone_modal, set_show_name_clone_modal] = useState();
    const [all_brands, set_all_brands] = useState();
    // const [new_sku_array, set_new_sku_array] = useState([])



    // const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 500);
    const handlOnMouseOver = (key,idx) => {
        setDelayHandler(setTimeout(() => {
            if(key){
                let a = JSON.parse(JSON.stringify(isHovered))
                a[key] = true
                set_isHovered(a)
            }else if(idx>=0){
                update_sku_array(idx, 'hovered_display_pic', true)
            }
        },500))
    }

    const handlOnMouseLeave = (key,idx) => {
        if(key){
            let a = JSON.parse(JSON.stringify(isHovered))
            a[key] = false
            set_isHovered(a)
        }else if(idx>=0){
            update_sku_array(idx, 'hovered_display_pic', false)
        }
        // debouncedHandleMouseEnter.cancel();
        clearTimeout(delayHandler)
      };

    // const [display_pic, set_display_pic] = useState(null)
    const alert = useAlert()



    const fetch_sub_categories = async() => {
        try{
            set_page_loader({
                show:true,
                text:'please wait...'
            })
            var response = await general_fetch({ url: 'inventory/get_sub_categories' , body: { sku_category_id:active_category.id } });
            set_sku_sub_categories(response)
            set_page_loader({
                show:false
            })
            console.log("sub_cats", response)
        }catch(err) {
			// alert.error('Sub-Categories fetching failed.')
			console.log(err);
		}
    }

    const fetch_sku_groups = async (sub_category_id) => {
		try {
            set_page_loader({
                show:true,
                text:'fetching groups...'
            })
			var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id:[sub_category_id] } });
            console.log("grps", response)
			let a = JSON.parse(JSON.stringify(sku_groups_json))
            a[sub_category_id] = response
            set_sku_groups_json(a)
            set_page_loader({
                show:false
            })
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_sku_groups_listed = async(sub_category_ids) => {
        try{
            var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id:sub_category_ids } });
            // console.log("sku grps listed", response)
            if(response){
                let a = JSON.parse(JSON.stringify(sku_groups_json))
                for(let i = 0; i < response.length ; i++){
                    // let temp = a[response[i]['sku_sub_category_id']]
                    // console.log("printing aaaac temp", temp)
                    // temp.push(response[i])
                    if(a[response[i]['sku_sub_category_id']]){
                        a[response[i]['sku_sub_category_id']].push(response[i])
                    }else{
                        a[response[i]['sku_sub_category_id']] = [response[i]]
                    }
                }
                set_sku_groups_json(a)
            }
        }catch(err) {
			console.log(err);
		}
    }

    const fetch_sales_channel_details = async () => {
		try {
			var identifiers = JSON.stringify({store_id : user.store_id})
			var attributes = JSON.stringify({})
			var body = {identifiers , attributes}
			var resp = await general_fetch({ url: 'sales_channel/get' , body});
            console.log("Sales channels details", resp)
			set_all_sales_channels(resp)
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_all_pref = async () => {
		try {
			var resp = await general_fetch({ url: 'price_type/get_all_of_store'});
			// set_all_sc_preferences(resp && resp.length?resp:[])
            console.log("Sales channels price types", resp)
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_category_types_data = async() => {
        let response = await general_fetch({url:'sku_category_type/get', body:{identifiers:JSON.stringify({sku_division_id:active_division_id})}})
        set_category_types_data(response)
        console.log("cat types data", response)
    }

    const fetch_material_json = async() => {
		try{
			//loader
            console.log("calling material/get 2")
			var attributes = JSON.stringify({})
			var identifiers = JSON.stringify({})
			var reponse = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
			reponse && set_materials_json(reponse)
		}catch(err){
			console.log(err)
		}
	}

    const fetch_material_template = async() => {
		try{	
			var attributes = JSON.stringify({})
			var identifiers = JSON.stringify({})
			var reponse = await general_fetch({url :'material_template/get' , body: {identifiers, attributes} }) 
			reponse && set_material_template(reponse)
		}catch(err){
			console.log(err)
		}
	}

    // const fetch_texture_json = async() => {
	// 	try{
	// 		var attributes = JSON.stringify({})
	// 		var identifiers = JSON.stringify({})
	// 		var reponse = await general_fetch({url :'texture/get' , body: {identifiers, attributes} }) 
	// 		reponse && set_texture_json(reponse)
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }

    const check_spl_chars = (name) => {
        let re = /^[a-zA-Z0-9.\-_\/\\ ]+$/
        console.log('spl name', name)
        return name.trim() && re.test(name)
    }

    const get_all_brands = async() => {
        try {
            var response = await general_fetch({ url: 'brand/get'});	
			set_all_brands(response)
        } catch(err) {
            console.log(err);
        }
    }

    const add_new_selected_sales_channel_array = () => {
        let a = [...selected_sales_channels_arrays];
        a.push([])
        set_selected_sales_channels_arrays(a);
    }

    const delete_selected_sales_channel_array = (index) => {
        let a = [...selected_sales_channels_arrays]
        a.splice(index,1)
        set_selected_sales_channels_arrays(a)
    }

    const update_selected_sales_channel_arrays = (index, value) => {
        let a = [...selected_sales_channels_arrays]
        a[index] = value
        set_selected_sales_channels_arrays(a)
    }

    const add_new_sku_groups = (sub_category_id) =>{
        if(!(Object.keys(sku_groups_json).includes(sub_category_id))){
            fetch_sku_groups(sub_category_id)
        }
    }

    const update_no_of_price_type = (index, selectedList) => {
        let temp = 0
        for(let i=0;i<selectedList.length;i++){
            temp += selectedList[i]['price_types'].length
        }
        update_sku_array(index, 'no_of_price_types', new Array(temp).fill(1))
    }

    const onclick_item = (index,selectedList) => {
        update_selected_sales_channel_arrays(index,selectedList)
        update_sku_array(index, 'sales_channels', selectedList)
        update_no_of_price_type(index, selectedList)
        console.log("selected keys", selectedList)
    }

    const onclick_handleClose = () => {
        handleClose()
        set_selected_sales_channels_arrays([])
        set_default_pricing_unit(null)
        set_default_pricing_dimension(null)
        console.log("is allow")
        set_is_allow_material(false)
        set_highlight_empty_fields(false)
        set_show_ellipsis_options(null)
        set_show_save_draft_warning(false)
        set_already_fetched(false)
        // set_category_types_data(null)
    }

    const close_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(false)
        set_return_confirm_low_resp_data(null)
	}
	const open_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(true)
	}

    const close_add_new_material = () =>{
		set_show_add_new_material(false)
		set_material_mode('')
	}
	const open_add_new_material = () =>{
		set_show_add_new_material(true)
		set_material_mode('add')
	}


    const close_save_draft_warning = () => {
        set_show_save_draft_warning(false)
    }

    const set_display_image = async(index, file) => {
        try{
            let data = new FormData();
            console.log("js file", file)
            data.append('upl', file)
            set_page_loader({
                show:true,
                text:'Please wait.. Uploading Display Image'
            })
            let response = await general_fetch({url: 'image/add', body:data, is_form_data:true, req_resp:true})

            // update_sku_array(index, 'display_pic_id', response && response.data?response.data.id:'')
            let a = [...sku_array]
            a[index]['display_pic_id'] = response && response.data?response.data.id:''
            a[index]['display_pic'] = file
            a[index]['display_pic_url'] = response && response.data?response.data.image:''
            a[index]['display_pic_name'] = file.name
            set_sku_array(a)

            console.log("display image success")
            set_page_loader({
                show:false,
                text:''
            })
        }catch(err){
            console.log(err)
        }
    }

    const calculate_display_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            console.log('checking for sq')
            var actual_unit =  unit.split('sq_')[1];
            return calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_display_price(calculate_display_price(calculate_display_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty * 1000;
        } else if (unit == 'foot') {
            return qty * 304.8;
        } else {
            return qty;
        }
    }

    const calculate_actual_price = (qty, unit) => {
        if (!unit) {
            return qty;
        }

        if (unit.indexOf('sq_') > -1) {
            var actual_unit =  unit.split('sq_')[1];
            return calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit)
        }

        if (unit.indexOf('cu_') > -1) {
            var actual_unit =  unit.split('cu_')[1];
            return calculate_actual_price(calculate_actual_price(calculate_actual_price(qty, actual_unit), actual_unit), actual_unit)
        }

        if (unit == 'metre') {
            return qty / 1000;
        } else if (unit == 'foot') {
            return qty / 304.8;
        } else {
            return qty;
        }   
    }

    const bulk_upload = async() => {
        try{
            if(final_sku_array && final_sku_array.length){
                
                let data = [...final_sku_array]

                if(default_pricing_dimension == 'per_unit_area' || default_pricing_dimension == 'per_unit_length'){
                    for(let i=0;i<data.length;i++){
                        let sales_channel_array = data[i]['sales_channels']
                        for(let j=0;j<sales_channel_array.length;j++){
                            let price_types_array = sales_channel_array[j]['price_types']
                            for(let z=0;z<price_types_array.length;z++){
                                if(price_types_array[z]['display_unit'] && price_types_array[z]['price']){
                                    price_types_array[z]['price'] = calculate_actual_price(price_types_array[z]['price'], price_types_array[z]['display_unit']) ? calculate_actual_price(price_types_array[z]['price'], price_types_array[z]['display_unit']) : '0'
                                }else if(price_types_array[z]['price']){
                                    price_types_array[z]['display_unit'] = default_pricing_unit
                                    price_types_array[z]['price'] = calculate_actual_price(price_types_array[z]['price'], default_pricing_unit) ? calculate_actual_price(price_types_array[z]['price'], default_pricing_unit) : '0'
                                }else if(!price_types_array[z]['display_unit']){
                                    price_types_array[z]['display_unit'] = default_pricing_unit
                                }
                            }
                        }
                    }
                }

                for(let i=0;i<data.length;i++){
                    if(!data[i].low_model_3d_id) data[i].low_model_3d_id = null
                    if(!data[i].display_pic_id) data[i].display_pic_id = null
                    if(!data[i].material_id) data[i].material_id = null
                }

                console.log("new sku array 1", final_sku_array)

                let response = await general_fetch ({url:'sku_bulk_operation/trigger_attempt', body: {bulk_operation_attempt_id:active_attempt_id ,data:data}})
                set_highlight_empty_fields(false)
                if(response){
                    let new_sku_array = [...sku_array]
                    for(let i=0;i<final_sku_array.length;i++){
                        let b = new_sku_array.findIndex(o => o.id == final_sku_array[i].id)
                        new_sku_array.splice(b,1)
                    }
                    set_sku_array(new_sku_array)
                    create_new_attempt(active_category)

                    let close_modal = true
                    for(let i=0;i<new_sku_array.length;i++){
                        if(new_sku_array[i].sub_category && new_sku_array[i].sub_category != 'none' && new_sku_array[i].sku_group_id && new_sku_array[i].sku_group_id != 'none') 
                            close_modal = false
                    }
                    if(close_modal){ 
                        onclick_handleClose()
                    }
                    alert.success("SKUs Created Successfully !")
                }
            }else{
                alert.error('Bulk Creation Failed !')
                return
            }
        }catch(err){
            alert.error('Bulk Creation Failed !')
            console.log(err)
        }
    }

    const bulk_upload_check = async() => {
        let temp_final_sku_array = []

        set_highlight_empty_fields(true)
        // let a = [...sku_array]
        // let t = sku_array && sku_array.length ? sku_array.map((x) => (x.sub_category && x.sku_group_id ? temp_final_sku_array.push(x) :'')):[]
        for(let i=0;i<sku_array.length;i++){
            if(sku_array[i].sub_category && sku_array[i].sub_category != 'none' && sku_array[i]['sku_checked'] && !sku_array[i]['sku_published']){
                console.log("printing sub cat", sku_array[i].sub_category, sku_array[i].sku_group_id)
                if((active_category.sku_category_type_id=='cabinet_furniture' || active_category.sku_category_type_id == 'wardrobe_furniture') && !(sku_array[i].component_file && sku_array[i].component_file.length))
                {
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
                if(active_category.sku_category_type_id=='tile_finish' && !(sku_array[i].width > 0 && sku_array[i].depth > 0 && sku_array[i].height > 0)){
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
                if(active_category.sku_category_type_id=='edge_band_hardware' && !(sku_array[i].depth > 0 && sku_array[i].height > 0)){
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
                if(active_category.sku_category_type_id=='shutter_hardware' && !(sku_array[i].depth > 0 && sku_array[i].height > 0 && sku_array[i].width > 0)){
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
                if(is_allow_material && !sku_array[i].material_id){
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
                if((sku_array[i].name && !check_spl_chars(sku_array[i].name)) || (sku_array[i].model_no && !check_spl_chars(sku_array[i].model_no))){
                    alert.error("Special characters are not allowed in SKU Name and Model Number")
                    return false
                }
                if(sku_array[i].sku_group_id && sku_array[i].sku_group_id != 'none' &&sku_array[i].id && sku_array[i].name /* && (sku_array[i].material_id || sku_array[i].low_model_3d_id)*/ && ((!is_allow_material && sku_array[i].placement_id && sku_array[i].placement_id != 'none' && sku_array[i].display_pic_id) || (is_allow_material && sku_array[i].height>0)) && sku_array[i].sales_channels && sku_array[i].sales_channels.length){
                    temp_final_sku_array.push(sku_array[i])
                    if(active_category.sku_category_type_id === 'shutter_hardware'){
                        temp_final_sku_array[temp_final_sku_array.length-1]['component_file'] = [{
                            id: uuid(),
                            container_component_id: null,
                            type: "obj_unit",
                            data: {
                                sku_id: sku_array[i].id,
                                width: Number(sku_array[i].width),
                                depth: Number(sku_array[i].depth),
                                height: Number(sku_array[i].height)
                            }
                        }]
                    }
                    // a.splice(i, 1)
                }else{
                    alert.error("Fill all the mandatory details or uncheck the SKU")
                    return false
                }
            }
        }
        if(temp_final_sku_array && temp_final_sku_array.length){
            set_final_sku_array(temp_final_sku_array)
            set_show_publish_confirmation_modal(true)
        }else{
            alert.error('Select any filled SKU or fill details of atleast one SKU')
            return false
        }
    }

    const check_all_boxes = (value) => {
        let temp_json = JSON.parse(JSON.stringify(checkbox_json))
        let a = [...sku_array]
        for(let i=0;i<sku_array.length;i++){
            a[i]['sku_checked'] = value
            temp_json[i] = value
        }
        if(value) set_highlight_empty_fields(false)

        set_sku_array(a)
        set_checkbox_json(temp_json)
    }

    const recheck_main_checkbox = (idx, value) => {
        let temp_json = JSON.parse(JSON.stringify(checkbox_json))
        temp_json[idx] = value
        set_checkbox_json(temp_json)
        console.log("temp json", temp_json)

        let temp_bool = true
        for(let i=0;i<sku_array.length;i++){
            if(!temp_json[i])
                temp_bool = false
        }
        let t = temp_bool ? set_main_checkbox(true) : set_main_checkbox(false)
    }

    const close_publish_confirmation_modal = () => {
        set_show_publish_confirmation_modal(false)
    }

    const open_add_sub_categories_modal = (mode) => {
		set_show_add_sub_category(true)
		set_sub_cat_mode(mode)
	}
	const close_add_sub_categories_modal = () => {
		set_show_add_sub_category(false)	
		set_sub_cat_mode('')
        fetch_sub_categories()
	}

    const show_add_new_group_modal = () => {
		set_open_add_new_group(true)
	}
	const close_add_new_group_modal = () => {
		set_open_add_new_group(false)	
        fetch_sku_groups(current_sub_cat_id)
	}

    const reset_sku_row = (idx) => {
        console.log("reset", sku_array[idx], idx)
        let a = [...sku_array];
        let temp_id = a[idx]['id']
        a[idx] = {id:temp_id}
        set_sku_array(a)
    }

    // const toDataURL = url => fetch(url)
    //   .then(response => response.blob())
    //   .then(blob => new Promise((resolve, reject) => {
    //   const reader = new FileReader()
    //   reader.onloadend = () => resolve(reader.result)
    //   reader.onerror = reject
    //   reader.readAsDataURL(blob)
    //  }))

    //  function dataURLtoFile(dataurl, filename) {
    //     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    //     while(n--){
    //     u8arr[n] = bstr.charCodeAt(n);
    //     }
    //   return new File([u8arr], filename, {type:mime});
    //  }

    const duplicate_sku_row = async(idx, new_sku_name) => {
        let a = [...sku_array]
        let temp = JSON.parse(JSON.stringify(a[idx]))
        // temp['show_ellipsis_options'] = false
        temp['id'] = get_unique_sku_id()
        temp['name'] = new_sku_name
        // temp['display_pic'] = a[idx]['display_pic']
        // toDataURL(a[idx]['display_pic_url']).then(dataUrl => {
        //     let file = dataURLtoFile(dataUrl, a[idx]['display_pic_name'])
        //     console.log("js file", file)
        //     // file.type = 'image/jpeg'
        //     temp['display_pic'] = file
        //     console.log("js file", file, temp['display_pic'])
        // })
        
        // try{
        //     let data = new FormData()
        //     data.append('upl', temp['display_pic'])
        //     let response = await general_fetch({url: 'image/add', body:data, is_form_data:true, req_resp:true})
        //     temp['display_pic_id'] =  response && response.data?response.data.id:''
        //     temp['display_pic_url'] = response && response.data?response.data.image:''
        // }catch(err){
        //     console.log(err)
        // }
        temp['display_pic'] = ''
        temp['display_pic_url'] = ''
        temp['display_pic_name'] = ''
        temp['display_pic_id'] = null
        temp['low_model_3d_id'] = null
        temp['material_id'] = null

        a.splice(idx+1, 0, temp)
        console.log("duplicated row",a)
        set_sku_array(a)
    }

    const calculate_top_left = (idx) => {
        let height = document.getElementById("ellipsis"+idx).offsetTop;
        set_ellipsis_options_height(height); 
        console.log("ellipsis height", height, "ellipsis"+idx)
        let left = document.getElementById("ellipsis"+idx).offsetLeft;
        set_ellipsis_options_left(left)
    }

    const save_as_draft = async() => {
        try{
            let final_sku_array = []
            if(sku_array && sku_array.length){ 
                sku_array.map((single_sku) => {
                if(single_sku.sku_group_id || single_sku.sub_category ) final_sku_array.push(single_sku)
                })
            }
            let resp = await general_fetch({url:'sku_bulk_operation/save_draft', body:{bulk_operation_attempt_id:active_attempt_id ,data:final_sku_array}})
            if(resp)
                alert.success('Saved as Draft!')
        }catch(err){
            alert.error('Save as Draft Failed!')
            console.log(err)
        }
    }

    const onclick_get_cat = async() => {
        try {
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories'});	
			set_all_division_tree_data(response)
        } catch(err) {
            console.log(err);
            // alert.success('Please contact admin')
        }
    }

    useEffect(() => {
        console.log("sku_array ----->", sku_array, default_pricing_unit, default_pricing_dimension)
        if(open && sku_array && can_add_new_rows()){
            add_new_rows()
        }
        if((using_import_file_method || using_edit_draft_method) && sku_array.length && !already_fetched){
            console.log("fetching details")
            let temp_selected_arrays = []
            let temp_sub_cat_ids = []
            let data = [...sku_array]
            for(let i=0;i<data.length;i++){
                if(data[i]['sales_channels'])
                {
                    temp_selected_arrays.push(data[i]['sales_channels'])
                    update_no_of_price_type(i, data[i]['sales_channels'])
                    if(using_edit_draft_method == 'published_history'){
                        console.log('entered edit_draft_method')
                        let sales_channel_array = data[i]['sales_channels']
                        for(let j=0;j<sales_channel_array.length;j++){
                            let price_types_array = sales_channel_array[j]['price_types']
                            for(let z=0;z<price_types_array.length;z++){
                                if(price_types_array[z]['display_unit'] && price_types_array[z]['price']){
                                    console.log('pric_type_calculate_display_price', price_types_array[z]['display_unit'], price_types_array[z]['price'])
                                    price_types_array[z]['price'] = calculate_display_price(price_types_array[z]['price'], price_types_array[z]['display_unit']) ? Math.round(calculate_display_price(price_types_array[z]['price'], price_types_array[z]['display_unit']) * 10000000000) / 10000000000 : '0'
                                }else if(price_types_array[z]['price']){
                                    console.log('pric_type_calculate_display_price', price_types_array[z]['display_unit'], price_types_array[z]['price'])
                                    price_types_array[z]['display_unit'] = default_pricing_unit
                                    price_types_array[z]['price'] = calculate_display_price(price_types_array[z]['price'], default_pricing_unit) ? Math.round(calculate_display_price(price_types_array[z]['price'], default_pricing_unit) * 10000000000) / 10000000000 : '0'
                                }else if(!price_types_array[z]['display_unit']){
                                    price_types_array[z]['display_unit'] = default_pricing_unit
                                }
                            }
                        }
                    }
                }else{
                    temp_selected_arrays.push([])
                }

                if(data[i]['sub_category'] && data[i]['sub_category'] != 'none'){
                    // add_new_sku_groups(data[i]['sub_category'])
                    temp_sub_cat_ids.push(data[i]['sub_category'])
                }
            }

            if(temp_sub_cat_ids.length > 0){
                fetch_sku_groups_listed(temp_sub_cat_ids)
            }

            set_selected_sales_channels_arrays(temp_selected_arrays)
            set_already_fetched(true)
        }
    },[sku_array, open, using_import_file_method, using_edit_draft_method])

    useEffect(() => {
        if(active_category && open){
            console.log("active_category", active_category)
            fetch_sub_categories()
        }
    },[active_category, open])

    useEffect(() => {
        if(active_division_id){
            fetch_category_types_data()
        }
    },[active_division_id])

    useEffect(() => {
        if(active_category && category_types_data){
            let temp = category_types_data.find(o => o.id == active_category.sku_category_type_id)
            if(temp){
                set_default_pricing_unit(temp.pricing_unit)
                set_default_pricing_dimension(temp.pricing_dimension)
            }
        }
    },[active_category, category_types_data])

    useEffect(() => {
        if(open){
            fetch_sales_channel_details()
            fetch_all_pref()
            onclick_get_cat()
            get_all_brands()
        }
    },[open])

    useEffect(() => {
        console.log("sku_array_idx", sku_array_idx)
    },[sku_array_idx])

    useEffect(() =>{
		if (!open) {
			return;
		}
		if(active_category){
		    console.log("active div/ cat type change : ", active_division_id, active_category.sku_category_type_id)
            if(active_division_id==='finish'|| (active_division_id==='hardware'&& (active_category.sku_category_type_id ==='panel_core_hardware' || active_category.sku_category_type_id ==='mirror_hardware' || active_category.sku_category_type_id ==='skirting_hardware'||active_category.sku_category_type_id ==='edge_band_hardware'))){
                set_is_allow_material(true)
            }
        }
	},[active_division_id, active_category, open])

    useEffect(() => {
		if(!(materials_json && materials_json.length)){
            console.log("calling material/get")
			fetch_material_json();
		}
        console.log("calling material_template/get")
		// if(!(texture_json && texture_json.length)){
        //     console.log("calling material_template 1/get")
		// 	fetch_texture_json();
		// }
        fetch_material_template()
	}, []);

    useEffect(() => {
        console.log('viewing_published_skus',viewing_published_skus)
    }, [viewing_published_skus]);

    return(
        <Modal style={{height:'700px', width:'1500px', padding:'0px', marginTop:'64px', marginLeft:'0px', pointerEvents:viewing_published_skus?'none':'auto'}} isOpen={open} toggle={() => {viewing_published_skus?onclick_handleClose():set_show_save_draft_warning(true)}}>

            <Upload3d_modal_modal 
                open={show_Upload3d_modal_modal} 
                handleclose={close_Upload3d_modal_modal} 
                set_return_confirm_low_resp_data={set_return_confirm_low_resp_data}
                return_confirm_low_resp_data={return_confirm_low_resp_data}
                active_division_id={active_division_id}
                set_page_loader={set_page_loader}
                update_sku_array = {update_sku_array}
                sku_array_idx = {sku_array_idx}
            />
            <Add_new_material 
                set_return_material_details={set_return_material_details} 
                return_material_details={return_material_details} 
                open={show_add_new_material} 
                handle_close={close_add_new_material} 
                fetch_material_template={fetch_material_template} 
                material_template={material_template} 
                materials_json={materials_json}
                set_page_loader={set_page_loader} 
                texture_json={texture_json}
                material_mode={material_mode}
                set_material_mode={set_material_mode}
                show_restore_button={true}
                update_sku_array = {update_sku_array}
                sku_array_idx = {sku_array_idx}
            />

            <AddSubCategory 
                open={show_add_sub_category} 
                handleClose={close_add_sub_categories_modal}
                user={user} 
                sku_category_id={active_category?active_category.id:''} 
                mode={sub_cat_mode} 
                set_page_loader={set_page_loader}
                fetch_sku_sub_categories = {fetch_sub_categories}
            />

            <AddNewGroup open={open_add_new_group} handleClose={close_add_new_group_modal} user={user} fetch_sku_groups_details={fetch_sku_groups} sub_category_id={current_sub_cat_id}/>      
            <PublishConfirmationModal
                open = {show_publish_confirmation_modal}
                handleClose = {close_publish_confirmation_modal}
                bulk_upload = {bulk_upload}
                final_sku_array = {final_sku_array}
                active_category = {active_category}
            />

            <SaveDraftWarning
                open = {show_save_draft_warning}
                handleClose = {close_save_draft_warning}
                close_bulk_create_modal = {onclick_handleClose}
                save_as_draft = {save_as_draft}
            />

            <AdditionalPropsModal id={sku_array_idx} 
                open={show_additional_props_modal} 
                handleClose={() => set_show_additional_props_modal(false)} 
                sku_name={sku_array[sku_array_idx] ? sku_array[sku_array_idx].name : ''} 
                additional_props = {sku_array[sku_array_idx] && sku_array[sku_array_idx].additional_properties ? sku_array[sku_array_idx].additional_properties:[]} 
                set_additional_props = {(val) => update_sku_array(sku_array_idx, 'additional_properties', val)} 
                // additional_props_custom = {sku_array[sku_array_idx] && sku_array[sku_array_idx].additional_props_custom?sku_array[sku_array_idx].additional_props_custom:[]} 
                // set_additional_props_custom = {(val) => update_sku_array(sku_array_idx, 'additional_props_custom', val)}
            />

            <LinkedSkuModal
                open = {show_linked_skus_modal}
                handleClose={() => set_show_linked_skus_modal(false)}
                sku_name={sku_array[sku_array_idx] ? sku_array[sku_array_idx].name : ''} 
                selected_sku_properties={sku_array && sku_array[sku_array_idx] ? sku_array[sku_array_idx]:null}
                all_division_tree_data={all_division_tree_data}
                active_division_id={active_division_id}
                sku_category_type={active_category ? active_category.sku_category_type_id : null}
                set_linked_skus = {(val) => update_sku_array(sku_array_idx, 'linked_skus', val)} 
            />

            <ReferenceSkuModal
                open = {show_reference_sku_modal}
                handleClose = {() => set_show_reference_sku_modal(false)}
                sku_name={sku_array[sku_array_idx] ? sku_array[sku_array_idx].name : ''}
                sku_category_type={active_category ? active_category.sku_category_type_id : null}
                all_division_tree_data = {all_division_tree_data}
                reference_sku_id = {sku_array[sku_array_idx] ? sku_array[sku_array_idx].reference_sku_id : null}
                bulk_sku_array = {sku_array}
                bulk_sku_array_idx = {sku_array_idx}
                set_bulk_sku_array = {set_sku_array}
                set_page_loader = {set_page_loader}
            />

            <NameCloneModal
                open = {show_name_clone_modal}
                handleClose = {() => set_show_name_clone_modal(false)}
                duplicate_sku_row = {duplicate_sku_row}
                sku_array_idx = {sku_array_idx}
                existing_sku_name = {sku_array[sku_array_idx] && sku_array[sku_array_idx].name ? sku_array[sku_array_idx].name : ''}
            />

            <Card style={{borderRadius: 0 , height:'92vh', width:'100vw', overflowY:'scroll', overflowX:'scroll'}}>
                <CardHeader className='global_modal_header'>
                    <span>
                    {viewing_published_skus ? 'Viewing Published SKUs' : 'Create SKUs'}
                    </span>
                    <i onClick={() => {set_show_save_draft_warning(true)}} style={{cursor:'pointer'}} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body' style={{}}>
                    <Row style={{padding:'0px 10px 10px 15px', color:'#22272e'}}>
                        <span>{'Category:  '}</span><span style={{fontWeight:'900'}}>{active_category?active_category.name:''}</span>
                    </Row>
                    <Row id={'parent_row'} style={{height:'calc(100% - 30px)',overflow:'scroll'}} onScroll = {() => set_show_ellipsis_options(null)}>
                        <Col style={{height:'100%', width:'calc(100% - 40px)',paddingLeft:'10px', paddingRight:'0'}} >
                            <Card id={'card1'} style={{borderRadius: 0, height:'100%', width:'calc(100%-40px)' , overflowX:'scroll',overflowY:'auto'}} onScroll={() => {let y = document.getElementById('card1');set_scroll_number(y.scrollTop); let z = document.getElementById('card2'); z.scrollTop=y.scrollTop}}>
                                <table style={{width:'2500px' , textAlign:'left',pointerEvents:viewing_published_skus?'none':'auto'}}>
                                    <thead style={{}}>
                                        <tr className='tr_style_no_flex' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
                                            {/* <th className='th_style_no_flex' style={{fontWeight:'900', width:'35px'}}><span></span></th> */}
                                            <th className='th_style_no_flex' style={{width:'35px', paddingLeft:'1.65%', paddingBottom:'1%'}}>
                                                <Input type = 'checkbox' checked={main_checkbox} style={{cursor:'pointer'}} onClick = {() => { let temp = !main_checkbox; check_all_boxes(temp); set_main_checkbox(temp) }} />
                                            </th>
                                            <th className='th_style_no_flex' >
                                                <div style={{fontWeight:'900', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                    <Tooltip title='Select the existing subcategory of the sku to be created or create a subcategory of your choice by clicking +' placement='top' mouseEnterDelay={0.5}>
                                                        <span style={{cursor:'pointer'}}>Sub-category*</span>
                                                    </Tooltip>
                                                    <span title='Add New Sub-Category' onClick={() =>{open_add_sub_categories_modal('add')}} style={{cursor:'pointer', height:'25px', width:'25px', backgroundColor:'rgba(16, 112, 202, 0.08)',display:'flex', alignItems:'center', justifyContent:'center', marginRight:'5px'}}><i style={{color:'#3686F7', fontSize:'14px'}} className='fa fa-plus'/></span>
                                                </div>
                                                {/* <span title='Add New Sub-Category' onClick={() =>{open_add_sub_categories_modal('add')}}  style={{height:'16px', width:'16px',marginRight:"8px",cursor:"pointer",display:'flex', alignItems:'center'}}><i style={{backgroundColor:'white', color:'#1070CA', fontSize:'14px'}} className="fas fa-plus-circle"/></span> */}
                                                {/* {
                                                    isHovered['sub_cat_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'10px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'15%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>SubCategory* </div>
                                                            <div style={{paddingTop:'5px'}}>Select the existing subcategory of the sku to be created or create a subcategory of your choice by clicking +</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}>
                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',cursor:'pointer'}}>
                                                    <Tooltip title='Select the existing Group of sku to be created or create a new group of your choice by clicking +' placement='top' mouseEnterDelay={0.5}>
                                                        <span>Group*</span>
                                                    </Tooltip>
                                                    {/* <span title='Add New Group' onClick={() =>{show_add_new_group_modal()}} style={{cursor:'pointer', height:'25px', width:'25px', backgroundColor:'rgba(16, 112, 202, 0.08)',display:'flex', alignItems:'center', justifyContent:'center', marginRight:'5px'}}><i style={{color:'#3686F7', fontSize:'14px'}} className='fa fa-plus'/></span> */}
                                                    {/* {
                                                        isHovered['group_thead']?
                                                        <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                            <div class="tooltip-arrow" style={{left:'80%'}} />
                                                            <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                                <div style={{fontSize:'14px', fontWeight:'900'}}>Group* </div>
                                                                <div style={{paddingTop:'5px'}}>Select the existing Group of sku to be created or create a new group of your choice by clicking +</div>
                                                            </div>
                                                        </div>
                                                        :''
                                                    } */}
                                                </div>
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px', position:'sticky', left:'0', zIndex:'2',cursor:'pointer'}}>
                                                <Tooltip title='Name of the SKU will be displayed in the design app, avoid dupliate name to avoid confusion' placement='top' mouseEnterDelay={0.5}>
                                                    <span style={{}}>SKU Name*</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['sku_name_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>SKU Name* </div>
                                                            <div style={{paddingTop:'5px'}}>Name of the SKU will be displayed in the design app, avoid dupliate name to avoid confusion</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            {
                                                active_category && (active_category.sku_category_type_id == 'cabinet_furniture' || active_category.sku_category_type_id == 'wardrobe_furniture')?
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px', position:'sticky', left:'0', zIndex:'2',cursor:'pointer'}}>
                                                    <Tooltip title='Pick the reference sku' placement='top' mouseEnterDelay={0.5}>
                                                        <span>Cloned From*</span>
                                                    </Tooltip>
                                                </th>
                                                :''
                                            }
                                            {/* <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}><span>SKU ID*</span></th> */}
                                            {
                                                is_allow_material?
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px',cursor:'pointer'}} >
                                                    <Tooltip title='Thickness of the SKU in millimetre' placement='top' mouseEnterDelay={0.5}>
                                                        <span>Thickness*(mm)</span>
                                                    </Tooltip>
                                                    {/* {
                                                        isHovered['thickness_thead']?
                                                        <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                            <div class="tooltip-arrow" style={{left:'80%'}} />
                                                            <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                                <div style={{fontSize:'14px', fontWeight:'900'}}>Thickness* </div>
                                                                <div style={{paddingTop:'5px'}}>Thickness of the SKU in millimetre</div>
                                                            </div>
                                                        </div>
                                                        :''
                                                    } */}
                                                </th>
                                                :
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}} >
                                                    <span>Placement*</span>
                                                    {/* {
                                                        isHovered['placement_thead']?
                                                        <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                            <div class="tooltip-arrow" style={{left:'80%'}} />
                                                            <div class="tooltip-label" style={{height:'80px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                                <div style={{fontSize:'14px', fontWeight:'900'}}>Placement* </div>
                                                                <div style={{paddingTop:'5px'}}>Placement of the SKU</div>
                                                            </div>
                                                        </div>
                                                        :''
                                                    } */}
                                                </th>
                                            }
                                            {
                                                active_category && active_category.sku_category_type_id == 'tile_finish' ?
                                                <>
                                                    <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Height*(mm)</span></th>
                                                    <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Width*(mm)</span></th>
                                                </>:''
                                            }
                                            {
                                                active_category && active_category.sku_category_type_id == 'shutter_hardware' ?
                                                <>
                                                    <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}><span>Thickness*(mm)</span></th>
                                                    <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Width*(mm)</span></th>
                                                    <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Height*(mm)</span></th>
                                                </>:''
                                            }
                                            {
                                                active_category && active_category.sku_category_type_id == 'edge_band_hardware'?
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}><span>Tape Width*(mm)</span></th>
                                                :''
                                            }
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Model No.</span></th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}><span>Brand</span></th>
                                            {
                                                !is_allow_material ?
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Display Image*</span></th>
                                                :''
                                            }

                                            {
                                                is_allow_material?
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Material Info*</span></th>
                                                :
                                                <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>3D Model</span></th>
                                            }
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Additional Properties</span></th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'150px'}}><span>Linked SKUs</span></th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'200px',cursor:'pointer'}} >
                                                <Tooltip title='Pricing setting for the SKU, Select the desired sales channel' placement='top' mouseEnterDelay={0.5}>
                                                    <span>Sales Channels*</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['sales_channels_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>Sales Channels* </div>
                                                            <div style={{paddingTop:'5px'}}>Pricing setting for the SKU, Select the desired sales channel</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px',cursor:'pointer'}}>
                                                <Tooltip title='Type of pricing contains MRP, List Price, Discount etc..' placement='top' mouseEnterDelay={0.5}>
                                                    <span>Price Type</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['price_type_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>Price Type* </div>
                                                            <div style={{paddingTop:'5px'}}>Type of pricing contains MRP, List Price, Discount etc..</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px',cursor:'pointer'}}>
                                                <Tooltip title='How sku is charged, Eg: based on one unit or one sq feet etc.' placement='top' mouseEnterDelay={0.5}>
                                                    <span>Price Unit</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['price_unit_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>Price Unit* </div>
                                                            <div style={{paddingTop:'5px'}}>How sku is charged, Eg: based on one unit or one sq feet etc.</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px'}}><span>Price</span></th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px',cursor:'pointer'}}>
                                                <Tooltip title='Pricing margin set for the SKU in percentage' placement='top' mouseEnterDelay={0.5}>
                                                    <span>Margin(%)</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['margin_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>Margin* </div>
                                                            <div style={{paddingTop:'5px'}}>Pricing margin set for the SKU in percentage</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            <th className='th_style_no_flex' style={{fontWeight:'900', width:'100px',cursor:'pointer'}}>
                                                <Tooltip title='Tax margin set for the SKU in percentage' placement='top' mouseEnterDelay={0.5}>
                                                    <span>Tax(%)</span>
                                                </Tooltip>
                                                {/* {
                                                    isHovered['tax_thead']?
                                                    <div id="tooltip" class="bottom" style={{ position:'absolute',top:'10px', left:'-163px',zIndex:'2'}}>
                                                        <div class="tooltip-arrow" style={{left:'80%'}} />
                                                        <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                                            <div style={{fontSize:'14px', fontWeight:'900'}}>Tax* </div>
                                                            <div style={{paddingTop:'5px'}}>Tax margin set for the SKU in percentage</div>
                                                        </div>
                                                    </div>
                                                    :''
                                                } */}
                                            </th>
                                            {/* <th className='th_style_no_flex' style={{fontWeight:'900', width:'40px'}}><span></span></th> */}
                                        </tr>
                                    </thead>
                                    <tbody style={{width:"100%", height:"calc(100% - 40px)"}}>
                                    {
                                        sku_array && sku_array.length ? sku_array.map((single_sku,idx) => (
                                            <>
                                                <tr key={idx} className='tr_style_no_flex' 
                                                    style={{ position:'relative' , borderTop:(selected_row==idx || selected_row==idx-1 || hovered_row==idx || hovered_row==idx-1)? '2px solid rgba(0, 120, 255, 0.6)' :'1px solid #f2f4f6', borderLeft: (selected_row==idx || hovered_row==idx)? '2px solid rgba(0, 120, 255, 0.6)' :'', borderRight:(selected_row==idx || hovered_row==idx)? '2px solid rgba(0, 120, 255, 0.6)' :'' ,height:'44px', overflowY:'visible'}} 
                                                    onClick={() => {set_selected_row(idx)}}
                                                    onMouseOver = {() => {set_hovered_row(idx)}}
                                                    onMouseLeave = {() => {set_hovered_row(null)}}
                                                >
                                                    {
                                                        (hovered_row == idx || (sku_array[idx]['sku_published'] || viewing_published_skus) || sku_array[idx]['sku_checked']) ?
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'35px', paddingLeft:'1.65%', paddingBottom:'1%',backgroundColor: sku_array[idx]['sku_checked']?'rgba(0, 120, 255, 0.1)':''}}>
                                                            <Input type = 'checkbox' style={{accentColor: (sku_array[idx]['sku_published'] || viewing_published_skus) ? '#23C16B' : '',cursor:'pointer'}} checked = {sku_array[idx]['sku_checked'] ? true :false} onClick = {() => { let temp_checked = !sku_array[idx]['sku_checked'] ;update_sku_array(idx,'sku_checked', temp_checked); if(temp_checked) set_highlight_empty_fields(!temp_checked) ;recheck_main_checkbox(idx, temp_checked)}} />
                                                        </td>
                                                        :
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'35px', backgroundColor: sku_array[idx]['sku_checked']?'rgba(0, 120, 255, 0.1)':''}}><span>{idx+1}</span></td>
                                                    }
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                        <span>
                                                            <Input type='select' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].sub_category || sku_array[idx].sub_category == 'none' ))?'1px solid red':'1px solid #e4e7eb', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category!='none')?'1':'0.5'}} value={sku_array[idx].sub_category?sku_array[idx].sub_category:''} onChange={(e) => {update_sku_array(idx,'sub_category',e.target.value); add_new_sku_groups(e.target.value)}}>
                                                                <option value='none'>Select a Sub-Category</option>
                                                                {
                                                                    sku_sub_categories && sku_sub_categories.length ? sku_sub_categories.map((single_sub_category) => (
                                                                        !single_sub_category.deprecated ? <option value={single_sub_category.id}>{single_sub_category.name}</option>:''
                                                                    )):''
                                                                }
                                                            </Input>
                                                        </span>
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                        <span>
                                                        {
                                                            sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' ?
                                                            <div style={{display:'flex',alignItems:'center',height:'100%'}}>
                                                                <Input type='select' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].sku_group_id || sku_array[idx].sku_group_id == 'none' ))?'1px solid red':'1px solid #f2f4f6', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5'}} value={sku_array[idx].sku_group_id?sku_array[idx].sku_group_id:''} onChange={(e) => {update_sku_array(idx,'sku_group_id',e.target.value)}}>
                                                                    <option value='none'>Select a Group</option>
                                                                    {
                                                                        sku_groups_json[sku_array[idx].sub_category] && sku_groups_json[sku_array[idx].sub_category].length ? sku_groups_json[sku_array[idx].sub_category].map((single_group) => (
                                                                               !single_group.depricated ? <option value={single_group.id}>{single_group.name}</option> :''
                                                                            )):''
                                                                    }
                                                                </Input>
                                                                {
                                                                    (selected_row==idx || hovered_row==idx) && !(sku_array[idx]['sku_published'] || viewing_published_skus) ?
                                                                    <span title='Add New Group' onClick={() =>{set_current_sub_cat_id(sku_array[idx].sub_category);show_add_new_group_modal()}} style={{cursor:'pointer', height:'25px', width:'25px', backgroundColor:'white',display:'flex', alignItems:'center', justifyContent:'center', marginRight:'0px'}}><i style={{color:'#3686F7', fontSize:'14px'}} className='fa fa-plus'/></span>
                                                                    :""
                                                                }
                                                            </div>
                                                            :''
                                                        }
                                                        </span>
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px',position:'sticky', left:'0', zIndex:'1'}}>
                                                        <span >
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <Input type='text' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && !sku_array[idx].name)?'1px solid red':'1px solid #f2f4f6',backgroundColor:(sku_array[idx]['sku_published'] || viewing_published_skus)?'':'white'}} value={sku_array[idx].name?sku_array[idx].name:''} onChange = {(e) => {update_sku_array(idx, 'name', e.target.value)}} />
                                                            :''
                                                        }
                                                        </span>
                                                    </td>
                                                    {/* <td rowSpan={(sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px',  opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5'}}>
                                                        <span>
                                                        {sku_array[idx].id ? sku_array[idx].id :""}
                                                        </span>
                                                    </td> */}
                                                    {/* <td rowSpan={(sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                        <span>

                                                            <Input type='number' style={{height:'100%',fontSize:'12px', border:'0'}} value={sku_array[idx].width?sku_array[idx].width:''} onChange = {(e) => {update_sku_array(idx, 'width', e.target.value)}} />
                                                        </span>
                                                    </td>
                                                    <td rowSpan={(sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                        <span>
                                                            <Input type='number' style={{height:'100%',fontSize:'12px', border:'0'}} value={sku_array[idx].depth?sku_array[idx].depth:''} onChange = {(e) => {update_sku_array(idx, 'depth', e.target.value)}} />
                                                        </span>
                                                    </td> */}
                                                    {
                                                        active_category && (active_category.sku_category_type_id == 'cabinet_furniture' || active_category.sku_category_type_id == 'wardrobe_furniture')?
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5', width:'100%', height:'100%',border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && !(sku_array[idx].component_file && sku_array[idx].component_file.length))?'1px solid red':'', borderRadius:'4px'}}>
                                                            {
                                                                (sku_array[idx].component_file && sku_array[idx].component_file.length) ?
                                                                <>
                                                                    <i style={{color:'#47B881', marginRight:'10px', fontSize:'14px'}} className='fa fa-check-circle' />
                                                                    <button onClick={() => {set_sku_array_idx(idx);set_show_reference_sku_modal(true)}} className='div_box' style={{width:'110px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>Change SKU</button>
                                                                </>
                                                                :
                                                                <button onClick={() => {set_sku_array_idx(idx);set_show_reference_sku_modal(true)}} className='div_box' style={{width:'110px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>+ Select SKU</button>
                                                            }
                                                            </div>:''
                                                        }
                                                        </td>:''
                                                    }
                                                    {
                                                        is_allow_material?

                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                            <span>
                                                            {
                                                                sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].height))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].height?sku_array[idx].height:''} onChange = {(e) => {Number(e.target.value>=0)?update_sku_array(idx, 'height', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                :''
                                                            }
                                                            </span>
                                                        </td>
                                                        :
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <Input type='select' style={{height:'100%',fontSize:'12px', opacity:(sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none')?'1':'0.5',border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].placement_id || sku_array[idx].placement_id == 'none' ))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].placement_id?sku_array[idx].placement_id:''} onChange={e => {update_sku_array(idx, 'placement_id', e.target.value)}} >
                                                                <option value='none'>Choose Placement</option>
                                                                <option value='base'>Base</option>
                                                                <option value='base_corner'>Base Corner</option>
                                                                <option value='wall'>Wall</option>
                                                                <option value='wall_corner'>Corner Wall</option>
                                                                <option value='ceiling'>Ceiling</option>
                                                                <option value='default'>Default</option>
                                                            </Input>
                                                            :''
                                                        }
                                                        </td>
                                                    }
                                                    {
                                                        active_category && active_category.sku_category_type_id == 'tile_finish' ?
                                                        <>
                                                            <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                <span>
                                                                {
                                                                    sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                    <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].depth))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].depth?sku_array[idx].depth:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'depth', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                    :''
                                                                }
                                                                </span>
                                                            </td>
                                                            <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                <span>
                                                                {
                                                                    sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                    <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].width))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].width?sku_array[idx].width:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'width', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                    :''
                                                                }
                                                                </span>
                                                            </td>
                                                        </>:''
                                                    }
                                                    {
                                                        active_category && active_category.sku_category_type_id == 'shutter_hardware' ?
                                                        <>
                                                            <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                <span>
                                                                {
                                                                    sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                    <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].height))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].height?sku_array[idx].height:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'height', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                    :''
                                                                }
                                                                </span>
                                                            </td>
                                                            <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                <span>
                                                                {
                                                                    sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                    <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].width))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].width?sku_array[idx].width:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'width', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                    :''
                                                                }
                                                                </span>
                                                            </td>
                                                            <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                <span>
                                                                {
                                                                    sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                    <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].depth))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].depth?sku_array[idx].depth:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'depth', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                    :''
                                                                }
                                                                </span>
                                                            </td>
                                                        </>:''
                                                    }
                                                    {
                                                        active_category && active_category.sku_category_type_id == 'edge_band_hardware'?
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                            <span>
                                                            {
                                                                sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                                <Input type='number' style={{height:'100%',fontSize:'12px', border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].depth))?'1px solid red':'1px solid #f2f4f6'}} value={sku_array[idx].depth?sku_array[idx].depth:''} onChange = {(e) => {Number(e.target.value)>=0?update_sku_array(idx, 'depth', e.target.value):alert.error('Negative numbers are not allowed !')}} />
                                                                :''
                                                            }
                                                            </span>
                                                        </td>
                                                        :''
                                                    }
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px',position:'sticky', left:'0', zIndex:'1'}}>
                                                        <span >
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <Input type='text' style={{height:'100%',fontSize:'12px', border:'1px solid #f2f4f6',backgroundColor:(sku_array[idx]['sku_published'] || viewing_published_skus)?'':'white'}} value={sku_array[idx].model_no?sku_array[idx].model_no:''} onChange = {(e) => {update_sku_array(idx, 'model_no', e.target.value)}} />
                                                            :''
                                                        }
                                                        </span>
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <Input type='select' style={{height:'100%',fontSize:'12px', opacity:(sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none')?'1':'0.5',border:'1px solid #f2f4f6'}} value={sku_array[idx].brand_id?sku_array[idx].brand_id:''} onChange={e => {update_sku_array(idx, 'brand_id', e.target.value)}} >
                                                                <option value=''>Choose Brand</option>
                                                            {
                                                                all_brands && all_brands.length ? all_brands.map((single_brand) => (
                                                                    <option value={single_brand.id}>{single_brand.name}</option>
                                                                )):''
                                                            }
                                                            </Input>
                                                            :''
                                                        }
                                                    </td>
                                                    {
                                                        !is_allow_material ?
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px',overflowX:'visible'}}>
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                            <div style={{display:'flex', alignItems:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5',border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].display_pic_id))?'1px solid red':'', width:'100%', height:'100%', borderRadius:'4px'}}>
                                                            {
                                                                
                                                                // <Input id={idx} type='file' style={{display:'none'}} onChange = {(e) => {set_display_image(idx, e.target.files[0])}} accept='.jpg,.png,.jpeg'/>
                                                                sku_array[idx]['display_pic_id'] && sku_array[idx]['display_pic_url'] && sku_array[idx]['display_pic_name'] ?
                                                                <div style={{marginLeft:'15px', cursor:'pointer', position:'relative'}}>
                                                                    <img src={global.config.server_path.slice(0,-1)+sku_array[idx]['display_pic_url']} width='45px' height='35px' onClick={() => {document.getElementById("image_preview"+idx).click();}} onMouseOver={() => {handlOnMouseOver(null,idx)}} onMouseLeave = {() => {handlOnMouseLeave(null,idx)}} />
                                                                    <span style={{marginLeft:'10px'}}>{sku_array[idx]['display_pic_name'].length>15?sku_array[idx]['display_pic_name'].slice(0,15)+'...'+sku_array[idx]['display_pic_name'].slice(sku_array[idx]['display_pic_name'].length-2,2):sku_array[idx]['display_pic_name']}</span>
                                                                    <Input id={"image_preview"+idx} type="file" onChange={(e) => {set_display_image(idx, e.target.files[0]);e.stopPropagation();}} style={{display:"none"}} accept='.jpg,.jpeg,.png'/>
                                                                    {
                                                                        sku_array[idx]['hovered_display_pic'] && !viewing_published_skus ?
                                                                        // <ReactTooltip id="sub_category" place="bottom" effect="solid">
                                                                        //     Tooltip for the register button
                                                                        // </ReactTooltip>
                                                                        <div id="tooltip" class="bottom" style={{ position:'absolute',top:'14px', left:'-137px',zIndex:'2'}}>
                                                                            <div class="tooltip-arrow" style={{left:'80%'}} />
                                                                            <div class="tooltip-label" style={{height:'100px', width:'200px',borderRadius:'8px', paddingTop:'5px'}}>
                                                                                <div style={{fontSize:'14px', paddingTop:'5px', fontWeight:'900'}}>Click on it to re-upload </div>
                                                                                <div>Please upload image in .png or .jpeg format</div>
                                                                                <div>(File size not exceeding 10MB)</div>
                                                                            </div>
                                                                        </div>
                                                                        :''
                                                                    }
                                                                </div>
                                                                :
                                                                <UploadDisplayImage accept=".jpg,.jpeg,.png" id={'upload display pic' + idx} set_display_image={set_display_image} index={idx} />
                                                                
                                                            }
                                                            </div>
                                                            :''
                                                        }
                                                        </td>
                                                        :''
                                                    }
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                    {
                                                        sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ? 
                                                        (is_allow_material?
                                                        <div style={{display:'flex', alignItems:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5',border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].material_id))?'1px solid red':'', borderRadius:'4px', width:'100%', height:'100%'}}>
                                                            <Button onClick={() => {set_sku_array_idx(idx);open_add_new_material()}} style={{backgroundColor: 'white', borderColor: 'black', color:'black', marginLeft:'10px', fontSize:'12px', opacity:(sku_array[idx]['sku_published'] || viewing_published_skus)?'0.5':'1'}}>Upload</Button>
                                                            {sku_array[idx]['material_id']?<div style={{marginLeft:'10px',paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><span style={{color:'green', padding:'2px' ,backgroundColor:'white', whiteSpace:"nowrap", opacity:(sku_array[idx]['sku_published'] || viewing_published_skus)?'0.5':'1'}}>Material Uploaded</span></div>:''}
                                                        </div>
                                                        :
                                                        <div style={{display:'flex', alignItems:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5',/*border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].low_model_3d_id))?'1px solid red':'',*/ borderRadius:'4px', width:'100%', height:'100%'}}>
                                                            <Button onClick={() => {set_sku_array_idx(idx);open_Upload3d_modal_modal()}} style={{backgroundColor: 'white', borderColor: 'black', color:'black', marginLeft:'10px', fontSize:'12px', opacity:(sku_array[idx]['sku_published'] || viewing_published_skus)?'0.5':'1'}}>Upload</Button>
                                                            {sku_array[idx]['low_model_3d_id']?<div style={{marginLeft:'10px',paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><span style={{color:'green', padding:'2px' ,backgroundColor:'white', whiteSpace:"nowrap", opacity:(sku_array[idx]['sku_published'] || viewing_published_skus)?'0.5':'1'}}>files Uploaded</span></div>:''}
                                                        </div>):''
                                                    }
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                    {
                                                        sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5', width:'100%', height:'100%', borderRadius:'4px'}}>
                                                        {
                                                            (sku_array[idx].additional_properties && sku_array[idx].additional_properties.length) ?
                                                            <>
                                                                <i style={{color:'#47B881', marginRight:'10px', fontSize:'14px'}} className='fa fa-check-circle' />
                                                                <button onClick={() => {set_sku_array_idx(idx);set_show_additional_props_modal(true)}} className='div_box' style={{width:'116px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>Edit Properties</button>
                                                            </>
                                                            :
                                                            <button onClick={() => {set_sku_array_idx(idx);set_show_additional_props_modal(true)}} className='div_box' style={{width:'116px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>+ Add Properties</button>
                                                        }
                                                        </div>:''
                                                    }
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px'}}>
                                                    {
                                                        sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ?
                                                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', opacity:(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none')?'1':'0.5', width:'100%', height:'100%', borderRadius:'4px'}}>
                                                        {
                                                            (sku_array[idx].linked_skus && sku_array[idx].linked_skus.length) ?
                                                            <>
                                                                <i style={{color:'#47B881', marginRight:'10px', fontSize:'14px'}} className='fa fa-check-circle' />
                                                                <button onClick={() => {set_sku_array_idx(idx);set_show_linked_skus_modal(true)}} className='div_box' style={{width:'120px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>Edit Linked SKUs</button>
                                                            </>
                                                            :
                                                            <button disabled={(['hardware','accessory','finish'].includes(active_division_id) || (sku_array[idx].additional_properties && sku_array[idx].additional_properties.length && sku_array[idx].additional_properties.filter(x => x.key == 'cabinet_type' && x.value == 'smart_cube').length))?false:true} onClick={() => {set_sku_array_idx(idx);set_show_linked_skus_modal(true)}} className='div_box' style={{width:'120px', height:'28px', alignItems:'center', cursor:'pointer', borderRadius:'4px'}}>+ Add Linked SKUs</button>
                                                        }
                                                        </div>:''
                                                    }
                                                    </td>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'200px', padding:'0px', overflow:'visible', height:'40px'}}>
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' ? 
                                                            <div style={{display:'flex', alignItems:'center',width:'98%',border:(highlight_empty_fields && sku_array[idx]['sku_checked'] && (!sku_array[idx].sales_channels || !sku_array[idx].sales_channels.length))?'1px solid red':'',borderRadius:'3px', margin:'0.4% 0%'}}>
                                                                <Col style={{margin:'0px', padding:'0px', height:'40px'}}>
                                                                    <Multiselect 
                                                                        style={{backgroundColor:'white', border:'0', width:'200px'}}
                                                                        options = {all_sales_channels && all_sales_channels.length? [...all_sales_channels]:[]}
                                                                        selectedValues = {selected_sales_channels_arrays[idx]}
                                                                        onSelect={(selectedList) => onclick_item(idx, selectedList)}
                                                                        onRemove={(selectedList) => onclick_item(idx, selectedList)}
                                                                        displayValue = 'name'
                                                                        placeholder = {'Select Sales Channels'}
                                                                        disable = {(sku_array[idx]['sku_published'] || viewing_published_skus) ? true : false}
                                                                    />
                                                                </Col>
                                                            </div>
                                                            :''
                                                        }
                                                        { sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && selected_sales_channels_arrays[idx] ? <hr style={{margin:"0px", padding:'0px'}}></hr> :''}
                                                        {
                                                            sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['sales_channels'] && sku_array[idx]['sales_channels'].length ? sku_array[idx]['sales_channels'].map((sales_channel,id) => (  
                                                                <>
                                                                    <Col style={{marginTop:'0px', marginLeft:'10px', paddingTop:'10px', fontSize:'12px', height:sales_channel['price_types'].length*40 + 'px'}}>
                                                                        {sales_channel.name}
                                                                    </Col>
                                                                    {(id != sku_array[idx]['sales_channels'].length-1)?<hr style={{margin:"0px", padding:'0px'}}></hr>:''}
                                                                </>
                                                            )):''
                                                        }
                                                    </td>
                                                    {
                                                        show_ellipsis_options == idx?
                                                        // <div id={'ellipsis'+idx} class='ellipsis_options' >
                                                        //     <div style={{padding:' 7px 16px', width:'150px', height:'32px'}} onclick={() => {console.log("tooltip click")}}>Reset Row</div>
                                                        //     <div style={{padding:' 7px 16px', width:'150px', height:'32px'}}>Duplicate Row</div>
                                                        //     <div style={{padding:' 7px 16px', width:'150px', height:'32px', color:'red'}}>Delete</div>
                                                        // </div>
                                                        <div id="tooltip" class="left" style={{left:'calc(100vw - 210px)',top:195+ellipsis_options_height-scroll_number+'px',zIndex:'15',position:'fixed', backgroundColor:'white',boxShadow:'#efeaea 1px 1px 3px 1px', borderRadius:'8px'}}>
                                                            <div class="tooltip-arrow" style={{top:'16%',left:'151px', backgroundColor:'white', borderLeftColor:'#efeaea', borderWidth:'10px 0 10px 10px'}} />
                                                            <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', paddingTop:'10px', color:'black',backgroundColor:'white'}} onClick={() => {console.log("click on tool tip");reset_sku_row(idx);set_show_ellipsis_options(null)}}>
                                                                Reset Row
                                                            </div>
                                                            <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', paddingTop:'7px',color:'black',backgroundColor:'white'}} onClick={() => {console.log("click on tool tip");set_sku_array_idx(idx);set_show_name_clone_modal(true);set_show_ellipsis_options(null)}}>
                                                                Duplicate Row
                                                            </div>
                                                            <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', color:'red',backgroundColor:'white'}} onClick={() => {console.log("click on tool tip");delete_sku_row(idx);set_show_ellipsis_options(null)}}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                        :''
                                                    }
                                                </tr>
                                                {
                                                    (sku_array && sku_array[idx] && sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none') && sku_array[idx]['sales_channels'] && sku_array[idx]['sales_channels'].length ? sku_array[idx]['sales_channels'].map((sales_channel,id) => (  
                                                        sales_channel && sales_channel['price_types'] && sales_channel['price_types'].length ? sales_channel['price_types'].map((single_price_type,index) => {
                                                            return (
                                                                <tr key={sales_channel.id+single_price_type.id} className='tr_style_no_flex' style={{borderTop:'1px solid #f2f4f6', borderLeft:(selected_row==idx || hovered_row==idx)?'2px solid rgba(0, 120, 255, 0.6)':'',height:'40px', overflowY:'visible'}} onClick={() => {set_selected_row(idx)}}>
                                                                    <td key={single_price_type.id+index+'1'} className='td_style_no_flex' style={{width:'150px', fontSize:'12px',}}>{single_price_type.name}</td>
                                                                    <td key={single_price_type.id+index+'2'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                        <Input type='select' value={ single_price_type['display_unit']?single_price_type['display_unit']:(default_pricing_unit?default_pricing_unit:'')} style={{height:'100%',fontSize:'12px', border:'0'}} onChange = {(e) => {update_sku_array_sales_channel(idx, sales_channel.id, single_price_type.id, 'display_unit', e.target.value)}}>
                                                                        {
                                                                            default_pricing_dimension && default_pricing_dimension=='per_unit_area'?
                                                                            <>
                                                                                <option value='sq_millimetre'>sq_millimetre</option>
                                                                                <option value='sq_metre'>sq_metre</option>
                                                                                <option value='sq_foot'>sq_foot</option>
                                                                            </>
                                                                            :
                                                                            (
                                                                                default_pricing_dimension && default_pricing_dimension=='per_unit_length'?
                                                                                <>
                                                                                    <option value='millimetre'>millimetre</option>
                                                                                    <option value='metre'>metre</option>
                                                                                    <option value='foot'>foot</option>
                                                                                </>:
                                                                                <option value='none'>per_unit</option>
                                                                            )
                                                                        }
                                                                        </Input>
                                                                    </td>
                                                                    <td key={single_price_type.id+index+'3'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                        <Input type='number' value={single_price_type['price']} style={{height:'100%',fontSize:'12px', border:'0'}} onChange = {(e) => {update_sku_array_sales_channel(idx, sales_channel.id, single_price_type.id, 'price', e.target.value)}}/>
                                                                    </td>
                                                                    <td key={single_price_type.id+index+'4'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px'}}>
                                                                        <Input type='number' value={single_price_type['margin']} style={{height:'100%',fontSize:'12px', border:'0'}} onChange = {(e) => {update_sku_array_sales_channel(idx, sales_channel.id, single_price_type.id, 'margin', e.target.value)}}/>
                                                                    </td>
                                                                    <td key={single_price_type.id+index+'5'} className='td_style_no_flex' style={{width:'100px', paddingLeft:'0px', borderRight:(selected_row==idx || hovered_row==idx)?'2px solid rgba(0, 120, 255, 0.6)':''}}>
                                                                        <Input type='number' value={single_price_type['tax']} style={{height:'100%',fontSize:'12px', border:'0'}} onChange = {(e) => {update_sku_array_sales_channel(idx, sales_channel.id, single_price_type.id, 'tax', e.target.value)}}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }):''
                                                    )):''
                                                }

                                                {/* <tr>
                                                    <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} className='td_style_no_flex' style={{width:'150px', paddingLeft:'0px',position:'sticky',right:'0',zIndex:'1'}}>
                                                        <i style={{color:'grey', paddingLeft:'5px', paddingTop:'12px'}} className='fa fa-ellipsis-h'/>
                                                    </td>
                                                </tr> */}
                                            </>
                                        )):''
                                    }
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                        <Col style={{width:'40px', padding:'0', margin:'0', height:'100%',position:'relative', zIndex:'10'}}>
                            <Card id={'card2'} style={{borderRadius: 0, height:'100%',width:'40px',padding:'0px',marginRight:'10px',overflowY:'auto',position:'relative'}} onScroll={() => {let y = document.getElementById('card1'); let z = document.getElementById('card2'); set_scroll_number(z.scrollTop);y.scrollTop=z.scrollTop}}>
                                <table style={{width:'35px', backgroundColor:'white',position:'relative',pointerEvents:viewing_published_skus?'none':'auto'}}>
                                        <thead>
                                            <tr>
                                                <th className='th_style_no_flex' style={{width:'35px'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{overflowY:"visible",width:"100%", height:"calc(100% - 40px)",position:'relative'}}>
                                        {
                                            sku_array && sku_array.length ? sku_array.map((single_sku,idx) => (
                                                <>
                                                    <tr id={"ellipsis"+idx} key={idx} style={{height:'44px', display:'flex',alignItems:'centre', position:'relative'}} onClick={() => {set_selected_row(idx)}}>
                                                        <td rowSpan={(sku_array[idx].sub_category && sku_array[idx].sub_category != 'none' && sku_array[idx].sku_group_id && sku_array[idx].sku_group_id != 'none' && sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length>0)?sku_array[idx]['no_of_price_types'].length+1:'1'} style={{position:'relative'}}>
                                                            <i style={{color:'grey', paddingLeft:'5px', paddingTop:'12px',cursor:'pointer'}} className='fa fa-ellipsis-h' onClick={() => {show_ellipsis_options==idx ? set_show_ellipsis_options(null) : set_show_ellipsis_options(idx); calculate_top_left(idx) }}/>
                                                            {/* {
                                                                show_ellipsis_options == idx?
                                                                // <div id={'ellipsis'+idx} class='ellipsis_options' >
                                                                //     <div style={{padding:' 7px 16px', width:'150px', height:'32px'}} onclick={() => {console.log("tooltip click")}}>Reset Row</div>
                                                                //     <div style={{padding:' 7px 16px', width:'150px', height:'32px'}}>Duplicate Row</div>
                                                                //     <div style={{padding:' 7px 16px', width:'150px', height:'32px', color:'red'}}>Delete</div>
                                                                // </div>
                                                                <div id="tooltip" class="left" style={{left:'-100px',top:'',zIndex:'500',position:'absolute'}}>
                                                                    <div class="tooltip-arrow" style={{top:'20%'}} />
                                                                    <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', borderRadius:'4px 4px 0px 0px', paddingTop:'10px'}} onClick={() => {console.log("click on tool tip");reset_sku_row(idx);set_show_ellipsis_options(null)}}>
                                                                        Reset Row
                                                                    </div>
                                                                    <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', borderRadius:'0px',paddingTop:'5px'}} onClick={() => {console.log("click on tool tip");duplicate_sku_row(idx);set_show_ellipsis_options(null)}}>
                                                                        Duplicate Row
                                                                    </div>
                                                                    <div class="tooltip-label" style={{cursor:'pointer',display:'flex', flexDirection:'column', height:'40px', borderRadius:'0px 0px 4px 4px', color:'red'}} onClick={() => {console.log("click on tool tip");delete_sku_row(idx);set_show_ellipsis_options(null)}}>
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                                :''
                                                            } */}
                                                        </td>
                                                    </tr>
                                                    {
                                                        sku_array[idx]['no_of_price_types'] && sku_array[idx]['no_of_price_types'].length ? sku_array[idx]['no_of_price_types'].map(x => (
                                                            <tr style={{height:'40px', display:'flex',alignItems:'centre'}} onClick={() => {set_selected_row(idx)}}></tr>
                                                        )):""
                                                    }
                                                </>
                                            )):''
                                        }
                                        </tbody>
                                    </table>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                {
                    viewing_published_skus?
                    <Button className='white_button' style={{marginRight:'5px'}} onClick={onclick_handleClose} type="button">Close</Button>
                    :
                    <>
                        <Button className='white_button' type="button" onClick={() => {save_as_draft()}}> Save as Draft </Button>
                        <Button className='blue_button' type="button" onClick={() => {bulk_upload_check()}} onMouseOver={() => {handlOnMouseOver('publish_button',null)}} onMouseLeave = {() => {handlOnMouseLeave('publish_button',null)}}>Publish</Button>
                    </>
                }
                    {/* {
                        isHovered['publish_button']?
                        <div id="tooltip" class="top" style={{ position:'absolute', bottom:'100px', right:'100px',zIndex:'2'}}>
                            <div class="tooltip-arrow" style={{left:'15%'}} />
                            <div class="tooltip-label" style={{height:'108px', width:'250px',borderRadius:'8px', padding:'12px 15px 12px 15px', textAlign:'left'}}>
                                <div style={{fontSize:'14px', fontWeight:'900'}}>Publish </div>
                                <div style={{paddingTop:'5px'}}>Clicking on it publishes the selected SKUS rows</div>
                            </div>
                        </div>
                        :''
                    } */}
                </CardFooter>
            </Card>
        </Modal>
    )
}

export default BulkCreateModal;