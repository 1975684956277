import React , { useEffect, useState }from 'react';
import general_fetch from '../../../utils/fetch';
import {Modal, Button, Input, Card, CardBody, CardHeader, CardFooter, Col, Row, Nav, NavItem, NavLink, TabContent,TabPane } from "reactstrap";
import { useAlert } from 'react-alert'
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import classnames from "classnames";
import Common_tag_component from '../../../components/Common_tag_component';

const styles = {
	owned_style: {
		display: "unset",
		backgroundColor:"#000033", 
		color:'white',
		borderRadius:"3px",
		zIndex:250,
		fontSize:'12px',
		padding:"0px 3px"
	},
};

const Tab_Structure = ({set_page_loader , handleClose , user, fetch_sku_sub_categories, sku_category_id, sku_sub_category_details, mode, sku_groups, set_sku_sub_category_id, submit_for_delete_confirm, onclick_remove_sub_cat, orignal_tags}) => {

	const [ sub_category_name , set_sub_category_name ] = useState('')
	const [	radio_value, set_radio_value ] = useState('false');
	const [ file, set_file ] =useState('') 
	const [ sub_category_order , set_sub_category_order] = useState(0);	
	const [	tabs , set_tabs] = useState(1)
	const [	tags_assigned_inherited , set_tags_assigned_inherited] = useState('')
	const [	all_inherited_tags , set_all_inherited_tags] = useState('')
	const [	eligible_sub_cat_tags , set_eligible_sub_cat_tags] = useState([])
	const alert = useAlert();
	const history = useHistory()

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const fetch_sku_sub_category_tags = async () => {
		if(sku_sub_category_details){
			try {
				var resp = await general_fetch({ url: 'sku_sub_category/get_tags', body:{ids:[sku_sub_category_details.id]}});
				set_tags_assigned_inherited(resp)
			}catch(err) {
				console.log(err);
			}
		}
	}

	const get_sub_category_eligible_tags = async(id) => {
        try { 
            let response = await general_fetch({ url: 'sku_sub_category/get_eligible_tags', body: { id: id}});
            set_eligible_sub_cat_tags(response && response.length ? response: []);

        }
        catch(err) {
            console.error(err);
        }
    }

	const onclick_add_new = async () => {
		if( sku_category_id && sub_category_name ){
			try {
				// var fd = new FormData();
				// fd.append('name', sub_category_name)
				// fd.append('order', sub_category_order)
				// fd.append('sku_category_id', sku_category_id)
				// fd.append('list_view', radio_value)
				// fd.append('store_id', user.store_id)
				// file && fd.append('upl', file, file.name)
				var resp = await general_fetch({ url: 'sku_sub_category/add', body:{name:sub_category_name, order:sub_category_order, sku_category_id:sku_category_id, list_view:radio_value}});
				fetch_sku_sub_categories();
				onclick_handleClose()
				alert.success('Sub Category Added Successfully')
				
			}catch(err) {
				alert.error('Failed to Add Sub Category')
				console.log(err);
			}
		}else{
			alert.error('Please enter Sub Category Name')
		}
	}

	const onclick_edit_sub_cat = async () => {
		if( sub_category_name && sku_sub_category_details ){
			try {
				var identifiers = JSON.stringify({id:sku_sub_category_details.id})
				var updates = JSON.stringify({name:sub_category_name, order:sub_category_order})
				// var fd = new FormData();
				// fd.append('identifiers', identifiers)
				// fd.append('updates', updates)
				var resp = await general_fetch({ url: 'sku_sub_category/update', body:{identifiers, updates}});
				fetch_sku_sub_categories();
				onclick_handleClose()
				alert.success('Sub Category Updated Successfully')
				
			}catch(err) {
				alert.error('Failed to Update Sub Category')
				console.log(err);
			}
		}else{
			alert.error('Please enter Sub Category Name')
		}
	}


	const onclick_delete_sub_cat = async () => {
		if( sku_groups && sku_groups.length===0){
			try {
				console.log(sku_groups)
				var resp = await general_fetch({ url: 'sku_sub_category/deprecate', body:{id:sku_sub_category_details.id} });
				fetch_sku_sub_categories();
				onclick_handleClose()
				alert.success('Sub Category Deleted Successfully')
				let myArr = (window.location.pathname).split('/')
				myArr.pop()
				myArr.pop()
				myArr.reverse()
				let new_path = myArr && myArr.length ? myArr.reduce((final,x) =>  x+'/'+final):''
				history.push(new_path)
			}catch(err) {
				alert.error('Failed to Delete Sub Category')
				console.log(err);
			}
		}else{
			alert.info('Please Delete all the group of the Sub-Category.')
		}
	}

	const update_sku_tag_assigned = async (selected_tag_array) => {
		if(sku_sub_category_details){
			try {
				var tag_array = selected_tag_array.map(x => x.id)
				var body = {ids: [sku_sub_category_details.id] , tag_ids: tag_array}
				set_page_loader({
					show:true,
					text:'Adding tag please wait ...'
				})
				var response_for_add_tag = await general_fetch({ url: 'sku_sub_category/attach_tags' , body});
				set_page_loader({
					show:false,
					text:''
				})
				fetch_sku_sub_category_tags()
				alert.success('Tags updated successfully')
			} catch(err) {
				alert.error('Error! in adding tags. Please Refresh')
				console.log(err);
			}
		}else{
			alert.error('No Tags Selected')
		}
	}

	const update_sku_tag_detached = async (selected_tag_array) => {
		try {
			var tag_array = selected_tag_array.map(x => x.id)
			var body = {sku_sub_category_id: sku_sub_category_details.id , tag_id: tag_array}
			set_page_loader({
				show:true,
				text:'Removing tag please wait ...'
			})
			var response_for_del_sale = await general_fetch({ url: 'sku_sub_category/unattach_tag' , body});
			set_page_loader({
				show:false,
				text:''
			})
			alert.success('Tags successfully removed')
			fetch_sku_sub_category_tags()
		} catch(err) {
			alert.error('Error! in removing tag. Please Refresh')
			console.log(err);
		}
	}

	// const onclick_remove_sub_cat = async () => {
	// 	if( sku_sub_category_details){
	// 		try {
	// 			var sku_ids = []
	// 			var x= sku_groups && sku_groups.length ? sku_groups.map(group=>{
	// 				var x_sku= group.sku.length ? group.sku.map(x => x.id):[]
	// 				sku_ids=sku_ids.concat(x_sku)
	// 			}):[]
	// 			var identifiers = JSON.stringify({id:sku_ids})
	// 			var resp = await general_fetch({ url: 'sku/remove_from_store', body:{identifiers}});
	// 			fetch_sku_sub_categories();
	// 			onclick_handleClose()
	// 			alert.success('Sub Category removed Successfully')
	// 			alert.success('Use Add From Warehouse For Adding Sub Category')
	// 			set_sku_sub_category_id('');
	// 		}catch(err) {
	// 			alert.error('Failed to Remove Sub Category')
	// 			console.log(err);
	// 		}
	// 	}else{
	// 		alert.info('Please Select Sub Category')
	// 	}
	// }

	useEffect(() => {
		if(sku_sub_category_details && mode==='edit'){
			set_sub_category_name(sku_sub_category_details.name)
			set_sub_category_order(sku_sub_category_details.order)
			set_tabs((sku_sub_category_details && sku_sub_category_details.store_id===user.store_id)||mode==='add'?1:2)
			fetch_sku_sub_category_tags()
			get_sub_category_eligible_tags(sku_sub_category_details.id)
		}else{
			set_sub_category_name('')
			set_sub_category_order(0)
		}
	}, [mode])

	const onclick_handleClose = () => {
		handleClose()
		set_file('')
		set_sub_category_name('')
		set_sub_category_order(0)
		set_tags_assigned_inherited('')
		set_all_inherited_tags('')
	}

	useEffect(()=>{
		if(sku_sub_category_details && tags_assigned_inherited && tags_assigned_inherited[sku_sub_category_details.id] && tags_assigned_inherited[sku_sub_category_details.id].sku_category_tags && tags_assigned_inherited[sku_sub_category_details.id].sku_category_tags.length){
			var x_inherit = tags_assigned_inherited[sku_sub_category_details.id].sku_category_tags;
			set_all_inherited_tags(x_inherit)
		}else{
			set_all_inherited_tags([])
		}
	},[tags_assigned_inherited]) 

	return(
		<div style={{height:'100%', width:'100%'}}>
			<CardHeader className='global_modal_header'>
				<span><span>{mode==='edit'?'Edit Sub Category':'Add Sub Category'} {mode==='edit' && sku_sub_category_details? sku_sub_category_details.name:''}</span> &nbsp; {mode==='edit' && sku_sub_category_details && sku_sub_category_details.store_id === user.store_id?<span style={styles.owned_style}>Owned</span>:''}</span>
				<i style={{cursor: 'pointer'}} onClick={onclick_handleClose} className='fa fa-times'/>
			</CardHeader>
			<div className="nav-wrapper" style={{padding:'10px 15px'}}>
				<Nav 
					style={{display:'flex', alignItems:'center'}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>
					{
					// (sku_sub_category_details && sku_sub_category_details.store_id===user.store_id)||mode==='add'?
						<NavItem>
							<NavLink
								aria-selected={tabs === 1}
								className={classnames("mb-sm-3 mb-md-0", {
									active: tabs === 1
								})}
								onClick={e => toggleNavs(e, 1)}
								href="#pablo"
								role="tab"
							>
								<span>Details</span>
							</NavLink>
						</NavItem>
						// :''
					}
					{
						mode==='edit'?
						<NavItem>
							<NavLink
								aria-selected={tabs === 2}
								className={classnames("mb-sm-3 mb-md-0", {
										active: tabs === 2
								})}
								onClick={e => toggleNavs(e, 2)}
								href="#pablo"
								role="tab"
							>
								<span>Tags</span>
							</NavLink>
						</NavItem>
						:''
					}
				</Nav>
			</div>
			<TabContent activeTab={"tabs" + tabs}>

				<TabPane tabId="tabs1" style={{fontSize:'12px'}}>		
					<CardBody className='global_modal_body'>
						<Row>
							<Col style={{marginBottom:'15px'}}>
								<div>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name</div>
									<Input
										style={{width:'100%',height:'32px', fontSize:'12px', color: '#435A6F', borderRadius: '3px',
											backgroundColor: sku_sub_category_details && sku_sub_category_details.store_id === user.store_id||mode==='add'?'white':'#f9f9f9', 
											boxShadow: sku_sub_category_details && sku_sub_category_details.store_id === user.store_id||mode==='add'?'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)':'inset 0 -1px 1px 0 rgb(67 90 111 / 9%), inset 0 0 0 1px rgb(67 90 111 / 14%)'
										}} 
										id='input_sub_cat_name'
										value={sub_category_name} placeholder="Name" type="text"  
										onChange={sku_sub_category_details && sku_sub_category_details.store_id=== user.store_id ||mode==='add'?(e) => set_sub_category_name(e.target.value):null}
										disabled = {sku_sub_category_details && sku_sub_category_details.store_id=== user.store_id ||mode==='add'?false:true}
									/>
								</div>
							</Col>	
						</Row>
						
						<Row>
							<Col>
								<div>
									<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Order</div>
									<Input 
										style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} 
										placeholder="Order" min={0} type="number" value={sub_category_order?sub_category_order:0} 
										onChange={e => set_sub_category_order(Number(e.target.value ? e.target.value : 0))} id='input_sub_cat_order'
										disabled = {sku_sub_category_details && sku_sub_category_details.store_id=== user.store_id ||mode==='add'?false:true}
									/>
								</div>
							</Col>	
						</Row>
						{
							mode==='edit'?
							<Row style={{margin:"28px 0px 10px"}}>
								<Col style={{padding:0}}>
								{
									sku_sub_category_details && sku_sub_category_details.store_id === user.store_id?
									<span
										onClick={()=>{onclick_handleClose();submit_for_delete_confirm(()=>onclick_delete_sub_cat(), ()=>{}, 'Sub-Category will be permanentaly deleted from store')}}
										className='delete_button' style={{color:"#f5594e", backgroundImage:'none', backgroundColor:'#f5f6f8', fontSize:'14px', boxShadow:'unset',cursor:'pointer'}}>Delete Sub Category
									</span>:
									<span
										onClick={()=>{onclick_handleClose();submit_for_delete_confirm(()=>onclick_remove_sub_cat(), ()=>{}, 'Once removed you can add sub-category from Add to Warehouse','non-owned')}}
										className='delete_button'  style={{color:"#f5594e", backgroundImage:'none', backgroundColor:'#f5f6f8', fontSize:'14px', boxShadow:'unset',cursor:'pointer'}}>Remove Sub Category
									</span>
								}
								</Col>
							</Row>
							:''
						}
					</CardBody>

					<CardFooter className='global_modal_footer'>
						<div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}} >
							<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
							{mode==='edit'?<Button className='blue_button' type="button" onClick={onclick_edit_sub_cat}>Update</Button>:<Button className='blue_button' type="button" onClick={onclick_add_new}>Submit</Button>}
						</div>		
					</CardFooter>
						
				</TabPane>

				<TabPane tabId="tabs2" style={{minHeight:'300px'}}>
						<Common_tag_component 
							type='sku_sub_category'
							all_tags={eligible_sub_cat_tags} 
							assigned_tags={sku_sub_category_details && tags_assigned_inherited && tags_assigned_inherited[sku_sub_category_details.id] && tags_assigned_inherited[sku_sub_category_details.id].sku_sub_category_tags && tags_assigned_inherited[sku_sub_category_details.id].sku_sub_category_tags.length?tags_assigned_inherited[sku_sub_category_details.id].sku_sub_category_tags:[]} 
							seperated_inherited_tags={tags_assigned_inherited} 
							all_inherited_tags={all_inherited_tags} 
							active_item={sku_sub_category_details} 
							attach_tag_fn={update_sku_tag_assigned} 
							detatch_tag_fn={update_sku_tag_detached} 
							handleclose={()=>{}}
						/>
				</TabPane>
			</TabContent>	
		</div>
	);
}


const AddSubCategory = ({ open , handleClose, user, fetch_sku_sub_categories, sku_category_id, sku_sub_category_details, mode, sku_groups, set_sku_sub_category_id, submit_for_delete_confirm, onclick_remove_sub_cat, orignal_tags, set_page_loader}) => {

	const alert = useAlert();

	return (
		<Modal className='Margin_64px_top' size="md" isOpen={open} toggle={handleClose}>
			<Card className='global_modal_height_for_mdlgxl'>
				{/* <CardHeader className='global_modal_header'>
					<span style={{fontFamily: 'Source_Sans_Pro-SemiBold'}}>
						{mode==='add'?'Create':'Edit'} Sub-Category {mode==='edit' && sku_sub_category_details? sku_sub_category_details.name:''}
					</span>
					<span>	
						<span style={{cursor:'pointer'}} onClick={handleClose}><i className='fa fa-times'/></span>	
					</span>
				</CardHeader> */}
				<div>
					<Tab_Structure
						handleClose = {handleClose }
						user= {user}
						fetch_sku_sub_categories= {fetch_sku_sub_categories}
						sku_category_id= {sku_category_id}
						sku_sub_category_details= {sku_sub_category_details}
						mode= {mode}
						sku_groups= {sku_groups}
						set_sku_sub_category_id= {set_sku_sub_category_id}
						submit_for_delete_confirm= {submit_for_delete_confirm}
						onclick_remove_sub_cat= {onclick_remove_sub_cat}
						orignal_tags={orignal_tags}
						set_page_loader={set_page_loader}
					/>
				</div>
			</Card> 
		</Modal>
	);
}

export default AddSubCategory ; 

//<AlertBox open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />