import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Toolbar from '../../components/Toolbar/'
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
import BusinessUnitDialogBox from './BusinessUnitDialogBox';
import { useAlert } from 'react-alert';

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};

const Filler = ({ initialized }) => {
	const [fetch_progress, set_fetch_progress] = useState(60);
	return (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Business Units to list  </b></span>&nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							{/* <Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col> */}
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching Business Units </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;
					</th>
				)}
			</tr>
		</thead>
	</Table>	
	)
};

const BusinessUnits = ({user, set_active_tab, set_active_division_id_sidebar, send_analytics_event, get_users, submit_for_delete_confirm, store_details, get_store_details}) => {

    const [search_string , set_search_string] = useState(null);
	const [initialized , set_initialized] = useState(false);
    const [business_units, set_business_units] = useState([]);
    const [display_business_units, set_display_business_units] = useState([]);
    const [show_business_unit_Dialog_Box, set_show_business_unit_Dialog_Box] = useState(false);
    const [active_business_unit, set_active_business_unit] = useState('')
    const [mode, set_mode] = useState('')
    const [all_users, set_all_users] = useState([])
    const [user_json, set_user_json] = useState('')
    const alert = useAlert()

    const fetch_business_units = async () => {
        try {
            var resp = await general_fetch({ url: 'business_unit/get_all'});
            set_business_units(resp);
            if(!initialized)set_initialized(true)
            console.log('fetch_business_units', resp)
        } catch(err) {
            console.log(err);
        }
    }

    const fetch_all_users = async() => {
        try{
            let resp = await general_fetch({url:'user/get_all'})
            let temp_json={}
            let tem_user_array = resp && resp.length? resp.map(item => { temp_json[item.id] = item.name; return ({id:item.id, name: item.name})}):''
            set_all_users(tem_user_array)
            set_user_json(temp_json)
        }catch(err){
            console.log(err)
        }
    }

    const filter_business_units = () => {
        console.log(business_units)
		if(business_units){
            var filtered_business_units =[...business_units]
            console.log(filtered_business_units)
            var lower_search_string = search_string ? search_string.toLowerCase():'';
            if(lower_search_string){
                filtered_business_units = filtered_business_units.filter( business_units => business_units.name.toLowerCase().includes(lower_search_string))
            }
            set_display_business_units(filtered_business_units)
            }
	}

    const delete_business_unit = async(id) => {
        try{
            let resp = await general_fetch({url:'business_unit/remove', body:{id: id}})
            alert.success("Business Unit Deleted Successfully !")
            fetch_business_units()
        }catch(err){
            alert.error("Business Unit Deletion Failed !")
            Sentry.captureException(err)
        }
    }

    const close_business_unit_Dialog_Box = () => {
		set_show_business_unit_Dialog_Box(false);
		set_mode(null);
	}

	const open_business_unit_add_dialog = () => {
		set_show_business_unit_Dialog_Box(true);
		set_mode('add');
	}

    const open_business_unit_update_dialog = (single_business_unit) => {
        set_active_business_unit(single_business_unit)
		set_show_business_unit_Dialog_Box(true);
		set_mode('edit');
	}

	const open_business_unit_view_dialog = (single_business_unit) => {
        set_active_business_unit(single_business_unit)
		set_show_business_unit_Dialog_Box(true);
		set_mode('view');
	}

    useEffect(() => {
		set_display_business_units(business_units);
	}, [business_units]);

	// useEffect(() => {
	// 	if (!initialized && display_business_units) {
	// 		set_initialized(true);
	// 	}
	// }, [display_business_units]);

	useEffect(() => {
			filter_business_units();
	}, [search_string]);

    useEffect(() => {
        set_active_division_id_sidebar('business_units')
        fetch_business_units()
        fetch_all_users()
    }, []);

    const BusinessUnits_Table = () => (
        <table style={{height:'100%'}} className="text-left flex_property table_style">
            <thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
				<th className='th_style flex_property' style={{flex:'5'}}><span>Business Unit</span></th>
				<th className='th_style flex_property' style={{flex:'3'}}><span>Logo</span></th>
                <th className='th_style flex_property' style={{flex:'2'}}><span>No. of Users</span></th>
				<th className='th_style ' style={{flex:'10'}}><span></span></th>
    
			</tr>
			</thead>
            
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
            {
                display_business_units && display_business_units.length && display_business_units.map((single_business_unit, idx) => (
                    <tr className='tr_style display_onhover'>
                        <td className='td_style flex_property' style={{flex:'5', height:'80px'}}><span className='table_text_overflow'>{single_business_unit.name}</span></td>   
                        <td className='td_style flex_property' style={{flex:'3', height:'80px'}}><img className='table_text_overflow' height='60px' width='60px' style={{maxWidth:'80px'}} src={single_business_unit.logo_path?global.config.server_path.slice(0,-1)+single_business_unit.logo_path:"/assets/img/icons/empty_image.png"} /></td>   
                        <td className='td_style flex_property' style={{flex:'2', height:'80px'}}><span className='table_text_overflow'>{single_business_unit.user_ids ? single_business_unit.user_ids.length:0}</span></td> 
                        <td className='td_style flex_property x1' style={{ justifyContent:'flex-end',overflow:'hidden', flex:'10'}}>
                            <span className='td_style flex_property x1' style={{ justifyContent:'flex-end'}}>
                                <span className='hide' style={{marginRight:'32px', cursor:'pointer', fontWeight:'900'}} onClick={() => open_business_unit_view_dialog(single_business_unit)}>View Users</span>
                                <span className='hide' style={{marginRight:'32px', cursor:'pointer'}}><i onClick ={() => open_business_unit_update_dialog(single_business_unit)} className='fa fa-edit' aria-hidden="true"/></span>
                                <span className='hide' style={{marginRight:'32px', cursor:'pointer', color:'red'}}><i onClick={()=>submit_for_delete_confirm(()=>delete_business_unit(single_business_unit.id), ()=>{}, 'Are you sure you want to delete this Business Unit?')} className='fa fa-trash'/></span>
                            </span>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )

    try{
        return (
            <Main_Grid active_tab="Business Units" user={user} store_details={store_details} get_store_details={get_store_details}>
                <BusinessUnitDialogBox user={user} open={show_business_unit_Dialog_Box} handleClose={close_business_unit_Dialog_Box} mode={mode} all_users={all_users} active_business_unit={active_business_unit} set_active_business_unit={set_active_business_unit} fetch_business_units={fetch_business_units} user_json={user_json}/>
                <Row style={{padding:'10px 10px', color:'#22272e'}}>
                    <Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
                        Viewing {display_business_units && display_business_units.length ?display_business_units.length:'0'} Business Units
                    </Col>
                    <Col xs={6} style={{textAlign:'right'}}>
                        <button onClick = {() => open_business_unit_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Business Unit</span></button>
                    </Col>
                </Row>
                <Row style={{height:'calc(100% - 90px)'}}>
                    <Col style={{height:'100%'}}>
                        <Card style={{borderRadius: 0, height:'100%'}}>
                            <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Business Unit Name' /></div>
                            <div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_business_units && display_business_units.length ? (<BusinessUnits_Table/>) : (<Filler initialized={initialized}/>)}</div>
                        </Card>
                    </Col>
                </Row>
            </Main_Grid>
        )
    }catch(err){
        Sentry.captureException(err)
    }
}

export default BusinessUnits