const primary_menu_options = [
	// {
	// 	text: 'Dashboard', 
	// 	icon: 'home',
	// 	url: '/home/',
	// 	key: 'home'
	// },
	{
		text: 'Project Hub',
		icon: 'list-alt',
		url: '/project_hub/',
		key: 'project_hub',
		sub_menu:[{
			text: 'Projects', 
			icon: 'list-alt',
			url: '/project_hub/design_hub/',
			key: 'design_hub',
			id: 'design_hub',
		},
		{
			text: 'Design Templates', 
			icon: 'list-alt',
			url: '/project_hub/design_templates/',
			key: 'design_templates',
			id: 'design_templates',
		}] 
	},
	{
		text: 'Catalogue', 
		icon: 'clone',
		url: '/catalogue/',
		key: 'catalogue',
		sub_menu: [{
			text: 'Furniture',
			id:'furniture',
			icon: 'clone',
			url: '/catalogue/furniture/',
			key: 'catalogue/furniture',
		}, {
			text: 'Accessories',
			id:'accessory', 
			icon: 'clone',
			url: '/catalogue/accessory/',
			key: 'catalogue/accessory',
		}, {
			text: 'Hardware', 
			id:'hardware',
			icon: 'clone',
			url: '/catalogue/hardware/',
			key: 'catalogue/hardware',
		}, {
			text: 'Building', 
			id:'building',
			icon: 'clone',
			url: '/catalogue/building/',
			key: 'catalogue/building',
		}, {
			text: 'Finishes', 
			id:'finish',
			icon: 'clone',
			url: '/catalogue/finish/',
			key: 'catalogue/finish',
		}, {
			text: 'Templates', 
			id:'template',
			icon: 'clone',
			url: '/catalogue/template/',
			key: 'catalogue/template',
		}]
	},
	{
		text: 'Brands', 
		icon: 'suitcase',
		url: '/brands/',
		key: 'Brands'
	},
	{
		text: 'Design Intelligence',
		icon: 'sitemap',
		url: '/design_intelligence/',
		key: 'DesignIntelligence',
		sub_menu: [
		{
			text: 'Rule Summary', 
			icon: 'sitemap',
			id: 'Design_intelligence',
			url: '/design_intelligence/design_intelligence/',
			key: 'Design_intelligence'
		},
		{
			text: 'Design Constraints', 
			icon: 'sitemap',
			id: 'DC_rules',
			url: '/design_intelligence/dc_rules/',
			key: 'DC_rules'
		},
		{
			text: 'Manufacturing Rules', 
			icon: 'sitemap',
			id: 'MF_rules',
			url: '/design_intelligence/mf_rules/',
			key: 'MF_rules'
		},
		{
			text: 'Hardware Rules', 
			icon: 'sitemap',
			id: 'HW_rules',
			url: '/design_intelligence/hw_rules/',
			key: 'HW_rules'
		},
		{
			text: 'Design Automation', 
			icon: 'sitemap',
			id: 'AM_rules',
			url: '/design_intelligence/am_rules/',
			key: 'AM_rules'
		}]
	},
	
	// {
		// 	text: 'Pricing Rules', 
		// 	icon: 'list',
		// 	url: '/panel_pricing_rule/',
		// 	key: 'panel_pricing_rule'
		// },
	{
		text: 'Category Type', 
		icon: 'list-alt',
		url: '/category_type/',
		key: 'category_type'
	},
	{
		text: 'Tags', 
		icon: 'tags',
		url: '/tags/',
		key: 'Tags'
	},
	{
		text:'Sales Channel', 
		icon:'chart-bar',
		url: '/sales/',
		key: 'Sales'
	},
	{
		text: 'Billing & Usage', 
		icon: 'calculator',
		url: '/billing/',
		key: 'billing',
		type:'professional',
		sub_menu: [
			{
				text: 'User Management',
				id:'users', 
				icon: 'users',
				url: '/billing/users/',
				key: '/billing/users'
			},
			{
				text: 'Business Units', 
				// icon: 'users',
				url: '/billing/business_units/',
				key: 'business_units',
				id: 'business_units'
			},
			{
				text:'Billing', 
				id:'usage',
				url:'/billing/usage/',
				key: 'usage'
			},
		// {
		// 	text: 'Payments & Invoice',
		// 	id:'payment', 
		// 	icon: 'calculator',
		// 	url: '/billing/payments/',
		// 	key: 'billing/payments'
		// },
		// {
		// 	text: 'Detailed Usage', 
		//  id:'usage'
		// 	icon: 'database',
		// 	url: '/billing/usage/',
		// 	key: 'billing/usage'
		// }
		]
	},	
	{
		text: 'Catalogue Archive', 
		icon: 'archive',
		url: '/archive_catalogue/',
		key: 'ArchiveCatalogue'
	},
	{
		text: 'Custom Reports', 
		icon: 'archive',
		url: '/custom_reports/',
		key: 'CustomReport'
	},
	{
		text: 'Usage Metrics', 
		icon: 'users',
		url: '/usage_metrics/',
		key: 'Usage_Metrics'
	},
	// {
	// 	text: 'Preferences', 
	// 	icon: 'cogs',
	// 	url: '/preferences/',
	// 	key: 'Preferences'
	// },
	// {
	// 	text: 'Plugins', 
	// 	icon: 'plug',
	// 	url: '/plugin/',
	// 	key: 'Plugin'
	// },
	// {
	// 	text: 'Tutorial', 
	// 	icon: 'film',
	// 	url: '/tutorial/',
	// 	key: 'Tutorials'
	// },
	
	
	{
		text: 'Preferences and Settings',
		icon: 'cogs',
		url: '/preferences_settings/',
		key: 'preferences_settings',
		sub_menu:[{
			text:'Designer Input', 
			icon:'users',
			url: '/preferences_settings/designer_input/',
			id: 'designer_input',
			key: 'designer_input'
		},
		{
			text:'Design App Configuration', 
			icon:'cogs',
			url: '/preferences_settings/feature_flags/',
			id: 'feature_flags',
			key: 'feature_flags'
		},
		{
			text:'Master Preferences', 
			icon:'cogs',
			url: '/preferences_settings/design_preferences/',
			id:'design_preferences',
			key: 'design_preferences'
		},
		{
			text:'Org Preferences', 
			icon:'cog',
			url: '/preferences_settings/org_preference/',
			id: 'org_preference',
			key: 'org_preference'
		},
		{
			text: 'Project Statuses', 
			icon: 'sitemap',
			url: '/preferences_settings/project_statuses/',
			id: 'ProjectStatuses',
			key: 'ProjectStatuses'
		},
		{
			text: 'Room Type', 
			icon: 'archive',
			url: '/preferences_settings/room_type/',
			id: 'RoomType',
			key: 'RoomType'
		},
		]
	}
]

export { primary_menu_options };
// const primary_menu_options = [
// 	{
// 		text: 'Home', 
// 		icon: 'home',
// 		url: '/home/',
// 		key: 'home'
// 	},
// 	{
// 		text: 'Design Hub', 
// 		icon: 'list-alt',
// 		url: '/design_hub/',
// 		key: 'design_hub'
// 	},
// 	{
// 		text: 'Catalogue', 
// 		icon: 'clone',
// 		url: '/catalogue/',
// 		key: 'catalogue',
// 		sub_menu: [{
// 			text: 'Furniture', 
// 			icon: 'clone',
// 			url: '/catalogue/furniture/',
// 			key: 'catalogue/furniture',
// 		}, {
// 			text: 'Accessories', 
// 			icon: 'clone',
// 			url: '/catalogue/accessory/',
// 			key: 'catalogue/accessory',
// 		}, {
// 			text: 'Hardware', 
// 			icon: 'clone',
// 			url: '/catalogue/hardware/',
// 			key: 'catalogue/hardware',
// 		}, {
// 			text: 'Building', 
// 			icon: 'clone',
// 			url: '/catalogue/building/',
// 			key: 'catalogue/building',
// 		}, {
// 			text: 'Finishes', 
// 			icon: 'clone',
// 			url: '/catalogue/finish/',
// 			key: 'catalogue/finish',
// 		}]
// 	},
// 	{
// 		text: 'Brands', 
// 		icon: 'suitcase',
// 		url: '/Brands/',
// 		key: 'Brands'
// 	},
// 	{
// 		text: 'Tags', 
// 		icon: 'tags',
// 		url: '/Tags/',
// 		key: 'Tags'
// 	},
// 	{
// 		text:'Sales Channels', 
// 		icon:'bar-chart',
// 		url: '/Sales/',
// 		key: 'Sales'
// 	},
// 	{
// 		text: 'Billing Info', 
// 		icon: 'calculator',
// 		url: '/Billing/',
// 		key: 'Brands'
// 	},
// 	{
// 		text: 'Manage Accounts', 
// 		icon: 'users',
// 		url: '/Accounts/',
// 		key: 'Accounts'
// 	},
// 	{
// 		text: 'Usage Details', 
// 		icon: 'database',
// 		url: '/Usage/',
// 		key: 'Usage'
// 	},
// 	{
// 		text: 'Preferences', 
// 		icon: 'cogs',
// 		url: '/Preferences/',
// 		key: 'Preferences'
// 	},
// 	{
// 		text: 'Plugin', 
// 		icon: 'plug',
// 		url: '/Plugin/',
// 		key: 'Plugin'
// 	},
// 	{
// 		text: 'Tutorial', 
// 		icon: 'film',
// 		url: '/Tutorial/',
// 		key: 'Tutorial'
// 	},
// ]

// export { primary_menu_options };


