import React, { useState , useEffect, cloneElement } from 'react';
import {Dropdown, Label, Modal, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

import CheckBox_Inventory_Card from '../../../components/CheckBox_Inventory_Card';
import build_path from '../../../utils/path';
import general_fetch from '../../../utils/fetch';

import custom_sort from '../../../utils/custom_sort';
import Inventory_Layout from '../../../components/Inventory_Layout';
import './sku_groups.css';
import {primary_menu_options} from '../../../components/Sidebar/menu_options.js'
import { useAlert } from 'react-alert'
import LazyLoad from 'react-lazyload';
import name_sort from '../../../utils/name_sort';
//import primary_menu_options from '../../../components/Sidebar/menu_options.js'

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	categories_div: {
		height:'40px', 
		paddingLeft:'20px', 
		display:'flex', 
		alignItems:'center',
		color:'#435A6F',
		cursor: 'pointer'
	},
	text_elipsis: {
	  whiteSpace: 'nowrap',
	  overflowX: 'auto',
	},
	category_dropdown_style: {
		height: '18px',
	    fontSize: '14px',
	    backgroundColor: 'unset',
	    border: 'none',
	    padding: 0,
	    display: 'flex',
	    alignItems: 'center',
	    cursor:'pointer',
	}
};

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span><br/> Try adjusting filters or consider adding from warehouse<br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const Group_collapse  = ({ selected_skus, selected_sku_map, set_selected_skus, tag, unchecked_group_array, set_unchecked_group_array, checked_group_array, set_checked_group_array, sku_group, user, checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [show_collapse, set_show_collapse] = useState(false)
	// const [active_group_id, set_active_group_id] = useState('')
	// const [active_group_name, set_active_group_name] = useState('')
	const [	checked_map_sku, set_checked_map_sku] =	useState(false);
	const [ group_checked, set_group_checked ] = useState('');
	const [c_unc_indicator_group, set_c_unc_indicator_group] =	useState('');
	const [stop_initial_group, set_stop_initial_group] = useState(false);
	const [display, set_display] = useState('');
	const [ group_selection_map, set_group_selection_map ] = useState({});
	const [ is_group_selected, set_is_group_selected ] = useState(false);


	const open_close_collapse = () => {
		if(sku_group){
			// set_active_group_id(sku_group.id)
			// set_active_group_name(sku_group.name)
			set_show_collapse(!show_collapse)
		}
	}

	const toggle_group_selection = () => {
		let sku_map = sku_group.sku.reduce((final, elem) => ({ ...final, [elem.id]: true }), {});
		set_selected_skus(is_group_selected ? selected_skus.filter(x => !sku_map[x]) : [ ...new Set([ ...selected_skus, ...Object.keys(sku_map) ]) ]);
	}

	const on_click_group_checked = (e) => {
		e.stopPropagation()
		set_group_checked(!group_checked)
		set_stop_initial_group(true)
	}

	const check_group_ticked = () => {
		if(sku_group && sku_group.sku && sku_group.sku.length && checked_sku_array && checked_sku_array.length){
			var check_ticked = sku_group.sku.map((x) => checked_sku_array.includes(x.id))
			var check_ticked_1 = check_ticked && check_ticked.filter((x) => x==false)
			if(check_ticked_1 && check_ticked_1.length==0){
				set_group_checked(true)
			}else{
				set_group_checked(false)
			}
		}
	}

	useEffect(() => {
		console.log('checked_group_array =========>',checked_group_array)
		console.log('unchecked_group_array =========>', unchecked_group_array)
	}, [display])	

	useEffect(() => {
		if(sku_group && stop_initial_group ){
			if(!group_checked){
				var check = checked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'unchecked')
				set_stop_initial_group(false)
			}
			if(group_checked){
				var check = unchecked_group_array.filter((x) => x==sku_group.id);
				set_c_unc_indicator_group(check && check.length==1?'delete':'checked')
				set_stop_initial_group(false)
			}
		}
	},[group_checked])


	useEffect(() => {
		if (Object.keys(selected_sku_map).length) {
			// console.log('group selection ops ---<>>>> ', selected_sku_map, sku_group.sku, (sku_group.sku.filter(x => !selected_sku_map[x])).length);
			set_is_group_selected(sku_group.sku.filter(x => !selected_sku_map[x.id]).length ? false : true);
		} else {
			set_is_group_selected(false);
		}
	}, [ selected_sku_map, sku_group ])

	useEffect(() => {
		set_show_collapse(false);
	}, [ sku_group ])


	useEffect(() => {	
		if(!group_checked && c_unc_indicator_group=='unchecked'){
			var x = unchecked_group_array;
			x.push(sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
		if(!group_checked && c_unc_indicator_group=='delete'){
			var x = checked_group_array.filter((x1) => x1!=sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='checked'){
			var x = checked_group_array;
			x.push(sku_group.id)
			set_checked_group_array(x)
			set_display(!display)
		}
		if(group_checked && c_unc_indicator_group=='delete'){
			var x = unchecked_group_array.filter((x1) => x1!=sku_group.id)
			set_unchecked_group_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_group])

	useEffect(() => {
		if(sku_group && sku_group.Tags && sku_group.Tags.length && tag){
			var check = sku_group.Tags.filter(x => x==tag.id)
			if(check && check.length==1){
				set_group_checked(true)
			}
		}else{
			set_group_checked(false)
		}
	}, [sku_group])
	

	return(
		<div>
			<div onClick={open_close_collapse} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', cursor: 'pointer'}}>
				<FormGroup style={{padding:0}} check >
					<Label className='checkbox_style' check style={{display:'flex', alignItems:'center'}}>
						<Input style={{margin:0}} type="checkbox" checked={is_group_selected}  onClick={e => {toggle_group_selection(); e.stopPropagation()}} />
					</Label> 
				</FormGroup>
				<span style={{textTransform:'capitalize',paddingLeft:'24px'}}>{sku_group.name}</span>
			</div>
			<div >
				<Row style={{display:show_collapse?'flex':'none', alignItems:'center', margin:0, width:'100%'}}>
				{
					sku_group && sku_group.sku && sku_group.sku.length && sku_group.sku.map((sku_item) => (
						<Single_sku selected_skus={selected_skus} selected_sku_map={selected_sku_map} set_selected_skus={set_selected_skus} tag={tag} stop_initial_group={stop_initial_group} c_unc_indicator_group={c_unc_indicator_group} checked_group_array={checked_group_array} group_checked={group_checked} check_group_ticked={check_group_ticked} user={user} sku_item={sku_item} set_checked_sku_array={set_checked_sku_array} checked_sku_array={checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/>
					))
				}
				</Row>
			</div>
		</div>
	)
}

const Single_sku  = ({ selected_skus, selected_sku_map, set_selected_skus, check_group_ticked, tag, stop_initial_group, c_unc_indicator_group, checked_group_array, group_checked, user, sku_item , checked_sku_array, set_checked_sku_array, unchecked_sku_array, set_unchecked_sku_array}) => {	

	const [ is_sku_selected, set_is_sku_selected ] = useState(false);

	const [checked_map_sku, set_checked_map_sku] =	useState('');
	const [c_unc_indicator_sku, set_c_unc_indicator_sku] =	useState('');
	const [stop_initial, set_stop_initial] = useState(false);

	const [display, set_display] = useState('');

	const toggle_sku_selection = () => {
		console.log('In --->>>>> ', selected_sku_map, sku_item, selected_skus.filter(x => x != sku_item.id));
		set_selected_skus(selected_sku_map[sku_item.id] ? selected_skus.filter(x => x != sku_item.id) : [ ...selected_skus, sku_item.id ]);
	}

	const onclick_sku_checkbox = () => {
		set_checked_map_sku(!checked_map_sku)	
		set_stop_initial(true)
		check_group_ticked()
	}
	
	useEffect(() => {
		if(stop_initial_group){
			if(group_checked){
				set_checked_map_sku(true)
			}else{
				set_checked_map_sku(false)
			}
		}
	}, [group_checked])

	useEffect(() => {
			console.log('checked_sku_array ====>',checked_sku_array)
			console.log('unchecked_sku_array ====>', unchecked_sku_array)
	}, [display])


	useEffect(() => {
		if(sku_item && sku_item.id && stop_initial ){
			if(!checked_map_sku){

				var check = checked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'unchecked')
				set_stop_initial(false)
			}
			if(checked_map_sku){

				var check = unchecked_sku_array.filter((x) => x==sku_item.id);
				set_c_unc_indicator_sku(check && check.length==1?'delete':'checked')
				set_stop_initial(false)
			}
		}
	},[checked_map_sku])

	useEffect(() => {
		if(!checked_map_sku && c_unc_indicator_sku=='unchecked'){
			var x = unchecked_sku_array;
			x.push(sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
		if(!checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = checked_sku_array.filter((x1) => x1!=sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='checked'){
			var x = checked_sku_array;
			x.push(sku_item.id)
			set_checked_sku_array(x)
			set_display(!display)
		}
		if(checked_map_sku && c_unc_indicator_sku=='delete'){
			var x = unchecked_sku_array.filter((x1) => x1!=sku_item.id)
			set_unchecked_sku_array(x)
			set_display(!display)
		}
	},[c_unc_indicator_sku])

	useEffect(() => {
		if(sku_item && sku_item.Tags && sku_item.Tags.length && tag){
			var check = sku_item.Tags.filter(x => x.id==tag.id)
			if(check && check.length==1){
				set_checked_map_sku(true)
			}
		}else{
			set_checked_map_sku(false)
		}
	},[sku_item])


	useEffect(() => {
		if (selected_sku_map[sku_item.id]) {
			set_is_sku_selected(true);
		} else {
			set_is_sku_selected(false);
		}
	}, [ selected_sku_map, sku_item ])


	return(
		<Col xs={12} sm={6} md={4} lg={3} xl={2} style={{padding:'10px', height:'100%'}}>
			<div style={{width:'100%', height:'130px', border: '1px solid #D5DAE0', borderRadius: '4px'}}>
				<div style={{padding:'10px', height:'28px', fontSize:'12px', backgroundColor: '#F8F9FA', borderRadius: '4px 4px 0 0', display:'flex', alignItems:'center' }}>
					<FormGroup style={{padding:0}} check >
						<Label className='checkbox_style' check style={{display:'flex', alignItems:'center', cursor: 'pointer'}}>
							<Input checked={is_sku_selected} onClick={toggle_sku_selection} style={{margin:0}} type="checkbox" />
						</Label>
					</FormGroup>
					<span style={{textTransform:'capitalize',paddingLeft:'24px', ...styles.text_elipsis}}>{sku_item.name ? sku_item.name : ''}</span>
				</div>
				<div style={{width:'100%',border: '1px solid #F2F4F6'}}></div>
				<div style={{height:'calc(100% - 30px)', backgroundColor: 'rgba(255,255,255,0.10)',display:'flex', alignItems:'center', justifyContent:'center'}}><img style={{maxHeight:'100%', maxWidth:'100%', borderRadius: '0 0 4px 4px'}} src={sku_item.display_pic && sku_item.display_pic.image ? build_path(global.config.static_path, sku_item.display_pic.image.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) : global.config.image_not_found}/></div>
			</div>
		</Col>
	)
}

const AFW_modal = ({ sku_sub_category_name, sku_category_id, user, open, handleclose, sku_sub_category_id, sku_category_name, sku_division_id,set_page_loader, fetch_sku_groups_details}) => {
	
	//State variables

	const [ sku_categories, set_sku_categories ] = useState([]);
	const [ active_sku_category_id, set_sku_category_id ] = useState(null);
	const [ active_sku_category_name, set_sku_category_name ] = useState(null);

	const [ sku_group_map, set_sku_group_map ] = useState([]);

	const alert = useAlert()

	const [ selected_skus, set_selected_skus ] = useState([]);
	const [ selected_sku_map, set_selected_sku_map ] = useState({});

	const [ all_afw_inventory, set_all_afw_inventory ] = useState([]);
	const [ afw_categories, set_afw_categories ] = useState('');
	const [ afw_sub_categories, set_afw_sub_categories ] = useState('');
	const [ afw_groups, set_afw_groups ] = useState('');
	const [ afw_skus, set_afw_skus ] = useState('');

	const [ afw_active_division_id, set_afw_active_division_id ] = useState('');
	const [ afw_active_category_id, set_afw_active_category_id ] = useState('');
	const [ afw_active_sub_category_id, set_afw_active_sub_category_id ] = useState('');
	const [ afw_active_group_id, set_afw_active_group_id ] = useState('');
	
	const [ afw_active_category_name, set_afw_active_category_name ] = useState('');
	const [ afw_active_sub_category_name, set_afw_active_sub_category_name ] = useState('');
	const [ afw_active_group_name, set_afw_active_group_name ] = useState('');

	const [category_dropdown_open, set_category_dropdown_open] = useState(false)

	const [	checked_sku_array, set_checked_sku_array] =	useState([]);
	const [	unchecked_sku_array, set_unchecked_sku_array] =	useState([]);
	const [	checked_group_array, set_checked_group_array] =	useState([]);
	const [	unchecked_group_array, set_unchecked_group_array] =	useState([]);

	const [division_json, set_division_json] = useState([])

	// const [ checked_list, set_checked_list ] = useState([]);

	// const[open_alert_box , set_open_alert_box] = useState(false);
	// const[current_state_alert , set_current_state_alert] = useState(null);
	

	//Member functions 

	// const fetch_sku_category_tree = async () => {
	// 	if(afw_active_division_id){
	// 		try {
	// 			console.log('Fetching categories tree!!')
	// 			var attributes = JSON.stringify({});
	// 			var identifiers = JSON.stringify({ store_id: user.store_id , sku_division_id: afw_active_division_id });
	// 			var categories_response = await general_fetch({ url: 'sales_channel/get_eligible_division_tree' , body: { identifiers , attributes } });
	// 			console.log('cat resp ====>>>>> ', categories_response);
	// 			set_afw_categories(categories_response);
	// 		} catch(err) {
	// 			console.log(err);
	// 		}
	// 	} else {
	// 		console.log('could not fetch categories +++++>>>> no sku_division selected');
	// 	}
	// }

	// const fetch_afw_sub_category_tree = async() => {
	// 	if(afw_active_category_id){
	// 		try {
	// 			console.log('Fetching sub categories tree!!')
	// 			set_afw_sub_categories('');
	// 			var attributes = JSON.stringify({});
	// 			var identifiers = JSON.stringify({ store_id: user.store_id, sku_category_id: afw_active_category_id });
	// 			var sub_categories_response = await general_fetch({ url: 'sales_channel/get_eligible_sub_category_tree' , body: { identifiers , attributes } });
	// 			set_afw_sub_categories(sub_categories_response);
	// 			set_sku_group_map(sub_categories_response.reduce((final, sub_cat) => ({ ...final, ...sub_cat.sku_group.reduce((f, sku_grp) => ({ ...f, [ sku_grp.id ]: sku_grp.sku }), {}) }), {}));
	// 			console.log('sub cat resp ---> ', sub_categories_response);
	// 		}catch(err) {
	// 			console.log(err);
	// 		}
	// 	}
	// }

	const fetch_all_afw_inventory = async (type) => {
		try {
			if(type==='fetch'){
				set_page_loader({
					show:true,
					text:'Fetching inventory might take few seconds...'
				})
			}else{
				set_page_loader({
					show:true,
					text:'Updating inventory might take few seconds...'
				})
			}
			console.log('Fetching all afw inventory!!')
			var categories_response = await general_fetch({ url: 'inventory/get_all_from_warehouse' });
			set_page_loader({
				show:false,
				text:''
			})
			console.log('cat resp ====>>>>> ', categories_response);
			set_all_afw_inventory(categories_response);
		} catch(err) {
			set_page_loader({
				show:true,
				text:'Please contact admin. Filed to fetch'
			})
			console.log(err);
		}
	}


	//Effects

	useEffect(() => {
		if(open){
			fetch_all_afw_inventory('fetch')
		}
	},[open])

	useEffect(() => {
		if(all_afw_inventory && all_afw_inventory.length) {
			set_division_json(all_afw_inventory)
		}else{
			if(open){
				
			}
		}
	},[ all_afw_inventory ])

	useEffect(() => {
		if(division_json && division_json.length) {
			var active_division_id_details = division_json.find( x => x.id ===sku_division_id) 
			set_afw_active_division_id(active_division_id_details?active_division_id_details.id:'')
		}
	},[ division_json ])

	
	useEffect(() => {
		if(division_json && division_json.length && afw_active_division_id){
			let active_awf_categories = division_json.find( x => x.id ===afw_active_division_id) 
			active_awf_categories = active_awf_categories && active_awf_categories.sku_category && active_awf_categories.sku_category.length ? active_awf_categories.sku_category.sort(name_sort):[]
			active_awf_categories = active_awf_categories && active_awf_categories.length ? active_awf_categories.filter(x => x.deprecated != 1):[]
			set_afw_categories(active_awf_categories)
		}
	},[afw_active_division_id])
	
	useEffect(() => {
		if(afw_categories && afw_categories.length){	
			if(sku_division_id===afw_active_division_id && afw_categories.find(x=> x.id===sku_category_id)){
				set_afw_active_category_id(sku_category_id ? sku_category_id :"")
			}
			else{
				set_afw_active_category_id(afw_categories[0].id)
			}
		}
	},[afw_categories])

	useEffect(() => {
		if(afw_categories && afw_categories.length && afw_active_category_id){
			let afw_active_category_details = afw_categories.find(x=> x.id===afw_active_category_id)
			set_afw_active_category_name(afw_active_category_details?afw_active_category_details.name:'')
			let afw_active_sub_categories = afw_active_category_details && afw_active_category_details.sku_sub_category ? afw_active_category_details.sku_sub_category:[]
			afw_active_sub_categories = afw_active_sub_categories && afw_active_sub_categories.length ? afw_active_sub_categories.filter(x => x.deprecated != 1):[]
			set_afw_sub_categories(afw_active_sub_categories)
		}
	},[afw_active_category_id])
	
	useEffect(() => {
		if(afw_sub_categories && afw_sub_categories.length){
			if(afw_sub_categories.find(x=> x.id===sku_sub_category_id)){
				set_afw_active_sub_category_id(sku_sub_category_id ? sku_sub_category_id :"")
			}
			else{
				set_afw_active_sub_category_id(afw_sub_categories[0].id)
			}
			set_sku_group_map(afw_sub_categories.reduce((final, sub_cat) => ({ ...final, ...sub_cat.sku_group.reduce((f, sku_grp) => ({ ...f, [ sku_grp.id ]: sku_grp.sku }), {}) }), {}));
		}
	},[afw_sub_categories])	

	useEffect(() => {
		if(sku_group_map){
			console.log('sub cat resp ---> ', sku_group_map);
		}
	},[sku_group_map])

	useEffect(() => {
		if(afw_active_sub_category_id && afw_sub_categories && afw_sub_categories.length){	
			let afw_active_sub_category_details = afw_sub_categories.find(x=> x.id===afw_active_sub_category_id)
			set_afw_active_sub_category_name(afw_active_sub_category_details?afw_active_sub_category_details.name:'')
			let afw_active_groups = afw_active_sub_category_details && afw_active_sub_category_details.sku_group ? afw_active_sub_category_details.sku_group:[]
			afw_active_groups = afw_active_groups && afw_active_groups.length ? afw_active_groups.filter(x => x.depricated != 1):[]
			set_afw_groups(afw_active_groups)
		}else{
			set_afw_groups('');
		}
	},[afw_active_sub_category_id])

	useEffect(() => {
		if(afw_groups && afw_groups.length){
			set_afw_active_group_id(afw_groups[0].id)
		}
	},[afw_groups])

	useEffect(() => {
		if(afw_active_group_id && afw_groups && afw_groups.length){
			var afw_active_group_details = afw_groups.find(x=> x.id===afw_active_group_id)
			set_afw_skus(afw_active_group_details && afw_active_group_details.sku ? afw_active_group_details.sku[0]:[])
		}
	},[afw_active_group_id])

	// useEffect(() => {
	// 	if(afw_active_group_id && afw_active_group_details && afw_groups && afw_groups.length){
	// 		var response3= afw_groups && afw_groups.length && afw_groups.filter((x) => x.id==afw_active_group_id)
	// 		var skus_response= response3&&response3.length && response3[0].sku
	// 		set_afw_skus(skus_response)
	// 	}
	// },[afw_active_group_details, afw_active_group_id])
	
	const onclick_apply_changes = async () =>{
		if(selected_skus && selected_skus.length){
			var attributes = JSON.stringify({});
			var identifiers = JSON.stringify({ id: selected_skus });
			var response = await general_fetch({ url: 'sku/add_to_store' , body: { identifiers , attributes } });
			console.log('response_AFW ==========>', response);
			alert.success('All items are successfully added to inventory.')
			on_click_handleclose()
			// fetch_all_afw_inventory('add')
			fetch_sku_groups_details()
			set_selected_skus([]);
		} else {
			console.log('No inventory added');
			alert.success('Error! Items not added to inventory.')
			on_click_handleclose()
		}
	}

	const on_click_handleclose = () => {
		handleclose()
		set_afw_categories('')		
		set_afw_sub_categories('')
		set_afw_groups('')
		set_afw_skus('')
		set_afw_active_division_id('')
		set_afw_active_category_id('')
		set_afw_active_sub_category_id('')
		set_afw_active_group_id('')
		set_afw_active_category_name('')
		set_afw_active_sub_category_name('')
		set_afw_active_group_name('')

		set_checked_sku_array([])
		set_checked_group_array([])
		set_unchecked_sku_array([])
		set_unchecked_group_array([])

	}

	const onclick_afw_sub_category = (sub_category) => {
		set_afw_active_sub_category_name(sub_category.name ? sub_category.name:'')
		set_afw_active_sub_category_id(sub_category.id ? sub_category.id :'')
	}

	const onclick_afw_division = (division) => {
		set_afw_active_division_id(division.id);
	}

	const onclick_afw_category = (category) => {
		set_afw_active_category_id(category.id);
		set_afw_active_category_name(category.name);	
	}  

	useEffect(() => {
		// console.log(selected_skus)
		set_selected_sku_map(selected_skus.reduce((final, elem) => ({ ...final, [elem]: true }), {}));
	}, [ selected_skus ])

	return(
		<div>
			<Modal className="Margin_64px_top" size="xl" isOpen={open} toggle={on_click_handleclose}>
			<div style={{width:'100%', height:'600px'}}>	
				<div className="modal-header" style={{paddingLeft:'20px', paddingRight:'20px', height:'56px', width:'100%', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
					<div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						<span style={{fontSize:'18px', color:'#234361', fontFamily: 'Source_Sans_Pro-SemiBold'}}>Add from Warehouse</span>
						<i onClick={on_click_handleclose} style={{color:"#66788A", cursor: 'pointer'}} className='fa fa-times'/>
					</div>		
				</div>

				<div className="modal-body" style={{height:'calc(100% - 56px)', width:'100%', padding:0}}>
					
					<div style={{paddingTop:'10px', width:'240px', height:'calc(100% - 74px)', backgroundColor: 'rgba(255,255,255,0.00)', boxShadow: 'inset -1px 0 0 0 #F2F4F6'}}>
						{
							division_json && division_json.length ? division_json.map((division, idx) => (
								<div key={idx} onClick={() => onclick_afw_division(division)} className={division.id==afw_active_division_id ? 'division_active' : 'division_hover'} style={{...styles.categories_div,textTransform:'capitalize'}}>{division.id}</div>
							)):''
						}
					</div>
					<div style={{position:'absolute', bottom:'0px', left:'0px' ,width:'240px', height:'74px'}} />		
					<div style={{position:'absolute', top:'0px', left:'240px' ,width:'calc(100% - 240px)', height:'100%'}}>		
						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
							<span style={{fontSize:'14px',fontFamily: 'Source_Sans_Pro-SemiBold', color: '#234361', letterSpacing: '-0.05px', lineHeight: '18px'}}>Sub-categories for</span>&nbsp;
							<Dropdown className='x1' isOpen={category_dropdown_open} toggle={() => set_category_dropdown_open(!category_dropdown_open)}>
								<DropdownToggle style={styles.category_dropdown_style} >
									<span style={{fontFamily: 'Source_Sans_Pro-SemiBold', color: '#1070CA' , display:'flex', alignItems:'center'}}>
										<span style={{paddingRight:'5px'}}>{afw_active_category_name ? afw_active_category_name :''}</span>
										<i className='fa fa-caret-down'/>
									</span>
								</DropdownToggle>
								<DropdownMenu style={{maxHeight:'350px', overflow:'auto'}} className='sku_dropdown_list'>
								{
									afw_categories && afw_categories.length ? afw_categories.map((category) => (
										<DropdownItem className={category.id==afw_active_category_id ? 'sub_category_active sku_dropdown_list_item':'sku_dropdown_list_item'} onClick={() => onclick_afw_category(category)}><span style={{textTransform:'capitalize'}}>{category.name}</span></DropdownItem>
									)):''
								}
								</DropdownMenu>
							</Dropdown>
						</div>
						<div style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', width:'100%', overflow:'auto'}}>
							{
								afw_sub_categories && afw_sub_categories.length ? afw_sub_categories.map(sub_category => (
									<span onClick={() => onclick_afw_sub_category(sub_category)} className={sub_category.id==afw_active_sub_category_id ? 'sub_category_active' : ''} style={{height:'28px', fontSize:'12px', padding:'0px 20px', borderRadius:'5px', display:'flex', cursor: 'pointer', alignItems:'center', color:'#435A6F'}}>{sub_category.name}</span>
								)):''
							}
						</div>
						<div style={{height:'40px', padding:'0px 20px', display:'flex', alignItems:'center'}}>
							<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#234361'}}>Groups for <span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#234361'}}>{afw_active_sub_category_name?afw_active_sub_category_name:''}</span></span>
						</div>
						<div style={{height:'calc(100% - 180px)', overflow:'auto'}}>
							{
								(afw_groups && afw_groups.length) ? afw_groups.map((sku_group) => (
									<LazyLoad overflow={true}><Group_collapse selected_sku_map={selected_sku_map} selected_skus={selected_skus} set_selected_skus={set_selected_skus} sku_group_map={sku_group_map} unchecked_group_array={unchecked_group_array} set_unchecked_group_array={set_unchecked_group_array} checked_group_array={checked_group_array} set_checked_group_array={set_checked_group_array} sku_group={sku_group} user={user} checked_sku_array={checked_sku_array} set_checked_sku_array={set_checked_sku_array} unchecked_sku_array={unchecked_sku_array} set_unchecked_sku_array={set_unchecked_sku_array}/></LazyLoad>
								)) : (
									''
									// <div>No Groups</div>
									// <Filler initialized={afw_sub_categories && afw_sub_categories.length ? true : false} />
								) 

							}
						</div>
						<div style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
							<Button disabled={!selected_skus.length} onClick={onclick_apply_changes} style={{height:'30px'}} className='blue_button' >Apply</Button>
							{/* onClick={onclick_apply_changes} */}
						</div>
					</div>
					
				</div>
			</div>
			</Modal>
		</div>					
	)
}

export default AFW_modal;