import React, { useState , useEffect } from 'react';
import { Redirect, NavLink as NavLinkRRD, Link , useHistory} from "react-router-dom";
import {CardHeader, CardBody, CardFooter,Input, Button, Alert, Modal, ModalBody, ModalFooter, ModalHeader, Dropdown, Card, Col, DropdownMenu, DropdownItem, DropdownToggle, Table, Row, DropdownContext } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import AddNewSKU from './AddNewSKU'
import EditSkuPropertiesTab from "./EditSkuPropertiesTab.js"
import Main_Grid from '../../../components/Main_Grid';
import { useAlert } from 'react-alert'
import EditGroupProperties from './EditGroupProperties.js'
import AddNewGroup from './AddNewGroup';
import AFW_modal from './AFW';
//import AlertBox from "../../../components/AlertBox/";
import ExtendedDropdown from "../../../components/ExtendedDropdown/";
import Category_edit_confirm_modal from "../../../components/Category_edit_confirm_modal/";
import AddSubCategory from './AddSubCategory';
import './sku_groups.css'
import LazyLoad from 'react-lazyload';
import { SentryError } from '@sentry/utils';
import * as Sentry from "@sentry/react";
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import BulkEditModal from '../BulkEditModal/index.js';


const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	text_elipsis : {
			whiteSpace: "nowrap", 
			overflow: "hidden",
			textOverflow: "ellipsis",
			padding :5
		},
};

const Filler = ({ initialized_grps, initialized1, fetching, div_name, sub_cat_id, sku_sub_categories }) => {

	const [no_of_cards, set_no_of_cards] = useState([])

	useEffect(()=>{
		if(div_name){
			var div_height = document.getElementById(div_name)?document.getElementById(div_name).offsetHeight:'';
			var no_of_cards = Math.round(((div_height/200)+1)*4);
			var loader_array=[]
			for(var i=0; i<no_of_cards;i++){
				loader_array.push(i)
			}
			set_no_of_cards(loader_array)
		} 
	},[div_name])

	return(
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
			<tr>
				{ 
				(initialized_grps[sub_cat_id] && initialized1 && fetching) || ((initialized1) && !(sku_sub_categories && sku_sub_categories.length)) ? 
				(
					<th scope="col">
					<span style={styles.table_header}><b>No Inventory to list</b></span><br/> Try adjusting filters or consider adding from warehouse or create new.<br /> &nbsp;
					</th>
				):(
					<Row style={{margin:'10px'}}>
						{   
							no_of_cards && no_of_cards.length ? no_of_cards.map((sku_group, idx) => (
								<Col key={idx} xs={12} sm={6} md={4} lg={3} xl={3} style={{padding:'10px' }}>
									<GroupInventoryCard_loader/>
								</Col>
							)
							):(
								<React.Fragment>
									<Row>
										<Col xs={12} className="text-center">
											<img src="/load2.gif" />
										</Col>
										<Col xs={12} className="text-center">
											<span style={styles.table_header}><b>Fetching your inventory  </b></span><br/> <br /> Please wait...<br /> <br />
										</Col>
									</Row>
								</React.Fragment>
							)
						}
					</Row>
				)
				}
			</tr>
			</thead>
  		</Table>  
	)
};

const Filler_sub_cat = ({ initialized_grps, initialized1, div_name, display_groups, sub_cat_id, sku_sub_categories }) => {

	const [no_of_cards_for_subcat, set_no_of_cards_for_subcat] = useState([])

	useEffect(()=>{
		if(div_name){
			var div_height = document.getElementById(div_name)?document.getElementById(div_name).offsetWidth:'';
			var no_of_cards = Math.round((div_height/115)-1);
			var loader_array=[]
			for(var i=0; i<no_of_cards;i++){
				loader_array.push(i)
			}
			set_no_of_cards_for_subcat(loader_array)
		} 
	},[div_name])

	return(
		<div style={{height:"100%", width:'100%'}}>
			{ 
				initialized1 && !(sku_sub_categories && sku_sub_categories.length) ? 
				(
					<div style={{height:'100%', display:'flex', alignItems:'center'}}>
						<span>No Sub-Categories</span>
					</div>
				):(
					<Row style={{margin:'0px 20px', height:'50px', display:'flex', alignItems:'center'}}>
						{   
							no_of_cards_for_subcat && no_of_cards_for_subcat.length ? no_of_cards_for_subcat.map((sku_group, idx) => (
								<div key={idx} className='card_loader' style={{height:'28px', width:'100px', marginRight:'15px'}}></div>
							)
							):''
						}
					</Row>
				)
			}	
		</div> 
	)
};

const GroupInventoryCard_loader = () => {
	
	return (
		<div className='card_loader' style={{height:'202px', width:'100%', border:'1px solid #eee'}}>
			<div className='flex_property' style={{height:'40px', width:'100%',borderRadius: '4px 4px 0 0'}}>
				
				<div style = {{...styles.text_elipsis, flex:5, textAlign:'left', fontSize:'14px', paddingLeft:'14px'}} ></div>
				{/* <div style = {{flex:1, paddingRight:'15px', textAlign:'right'}}>
					<div style={{height:'30px',width:"30px", backgroundColor:'#cccccc', border:'1px solid #E9ECEF' }}></div>
				</div>
				<div style = {{flex:1, paddingRight:'10px'}}>
					<div style={{height:'30px',width:"30px", backgroundColor:'#cccccc' , border:'1px solid #E9ECEF'}}></div>
				</div> */}
					
			</div>
			
			<div className='flex_property' style={{height:'120px', width:'100%', backgroundColor:'white', borderRadius: '4px', justifyContent:'center'}}>

			</div>
			<div style={{border: '1px solid #F2F4F6'}}></div>
			<div className='flex_property' style={{height:'40px', width:'100%', padding:'0px 10px'}}>
				
				<div style = {{flex:6, marginRight:'15px'}}>
					<Input style={{height:'30px', maxWidth:'100%', backgroundColor:'#cccccc'}} disabled={true} type='text'></Input>
				</div>
				<div style = {{flex:1, textAlign:'right'}}>
					<div style={{height:'30px',width:'30px', backgroundColor:'#cccccc' , border:'1px solid #E9ECEF'}}></div>
				</div>
				
			</div>
		</div>
	);
	
};


// const Checkbox_input = ({item, on_click_move_group}) => {

// 	const [checkbox_value, set_checkbox_value] = useState(false)

// 	useEffect(() => {
// 		if(item){
// 			set_checkbox_value(item.includes?true:false)
// 		}
// 	},[item])
	
// 	useEffect(() => {
// 		console.log('checkbox_value',checkbox_value)
// 	},[checkbox_value])

// 	const set_includes = () => {
// 		console.log("set_includes", !checkbox_value)
// 		set_checkbox_value(!checkbox_value)
// 	}

// 	return(
// 		<div className='sub_categories_div_for_move' onClick={() => on_click_move_group(item.id)}>
// 			<Input style={{ position:'unset', margin:0, marginRight:'10px'}} type='radio' checked={checkbox_value?true:''}  onChange={set_includes} />
// 			<div>{item.name?item.name:''}</div>
// 		</div>
// 	)
// }

const NameCloneModal = ({open, handleClose, mode, toBe_cloned_sku, cloned_into_group, cloned_from_group, cloned_into_sub_cat, clone_to_same_group, clone_to_new_group, clone_to_existing_group, new_sku_name, set_new_sku_name, new_grp_name, set_new_grp_name, clone_prices, set_clone_prices}) => {

	const alert = useAlert()

	const onClick_submit = () => {

		if(new_sku_name && new_sku_name.length > 200){
			alert.error("SKU name shouldn't exceed 200 characters")
			return
		}

		if(new_grp_name && new_grp_name.length > 200){
			alert.error("Group name shouldn't exceed 200 characters")
			return
		}

		if(mode == 'clone_to_same_grp'){
			clone_to_same_group(toBe_cloned_sku, cloned_into_group)
		}else if(mode == 'clone_to_new_grp'){
			clone_to_new_group(toBe_cloned_sku, cloned_into_sub_cat)
		}else if(mode == 'clone_to_existing_grp'){
			clone_to_existing_group(cloned_into_group, cloned_into_sub_cat)
		}
		handleClose()
	}

	useEffect(() => {
		if(open){
			if(toBe_cloned_sku) set_new_sku_name('Copy of '+toBe_cloned_sku.name)
			if(cloned_from_group) set_new_grp_name('Copy of '+cloned_from_group)
		}
	}, [toBe_cloned_sku, open]);

	return(
		<Modal size='sm' style={{marginTop:'200px'}} isOpen={open} >
			<CardHeader className='global_modal_header'>
				{mode=='clone_to_new_grp' ? 'Name the Clone SKU and the New Group' : 'Name the Clone SKU'}
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handleClose} />
			</CardHeader>
			<ModalBody style={{height:mode=='clone_to_new_grp'?'250px':'150px', backgroundColor:'#F5F6F8', padding:'20px 60px'}}>
				<span style={{}}>SKU Name</span>
				<Input value={new_sku_name} placeholder={'New SKU Name'} onChange={(e) => set_new_sku_name(e.target.value)} style={{marginTop:'5px'}}/>
				{
					mode == 'clone_to_new_grp'?
					<div style={{marginTop:'20px'}}>
						<span >Group Name</span>
						<Input value={new_grp_name} placeholder={'New Group Name'} onChange={(e) => set_new_grp_name(e.target.value)} style={{marginTop:'5px'}}/>
					</div>:''
				}
				<div className='flex_property' style={{fontSize:'14px', marginTop:'10px'}}>
					<input style={{cursor:'pointer'}} id='clone_prices_checkbox' type='checkbox' checked = {clone_prices} onClick={() => set_clone_prices(!clone_prices)}/>
					<div style={{marginLeft:'10px'}}>Clone Sales channel mapping and Prices</div>
				</div>
			</ModalBody>
			<CardFooter className='global_modal_footer'>
				<Button className='blue_button' disabled={(mode=='clone_to_new_grp'?((new_grp_name && new_sku_name)?false:true):(new_sku_name?false:true))} onClick={onClick_submit} >Submit</Button>
			</CardFooter>
		</Modal>
	)
}

const SelectSubCategoryModal = ({open, handleClose, set_show_name_clone_modal, toBe_cloned_sku, sub_cat_id, sku_sub_categories, set_cloned_into_sub_cat}) => {
	
	const [active_sub_cat_id, set_active_sub_cat_id] = useState('')

	const onClick_submit = () => {
		set_show_name_clone_modal(true)
	}

	const onclick_radio_button = (item) => {
		set_active_sub_cat_id(item.id)
		set_cloned_into_sub_cat(item.id)
	}

	useEffect(() => {
		if(sub_cat_id) set_active_sub_cat_id(sub_cat_id)
	}, [sub_cat_id]);
	
	return(
		<Modal className='Margin_64px_top' size='md' isOpen={open} toggle={handleClose}>
			<CardHeader className='global_modal_header'>
				<span>Select the Sub-Category to which you want the SKU to be cloned</span>
				<i style={{cursor:'pointer'}} className='fa fa-times' onClick={handleClose} />
			</CardHeader>
			<CardBody style={{backgroundColor:'#F5F6F8'}}>
			{
				sku_sub_categories && sku_sub_categories.length ? sku_sub_categories.map ((single_sub_category,idx) => (
					<div key={idx} style={{display:'flex', marginBottom:'10px'}}>
						<Input checked={active_sub_cat_id===single_sub_category.id?true:false} onClick={()=>onclick_radio_button(single_sub_category)} key={idx} style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
						<div>{single_sub_category.name?single_sub_category.name:''}</div>
						{
							sub_cat_id == single_sub_category.id ?
							<div style={{opacity:'0.5'}}>{'\xa0\xa0\xa0\xa0'}(Current Sub-Category)</div>:''
						}
					</div>
				)):<div style={{padding: '10px', alignItems:'center'}}>No Sub Categories to list.</div>
			}
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='blue_button' onClick={onClick_submit}>Submit</Button>
			</CardFooter>
		</Modal>
	)
}

const Move_group = ({ open, handleClose, on_click_move_group, active_inventory_item, active_inventory_item_details, sku_categories, fetch_categories, sku_sub_categories }) => {

	const [active_sub_categories, set_active_sub_categories] = useState([])
	const [active_category, set_active_category] = useState('')
	const [search_string, set_search_string] = useState('')
	const [display_active_sub_categories, set_display_active_sub_categories] = useState([])
	const [active_radio_value, set_active_radio_value] = useState(false)

	const fetch_active_sku_sub_categories = async () => {
		try {
			// var identifiers = JSON.stringify({sku_category_id:active_category.id});
			// var attributes = JSON.stringify({});
			var response = await general_fetch({ url: 'inventory/get_sub_categories' , body: { sku_category_id:active_category.id } });
			// console.log('output frrrr --->> ', response2);
			// var response = await general_fetch({ url: 'sku_sub_category/get_active_sku_new' , body: { identifiers , attributes } });
			debugger
			var depricated_filter_output =response && response.length ?response.filter(x => x.deprecated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(name_sort):[]
			set_active_sub_categories(resp && resp.length?resp:[]);
			set_display_active_sub_categories(resp && resp.length?resp:[]);
		} catch(err) {
			// alert.error('Sub-Categories fetching failed.')
			console.log(err);
		}
	}

	useEffect(()=>{
		fetch_categories();
		if(sku_categories && sku_categories.length && open){
			set_active_category(sku_categories[0])
		}
	},[open])

	useEffect(()=>{
		if(active_category && active_category.id){
			console.log('active_category',active_category)
			fetch_active_sku_sub_categories()
		}
	},[active_category])

	useEffect(()=>{
		var filtered_json = [...active_sub_categories]
		if(search_string){
			var lower_search_string = search_string.toLowerCase();
			if(lower_search_string){
				filtered_json = filtered_json.filter( item => item.name.toLowerCase().includes(lower_search_string))
			}
		}
		set_display_active_sub_categories(filtered_json)
	},[search_string])

	const onClick_category = (active_category) => {
		set_active_category(active_category)
	}
	
	const onclick_handleClose = () => {
		handleClose()
		set_active_sub_categories([])
		set_display_active_sub_categories([])
		set_active_category({})
		set_search_string('')
		set_active_radio_value('')
	}

	const onclick_radio_button = (item) => {
		set_active_radio_value(item.id)
	}

	return(
		<Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={handleClose}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<div style={{textTransform:'capitalize'}}>Move Group : {active_inventory_item_details?active_inventory_item_details.name:''}</div>
					<i style={{cursor:'pointer'}} onClick={onclick_handleClose} className='fa fa-times'/>	
				</CardHeader>
				<CardBody className='global_modal_body'>
					<Row style={{margin:0, height:'100%'}}>
						<div style={{width:'160px', height:'100%', overflowY:'auto'}}>
							{
								sku_categories && sku_categories.length ? sku_categories.map((category, idx) => (
									<div key={idx} onClick={()=>onClick_category(category)} className={category.id===active_category.id?'active_categories_div_for_move categories_div_for_move':'categories_div_for_move'}>{category.name}</div>
								))
								:''
							}
						</div>
						<div style={{width:'calc(100% - 160px)', backgroundColor:'#F5F6F8', height:'100%', overflowY:'auto'}}>
							<div style={{height:'70px', display:'flex', alignItems:"center", padding:"0px 25px"}}>
								<Input placeholder='Search Text' value={search_string} onChange={(e) => set_search_string(e.target.value)} type='text' style={{fontSize:"14px"}}/>
							</div>
							<div style={{height:'calc(100% - 70px)', overflowY:'auto', padding:'0px 25px'}}>
							{
								display_active_sub_categories && display_active_sub_categories.length ? display_active_sub_categories.map((sub_category, idx) => (	
									<div key={idx} className='sub_categories_div_for_move'>
										<Input value={active_radio_value} onClick={()=>onclick_radio_button(sub_category)} key={idx} name='radio_for_move_group' id='radio_for_move_group' style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
										<div>{sub_category.name?sub_category.name:''}</div>
									</div>
									// <Checkbox_input key={idx} item={sub_category} on_click_move_group={on_click_move_group}/>
								)):''
							}
							</div>
						</div>
					</Row>
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button className='white_button' style={{marginRight:'10px', fontSize:'14px'}} onClick={onclick_handleClose}>Cancel</Button>
					<Button disabled={active_radio_value?false:true} onClick={active_radio_value?() => on_click_move_group(active_radio_value, active_category):null} className='blue_button' style={{fontSize:'14px'}}>Move</Button>
				</CardFooter>
			</Card>
		</Modal>					
	)
}

const Move_clone_sku = ({ open, handleClose, on_click_fn, active_sku_details, sku_sub_categories, action_type, sku_category_id, active_group_id, active_sub_category_id, set_show_name_clone_modal, set_cloned_into_group, set_cloned_into_sub_cat}) => {

	const [search_string, set_search_string] = useState('')
	const [display_groups, set_display_groups] = useState([])
	const [groups, set_groups] = useState([])	
	const [active_sub_cat_id, set_active_sub_cat_id] = useState('')
	const [active_sku_group_id, set_active_sku_group_id] = useState('')

	const fetch_sku_groups = async () => {
		try {
			if(active_sub_cat_id){
				var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id:active_sub_cat_id } });
				var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
				var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
				set_groups(resp)
				set_display_groups(resp)
			}else{
				console.log("active_sub_cat_id is null")
			}
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(()=>{
		if(active_sub_category_id && open){
			set_active_sub_cat_id(active_sub_category_id)
		}
	},[open])

	useEffect(()=>{
		if(active_sub_cat_id){
			fetch_sku_groups()
		}
	},[active_sub_cat_id])

	useEffect(()=>{
		if(active_group_id && open && action_type==='clone'){
			set_active_sku_group_id(active_group_id)
		}
	},[active_group_id])

	useEffect(()=>{
		var filtered_json = [...groups]
		if(search_string){
			var lower_search_string = search_string.toLowerCase();
			if(lower_search_string){
				filtered_json = filtered_json.filter( item => item.name.toLowerCase().includes(lower_search_string))
			}
		}
		set_display_groups(filtered_json)
	},[search_string])

	const onClick_sub_category = (sub_cat) => {
		set_active_sub_cat_id(sub_cat.id)
	}
	
	const onclick_handleClose = () => {
		handleClose()
		set_search_string('')
		set_display_groups([])
		set_groups([])
		set_active_sub_cat_id('')
		set_active_sku_group_id('')
	}

	const onclick_radio_button = (item) => {
		set_active_sku_group_id(item.id)
	}

	const on_click_clone = () => {
		set_cloned_into_group(active_sku_group_id);
		set_cloned_into_sub_cat(active_sub_cat_id)
		set_show_name_clone_modal(true)
	}

	return(
		<Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={handleClose}>
			<Card className='global_modal_height_for_mdlgxl'>
				<CardHeader className='global_modal_header'>
					<div style={{textTransform:'capitalize'}}>Clone SKU : {active_sku_details?active_sku_details.name:''}</div>
					<i style={{cursor:'pointer'}} onClick={onclick_handleClose} className='fa fa-times'/>	
				</CardHeader>
				<CardBody className='global_modal_body'>
					<Row style={{margin:0, height:'100%'}}>
						<div style={{width:'160px', height:'100%', overflowY:'auto'}}>
							{
								sku_sub_categories && sku_sub_categories.length ? sku_sub_categories.map((item, idx) => (
									<div key={idx} onClick={()=>onClick_sub_category(item)} className={item.id===active_sub_cat_id?'active_categories_div_for_move categories_div_for_move':'categories_div_for_move'}>{item.name}</div>
								))
								:''
							}
						</div>
						<div style={{width:'calc(100% - 160px)', backgroundColor:'#F5F6F8', height:'100%', overflowY:'auto'}}>
							<div style={{height:'70px', display:'flex', alignItems:"center", padding:"0px 25px"}}>
								<Input placeholder='Search Text' value={search_string} onChange={(e) => set_search_string(e.target.value)} type='text' style={{fontSize:"14px"}}/>
							</div>
							<div style={{height:'calc(100% - 70px)', overflowY:'auto', padding:'0px 25px'}}>
							{
								display_groups && display_groups.length ? display_groups.map((group, idx) => (	
									<div key={idx} className='sub_categories_div_for_move'>
										<Input checked={active_sku_group_id===group.id?true:false} onClick={()=>onclick_radio_button(group)} key={idx} name='radio_for_move_group' id='radio_for_move_group' style={{ position:'unset', margin:0, marginRight:'10px', cursor:'pointer'}} type='radio' />
										<div>{group.name?group.name:''}</div>
									</div>
									// <Checkbox_input key={idx} item={sub_category} on_click_move_group={on_click_move_group}/>
								)):<div style={{padding: '10px', alignItems:'center'}}>No Groups to list.</div>
							}
							</div>
						</div>
					</Row>
				</CardBody>
				<CardFooter className='global_modal_footer'>
					<Button className='white_button' style={{marginRight:'10px', fontSize:'14px'}} onClick={onclick_handleClose}>Cancel</Button>
					<Button disabled={active_sku_group_id?false:true} onClick={active_sku_group_id?() => action_type=='move' ? on_click_fn(active_sku_group_id, active_sub_cat_id) : on_click_clone():null} className='blue_button' style={{fontSize:'14px'}}>{action_type==='clone'?'Clone':'Move'}</Button>
				</CardFooter>
			</Card>
		</Modal>					
	)
}

const Group_Layout = ({ confirmation_alert, fetch_categories, sku_categories, sku_sub_categories, sku_sub_category_id, submit_for_delete_confirm, texture_json, all_sales_channels, set_page_loader, brands, fetch_material_template, material_template, set_material_template, orignal_tags, sku_category_id, sku_category_name,sku_category_type, sku_groups , user , fetch_sku_groups, Filler, inventory_type, content_view, active_division_id, materials_json, border_animation_group, set_border_animation_group, set_border_animation_sub_cat, pre_selected_sku, set_pre_selected_sku, bulk_selection_mode, set_bulk_selection_mode, bulk_selected_skus, set_bulk_selected_skus, bulk_selected_grps, set_bulk_selected_grps, no_of_bulk_selected_skus_in_grp, set_no_of_bulk_selected_skus_in_grp, update_no_of_bulk_selected_skus_in_grp, no_of_bulk_selected_skus_in_subcat, update_no_of_bulk_selected_skus_in_subcat, send_analytics_event}) => {

	const alert = useAlert()
	const [	open_tab_modal , set_open_tab_modal ] = useState(false);
	const [ selected_sku, set_selected_sku ] = useState(null);
	const [ selected_sku_group, set_selected_sku_group ] = useState(null);
	const [ active_inventory_item, set_active_inventory_item ] = useState(null);
	const [ active_group_name, set_active_group_name ] = useState(null);
	const [ active_inventory_item_details, set_active_inventory_item_details ] = useState(null);
	const [ show_edit_sku_properties_modal , set_show_edit_sku_properties_modal ] = useState(false);
	const [ show_sales_channel_sku_modal , set_show_sales_channel_sku_modal ] = useState(false);
	const [ show_move_group_modal , set_show_move_group_modal ] = useState(false);
	const [ show_group_properties_modal , set_show_group_properties_modal ] = useState(false);
	const [selected_sku_properties , set_selected_sku_properties] = useState('')
	const [ show_move_clone_sku_modal , set_show_move_clone_sku_modal ] = useState(false);
	const [ action_type , set_action_type ] = useState('');
	const [ active_item_for_moveclone , set_active_item_for_moveclone ] = useState('');

	const [ show_tag_move_group_confirm_modal , set_show_tag_move_group_confirm_modal ] = useState(false);
	const [ data_for_tags_move_group , set_data_for_tags_move_group ] = useState('');
	const [ choosen_sku_sub_category_id , set_choosen_sku_sub_category_id ] = useState('');
	const [custom_properties, set_custom_properties] = useState([]);
	const [show_name_clone_modal, set_show_name_clone_modal] = useState(false)
	const [cloning_mode, set_cloning_mode] = useState('');
	const [toBe_cloned_sku, set_toBe_cloned_sku] = useState('');
	const [cloned_into_group, set_cloned_into_group] = useState('');
	const [cloned_from_group, set_cloned_from_group] = useState('');
	const [cloned_into_sub_cat, set_cloned_into_sub_cat] = useState('');
	const [new_sku_name, set_new_sku_name] = useState('');
	const [new_grp_name, set_new_grp_name] = useState('');
	const [show_select_sub_cat_modal, set_show_select_sub_cat_modal] = useState();
	const [is_material , set_is_material] = useState(false)
	const [skus_in_subcat, set_skus_in_subcat] = useState([]);
	const [clone_prices, set_clone_prices] = useState(false);
	// const [bulk_selected_skus_in_subcat, set_bulk_selected_skus_in_subcat] = useState([])
	// const [initialized_bulk_selected_skus_in_subcat, set_initialized_bulk_selected_skus_in_subcat] = useState();

	const update_bulk_selected_skus = (sku_id, value) => {
		let a = {...bulk_selected_skus}
		a[sku_id] = value
		set_bulk_selected_skus(a)
	}

	// const remove_from_bulk_selected_skus = (sku_id, value) => {
	// 	let a = {...bulk_selected_skus}
	// 	let index = a.indexOf(sku_id)
	// 	if(index) a.splice(index, 1)
	// 	set_bulk_selected_skus(a)
	// }

	const update_bulk_selected_grps = (grp_id, value) => {
		let a = {...bulk_selected_grps}
		a[grp_id] = value
		console.log('update_bulk_selected_grps', grp_id, value)
		set_bulk_selected_grps(a)
	}

	// const remove_from_bulk_selected_grps = (grp_id, value) => {
	// 	let a = {...bulk_selected_grps}
	// 	let index = a.indexOf(grp_id)
	// 	if(index) a.splice(index, 1)
	// 	set_bulk_selected_grps(a)
	// }

	// const update_bulk_selected_skus_in_subcat = (sku_id) => {
	// 	let a = [...bulk_selected_skus_in_subcat]
	// 	if(!a.find(o => o==sku_id)) a.push(sku_id)
	// 	set_bulk_selected_skus_in_subcat(a)
	// }

	// const remove_from_bulk_selected_skus_in_subcat = (sku_id) => {
	// 	let a = [...bulk_selected_skus_in_subcat]
	// 	let id = a.indexOf(sku_id)
	// 	a.splice(id,1)
	// 	set_bulk_selected_skus_in_subcat(a)
	// }

	// const fetch_additional_properties = async() => {
	// 	try{
	// 		var response = await general_fetch({url : 'additional_property/get'})
	// 		console.log("custom", response)
	// 		set_custom_properties(response)
	// 		console.log("customm", custom_properties)
	// 	} catch(err){
	// 		alert.error("Request Failed")
	// 	}
	// } 

	const remove_sku_details = async (active_sku) => {
		try{
			if (active_sku && active_sku.id) {
				var identifiers = JSON.stringify({ id:active_sku.id });
				var reponse = await general_fetch({url :'sku/remove_from_store' , body: {identifiers} })
				fetch_sku_groups()
			}
		}catch(err){
			console.log(err)
		}
	}

	const clone_to_same_group = async (active_sku, sku_group_id) => {
		try {
			console.log('hh',active_sku)
			if (active_sku && active_sku.id) {
				console.log(active_sku)

				var body = {id:active_sku.id ,sku_group_id:sku_group_id, clone_prices};
				if(new_sku_name) body['sku_new_name'] = new_sku_name
				var response = await general_fetch({url :'sku/clone_to_existing_group' , body: body })
				console.log("clone to same grp", response)
				set_border_animation_group(sku_group_id)
				set_pre_selected_sku(response.sku_id)
				set_new_sku_name('')
				fetch_sku_groups()
			}
		} catch(err) {
			console.log(err)
		}
	}

	const clone_to_new_group = async (active_sku, sub_cat_id) => {
		try{
			if (active_sku && active_sku.id) {
				let body = {id: active_sku.id, sku_sub_category_id: sub_cat_id, clone_prices}
				if(new_sku_name) body['sku_new_name'] = new_sku_name
				if(new_grp_name) body['sku_group_new_name'] = new_grp_name
				var response = await general_fetch({url :'sku/clone_with_group' , body: body })
				console.log("clone to new grp", response)
				set_border_animation_group(response.sku_group.id)
				set_border_animation_sub_cat(sub_cat_id)
				set_pre_selected_sku(response.sku_id)
				set_new_sku_name('')
				set_new_sku_name('')
				fetch_sku_groups()
			}
		}catch(err){
			console.log(err)
		}
	}

	const Remove_from_group = async (id, sku_group) => {
		try{
			var sku_ids= sku_group && sku_group.sku && sku_group.sku.length ? sku_group.sku.map(x => x.id):[]
			var identifiers = JSON.stringify({id:sku_ids})
			var body = {identifiers}
			var reponse = await general_fetch({url :'sku/remove_from_store' , body })
			fetch_sku_groups()
			alert.success('Group Removed Successfully')
		}catch(err){
			console.log(err)
			alert.error('Request Failed')
		}
	}
	const delete_group = async (id) => {
		try{
			var identifiers = JSON.stringify({id:id})
			var body = {identifiers}
			var reponse = await general_fetch({url :'sku_group/remove_from_store' , body })
			fetch_sku_groups()
			alert.success('Group Deleted Successfully')
		}catch(err){
			console.log(err)
			alert.error('Request Failed')
		}
	}

	const onclick_confirm_for_tags_move_group = async (active_sku) => {
		// try{
		// 	if (active_sku && active_sku.id) {
		// 		var identifiers = JSON.stringify({ id:active_sku.id });
		// 		var reponse = await general_fetch({url :'sku/remove_from_store' , body: {identifiers} })
		// 		fetch_sku_groups()
		// 	}
		// }catch(err){
		// 	console.log(err)
		// }
	}

	const get_tags_data_to_be_affected_for_move_group = async (new_sub_cat_id) => {
		try {
			var response =''
			var resp = await general_fetch({ url: 'tag/get_invalid_assignments_for_sku_group', body:{sku_group_id:active_inventory_item , new_sku_sub_category_id:new_sub_cat_id}});
			if(resp){
				response={...resp, sku_category:[],sku_sub_category:[]}
			}
			set_data_for_tags_move_group(response ? response:'')
			return response ? response:''
		} catch(err) {
			console.log(err);
		}
	}

	const on_click_move_group = async (choosen_sku_sub_category_id, choosen_category) => {
		console.log("ACTIVE SKU GRP ------>>>>> ", choosen_sku_sub_category_id, choosen_category)
		if(sku_category_id!=choosen_category.id){
			set_choosen_sku_sub_category_id(choosen_sku_sub_category_id)
			var x_data_for_tags_move_group = await get_tags_data_to_be_affected_for_move_group(choosen_sku_sub_category_id)
			if(x_data_for_tags_move_group && !x_data_for_tags_move_group.sku_category.length && !x_data_for_tags_move_group.sku_sub_category.length && !x_data_for_tags_move_group.sku_group.length && !x_data_for_tags_move_group.sku.length){
                on_click_move_group_fn(choosen_sku_sub_category_id);
            }else{
                set_show_tag_move_group_confirm_modal(true)
            }
		}else{
			on_click_move_group_fn(choosen_sku_sub_category_id);
		}
	}

	const close_tag_move_group_confirm_modal = () => {
		set_show_tag_move_group_confirm_modal(false)
		set_data_for_tags_move_group('')
		set_choosen_sku_sub_category_id('')
	}

	const on_click_move_group_fn = async (sku_sub_category_id) => {
		try{
			if(active_inventory_item){
				var identifiers = JSON.stringify({ id:active_inventory_item });
				var updates = JSON.stringify({ sku_sub_category_id:sku_sub_category_id?sku_sub_category_id:choosen_sku_sub_category_id });
				var reponse = await general_fetch({url :'sku_group/update' , body: {identifiers, updates} })
				fetch_sku_groups();
				edit_move_group_modal_dismiss();
				alert.success('Group moved successfully')
			}
		}catch(err){
			alert.error('Error Api failed')
			console.log(err)
		}
	}

	const on_click_move_sku_to_existing_group = async(id, active_sub_cat_id) => {
		try{
			console.log("ACTIVE SKU & GRP ------>>>>> ", active_item_for_moveclone, active_inventory_item_details, id)
			if(active_item_for_moveclone){
				var identifiers = JSON.stringify({id : active_item_for_moveclone.id})
				var updates = JSON.stringify({ sku_group_id:id  });
				var reponse = await general_fetch({url :'sku/update' , body: {identifiers, updates} })
				set_border_animation_group(id)
				set_border_animation_sub_cat(active_sub_cat_id)
				set_pre_selected_sku(active_item_for_moveclone.id)
				fetch_sku_groups();
				close_move_clone_sku_modal();
			}
		}catch(err){
			console.log(err)
		}
	}

	const on_click_clone_sku_to_existing_group = async (id, active_sub_cat_id) => {
		try{
			console.log("ACTIVE SKU & GRP ------>>>>> ", active_item_for_moveclone, active_inventory_item_details, id)
			if(active_item_for_moveclone){
				var body = {id:active_item_for_moveclone.id ,sku_group_id:id};
				if(new_sku_name) body['sku_new_name'] = new_sku_name
				var response = await general_fetch({url :'sku/clone_to_existing_group' , body: body })
				set_border_animation_group(id)
				set_border_animation_sub_cat(active_sub_cat_id)
				set_pre_selected_sku(response.sku_id)
				set_new_sku_name('')
				fetch_sku_groups();
				close_move_clone_sku_modal();
			}
		}catch(err){
			console.log(err)
		}
	}

	const onclick_edit_sku_structure = (active_sku) => {
		if(global.config.website_url && active_sku){
			send_analytics_event('track', user.id, active_sku.name, active_sku.id, sku_category_type, "sku_structure", "sku_structure_edited")
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				window.open(`https://design.${domain}.${extension}`+'/seller/'+active_sku.id, '_blank')
			} else {
				window.open(global.config.website_url+'seller/'+active_sku.id, '_blank')
			}
		}else{
			alert.error('Request Failed')
		}
	}

	const fetch_sku_get_details = async () => {
		try {
			set_page_loader({
				show:true,
				text:'fetching sku details...'
			})
			var response = await general_fetch({ url: 'sku/get' , body: {identifiers:JSON.stringify({ id : selected_sku.id})} });
			set_selected_sku_properties(response && response.length?response[0]:'');
			set_page_loader({
				show:false,
				text:''
			})
		} catch(err) {
			set_page_loader({
				show:true,
				text:'Please refresh...'
			})
			console.log(err);
		}
	}

	const close_tab_modal = () => {
		set_open_tab_modal(false)
		set_active_inventory_item(null)
		set_active_inventory_item_details(null)
	}

	const edit_plus_action = (inventory_item_id) => {
		set_active_inventory_item(inventory_item_id);
		set_open_tab_modal(true)
	}

	const edit_sku_properties_modal_trigger = (active_sku, sku_group_id, SKU_group_name) => {
		console.log('active sku recd ====>', active_sku)
		set_selected_sku(active_sku);

		set_active_inventory_item(sku_group_id);
		set_active_group_name(SKU_group_name);
		set_show_edit_sku_properties_modal(true)
	}

	const edit_move_group_modal_trigger = active_sku => {
		set_active_inventory_item(active_sku);
		set_show_move_group_modal(true)
	}
	const edit_move_group_modal_dismiss = () => {
		set_active_inventory_item(null);
		set_show_move_group_modal(false)
	}

	const open_move_clone_sku_modal = (active_sku,sku_group_id, action_type) => {
		set_active_inventory_item(sku_group_id)
		set_active_item_for_moveclone(active_sku);
		set_show_move_clone_sku_modal(true)
		set_action_type(action_type)
	}
	const close_move_clone_sku_modal = () => {
		set_active_inventory_item('')
		set_active_item_for_moveclone(null);
		set_show_move_clone_sku_modal(false)
		set_action_type('')
	}

	const edit_group_properties_modal_trigger = active_sku => {
		set_active_inventory_item(active_sku);
		set_show_group_properties_modal(true)
	}
	const edit_group_properties_modal_dismiss = () => {
		set_active_inventory_item(null);
		set_show_group_properties_modal(false)
		set_active_inventory_item_details(null)
	}

	const edit_sku_properties_modal_dismiss = () => {
		set_selected_sku(null);
		set_show_edit_sku_properties_modal(false)
	}

	const check_skus_in_subcat = (value) => {
		if(value == false){
			update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, 0)
		}else{
			update_no_of_bulk_selected_skus_in_subcat(sku_sub_category_id, skus_in_subcat ? skus_in_subcat.length : 0)
		}
		
		let b = {...bulk_selected_grps}
		let c = {...no_of_bulk_selected_skus_in_grp}
		if(sku_groups && sku_groups.length){
			for(let i=0;i<sku_groups.length;i++){
				b[sku_groups[i].id] = value
				c[sku_groups[i].id] = value ? (sku_groups[i].sku ? sku_groups[i].sku.length : 0) : 0
			}
		}
		set_bulk_selected_grps(b)
		set_no_of_bulk_selected_skus_in_grp(c)

		let a = {...bulk_selected_skus}
		if(skus_in_subcat && skus_in_subcat.length){
			for(let i=0;i<skus_in_subcat.length;i++){
				a[skus_in_subcat[i]] = value
			}
		}
		
		set_bulk_selected_skus(a)
	}
 
	useEffect(() => {
		if (active_inventory_item) {
			var active = sku_groups.filter((x) => x.id == active_inventory_item )[0]
		}
		set_active_inventory_item_details(active)
	}, [ active_inventory_item , sku_groups ])

	// useEffect(() => {
	// 	if (selected_sku) {
	// 		var active = active_inventory_item_details && active_inventory_item_details.sku && active_inventory_item_details.sku.filter((x) => x.id == selected_sku.id )[0]
	// 		set_selected_sku(active)
	// 	}
	// }, [ active_inventory_item_details ])

	// useEffect(() => {
	// 	fetch_additional_properties();
	// 	console.log("custom", custom_properties)
	// },[])

	useEffect(() => {
		if (selected_sku) {
			fetch_sku_get_details();
		}
	}, [ selected_sku ])	

	useEffect(() =>{
		if(active_division_id==='finish'|| (active_division_id==='hardware'&& (sku_category_type==='panel_core_hardware' || sku_category_type ==='mirror_hardware' || sku_category_type==='skirting_hardware'||sku_category_type==='edge_band_hardware'))){
			set_is_material(true)
		}
	},[active_division_id, sku_category_type])

	useEffect(() => {
		let temp=[]
		if(sku_groups && sku_groups.length){
			for(let i=0; i<sku_groups.length; i++){
				let skus = sku_groups[i] ? sku_groups[i].sku : []
				if(skus && skus.length){
					for(let j=0;j<skus.length;j++){
						temp.push(skus[j].id)
					}
				}
			}
		}
		set_skus_in_subcat(temp)
	}, [sku_groups]);

	useEffect(() => {
		let temp = 0
		if(bulk_selected_skus){
			let myArr = Object.keys(bulk_selected_skus)
			if(myArr && myArr.length){
				for(let i = 0; i < myArr.length; i++){
					if(bulk_selected_skus[myArr[i]]){
						temp = 1
						if(!bulk_selection_mode) set_bulk_selection_mode(true)
						break
					}
				}
			}
			// console.log("bulk_selection_mode", temp, bulk_selected_skus, myArr)
		}
		if(!temp) set_bulk_selection_mode(false)
	}, [bulk_selected_skus]);

	// useEffect(() => {
	// 	let temp=[]
	// 	if(bulk_selected_skus ){
	// 		let myArr = Object.keys(bulk_selected_skus)
	// 		if(myArr && myArr.length){
	// 			for(let i = 0; i < myArr.length; i++){
	// 				if(bulk_selected_skus[myArr[i]] && skus_in_subcat.find(o => o==myArr[i])){
	// 					temp.push(myArr[i])
	// 				}
	// 			}
	// 		}
	// 		console.log("initialize_bulk", temp)
	// 		set_bulk_selected_skus_in_subcat(temp)
	// 		// set_initialized_bulk_selected_skus_in_subcat(true)
	// 	}
	// }, [sku_sub_category_id, skus_in_subcat]);

	// useEffect(() => {
	// 	// console.log('bulk_selected_skus_in_subcat', bulk_selected_skus_in_subcat, skus_in_subcat)
	// 	update_bulk_selected_json(sku_sub_category_id, bulk_selected_skus_in_subcat ? bulk_selected_skus_in_subcat.length : 0)

	// }, [bulk_selected_skus_in_subcat, skus_in_subcat]);

	// useEffect(() => {
	// 	if(!bulk_selection_mode) set_bulk_selected_skus_in_subcat([])
	// }, [bulk_selection_mode]);

	const SKU_Dropdown = ({ active_sku, dropdown_open, set_dropdown_open, sku_group_id , SKU_group_name}) => {
		try {
			return (
				<Dropdown isOpen={dropdown_open} toggle={() => set_dropdown_open(!dropdown_open)}>
					<DropdownToggle title='Edit SKU' className='sku_dropdown hover_4' ><i className="fa fa-cog hover_3" /></DropdownToggle>
					<DropdownMenu right className='sku_dropdown_list'>
						{/* <DropdownItem disabled={active_sku && user.store_id == active_sku.store_id ?false:true} className='sku_dropdown_list_item' onClick={active_sku && user.store_id == active_sku.store_id ?() => edit_sku_properties_modal_trigger(active_sku, sku_group_id, SKU_group_name):null}>Edit SKU Properties</DropdownItem> */}
						<DropdownItem className='sku_dropdown_list_item' onClick={() => edit_sku_properties_modal_trigger(active_sku, sku_group_id, SKU_group_name)}>Edit SKU Properties</DropdownItem>
						<DropdownItem disabled={active_sku && user.store_id == active_sku.store_id ?false:true} className='sku_dropdown_list_item' onClick={active_sku && user.store_id == active_sku.store_id ?() => onclick_edit_sku_structure(active_sku):null}>Edit SKU Structure</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' id = 'clone_sku_to_same_group' onClick = {() => {set_toBe_cloned_sku(active_sku);set_cloned_into_group(sku_group_id);set_cloning_mode('clone_to_same_grp');set_show_name_clone_modal(true)}} >Clone SKU to same Group</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick = {() => {set_show_select_sub_cat_modal(true);set_toBe_cloned_sku(active_sku);set_cloned_from_group(SKU_group_name);set_cloning_mode('clone_to_new_grp')}} >Clone SKU to new Group</DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' onClick = {() => {open_move_clone_sku_modal(active_sku, sku_group_id,'clone');set_toBe_cloned_sku(active_sku);set_cloning_mode('clone_to_existing_grp')}} >Clone SKU to any Group</DropdownItem>
						<DropdownItem disabled={active_sku && user.store_id == active_sku.store_id ?false:true} className='sku_dropdown_list_item' onClick = {() => open_move_clone_sku_modal(active_sku,sku_group_id, 'move')} >Move SKU to existing Group</DropdownItem>
						{/* <DropdownItem className='sku_dropdown_list_item' onClick = {() => sales_channel_sku_modal_trigger(active_sku)}>Manage Sales Channels</DropdownItem> */}
						<DropdownItem style={{borderTop:'1px solid #EDF0F2', padding:0}}></DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' id='remove_or_delete_sku' style={{padding:'10px 12px'}} onClick={()=>submit_for_delete_confirm(()=>remove_sku_details(active_sku), ()=>{}, active_sku.store_id===user.store_id?'SKU will be permanentaly deleted from store':'Once removed you can add SKU from Add to Warehouse', active_sku.store_id===user.store_id?'':'non-owned')} ><span style={{color:'#EC4C47'}}>{active_sku.store_id===user.store_id?'Delete from store':'Remove from store'}</span></DropdownItem>
					</DropdownMenu>
				</Dropdown>	
			)
		} catch(err) {
			Sentry.captureException(err)
		}
	}

	const Group_Dropdown = ({ group_dropdown_open, set_group_dropdown_open, active_sku_group, group_store_id, sku_group }) => {
		try {
			return (
				<Dropdown isOpen={group_dropdown_open} toggle={() => set_group_dropdown_open(!group_dropdown_open)}>
					<DropdownToggle title='Edit Group' style={{border: '1px solid #D1D7DA'}} className='group_dropdown hover_3' ><i style={{color:'#66788A', boxShadow:'unset'}} className="fa fa-ellipsis-h hover_3" /></DropdownToggle>
					<DropdownMenu right className='sku_dropdown_list'>
						<DropdownItem className='sku_dropdown_list_item' onClick ={() => edit_group_properties_modal_trigger(active_sku_group)}>Edit Group Properties</DropdownItem>
						<DropdownItem disabled={group_store_id===user.store_id?false:true} className='sku_dropdown_list_item' onClick ={group_store_id===user.store_id?() => edit_move_group_modal_trigger(active_sku_group):null} >Move Group</DropdownItem>
						<DropdownItem style={{borderTop:'1px solid #EDF0F2', padding:0}}></DropdownItem>
						<DropdownItem className='sku_dropdown_list_item' id='remove_or_delete_group' style={{padding:'10px 12px'}} 
							onClick={group_store_id===user.store_id?()=>submit_for_delete_confirm(()=>delete_group(active_sku_group), ()=>{}, 'Group will be permanentaly deleted from store' ) : ()=>submit_for_delete_confirm(()=>Remove_from_group(active_sku_group, sku_group), ()=>{}, 'Once removed you can add Group from Add to Warehouse', 'non-owned') }>
							<span style={{color:'#EC4C47'}}>{group_store_id===user.store_id?'Delete from store':'Remove from store'}</span>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>	
			)
		} catch(err) {
			Sentry.captureException(err)
		}
	}

	const Edit_Button = ({ on_click }) => (
		<span title='Add New SKU' id='add_new_sku_button' style={{maxHeight:'14px', width:'100%'}} onClick={on_click}><i style={{cursor:'pointer', fontSize:'14px', color:'#007EF4', display:'none'}}className="fa fa-plus hover_x3" /></span>
	);

	return (
		<div style={{width:'100%', height:'100%'}}>
			<EditSkuPropertiesTab 
				confirmation_alert={confirmation_alert}
				open={show_edit_sku_properties_modal} 
				handleClose={edit_sku_properties_modal_dismiss} 
				user={user} 
				fetch_sku_groups_details={fetch_sku_groups}
				orignal_tags={orignal_tags}
				sku_category_id={sku_category_id}
				sku_category_name={sku_category_name}
				sku_category_type={sku_category_type}
				active_group_name={active_group_name} 
				active_division_id={active_division_id}
				fetch_material_template={fetch_material_template}
				material_template={material_template}
				set_material_template={set_material_template}
				brands={brands}
				set_page_loader={set_page_loader}
				materials_json={materials_json}
				texture_json={texture_json}
				fetch_sku_get_details={fetch_sku_get_details}
				selected_sku_properties={selected_sku_properties}
				show_sales_channel_sku_modal={show_sales_channel_sku_modal} 
				all_sales_channels={all_sales_channels} 
				all_allowed_key = {all_allowed_key}
				send_analytics_event={send_analytics_event}
				submit_for_delete_confirm  = {submit_for_delete_confirm}
			/>
			<AddNewSKU 
				open={open_tab_modal} 
				handleClose={close_tab_modal} 
				user={user}
				active_inventory_item={active_inventory_item}
				fetch_sku_groups={fetch_sku_groups}
				active_division_id={active_division_id}
				fetch_material_template={fetch_material_template}
				material_template={material_template}
				brands={brands}
				materials_json={materials_json}
				set_page_loader={set_page_loader}
				texture_json={texture_json}
				sku_category_id={sku_category_id}
				sku_category_name={sku_category_name}
				sku_category_type={sku_category_type}
				all_allowed_key = {all_allowed_key}
				set_border_animation_group = {set_border_animation_group}
				set_pre_selected_sku = {set_pre_selected_sku}
			/>
			<Move_group 
				open={show_move_group_modal} 
				handleClose={edit_move_group_modal_dismiss}
				on_click_move_group={on_click_move_group}
				active_inventory_item={active_inventory_item}
				active_inventory_item_details={active_inventory_item_details}
				sku_categories={sku_categories}
				fetch_categories = {fetch_categories}
			/>
			<Move_clone_sku 
				open={show_move_clone_sku_modal} 
				handleClose={close_move_clone_sku_modal} 
				active_sku_details={active_item_for_moveclone?active_item_for_moveclone:''} 
				sku_sub_categories={sku_sub_categories} 
				action_type={action_type} 
				sku_category_id={sku_category_id} 
				active_group_id={active_inventory_item?active_inventory_item:''} 
				active_sub_category_id={sku_sub_category_id}  
				on_click_fn={action_type==='clone'?on_click_clone_sku_to_existing_group:on_click_move_sku_to_existing_group}
				set_show_name_clone_modal = {set_show_name_clone_modal}
				set_cloned_into_group = {set_cloned_into_group}
				set_cloned_into_sub_cat = {set_cloned_into_sub_cat}
			/>
			<SelectSubCategoryModal
				open = {show_select_sub_cat_modal}
				handleClose = {() => set_show_select_sub_cat_modal(false)}
				set_show_name_clone_modal = {set_show_name_clone_modal}
				toBe_cloned_sku = {toBe_cloned_sku}
				sku_sub_categories = {sku_sub_categories}
				sub_cat_id = {sku_sub_category_id}
				set_cloned_into_sub_cat = {set_cloned_into_sub_cat}
			/>
			<Category_edit_confirm_modal 
				open={show_tag_move_group_confirm_modal} 
				handle_close={close_tag_move_group_confirm_modal} 
				confirm_onclick_fn={on_click_move_group_fn} 
				all_sku_data={data_for_tags_move_group}
				modal_type='move' 
			/>
			<EditGroupProperties 
				open={show_group_properties_modal} 
				handleClose={edit_group_properties_modal_dismiss}
				active_item={active_inventory_item_details}
				user={user}
				fetch_sku_groups={fetch_sku_groups}
				sku_category_id_for_group={sku_category_id}
				orignal_tags={orignal_tags}
				set_page_loader={set_page_loader}
			/>
			<NameCloneModal
				open = {show_name_clone_modal}
				handleClose = {() => {set_show_name_clone_modal(false); set_clone_prices(false)}}
				mode = {cloning_mode}
				toBe_cloned_sku = {toBe_cloned_sku}
				cloned_into_group = {cloned_into_group}
				cloned_from_group = {cloned_from_group}
				cloned_into_sub_cat = {cloned_into_sub_cat}
				clone_to_same_group = {clone_to_same_group}
				clone_to_new_group = {clone_to_new_group}
				clone_to_existing_group = {on_click_clone_sku_to_existing_group}
				new_sku_name = {new_sku_name}
				set_new_sku_name = {set_new_sku_name}
				new_grp_name = {new_grp_name}
				set_new_grp_name = {set_new_grp_name}
				clone_prices = {clone_prices}
				set_clone_prices = {set_clone_prices}
			/>
			{
				bulk_selection_mode ?
				<div style={{display:'flex', justifyContent:'space-between', backgroundColor:'#F3F4F6', height:'40px'}}>
					<div className='flex_property'>
						<input type='checkbox' checked={no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] == skus_in_subcat.length ? true : false} style={{marginLeft:'12px', cursor:'pointer'}} onClick={() => {no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] == skus_in_subcat.length ? check_skus_in_subcat(false) : check_skus_in_subcat(true)}}/>
						<span style={{marginLeft:'8px', fontSize:'15px'}} >Select All SKUs</span>
					</div>
					<div className='flex_property' style={{color:'#A7A8B2', fontSize:'14px'}}>
						<span style={{fontWeight:'600', marginRight:'16px'}}>Selected Items: </span>
						<span style={{marginRight:'16px'}}>{no_of_bulk_selected_skus_in_subcat ? no_of_bulk_selected_skus_in_subcat[sku_sub_category_id] : 0} SKUs Selected / {skus_in_subcat ? skus_in_subcat.length : 0}</span>
					</div>
				</div>:''
			}
			{
				content_view=='card' ? (
					<Row style={{ margin:0, padding:'10px', height:'100%' }}>
						{sku_groups && sku_groups.length && sku_groups.map((sku_group, idx) => (
							<Col key={idx} xs={12} sm={6} md={4} lg={3} xl={3} style={{padding:'5px' }}>
							{
								(sku_group.id == border_animation_group || (pre_selected_sku && sku_group.sku && sku_group.sku.find(o => o.id == pre_selected_sku))) ?
								<GroupInventoryCard	
									fetch_sku_groups={fetch_sku_groups}
									user={user}
									sku_sub_category_id = {sku_sub_category_id}
									sku = {sku_group.sku && sku_group.sku.length?sku_group.sku.sort(custom_sort):[]}
									Header_Element={Edit_Button}
									inventory_item_id={sku_group.id}
									SKU_group_name={sku_group.name?sku_group.name:''}
									link={sku_group.id}
									title={sku_group.name}
									edit_plus_action={edit_plus_action}
									SKU_Dropdown={SKU_Dropdown}
									Group_Dropdown={Group_Dropdown}
									group_store_id={sku_group.store_id}
									sku_group={sku_group}
									border_animation_group = {border_animation_group}
									set_border_animation_group = {set_border_animation_group}
									pre_selected_sku = {pre_selected_sku}
									set_pre_selected_sku = {set_pre_selected_sku}
									bulk_selection_mode = {bulk_selection_mode}
									bulk_selected_skus = {bulk_selected_skus}
									set_bulk_selected_skus = {set_bulk_selected_skus}
									bulk_selected_grps = {bulk_selected_grps}
									set_bulk_selection_mode = {set_bulk_selection_mode}
									update_bulk_selected_skus = {update_bulk_selected_skus}
									// remove_from_bulk_selected_skus = {remove_from_bulk_selected_skus}
									update_bulk_selected_grps = {update_bulk_selected_grps}
									// remove_from_bulk_selected_grps = {remove_from_bulk_selected_grps}
									// bulk_selected_skus_in_subcat = {bulk_selected_skus_in_subcat}
									// set_bulk_selected_skus_in_subcat = {set_bulk_selected_skus_in_subcat}
									// update_bulk_selected_skus_in_subcat = {update_bulk_selected_skus_in_subcat}
									// remove_from_bulk_selected_skus_in_subcat = {remove_from_bulk_selected_skus_in_subcat}
									no_of_bulk_selected_skus_in_grp = {no_of_bulk_selected_skus_in_grp}
									update_no_of_bulk_selected_skus_in_grp = {update_no_of_bulk_selected_skus_in_grp}
									no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
									update_no_of_bulk_selected_skus_in_subcat = {update_no_of_bulk_selected_skus_in_subcat}
								/>
								:
								<LazyLoad overflow={true} placeholder={<GroupInventoryCard_loader/>}>
								<GroupInventoryCard	
									fetch_sku_groups={fetch_sku_groups}
									user={user}
									sku_sub_category_id = {sku_sub_category_id}
									sku = {sku_group.sku && sku_group.sku.length?sku_group.sku.sort(custom_sort):[]}
									Header_Element={Edit_Button}
									inventory_item_id={sku_group.id}
									SKU_group_name={sku_group.name?sku_group.name:''}
									link={sku_group.id}
									title={sku_group.name}
									edit_plus_action={edit_plus_action}
									SKU_Dropdown={SKU_Dropdown}
									Group_Dropdown={Group_Dropdown}
									group_store_id={sku_group.store_id}
									sku_group={sku_group}
									border_animation_group = {border_animation_group}
									set_border_animation_group = {set_border_animation_group}
									pre_selected_sku = {pre_selected_sku}
									set_pre_selected_sku = {set_pre_selected_sku}
									bulk_selection_mode = {bulk_selection_mode}
									bulk_selected_skus = {bulk_selected_skus}
									set_bulk_selected_skus = {set_bulk_selected_skus}
									bulk_selected_grps = {bulk_selected_grps}
									set_bulk_selection_mode = {set_bulk_selection_mode}
									update_bulk_selected_skus = {update_bulk_selected_skus}
									// remove_from_bulk_selected_skus = {remove_from_bulk_selected_skus}
									update_bulk_selected_grps = {update_bulk_selected_grps}
									// remove_from_bulk_selected_grps = {remove_from_bulk_selected_grps}
									// bulk_selected_skus_in_subcat = {bulk_selected_skus_in_subcat}
									// set_bulk_selected_skus_in_subcat = {set_bulk_selected_skus_in_subcat}
									// update_bulk_selected_skus_in_subcat = {update_bulk_selected_skus_in_subcat}
									// remove_from_bulk_selected_skus_in_subcat = {remove_from_bulk_selected_skus_in_subcat}
									no_of_bulk_selected_skus_in_grp = {no_of_bulk_selected_skus_in_grp}
									update_no_of_bulk_selected_skus_in_grp = {update_no_of_bulk_selected_skus_in_grp}
									no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
									update_no_of_bulk_selected_skus_in_subcat = {update_no_of_bulk_selected_skus_in_subcat}
								/>
								</LazyLoad>
							}
							</Col>	
						))}
					</Row>
				):(
					<table style={{ height:'100%', width:'100%' }}>
						<thead>
						<tr style={{ width:'100%', height:'35px', display:'flex', alignItems:'center'}}>
							<th style={{flex:1, paddingLeft:'20px'}}></th>
							<th style={{flex:7, textAlign:"left", color:"#627386", fontSize:"12px", fontWeight:0}}>Groups</th>
							<th style={{flex:6, textAlign:"left", color:"#627386", fontSize:"12px", fontWeight:0}}>SKU's</th>
							<th style={{flex:2}}></th>
						</tr>
						</thead>
						<tbody>
						{sku_groups && sku_groups.length && sku_groups.map((sku_group, index) => (	
							<Group_ListView	
								key={index}
								index={index+1}
								fetch_sku_groups={fetch_sku_groups}
								sku_group={sku_group}
								user={user}
								sku = {sku_group.sku && sku_group.sku.length?sku_group.sku.sort(custom_sort):[]}
								Header_Element={Edit_Button}
								inventory_item_id={sku_group.id}
								SKU_group_name={sku_group.name}
								link={sku_group.id}
								title={sku_group.name}
								edit_plus_action={edit_plus_action}
								SKU_Dropdown={SKU_Dropdown}
								Group_Dropdown={Group_Dropdown}
								group_store_id={sku_group.store_id}
							/>	
						))}
						</tbody>
					</table>
				)
			}
		</div>	
	)
}

const SKU_Groups  = ({ orignal_tags, confirmation_alert, user, match, set_page_loader, submit_for_delete_confirm, set_active_tab,set_active_division_id_sidebar, send_analytics_event, check_route_fn,store_details, get_store_details, sku_sub_category_id_first}) => {
	const [initialized_grps, set_initialized_grps] = useState({});
	const [ initialized1, set_initialized1 ] = useState(false);
	const [ fetching, set_fetching ] = useState(false);
	const [ sku_groups, set_sku_groups ] = useState([]);
	const [ sku_groups_display, set_sku_groups_display ] = useState([]);
	const [ parent_category_details , set_parent_category_details] = useState(null)
	const [ open_add_new_group, set_open_add_new_group ] = useState(false);
	const [	open_afw_group_modal, set_open_afw_group_modal ] = useState(false);
	const [	inventory_type, set_inventory_type ] = useState(null);
	const [content_view, set_content_view] = useState('card');

	const [ brands, set_brands ] = useState([]);
	const [ all_sales_channels, set_all_sales_channels ] = useState([]);
	// const [ stale_crumbs, set_stale_crumbs ] = useState(false);
	// const [ active_crumb_index, set_active_crumb_index ] = useState(0);
	const [ sku_division_id, set_sku_division_id ] = useState(null);
	const [ sku_category_id, set_sku_category_id ] = useState(null);
	const [ sku_category_name, set_sku_category_name ] = useState(null);
	const [ sku_category_type, set_sku_category_type ] = useState(null);
	const [ sku_sub_category_id, set_sku_sub_category_id ] = useState(null);
	const [ sku_sub_category_name, set_sku_sub_category_name ] = useState(null);
	const [sku_sub_categories, set_sku_sub_categories] = useState([]);
	const [sku_categories, set_sku_categories] = useState([]);
	const [search_string, set_search_string] = useState('');
	const [	show_add_sub_category, set_show_add_sub_category ] = useState(false);
	const [	material_template, set_material_template ] = useState('');
	const [	materials_json, set_materials_json ] = useState([]);
	const [	texture_json, set_texture_json ] = useState([]);
	// const [	show_edit_sub_category_modal, set_show_edit_sub_category_modal ] = useState(false);
	const [ sku_sub_category_details, set_sku_sub_category_details ] = useState({});
	const [ sub_cat_mode, set_sub_cat_mode ] = useState('');
	const [border_animation_group, set_border_animation_group] = useState();
	const [border_animation_sub_cat, set_border_animation_sub_cat] = useState();
	const [pre_selected_sku, set_pre_selected_sku] = useState('')
	const [bulk_selection_mode, set_bulk_selection_mode] = useState();
	const [bulk_selected_skus, set_bulk_selected_skus] = useState({});
	const [bulk_selected_grps, set_bulk_selected_grps] = useState({});
	// const [bulk_selected_json, set_bulk_selected_json] = useState();
	const [no_of_bulk_selected_grps, set_no_of_bulk_selected_grps] = useState(0);
	const [no_of_bulk_selected_skus, set_no_of_bulk_selected_skus] = useState(0);
	const [no_of_bulk_selected_skus_in_grp, set_no_of_bulk_selected_skus_in_grp] = useState({});
	const [no_of_bulk_selected_skus_in_subcat, set_no_of_bulk_selected_skus_in_subcat] = useState({});
	const [bulk_actions_dropdown_open, set_bulk_actions_dropdown_open] = useState(false);
	const [show_bulk_edit_modal, set_show_bulk_edit_modal] = useState(false);
	
	const alert = useAlert()
	const history = useHistory()

    // const fetch_texture_json = async() => {
	// 	try{
	// 		var attributes = JSON.stringify({})
	// 		var identifiers = JSON.stringify({})
	// 		var reponse = await general_fetch({url :'texture/get' , body: {identifiers, attributes} }) 
	// 		reponse && set_texture_json(reponse)
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }

	const fetch_sku_category = async () => {
		try {
			//console.log('fetching category')
			var identifiers = JSON.stringify({ id: sku_category_id });
			var attributes = JSON.stringify({});
			var resp = await general_fetch({ url: 'sku_category/get' , body: { identifiers , attributes } });
			//console.log(resp);
			set_sku_category_name(resp[0].name);
			set_sku_category_type(resp[0].sku_category_type_id);
		} catch (err) {
			console.log(err);
		}
	}

	// const fetch_material_json = async() => {
	// 	try{
	// 		//loader
	// 		var attributes = JSON.stringify({})
	// 		var identifiers = JSON.stringify({})
	// 		var reponse = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
	// 		reponse && set_materials_json(reponse)
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }

	const fetch_all_sales_channels = async () => {
		try {
			var identifiers = JSON.stringify({store_id : user.store_id})
			var attributes = JSON.stringify({})
			var body = {identifiers , attributes}
			var sales_channel_details = await general_fetch({ url: 'sales_channel/get' , body});
			set_all_sales_channels(sales_channel_details);

		} catch(err) {
			console.log(err);
		}
	}

	const fetch_brand_details = async () => {
		try {
			var brand_details = await general_fetch({ url: 'brand/get'});
			set_brands(brand_details);
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_sku_sub_categories = async () => {
		try {
			if(sku_category_id){
				var identifiers = JSON.stringify({sku_category_id});
				var attributes = JSON.stringify({});
				var response = await general_fetch({ url: 'inventory/get_sub_categories' , body: { sku_category_id } });
				// var response = await general_fetch({ url: 'sku_sub_category/get_active_sku_new' , body: { identifiers , attributes } });
				// console.log('output frrrr --->> ', response2, 'ddddd', response);
				// set_fetching(true)
				set_initialized1(true)
				var depricated_filter_output =response && response.length ?response.filter(x => x.deprecated !=1):[]
				var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
				resp && set_sku_sub_categories(resp);
			}
		} catch(err) {
			alert.error('Something went wrong, Please refresh')
			console.log(err);
		}
	}

	const fetch_categories = async () => {
		try {
			var identifiers = JSON.stringify({ sku_division_id:sku_division_id });
			var attributes = JSON.stringify({});
			console.log('firring get_categories api =========')
			var response = await general_fetch({ url: 'inventory/get_categories' , body: { sku_division_id } });
			console.log('firring  comp=========',response)
			var resp = response.sort(name_sort)
			set_sku_categories(resp);
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_material_template = async() => {
		try{	
			var attributes = JSON.stringify({})
			var identifiers = JSON.stringify({})
			var reponse = await general_fetch({url :'material_template/get' , body: {identifiers, attributes} }) 
			reponse && set_material_template(reponse)
		}catch(err){
			console.log(err)
		}
	}

	// const update_bulk_selected_json = (subcat_id, selected_skus_length) => {
	// 	let a = {...bulk_selected_json}
	// 	a[subcat_id] = selected_skus_length
	// 	console.log('bulk_selected_json', a)
	// 	set_bulk_selected_json(a)
	// }

	const exit_bulk_selection_mode = () => {
		set_bulk_selection_mode(false);
		set_bulk_selected_grps({});
		set_bulk_selected_skus({});
		set_no_of_bulk_selected_skus_in_grp({});
		set_no_of_bulk_selected_skus_in_subcat({});
	}

	const update_no_of_bulk_selected_skus_in_grp = (grp_id, value) => {
		let a = {...no_of_bulk_selected_skus_in_grp}
		a[grp_id] = value
		set_no_of_bulk_selected_skus_in_grp(a)
	}

	const update_no_of_bulk_selected_skus_in_subcat = (subcat_id, value) => {
		let a = {...no_of_bulk_selected_skus_in_subcat}
		a[subcat_id] = value
		set_no_of_bulk_selected_skus_in_subcat(a)
	}

	useEffect(() => {
		if(material_template && material_template.length){
			console.log(material_template)
		}
	},[material_template])

	// const recaliberate_crumbs = () => {
	// 	console.log('recaliberate_crumbs called --> ', crumbs)
	// 	var tmp_crumbs = [];
	// 	var active_idx = 0;
	// 	if (sku_division_id) {
	// 		tmp_crumbs.push({ name: sku_division_id, url: '/catalogue/' + sku_division_id + '/' });
	// 	}
	// 	if (sku_category_name) {
	// 		tmp_crumbs.push({ name: sku_category_name, url: '/catalogue/' + sku_division_id + '/' + sku_category_id + '/'});
	// 		active_idx = 1;
	// 	}
	// 	if (sku_sub_category_name) {
	// 		tmp_crumbs.push({ name: sku_sub_category_name, url: '/catalogue/' + sku_division_id + '/' + sku_category_id + '/' + sku_sub_category_id + '/' });
	// 		active_idx = 2;
	// 	}
	// 	console.log('final crumbs ---> ', tmp_crumbs)
	// 	set_crumbs(tmp_crumbs);
	// 	set_active_crumb_index(active_idx);
	// }

	useEffect(() => {
		//console.log('url change detected props recd ---> ', match);
		var { sku_division, sku_category } = match.params;
		// var tmp_crumbs = [];
		// var active_idx = 0;
		//console.log("params found Done ======> " , sku_division, sku_category)		
		set_sku_division_id(sku_division);
		set_sku_category_id(sku_category);
		// set_stale_crumbs(!stale_crumbs);
	}, [ match ]);

	useEffect(() => {
		if(!(brands && brands.length)){
			fetch_brand_details();	
		}
		if(!(all_sales_channels && all_sales_channels.length)){
			fetch_all_sales_channels();	
		}
		// if(!(materials_json && materials_json.length)){
		// 	fetch_material_json();
		// }
		// if(!(texture_json && texture_json.length)){
		// 	fetch_texture_json();
		// }
		set_active_tab('catalogue')
		// check_route_fn('catalogue')
		set_active_division_id_sidebar(sku_division_id)
		send_analytics_event('auto', 'Catalogue', 'Catalogue', 1, window.location.href)
	}, []);

	useEffect(() => {
		if (sku_category_id) {
			fetch_sku_category();
			fetch_sku_sub_categories();
			fetch_material_template();
		} else {
			set_sku_category_name(null);
			set_sku_category_type(null);
			set_sku_sub_category_id('');
		}
		exit_bulk_selection_mode()
	}, [ sku_category_id ]);

	useEffect(() => {
		if (sku_division_id) {
			fetch_categories()
		}
	}, [ sku_division_id ]);

	useEffect(() => {
		if(sku_sub_categories && sku_sub_categories.length){
			console.log("sku_sub_categories", sku_sub_categories)
			if(sku_sub_category_id_first){
				console.log("sku_sub_categories if", sku_sub_category_id_first)
				let temp_sku_sub_cat = sku_sub_categories.find(o=>o.id == sku_sub_category_id_first)
				set_sku_sub_category_id(temp_sku_sub_cat?temp_sku_sub_cat.id:'')
				set_sku_sub_category_name(temp_sku_sub_cat? temp_sku_sub_cat.name:'');
				set_sku_sub_category_details(temp_sku_sub_cat? temp_sku_sub_cat:'');
			}else{
				console.log("sku_sub_categories else", sku_sub_categories)
				// let temp_sku_sub_cat = sku_sub_categories.find(o=>o.id == sku_sub_category_id_first)
				set_sku_sub_category_id(sku_sub_categories[0]?sku_sub_categories[0].id:'')
				set_sku_sub_category_name(sku_sub_categories[0]? sku_sub_categories[0].name:'');
				set_sku_sub_category_details(sku_sub_categories[0]? sku_sub_categories[0]:'');
			}
		}else{
			set_sku_sub_category_id('')
			set_sku_sub_category_name('');
			set_sku_sub_category_details({});
		}
	},[sku_sub_categories])


	const update_initialized_grps = (sub_cat_id, val) => {
		let a = JSON.parse(JSON.stringify(initialized_grps))
		a[sub_cat_id] = val 
		set_initialized_grps(a)
	}

	const fetch_sku_groups = async () => {
		try {
			if(sku_sub_category_id){
				// set_page_loader({
				//     show: true,
				//     text: 'Fetching groups...',
				// })
				console.log("sku_groups_display sku grps fn called", sku_sub_category_id, sku_sub_category_details)
				set_sku_groups_display([])
				set_fetching(false)
				var identifiers = JSON.stringify({ store_id: user.store_id, sku_sub_category_id });
				var attributes = JSON.stringify({});
				// var response = await general_fetch({ url: 'sku_group/get_active_sku' , body: { identifiers , attributes } });
				var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id } });
				// console.log(' group inventory resp -=->>>> ', response3)
				var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
				var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
				set_fetching(true)
				// set_initialized(true)

				update_initialized_grps(sku_sub_category_id, true)

				set_sku_groups(resp);
				set_sku_groups_display(resp)
				
				// set_page_loader({
				//     show: false,
				//     text: '',
				// })
			}else{
				console.log("sku_sub_category_id is null")
			}
		} catch(err) {
			console.log(err);
		}
	}

	const check_is_owned_group = () => {
		var sku_group_dummy=[...sku_groups]
		var flag=0;
		var x= sku_group_dummy && sku_group_dummy.length && sku_group_dummy.map(group=>{
			if(group.store_id===user.store_id){
				flag=1;
			}
			group.sku && group.sku.length &&  group.sku.map(sk =>{
				if(sk.store_id===user.store_id){
					flag=1;
				}
			})				
		})
		if(flag===1){
			return true
		}else{
			return false
		}
	}

	const onclick_remove_sub_cat = async () => {
		if( sku_sub_category_details && !check_is_owned_group()){
			try {
				var sku_ids = []
				var x= sku_groups && sku_groups.length ? sku_groups.map(group=>{
					var x_sku= group.sku.length ? group.sku.map(x => x.id):[]
					sku_ids=sku_ids.concat(x_sku)
				}):[]
				var identifiers = JSON.stringify({id:sku_ids})
				var resp = await general_fetch({ url: 'sku/remove_from_store', body:{identifiers}});
				fetch_sku_sub_categories();
				alert.success('Sub Category removed Successfully')
				set_sku_sub_category_id('');
			}catch(err) {
				alert.error('Failed to Remove Sub Category')
				console.log(err);
			}
		}else{
			alert.info('Please Remove All Owned Group and SKU for deletion of Sub-Category')
		}
	}

	// const onclick_sub_category_trash = () => {
	// 	set_show_delete_confirm_modal({
	// 		show:true,
	// 		text:'Sub-Category removed can be added from Add to Warehouse, Press Delete to continue.',
	// 		delete_function: onclick_remove_sub_cat
	// 	})
	// }

	const test = () => {
		 console.log('success')
	}
	// const onclick_sub_category_edit = () => {
	// 	set_show_delete_confirm_modal({
	// 		show:true,
	// 		text:'Sub-Category removed can be added from Add to Warehouse, Press Delete to continue.',
	// 		delete_function: test()
	// 	})
	// }

	const show_add_new_group_modal = () => {
		set_open_add_new_group(true)
	}
	const close_add_new_group_modal = () => {
		set_open_add_new_group(false)	
	}

	const open_add_sub_categories_modal = (mode) => {
		set_show_add_sub_category(true)
		set_sub_cat_mode(mode)
	}
	const close_add_sub_categories_modal = () => {
		set_show_add_sub_category(false)	
		set_sub_cat_mode('')
	}

	useEffect(() => {
		if (sku_sub_category_id) {
			update_initialized_grps(sku_sub_category_id, false)
			fetch_sku_groups();
		}else{
			set_sku_groups([])
			set_sku_groups_display([])
		}
	}, [ sku_sub_category_id ])

	const close_afw_group_modal = () => {
		set_inventory_type(null);
		set_open_afw_group_modal(false);
		fetch_sku_sub_categories();
	}

	const onclick_group_afw = () => {
		//set_inventory_type('afw_groups')
		set_open_afw_group_modal(true)
	}
	const onclick_sub_category_icon = (sub_category) => {
		set_sku_sub_category_id(sub_category.id) 
		set_sku_sub_category_name(sub_category.name)
		set_sku_sub_category_details(sub_category)
	}

	useEffect(() => {
			search_action()
	}, [search_string])

	useEffect(() => {
		console.log("no_of_bulk_selected_skus_in_grp", no_of_bulk_selected_skus_in_subcat)
	}, [no_of_bulk_selected_skus_in_subcat]);

	const search_action = () => 
	{
		var filtered_groups = [...sku_groups];

		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_groups = filtered_groups.filter( group => group.name && group.name.toLowerCase().includes(lower_search_string))
		}
		set_sku_groups_display(filtered_groups)
	} 

	// useEffect(() => {
	// 	let temp=0
	// 	let temp2=0
	// 	if(bulk_selected_skus){
	// 		let myArr = Object.keys(bulk_selected_skus)
	// 		if(myArr && myArr.length){
	// 			for(let i = 0; i < myArr.length; i++){
	// 				if(bulk_selected_skus[myArr[i]]){
	// 					temp++
	// 					temp2 = 1
	// 					if(!bulk_selection_mode) set_bulk_selection_mode(true)
	// 				}
	// 			}
	// 		}
	// 	}
	// 	set_no_of_bulk_selected_skus(temp)
	// 	if(!temp2) set_bulk_selection_mode(false)
	// }, [bulk_selected_skus]);

	// useEffect(() => {
	// 	let temp=0
	// 	if(bulk_selected_grps){
	// 		let myArr = Object.keys(bulk_selected_grps)
	// 		if(myArr && myArr.length){
	// 			for(let i = 0; i < myArr.length; i++){
	// 				if(bulk_selected_grps[myArr[i]]) temp++
	// 			}
	// 		}
	// 	}
	// 	set_no_of_bulk_selected_grps(temp)
	// }, [bulk_selected_grps]);

	return (
		<Main_Grid active_tab='catalogue' active_division_id={sku_division_id} user={user} store_details={store_details} get_store_details={get_store_details}>
			<BulkEditModal
				open = {show_bulk_edit_modal}
				handleClose = {() => set_show_bulk_edit_modal(false)}
				set_page_loader = {set_page_loader}
				user = {user}
				orignal_tags = {orignal_tags}
				bulk_selected_skus = {bulk_selected_skus}
				no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
				sku_category_name = {sku_category_name}
				sku_category_id = {sku_category_id}
				sku_category_type = {sku_category_type}
				active_division_id = {sku_division_id}
				no_of_bulk_selected_skus_in_grp = {no_of_bulk_selected_skus_in_grp}
				sku_sub_categories = {sku_sub_categories}
				exit_bulk_selection_mode = {exit_bulk_selection_mode}
			/>
		<div style={{height:'100%', width:'100%'}}>
			<AddSubCategory open={show_add_sub_category} handleClose={close_add_sub_categories_modal} sku_sub_category_details={sku_sub_category_details} fetch_sku_sub_categories={fetch_sku_sub_categories} user={user} sku_category_id={sku_category_id} mode={sub_cat_mode} sku_groups={sku_groups} set_sku_sub_category_id={set_sku_sub_category_id} set_sku_sub_category_details={set_sku_sub_category_details} submit_for_delete_confirm={submit_for_delete_confirm} onclick_remove_sub_cat={onclick_remove_sub_cat} orignal_tags={orignal_tags} set_page_loader={set_page_loader}/>
			<AFW_modal set_page_loader={set_page_loader} fetch_sku_groups_details={fetch_sku_groups} sku_division_id={sku_division_id} sku_category_id={sku_category_id} sku_category_name={sku_category_name} sku_sub_category_id={sku_sub_category_id} sku_sub_categories={sku_sub_categories} sku_sub_category_name={sku_sub_category_name} sku_groups={sku_groups} user={user} open={open_afw_group_modal} handleclose={close_afw_group_modal}/>
			<AddNewGroup open={open_add_new_group} handleClose={close_add_new_group_modal} user={user} fetch_sku_groups_details={fetch_sku_groups} sub_category_id={sku_sub_category_id} set_border_animation_group = {set_border_animation_group} />
			<div style={{height:'100%', width:'100%'}}>
				<div style={{height:'54px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
					<span className='flex_property'>
						{bulk_selection_mode ? <span className='bulk_selection_exit_button' onClick={() => {exit_bulk_selection_mode()}}><i className='fa fa-caret-left' /></span> : ''}
						<span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', color:'#22272E'}}>Sub-categories for - <span style={{fontSize:'14px', fontFamily:'Source_Sans_Pro-SemiBold', textTransform: 'capitalize', color:'#22272E'}}>{sku_category_name ? sku_category_name : ''}</span></span>
					</span>
					<span style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
						{/* <Link style={{textDecoration:'none'}} to = {'/bulk_create_menu'}>
							<button onClick={() => {}} style={{cursor:'pointer', height:'32px', border: '1px solid #b8bfca', backgroundColor:'white', borderRadius:'4px', padding:'8px 10px', display:'flex', alignItems:'center'}}><span style={{fontSize:'12px', color:'#4C5D6E'}}>Bulk Create</span></button>
						</Link> */}
						<Dropdown style={{border:bulk_selection_mode ? '2px solid #0078FF' : '', borderRadius:'6px'}} isOpen={bulk_actions_dropdown_open} toggle={() => set_bulk_actions_dropdown_open(!bulk_actions_dropdown_open)} >
							<DropdownToggle style={{cursor:'pointer', height:'32px', border: '1px solid #b8bfca', backgroundColor:'white', borderRadius:'4px', padding:'8px 10px', display:'flex', alignItems:'center', color:'#676878', fontSize:'12px'}}>Bulk Actions <i style={{marginLeft:'4px', fontSize:'14px'}} className='fa fa-caret-down'/></DropdownToggle>
							<DropdownMenu style={{fontSize:'14px'}}>
								<DropdownItem style={{ height:'32px'}} onClick={() => history.push('/bulk_create_menu')} >Bulk Create</DropdownItem>
								<DropdownItem style={{borderRadius:'4px', opacity:bulk_selection_mode?'1':'0.3', height:'32px'}} onClick={() => {if(bulk_selection_mode)set_show_bulk_edit_modal(true)}} >Bulk Edit</DropdownItem>
							</DropdownMenu>
						</Dropdown>
						<button onClick={onclick_group_afw} className='add_from_warehouse_button'><span style={{fontSize:'12px', color:'#4C5D6E'}}>Add From Warehouse</span></button>
					</span>
				</div>
				<div style={{height:'calc(100% - 54px)', width:'100%'}}>
					<Card style={{height:'100%', width:'100%'}}>

						<div style={{height:'50px', width:'100%', display:'flex', alignItems:'center', padding:'0px 20px', justifyContent:'space-between'}}>
							<div id='div_for_loader' style={{ height:'100%', width:'100%', overflow:'hidden', display:'flex', alignItems:'center'}}>
								{
									sku_sub_categories && sku_sub_categories.length && initialized_grps[sku_sub_category_id] ?
									<ExtendedDropdown item_json={sku_sub_categories} sku_category_id = {sku_category_id} sku_division_id = {sku_division_id} open_additional_option_fn={()=>open_add_sub_categories_modal('edit')} sku_sub_category_id={sku_sub_category_id} onclick_tab={onclick_sub_category_icon} border_animation_sub_cat={border_animation_sub_cat} set_border_animation_sub_cat={set_border_animation_sub_cat} bulk_selection_mode={bulk_selection_mode} no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}/>
									:
									<Filler_sub_cat initialized_grps={initialized_grps} initialized1={initialized1} display_groups={sku_groups_display} sub_cat_id={sku_sub_category_id} sku_sub_categories={sku_sub_categories} div_name='div_for_loader' />
								}
							</div>
							<div style={{ display:'flex', alignItems:'center', width:'180px'}}>
								<div style={{border:'2px solid #F2F4F6', margin:'0px 20px', height:'25px', width:0}}></div>
								<div id='add_sub_cat_button' title='Add New Sub-Category' style={{cursor:'pointer'}} onClick={() =>open_add_sub_categories_modal('add')} className='flex_property'>
									<div className='flex_property' style={{height:'12px', width:'12px',marginRight:"8px"}}><i style={{backgroundColor:'white', color:'#1070CA'}} className="fas fa-plus-circle"/></div>
									<div style={{fontSize:'12px', color:'#1070CA'}}>Add Sub-Category</div>
								</div>
							</div>
						</div>

						{sku_sub_categories && sku_sub_categories.length?
							<div style={{height:'38px', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0px 20px', borderTop:'1px solid #f2f4f6', borderBottom:'1px solid #f2f4f6'}}>
								<div style={{flex:2}}>
									<div className='flex_property' style={{height:"100%", width:'100%', backgroundColor:'white', width:'100%', borderRadius:'2px'}}><i  style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input value={search_string ? search_string : ''} onChange={e => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Group Name' /></div>
								</div>
								<div className='flex_property' style={{flex:1, justifyContent:"flex-end"}}>
									<div title='Add New Group' style={{cursor:'pointer'}} onClick={show_add_new_group_modal} className='flex_property'>
										<div className='flex_property' style={{height:'12px', width:'12px',marginRight:"8px"}}><i style={{backgroundColor:'white', color:'#1070CA'}} className="fas fa-plus-circle"/></div>
										<div style={{fontSize:'12px', color:'#1070CA'}}>Add Group</div>
									</div>
									<div style={{border:'2px solid #F2F4F6', margin:'0px 20px', height:'25px', width:0}}></div>
									<div title='List View' onClick={() => set_content_view('list')} className='flex_property' style={{cursor:'pointer', height:'12px', width:'12px', marginRight:'13px'}}><i style={{color:content_view=='list' ? '#4D5969' : 'rgb(154, 165, 181)', fontSize:'12px'}} className='fa fa-bars'/></div>
									<div title='Card View' onClick={() => set_content_view('card')} className='flex_property' style={{cursor:'pointer', height:'12px', width:'12px'}}><i style={{color:content_view=='card' ? '#4D5969' : 'rgb(154, 165, 181)', fontSize:'12px'}} className="fa fa-th-large"></i></div>
								</div>
							</div>
							:<div style={{height:'38px'}}></div>
						} 
						<div id='all_cards_div' style={{height:'calc(100% - 88px)', width:'100%', overflow:'auto'}}>
								<div>{sku_groups_display && sku_groups_display.length ? 
									(<Group_Layout
										fetch_categories={fetch_categories}
										confirmation_alert={confirmation_alert}
										sku_categories={sku_categories}
										sku_sub_categories={sku_sub_categories}
										sku_sub_category_id={sku_sub_category_id}
										submit_for_delete_confirm={submit_for_delete_confirm}
										texture_json={texture_json}
										materials_json={materials_json}
										all_sales_channels={all_sales_channels}
										set_page_loader={set_page_loader}
										brands={brands} 
										fetch_material_template={fetch_material_template} 
										material_template={material_template} 
										set_material_template={set_material_template} 
										active_division_id={sku_division_id} 
										orignal_tags={orignal_tags} 
										sku_category_id={sku_category_id} 
										sku_category_name={sku_category_name} 
										sku_category_type={sku_category_type} 
										sku_groups={sku_groups_display} 
										user={user} 
										fetch_sku_groups={fetch_sku_groups} 
										Filler={Filler} 
										inventory_type="groups" 
										content_view={content_view} 
										border_animation_group = {border_animation_group}
										set_border_animation_group = {set_border_animation_group}
										set_border_animation_sub_cat = {set_border_animation_sub_cat}
										pre_selected_sku = {pre_selected_sku}
										set_pre_selected_sku = {set_pre_selected_sku}
										bulk_selection_mode = {bulk_selection_mode}
										bulk_selected_skus = {bulk_selected_skus}
										bulk_selected_grps = {bulk_selected_grps}
										set_bulk_selection_mode = {set_bulk_selection_mode}
										set_bulk_selected_skus = {set_bulk_selected_skus}
										set_bulk_selected_grps = {set_bulk_selected_grps}
										// update_bulk_selected_json = {update_bulk_selected_json}
										no_of_bulk_selected_skus_in_grp = {no_of_bulk_selected_skus_in_grp}
										set_no_of_bulk_selected_skus_in_grp = {set_no_of_bulk_selected_skus_in_grp}
										update_no_of_bulk_selected_skus_in_grp = {update_no_of_bulk_selected_skus_in_grp}
										no_of_bulk_selected_skus_in_subcat = {no_of_bulk_selected_skus_in_subcat}
										update_no_of_bulk_selected_skus_in_subcat = {update_no_of_bulk_selected_skus_in_subcat}
										send_analytics_event={send_analytics_event}
									/>) : (<Filler 
										initialized_grps={initialized_grps} 
										initialized1={initialized1} 
										fetching={fetching} 
										sub_cat_id = {sku_sub_category_id}
										sku_sub_categories = {sku_sub_categories}
										div_name='all_cards_div'
									/>)}
								</div>
						</div>
					</Card>
				</div>
			</div>
		</div>	
		</Main_Grid>
	);
}

export default SKU_Groups;

//onClick={onclick_group_afw}

//<SKU_Sub_Categories user={user} sku_category_id={sku_category_id} active_sku_group_id = {active_inventory_item} card_image_onclick_function={card_image_onclick_function} Filler={Filler}  />		

//<AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />
