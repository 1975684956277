import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import AlertBox from "../../components/AlertBox/";
import filter_duplicate from '../../utils/filter_duplicate';
import Multiselect from '../../components/Multiselect';

const FilterModal = ({ open ,handle_close, filter_category, set_filter_category ,set_default_filter_status,default_filter_status,set_current_filter_status,current_filter_status ,all_feature_flag,apply_onclick, all_feature_flags_orig}) => {
	
	// const update_project_name = async () => {
	// 	if(project_name){
	// 		try {
	// 			var identifiers = JSON.stringify({store_id : user.store_id , id:project_id})
	// 			var updates = JSON.stringify({name : project_name})
	// 			var body = {identifiers , updates}
	// 			var response = await general_fetch({ url: 'project/update', body});
	// 			set_current_state_alert("successfull")
	// 			set_open_alert_box(true)
	// 			console.log(response)
	// 			fetch_project_details();
	// 			handleClose();
	// 		} catch(err) { 
	// 			set_current_state_alert("fail")
	// 			set_open_alert_box(true) 
	// 			console.log(err);
	// 		}
	// 	}else{
	// 		set_current_state_alert("missing")
	// 		set_open_alert_box(true)
	// 	}	
	// }

	const apply_onclick_fn = () => {
		handle_close()
		apply_onclick()
	}

	const reset_fn = () => {
		set_default_filter_status('')
		set_filter_category([])
		set_current_filter_status('')
	}

	return (	
		<div style={{display: open?'block':'none', height:'320px', width:'340px', zIndex:'1', backgroundColor:'white', boxShadow: '2px 2px rgba(0, 0, 0, 0.2)', fontSize:'14px', textAlign:'left', borderRadius:'4px', border:'1px solid #f2f2f2'}}>
			<i style={{color:'white', position:'absolute', top:'-6px', right:'27px'}} className='fa fa-sort-asc' />
			<Row style={{margin:0}}>
				<Col className='flex_property' style={{justifyContent:'space-between', padding:"0px 20px", height:'50px', color:'#22272e' }}>
					<span style={{fontFamily:'Source_Sans_Pro-SemiBold'}}>Filters</span>
					<span><i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/></span>
				</Col>
			</Row>
			<div style={{borderTop:'1px solid #f2f2f2'}}></div>
			{/* <Row style={{margin:0}}>
				<Col style={{padding:"20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Name</label>
					<Input value={filter_name} onChange={(e) => set_filter_name(e.target.value)} style={{cursor:'pointer', fontSize:'14px', lineHeight:'30px', width:"100%" ,color:'#627386', backgroundColor:'white', border:'1px solid #627386', paddingLeft:0}} type='select'>
						<option value='all'>All</option>
						{
							all_feature_flag && all_feature_flag.length && filter_duplicate(all_feature_flag,'id').map((item,idx)=>
								<option key={idx} value={item.id}>{item.name}</option>
							)	
						}
					</Input>
				</Col>
			</Row>
			<div style={{borderTop:'1px solid #f2f2f2'}}></div> */}
			<Row style={{margin:0}}>
				<Col style={{padding:"20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Feature Category</label>
					<Multiselect
						style={{backgroundColor:'white'}}
						options={all_feature_flags_orig && all_feature_flags_orig.length? filter_duplicate(all_feature_flags_orig,'feature_category'):[]} 
						selectedValues={filter_category}
						onSelect={set_filter_category}
						onRemove={set_filter_category}
						displayValue="feature_category" // Property name
						id='select_Category'
						placeholder= 'Select Category'
					/>
				</Col>
			</Row>
			<Row style={{margin:0}}>
				<Col style={{padding:"0px 20px 20px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Current Status</label>
					<Input value={current_filter_status} onChange={(e) => set_current_filter_status(e.target.value)} style={{cursor:'pointer', fontSize:'14px', lineHeight:'30px', width:"100%" ,color:'#627386', backgroundColor:'white', border:'1px solid #627386', paddingLeft:0}} type='select'>
						<option value=''>None</option>
						<option value='enabled'>Enabled</option>
						<option value='disabled'>Disabled</option>
						<option value='disabled_for_designers'>Disabled For Designers</option>
						<option value='default'>Default</option>
					</Input>
				</Col>
			</Row>
			{/* <Row style={{margin:0}}>
				<Col style={{padding:"0px 20px 20px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
					<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Default Status</label>
					<Input value={default_filter_status} onChange={(e) => set_default_filter_status(e.target.value)} style={{cursor:'pointer', fontSize:'14px', lineHeight:'30px', width:"100%" ,color:'#627386', backgroundColor:'white', border:'1px solid #627386', paddingLeft:0}} type='select'>
						<option value=''>None</option>
						<option value='enabled'>Enabled</option>
						<option value='disabled'>Disabled</option>
					</Input>
				</Col>
			</Row> */}
			<div style={{borderTop:'1px solid #f2f2f2'}}></div>
			<Row style={{margin:0}}>
				<Col style={{padding:"24px 20px 26px", textAlign:"center"}}>
					<button onClick={reset_fn} style={{ border:0, height:'32px', width:"134px", backgroundColor:'#007ef4', borderRadius:'4px', textAlign:"center", color:'white', margin :'10px'}}><span style={{fontSize:'14px', fontFamily:'Roboto-Medium'}}>Reset</span></button>
					<button onClick={apply_onclick_fn} style={{ border:0, height:'32px', width:"134px", backgroundColor:'#007ef4', borderRadius:'4px', textAlign:"center", color:'white'}}><span style={{fontSize:'14px', fontFamily:'Roboto-Medium'}}>Apply</span></button>
				</Col>
			</Row>
		</div>	
	);
}

export default FilterModal ; 

// <AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />