export const COLORS = {
   
    gray1: '#FFFFFF',
    gray2: '#F5F6F8',
    gray3: '#E6E9EE',
    gray4: '#C5C7CF',
    gray5: '#A7A8B3',
    gray6: '#676878',
    gray7: '#323338',
    gray8: '#202126',

    blue1: '#E2EFFD',
    blue2: '#C2D6F3',
    blue3: '#D3EDFD',
    blue4: '#3686F7',
    blue5: '#3A63D4',
    blue6: '#0078FF',
    
  }