import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Multiselect from "../../components/Multiselect/";
import { primary_menu_options } from '../../components/Sidebar/menu_options';
import { useAlert } from 'react-alert'
import DeleteStatus from '../../components/DeleteStatus';

const StatusDialogBox = ({open, fetch_projectStatuses_details, handleClose, mode, project_statuses, idx, submit_for_delete_confirm}) => {

    const[project_status, set_project_status] = useState(null);
    const[project_status_id, set_project_status_id] = useState(null);
    // const[selected_status_id, set_selected_status_id] = useState(null);
    const [reassignment_project_status_id, set_reassignment_project_status_id] = useState(null);
	const alert = useAlert();

    // useEffect(() => {
    //     if(project_statuses) {
    //         set_project_status(project_statuses.status)
    //     }
    // }, [project_statuses])

    useEffect (() => {
        console.log(project_statuses, idx)
        if(project_statuses && project_statuses.length && idx>=0 && mode != 'add' && open)
        {
            console.log("useefeect idx")
            set_project_status_id(project_statuses[idx].id);
            set_project_status(project_statuses[idx].status);
            // set_selected_status_id(project_statuses[idx].status);
        }
    }, [idx, open, project_statuses])

    // useEffect (() => {
    //     console.log(project_status);
    // }, [project_status])

    useEffect(() => {
    
        console.log("project status id set" + project_status_id);
    },[project_status_id])

    const add_status = async() => {
        if(project_status) {
            try {
                var body = {status: project_status};
                var response = await general_fetch({url : 'project_status/add', body});
                alert.success("Status added successfully");
                fetch_projectStatuses_details();
                onclick_handleClose();
            } catch(err) {
                alert.error("Status addition failed!")
				console.log(err);
            }
        }else {
            alert.error("Status required")
        }
    }

    const update_status = async() => {
        if(project_status) {
            try {
                var body = {id: project_status_id, status: project_status};
                console.log("testing update");
                var response = await general_fetch({url : 'project_status/update', body});
                console.log("tested update");
                alert.success("Status updated successfully");
                fetch_projectStatuses_details();
                onclick_handleClose();
            } catch(err) {
                alert.error("Status updation failed!")
				console.log(err);
            }
        }else {
            alert.error("Status required")
        }
    }

    const remove_status = async(id, reassignment_project_status_id) => {
        try {
            var body = {id : id, reassignment_project_status_id : reassignment_project_status_id}
            console.log("testing delete status");
			console.log(body);
			var response = await general_fetch({ url: 'project_status/delete', body});
			alert.success("Project Status deleted successfully");
			fetch_projectStatuses_details();
        } catch(err) {
            alert.error("Request Fail")
			console.log(err);
        }
    }

    const onclick_handleClose = () => {
        set_project_status('');
        console.log("project_status "+project_status);
        handleClose();
    }

    return (
        <div>
            <Modal className="Margin_64px_top" size="sm" isOpen={open} toggle={onclick_handleClose}>
				<Card className='global_modal_height_for_xs'>
					<CardHeader className='global_modal_header'>
                    <span>
                        <span>{(mode == 'delete') ? "Select Status for Re-Asssignment"
                                :(mode=='add') ? "Enter Status" 
                                : "Update Status"}
                        </span>
                    </span>
                    <i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
                    </CardHeader>

                    <CardBody className='global_modal_body'>
                        <Row style={{marginBottom:'15px'}}>
                        {
                            mode=='delete' ?
                            <Col>
                                <div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Select Status*</div>
									<Input style={{opacity:mode==='add'?1:.5, width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select" 
										value={reassignment_project_status_id} onChange={e => {set_reassignment_project_status_id(e.target.value)}}
									>
										<option value=''>Select New Status</option>
										{
											project_statuses && project_statuses.length? project_statuses.map((item, idx) => (
                                                item.id != project_status_id?
												<option key={idx} value={item.id}>{item.status}</option>
                                                :''
											))	
											:''
										}
									</Input>
								</div>
                            </Col>
                            :
                            <Col>
                        		<div>
		                            <div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Status*</div>
									<Input style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Status" type="text" value={project_status} onChange={e => set_project_status(e.target.value)}/>
								</div>
                        	</Col>	
                        }
                        </Row>
                    </CardBody>

                    <CardFooter className='global_modal_footer'>
						<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
						{ 
                            mode == 'delete' ?
							<Button className='blue_button' disabled = {reassignment_project_status_id ? false : true} type="button" onClick={() => {
                                                                                        //   console.log("selected_status_id = " + selected_status_id);
                                                                                          console.log("reassignment_project_status_id = "+reassignment_project_status_id); 
                                                                                          onclick_handleClose();
                                                                                          submit_for_delete_confirm(()=>remove_status(project_status_id, reassignment_project_status_id), ()=>{}, null, null, null, DeleteStatus);
                                                                                          }}>select
                            </Button> : 
							mode == 'edit' ?
							<Button className='blue_button' type="button" onClick={update_status}>Update</Button> : 
							<Button className='blue_button' type="button" onClick={add_status}>Add</Button>
						}		
					</CardFooter>
                </Card>
            </Modal>
        </div>
    )
}


export default StatusDialogBox;