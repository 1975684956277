import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useAlert } from 'react-alert'

const PricingTermsConditions = ({tandc_row, row_index, update_tandc_row, delete_tandc_row, make_bold, set_make_bold}) => {
    const [is_bold_terms, set_is_bold_terms] = useState(false)
    const [is_bold_conditions, set_is_bold_conditions] = useState(false)
    // const [on_terms, set_on_terms] = useState(false)
    // const [on_conditions, set_on_conditions] = useState(false)

    useEffect(() => {
        if(tandc_row){
            set_is_bold_terms(tandc_row[0].bold)
            set_is_bold_conditions(tandc_row[1].bold)
        }
    },[tandc_row])

    return(
        <>
            <div style={{ marginBottom:'2%'}}>
                <Row>
                <span style = {{marginTop:'1.5%', marginLeft:'3%'}}>{row_index+1}.</span>
                <span className='textarea_container_terms'>
                    <textarea type='text' placeholder = 'Text...' onFocus={()=>set_make_bold(String(row_index)+'0')} style={{fontSize:'14px', fontWeight:tandc_row[0].bold?'1000':'50', paddingLeft:'5px', paddingRight:make_bold == String(row_index)+'0'?'15%':'5px'}} value = {tandc_row[0].text?tandc_row[0].text:''} onChange = {(e) => update_tandc_row(row_index,0,'text',e.target.value)}/>
                    {
                        make_bold == String(row_index)+'0'?
                        <span className='bold_icon' style={{background:tandc_row[0].bold?'rgba(38, 136, 247, 0.2)':''}} onClick={() => {let temp_bold_terms=!tandc_row[0].bold; set_is_bold_terms(temp_bold_terms);update_tandc_row(row_index,0,'bold',temp_bold_terms)}}><i className='fa fa-bold' style={{fontSize:'14px', marginLeft:'20%', top:'11%', position:'absolute', color:tandc_row[0].bold?'#2688F7':'grey'}}/></span>
                        :''
                    }
                </span>
                <span className='textarea_container_conditions'>
                    <textarea type='text' placeholder = 'Text...' onFocus={()=>set_make_bold(String(row_index)+'1')} style={{fontSize:'14px',fontWeight:tandc_row[1].bold?'1000':'50', paddingLeft:'5px' ,paddingRight:make_bold == String(row_index)+'1'?'7%':'5px'}} value = {tandc_row[1].text?tandc_row[1].text:''} onChange = {(e) => update_tandc_row(row_index,1,'text',e.target.value)}/>
                    {
                        make_bold == String(row_index)+'1'?
                        <span className='bold_icon' style={{background:tandc_row[1].bold?'rgba(38, 136, 247, 0.2)':''}} onClick={() => {let temp_bold_conditions=!tandc_row[1].bold; set_is_bold_conditions(temp_bold_conditions); update_tandc_row(row_index,1,'bold',temp_bold_conditions)}}><i className='fa fa-bold' style={{fontSize:'14px', marginLeft:'20%', top:'11%', position:'absolute', color:tandc_row[1].bold?'#2688F7':'grey'}}/></span>
                        :''
                    }
                </span>
                <i style={{fontSize:'14px', color:'red', cursor:'pointer', marginTop:'5.2%', marginLeft:'2.3%'}} className='fa fa-trash' onClick={()=>{console.log("delete", row_index) ;delete_tandc_row(row_index)}}/>
                </Row>
            </div>
            {/* <div className='display_onhover x1 on_hover_option_1 on_hover_option_2'></div> */}
        </>

    ) 
}

export default PricingTermsConditions;