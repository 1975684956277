import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import CategoryTypeDialogBox from'./CategoryTypeDialogBox'
import {Card, Col, Progress, Table, Row, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';

const CategoryType = ({user, submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details}) => {
	const styles = {
		add_brand_style:{
			backgroundColor: '#007EF4',
			boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
			borderRadius: '3px',
			height:'32px',
			padding:'0px 24px',
			border:'0px',
			color:'white',
		},
		table_header: {
			fontWeight: "bold",
			fontSize: 'small'
		},
    };
	//console.log('BRANDPAGE====>CALLED')
	const [category_type, set_category_type ] = useState([]);
	const [show_category_type_Dialog_Box, set_show_category_type_Dialog_Box] = useState(false);
	const [active_category_type, set_active_category_type] = useState('');
	const [search_string , set_search_string] = useState('');
	const [display_category_type , set_display_category_type] = useState([]);
	const [key , set_key] = useState('');

	const [page_size , set_page_size] = useState(30)
	const [fetch_progress , set_fetch_progress] = useState(40)
	const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');
	
	const fetch_category_type_details = async() => {
		// if(sku_division_id){
			try {
				// var identifiers = JSON.stringify({'sku_division_id':sku_division_id});
				var response = await general_fetch({ url: 'sku_category_type/get'});
				// var resp = response.sort(name_sort)split_cat_type
				var resp = split_cat_type(response).sort(name_sort)
				set_category_type(resp);
				set_display_category_type(resp)
			} catch(err) {
				console.log(err);
			}
		// }
	}

	// useEffect(() => {
	// 	console.log('brand======>',category_type);
	// }
	// , [category_type]);

	useEffect(() => {
		if(!category_type.length){
			fetch_category_type_details();
		}
	}
	, [user]);


	const open_category_type_update_dialog = (CT_item) => {
		set_show_category_type_Dialog_Box(true);
		set_active_category_type(CT_item);
		set_key('edit');
	}

	const close_category_type_Dialog_Box = () => {
		set_show_category_type_Dialog_Box(false);
		set_active_category_type('');
		set_key('');
	}

	const open_category_type_add_dialog = () => {
		set_show_category_type_Dialog_Box(true);
		set_key('add');
	}
	
	const filter_category_type = () => 
	{
		var filtered_brands = [...category_type];
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_brands = filtered_brands.filter( brand => brand.display_name.toLowerCase().includes(lower_search_string))
		}
		set_display_category_type(filtered_brands)
	}

	useEffect(() => {
			filter_category_type();
	}, [search_string]);

	useEffect(() => {
		set_active_tab('category_type')
		send_analytics_event('auto', 'category_type', 'category_type', 1, window.location.href)
		// check_route_fn('category_type')
	}, []);

	useEffect(() => {
		if (initialized) {
			var pages = Number(Math.ceil(display_category_type.length / page_size));
			//console.log('all pages --> ', pages, 'projects length --<>', display_category_type.length)
			var tmp = Array.apply('', {length: pages + 1}).map(Number.call, Number)
			tmp.splice(0, 1);
			//console.log('tmp --<>', tmp)
			set_all_pages(tmp);
			//console.log('tmp spliced--<>', tmp)
			set_current_page(1);
		}
	}, [ page_size, display_category_type ])

	const Category_type_Filler = () => (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					{ initialized ? (
						<th scope="col"><span style={styles.table_header}><b>No Category Type to list  </b></span><br/> Try adjusting filters...<br /> &nbsp;</th>
					):(
						<th scope="col"><span style={styles.table_header}><b>Fetching all Category Type </b></span><br/> <br /> Please wait...<br /> <br />
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;</th>
					)}
				</tr>
			</thead>
		</Table>
	);

	const Category_type_Table = () => (
		<table className="text-left flex_property table_style">
			<thead style={{width:'100%'}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',width:'100%'}}>
				<th className='th_style flex_property' style={{flex:5}}><span>Category Type Name</span></th>
				<th className='th_style flex_property' style={{flex:3}}><span>Division ID</span></th>
				<th className='th_style flex_property' style={{flex:5}}><span>Created at</span></th>
				<th className='th_style flex_property' style={{border:0, flex:5}}><span>Last Modified</span></th>
				<th className='th_style flex_property' style={{border:0, flex:1, paddingRight:"20px"}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{display:"block",width:'100%'}}>
			{
				display_category_type && display_category_type.length && display_category_type.map((CT_item, index) => (
				<tr key={index} style={{display:(index+1>=start_index && index<end_index)?'flex':'none'}} className='tr_style display_onhover'>
					
					<td className='td_style flex_property' style={{flex:5}}>{CT_item.display_name}</td>
					<td className='td_style flex_property' style={{flex:3}}>{CT_item.sku_division_id}</td>
					<td className='td_style flex_property' style={{flex:5}}>{CT_item.created_at}</td>
					<td className='td_style flex_property x1' style={{flex:5}}>{CT_item.updated_at}</td>
					<td className='td_style flex_property x1' style={{flex:'1', paddingRight:"20px"}}>
						<span className='hide'><i onClick ={() => open_category_type_update_dialog(CT_item)} className='fa fa-edit'/></span>
					</td>
				</tr>
			))}
			</tbody>
		</table>
	);

	return (
		<Main_Grid user={user} store_details={store_details} get_store_details={get_store_details}>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_category_type && display_category_type.length ?display_category_type.length:'0'} Category Type
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick={open_category_type_add_dialog} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Category Type</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Rule Name' /></div>
						<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_category_type && display_category_type.length ? (<Category_type_Table />) : (<Category_type_Filler />)}</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        <Row>
		        <Col>	
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_category_type && display_category_type.length ? display_category_type.length : '0'}
					/>
		        </Col>
	        </Row>
			<CategoryTypeDialogBox open = {show_category_type_Dialog_Box} handleClose = {close_category_type_Dialog_Box} active_category_type={active_category_type} user= {user} fetch_category_type_details = {fetch_category_type_details} mode = {key}/>
		</Main_Grid>
	);
}	


export default CategoryType;

