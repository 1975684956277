import React , { useState, useEffect } from 'react';
import { Card, Col, Progress, Table, Row, Modal, CardHeader, CardBody, CardFooter, Button, Input } from "reactstrap";
import { useAlert } from 'react-alert'

import Main_Grid from '../../components/Main_Grid/';
// import Multiselect from "../../components/Multiselect/";
import Multiselect from 'multiselect-react-dropdown';


import general_fetch from '../../utils/fetch';
import name_sort from '../../utils/name_sort';
import split_cat_type from '../../utils/split_cat_type';
import { act } from '@testing-library/react';
import {Tooltip} from 'antd'

const valid_value_types = ['integer', 'string', 'enum', 'boolean', 'sku_category_type_id','list', 'sku_id'];
let sku_ids_set = new Set([])


//generate a random 20 charecter id
const generate_id = () => {
	const id_length = 20;
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let text = "";
	for (let i = 0; i < id_length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


const convert_to_readable = (str) => {
    //snake case
    if (!str)   return '';
    if (str.includes('_')) {
        return str.replace(/_/g, ' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
    //camel case
    let words = str.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
}

const Edit_SalesChannel_preference_Modal = ({active_sales_channel, hierarchy, open, handle_close, category_types, leaf_data, fetch_all_level_preferences}) => {

    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [ value, set_value ] = useState('');
    const [ sales_channel_level_value, set_sales_channel_level_value] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ value_options, set_value_options ] = useState('');
    const [ category_type_map, set_category_type_map ] = useState({});
    const alert = useAlert()

    const set_sales_channel_level_pref = async() => {
        if(active_sales_channel){
            try{
                let design_preference_id_and_values = [{designing_preference_id: id, value: sales_channel_level_value}]
                let resp = await general_fetch({url:"designing_preference/set_sales_channel_preference", body:{sales_channel_id:active_sales_channel.id, design_preference_id_and_values:design_preference_id_and_values}})
                if(resp) alert.success('Updation Successful!')
                fetch_all_level_preferences()
                handle_close()
            }catch(err){
                alert.error('Update Failed!')
            }
        }
    }

    useEffect(() => {
        console.log('values ---> ', value);
    }, [ value ])


    useEffect(() => {
        console.log('ssss')
        if(leaf_data && category_type_map && Object.keys(category_type_map).length && open) {
            console.log('ssss2', category_type_map);
            set_id(leaf_data.id);
            set_name(leaf_data.display_name);
            let temp_value_options = leaf_data.value_options;
            let temp_value_options_map = {};

            if (leaf_data.value_type === 'sku_category_type_id') {
                console.log('ssss3', leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master, JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')).map(x => category_type_map[x]));
            } else if (leaf_data.value_type === 'list') {
                temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x }));
                temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => temp_value_options_map[x]));
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')).map(x => temp_value_options_map[x]))
            } else {
                set_value(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master);
                set_sales_channel_level_value(((leaf_data.value ? (leaf_data.value['sales_channel'] ? (leaf_data.value['sales_channel'][active_sales_channel.id] ? leaf_data.value['sales_channel'][active_sales_channel.id]: (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])) : (leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master'])):'')))
            }
            set_value_type(leaf_data.value_type);
            if (leaf_data.value_type === 'list') {
                set_value_options(temp_value_options);
            } else {
                set_value_options(leaf_data?.value_options ?? []);
            }
        }
    }, [ leaf_data, category_type_map, open ]);


    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);

    return(
        <Modal className="Margin_64px_top" isOpen={open} size={value_type == 'list' || value_type == 'sku_category_type_id' ? "lg" : "sm"} toggle={handle_close}>
            <Card>
                <CardHeader className='global_modal_header'>
                    <span>
                        Edit Preference at Sales Channel Level
                    </span>
                    <i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
                </CardHeader>
                <CardBody className='global_modal_body' style={{ height: 'auto', overflow: 'unset' }}>
                    {value_type == 'integer' || value_type == 'string' ? (
                        <>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input disabled={true} type="text" className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                            <span style={{}}>{'Sales Channel Level ('}<span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row>
                                <Col>
                                    <input type="text" className="form-control" placeholder="Value" value={sales_channel_level_value} onChange={(e) => set_sales_channel_level_value(e.target.value)}/>
                                </Col>
                            </Row>
                        </>
                    ) : ( value_type == 'enum' || value_type == 'boolean' ? (
                        <>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <input disabled={true} type="text" className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                            <span style={{}}>{'Sales Channel Level ('}<span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row>
                                <Col>
                                    <select className="form-control" value={sales_channel_level_value} onChange={(e) => set_sales_channel_level_value(e.target.value)}>
                                        {value_type == 'enum' ? value_options.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        )) : ['true', 'false'].map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </>
                    ) : ( value_type == 'list' || value_type == 'sku_category_type_id' ? (
                        <div style={{ overflowY: 'visible' }}>
                            <Row>
                                <Col>
                                    <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                        {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                    </span>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{marginTop:'15px', fontSize:'15px', marginBottom:'5px'}}>
                                <Col>Organization Level (Default)</Col>
                            </Row>
                            <Row style={{paddingBottom: 50, overflowY: 'visible'}}>
                                <Col>
                                    <input disabled={true} type="text" className="form-control" placeholder="Value" value={value} />
                                </Col>
                            </Row>
                            <span style={{marginTop:'10px', fontSize:'15px', display:'flex', marginBottom:'5px'}}>
                                <span style={{}}>{'Sales Channel Level ('} <span>{active_sales_channel?active_sales_channel.name:''}</span>{')'}</span>
                            </span>
                            <Row style={{paddingBottom: 50, overflowY: 'visible'}}>
                                <Col>
                                    <Multiselect
                                        key={'select_value' + id}
                                        style={{ backgroundColor:'white', width:'147px' }}
                                        options={value_type == 'list' ? value_options : category_types}
                                        selectedValues={sales_channel_level_value ? sales_channel_level_value : []}
                                        onSelect={x => { console.log('setting selected value 000  >>>>', x);set_sales_channel_level_value(x); }}
                                        onRemove={set_sales_channel_level_value}
                                        displayValue='display_name'
                                        closeOnSelect={false}
                                        avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                </Col>
                            </Row>
                        </div>              
                    ) : <></> ) )}                
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button className='blue_button' style={{margin:0}} type="button" onClick={() => {set_sales_channel_level_pref()}} > Update </Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

const Leaf_Edit_Modal = ({ leaf_data, hierarchy, open, handle_close, fetch_all_level_preferences, category_types, sku_data_json, all_division_tree_data, set_page_loader, link_store_level_to_master, resync_store_level_to_master, out_of_sync_preferences }) => {

    const [ id, set_id ] = useState('');
    const [ name, set_name ] = useState('');
    const [ value, set_value ] = useState('');
    const [ value_type, set_value_type ] = useState('');
    const [ value_options, set_value_options ] = useState('');
    const [ category_type_map, set_category_type_map ] = useState({});
    const [categories, set_categories] = useState([]);
    const [sub_categories, set_sub_categories] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);
    const [selected_category, set_selected_category] = useState();
    const [selected_sub_category, set_selected_sub_category] = useState();
	const [selected_group, set_selected_group] = useState();
	const [selected_sku, set_selected_sku] = useState();
    const [selected_division, set_selected_division] = useState();
    const [unlinked_from_master, set_unlinked_from_master] = useState();
    const alert = useAlert()


    const handle_submit = async () => {
        try {
            let value_temp = value;
            if (value_type == 'list') {
                value_temp = JSON.stringify(value.map(x => x.display_name));
            } else if (value_type == 'sku_category_type_id') {
                value_temp = JSON.stringify(value.map(x => x.id));
            }else if(value_type == 'sku_id'){
                if(selected_division && selected_category && selected_sub_category && selected_group && selected_sku){
                    value_temp = selected_sku
                }else{
                    alert.error('Please select all the fields')
                    return
                }
            }
            set_page_loader({
                show:true,
                text: 'Updating preference...'
            })
            let resp = await general_fetch({
                url: 'designing_preference/set_store_preference',
                body: { design_preference_id_and_values: [{ designing_preference_id: id, value: value_temp }] }
            }); 
            set_page_loader({show:false})
            if(unlinked_from_master){
                alert.success('Preference updated successfully !')
            }else{
                alert.success('Preference updated successfully and unlinked from Infurnia default !')
            }
            fetch_all_level_preferences();
            // handle_close();
            console.log(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const set_categories_fn = (division_id) => {
        let division = all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.find(o => o.id == division_id):''
        set_categories(division ? division.sku_category : [])
    }

    const set_sub_categories_fn = (category_id) => {
        let category = categories && categories.length ? categories.find(o => o.id == category_id):''
        set_sub_categories(category ? category.sku_sub_category : [])
    }

    const get_groups = async(subcat_id) => {
		if(subcat_id){
            try{
                set_page_loader({
                    show:true,
                    text:'fetching groups...'
                })
                let resp = await general_fetch({url:'inventory/get_groups',  body: { sku_sub_category_id:subcat_id}})
                set_groups(resp)
                set_page_loader({
                    show:false
                })
            }catch(err){
                console.log(err)
            }
        }
	}

	const set_skus_fn = (group_id) => {
		let group = groups && groups.length ? groups.find(o => o.id === group_id) : ''
        console.log('skus set', group)
		set_skus(group && group.sku ? group.sku : [])
	}


    useEffect(() => {
        if(open && leaf_data.value_type == 'sku_id' && sku_data_json && sku_data_json[leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']]){
            let sku_id = leaf_data.value['store'] ? leaf_data.value['store'] : leaf_data.value['master']
            
            set_categories_fn(sku_data_json[sku_id].sku_division_id)

            
            // set_sub_categories_fn(sku_data_json[sku_id].sku_category_id)
            
            get_groups(sku_data_json[sku_id].sku_sub_category_id)
            
            // set_skus_fn(sku_id)
            

            set_selected_division(sku_data_json[sku_id].sku_division_id)
            set_selected_category(sku_data_json[sku_id].sku_category_id)
            set_selected_sub_category(sku_data_json[sku_id].sku_sub_category_id)
            set_selected_group(sku_data_json[sku_id].sku_group_id)
            set_selected_sku(sku_id)
        }
    }, [sku_data_json, leaf_data, open]);

    useEffect(() => {
        if(categories && categories.length && selected_category){
            set_sub_categories_fn(selected_category)
        }
    }, [categories, selected_category]);

    useEffect(() => {
        if(groups && groups.length && selected_group){
            set_skus_fn(selected_group)
        }
    }, [groups, selected_group]);


    useEffect(() => {
        console.log('ssss')
        if(leaf_data && category_type_map && Object.keys(category_type_map).length && open) {
            console.log('ssss2', unlinked_from_master, leaf_data);
            set_id(leaf_data.id);
            set_name(leaf_data.display_name);
            
            if(leaf_data && leaf_data.value && leaf_data.value.store){
                set_unlinked_from_master(true)
            }else{
                set_unlinked_from_master(false)
            }

            let temp_value_options = leaf_data.value_options;
            let temp_value_options_map = {};

            if (leaf_data.value_type === 'sku_category_type_id') {
                console.log('ssss3', leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master, JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => category_type_map[x]));
            } else if (leaf_data.value_type === 'list') {
                temp_value_options = temp_value_options.map(x => ({ id: generate_id(), display_name: x }));
                temp_value_options_map = temp_value_options.reduce((final, elem) => ({...final, [elem.display_name]: elem }), {});
                set_value(JSON.parse(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master).map(x => temp_value_options_map[x]));
            } else {
                set_value(leaf_data.value['store'] ? leaf_data.value.store : leaf_data.value.master);
            }
            set_value_type(leaf_data.value_type);
            if (leaf_data.value_type === 'list') {
                set_value_options(temp_value_options);
            } else {
                set_value_options(leaf_data?.value_options ?? []);
            }
        }
    }, [ leaf_data, category_type_map, open ]);


    useEffect(() => {
        if (category_types && category_types.length) {
            set_category_type_map(category_types.reduce((final, elem) => ({...final, [elem.id]: elem }), {}));
        }
    }, [ category_types ]);

    const Link_sync_buttons = () => (
        <div className='flex_property' style={{marginBottom:'20px'}}>
        {
            id && out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == id)?
            <div onClick={() => resync_store_level_to_master(leaf_data.id)} className='flex_property' style={{backgroundColor:'white', border:'1px solid gray', padding:'4px 4px 4px 8px', borderRadius:'4px', fontSize:'14px', cursor:'pointer', width:'200px', marginRight:'12px'}}>
                <img style={{width:'20px', height:'24px', cursor:'pointer', marginRight:'10px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                <div>Sync with Infurnia default</div>
            </div>:''
        }
        {   
            leaf_data && leaf_data.value && leaf_data.value.store ? 
            <div onClick={() => link_store_level_to_master(leaf_data.id)} className='flex_property' style={{backgroundColor:'white', border:'1px solid gray', padding:'4px 4px 4px 8px', borderRadius:'4px', fontSize:'14px', cursor:'pointer', width:'198px'}}>
                <img style={{width:'20px', height:'24px', cursor:'pointer', marginRight:'10px'}} src={'/assets/img/icons/link_icon_black.svg'}/>
                <div>Link with Infurnia default</div>
            </div>:''
        }
        </div>
    )

    
    return (
        <div>
		  	<Modal className="Margin_64px_top" isOpen={open} size={value_type == 'list' || value_type == 'sku_category_type_id' || value_type == 'sku_id' ? "lg" : "sm"} toggle={handle_close}>
				<Card>
					<CardHeader className='global_modal_header'>
						<span>
							Edit Preference
						</span>
						<i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/>
					</CardHeader>
					<CardBody className='global_modal_body' style={{ height: 'auto', overflow: 'unset' }}>
                        {value_type == 'integer' || value_type == 'string' ? (
                            <>
                                <Row style={{marginBottom:'20px'}}>
                                    <Col>
                                        <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                            {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                    </Col>
                                </Row>
                                {/* <hr/> */}
                                <Link_sync_buttons/>
                                <Row>
                                    <Col style={{flex:'5'}}>
                                        <input type="text" className="form-control" placeholder="Value" value={value} onChange={(e) => set_value(e.target.value)}/>
                                    </Col>
                                    <Col style={{flex:'1'}} className='flex_property'>
                                    {
                                        leaf_data && leaf_data.max_level == 'master'?'':
                                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                                    }
                                    </Col>
                                </Row>
                            </>
                        ) : ( value_type == 'enum' || value_type == 'boolean' ? (
                            <>
                                <Row style={{marginBottom:'20px'}}>
                                    <Col>
                                        <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                            {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                    </Col>
                                </Row>
                                {/* <hr/> */}
                                <Link_sync_buttons/>
                                <Row>
                                    <Col style={{flex:'5'}}>
                                        <select className="form-control" value={value} onChange={(e) => set_value(e.target.value)}>
                                            {value_type == 'enum' ? value_options.map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            )) : ['true', 'false'].map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col style={{flex:'1'}} className='flex_property'>
                                    {
                                        leaf_data && leaf_data.max_level == 'master'?'':
                                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                                    }
                                    </Col>
                                </Row>
                            </>
                        ) : ( value_type == 'list' || value_type == 'sku_category_type_id' ? (
                            <div style={{ overflowY: 'visible' }}>
                                <Row style={{marginBottom:'20px'}}>
                                    <Col>
                                        <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                            {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {convert_to_readable(value_type)}</div>
                                    </Col>
                                </Row>
                                {/* <hr/> */}
                                <Link_sync_buttons/>
                                <Row style={{paddingBottom: 50, overflowY: 'visible'}}>
                                    <Col xs={10}>
                                        <Multiselect
                                            key={'select_value' + id}
                                            style={{ backgroundColor:'white', width:'147px' }}
                                            options={value_type == 'list' ? value_options : name === 'highlight_in_pricing_quotation' ? category_types.filter(x => x.sku_division_id === 'hardware' || x.sku_division_id === 'accessory') : category_types}
                                            selectedValues={value ? value : []}
                                            onSelect={x => { console.log('setting selected value 000  >>>>', x);set_value(x); }}
                                            onRemove={set_value}
                                            displayValue='display_name'
                                            closeOnSelect={false}
                                            avoidHighlightFirstOption={true}
                                            showCheckbox
                                        />
                                    </Col>
                                    <Col xs={2} className='flex_property'>
                                    {
                                        leaf_data && leaf_data.max_level == 'master'?'':
                                        <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                                    }
                                    </Col>
                                </Row>
                            </div>              
                        ) : (value_type === 'sku_id' ? (
                            <>
                               <Row style={{marginBottom:'20px'}}>
                                    <Col>
                                        <span className='global_modal_label' style={{ fontFamily: 'Source_Sans_Pro-SemiBold'}}>
                                            {hierarchy.reduce((final, elem) => final + convert_to_readable(elem) + ' > ', '')} {convert_to_readable(name)}
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <div style={{ width: '100%', backgroundColor: "rgb(231 236 241)", padding: 4, borderRadius: 4, color: "#1070CA", display: 'inline', paddingLeft: 10, paddingRight: 10 }}> {'SKU'}</div>
                                    </Col>
                                </Row> 
                                <Link_sync_buttons/>
                                <div style={{margin:'24px 0px 4px 0px', fontSize:'14px'}}>
                                    <div className='flex_property' style={{}}>
                                        <div style={{flex:'3'}}>Division</div>
                                        <div style={{flex:'3'}}>Category</div>
                                        <div style={{flex:'3'}}>Sub Category</div>
                                        <div style={{flex:'3'}}>Group</div>
                                        <div style={{flex:'3'}}>Sku</div>
                                        <div style={{flex:'2'}}></div>
                                    </div>
                                    <div className='flex_property' style={{}}>
                                    <div style={{display:'flex', alignItems:'center', marginTop:'12px', width:'100%'}}>
                                        <div style={{flex:'3'}}>
                                            <Input type='select' value={selected_division} onChange={(e) => {set_selected_division(e.target.value); set_categories_fn(e.target.value); set_selected_category(''); set_selected_sub_category(''); set_selected_group(''); set_selected_sku('')}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
                                                <option value=''>Select Division</option>
                                            {
                                                all_division_tree_data && all_division_tree_data.length ? all_division_tree_data.map((division, idx) => (
                                                    <option value = {division.id}>{convert_to_readable(division.id)}</option>
                                                )):''
                                            }
                                            </Input>
                                        </div>
                                        <div style={{flex:'3'}}>
                                            <Input type='select' value={selected_category} onChange={(e) => {set_selected_category(e.target.value);/*set_sub_categories_fn(e.target.value);*/ set_selected_sub_category(''); set_selected_group(''); set_selected_sku('')}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
                                                <option value=''>Select Category</option>
                                            {
                                                categories && categories.length ? categories.map((single_category, idx) => (
                                                    <option value={single_category.id}>{single_category.name}</option>
                                                )):""
                                            }
                                            </Input>
                                        </div>
                                        <div style={{flex:'3'}}>
                                            <Input type='select' value={selected_sub_category} onChange={(e) => {set_selected_sub_category(e.target.value);get_groups(e.target.value); set_selected_group(''); set_selected_sku('')}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
                                                <option value=''>Select Sub Category</option>
                                            {
                                                sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
                                                    <option value={single_sub_category.id}>{single_sub_category.name}</option>
                                                )):""
                                            }
                                            </Input>
                                        </div>
                                        <div style={{flex:'3'}}>
                                            <Input type='select' value={selected_group} onChange={(e) => {set_selected_group(e.target.value); /*set_skus_fn(e.target.value);*/ set_selected_sku('')}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
                                                <option value=''>Select Group</option>
                                            {
                                                groups && groups.length ? groups.map((single_group, idx) => (
                                                    <option value={single_group.id}>{single_group.name}</option>
                                                )):""
                                            }
                                            </Input>
                                        </div>
                                        <div style={{flex:'3'}}>
                                            <Input type='select' value={selected_sku} onChange={(e) => set_selected_sku(e.target.value)} style={{height:'32px', fontSize:'12px', width:'90%'}}>
                                                <option value=''>Select Sku</option>
                                            {
                                                skus && skus.length ? skus.map((single_sku, idx) => (
                                                    <option value={single_sku.id}>{single_sku.name}</option>
                                                )):""
                                            }
                                            </Input>
                                        </div>
                                        <div style={{flex:'2'}}>
                                        {
                                            leaf_data && leaf_data.max_level == 'master'?'':
                                            <Button className='blue_button' style={{margin:0, height:'32px'}} type="button" onClick={handle_submit}> {unlinked_from_master ? 'Update' : 'Update/Unlink'} </Button>
                                        }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </>
                    ):'')))}      
					</CardBody>
					<CardFooter className='global_modal_footer'>
						<Button className='white_button' style={{marginRight:'0px'}} type="button" onClick={handle_close}> Close </Button>
					</CardFooter>
				</Card>
			</Modal>
		</div>
    );
}
    

const traverse_json = (json, hierarchy) => {
    if (hierarchy.length == 0) {
        return json;
    }
    var key = hierarchy.shift();
    if (json[key]) {
        return traverse_json(json[key], hierarchy);
    }
    return null;
}


const determine_visibility = (json, hierarchy, visible) => {
    if (hierarchy.length == 0) {
        // console.log("determine visi", hierarchy)
        Object.keys(json).map(key => {
            // console.log('determine vis key', visible, json[key]?json[key].value_type:undefined)
            if (!visible && json[key] && json[key].value_type) {
                visible = json[key].value_type && valid_value_types.includes(json[key].value_type) && json[key].visible ? true : false;
                // console.log('determine vis key 1',json[key], visible)
            }
        });
        return {json, visible};
    }
    let key = hierarchy.shift();
    // console.log("determine vis", key, hierarchy)
    if (json[key]) {
        let { json:new_json, visible:new_visible } = determine_visibility(json[key], hierarchy, visible);
        return { json: new_json, visible: new_visible };
    }
    return {visible, json};
}


const Leaf_Editor = ({ current_key, preference_json, hierarchy, fetch_all_level_preferences, category_types, sales_channels, sales_channel_level_prefs, fetch_sales_channel_level_prefs, sku_data_json, all_division_tree_data, set_page_loader, out_of_sync_preferences }) => {
    
    const [ current_json, set_current_json ] = useState({});
    const [ update_leaf_modal_show, set_update_leaf_modal_show ] = useState(false);
    const [sales_channel_collapse, set_sales_channel_collapse] = useState(true)
    const [show_sales_channel_pref_modal, set_show_sales_channel_pref_modal] = useState(false)
    const [active_sales_channel, set_active_sales_channel] = useState(null);
    const alert = useAlert()

    const resync_store_level_to_master = async(designing_preference_id) =>{
        if(designing_preference_id){
            try{
                set_page_loader({
                    show:true,
                    text: 'Syncing with infurnia default ...'
                })
                let resp = await general_fetch({url:'designing_preference/resync_store_level_to_master', body:{designing_preference_ids:[designing_preference_id]}})
                set_page_loader({show:false})
                if(resp) alert.success('Synced with Infurnia default value successfully !')
                fetch_all_level_preferences()
            }catch(err){
                console.log(err)
                alert.error('Syncing with Infurnia default value failed !')
            }
        }
    }

    const link_store_level_to_master = async(designing_preference_id) => {
        if(designing_preference_id){
            try{
                set_page_loader({
                    show:true,
                    text: 'Linking with infurnia default ...'
                })
                let resp = await general_fetch({url:'designing_preference/link_store_level_to_master', body:{designing_preference_ids:[designing_preference_id]}})
                set_page_loader({show:false})
                if(resp) alert.success('Linked with Infurnia default value successfully !')
                fetch_all_level_preferences()
            }catch(err){
                console.log(err)
                alert.error('Linking with Infurnia default value failed !')
            }
        }
    }
    
    useEffect(() => {
        set_current_json(traverse_json(preference_json, [ ...hierarchy, current_key ]));
        console.log("sales channel", sales_channels, current_key, traverse_json(preference_json, [ ...hierarchy, current_key ]))
    }, [ preference_json, hierarchy ]);

    useEffect(() => {
        console.log('sku_data_json', sku_data_json)
    }, [sku_data_json]);
    
    return current_json ? (
        <div>
            <Leaf_Edit_Modal
                leaf_data={current_json}
                open={update_leaf_modal_show}
                hierarchy={hierarchy}
                handle_close={() => set_update_leaf_modal_show(false)}
                fetch_all_level_preferences={fetch_all_level_preferences}
                category_types={category_types}
                sku_data_json = {sku_data_json}
                all_division_tree_data = {all_division_tree_data}
                set_page_loader = {set_page_loader}
                link_store_level_to_master = {link_store_level_to_master}
                resync_store_level_to_master = {resync_store_level_to_master}
                out_of_sync_preferences = {out_of_sync_preferences}
            />
            <Edit_SalesChannel_preference_Modal
                open = {show_sales_channel_pref_modal}
                handle_close = {() => set_show_sales_channel_pref_modal(false)}
                active_sales_channel = {active_sales_channel}
                hierarchy = {hierarchy}
                leaf_data = {current_json}
                category_types = {category_types}
                fetch_all_level_preferences = {fetch_all_level_preferences}
                sku_data_json = {sku_data_json}
                all_division_tree_data = {all_division_tree_data}
            />
            <div style={{ textAlign: 'left', backgroundColor: "white", color: "#1070CA",borderBottom: "1px solid rgb(242, 244, 246)", height:'40px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <div style={{flex:'5', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                    {convert_to_readable(current_json.display_name)}
                </div>
                <div className='flex_property' style={{flex:'4', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,justifyContent:'space-between'}}>
                    {['list', 'sku_category_type_id', 'sku_id'].includes(current_json.value_type) ? ((current_json.value_type == 'sku_id' && sku_data_json && sku_data_json[current_json.value['store'] ? current_json.value['store'] : current_json.value['master']]) ? sku_data_json[current_json.value['store'] ? current_json.value['store'] : current_json.value['master']].name : current_json.value_type) : (current_json.value ? (current_json.value['store'] ? current_json.value['store'] : current_json.value['master']):'')}
                    {current_json.max_level == 'sales_channel'? 
                        <div>
                            {
                                out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == current_json.id)?
                                <Tooltip title='Sync with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'18px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                                </Tooltip>:''
                            }
                            {
                                current_json.value && current_json.value.store?
                                <Tooltip title='Link with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/unlinked_with_global.svg'}/>
                                </Tooltip>:
                                <Tooltip title='Linked with Infurnia default' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/linked_with_global.svg'}/>
                                </Tooltip>
                            }
                            <i style={{cursor:'pointer', color: "#1070CA" }} onClick={() => set_update_leaf_modal_show(true)} className='fa fa-edit'/>
                        </div>
                        :''}
                </div>
                <div className='flex_property' style={{flex:'6',fontSize:'14px', color:'black',borderLeft:current_json.max_level == 'sales_channel'?'1px solid rgb(242, 244, 246)':'', height:'100%'}}>
                {
                    current_json.max_level == 'sales_channel'?                    
                        <span style={{cursor:'pointer'}} onClick={() => set_sales_channel_collapse(!sales_channel_collapse)} >
                            <span style={{ float: 'left',margin:'4px 10px 3px 10px', color:'#0078FF'}} className={sales_channel_collapse ? 'fa fa-angle-down' : 'fa fa-angle-up'} ></span> 
                            <span style={{marginLeft:'5px',color:'#0078FF'}}>Sales channel specific values</span>
                        </span>
                    :''
                }
                </div>
                <div style={{flex:'2', display:'flex', justifyContent:'flex-end', marginRight:'10px',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                {
                    current_json.max_level == 'sales_channel'? <span>{'\xa0\xa0\xa0\xa0\xa0\xa0'}</span>:
                        <span>
                            {
                                out_of_sync_preferences && out_of_sync_preferences.length && out_of_sync_preferences.find(o => o.designing_preference_id == current_json.id)?
                                <Tooltip title='Sync with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'18px'}} src={'/assets/img/icons/sync_icon_black.svg'}/>
                                </Tooltip>:''
                            }
                            {
                                current_json.value && current_json.value.store?
                                <Tooltip title='Link with Infurnia default in the edit modal' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/unlinked_with_global.svg'}/>
                                </Tooltip>:
                                <Tooltip title='Linked with Infurnia default' placement='bottom' mouseEnterDelay={0.5}>
                                    <img style={{width:'24px', height:'22px', cursor:'pointer', marginRight:'20px'}} src={'/assets/img/icons/linked_with_global.svg'}/>
                                </Tooltip>
                            }
                            <span style={{cursor:'pointer', color: "#1070CA" }} onClick={() => set_update_leaf_modal_show(true)} className='fa fa-edit'/>
                        </span>
                }
                </div>
            </div>
            {
                !sales_channel_collapse && sales_channels && sales_channels.length ? sales_channels.map((single_sales_channel,idx) => (
                    <div style={{ textAlign: 'left', backgroundColor: "white", color: "#1070CA", height:'40px', display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:idx==sales_channels.length-1? "1px solid rgb(242, 244, 246)":'' }}>   
                        <div style={{flex:'6', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                        </div>
                        <div style={{flex:'3', fontSize:'14px', color:'black',padding: 5, paddingLeft: 15,paddingRight: 15,}}>
                        </div>
                        <div className='flex_property' style={{display:'flex',flex:'7', justifyContent:'space-between',fontSize:'14px', color:'black',borderBottom: idx==sales_channels.length-1? '':"1px solid rgb(242, 244, 246)",borderLeft:current_json.max_level == 'sales_channel'?'1px solid rgb(242, 244, 246)':'', height:'100%'}}>
                            <span style={{marginLeft:'35px',color:'black', flex:'2'}}>{single_sales_channel.name}</span>
                            <span style={{flex:'1',}}>{['list', 'sku_category_type_id'].includes(current_json.value_type) ? current_json.value_type : (current_json.value ? (current_json.value['sales_channel'] ? (current_json.value['sales_channel'][single_sales_channel.id] ? current_json.value['sales_channel'][single_sales_channel.id]: (current_json.value['store'] ? current_json.value['store'] : current_json.value['master'])) : (current_json.value['store'] ? current_json.value['store'] : current_json.value['master'])):'')}</span>
                        </div>
                        <div className='flex_property' style={{justifyContent:'flex-end',paddingLeft: 15,paddingRight: 25,borderBottom: "1px solid rgb(242, 244, 246)", height:'100%'}}>
                            <span style={{cursor:'pointer', color: "#1070CA" }} onClick = {() => {set_active_sales_channel(single_sales_channel);set_show_sales_channel_pref_modal(true)}} className='fa fa-edit'/>
                        </div>
                    </div>
                )):""
            }
            
        </div>
    ) : '';
}


const Parent_Editor = ({ current_key, preference_json, hierarchy, fetch_all_level_preferences, category_types, sales_channels, sales_channel_level_prefs, fetch_sales_channel_level_prefs, first_call, sku_data_json, all_division_tree_data, set_page_loader, out_of_sync_preferences }) => {

    const [ current_json, set_current_json ] = useState({});
    const [ collapsed, set_collapsed ] = useState(true);
    const [ render, set_render ] = useState(false);

    useEffect(() => {
        // let children = traverse_json(preference_json, [ ...hierarchy, current_key ]);
        let { json, visible } = determine_visibility(preference_json, [ ...hierarchy, current_key ], false);
        set_current_json(json);
        set_render(visible);
        // console.log("determine", json, visible)
        if(first_call) set_collapsed(false)
    }, [preference_json, hierarchy]);

    return (
        <div id="preferences_parent_editor" style={{ width: "100%", display: render ? 'block' : 'none', border:first_call?'1px solid #D1D7DA':'', borderBottom:!collapsed?'1px solid #D1D7DA':'', marginBottom:first_call?'20px':'' }}>
            <div className='flex_property' style={{ fontSize:first_call?'16px':'14px', height:first_call?'45px':'40px' ,fontWeight:'900', padding: 5, cursor: 'pointer', paddingLeft: 15, paddingRight: 15 , backgroundColor:first_call?'rgb(223 227 230)':"rgb(242, 244, 246)",borderBottom: "1px solid lightgrey"}} onClick={() => set_collapsed(!collapsed)}>
                <span style={{ float: 'left', marginTop: 4, marginRight:'10px', marginBottom:'3px'}} className={collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up'} ></span> 
                <span style={{ float: 'left'}}> {convert_to_readable(current_key)} </span>
            </div>
            <div style={{ margin:"0px 10px 10px 10px", display: collapsed ? "none" : "block", borderLeft: "1px solid lightgrey", borderRight: "1px solid lightgrey", borderBottom: "1px solid lightgrey" }}>
                {!collapsed && current_json && Object.keys(current_json).map((key, index) => (
                    current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? (
                        current_json[key].visible ? (
                            <div>
                                <Leaf_Editor
                                    current_key={key}
                                    hierarchy={[ ...hierarchy, current_key ]}
                                    preference_json={preference_json}
                                    fetch_all_level_preferences={fetch_all_level_preferences}
                                    category_types={category_types}
                                    sales_channels = {sales_channels}
                                    sku_data_json = {sku_data_json}
                                    all_division_tree_data = {all_division_tree_data}
                                    set_page_loader = {set_page_loader}
                                    out_of_sync_preferences = {out_of_sync_preferences}
                                    // sales_channel_level_prefs = {sales_channel_level_prefs}
                                    // fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                />
                            </div>
                        ): ''
                    ) : ''
                ))}
                {!collapsed && current_json && Object.keys(current_json).map((key, index) => (
                    current_json[key].type == 'leaf' && current_json[key].id && valid_value_types.includes(current_json[key].value_type) ? '' : (
                        <div>
                            <Parent_Editor
                                current_key={key}
                                hierarchy={[ ...hierarchy, current_key ]}
                                preference_json={preference_json}
                                fetch_all_level_preferences={fetch_all_level_preferences}
                                category_types={category_types}
                                sales_channels = {sales_channels}
                                sku_data_json = {sku_data_json}
                                all_division_tree_data = {all_division_tree_data}
                                set_page_loader = {set_page_loader}
                                out_of_sync_preferences = {out_of_sync_preferences}
                                // sales_channel_level_prefs = {sales_channel_level_prefs}
                                // fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                            />
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

const Store_Preferences = ({user, set_active_tab, send_analytics_event, check_route_fn,store_details, get_store_details, set_active_division_id_sidebar, set_page_loader}) => {

    const [ initialized, set_initialized ] = useState(false);
	const [ preference_json, set_preference_json ] = useState({});
    const [ category_types, set_category_types ] = useState([]);
    const [sales_channels , set_sales_channels] = useState([]);
    const [fetch_progress , set_fetch_progress] = useState(40)
    const [sales_channel_level_prefs, set_sales_channel_level_prefs] = useState({});
    const [sku_data_json, set_sku_data_json] = useState();
    const [all_division_tree_data, set_all_division_tree_data] = useState();
    const [out_of_sync_preferences, set_out_of_sync_preferences] = useState();

    const fetch_sales_channel_details = async () => {
		try {
			var brand_timer = setInterval(() => {
				var x = Number(fetch_progress);
				console.log('xxx ---> ', x,x + Math.floor((100 - x)/2))
				set_fetch_progress(String(x + Math.floor((100 - x)/2)))  
			}, 200);
			var identifiers = JSON.stringify({store_id : user.store_id})
			var attributes = JSON.stringify({})
			var body = {identifiers , attributes}
			var resp = await general_fetch({ url: 'sales_channel/get' , body});
			var sales_channel_details= resp && resp.length ? resp.sort(name_sort):[]
			set_sales_channels(sales_channel_details);
			clearInterval(brand_timer);
		} catch(err) {
			console.log(err);
		}
	}

    const fetch_category_type_details = async() => {
        try {
            let response = await general_fetch({ url: 'sku_category_type/get'});
            response = split_cat_type(response, 'with_division').sort(name_sort);
            console.log('cat type --->> ', response);
            set_category_types(response);
        } catch(err) {
            console.log(err);
        }
	}

	// const fetch_master_preferences = async () => {
	// 	try {
	// 		var resp = await general_fetch({ url: 'designing_preference/get_preference_at_level', body: { level: 'store', json_with_leaf_values: true, nested_output: true } });
	// 		set_initialized(true);
    //         set_preference_json(resp);
	// 	} catch(err) {
	// 		console.log(err);
	// 	}
	// }

    // const fetch_sales_channel_level_prefs = async() => {
    //     try{
    //         if(sales_channels && sales_channels.length){
    //             let temp_json = {}
    //             for(let i=0;i<sales_channels.length;i++){
    //                 var resp = await general_fetch({url:'designing_preference/get_preference_at_level', body:{level:'sales_channel', sales_channel_id:sales_channels[i].id, nested_output:true}})
    //                 if(resp){
    //                     temp_json[sales_channels[i].id] = resp
    //                 }
    //             }
    //             console.log("sales prefs", temp_json)
    //             set_sales_channel_level_prefs(temp_json)
    //         }
    //     }catch(err){
    //         console.log(err)
    //     }
    // }

    const fetch_all_level_preferences = async() => {
        try{
            let sales_channel_ids = sales_channels && sales_channels.length ? sales_channels.map(x => x.id):[]
            set_page_loader({
                show:true,
                text: 'fetching preferences...'
            })
            let response = await general_fetch({url:'designing_preference/get_all_level_preferences', body:{sales_channel_id:sales_channel_ids, nested_output:true, json_with_leaf_values:true}})
            set_page_loader({show:false})
            if(response){
                set_preference_json(response)
                search_for_sku_ids(response)
                get_sku_names()
                fetch_out_of_sync_for_store()
            }
        }catch(err){
            console.log(err)
        }
    }

    const fetch_all_subcategories = async() => {
        try{
            var response = await general_fetch({ url: 'inventory/get_all_sub_categories'});	
			set_all_division_tree_data(response)
        }catch(err){
            console.log(err)
        }
    }

    const fetch_out_of_sync_for_store = async() => {
        try{
            let resp = await general_fetch({url:'designing_preference/get_out_of_sync_for_store'})
            set_out_of_sync_preferences(resp)
        }catch(err){
            console.log(err)
        }
    }

    const search_for_sku_ids = async(preference_json) => {
        if(preference_json && Object.keys(preference_json) && Object.keys(preference_json).length){
            Object.keys(preference_json).map((key, idx) => {
                if(preference_json[key].type === 'leaf'){
                    if(preference_json[key].value_type === 'sku_id'){
                        sku_ids_set.add(preference_json[key].value.master)
                        if(preference_json[key].value.store) sku_ids_set.add(preference_json[key].value.store)
                        if(preference_json[key].value.sales_channel && Object.values(preference_json[key].value.sales_channel) && Object.values(preference_json[key].value.sales_channel.length)){
                            Object.values(preference_json[key].value.sales_channel).map((value) => {
                                sku_ids_set.add(value)
                            })
                        }
                    }
                }else{
                    search_for_sku_ids(preference_json[key])
                }
            })
        }
    }

    const get_sku_names = async() => {
        try{
            let sku_ids_list = [...sku_ids_set]
            set_page_loader({
                show:true,
                text:'Please wait...'
            })
            let resp = await general_fetch({url:'sku/get', body: {identifiers:JSON.stringify({id:sku_ids_list})}});
            set_page_loader({
                show:false
            })
            let temp_sku_json = {}
            if(resp && resp.length){
                resp.map((single_sku, idx) => {
                    temp_sku_json[single_sku.id] = {id: single_sku.id, 
                                                    name: single_sku.name,
                                                    sku_group_id: single_sku.sku_group.id, 
                                                    sku_sub_category_id: single_sku.sku_group.sku_sub_category.id, 
                                                    sku_category_id: single_sku.sku_group.sku_sub_category.sku_category.id,
                                                    sku_division_id: single_sku.sku_group.sku_sub_category.sku_category.sku_division_id
                                                    }
                })
            }
            console.log('temp_sku_json', temp_sku_json)
            set_sku_data_json(temp_sku_json)
        }catch(err){
            console.log(err)
        }
    }

	useEffect(() => {
		if (!initialized) {
			// fetch_master_preferences();
            fetch_category_type_details();
            fetch_sales_channel_details();
            fetch_all_level_preferences()
            fetch_all_subcategories()

		}
		set_active_tab('preferences_settings');
        set_active_division_id_sidebar('org_preference')
		send_analytics_event('auto', 'Org Preferences', 'Org Preferences', 1, window.location.href)
		// check_route_fn('store_preference');
	}, []);

    useEffect(() => {
        if(sales_channels && sales_channels.length){
            // fetch_sales_channel_level_prefs()
            fetch_all_level_preferences()
        }
    }, [sales_channels]);

	try {
		return (
			<Main_Grid  active_tab="org_preference" user={user} store_details={store_details} get_store_details={get_store_details}>
                <div style={{ height: '100%', overflow: 'scroll', padding: 30, margin: 0 }}>
                    <Row style={{padding:'10px', color:'#22272e', fontFamily:'Source_Sans_Pro-SemiBold'}}>
                        Manage Org Preferences
                    </Row>
                    <Row style={{padding:'10px 0px', color:'#22272e'}}>
                        {preference_json && Object.keys(preference_json).map((key, index) => 
                            (preference_json[key].type == 'leaf' && preference_json[key].id) ? (
                                preference_json[key].visible ? (
                                    <div style={{width: '100%'}}>
                                        <Leaf_Editor
                                            current_key={key}
                                            hierarchy={[]}
                                            preference_json={preference_json}
                                            category_types={category_types}
                                            fetch_all_level_preferences = {fetch_all_level_preferences}
                                            sales_channels = {sales_channels}
                                            sku_data_json = {sku_data_json}
                                            all_division_tree_data = {all_division_tree_data}
                                            set_page_loader = {set_page_loader}
                                            out_of_sync_preferences = {out_of_sync_preferences}
                                            // sales_channel_level_prefs = {sales_channel_level_prefs}
                                            // fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                        />
                                    </div>
                                ) : ''
                            ) : ''
                        )}
                        {preference_json && Object.keys(preference_json).map((key, index) => 
                            (preference_json[key].type == 'leaf' && preference_json[key].id) ? '' : (
                                <div style={{width: '100%'}}>
                                    <Parent_Editor
                                        current_key={key}
                                        hierarchy={[]}
                                        preference_json={preference_json}
                                        fetch_all_level_preferences = {fetch_all_level_preferences}
                                        category_types={category_types}
                                        sales_channels = {sales_channels}
                                        sku_data_json = {sku_data_json}
                                        all_division_tree_data = {all_division_tree_data}
                                        set_page_loader = {set_page_loader}
                                        out_of_sync_preferences = {out_of_sync_preferences}
                                        // sales_channel_level_prefs = {sales_channel_level_prefs}
                                        // fetch_sales_channel_level_prefs = {fetch_sales_channel_level_prefs}
                                        first_call = {true}
                                    />
                                </div>
                            )
                        )}
                    </Row>
                </div>
			</Main_Grid>
		);
	} catch(err) {
		console.error("Error in feature flags component ---> ", err);
	}

}

export default Store_Preferences;