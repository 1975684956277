import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import Multiselect from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert'
import './index.js'
import Multiselect from '../Multiselect';
import AMLinked_sku_structure from '../AMLinked_sku_structure';

const AMKeyValuePair_relative= ({open, component_options_json, item_json, idx, single_item_json, set_item_json , mode, all_division_tree_data, tags, all_category, all_sub_category, all_sales_ch, set_condition_combination_logic, condition_combination_logic, is_back_button, position_type, shelf_type_values}) => {

    const alert = useAlert()
    const [selected_tag_1_array ,set_selected_tag_1_array ] =useState([])
    const [values_json ,set_values_json ] =useState([])
    
    const [selected_comp , set_selected_comp] = useState([]);
    const [selected_sub_comp,set_selected_sub_comp] =useState([])
    const [selected_property,set_selected_property] =useState([])

    useEffect(() => {
        if(single_item_json){
            console.log('single_item_json =======>',single_item_json)

            //components and subcomponents
            var x_component = component_options_json.filter(x =>x.id===single_item_json.relativecomponent)
            var x_sub_component = x_component && x_component.length && x_component[0].sub_component.length? x_component[0].sub_component.filter(x => x.id===single_item_json.relativesubcomponent):{}
            var x_selected_property = x_sub_component && x_sub_component.length && x_sub_component[0].properties && x_sub_component[0].properties.length? x_sub_component[0].properties.filter(x =>x.id===single_item_json.relativeproperty):{}

            single_item_json.relativecomponent=x_component;
            single_item_json.relativesubcomponent=x_sub_component;
            single_item_json.relativeproperty=x_selected_property;

            set_selected_comp(x_component)
            set_selected_sub_comp(x_sub_component)
            set_selected_property(x_selected_property)
        }
	},[single_item_json])

    const onclick_item = (operator, value) => {
        update_item_json(idx,operator, value)
        if(operator==='relativecomponent'){
            set_selected_comp(value)
            set_selected_sub_comp([])
            set_selected_property('')
            update_item_json(idx,'relativesubcomponent',[]) 
            update_item_json(idx,'relativeproperty', [])
        }
        if(operator==='relativesubcomponent'){
            set_selected_sub_comp(value)
            set_selected_property([])
            update_item_json(idx,'relativeproperty',[])    
        }
        if(operator==='relativeproperty'){
            value && value.length && value[0].id==='tag' && set_values_json(tags)
            set_selected_property(value)
        }
    }

    // useEffect(() =>{
	// 	if(item_json){
    //         if(position_type==='condition'){
	// 			console.log('item_json_condition======>',item_json)
	// 		}else if(position_type==='action'){
	// 			console.log('item_json_action======>',item_json)
	// 		}else{
	// 			console.log('item_json_applicable======>',item_json)
	// 		}
    //         console.log('item_json_final======>',JSON.stringify(item_json))
	// 	}
	// },[item_json])

    useEffect(() =>{
		if(!open){
			handleClose_for_mf__source()
		}
	},[open])

    const handleClose_for_mf__source = () => {
        set_selected_comp([])
        set_selected_sub_comp([])
        set_selected_property('')
    }

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
	}

    const fn_check_null = (x) => {
        if(x===null){
            alert.error('value of '+selected_property?selected_property.name+' is no longer available.':'')
        }
        return x;
    }

    return(
        <Row style={{ margin:0, marginTop:'15px'}}>
            <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                <div style={{marginBottom:'5px'}}>Component</div>
                {
                    component_options_json && component_options_json.length?
                        <Multiselect
                            options={component_options_json}
                            selectedValues={selected_comp}
                            onSelect={(selectedList) => onclick_item('relativecomponent', selectedList)}
                            onRemove={(selectedList) => onclick_item('relativecomponent', selectedList)}
                            displayValue="name" // Property name 
                            id={position_type+'_relativecomponent_'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                        />  
                    :
                    <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                        <option>Choose a value</option>
                    </Input>
                }
            </Col>
            <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px 0px 0px'}}>
                <div style={{marginBottom:'5px'}}>SubComponent</div>
                {
                    selected_comp && selected_comp.length && selected_comp[0].sub_component && selected_comp[0].sub_component.length?
                        <Multiselect
                            options={selected_comp[0].sub_component}
                            selectedValues={selected_sub_comp}
                            onSelect={(selectedList) => onclick_item('relativesubcomponent', selectedList)}
                            onRemove={(selectedList) => onclick_item('relativesubcomponent', selectedList)}
                            displayValue="name" // Property name 
                            id={position_type+'_relativesubcomponent_'+idx}
                            placeholder='Search Value'
                            selectionLimit={1}
                        />  
                    :
                    <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', color:'#425A70'}} type="select">
                        <option>Choose a value</option>
                    </Input>
                }
            </Col>
            <Col xs={3} style={{fontSize:'12px', color:'#425A70', padding:'0px 10px'}}>
                <div style={{marginBottom:'5px'}}>Property</div>
                    {
                        selected_sub_comp && selected_sub_comp.length && selected_sub_comp[0].properties &&  selected_sub_comp[0].properties.length ?
                            <Multiselect
                                options={selected_sub_comp[0].properties} 
                                selectedValues={selected_property}
                                onSelect={(selectedList) => onclick_item('relativeproperty', selectedList)}
                                onRemove={(selectedList) => onclick_item('relativeproperty', selectedList)}
                                displayValue="name" // Property name 
                                id={position_type+'_relativeproperty_'+idx}
                                placeholder='Search Value'
                                selectionLimit={1}
                            />  
                        :
                        <Input id={'box3'+idx} style={{height:'32px',overflowY:'auto',fontSize:'12px', height:'32px', color:'#425A70'}} type="select">
                            <option>Choose a value</option>
                        </Input>
                    }
            </Col> 
        </Row>
    )
}

export default AMKeyValuePair_relative;

