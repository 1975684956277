import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './config';
import './index.css';
import './effects.css';
import App from './App';
import {Col, Row} from "reactstrap";
import * as serviceWorker from './serviceWorker';
//import * as THREE from "three";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertBox/'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {useHistory } from 'react-router-dom';


window.intercomSettings = {
  hide_default_launcher: true,
  vertical_padding: 30,
  horizontal_padding: 20,
  custom_launcher_selector:'#IntercomDefaultWidget'
};


Sentry.init({
  dsn: "https://1f9c486a6aef4307a5bd9f94dce88f80@o932499.ingest.sentry.io/5881249",
  environment: global.config.sentry_environment,
  integrations: [new Integrations.BrowserTracing()],
//   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// const AlertTemplate = ({ style, options, message, close }) => {

//   const [background_color, set_background_color] =useState('')

//   useEffect(() =>{
//     if(options && options.type){
//       options.type === 'info' && set_background_color('#e6f0ff')
//       options.type === 'success' && set_background_color('#ecf9ec')
//       options.type === 'error' && set_background_color('#ff3333')
//     }
//   },[options])

//   useEffect(() =>{
//     if(options && options.type){
//       console.log('background_color =>>>>>', background_color)
//     }
//   },[background_color])
  
//     // useEffect(() => {
//     //   // === THREE.JS CODE START ===
//     //   var scene = new THREE.Scene();
//     //   var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
//     //   var renderer = new THREE.WebGLRenderer();
//     //   renderer.setSize( window.innerWidth, window.innerHeight );
//     //   document.body.appendChild( renderer.domElement );
//     //   var geometry = new THREE.BoxGeometry( 1, 1, 1 );
//     //   var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
//     //   var cube = new THREE.Mesh( geometry, material );
//     //   scene.add( cube );
//     //   camera.position.z = 5;
//     //   var animate = function () {
//     //     requestAnimationFrame( animate );
//     //     cube.rotation.x += 0.01;
//     //     cube.rotation.y += 0.01;
//     //     renderer.render( scene, camera );
//     //   };
//     //   animate();
//     //   // === THREE.JS EXAMPLE CODE END ===
//     // }, [])

//   return(
//     <Row style={{height:'40px', width:'100vw', backgroundColor:background_color, textAlign:'left'}}>
//       <Col className='flex_property' xs={11}>
//         {options.type === 'info' && '!'}
//         {options.type === 'success' && ':)'}
//         {options.type === 'error' && ':('}
//         {message}
//       </Col>
//       <Col className='flex_property' xs={1} style={{justifyContent:'flex-end'}}><button onClick={close}>X</button></Col>
//     </Row>
//   );
// }

const options = {
  // you can also just use 'bottom center'
	position: 'bottom right',
	timeout: 4000,
	offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  },
}

ReactDOM.render(
  <React.StrictMode>
	  <AlertProvider template={AlertTemplate} {...options}>
	    <App />
	  </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
