import React, { useState , useEffect, isValidElement, useRef } from 'react';
import {Modal, FormGroup, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import { useAlert } from 'react-alert'
import './sku_groups.css'
import Moment from 'react-moment';

const SkuDraftsModal = ({open, handleClose, user, array_of_latest_attempts,active_division_id,active_category,all_users, edit_draft, get_latest_drafts, set_page_loader, submit_for_delete_confirm, set_show_bulk_create_modal, create_new_attempt}) => {

    const [no_drafts, set_no_drafts] = useState(false)
    const alert = useAlert()

    const delete_draft = async(attempt_id) => {
        try{
            set_page_loader({
                show:true,
                text:'Please wait.. Deleteing current draft'
            })
            let resp = await general_fetch({url:'sku_bulk_operation/remove_drafts_of_attempt', body: {attempt_id:attempt_id}})
            if(resp){
                alert.success('Draft Deletion Successful !')
                get_latest_drafts(active_category)
            }
            set_page_loader({
                show:false,
                text:''
            })
        }catch(err){
            alert.error('Draft Deletion Failed !')
            console.log(err)
        }
    }

    useEffect(() => {
        if(array_of_latest_attempts && array_of_latest_attempts.length){
            let temp_var = 0
            for(let i=0;i<array_of_latest_attempts.length;i++){
                if(array_of_latest_attempts[i].draft){
                    temp_var++
                }
            }
            console.log("draftsss", temp_var)
            if(temp_var>0){
                set_no_drafts(false)
            }else {
                set_no_drafts(true)
            }
        }
    }, [array_of_latest_attempts])
    
    return (
        <Modal style={{marginTop:'64px', width:'800px', marginLeft:'22vw'}} size="md" isOpen={open} toggle={handleClose}>
            <Card style = {{height:'550px', width:'60vw'}}>
                <CardHeader className='global_modal_header'>
                    <span>SKUs in Draft</span>
                </CardHeader>
                <CardBody className='global_modal_body'>
                    <Row style={{backgroundColor:'white', width:'100%', height:'100%', marginLeft:'0px', marginRight:'0px', overflowY:'scroll'}}>
                        <table style={{height:'100%'}} className="text-left flex_property table_style">
                            <thead style={{width:"100%"}}>
                                <tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
                                    <th className='th_style flex_property' style={{flex:'3'}}><span>Time</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>No of SKUs</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Division</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Category</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Owner</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span></span></th>
                                </tr>
                            </thead>
                            <tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)",overflowY:'scroll'}}>
                            {
                                 !no_drafts && array_of_latest_attempts && array_of_latest_attempts.length ? array_of_latest_attempts.map((single_attempt,idx) => ( 
                                    
                                     single_attempt.draft ? 
                                         <tr className='tr_style display_onhover'> 
                                             <td className='td_style flex_property' style={{flex:'3'}}><span className='table_text_overflow'> 
                                                 {/* <Moment format="DD/MM/YYYY">{single_attempt.draft.updated_at}</Moment>  */}
                                                 {new Date(single_attempt.draft.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})} 
                                             </span></td>  
                                             <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_attempt.draft.data.length}</span></td>  
                                             <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{active_division_id?active_division_id:""}</span></td>  
                                             <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{active_category?active_category.name:""}</span></td>  
                                             <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{all_users[single_attempt.user_id]?all_users[single_attempt.user_id]:''}</span></td>  
                                             { 
                                                 user.id == single_attempt.user_id ? 
                                                 <td className='td_style flex_property x1' style={{flex:'2'}}><span style={{marginLeft:'10%',fontWeight:'900'}} className='table_text_overflow hide'><span onClick={() => {edit_draft(single_attempt.draft.data, single_attempt.id, 'draft');handleClose()}}>Edit</span> <i style={{color:'red', marginLeft:'35%',marginTop:'4px'}} className='fa fa-trash' onClick = {() => {submit_for_delete_confirm(() => delete_draft(single_attempt.id), ()=>{}, 'This action will delete all your existing drafts. click Delete to proceeed')}} /></span></td>  
                                                 :"" 
                                             } 
                                         </tr> 
                                     :'' 
                                 )):
                                 <div style={{height:'100%',width:'100%', display:'flex', flexDirection:'column', textAlign:'center', alignItems:'center', justifyContent:'center'}}>
                                    <span>There are no drafts saved yet,</span>
                                    <span>please click here to start adding new SKUS</span>
                                    <span style={{backgroundColor:'#0078FF', color:'white', padding:'5px 10px', borderRadius:'4px', fontSize:'14px', marginTop:'10px', cursor:'pointer'}} onClick={() => {set_show_bulk_create_modal(true);create_new_attempt(active_category);handleClose()}} >Add new SKUs</span>
                                </div>
                             } 
                            
                            </tbody>
                        </table>
                    </Row>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button onClick={() => {handleClose()}} className='white_button' style={{marginRight:'0px'}}>Close</Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

export default SkuDraftsModal   