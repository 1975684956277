import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Modal, Alert, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { Link } from "react-router-dom";
//import AlertBox from "../../components/AlertBox/";
//import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import Moment from 'react-moment';
import {CSVLink} from "react-csv";
import useRazorpay from "react-razorpay";
import './index.css'

const studio_plan = ['Includes 2 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const studio_features = ['All Design Features including Architecture, interior & modular kitchen design', 
                            'Export Design as .gltf', 
                            'Production Drawings', 
                            'Includes 100 HD (or equivalent) renders /month per user',
                            'Custom Catalog',
                            'Price Quotation',
                            'Create your own design & presentation templates'
                            ]

const business_plan = ['Includes 5 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const business_features = ['All Studio Features Plus', 
                            'Custom Cabinet Construction mechanisms', 
                            'Cutting List & BOQ', 
                            'Custom Pricing Rules Framework', 
                            'Custom Hardware rules & design constraints',
                            'CNC Compatible output generation',
                            'Support over phone and video call']

const enterprise_plan = ['Includes 40 User Licenses', 'Additional Licenses at ₹ 2,000/mo per user']
const enterprise_features = ['All Business Features Plus',
                                'Custom output generation',
                                'API Integrations',
                                'Usage & Analytics reports', 
                                'Custom Branding',
                                'Dedicated Account Manager & Enterprise level support']

const ShowPlanSummary = ({open, handleClose, plan_selected, order_details, payment_function}) => {
    return(
        <Modal isOpen={open} size='xs' className='modal-dialog-centered'>
            <CardHeader className='global_modal_header'>
                <div>Payment Summary</div>
                <i className='fa fa-times' style={{cursor:'pointer'}} onClick={handleClose}/>
            </CardHeader>
            <CardBody style={{minHeight:'240px', backgroundColor:'#F5F6F8'}}>
                <div style={{margin:'10px 10px 30px 10px', padding:'10px 20px', borderRadius:'6px', backgroundColor:'white', width:'calc(100% - 20px)'}}>
                    <div className='flex_property' style={{justifyContent:'space-between'}}>
                        <div>Type of Plan selected</div>
                        <div style={{fontWeight:'700', textTransform:'capitalize'}}>{plan_selected}</div>
                    </div>
                    <div className='flex_property' style={{justifyContent:'space-between', marginTop:'10px'}}>
                        <div>Plan pricing</div>
                        <div style={{fontWeight:'700'}}>{order_details && order_details.order ? order_details.order.price + ' (INR)' : ''}</div>
                    </div>
                    <div className='flex_property' style={{justifyContent:'space-between', marginTop:'10px'}}>
                        <div>Tax</div>
                        <div style={{fontWeight:'700'}}>{order_details && order_details.order ? order_details.order.tax + ' (INR)' : ''}</div>
                    </div>
                </div>
                <div className='flex_property' style={{justifyContent:'space-between', margin:'20px'}}>
                    <div>Total Amount payable : </div>
                    <div style={{color:'#23C16B', fontWeight:'700'}}>{order_details && order_details.order ? order_details.order.total + ' (INR)' : ''}</div>
                </div>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button className='blue_button' onClick={() => {payment_function(order_details?.order); handleClose()}}>Proceed to Payment</Button>
            </CardFooter>
        </Modal>
    )
}

const ShowInfurniaPlans = ({payment_function, set_first_order, set_page_loader, plan_selected, set_plan_selected}) => {
    
    const [show_plan_summary_modal, set_show_plan_summary_modal] = useState(false);
    const [first_order_details, set_first_order_details] = useState();
    const [view_more_info, set_view_more_info] = useState(true);
    const alert = useAlert()

    const create_first_order = async(plan_type) => {
        try{
            // set_page_loader({
            //     show:true,
            //     text: 'Please wait ...'
            // })
            set_plan_selected(plan_type)
            let resp = await general_fetch({url:'billing/order/request_first_order', body:{plan_type}})
            set_first_order_details(resp)
            set_show_plan_summary_modal(true)
            set_first_order(true)
            // set_page_loader({show:false})
        }catch(err){
            console.log(err)
            alert.error('Something went wrong, please try again')
        }
    }

    return(
        <div style={{height:'100%', overflow:'auto'}}>
            <div style={{color:'#A7A8B2', margin:'10vh 0vh 2vh'}}>Thank you for taking the free trial</div>
            <div style={{fontSize:'24px', /*marginTop: view_more_info ? '10vh' : '',*/ marginBottom:'3vh'}}>To continue with Infurnia, please upgrade your plan</div>
            <div className='all_plans_container'>
                <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Studio</div>
                        <div className='plan_type'>SMEs & design studios</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 5,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            studio_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {create_first_order('studio')}}>START NOW</Button>
                        {
                            view_more_info ? 
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    studio_features.map((single_line) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:''
                        }
                        {/* {
                            !view_more_info ? <div style={{textDecoration:'underline', color:"#A7A8B2", cursor:'pointer', marginTop:'40px'}} onClick={() => set_view_more_info(!view_more_info)}>View more information</div>:''
                        } */}
                    </div>
                </div>
                <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Business</div>
                        <div className='plan_type'>Manufacturing Oriented Orgs</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 20,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            business_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {create_first_order('business')}}>START NOW</Button>
                        {
                            view_more_info?
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    business_features.map((single_line, idx) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1', fontWeight:idx===0?'700':''}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:''
                        }
                    </div>
                </div>
                <div className='col-lg-4 col-12'>
                    <div className='plan_container' style={{height: view_more_info ? '' : '380px'}}>
                        <div className='plan_name'>Enterprise</div>
                        <div className='plan_type'>Large Organizations</div>
                        <div style={{display:'flex', alignItems:'baseline', marginBottom:'5px'}}>
                            <div className='plan_price'>&#8377; 1,00,000</div>
                            <div className='per_month'>{'\xa0\xa0'}/mo Platform Fee</div>
                        </div>
                        {
                            enterprise_plan.map((single_line) => (
                                <div className='plan_points flex_center'>
                                    <div className='worksans' style={{fontSize:'16px'}}>+{'\xa0\xa0\xa0'}</div>
                                    <div className='worksans' style={{flex:'1'}}>{single_line}</div>
                                </div>
                            ))
                        }
                        <Button className='start_button_pricing' onClick = {() => {create_first_order('enterprise')}}>START NOW</Button>
                        {
                            view_more_info?
                            <>
                                <hr style={{margin:0, marginBottom:'30px'}}/>
                                {
                                    enterprise_features.map((single_line, idx) => (
                                        <div className='plan_points flex_center'>
                                            <div className='worksans' style={{fontSize:'16px'}}><i className='fas fa-check pricing_tick' />{'\xa0\xa0\xa0'}</div>
                                            <div className='worksans' style={{flex:'1', fontWeight:idx===0?'700':''}}>{single_line}</div>
                                        </div>
                                    ))
                                }
                            </>:""
                        }
                    </div>
                </div>
            </div>
            <div style={{color:'#A7A8B2', margin: '10vh 0px 2vh'}}>Don’t worry, your existing data is preserved and you can continue with the same data</div>
            <div style={{color:'#A7A8B2', marginTop: '32px', position:'relative'}}>
                For feedbacks please write to us at support@infurnia.com
                {
                    !view_more_info ? <div style={{textDecoration:'underline', color:"#A7A8B2", cursor:'pointer', marginTop:'40px', position:'absolute', bottom:'15vh', left:'12vh'}} onClick={() => set_view_more_info(!view_more_info)}>View more information</div>:''
                }
            </div>
            <ShowPlanSummary open = {show_plan_summary_modal} handleClose={() => set_show_plan_summary_modal(false)} plan_selected={plan_selected} order_details={first_order_details} payment_function={payment_function} />
        </div>
    )
}

export default ShowInfurniaPlans