import Switch from "react-switch";
import React , { useState , useEffect }from 'react';

const Penguin_Switch = ({ checked, on_change, id }) => {
    try {
        return (
            <Switch
                checked={checked}
                onChange={on_change}
                onColor="#86d3ff"
                onHandleColor="#1070CA"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={30}
                className="react-switch"
                id={id}
            />
        )
    } catch (error) {
        console.error(error);
        return '';
    }
}

export default Penguin_Switch;