import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Modal, Alert, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import { Link } from "react-router-dom";
//import AlertBox from "../../components/AlertBox/";
//import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import Moment from 'react-moment';
import {CSVLink} from "react-csv";
import useRazorpay from "react-razorpay";
import ShowInfurniaPlans from './ShowInfurniaPlans';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


const PaymentConfirmationModal = ({open, handleClose, order_status, first_order, plan_selected}) => {
	const alert = useAlert()

	useEffect(() => {
		if(order_status === 'paid'){
			alert.success('Payment was successful ', {timeout:10000000})
		}
	}, [order_status]);

	return(
		<Modal isOpen={open} size='xs' className='modal-dialog-centered'>
			<CardBody className='flex_center' style={{minHeight:'260px', backgroundColor:'white', fontSize:'18px'}}>
			{
				order_status === 'paid' ?
				<div className='flex_center' style={{flexDirection:'column'}}>
					{/* <i className='fa fa-check-circle payment_success_check' style={{color:'green', fontSize:'64px', marginBottom:'32px'}}/> */}
					<img /*className='payment_success_check'*/ src={'/assets/billing/payment_successful.svg'} style={{height:'76px', width:'76px', margin:'32px 0px 0px 0px'}} />
					<div style={{padding:'28px 0px', color:'#23C16B', fontWeight:'700'}}>Payment Successful !</div>
					{
						first_order ? <div style={{marginBottom: '28px', fontSize:'16px'}}>&#127881;{'\xa0\xa0'}Your organisation is now subscribed to <span style={{textTransform:'capitalize', fontWeight:'700'}}>{plan_selected}</span> Plan</div>:""
					}
					<Button className='white_button' style={{margin:'0px', fontSize:'14px', marginBottom:'14px'}} onClick={() => {window.location.href = global.config.admin_url}}>Continue</Button>
				</div>
				:
				(order_status === 'timeout' ? 
					<div className='flex_center' style={{flexDirection:'column'}}>
						<div style={{padding:'28px 0px 0px 0px'}}>It is taking longer than usual !</div>
						<div style={{padding:'8px 0px 28px 0px'}}>Please refresh the page and check </div>
						<i className='fa fa-exclamation' style={{color:'orangered', fontSize:'64px', marginBottom:'32px'}}/>
						<Button className='white_button' style={{margin:'0px'}} onClick={handleClose}>Close</Button>
					</div>:
					<div className='flex_center' style={{flexDirection:'column'}}>
						<div style={{padding:'28px 0px'}}>Please wait while we confirm your payment</div>
						<div class="dot-spinner">
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
							<div class="dot-spinner__dot"></div>
						</div>
						<div style={{padding:'28px 0px'}}>Please do not close or refresh this tab</div>
					</div>
				)
			}
			</CardBody>
		</Modal>
	)
}

const Billing_usage = ({user, store_details, set_active_tab, set_active_division_id_sidebar, set_page_loader}) => {
	
	let now = new Date();
	const [tabs, set_tabs] = useState(process.env.REACT_APP_ENV === 'stage' ? 1 : 2);
	const [old_selected_date, set_old_selected_date] = useState(now.getMonth() == 0 ? (months[11] + ', ' + now.getFullYear() -1) : (months[now.getMonth()-1] + ', ' + now.getFullYear()));
	const [selected_date, set_selected_date] = useState(now.getMonth() == 0 ? (months[11] + ', ' + now.getFullYear() -1) : (months[now.getMonth()-1] + ', ' + now.getFullYear()));
	const [unbilled_usage, set_unbilled_usage] = useState({});
	const [billing_history, set_billing_history] = useState({});
	const [selected_month_usage, set_selected_month_usage] = useState({});
	const [bill_id, set_bill_id] = useState();
	const [dues, set_dues] = useState();
	const [show_payment_confirmation_modal, set_show_payment_confirmation_modal] = useState(false);
	const [order_status, set_order_status] = useState('');

	const [time_out_output, set_time_out_output] = useState();
	const [first_order, set_first_order] = useState(false);
	const [plan_selected, set_plan_selected] = useState();
	const Razorpay = useRazorpay()

	const [usage_csv_data, set_usage_csv_data] = useState([
		["Billing details", "",],
		["Ahmed", "Tomi"],
		["Raed", "Labes"],
		["S.no", "email", "roles", 'usage start date', 'usage end date'],
		["Yezzi", "Min l3b", "ymin@cocococo.com", "extra"]
	  ]);
	  

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const fetch_unbilled_usage = async() => {
		try{
			if(store_details && store_details.id){
				set_page_loader({show:true, text:'fetching unbilled usage...'})
				let resp = await general_fetch({url:'billing/find_unbilled_statement', body:{customer_store_id:store_details.id}})
				set_unbilled_usage(resp)
				set_page_loader({show:false})
			}
		}catch(err){
			console.log(err)
		}
	}

	const fetch_billing_history = async() => {
		try{
			if(store_details && store_details.id && selected_date){
				set_page_loader({show:true, text:'fetching billing history...'})
				let resp = await general_fetch({url:'billing/get_bills', body:{customer_store_id:store_details.id, year: selected_date.slice(-4)}})
				set_page_loader({show:false})
				if(resp && resp.length){
					let t = selected_date
					let temp_month_usage = resp.find(o => {console.log('temp_month_usage 1', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
					set_selected_month_usage(temp_month_usage)
					set_bill_id(temp_month_usage ? temp_month_usage.id : '')
				}
				set_billing_history(resp)
				
			}
		}catch(err){
			console.log(err)
		}
	}

	const fetch_dues = async() => {
		try{
			set_page_loader({
				show:true,
				text:'fetching dues...'
			})
			let resp = await general_fetch({url:'billing/order/find_dues'})
			set_page_loader({
				show:false
			})
			set_dues(resp)
		}catch(err){
			console.log(err)
		}
	}

	// const check_for_payment_gateway_close = (id) => {
	// 	let checkExist = setInterval(function() {
	// 		let temp = 1
	// 		if(document.getElementsByClassName('selected-language')){
	// 			console.log('found Infurnia Technologies second time');
	// 			temp = 0
	// 		}

	// 		if(temp){
	// 			start_pinging_status(id)
	// 			set_show_payment_confirmation_modal(true)
	// 			clearInterval(checkExist)
	// 		}
	// 	},100)
	// }

	const get_order_status = async(id) =>{
		if(id){
			try{
				let resp = await general_fetch({url:'billing/order/get', body:{id}})
				return resp && resp.status
			}catch(err){
				console.log(err)
			}
		}
	}

	const start_pinging_status = async(id) => {
		let poll_interval = setInterval(async() => {
			let temp_order_status = await get_order_status(id)
			if(temp_order_status === 'paid'){
				clearInterval(poll_interval);
				console.log(poll_interval)
				set_order_status('paid')
				fetch_dues()
			}
		}, 1000)

		let time_out = setTimeout(function() {clearInterval(poll_interval);if(order_status != 'paid'){
			// console.log('order status updated 1', String(order_status))
			set_order_status('timeout');
			fetch_dues()
		}}, 5*60*1000)

		set_time_out_output(time_out)

	}

	useEffect(() => {
		// console.log('order status updated 2', order_status)
		if(order_status === 'paid'){ console.log('order status updated clearing '); clearTimeout(time_out_output) }
	}, [order_status]);

	const payment_function = (single_due) => {
		try{
			if(single_due.total && single_due.payment_gateway_order_id){
				let options = {amount: single_due.total, 
								currency: 'INR', order_id: single_due.payment_gateway_order_id, 
								handler:(res)=>{console.log('Checkout form closed',res); set_show_payment_confirmation_modal(true); start_pinging_status(single_due.id)},
								modal: {
									"ondismiss": function(){
										console.log('Checkout form closed');
										set_show_payment_confirmation_modal(true)
										start_pinging_status(single_due.id)
									}
								}}
				let rzp1 = new Razorpay(options)
				rzp1.on("payment.failed", function (response) {
					// alert(response.error.code);
					alert(response.error.description);
					// alert.error(response.error.description)
					// alert(response.error.source);
					// alert(response.error.step);
					// alert(response.error.reason);
					// alert(response.error.metadata.order_id);
					// alert(response.error.metadata.payment_id);
				});
				
				rzp1.open()
				// let checkExist = setInterval(function() {
				// 	let temp = 0
				// 	console.log('didnt find Infurnia Technologies first time');
				// 	if(document.getElementsByClassName('selected-language')){
				// 		console.log('found Infurnia Technologies first time');
				// 		temp = 1
				// 	}
		
				// 	if(temp){
				// 		// start_pinging_status()
				// 		check_for_payment_gateway_close(single_due.id)
				// 		// set_show_payment_confirmation_modal(true)
				// 		clearInterval(checkExist)
				// 	}
				// },100)
			}
		}catch(err){
			alert.error("Something went wrong, Please try again")
			console.error(err)
		}
	}

	useEffect(() => {
		set_active_division_id_sidebar('usage')
	}, []);

	useEffect(() => {
		console.log('tabssssss', tabs)
		if(tabs == 3 && store_details && store_details.id){
			fetch_unbilled_usage()
		}else if(tabs == 2 && store_details && store_details.id){
			fetch_billing_history()
		}
	}, [tabs, store_details]);

	useEffect(() => {
		if(tabs == 1) fetch_dues()
	}, [tabs]);

	useEffect(() => {
		if(old_selected_date.slice(-4) != selected_date.slice(-4)){
			fetch_billing_history()
		}

		if(old_selected_date.substring(0, old_selected_date.length-6) != selected_date.substring(0, selected_date.length-6) && old_selected_date.slice(-4) === selected_date.slice(-4)){
			let t = selected_date
			let temp_month_usage = billing_history.find(o => {console.log('temp_month_usage 1', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
			set_selected_month_usage(temp_month_usage)
			set_bill_id(temp_month_usage ? temp_month_usage.id : '')
		}

		set_old_selected_date(selected_date)
	}, [selected_date]);

	useEffect(() => {
		if(selected_month_usage && selected_month_usage.user_credits_summary){
			let temp_csv_data = []
			temp_csv_data.push(['Billing Details'])
			temp_csv_data.push(['Organization name', store_details ? store_details.name : ''])
			temp_csv_data.push(['Billing Period', (new Date(selected_month_usage.start_date).toString().substring(4, 15)) + ' - ' + (new Date(selected_month_usage.end_date).toString().substring(4, 15))])
			temp_csv_data.push(['Platform Cost (in credits)', selected_month_usage.platform_credits])
			temp_csv_data.push(['Extra Usage Cost (in credits)', selected_month_usage.designer_extra_credits])
			temp_csv_data.push(['Render Cost (in credits)', selected_month_usage.render_credits])
			temp_csv_data.push(['Total Cost (in credits)', selected_month_usage.amount])
			temp_csv_data.push([])
			temp_csv_data.push(['S.No', "Email", 'Roles', "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', 'Credits Used'])
			let t = selected_month_usage.user_credits_summary && selected_month_usage.user_credits_summary.length ? selected_month_usage.user_credits_summary.map((user_usage, idx) => {
				let user_roles_str = ''
				let t = user_usage.role_usage && user_usage.role_usage.length ? user_usage.role_usage.map((role_usage, id) => {if(id > 0) user_roles_str += ', ' ;user_roles_str += role_usage.role_id}):''
				temp_csv_data.push([idx+1, user_usage.email, user_roles_str, new Date(user_usage.usage_start_date).toString().substring(4, 15), new Date(user_usage.usage_end_date).toString().substring(4, 15), user_usage.render_usage.render?user_usage.render_usage.render.HD:0, user_usage.render_usage.render?user_usage.render_usage.render.Full_HD:0, user_usage.render_usage.render?user_usage.render_usage.render['4K']:0, user_usage.role_credits_used])
			}):""
			set_usage_csv_data(temp_csv_data)
		}
	}, [selected_month_usage]);

	useEffect(() => {
		if(unbilled_usage && unbilled_usage.estimate_charge){
			let temp_csv_data = []
			let str = 'Current Usage' /*+ (new Date(unbilled_usage.next_bill_start_date).toString().substring(4, 15)) + ' - ' + (new Date(unbilled_usage.unbilled_till).toString().substring(4, 15)) + (store_details ? ('for ' + store_details.name) : '')*/
			temp_csv_data.push([str])
			temp_csv_data.push(['Organization name', store_details ? store_details.name : ''])
			temp_csv_data.push(['Billing Period', (new Date(unbilled_usage.next_bill_start_date).toString().substring(4, 15)) + ' - ' + (new Date(unbilled_usage.unbilled_till).toString().substring(4, 15))])
			temp_csv_data.push(['Platform Cost (in credits)', unbilled_usage.estimate_charge.platform_fee_credit_cost])
			temp_csv_data.push(['Extra Usage Cost (in credits)', unbilled_usage.estimate_charge.extra_usage_credit_cost])
			temp_csv_data.push(['Render Cost (in credits)', unbilled_usage.estimate_charge.total_render_credit_cost])
			temp_csv_data.push(['Total Cost (in credits)', unbilled_usage.estimate_charge.total_credits_used])
			temp_csv_data.push([])
			temp_csv_data.push(['S.No', "Email", 'Roles', "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', 'Credits Used'])
			let t = unbilled_usage.user_credits_summary && unbilled_usage.user_credits_summary.length ? unbilled_usage.user_credits_summary.map((user_usage, idx) => {
				let user_roles_str = ''
				let t = user_usage.role_usage && user_usage.role_usage.length ? user_usage.role_usage.map((role_usage, id) => {if(id > 0) user_roles_str += ', ' ;user_roles_str += role_usage.role}):''

				temp_csv_data.push([idx+1, user_usage.email, user_roles_str, new Date(user_usage.usage_start_date).toString().substring(4, 15), new Date(user_usage.usage_end_date).toString().substring(4, 15), user_usage.render_usage.render?user_usage.render_usage.render.HD?.count:0, user_usage.render_usage.render?user_usage.render_usage.render.Full_HD?.count:0, user_usage.render_usage.render?user_usage.render_usage.render['4K']?.count:0, user_usage.role_credits_used])
			}):''
			console.log('temp_csv_data',temp_csv_data)
			set_usage_csv_data(temp_csv_data)
		}
	}, [unbilled_usage]);

	const Billing_history_table = () => (
		<>
			<div className='flex_property' style={{justifyContent:'space-between', marginTop:'8px', backgroundColor:'#f2f4f6'}}>
				<div style={{textAlign:'left', fontSize:'15px', padding:'8px', marginLeft:'8px',fontWeight:'900'}}>Usage Summary  <span style={{color:'gray'}}>{'(' + (selected_month_usage ? new Date(selected_month_usage.start_date).toString().substring(4, 15) : '') + ' - ' + (selected_month_usage ? new Date(selected_month_usage.end_date).toString().substring(4, 15) : '') + ')'}</span></div>
				<ReactDatetime className='rdt1' style={{cursor:'pointer'}} closeOnSelect onChange={d => {set_selected_date(months[d.month()] + ', ' + d.year())}} value={selected_date} inputProps={{placeholder: "Month Wise Usage"}} dateFormat="YYYY-MM" maxDate={new Date("2023-10")} timeFormat={false}/>
			</div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Type</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Credits Cost</span></th>
					</tr>	
				</thead>
				
				{
					selected_month_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Platform Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.platform_credits}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Extra Usage Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.designer_extra_credits}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Render Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.render_credits}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Total Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{selected_month_usage.amount}</span></td>
						</tr>
					</tbody>:""
				}
			</table>
			<div style={{textAlign:'left', fontSize:'15px', padding:'8px', fontWeight:'900', backgroundColor:'#f2f4f6'}}><div style={{marginLeft:'8px'}}>Monthly Usage </div></div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'2'}}><span>Email</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Start Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>End Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Full HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Credits used</span></th>
					</tr>	
				</thead>
				
				{
					selected_month_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
					{
						selected_month_usage && selected_month_usage.user_credits_summary && selected_month_usage.user_credits_summary.length ? selected_month_usage.user_credits_summary.map((user_usage, idx) => (
							<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
								<td className='td_style flex_property' style={{flex:'2'}}><span>{user_usage.email}</span></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.usage_start_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.usage_end_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render? user_usage.render_usage.render.HD : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render? user_usage.render_usage.render.Full_HD : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render ? user_usage.render_usage.render['4K'] : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.role_credits_used}</td>
								{/* <td className='td_style flex_property' style={{flex:'1'}}>{user_usage.extra_usage_INR_cost}</td> */}
							</tr>
						)):""
					}
					</tbody>:""
				}
			</table>
		</>
	)

	const Unbilled_usage_table = () => (
		<>
			<div style={{backgroundColor:'#f2f4f6'}}>
				<div style={{textAlign:'left', fontSize:'15px', padding:'8px', marginLeft:'8px', marginTop:'8px', fontWeight:'900'}}>Estimated Cost  <span style={{color:'gray'}}>{('(' + (unbilled_usage ? new Date(unbilled_usage.next_bill_start_date).toString().substring(4, 15) : '')) + ' - ' + (unbilled_usage ? new Date(unbilled_usage.unbilled_till).toString().substring(4, 15) : '') + ')'}</span></div>
			</div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Type</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Credits Cost</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>INR</span></th>
					</tr>	
				</thead>
				
				{
					unbilled_usage && unbilled_usage.estimate_charge ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Platform Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.platform_fee_credit_cost}</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.platform_INR_cost}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Extra Usage Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.extra_usage_credit_cost}</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.extra_usage_INR_cost}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Render Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_render_credit_cost}</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_render_INR_cost}</span></td>
						</tr>
						<tr className='tr_style'>
							<td className='td_style flex_property' style={{flex:'1'}}><span>Total Cost</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_credits_used}</span></td>
							<td className='td_style flex_property' style={{flex:'1'}}><span>{unbilled_usage.estimate_charge.total_INR_cost}</span></td>
						</tr>
					</tbody>:""
				}
				
			</table>
			<div style={{textAlign:'left', fontSize:'15px', padding:'8px', fontWeight:'900', backgroundColor:'#f2f4f6'}}><div style={{marginLeft:'8px'}}>Current Usage</div></div>
			<table style={{height:'100%'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<th className='th_style flex_property' style={{flex:'2'}}><span>Email</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Start Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>End Date</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Full HD Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>4K Renders</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Credits used</span></th>
						<th className='th_style flex_property' style={{flex:'1'}}><span>Amount</span></th>
					</tr>	
				</thead>
				
				{
					unbilled_usage ?
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
					{
						unbilled_usage && unbilled_usage.user_credits_summary && unbilled_usage.user_credits_summary.length ? unbilled_usage.user_credits_summary.map((user_usage, idx) => (
							<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
								<td className='td_style flex_property' style={{flex:'2'}}><span>{user_usage.email}</span></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.usage_start_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}><Moment format="DD/MM/YYYY">{user_usage.usage_end_date}</Moment></td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render? user_usage.render_usage.render.HD?.count : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render? user_usage.render_usage.render.Full_HD?.count : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.render_usage.render ? user_usage.render_usage.render['4K']?.count : '0'}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.role_credits_used}</td>
								<td className='td_style flex_property' style={{flex:'1'}}>{user_usage.extra_usage_INR_cost}</td>
							</tr>
						)):""
					}
					</tbody>:""
				}
			</table>
		</>
	)

	const Dues_table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
				<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
					<th className='th_style flex_property' style={{flex:'2'}}><span>Order Id</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Status</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Price</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Tax</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Total</span></th>
					<th className='th_style flex_property' style={{flex:'1'}}><span>Payment Link</span></th>
				</tr>
			</thead>
			<tbody style={{width:"100%"}}>
			{
				dues && dues.length ? dues.map((single_due, idx) => (
					<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
						<td className='td_style flex_property' style={{flex:'2'}}><span>{single_due.payment_gateway_order_id}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.status}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.price}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.tax}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><span>{single_due.total}</span></td>
						<td className='td_style flex_property' style={{flex:'1'}}><div style={{marginBottom:'2px'}}><Button className='blue_button flex_center' onClick={() => payment_function(single_due)} style={{fontSize:'14px', minHeight:'28px', height:'28px'}}>Pay</Button></div></td>
					</tr>
				)):''
			}
			</tbody>
		</table>
	)

	return (
		<Main_Grid active_tab="billing" active_division_id='usage' user={user} store_details={store_details}>
		{
			store_details && !store_details.premium_customer ? 
			<ShowInfurniaPlans 
				payment_function={payment_function} 
				set_page_loader = {set_page_loader} 
				set_first_order = {set_first_order}
				plan_selected = {plan_selected}
				set_plan_selected = {set_plan_selected}	
			/>
			:
			<>
				<Row style={{padding:'10px 10px', color:'#22272e'}}>
					<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'14px'}}>
							{tabs==1? 'Viewing Unpaid Orders' :(tabs == 2 ? 'Viewing Billing History' : 'Viewing Unbilled Usage')}
					</Col>

					<Col xs={6} style={{textAlign:'right'}}>
					{
						tabs != 1 ? 
						<CSVLink data = {usage_csv_data} filename={"Usage_CSV"} style={{alignItems:'center'}} >
							<button onClick = {() => {}} style={{all: 'unset', cursor:'pointer'}}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center', backgroundColor:'#007ef4', boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',borderRadius: '3px',height:'32px',border:'0px', color:'white', padding:'0px 24px', fontSize:'14px'}}>Download CSV</span></button>
						</CSVLink>:""
					}
					</Col>
				</Row>

				<Row style={{height:'calc(100% - 50px)'}}>
					<Col style={{height:'100%'}}>
						<Card style={{borderRadius: 0, height:'100%', overflowY:'scroll'}}>
							<Nav 
								style={{display:'flex', alignItems:'center'}}
								className="flex-md-row"
								id="tabs-icons-text" 
								pills
								role="tablist"
							>
								{
									process.env.REACT_APP_ENV === 'stage' ? 
									<NavItem>
										<NavLink
											aria-selected={tabs === 1}
											style = {{marginLeft:'10px'}}
											className={classnames("mb-sm-3 mb-md-0", {
												active: tabs === 1
											})}
											onClick={e => toggleNavs(e, 1)}
											href="#pablo"
											role="tab"
										>
											<span style={{fontSize:'14px'}}>{'Unpaid Orders'}</span>
										</NavLink>
									</NavItem>:""
								}
								<NavItem>
									<NavLink
										aria-selected={tabs === 2}
										style = {{marginLeft : process.env.REACT_APP_ENV === 'stage' ? '' :'10px'}}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 2
										})}
										onClick={e => toggleNavs(e, 2)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{'Billing History'}</span>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										aria-selected={tabs === 3}
										className={classnames("mb-sm-3 mb-md-0", {
											active: tabs === 3
										})}
										onClick={e => toggleNavs(e, 3)}
										href="#pablo"
										role="tab"
									>
										<span style={{fontSize:'14px'}}>{'Current Usage'}</span>
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs1'} style={{fontSize:'12px'}}>
								{
									dues && dues.length ? <Dues_table/> : <div style={{fontSize:"18px"}}>There are no dues</div>
								}
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs2'} style={{fontSize:'12px'}}>
								{/* <InputGroup className="input-group-alternative" style={{border:'1px solid #5E72E4', width:'250px'}}>
									<InputGroupAddon addonType="prepend" >
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" style={{color:'#5E72E4'}} />
									</InputGroupText>
									</InputGroupAddon> */}
								{/* </InputGroup> */}
									<Billing_history_table/>
								</TabPane>
							</TabContent>

							<TabContent activeTab={"tabs" + tabs}>
								<TabPane tabId={'tabs3'} style={{fontSize:'12px'}}>
									{/* <div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_custom_roomtypes.length ? (<Unbilled_usage_table display_roomtypes={display_custom_roomtypes} tab={custom_mode}/>) : (<Filler initialized={initialized_custom}/>)}</div> */}
									<Unbilled_usage_table />
								</TabPane>
							</TabContent>
						</Card>
					</Col>	
				</Row>
			</>
		}
		<PaymentConfirmationModal open={show_payment_confirmation_modal} handleClose={() => {set_show_payment_confirmation_modal(false); set_order_status('')}} order_status={order_status} first_order={first_order} plan_selected = {plan_selected}/>
		</Main_Grid>
	);
}	


export default Billing_usage;

