import React , { useState , useEffect, useRef }from 'react';
import general_fetch from '../../../utils/fetch';
import take_sku_snapshot from '../../../utils/take_sku_snapshot.js';
import { Modal, FormGroup, Button, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import UploadFile from "../../../components/UploadFile";
import { useAlert } from 'react-alert'
import Add_new_material from '../../../components/Add_new_material'
import Upload3d_modal_modal from "../../../components/Upload_3d_modal/";
import CardFooter from 'reactstrap/lib/CardFooter';
import KeyValuePair from '../../../components/KeyValuePair';
import is_valid_add_key from '../../../utils/is_valid_add_key';
const { uuid } = require('uuidv4');
const crypto = require('crypto');
const format = require('biguint-format');

const AddNewSKU = ({ brands, open , handleClose, user, active_inventory_item, sku_category_id, sku_category_name, sku_category_type, fetch_sku_groups, active_division_id, fetch_material_template, material_template, materials_json,set_page_loader, texture_json, all_allowed_key, set_border_animation_group, set_pre_selected_sku}) => {
	
	const alert = useAlert()
	const [ selected_placement , set_selected_placement ] = useState('base');
	const [ selected_brand , set_selected_brand ] = useState('none');
	const [ selected_file , set_selected_file ] = useState(null)
	const [ global_checked , set_global_checked ] = useState(false);
	const [ file , set_file ] = useState("");
	const [ sku_name , set_sku_name ] = useState("");
	const [ model_no , set_model_no ] = useState("");
	const [ sku_order , set_sku_order ] = useState(0);
	const [ return_material_details , set_return_material_details ] = useState('');
	const [base_texture_url, set_base_texture_url] = useState('')
	// const [ return_confirm_high_id , set_return_confirm_high_id ] = useState('');
	// const [ return_confirm_high_model , set_return_confirm_high_model ] = useState('');
	const [ return_confirm_low_resp_data , set_return_confirm_low_resp_data ] = useState('');
	// const [ return_confirm_low_model , set_return_confirm_low_model ] = useState('');
	const [ show_Upload3d_modal_modal , set_show_Upload3d_modal_modal ] = useState(false);
	const [ show_add_new_material , set_show_add_new_material ] = useState(false);

	const [ height , set_height ] = useState(1)
	const [ width , set_width ] = useState(undefined)
	const [ depth , set_depth ] = useState(undefined)
	// const [ max_height , set_max_height ] = useState(undefined)
	// const [ max_width , set_max_width ] = useState(undefined)
	// const [ max_depth , set_max_depth ] = useState(undefined)
	// const [ min_height , set_min_height ] = useState(undefined)
	// const [ min_width , set_min_width ] = useState(undefined)
	// const [ min_depth , set_min_depth ] = useState(undefined)
	const [ material_mode , set_material_mode ] = useState(undefined)
	const [is_allow_material , set_is_allow_material] = useState(false)
	const [is_allow_material_and_depth , set_is_allow_material_and_depth] = useState(false)

	const [ additional_json_item_default, set_additional_json_item_default ] = useState([]);
	const [ additional_json_item_custom, set_additional_json_item_custom ] = useState([]);
	const [ material_preview, set_material_preview ] = useState(null);
	const [show_additional_properties, set_show_additional_properties] = useState();

	const myRef = useRef(null)


	const[item_json , set_item_json] = useState([]);
	// const[current_state_alert , set_current_state_alert] = useState(null);


	const onclick_handleClose = () => {
		set_sku_name("")
		set_file("")
		set_selected_placement('base')
		set_selected_brand('none')
		handleClose()
		set_return_confirm_low_resp_data('')
		// set_depth(undefined)
		set_width(undefined)
		set_height(undefined)
		// set_max_depth(undefined)
		// set_max_width(undefined)
		// set_max_height(undefined)
		// set_min_depth(undefined)
		// set_min_width(undefined)
		// set_min_height(undefined)
		set_return_material_details('')
		set_model_no('')
		set_sku_order(0)
		set_is_allow_material(false)
		set_is_allow_material_and_depth(false)
		set_additional_json_item_custom([])
		set_additional_json_item_default([])
		set_show_additional_properties(false)
	}

	useEffect(() =>{
		if (!open) {
			return;
		}
		console.log("active div/ cat type change : ", active_division_id, sku_category_type)
		if(active_division_id==='finish'|| (active_division_id==='hardware'&& (sku_category_type==='panel_core_hardware' || sku_category_type ==='mirror_hardware'))){
			set_is_allow_material(true)
		}
		if(active_division_id==='hardware' && (sku_category_type==='skirting_hardware'||sku_category_type==='edge_band_hardware')){
			set_is_allow_material_and_depth(true)
			set_is_allow_material(true)
		}
	},[active_division_id, sku_category_type, open])
	
	useEffect(() =>{
		if(return_confirm_low_resp_data){
			alert.success('OBJ model files loaded successfully')
		}
	},[return_confirm_low_resp_data])
	
	const get_unique_sku_id = () => {
        const id = crypto.randomBytes(10);
        return format(id, 'hex');
    }

	const onClick_submit_3dmodel = async () => {
		// if(item_json && item_json.length>1 && !is_valid_add_key(item_json)){
		// 	alert.error("Empty or Duplicate key values not allowed.")
		// }
		if(additional_json_item_default && additional_json_item_default.length>1 && !is_valid_add_key(additional_json_item_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(additional_json_item_custom && additional_json_item_custom.length>1 && !is_valid_add_key(additional_json_item_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}
		else if( selected_placement && sku_name && active_inventory_item ){
			try {
				var x_addi_prop = [];
				additional_json_item_default.map(x => {
					if(additional_json_item_default[0].key){
						x_addi_prop.push(x)
					}
				})
				additional_json_item_custom.map(x => {
					if (additional_json_item_custom[0].key) {
						x_addi_prop.push(x)
					}
				}) 

				var fd = new FormData();
				if(selected_brand != 'none') fd.append('brand_id', selected_brand)
				fd.append('placement_id', selected_placement)
				fd.append('name', sku_name)
				if(is_allow_material && height) {
					fd.append('height', height)
				}
				if(sku_category_type === 'shutter_hardware') {
					
					if(!height){
						alert.error('Please enter Thickness')
						return
					}
					if(!width){
						alert.error('Please enter Width')
						return
					}
					if(!depth){
						alert.error('Please enter Height')
						return
					}

					let sku_id = get_unique_sku_id()
					let component_file = [{
						id: uuid(),
						container_component_id: null,
						type: "obj_unit",
						data: {
							sku_id: sku_id,
							width: Number(width),
							depth: Number(depth),
							height: Number(height)
						}
					}]
					fd.append('component_file', JSON.stringify(component_file))
					fd.append('sku_id', sku_id)
				}
				// fd.append('width', width?width:undefined)
				// fd.append('depth', depth?depth:undefined)
				// fd.append('max_height', max_height?max_height:undefined)
				// fd.append('max_width', max_width?max_width:undefined)
				// fd.append('max_depth', max_depth?max_depth:undefined)
				// fd.append('min_height', min_height?min_height:undefined)
				// fd.append('min_width', min_width?min_width:undefined)
				// fd.append('min_depth', min_depth?min_depth:undefined)
				fd.append('order', sku_order?sku_order:0)
				fd.append('model_no', model_no?model_no:'')
				fd.append('sku_group_id', active_inventory_item)
				fd.append('store_id', user.store_id)
				fd.append('global', global_checked)
				fd.append('additional_properties', JSON.stringify(x_addi_prop))
				return_confirm_low_resp_data && fd.append('low_model_3d_id', return_confirm_low_resp_data.id)

				if(file && file.size <= 10000000){
					fd.append('upl', file, file.name)
				}else if(file){
					alert.error('Please select a file of size less than 10MB')
					return
				}

				console.log('add new sku', fd)
				var resp = await general_fetch({ url: 'sku/add', body:fd, is_form_data:true });
				// console.log('resp==>',resp)
				set_border_animation_group(active_inventory_item)
				set_pre_selected_sku(resp ? resp.id : '')
				// return_confirm_low_resp_data && take_sku_snapshot(return_confirm_low_resp_data.path, return_confirm_low_resp_data.mtl_path, global.config.server_path, return_confirm_low_resp_data.glb_src, return_confirm_low_resp_data.scale)
				fetch_sku_groups();
				alert.success('SKU added successfully')
				onclick_handleClose();
				
			}catch(err) {
				console.log(err);
				alert.error('Error , Not able to add SKU')
			}
		}else{
			alert.error('Please fill all reqired details')
		}
	}

	const onclick_submit_for_material = async () => {
		// if(item_json && item_json.length>1 && !is_valid_add_key(item_json)){
		// 	alert.error("Empty or Duplicate key values not allowed.")
		// }
		if(additional_json_item_default && additional_json_item_default.length>1 && !is_valid_add_key(additional_json_item_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(additional_json_item_custom && additional_json_item_custom.length>1 && !is_valid_add_key(additional_json_item_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}
		else if( sku_name && return_material_details){
			try {
				var x_addi_prop = [];
				additional_json_item_default.map(x => {
					if(additional_json_item_default[0].key){
						x_addi_prop.push(x)
					}
				})
				additional_json_item_custom.map(x => {
					if (additional_json_item_custom[0].key) {
						x_addi_prop.push(x)
					}
				}) 

				var fd = new FormData();
				if(selected_brand != 'none') fd.append('brand_id', selected_brand)
				sku_category_type==='panel_core_hardware' && fd.append('placement_id', selected_placement)
				fd.append('name', sku_name)
				if(height) fd.append('height', height)
				if(width) fd.append('width', width)
				if(is_allow_material_and_depth || sku_category_type == 'tile_finish'){
					if(depth)fd.append('depth', depth)
				}
				// fd.append('max_height', max_height?max_height:undefined)
				// fd.append('max_width', max_width?max_width:undefined)
				// fd.append('max_depth', max_depth?max_depth:undefined)
				// fd.append('min_height', min_height?min_height:undefined)
				// fd.append('min_width', min_width?min_width:undefined)
				// fd.append('min_depth', min_depth?min_depth:undefined)
				fd.append('model_no', model_no?model_no:'')
				fd.append('order', sku_order?sku_order:0)
				fd.append('sku_group_id', active_inventory_item)
				fd.append('store_id', user.store_id)
				fd.append('global', false)
				fd.append('material_id', return_material_details.id)
				file && fd.append('upl', file, file.name)
				fd.append('additional_properties', JSON.stringify(x_addi_prop))
				var resp = await general_fetch({ url: 'sku/add', body:fd, is_form_data:true });
				set_border_animation_group(active_inventory_item)
				set_pre_selected_sku(resp ? resp.id : '')
				fetch_sku_groups();
				alert.success('SKU added successfully')
				onclick_handleClose();
				
			}catch(err) {
				console.log(err);
				alert.error('Error , Not able to add SKU')
			}
		}else{
			alert.error('Either Name or Material info/ID is empty')
		}
	}

	const close_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(false)
	}
	const open_Upload3d_modal_modal = () =>{
		set_show_Upload3d_modal_modal(true)
	}

	const close_add_new_material = () =>{
		set_show_add_new_material(false)
		set_material_mode('')
	}
	const open_add_new_material = () =>{
		set_show_add_new_material(true)
		set_material_mode('add')
	}

	const open_edit_material = () => {

		set_show_add_new_material(true)
		set_material_mode('edit')
	}

	const add_dummy_material = async() => {
		try{
			let x = material_template[0]

			let properties = x.properties && x.properties.length && x.properties.reduce((final, elem)=> {
				return {
					...final, [elem.label]: elem.value.value!=null?elem.value.value:''
				}
			}, {});

			let white_image_blob = ''
			var url = '';
			let check_enterprise_white_labelled_url = (window.location.host.split('.')[1] == 'infurnia') ? false : true;
			if (check_enterprise_white_labelled_url) {
				let [ subdomain, domain, extension ] = window.location.host.split('.');
				url = `https://admin.${domain}.${extension}/`;
			} else {
				url = global.config.admin_url;
			}
			let a = await fetch(url+'assets/img/icons/white_image.png').then(async resp => {white_image_blob = await resp.blob(); console.log('white_image_blob', resp, white_image_blob)})
			
			var fd = new FormData();	
            fd.append('file', new File([white_image_blob], "white_image"))
            fd.append('name', 'white_image')
			console.log('white_image_blob 1', white_image_blob)
			let resp = await general_fetch({url:'texture/add', body:fd, is_form_data:true})
			properties.map = resp.id

			if(resp && resp.id){
				resp = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[resp.id]})}})
				console.log('white_image_blob 2', resp)
				set_base_texture_url(resp[0].Image.image)
			}

			var body={	
				name:x.name, 
				properties:JSON.stringify(properties), 
				material_template_id: x.id,
			}
			resp = await general_fetch({ url: 'material/add', body });
			let material_id = resp.id;
			
			var identifiers = JSON.stringify({id:material_id})
			var attributes = JSON.stringify({})
			var response = await general_fetch({url :'material/get' , body: {identifiers, attributes} }) 
			set_return_material_details(response && response.length ?response[0]:[])
		}catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		if(show_additional_properties){
			let t = myRef.current?.scrollIntoView({behavior: 'smooth'})
		}
	}, [show_additional_properties, ]);

	// const get_base_texture_url = async() => {
	// 	try{
	// 		if(return_material_details){
	// 			let texture_id = return_material_details.properties.map
	// 			var texture_get = await general_fetch({url:'texture/get', body:{identifiers:JSON.stringify({id:[texture_id]})}})
	// 			if(texture_get) set_base_texture_url(texture_get[0] && texture_get[0].Image ? texture_get[0].Image.image :'')
	// 		}
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// }

	useEffect(() => {
		if(open && sku_category_type === 'panel_core_hardware' && material_template && material_template.length){
			add_dummy_material()
		}
	}, [sku_category_type, material_template, open]);

	const AddMaterialbox = () => {
		return(
			<Row style={{marginBottom:"10px"}}>
				<Col>
					<div className='flex_property' style={{fontWeight:'900'}}>{sku_category_type=='panel_core_hardware'?'Material Info / Display Image':'Material Info*'}</div>
					<div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
						{
							return_material_details?
							<>
								<div style={{cursor:'pointer', fontSize:'13px', marginBottom:'5px'}} onClick={() => open_edit_material()}><i style={{marginRight:'5px'}} className='fa fa-edit'/><span>Edit Material</span></div>
								<img src={base_texture_url ? (global.config.server_path.slice(0,-1) + base_texture_url) : '/assets/img/icons/empty_image.png'}  width='135px' height='80px' onClick={() => open_edit_material()} style={{cursor:'pointer'}}></img>
								<div style={{fontSize:'13px', marginTop:'5px'}}><i style={{marginRight:'5px', color:'#47B881'}} className='fa fa-check-circle'/><span>Material Added</span></div>
							</>
							:
							(
								<div onClick={open_add_new_material} style={{width:'170px', height:'120px', backgroundColor:'white', borderRadius:'4px', border:'1px dashed #A7A8B3', padding:'0px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center',cursor:'pointer', textAlign:'center'}}>
									<span style={{fontSize:'32px'}}>+</span>
									<span style={{fontSize:'13px'}}>Add Material</span>
									<span style={{fontSize:'12px', color:'#A7A8B2', padding:'5px'}}>Click to add material details to the SKU</span>
								</div>
							)
						}
						{/* {return_material_details?<span style={{marginLeft:'20px', fontSize:'14px'}}><b>New Material</b> : {return_material_details.name}</span>:''} */}
					</div>
				</Col>
			</Row>
		)
	}

				
	return (
		<div>
		<Upload3d_modal_modal 
			open={show_Upload3d_modal_modal} 
			handleclose={close_Upload3d_modal_modal} 
			set_return_confirm_low_resp_data={set_return_confirm_low_resp_data}
			return_confirm_low_resp_data={return_confirm_low_resp_data}
			active_division_id={active_division_id}
			set_page_loader={set_page_loader}
		/>
		<Add_new_material 
			set_return_material_details={set_return_material_details} 
			return_material_details={return_material_details} 
			open={show_add_new_material} 
			handle_close={close_add_new_material} 
			fetch_material_template={fetch_material_template} 
			material_template={material_template} 
			materials_json={materials_json}
			set_page_loader={set_page_loader} 
			texture_json={texture_json}
			material_mode={material_mode}
			set_material_mode={set_material_mode}
			show_restore_button={true}
			active_material_template = {return_material_details}
			base_texture_url = {base_texture_url}
			set_base_texture_url ={set_base_texture_url}
		/>
		<Modal className="Margin_64px_top" size="lg" isOpen={open} toggle={onclick_handleClose} >
			<Card className='global_modal_height_for_mdlgxl' style={{height:is_allow_material?'665px':'620px'}}>
				<CardHeader className='global_modal_header'>
						<span>Add New SKU</span>
						<i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
				</CardHeader>

				{	
					is_allow_material?(
						<React.Fragment>
						<CardBody className='global_modal_body' >
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name *</div>
										<Input id='sku_name_input' value = {sku_name} onChange = {(e) => set_sku_name(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" />
									</div>
								</Col>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Model No. / SKU Code</div>
										<Input id='model_no_input' value = {model_no} onChange = {(e) => set_model_no(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Model No. / SKU Code" type="text" />
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Viewing Order</div>
										<Input id='sku_order_input' value = {sku_order} onChange = {(e) => set_sku_order(Number(e.target.value ? e.target.value : 0))} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Order" type="number" />
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div style={{fontFamily: 'Source_Sans_Pro-SemiBold',fontSize: '14px', color: '#234361', letterSpacing: '-0.04px'}}>Product Dimensions (mm)</div>
								</Col>	
							</Row>
							{/* <Row style={{marginTop:'5px', marginBottom:'10px'}}>
								<Col>
									<FormGroup >
										<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px"  >Depth</label>
										<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
									</FormGroup>
								</Col>
							</Row> */}
							 <Row style={{marginTop:'5px', marginBottom:'10px'}}>
								<Col>
									<FormGroup>
										<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px"  >Thickness</label>
										<Input id='sku_height_input' className='input_box' type="number" placeolder='0' value={height?height:undefined} onChange = {(e) => set_height(e.target.value) } />
									</FormGroup>
								</Col>
								{ 
									is_allow_material_and_depth?
									<Col>
										<FormGroup >
										<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px"  >{sku_category_type==='edge_band_hardware'?'Tape Width':'Height'}</label>
											<Input id='sku_depth_input' className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
										</FormGroup>
									</Col>
									:''
								}
								{
									sku_category_type == 'tile_finish'?
									<Col>
										<FormGroup >
											<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px">Width</label>
											<Input id='tile_width_input' className='input_box' type="number" placeolder='0' value={width?width:undefined} onChange = {(e) => set_width(e.target.value) } />
										</FormGroup>
									</Col>:''
								}
							</Row>
							<Row style={{marginBottom:"10px"}}>
								{
									active_division_id==='finish'?"":
									<Col>
										<div>
											<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Placement:</div>
											<Input id='sku_placement_input' value = {selected_placement} onChange={e => set_selected_placement(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
												<option value='base'>Base</option>
												<option value='base_corner'>Base Corner</option>
												<option value='wall'>Wall</option>
												<option value='wall_corner'>Corner Wall</option>
												<option value='ceiling'>Ceiling</option>
												<option value='default'>Default</option>
											</Input>
										</div>
									</Col>
								}
								{
									sku_category_type == 'tile_finish'?
									<Col>
										<FormGroup >
											<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px">Height</label>
											<Input id='tile_depth_input' className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
										</FormGroup>
									</Col>:''
								}
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Brand:</div>
										<Input id='sku_brand_input' value = {selected_brand} onChange={e => set_selected_brand(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
											<option value='none'>None</option>
											{
												brands.map((list_value, idx)=>
													<option key={idx} value={list_value.id}>{list_value.name}</option>
												)	
											}	
										</Input>
									</div>
								</Col>						
							</Row>
							{/* <div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>				
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Update Display Image</div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{file ? file.name : ""}</div>
										<UploadFile id="id_upload_file" set_file={set_file} accept=".jpg,.jpeg,.png" />
									</div>
								</Col>
							</Row> */}
							<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>				
							<AddMaterialbox />
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)', margin:"20px 0px"}}></div>
							<div style={{fontWeight:600, cursor:'pointer'}} onClick={()=>set_show_additional_properties(!show_additional_properties)}>
								<span style={{marginRight:'15px',fontSize:'16px'}}>{'Additional Properties'}</span>
								<i className={show_additional_properties?'fa fa-caret-up':'fa fa-caret-down'}/>
							</div>
							{
								show_additional_properties?
								<div ref={myRef}>
									<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
									<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom' all_allowed_key={all_allowed_key} />
								</div>:''
							}
						</CardBody>
						<CardFooter className='global_modal_footer'>
							<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
							<Button className='blue_button' type="button" onClick={onclick_submit_for_material} >Submit</Button>
						</CardFooter>
						</React.Fragment>
						
					):(
						<React.Fragment>
						<CardBody className='global_modal_body' >
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Name *</div>
										<Input id='sku_name_input' value = {sku_name} onChange = {(e) => set_sku_name(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Name" type="text" />
									</div>
								</Col>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Model No. / SKU Code</div>
										<Input id='model_no_input' value = {model_no} onChange = {(e) => set_model_no(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Model No. / SKU Code" type="text" />
									</div>
								</Col>
							</Row>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Viewing Order</div>
										<Input id='sku_order_input' value = {sku_order} onChange = {(e) => set_sku_order(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} placeholder="Order" min={0} type="number" />
									</div>
								</Col>
							</Row>
							<div style={{paddingTop:'20px' , paddingBottom:'15px'}}><div style={{borderTop:'1px solid #ECECEC'}}></div></div>
							<Row style={{marginBottom:"10px"}}>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Brand:</div>
										<Input id='sku_brand_input' value = {selected_brand} onChange={e => set_selected_brand(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
											<option value='none'>None</option>
											{
												brands.map((list_value, idx)=>
													<option key={idx} value={list_value.id}>{list_value.name}</option>
												)	
											}	
										</Input>
									</div>
								</Col>
								<Col>
									<div>
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Placement:</div>
										<Input id='sku_placement_input' value = {selected_placement} onChange={e => set_selected_placement(e.target.value)} style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20)', borderRadius: '3px'}} type="select">
											<option value='base'>Base</option>
											<option value='base_corner'>Base Corner</option>
											<option value='wall'>Wall</option>
											<option value='wall_corner'>Wall Corner</option>
											<option value='ceiling'>Ceiling</option>
											<option value='default'>Default</option>
										</Input>
									</div>
								</Col>
							</Row>
							{
								sku_category_type === 'shutter_hardware' ?
								<Row>
									<Col>
										<FormGroup >
											<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px">Thickness</label>
											<Input className='input_box' type="number" placeolder='0' value={height?height:undefined} onChange = {(e) => set_height(e.target.value) } />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup >
											<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px">Width</label>
											<Input className='input_box' type="number" placeolder='0' value={width?width:undefined} onChange = {(e) => set_width(e.target.value) } />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup >
											<label style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}} className="label_margin_0px">Height</label>
											<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
										</FormGroup>
									</Col>
								</Row>:''
							}
							
							{/* <React.Fragment>
								<Row>
									<Col>
										<div style={{fontFamily: 'Source_Sans_Pro-SemiBold',fontSize: '14px', color: '#234361', letterSpacing: '-0.04px'}}>Product Dimensions (mm)</div>
									</Col>	
								</Row>
								<Row style={{marginTop:'5px'}}>
									<Col>
										<FormGroup>
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Width</div>
											<Input className='input_box' type="number" placeolder='0' value ={width?width:undefined} onChange = {(e) => set_width(e.target.value) } />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup >
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Depth</div>
											<Input className='input_box' type="number" placeolder='0' value={depth?depth:undefined} onChange = {(e) => set_depth(e.target.value) } />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup >
											<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Height</div>
											<Input className='input_box' type="number" placeolder='0' value={height?height:undefined} onChange = {(e) => set_height(e.target.value) } />
										</FormGroup>
									</Col>
								</Row>
							</React.Fragment> */}
						
							{/* <Row style={{marginTop:'5px'}}>
								<Col>
									<FormGroup>
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Min-width</div>
										<Input className='input_box' type="number" value ={min_width?min_width:undefined} onChange = {(e) => set_min_width(e.target.value) } />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup >
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Min-depth</div>
										<Input className='input_box' type="number" value={min_depth?min_depth:undefined} onChange = {(e) => set_min_depth(e.target.value) } />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup >
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Min-height</div>
										<Input className='input_box' type="number" value={min_height?min_height:undefined} onChange = {(e) => set_min_height(e.target.value) } />
									</FormGroup>
								</Col>
							</Row>
							<Row style={{marginTop:'5px'}}>
								<Col>
									<FormGroup>
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Max-width</div>
										<Input className='input_box' type="number" value ={max_width?max_width:undefined} onChange = {(e) => set_max_width(e.target.value) } />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup >
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Max-depth</div>
										<Input className='input_box' type="number" value={max_depth?max_depth:undefined} onChange = {(e) => set_max_depth(e.target.value) } />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup >
										<div style={{fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}  >Max-height</div>
										<Input className='input_box' type="number" value={max_height?max_height:undefined} onChange = {(e) => set_max_height(e.target.value) } />
									</FormGroup>
								</Col>
							</Row> */}
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)', margin:"20px 0px"}}></div>
							<Row style={{marginBottom:"8px"}}>
								<Col >
									<div>
										{console.log("file", file)}
										<div style={{paddingBottom:'3px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Update Display Image</div>
										<div style={{paddingBottom:'6px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>{file ? file.name : ""}</div>
										<UploadFile id="sku_image_upload" set_file={set_file} file_size={file?file.size:''} accept=".jpg,.jpeg,.png" />
										{
											file && file.size>10000000?
											<div style={{paddingTop:'7px',fontSize:'12px', color:'red', letterSpacing: '-0.04px', lineHeight: '20px'}}>File size exceeded, Please select a file of size less than 10 MB</div>
											:
											!file?<div style={{paddingTop:'7px',fontSize:'12px', color:'#425A70', letterSpacing: '-0.04px', lineHeight: '20px'}}>Please select a file of size less than 10 MB</div>:''
								
										}
									</div>
								</Col>
							</Row>
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)', margin:"20px 0px"}}></div>
							<Row style={{marginBottom:"10px"}}>
								<Col xs={6}>
									<div style={{display:'flex', alignItems:'center'}}>
										<Button onClick={open_Upload3d_modal_modal} style={{backgroundColor: '#f0ad4e', borderColor: '#f0ad4e', color:'white', fontSize:'13px'}}>Upload 3d model</Button>
										{return_confirm_low_resp_data?<div style={{marginLeft:'20px',paddingBottom:'3px',fontSize:'12px', letterSpacing: '-0.04px', lineHeight: '20px'}}><span style={{color:'white', padding:'2px' ,backgroundColor:'green', whiteSpace:"nowrap"}}>files Uploaded Successfully</span></div>:''}
									</div>
								</Col>
							</Row>

							{/* Key value pair */}
							<div style={{ borderBottom:'1px solid rgba(0, 0, 0, 0.125)', margin:"20px 0px"}}></div>
							<div style={{fontWeight:600, cursor:'pointer'}} onClick={()=>set_show_additional_properties(!show_additional_properties)}>
								<span style={{marginRight:'15px',fontSize:'16px'}}>Additional Properties</span>
								<i className={show_additional_properties?'fa fa-caret-up':'fa fa-caret-down'}/>
							</div>
							{
								show_additional_properties?
								<div ref={myRef}>
									<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
									<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom' all_allowed_key={all_allowed_key} />
								</div >:''
							}
					
						</CardBody>
						<CardFooter className='global_modal_footer'>
							<Button className='white_button' type="button" onClick={onclick_handleClose}> Cancel </Button>
							<Button className='blue_button' type="button" onClick={onClick_submit_3dmodel} >Submit</Button>
						</CardFooter>
					</React.Fragment>
				)}
			</Card>  
		</Modal>
		</div>
	);
}

export default AddNewSKU ; 