import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

import DesignerInputDialogBox from'./DesignerInputDialogBox'
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import remove_underscore_wtih_first_cap from '../../utils/remove_underscore_wtih_first_cap.js';


const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};

const Filler = ({ initialized }) => (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Designer Inputs to list  </b></span><br/> Try adjusting filters <br /> &nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching Designer Input </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
					</th>
				)}
			</tr>
		</thead>
	</Table>	
);

const DesignerInput = ({ user ,submit_for_delete_confirm, set_active_tab, send_analytics_event, check_route_fn, store_details, get_store_details, set_active_division_id_sidebar}) => {
	
	const [users , set_users] = useState([]);
	const [open_designer_input_Dialog_Box, set_open_designer_input_Dialog_Box] = useState(false);
	const [active_designer_input , set_active_designer_input] = useState('');
	const [search_string , set_search_string] = useState(null);
	const [designer_input , set_designer_input] = useState([]);
	const [all_category_type , set_all_category_type] = useState([]);
	const [display_designer_input , set_display_designer_input] = useState([]);
	const [key , set_key] = useState(null);
	const [open_manage_Dialog_Box, set_open_manage_Dialog_Box] = useState(false);
	const [active_manage_designer_input , set_active_manage_designer_input] = useState({});
	const [all_brands , set_all_brands] = useState([]);

	// const [page_size , set_page_size] = useState(30)
	// const [fetch_progress , set_fetch_progress] = useState(40)
	// const [current_page , set_current_page] = useState(1)
	const [initialized , set_initialized] = useState(false) 
 	// const [all_pages , set_all_pages] = useState([])
	const alert = useAlert()
	const [ start_index , set_start_index ] = useState('');
	const [ end_index , set_end_index ] = useState('');

	const fetch_designer_input_details = async (id) => {
		try {
			var response = await general_fetch({ url: 'automation_rule/get_all_inputs_of_store'});
			set_display_designer_input(response);
			set_designer_input(response);
			set_initialized(true);
			
		} catch(err) {
			console.log(err);
		}
	}
	const fetch_brands = async (id) => {
		try {
			var response = await general_fetch({ url: 'brand/get'});
			set_all_brands(response)
		} catch(err) {
			console.log(err);
		}
	}

	const fetch_category_type_details = async() => {
		try {
			var response = await general_fetch({ url: 'sku_category_type/get'});
			var resp = split_cat_type(response).sort(name_sort)
			console.log("cat types", resp)
			set_all_category_type(resp);
		} catch(err) {
			console.log(err);
		}
	}

	const onclick_delete_designer_input = async (item) => {
		try {
			var body = { id: item.id }; 
			var response = await general_fetch({ url: 'automation_rule/remove_rule_input', body });
			alert.success("successfull")
			fetch_designer_input_details();
		} catch(err) { 
			alert.success("Request Fail")
			console.log(err);
		}
	}

	// useEffect(() => {
	// 	if (!initialized && display_designer_input && display_designer_input.length) {
	// 		set_initialized(true);
	// 	}
	// }, [display_designer_input]);

	
	const show_designer_input_update_dialog = (designer_input) => {
		set_open_designer_input_Dialog_Box(true);
		set_active_designer_input(designer_input);
		set_key('edit');
	}

	const close_designer_input_Dialog_Box = () => {
		set_open_designer_input_Dialog_Box(false);
		set_active_designer_input('');
		set_key(null);
	}

	const show_designer_input_add_dialog = () => {
		set_open_designer_input_Dialog_Box(true);
		set_key('add');
	}

	const filter_designer_input = () => {
		var filtered_designer_input =[...designer_input]
		var lower_search_string = search_string && search_string.length ? search_string.toLowerCase():'';
		console.log(filtered_designer_input)
		if(lower_search_string){
			filtered_designer_input = filtered_designer_input.filter( designer_input => (designer_input.name.toLowerCase().includes(lower_search_string) || (designer_input.type ? designer_input.type.toLowerCase().includes(lower_search_string):false) || (remove_underscore_wtih_first_cap(designer_input.sku_category_type_id) ? remove_underscore_wtih_first_cap(designer_input.sku_category_type_id).toLowerCase().includes(lower_search_string):false)))
		}
		set_display_designer_input(filtered_designer_input)
	}
	useEffect(() => {
			filter_designer_input();
	}, [search_string, designer_input]);


	useEffect(() => {
		set_active_tab('preferences_settings')
		set_active_division_id_sidebar('designer_input')
		// check_route_fn('designer_input')
		send_analytics_event('auto', 'designer_input', 'designer_input', 1, window.location.href)
		if(!initialized){
			fetch_designer_input_details()
		}
		if(all_category_type && !all_category_type.length){
			fetch_category_type_details()
		}
		if(all_brands && !all_brands.length){
			fetch_brands()
		}
	}, []);

	const Tag_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
			<thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
				<th className='th_style flex_property' style={{flex:'1'}}><span> Name</span></th>
				<th className='th_style flex_property' style={{flex:'1'}}><span>Type</span></th>
				<th className='th_style flex_property' style={{flex:'1'}}><span>Category Type</span></th>
				<th className='th_style flex_property' style={{flex:'1', paddingRight:'20px'}}><span></span></th>
			</tr>
			</thead>
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
			{
				display_designer_input && display_designer_input.length && display_designer_input.map((item, idx) => (
				<tr key={idx} className='tr_style display_onhover'>
					
					<td className='td_style flex_property' style={{flex:'1'}}><span className='table_text_overflow'>{item.name}</span></td>
					<td className='td_style flex_property' style={{flex:'1'}}>{item.type}</td>
					<td className='td_style flex_property' style={{flex:'1'}}>{remove_underscore_wtih_first_cap(item.sku_category_type_id)}</td>
					<td className='td_style flex_property x1' style={{ justifyContent:'flex-end', flex:1, paddingRight:'20px'}}>
						{
							user.store_id ==item.store_id ?(
								<span className='hide'>
									<i style={{marginRight:'15px'}} onClick ={() => show_designer_input_update_dialog(item)} className='fa fa-edit'/>
									<i 
										onClick={()=>submit_for_delete_confirm(()=>onclick_delete_designer_input(item), ()=>{}, 'Are you sure you want to delete this Designer Input? DI Name - '+ item.name)}
										className='fa fa-trash'
									/>
								</span>
							):''
						}
					</td>
					
				</tr>
			))}
			</tbody>
		</table>
		
	);

	return (
		<Main_Grid active_tab="designer_input" user={user} store_details={store_details} get_store_details={get_store_details}>
			<DesignerInputDialogBox open = {open_designer_input_Dialog_Box} handleClose = {close_designer_input_Dialog_Box} active_designer_input={active_designer_input} user= {user} fetch_designer_input_details = {fetch_designer_input_details} mode = {key} all_category_type={all_category_type} all_brands={all_brands}/>
			<Row style={{padding:'10px 0px', color:'#22272e'}}>
	        	<Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
	        		Viewing {display_designer_input && display_designer_input.length ?display_designer_input.length:'0'} Designer Input
	        	</Col>
	        	<Col xs={6} style={{textAlign:'right'}}>
	        		<button onClick ={() => show_designer_input_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Designer Input</span></button>
	        	</Col>
        	</Row>
	        <Row style={{height:'calc(100% - 90px)'}}>
		        <Col style={{height:'100%'}}>
		        	<Card style={{borderRadius: 0, height:'100%'}}>
		        		<div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Designer Input Name, Type, Category Type' /></div>
		        		<div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_designer_input.length ? (<Tag_Table />) : (<Filler initialized={initialized}/>)}</div>
					</Card>
		        </Col>
	        </Row>
	        <Row style={{height:'3px'}}>
		        <Col>
		        </Col>
	        </Row>
	        {/* <Row>
		        <Col>
					<Pegasus_Paginator 
						id='design-paginator'
						end_index={end_index}
						set_end_index={set_end_index}
						start_index={start_index}
						set_start_index={set_start_index}
						all_projects={display_designer_input && display_designer_input.length ? display_designer_input.length : '0'}
					/>
		        </Col>
	        </Row> */}
		</Main_Grid>
	)

}
export default DesignerInput;

//<AlertBox message ="Brand succesfull added" open_copy = {open_alert_box} current_state = {current_state_alert} handleClose ={close_alert_box} />