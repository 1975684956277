import React, { useState , useEffect, isValidElement, useRef } from 'react';
import {Modal, FormGroup, Button, Form, Input, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import general_fetch from '../../../utils/fetch';
import name_sort from '../../../utils/name_sort';
import custom_sort from '../../../utils/custom_sort';
import GroupInventoryCard from './GroupInventoryCard';
import Group_ListView from './Group_ListView';
import { useAlert } from 'react-alert'
import './sku_groups.css'

const OpenasDraftWarning = ({open, handleClose, close_history_modal, make_sku_array, attempt_id}) => {
    return (
        <div>
            <Modal style ={{marginTop:'17%', height:'150px', width:'380px'}} size="sm" isOpen={open}>
                <Card>
                    <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'5%'}}> 
                        <div style = {{paddingDown:'10px', paddingLeft:'8px', marginBottom:'5px'}}>Note that you are not editing the published SKUs <span style={{fontWeight:'900'}}>New SKUs will be created if you publish this draft</span></div>
                        <div style={{paddingLeft:'21%', paddingTop:'5%'}}>
                            <Button className='white_button' type="button" onClick={() => handleClose()}>Cancel</Button>
                            <Button className='blue_button' type="button" onClick={() => {make_sku_array(attempt_id);handleClose();close_history_modal()}}>OK</Button>
                        </div>
                    </div>
                </Card>
            </Modal>
        </div>
    )
}

const SkuPublishHistory = ({open, handleClose, user, json_of_attempts_history,active_category,active_division_id, all_users, sku_categories, edit_draft, set_active_category, viewing_published_skus, set_viewing_published_skus, get_unique_sku_id, set_page_loader}) => {

    const [display_history, set_display_history] = useState([])
    const [attempt_id, set_attempt_id] = useState();
    const [show_open_as_draft_warning, set_show_open_as_draft_warning] = useState();
    
    const alert = useAlert()
    const downloadJSONFile = async(attempt_id, file_name) => {
        try{
            let resp = await general_fetch({url:'sku_bulk_operation/get_published_skus', body:{attempt_id:attempt_id}})

            let text = JSON.stringify(resp)

            let uploadBlob = new Blob([text])
            let json_file = new File([uploadBlob], file_name)
            const element = document.createElement("a");
            element.href = URL.createObjectURL(json_file)
            element.download = file_name
            document.body.appendChild(element); 
            element.click();
        }catch(err){
            console.log(err)
            alert.error('Download Failed!')
        }
    }

    const make_sku_array = async(attempt_id) => {
        try{
            set_page_loader({
                show:true,
                text:'Please wait...'
            })
            let resp = await general_fetch({url:'sku_bulk_operation/get_published_skus', body:{attempt_id:attempt_id}})
            let temp_sku_array = resp && resp.data && resp.data.length ? resp.data.map( x => {x['id']=get_unique_sku_id();x['display_pic']='';if(!viewing_published_skus)x['display_pic_url']='';if(!viewing_published_skus)x['display_pic_name']='';x['display_pic_id']='';x['low_model_3d_id']='';x['material_id']=''; return x}):[]
            edit_draft(temp_sku_array, attempt_id, 'published_history')
            set_page_loader({
                show:false
            })
        }catch(err){
            console.log(err)
            alert.error('Viewing Failed!')
        }
    }

    const comp = (a,b) => {
        return a.time < b.time ? 1 : -1
    }

    useEffect(() => {
        if(json_of_attempts_history && active_division_id && sku_categories && sku_categories.length && all_users){

            let temp_array = []
            let t = json_of_attempts_history && Object.keys(json_of_attempts_history) && Object.keys(json_of_attempts_history).length ? Object.keys(json_of_attempts_history).map((single_category_id, idx) => (
                        json_of_attempts_history[single_category_id] && json_of_attempts_history[single_category_id].length ? json_of_attempts_history[single_category_id].map((single_attempt) => (
                            temp_array.push({time:new Date(single_attempt.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}),
                             no_of_skus: single_attempt.successful_skus_published,
                             division:active_division_id,
                             category: sku_categories.find(o => o.id == single_category_id),
                             owner:all_users[single_attempt.user_id]?all_users[single_attempt.user_id]:'',
                             attempt_id: single_attempt.id})
                        )):''
                    )):''

            console.log("publish history",temp_array)
            
            temp_array.sort(comp)
            set_display_history(temp_array)
        }
    }, [json_of_attempts_history, active_division_id, sku_categories, all_users]);

    useEffect(() => {
        console.log("display history",display_history)
    },[display_history])

    return (
        <Modal style={{marginTop:'64px', width:'800px', marginLeft:'22vw'}} size="md" isOpen={open} toggle={handleClose}>
            <OpenasDraftWarning
                open = {show_open_as_draft_warning}
                handleClose = {() => {set_show_open_as_draft_warning(false)}}
                close_history_modal = {handleClose}
                make_sku_array = {make_sku_array}
                attempt_id = {attempt_id}
            />
            <Card style = {{height:'550px', width:'60vw'}}>
                <CardHeader className='global_modal_header'>
                    <span>Publish History</span>
                </CardHeader>
                <CardBody className='global_modal_body'>
                    <Row style={{backgroundColor:'white', width:'100%', height:'100%', marginLeft:'0px', marginRight:'0px', overflowY:'scroll'}}>
                        <table style={{height:'100%'}} className="text-left flex_property table_style">
                            <thead style={{width:"100%"}}>
                                <tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
                                    <th className='th_style flex_property' style={{flex:'3'}}><span>Time</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>No of SKUs</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Division</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Category</span></th>
                                    <th className='th_style flex_property' style={{flex:'2'}}><span>Owner</span></th>
                                    <th className='th_style flex_property' style={{flex:'3'}}><span></span></th>
                                </tr>
                            </thead>
                            <tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
                            {
                                // json_of_attempts_history && Object.keys(json_of_attempts_history) && Object.keys(json_of_attempts_history).length ? Object.keys(json_of_attempts_history).map((single_category_id, idx) => (
                                //     json_of_attempts_history[single_category_id] && json_of_attempts_history[single_category_id].length ? json_of_attempts_history[single_category_id].map((single_attempt) => (
                                        // <tr className='tr_style display_onhover'>
                                        //     <td className='td_style flex_property' style={{flex:'3'}}><span className='table_text_overflow'>
                                        //         {/* <Moment format="DD/MM/YYYY">{single_attempt.draft.updated_at}</Moment> */}
                                        //         {new Date(single_attempt.updated_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})}
                                        //     </span></td> 
                                        //     <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_attempt.successful_skus_published}</span></td> 
                                        //     <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{active_division_id?active_division_id:""}</span></td> 
                                        //     <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{sku_categories && sku_categories.find(o => o.id == single_category_id) ? sku_categories.find(o => o.id == single_category_id).name:''}</span></td> 
                                        //     <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{all_users[single_attempt.user_id]?all_users[single_attempt.user_id]:''}</span></td> 
                                        //     <td className='td_style flex_property x1' style={{flex:'2'}}><span style={{marginLeft:'10%'}} className='table_text_overflow hide' onClick={() => {set_active_category(sku_categories ? sku_categories.find(o => o.id == single_category_id):null);set_viewing_published_skus(true);make_sku_array(single_attempt.id);handleClose()}}>View</span></td> 
                                        // </tr>
                                //     )):''
                                    
                                // )):''
                                display_history && display_history.length ? display_history.map((single_row, idx) => (
                                    <tr className='tr_style display_onhover'>
                                        <td className='td_style flex_property' style={{flex:'3'}}><span className='table_text_overflow'>{single_row.time}</span></td> 
                                        <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_row.no_of_skus}</span></td> 
                                        <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_row.division}</span></td> 
                                        <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_row.category ? single_row.category.name : ''}</span></td> 
                                        <td className='td_style flex_property' style={{flex:'2'}}><span className='table_text_overflow'>{single_row.owner}</span></td> 
                                        <td className='td_style flex_property x1' style={{flex:'3', justifyContent:'flex-end'}}>
                                        {/* <span style={{marginRight:'16px', fontWeight:'900'}} className='hide' onClick={() => {set_active_category(single_row.category);set_viewing_published_skus(true);make_sku_array(single_row.attempt_id);handleClose()}}>View</span> */}
                                            <span style={{marginRight:'24px', fontWeight:'900'}} className='hide' onClick={() => {set_active_category(single_row.category);set_attempt_id(single_row.attempt_id);set_show_open_as_draft_warning(true)}}>Open as Draft</span>
                                        </td> 
                                    </tr>
                                )):''
                            }
                            </tbody>
                        </table>
                    </Row>
                </CardBody>
                <CardFooter className='global_modal_footer'>
                    <Button onClick={() => {handleClose()}} className='white_button' style={{marginRight:'0px'}}>Close</Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

export default SkuPublishHistory;   