import React, { useEffect, useState } from 'react';
import SingleItemTab from './SingleItemTab.js';
import {Table, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import { Redirect, NavLink as NavLinkRRD, Link , useHistory} from "react-router-dom";
import { waitForElement } from '@testing-library/react';


const Filler = ({ initialized }) => (
    <div style={{height:'100%', width:'100%', display:"flex", alignItems:'center', justifyContent:"center"}}>
        { 
            initialized ?( 
                <span>No Sub-Category found.Please Add a new Sub-Category.</span>
            ):(
                <span><i style={{marginRight:'10px'}} className="fa fa-spinner fa-spin"/><span>Fetching your inventory</span></span>
            )
        }
    </div>  
  );

const ExtendedDropdown = ({item_json, open_additional_option_fn, sku_category_id, sku_division_id, sku_sub_category_id, onclick_tab, border_animation_sub_cat, set_border_animation_sub_cat, bulk_selection_mode, no_of_bulk_selected_skus_in_subcat}) => {

    const [total_width, set_total_width] = useState(0)
    const [visibility_map, set_visibility_map] = useState({0:true})
    const [visibility_idx, set_visibility_idx] = useState(0)
    const [occupied_width, set_occupied_width] = useState(0)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [no_of_skus_json, set_no_of_skus_json] = useState({});
    const [calculating_visibility, set_calculating_visibility] = useState();
    const [hanging_sub_category, set_hanging_sub_category] = useState({});
    // const [initialized, set_initialized] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
        let t_width = document.getElementById('primary_div').offsetWidth - (hanging_sub_category && hanging_sub_category.id ? 250 : 120)
        // console.log("occupied width total", t_width, item_json)
        set_total_width(t_width?t_width:0)
        set_visibility_idx(item_json.length-1)

        let temp_json = {}

        if(item_json && item_json.length){
            for(let i=0;i<item_json.length;i++){
                let groups = item_json[i] && item_json[i].sku_group ? item_json[i].sku_group.filter(x => x.deprecated != 1) : []
                let temp_number = 0
                for(let j=0;j<groups.length;j++){
                    temp_number += groups[j] && groups[j].sku ? groups[j].sku.length : 0
                }
                temp_json[item_json[i].id] = temp_number
            }
            set_no_of_skus_json(temp_json)
        }

    },[item_json, hanging_sub_category])
        
    // useEffect(() => {
    //     if(sku_sub_category_id && !item_json.length){
    //         set_initialized(true)
    //     }
    // },[sku_sub_category_id])

    const sec_fn = (idx) => {
        if(total_width>occupied_width){
            console.log('called')
            // set_visibility_map({...visibility_map, [idx+1]:true})
            set_visibility_idx(idx+1)
        }else{
            console.log('sdfnsjfsknfsjk', total_width, occupied_width)
            // set_visibility_map({...visibility_map, [idx]:false})
            set_visibility_idx(idx)
        }
    }

    const re_calculate_visibility = () => {
        if(item_json && item_json.length){
            console.log("re_calculate_visibility", total_width)
            let cur_occupied_width = document.getElementById('single_item_tab'+item_json[0].id).offsetWidth
            console.log('hanging_subcat')
            // if(document.getElementById('hanging_subcat')){
            //     cur_occupied_width += document.getElementById('hanging_subcat').offsetWidth
            //     console.log('hanging_subcat inside')
            // }
            let cur_visibility_idx = 0
            for(let i=1;i<item_json.length;i++){
                if(!document.getElementById('single_item_tab'+item_json[i].id)) break
                let cur_width = document.getElementById('single_item_tab'+item_json[i].id).offsetWidth
                cur_occupied_width += cur_width
                if(total_width > cur_occupied_width){
                    cur_visibility_idx = i
                    console.log("re_calculate_visibility", total_width, cur_width, cur_occupied_width, cur_visibility_idx)
                }else{
                    break
                }
            }
            console.log('re_calculate_visibility index', cur_visibility_idx)
            set_visibility_idx(cur_visibility_idx)
        }
    }

    const handleWindowSizeChange = () => {
        var t_width = document.getElementById('primary_div').offsetWidth - 250; 
        // console.log(" handleWindowSizeChange occupied width total", t_width, item_json, hanging_sub_category)
        set_total_width(t_width?t_width:0)
    }

    useEffect(() => {
        // set_visibility_idx(0)
        if(occupied_width>0){
            sec_fn(visibility_idx)
        }
        console.log("occupied width2", occupied_width)
    },[occupied_width])

    // useEffect(() => {
    //     console.log("Tital width", total_width)
    //     // set_visibility_idx(0)
    // }, [total_width]);

    // const waitForElm = (selector) => {
    //     return new Promise(resolve => {
    //         if (document.querySelector(selector)) {
    //             return resolve(document.querySelector(selector));
    //         }
    
    //         const observer = new MutationObserver(mutations => {
    //             if (document.querySelector(selector)) {
    //                 resolve(document.querySelector(selector));
    //                 observer.disconnect();
    //             }
    //         });
    
    //         observer.observe(document.body, {
    //             childList: true,
    //             subtree: true
    //         });
    //     });
    // }

    useEffect(() => {
        // console.log('Element is ready 123', 'single_item_tab'+item_json[0].id);
        // waitForElm('single_item_tab'+String(item_json[0].id)).then((elm) => {
        //     console.log('Element is ready');
        //     if(total_width) re_calculate_visibility()
        // });
        if(total_width > 0){
            set_visibility_idx(item_json.length-1)
            // console.log('triggering uef', total_width, bulk_selection_mode, item_json)
            let checkExist = setInterval(function() {
                // console.log('triggering uef222', total_width, bulk_selection_mode, item_json)
                if(item_json && item_json.length){
                    let temp=1
                    for(let i=0;i<item_json.length;i++){
                        if(!document.getElementById('single_item_tab'+item_json[i].id)){
                            temp=0
                            // console.log("re_calculate_visibility NOT Exists!", temp);
                            break
                        }
                    }
                    if (temp) {
                        // console.log("re_calculate_visibility Exists!", total_width);
                        set_calculating_visibility(true)
                        clearInterval(checkExist);
                    }
                }
            }, 100);
        } 
    }, [total_width, bulk_selection_mode, item_json]);
    
    useEffect(() => {
        if(calculating_visibility){
            if(total_width){
                re_calculate_visibility()
             } 
             set_calculating_visibility(false)
        }
    }, [calculating_visibility]);

    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);

    useEffect(() => {
        if(visibility_idx && sku_sub_category_id){
            let subcat_index = item_json.findIndex(o => o.id == sku_sub_category_id)
            if(subcat_index> visibility_idx){
                set_hanging_sub_category(item_json.find(o => o.id == sku_sub_category_id))
            }
        }
    }, [sku_sub_category_id, visibility_idx]);

    return(
        <div id='primary_div' style={{height:'100%', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div id='primary_div' style={{height:'100%', width:'100%', display:'flex', alignItems:'center'}}>
            {
                item_json && item_json.length ? item_json.map((item, idx) => 
                    <div key={idx} style={{height:'100%', display:'flex', alignItems:'center', marginLeft:'3px'}}>
                    {
                        idx <= visibility_idx ?
                        <Link key={idx} style={{textDecoration:'none'}} to={'/catalogue/' + sku_division_id +'/' + sku_category_id + '/' + item.id + '/'}>
                            <div id={'single_item_tab'+String(item.id)}>
                                <SingleItemTab item={item} idx={idx} open_additional_option_fn={open_additional_option_fn} sku_sub_category_id={sku_sub_category_id} onclick_tab={onclick_tab} set_occupied_width={set_occupied_width} occupied_width={occupied_width} border_animation_sub_cat={border_animation_sub_cat} set_border_animation_sub_cat={set_border_animation_sub_cat} bulk_selection_mode={bulk_selection_mode} no_of_bulk_selected_skus_in_subcat={no_of_bulk_selected_skus_in_subcat} no_of_skus_json={no_of_skus_json}/>
                            </div>
                        </Link>
                        :
                        ''
                    }
                    </div>
                )
                :""
                // :<Filler initialized={initialized}/>
            }
            </div>
            {
                item_json.length-1 >visibility_idx ?
                <div className='flex_property'>
                    {
                        hanging_sub_category && hanging_sub_category.id ?
                        <span id='hanging_subcat' className='sub_category_active' style={{ whiteSpace:'nowrap', fontSize:'14px', height:'100%', width:'100%', display:'flex', alignItems:'center',justifyContent:'space-between', borderRadius:'4px', color:'#435A6F'}}>
                            <span style={{ height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:'0px 20px'}}><span style={{cursor:'default'}} title={hanging_sub_category.name?hanging_sub_category.name:''} onClick={() => onclick_tab(hanging_sub_category)}>{hanging_sub_category.name?(hanging_sub_category.name.length > 15 ? hanging_sub_category.name.substring(0,15)+'...': hanging_sub_category.name):''}</span></span>
                            {open_additional_option_fn && !bulk_selection_mode ?
                                <span style={{height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:'0px 20px 0px 0px'}}>
                                    <i title='Edit Sub-Category' style={{cursor:'pointer'}} onClick={() =>open_additional_option_fn('edit')}   className='fa fa-cog' />
                                </span>
                                :""
                            }
                            {
                                bulk_selection_mode?
                                <span style={{marginRight:'24px'}}>
                                    {'('+(no_of_bulk_selected_skus_in_subcat[hanging_sub_category.id] ? no_of_bulk_selected_skus_in_subcat[hanging_sub_category.id] : '0')+'/'+(no_of_skus_json[hanging_sub_category.id] ? no_of_skus_json[hanging_sub_category.id] : '0')+')'}
                                </span>:''
                            }
                        </span>:''
                    }
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{position:'static', right:'75px'}}>
                        <DropdownToggle nav style={{fontSize:'13px', padding:'0px 10px', border:'1px solid #C5C7CF', borderRadius:'0px 6px 6px 0px', backgroundColor:'#F5F6F8'}}>
                            <div style={{ display:'flex', alignItems:'center', cursor:'pointer',color:'#435A6F', fontSize:'14px'}}>
                                <div style={{ marginRight:'5px', height:'25px',display:'flex', alignItems:'center'}}>More</div>
                                <i className='fa fa-caret-down'/>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {
                                item_json.length>visibility_idx ? item_json.map((item, idx) => 
                                    idx>visibility_idx && item.id != sku_sub_category_id &&
                                        <DropdownItem  key={idx} onClick={() => onclick_tab(item)} style={{fontSize:'13px', padding:0}}>
                                            <span className={sku_sub_category_id==item.id?'sub_category_active':''} style={{ whiteSpace:'nowrap', fontSize:'14px', height:'100%', width:'100%', display:'flex', alignItems:'center',justifyContent:'space-between', borderRadius:'4px', color:'#435A6F'}}>
                                                <span style={{ height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:'0px 20px'}}><span style={{cursor:sku_sub_category_id==item.id?'default':'pointer'}} title={item.name?item.name:''} onClick={() => onclick_tab(item)}>{item.name?(item.name.length > 15 ? item.name.substring(0,15)+'...': item.name):''}</span></span>
                                                {open_additional_option_fn && !bulk_selection_mode ?
                                                    <span className={sku_sub_category_id==item.id?'':'sub_category_display'} style={{height:'28px', maxHeight:'28px', display:'flex', alignItems:'center', justifyContent:'center', padding:'0px 20px 0px 0px'}}>
                                                        <i title='Edit Sub-Category' style={{cursor:'pointer'}} onClick={() =>open_additional_option_fn('edit')}   className='fa fa-cog' />
                                                    </span>
                                                    :""
                                                }
                                                {
                                                    bulk_selection_mode?
                                                    <span style={{marginRight:'24px'}}>
                                                        {'('+(no_of_bulk_selected_skus_in_subcat[item.id] ? no_of_bulk_selected_skus_in_subcat[item.id] : '0')+'/'+(no_of_skus_json[item.id] ? no_of_skus_json[item.id] : '0')+')'}
                                                    </span>:''
                                                }
                                            </span>
                                        </DropdownItem>
                                        
                                )
                                :''
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>    
                :''
            }
        </div>
    )
}

export default ExtendedDropdown;

{/* <div style={{ display:'flex', alignItems:'center', cursor:'pointer',color:'#435A6F', fontSize:'14px'}}>
								<div style={{ marginRight:'5px', height:'25px',display:'flex', alignItems:'center'}}>More</div>
								<i className='fa fa-caret-down'/>
							</div> */}

