import React , { useState , useEffect }from 'react';
import {Input, CardBody, Col, Row, CardFooter, Button } from "reactstrap";
import { useAlert } from 'react-alert'; 
import '../../views/Catalogue/SKU_Groups/sku_groups.css';
import Multiselect from '../Multiselect';
import custom_sort from '../../utils/custom_sort';
import general_fetch from '../../utils/fetch';

const Single_item = ({item, idx, all_division_tree_data, item_json, set_item_json, set_remove_loader, already_selected_sku_groups, position_type, original_idx}) => {

	// const [all_divisions ,set_all_divisions ] =useState([]);
	const alert = useAlert()
	const [all_category ,set_all_category ] =useState([]);
	const [all_sub_category ,set_all_sub_category ] =useState([]);
	const [all_groups ,set_all_groups ] =useState([]);
	const [all_skus ,set_all_skus ] =useState([]);

	const [selected_div_array ,set_selected_div_array ] =useState([]);
	const [selected_cat_array ,set_selected_cat_array ] =useState([]);
	const [selected_sub_cat_array ,set_selected_sub_cat_array ] =useState([]);
	const [selected_group_array ,set_selected_group_array ] =useState([]);
	const [selected_sku_array ,set_selected_sku_array ] =useState([]);
	const [initialized ,set_initialized ] =useState(false);

	const [allow_edit ,set_allow_edit ] = useState(false);

	// useEffect(() => {
    //     if(all_division_tree_data && all_division_tree_data.length){
    //         console.log('all_division_tree_data======>',all_division_tree_data)
	// 		set_all_divisions(all_division_tree_data)
    //     }
    // },[all_division_tree_data])

	const fetch_sku_groups = async (id) => {
		try {
			var response = await general_fetch({ url: 'inventory/get_groups' , body: { sku_sub_category_id:id } });
			var depricated_filter_output =response && response.length ?response.filter(x => x.depricated !=1):[]
			var resp = depricated_filter_output.length?depricated_filter_output.sort(custom_sort):[]
			set_all_groups(resp)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if(item){
			set_selected_div_array(item.division)
			set_selected_cat_array(item.category)
			set_selected_sub_cat_array(item.sub_cat)
			set_selected_group_array(item.group)
			set_selected_sku_array(item.sku)
			// set_allow_edit(item.sku && item.sku.length?false:true)
		}        
    },[item])

	useEffect(() => {
		if(all_division_tree_data && all_division_tree_data.length && selected_div_array && selected_div_array.length ){
			console.log('Division execution started')
			update_item_json(idx, 'division', selected_div_array)
			var x_id = selected_div_array.map(x => x.id)[0]
			set_all_category( all_division_tree_data.find(x => x.id===x_id)?all_division_tree_data.find(x => x.id===x_id).sku_category:[])
			console.log('Division execution ended')
		}
    },[all_division_tree_data,selected_div_array])

	useEffect(() => {
		if( all_category && all_category.length && selected_cat_array && selected_cat_array.length){
			console.log('Cat execution started')
			update_item_json(idx, 'category', selected_cat_array)
			var x_id = selected_cat_array.map(x => x.id)[0]
			set_all_sub_category( all_category.find(x => x.id===x_id) ?all_category.find(x => x.id===x_id).sku_sub_category:[])
		}      
    },[all_category,selected_cat_array])

	useEffect(() => {
		if (initialized) {
			if(all_sub_category && all_sub_category.length && selected_sub_cat_array && selected_sub_cat_array.length){
				update_item_json(idx, 'sub_cat', selected_sub_cat_array)
				var x_id = selected_sub_cat_array.map(x => x.id)[0]
				if(x_id){
					fetch_sku_groups(x_id)
				}else{
					set_all_groups([])
				}
			}        
		} else {
			if (already_selected_sku_groups && selected_sub_cat_array && selected_sub_cat_array.length) {
				// var x_groups_list = Object.keys(already_selected_sku_groups) && Object.keys(already_selected_sku_groups).length ? Object.keys(already_selected_sku_groups).filter(x => {
				// 	if(x===selected_sub_cat_array[0].id){
				// 		console.log("MAJOR CONDITION SAT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
				// 		return already_selected_sku_groups[x]
				// 	}
				// }) :[] 
				let x_groups_list = already_selected_sku_groups?.[selected_sub_cat_array[0].id]??[];
				console.log("MAJOR LOG ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", x_groups_list);
				set_all_groups(x_groups_list);
			}
		}
    },[already_selected_sku_groups, all_sub_category, selected_sub_cat_array])

	useEffect(() => {
		if(all_groups && all_groups.length && selected_group_array && selected_group_array.length){
			update_item_json(idx, 'group', selected_group_array)
			var x_id = selected_group_array.map(x => x.id)[0]
			set_all_skus(all_groups.find(x => x.id===x_id)?all_groups.find(x => x.id===x_id).sku:[])
		}        
    },[all_groups, selected_group_array])

	useEffect(() => {
		if(all_skus && all_skus.length && selected_sku_array && selected_sku_array.length){
			update_item_json(idx, 'sku', selected_sku_array)
			console.log('skuexecuted')
		}
		if(idx===item_json.length-1){
			if(selected_sku_array && selected_sku_array.length){
				set_remove_loader(true)
			}
		}
    },[selected_sku_array])

	const onselect_multi_options = (property, selectedList_1) => {
		// update_item_json(idx,property, selectedList_1)
        if(property==='division'){
			set_selected_div_array(selectedList_1)
			update_item_json(idx, 'division', selectedList_1)
			reset_for_div('division')
		}else if(property==='category'){
			set_selected_cat_array(selectedList_1)
			update_item_json(idx, 'category', selectedList_1)
			reset_for_div('category')
		}else if(property==='sub_cat'){
			set_selected_sub_cat_array(selectedList_1)
			set_initialized(true);
			update_item_json(idx, 'sub_cat', selectedList_1)
			reset_for_div('sub_cat')
		}else if(property==='group'){
			set_selected_group_array(selectedList_1)
			update_item_json(idx, 'group', selectedList_1)
			reset_for_div('group')
		}else{
			set_selected_sku_array(selectedList_1)
			update_item_json(idx, 'sku', selectedList_1)
		}
	}

	const reset_for_div = (property) => {
		if(property==='division'){
			set_all_category([]);
			set_selected_cat_array([]);
			update_item_json(idx, 'category', [])
		}
		if(property==='category' || property==='division'){	
			set_all_sub_category([]);
			set_selected_sub_cat_array([]);
			set_initialized(true);
			update_item_json(idx, 'sub_cat', [])
		}
		if(property==='sub_cat' || property==='category' || property==='division'){	
			set_all_groups([]);
			set_selected_group_array([]);
			update_item_json(idx, 'group', [])
		}
		if(property==='group' || property==='sub_cat' || property==='category' || property==='division'){	
			set_all_skus([]);
			set_selected_sku_array([]);
			update_item_json(idx, 'sku', [])
		}
	}

	const delete_item_json = (index) => {
		let a = [...item_json];
		a.splice(index,1)
		set_item_json(a);
	}

    const update_item_json = (index, key, value) =>{
		let a = [...item_json];
		a[index][key] = value;
		set_item_json(a);
    }

	// useEffect(() => {
	// 	console.log('outputs', item_json, item)       
    // },[item_json, item])

	return(
		<Row key={idx} style={{margin:0,marginTop:'10px'}}>
			<Col xs={3} style={{display:'flex', alignItems:"center", paddingLeft:0}}>
				<Multiselect
					style={{backgroundColor:'white', width:'227px'}}
					options={all_division_tree_data && all_division_tree_data.length? JSON.parse(JSON.stringify(all_division_tree_data)):[]} 
					selectedValues={selected_div_array}
					onSelect={(selectedList)=>onselect_multi_options('division', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('division', selectedList)} 
					displayValue="id" // Property name 
					selectionLimit={1}
					id={position_type+'_linked_sku_structure'+'_division_'+original_idx}
					placeholder='Select'
					// disable={allow_edit?false:true}
				/> 
			</Col>
			<Col xs={2} style={{display:'flex', alignItems:"center"}}>
				<Multiselect
					style={{backgroundColor:'white', width:'227px'}}
					options={all_category && all_category.length? JSON.parse(JSON.stringify(all_category)):[]} 
					selectedValues={selected_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('category', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('category', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={position_type+'_linked_sku_structure'+'_category_'+original_idx}
					placeholder='Select'
					// disable={allow_edit?false:true}
				/> 
			</Col>
			<Col xs={3} style={{display:'flex', alignItems:"center"}}>
				<Multiselect
					style={{backgroundColor:'white', width:'227px'}}
					options={all_sub_category && all_sub_category.length? JSON.parse(JSON.stringify(all_sub_category)):[]} 
					selectedValues={selected_sub_cat_array}
					onSelect={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sub_cat', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={position_type+'_linked_sku_structure'+'_sub_cat_'+original_idx}
					placeholder='Select'
					// disable={allow_edit?false:true}
				/> 
			</Col>
			<Col xs={2} style={{display:'flex', alignItems:"center"}}>
				<Multiselect
					style={{backgroundColor:'white', width:'227px'}}
					options={all_groups && all_groups.length? JSON.parse(JSON.stringify(all_groups)):[]} 
					selectedValues={selected_group_array}
					onSelect={(selectedList)=>onselect_multi_options('group', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('group', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={position_type+'_linked_sku_structure'+'_group_'+original_idx}
					placeholder='Select'
					// disable={allow_edit?false:true}
				/> 
			</Col>
			<Col xs={2} style={{display:'flex', alignItems:"center"}}>
				<Multiselect
					style={{backgroundColor:'white', width:'227px'}}
					options={all_skus && all_skus.length? JSON.parse(JSON.stringify(all_skus)):[]} 
					selectedValues={selected_sku_array}
					onSelect={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					onRemove={(selectedList)=>onselect_multi_options('sku', selectedList)} 
					displayValue="name" // Property name 
					selectionLimit={1}
					id={position_type+'_linked_sku_structure'+'_sku_'+original_idx}
					placeholder='Select'
					// disable={allow_edit?false:true}
				/> 
			</Col>
		</Row>
	)
}

const HWLinked_sku_structure = ({item_json, set_item_json, all_division_tree_data, position_type, original_idx}) => {

	const [skus_ansistry ,set_skus_ansistry ] =useState([]);
	const [remove_loader ,set_remove_loader ] =useState(false);
	const [sku_group_initial_options ,set_sku_group_initial_options ] = useState([]);
	const alert = useAlert()

	const is_valid_add_key = (item_json) => {
		if(item_json && item_json.length){
			for(var i=0;i<item_json.length;i++){
				if(item_json[i].sku && item_json[i].sku.length<1 ){
					return false;
				}
			}
			return true;
		}else{
			return true;
		}
	}

    const add_new_item_json = () => {
		if(is_valid_add_key(item_json)){
            let a = [...item_json];
			a.push({condition_type:'absolute',component:[], subcomponent:[], property:[] ,relativecomponent:[], relativesubcomponent:[], relativeproperty:[], operator:'equal', division:[], category:[], sub_cat:[], group:[], sku:[], range_max:'', range_min:'', value:'', tag:[], value_combination_logic:'and'})
			// a.push({division:[], category:[], sub_cat:[], group:[], sku:[], quantity:[]})
			set_item_json(a);
        }else{
            alert.error("Empty or Duplicate key values not allowed.")
        }
	}

    useEffect(() =>{
		if(item_json && !item_json.length){
			add_new_item_json()
		}else{
			var x_sku_ids = item_json && item_json.length ?item_json.map(x => x.sku_id) :[];
			if(x_sku_ids && x_sku_ids.length){
				let ancestors = fetch_ansisters(x_sku_ids);
			}
		}
	},[item_json])

	const onclick_handleclose =() => {

	}

	const fetch_ansisters = async(sku_ids) => {
		// console.log('AM linked list=================================> ',sku_ids)
		var response =[]
		if(sku_ids && sku_ids.length && sku_ids[0]){
			response = await general_fetch({ url: 'inventory/get_sku_ancestry' , body: { sku_id:sku_ids } });
		}
		set_skus_ansistry(response && response.length?response:[]);
		return response;
	}

	const fetch_group_and_skus = async sku_sub_category_id => {
		try{
			var response = await general_fetch({ url: 'inventory/get_groups_for_multiple_sub_categories' , body: { sku_sub_category_id } });
			set_sku_group_initial_options(response)
		}catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		if(skus_ansistry && skus_ansistry.length && item_json && item_json && item_json.length){
			let sku_sub_category_ids = skus_ansistry.map(x => x.sku_sub_category_data[0].id);
			fetch_group_and_skus(sku_sub_category_ids)
			var x_single_item = item_json.map((x_lskus,idx) => {
				var x = skus_ansistry.find(x => x.id===x_lskus.sku_id)
				return({
					division:[{id:x.sku_division_id}],
					category:x.sku_category_data,
					sub_cat:x.sku_sub_category_data,
					group:x.sku_group_data,
					sku:x.sku_data,
					value:item_json[idx]?item_json[idx].value:'',
				})
			});
			set_item_json(x_single_item);
		}        
    },[skus_ansistry])

	return(
		<div style={{backgroundColor:'white'}}>
			<div>
				<Row style={{margin:0,marginTop:'10px', fontSize:'14px'}}>
					<Col xs={3} style={{display:'flex', alignItems:"center",paddingLeft:0}}>
						Division 
					</Col>
					<Col xs={2} style={{display:'flex', alignItems:"center"}}>
						Category 
					</Col>
					<Col xs={3} style={{display:'flex', alignItems:"center"}}>
						Sub Category
					</Col>
					<Col xs={2} style={{display:'flex', alignItems:"center"}}>
						Group 
					</Col>
					<Col xs={2} style={{display:'flex', alignItems:"center"}}>
						SKU
					</Col>
				</Row>
			</div>
			{
				item_json && item_json.length?item_json.map((x,idx) => 
					<Single_item 
						item={x} 
						key={x.id?x.id:idx} 
						idx={idx} 
						set_remove_loader={set_remove_loader} 
						item_json={item_json} 
						set_item_json={set_item_json} 
						all_division_tree_data={all_division_tree_data}
						already_selected_sku_groups={sku_group_initial_options}
						position_type = {position_type}
						original_idx = {original_idx}
					/>
				):''
			}
		</div>
	)
} 

export default HWLinked_sku_structure;

