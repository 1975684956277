import React , { useState , useEffect }from 'react';
import {FormGroup, Button, Form, Input, InputGroupAddon, Modal, InputGroupText, InputGroup, Badge, Card, CardBody, CardText, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, TabPane , TabContent ,CardImg ,Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";

const UploadFile = ({ id, set_file, file_size, styles, button_name, accept, update_additional_file, additional_files_idx, set_text, additional_files}) => {

	const onchange_filename = (e) => {
		if(update_additional_file){
			console.log('inside upload comp',additional_files_idx, e.target.files[0])
			update_additional_file(additional_files_idx, e.target.files[0]) 
		}else{
			var file = e.target.files[0];
			set_file(file)
			var reader = new FileReader();
			reader.onload = function(event) {
				// The file's text will be printed here
				console.log(event.target.result)
				set_text(event.target.result)
			};
			if((accept == '.mtl' || accept == '.json') && file) reader.readAsText(file)
			e.stopPropagation();
		}
	}

	// useEffect(()=>{
	// 	console.log("From useEffect ---> ", additional_files_idx)
	// },[additional_files_idx])

	const onclick_upload_button = () => {
		document.getElementById(id).click();
		// document.getElementById(id).value = null
		console.log("filessss",document.getElementById(id).files)
	}

	useEffect(() => {
		if(additional_files && !additional_files[additional_files_idx]){
			document.getElementById(id).value = null
		}
	},[additional_files])

	return(
		<div>
			<Button className="blue_button" style={styles} onClick = {onclick_upload_button}>{button_name?button_name:'Select file'}</Button>
			<Input id={id} type="file" onChange={(e) => onchange_filename(e)} style={{display:"none"}} accept={accept}/>
		</div>	
	)
}

export default UploadFile ;