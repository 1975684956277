import React, { useState, useEffect } from 'react';
import { Redirect, NavLink as NavLinkRRD, Link , useHistory} from "react-router-dom";
import { Input, NavItem, NavLink, Row, Col} from "reactstrap";
import custom_sort from '../../utils/custom_sort';
import name_sort from '../../utils/name_sort';
import general_fetch from '../../utils/fetch';
import split_cat_type from '../../utils/split_cat_type';
import build_path from '../../utils/path';
import { primary_menu_options } from "./menu_options.js";
import './style.css';
import { useAlert } from 'react-alert'
import { useStripe } from '@stripe/react-stripe-js';
import { Span } from '@sentry/tracing';
import Category_add_edit_modal from '../../views/Catalogue/SKU_Groups/Category_add_edit_modal.js'
import OptionsDialogBox from '../../views/SalesChannel/OptionsDialogBox';
// import {Modal, CardImg, Alert, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
//import AlertBox from "../../components/AlertBox/";

const styles = {
	error_style: {
		color: "red",
		fontSize: 'small'
	},

};

const Filler_for_cat = ({ initialized }) => {

	return(
		<div style={{height:"100%", width:'100%'}}>
			{ 
				initialized ? 
				(
					<div style={{height:'30px', display:'flex', alignItems:'center', justifyContent:'center', marginTop:'20px'}}>
						<span>No Categories</span>
					</div>
				):(
					<div style={{height:'30px', display:'flex', alignItems:'center', justifyContent:'center', marginTop:'30px'}}>
						<span>Fetching ...</span>
					</div>
				)
			}	
		</div> 
	)
};

const Categories_Dropdown = ({ fetch_fn, set_page_loader, sku_division_id, dropdown_open, set_dropdown_open, sku_categories, set_sku_division_id, fetch_categories_one_one, user, initialized, submit_for_delete_confirm }) => {
	
	const [search_string, set_search_string] = useState('');
	const [display_sku_categories, set_display_sku_categories] = useState([]);
	const [show_category_modal, set_show_category_modal] = useState(false);
	const [active_category, set_active_category] = useState({});
	const [category_type , set_category_type] = useState("");
	const [mode, set_mode] = useState('');
	// const [first_subcategory_ids, set_first_subcategory_ids] = useState([])

	const fetch_categories_type = async() => {
		if(sku_division_id){
			try {
				var identifiers = JSON.stringify({'sku_division_id':sku_division_id});
				var response = await general_fetch({ url: 'sku_category_type/get', body:{identifiers}});
				// var resp = response.sort(name_sort)split_cat_type
				var resp = split_cat_type(response).sort(name_sort)
				set_category_type(resp);
			} catch(err) {
				console.log(err);
			}
		}
	}

	const compare  = (a,b)=>{
		return a.order>b.order ? 1 : -1

	}

	// const fetch_sku_sub_categories = async() => {
	// 	let first_subcategory_ids_temp = []
	// 	for(let i=0;i<display_sku_categories.length;i++){
	// 		try{
	// 			let body = {sku_category_id:display_sku_categories[i].id}
	// 			var response = await general_fetch({ url: 'inventory/get_sub_categories' , body });
	// 			// let temp = [...response]
	// 			response.sort(compare)
	// 			// console.log("displaay resp", response)
	// 			// console.log("displaay temp", temp)
	// 			// if([...temp] == [...response]) 
	// 			console.log("displaay", response)

	// 			first_subcategory_ids_temp.push(response[0].id)
	// 		}catch(err){
	// 			console.error(err);
	// 		}
	// 	}
	// 	set_first_subcategory_ids(first_subcategory_ids_temp)
	// }

	useEffect(() => {
		if(sku_categories && sku_categories.length){
			set_display_sku_categories(sku_categories)
		}else{
			set_display_sku_categories([])
		}
		set_search_string('')
		fetch_categories_type()
	},[sku_categories])

	// useEffect(() => {
	// 	if(display_sku_categories && display_sku_categories.length){
	// 		fetch_sku_sub_categories()
	// 	}
	// },[display_sku_categories])

	const filter_category = () => {
		var filtered_categories = [...sku_categories];
		if(search_string){
			var lower_search_string = search_string ? search_string.toLowerCase():'';
			if(lower_search_string){
				filtered_categories = filtered_categories.filter( category => category.name && category.name.toLowerCase().includes(lower_search_string))
			}
		}
		set_display_sku_categories(filtered_categories)
	}

	useEffect(() => {
		filter_category()
	},[search_string])

	const onclick_category = () => {
		set_dropdown_open(!dropdown_open)
		set_search_string('')
		if(!dropdown_open){
			set_display_sku_categories('')
		}
	}

	const onclick_Category_handleclose = () => {
		set_dropdown_open(false)
		set_sku_division_id('')
		set_search_string('')
	}

	const open_category_modal = (category, mode) => {
		set_show_category_modal(true)
		set_active_category(category?category:'')
		set_mode(mode)
	}
	const close_category_modal = () => {
		set_show_category_modal(false)
		set_active_category({})
		set_mode('')
	}

	return (
		<div className='div_position' style={{display: dropdown_open? 'block':'none', textAlign:'center',zIndex:12}}>
			<div style={{width:"100%",textTransform:'capitalize', fontSize:'18px', lineHeight:'40px', textAlign:'left', padding:'5px 20px', fontFamily:'Source_Sans_Pro-SemiBold', display:"flex", justifyContent:"space-between",alignItems:'center'}}>
				{sku_division_id ? sku_division_id : ''}
				<span style={{display:"flex", justifyContent:"space-between",alignItems:'center'}}>
					<span id='add_category_button' style={{ marginRight:'10px', fontSize:"14px"}} ><i onClick={()=>open_category_modal('','add')} style={{cursor:'pointer',color:'#1070CA'}} title='Add Category' className="fa fa-plus-circle"/></span>
					<span id='close_category_list' style={{cursor:"pointer"}} onClick={onclick_Category_handleclose}><i className='fa fa-times'/></span>
				</span>
			</div>
			<div className='flex_property' style={{width:"100%", lineHeight:'40px', padding:'0px 20px 5px', fontFamily:'Source_Sans_Pro-SemiBold'}}>
				<Input style={{fontSize:'12px', backgroundColor:'rgba(43, 56, 68, 0.54)', border:0, color:'#B8BFCA'}} value={search_string} onChange={(e) => set_search_string(e.target.value)} type='text' placeholder='Category Name'/>
				<i style={{backgroundColor:'rgba(43, 56, 68, 0.54)', color:'#B8BFCA', height: '32px', display: 'flex', alignItems: 'center', paddingRight: '10px'}} className='fa fa-search' />
			</div>
			<div className='div_category'>
				{
					display_sku_categories && display_sku_categories.length?
					display_sku_categories.map((category, idx) => 
						<Link key={idx} style={{textDecoration:'none'}} to={'/catalogue/' + sku_division_id +'/' + category.id + '/'}>
							<Row className='single_div_category' style={{margin:0}}>
								<Col style={{textAlign:'left', padding:'0px 20px'}} className='hover_on_categories flex_property_spacebetween' onClick={onclick_category}>
								{/* onClick={()=>open_category_modal(category, 'edit')} */}
									<div title={category.name ? category.name : ''} className='change_icon_text_color' style={{textTransform:'capitalize', fontSize:'14px', lineHeight:'32px', textAlign:'left'}}>{category.name ? (category.name.length > 28 ? category.name.substring(0,28)+'...' : category.name) : ''}</div>
									<div className='category_edit_fa'><i onClick={(e)=>{open_category_modal(category, 'edit');e.preventDefault()}} style={{fontSize:'12px'}} className="fa fa-pencil-alt"/></div>
									{/* {user.store_id===category.store_id?<i onClick={()=>open_category_modal(category, 'edit')} className="fa fa-pencil category_edit_fa"/>:''} */}
								</Col>
							</Row>
						</Link>
						)
					:<Filler_for_cat initialized={initialized} />
				}
			</div>
			<Category_add_edit_modal open={show_category_modal} handleClose={close_category_modal} category={active_category} sku_division_id={sku_division_id} mode={mode} fetch_fn={fetch_fn} category_type={category_type} sku_categories={sku_categories} fetch_categories_one_one={fetch_categories_one_one} user={user} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm}/>
		</div>			
	)
	
}

const divisions = ['furniture', 'accessory','building','hardware','finish'] ;
const  Sidebar = ({ active_tab, user, set_page_loader, active_division_id, set_active_division_id, store_details, submit_for_delete_confirm}) => {

	//console.log('user===================>', active_tab, active_division_id)
	const alert = useAlert()
	const [sku_division_id, set_sku_division_id] = useState('');
	const [dropdown_open, set_dropdown_open] = useState(false);	
	// const [all_sku_categories, set_all_sku_categories] = useState([]);
	const [sku_categories, set_sku_categories] = useState([]);
	const [primary_menu_options_PorB, set_primary_menu_options_PorB] = useState([]);
	const [collapse_show, set_collapse_show] = useState(null);
	const [collapse_catalogue_show, set_collapse_catalogue_show] = useState(false);
	const [initialized, set_initialized] = useState(false);
	const [allowed_user_for_allrules, set_allowed_user_for_allrules] = useState([]);

	const set_fn = (option) => {
		var x_list =[]
		if(option.key==='category_type'){
			x_list=global.config.cat_type_allowed_store
		}else if(['MF_rules','HW_rules','AM_rules','designer_input'].includes(option.key)){
			x_list=store_details && (store_details.enterprise || store_details.type == 'business')?false:true;
			return x_list;
		} else if (['DC_rules','designer_input'].includes(option.key)) {
			x_list = store_details && (store_details.enterprise || store_details.type == 'business' || store_details.id == '923803209d317d3a') ? false : true;
			return x_list;
		}else if(option.key==='panel_pricing_rule'){
			x_list=global.config.pp_rules_allowed_store
		}else{
			x_list=[]
		}
		if(option.key == 'design_preferences') {
			console.log(option.key, global.config.master_preferences_allowed_users, user.id);
			if (global.config.master_preferences_allowed_users.includes(String(user.id))) {
				return false;
			} else {
				return true;
			}
		}
		if(option.key == 'Usage_Metrics'){
			if(store_details.usage_metric_reporting_enabled)
				return false
			return true
		}

		if(option.key == 'ArchiveCatalogue' || option.key == 'CustomReport'){
			if(store_details.catalog_dump_enabled)
				return false
			return true
		}

		if(option.key==='category_type'){
			var x_res = global.config.menu_items_ket_list.find(x=> option.key===x) && (x_list.find(x=> user.id===x))?false:true
		}else if(option.key==='ArchiveCatalogue'){
			var x_res = store_details && store_details.catalog_dump_enabled ?false : true;
        }else{
			var x_res = global.config.menu_items_ket_list.find(x=> option.key===x) && !(x_list.find(x=> user.store_id===x))?true:false
		}
		return(x_res)
	}

	useEffect(()=>{
		if(user){
			if(user.type=='business'){
				set_primary_menu_options_PorB(primary_menu_options)
			}else{
				// var x = primary_menu_options.filter(x => x && x.type && x.type=='professional')
				set_primary_menu_options_PorB(primary_menu_options)
			}
			
		}
	},[user])

	const fetch_categories_one_one = async () => {
		try {
			// var identifiers = JSON.stringify({'sku_division_id':sku_division_id});
			// var attributes = JSON.stringify({});
			// var response = await general_fetch({ url: 'sku_category/get_active' , body: { identifiers , attributes } });
			var response = await general_fetch({ url: 'inventory/get_categories', body: { sku_division_id } });
			// console.log('response from inventory calls ===> ', resp2);
			set_initialized(true)
			var resp = response && response.length?response.sort(name_sort):[];
			set_sku_categories(resp)
		} catch(err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if(sku_division_id){
			fetch_categories_one_one()
		}
	},[sku_division_id])

	// useEffect(() => {
	// 	if(all_sku_categories && all_sku_categories.length){
	// 		set_sku_categories(all_sku_categories.filter(o => o.sku_division_id === sku_division_id));
	// 	}
	// },[all_sku_categories])

	const click_xyz = (division_id) => {
		if(store_details && store_details.type==='business'){	
			if(dropdown_open){
				set_sku_division_id('')
			}
			else{
				set_sku_division_id(division_id)
			}
			set_dropdown_open(!dropdown_open)
		}else{
			alert.info('Catalogue management access is not allowed for professional accounts.')
		}
	}

	const onclick_close_category_dropdown = () => {
		set_dropdown_open(false)
		set_collapse_show({})
		set_collapse_catalogue_show(false)
	}

	const onclick_catalogue_check_rule = () => {
		if(user && user.role && user.role.find(x => ['catalog_manager'].includes(x.id))){
			set_collapse_catalogue_show(!collapse_catalogue_show)
		}else{
			alert.info("Access Denied! You don't have Catalogue Manager access")
		}
	}

	useEffect(() =>{
		var temp = {}
		for(var i=0;i<primary_menu_options_PorB.length;i++){
			var option = primary_menu_options_PorB[i];
			if(option.sub_menu && option.sub_menu.length && option.key != 'catalogue')
				temp[option.key] = false;
		}
		set_collapse_show(temp);
	},[primary_menu_options_PorB])

	const set_collapse = (key) => {
		if(key == 'DesignIntelligence' && !(user && user.role && user.role.find(x => ['catalog_manager'].includes(x.id)))){
			alert.info("Access Denied! You don't have Catalogue Manager access")
			return
		}
		var a = JSON.parse(JSON.stringify(collapse_show))
		a[key] = !a[key]
		set_collapse_show(a);
		set_dropdown_open(false)
	}

	return (
	  	<div style={{width:'100%', height:'100%', maxWidth:'100%', overflowY:'auto', paddingTop:'10px', paddingBottom:'10px'}}>
	  		<Categories_Dropdown fetch_fn={fetch_categories_one_one} initialized={initialized} set_page_loader={set_page_loader} sku_division_id={sku_division_id} set_sku_division_id={set_sku_division_id}  dropdown_open={dropdown_open} set_dropdown_open={set_dropdown_open} sku_categories={sku_categories} fetch_categories_one_one={fetch_categories_one_one} user={user} submit_for_delete_confirm={submit_for_delete_confirm}/>
			{
				primary_menu_options_PorB && primary_menu_options_PorB.map( option => (
				<NavItem key={option.text} style={{ cursor: 'pointer',listStyleType:'none', lineHeight:'22px'}}>
					{(option.sub_menu && option.sub_menu.length) ? (
						<div style={{height:'auto'}}>
							{
								option.key=='catalogue'?(
									<div>
										<NavLink onClick={onclick_catalogue_check_rule} className={(active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text"} style={{padding:0, display:'flex', alignItems:'center', height:'35px', paddingLeft:'25px'}} id={option.key} active={active_tab == option.key}>
											<i style={{ width: '14px', height:'14px' , fontSize:'11px', color:(active_tab == option.key) ?'white':'#a6a6a6', marginRight:'10px'}} className={'fa fa-'+option.icon} />
											<span className={((active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text")} style={{color:'#a6a6a6', fontSize:'14px'}}>{option.text}</span>
											<i style={{flex:1, width: '14px', lineHeight: '14px', color:(active_tab == option.key) ?'white':'#a6a6a6', fontSize:'11px', textAlign:'right', marginRight:'20px'}} className={collapse_catalogue_show?'fa fa-angle-up':'fa fa-angle-down'} />
										</NavLink>
										
										{
											collapse_catalogue_show?
												<div style={{backgroundColor:'#000D1A', height:'auto'}}>
													{option.sub_menu && option.sub_menu.length && option.sub_menu.map((sub_option, idx) => (
														<div key={idx} className={sku_division_id==sub_option.id || active_division_id==sub_option.id? 'active_division':''} style={{paddingLeft:'50px',height:'35px', display:'flex', alignItems:'center'}} active={active_tab == sub_option.key} onClick={() => click_xyz(sub_option.id)}>
															<span className={sku_division_id==sub_option.id|| active_division_id==sub_option.id? 'active_division_text':''} style={{flex:10, color:'#a6a6a6', fontSize:'14px' }}>{sub_option.text}</span>
															<i classsName={sku_division_id==sub_option.id|| active_division_id==sub_option.id? 'active_division_text':''} style={{flex:1, width: '14px', lineHeight: '14px', color:(sku_division_id==sub_option.id|| active_division_id==sub_option.id)? 'white':'#a6a6a6', fontSize:'11px', textAlign:'right', marginRight:'20px'}} className='fa fa-angle-right' />
														</div>		
													))}
												</div>
											:""
										}
									</div>
								):(
									<div>
										{/* {console.log("sidebar", a)} */}
										<NavLink onClick={() =>set_collapse(option.key)} className={(active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text"} style={{padding:0, display:'flex', alignItems:'center', height:'35px', paddingLeft:'25px'}} id={option.key} active={active_tab == option.key}>
											<i style={{ width: '14px', height:'14px' , fontSize:'11px', color:(active_tab == option.key) ?'white':'#a6a6a6', marginRight:'10px'}} className={'fa fa-'+option.icon} />
											<span className={((active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text")} style={{color:'#a6a6a6', fontSize:'14px'}}>{option.text}</span>
											<i  style={{flex:1, width: '14px', lineHeight: '14px', color:(active_tab == option.key) ?'white':'#a6a6a6', fontSize:'11px', textAlign:'right', marginRight:'20px'}} className={collapse_show[option.key]?'fa fa-angle-up':'fa fa-angle-down'} />
										</NavLink>
										{
											collapse_show[option.key]?
												<div style={{backgroundColor:'#000D1A', height:'auto'}}>
													{option.sub_menu.map((sub_option, idx) => (
														sub_option && set_fn(sub_option)?'':
														<Link onClick={() => set_dropdown_open(false)} key={idx} to={sub_option.url} className={active_division_id==sub_option.id? 'active_division':''} style={{paddingLeft:'50px',height:'35px', display:'flex', alignItems:'center', textDecoration:'none'}} active={active_tab == sub_option.key}>
															<span className={active_division_id==sub_option.id? 'active_division_text':''} style={{flex:10, color:'#a6a6a6', fontSize:'14px' }}>{sub_option.text}</span>
															<i style={{flex:1, width: '14px', lineHeight: '14px', color:'#a6a6a6', fontSize:'11px', textAlign:'right', marginRight:'20px'}} />
														</Link>		
													))}
												</div>
											:''
										}
									</div>
								)
							}
							
						</div>
					) : (
						<React.Fragment>
						{
							option && set_fn(option)?'':
							<NavLink onClick={() => {onclick_close_category_dropdown(); set_active_division_id('')}} style={{padding:0, height:'35px', display:'flex', alignItems:'center', paddingLeft:'25px' }} tag={NavLinkRRD} to={option.url} active={active_tab == option.key} className={((active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text")}>
								<i style={{ width: '14px', lineHeight: '14px', color:(active_tab == option.key) ?'white':'#a6a6a6', fontSize:'11px',marginRight:'10px',}} className={'fa fa-'+option.icon} />
								<span className={((active_tab == option.key) ? "sidebar_text active_nav_tab" : "sidebar_text")} style={{color:'#a6a6a6', fontSize:'14px'}}>{option.text}</span>
							</NavLink>
						}
						</React.Fragment>
					)}
				</NavItem>
			))}
	  				
	  	</div>
	);
}

export default Sidebar;