import React , { useState , useEffect, useRef }from 'react';
import {FormGroup, Button, Input, Modal, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, TabPane , TabContent ,Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import Multiselect from '../../../components/Multiselect';
import KeyValuePair from '../../../components/KeyValuePair';
import { all_allowed_key } from '../../../components/AdditionalPropKeys';
import { useAlert } from 'react-alert';
import is_valid_add_key from '../../../utils/is_valid_add_key';

const NewSalesChannelWarning = ({open, handleClose, create_attempt_and_trigger, edited_sku_array}) => {
    return(
        <Modal style ={{marginTop:'17%', height:'150px', width:'464px'}} size="sm" isOpen={open}>
            <Card>
                <div style = {{backgroundColor:'#F5F6F8', overFlow:'auto', padding:'24px'}}> 
                    <div style = {{paddingLeft:'0px', paddingBottom:'16px'}}>Some of the sales channels which you are trying to map might already be mapped to some selected SKUs. In those cases, existing tax and margin will be overriden by the new values</div>
                    <div className='flex_property' style={{width:'100%', justifyContent:'center'}}>
                        <Button className='white_button' type="button" style={{marginRight:'16px'}} onClick={() => handleClose()}>Cancel</Button>
                        <Button className='blue_button' type="button" onClick={() => {handleClose();create_attempt_and_trigger(edited_sku_array)}}>OK</Button>
                    </div>
                </div>
            </Card>
        </Modal>
    )
}

const EditCommonProperties = ({user, orignal_tags, eligible_tags, all_sales_channels, all_brands, original_sku_array, original_sales_channels, original_prices, sub_cats_included, bulk_selected_hierarchical_data, sku_category_name, active_division_id, sku_category_type, no_of_bulk_selected_skus_in_subcat, edited_sku_array, set_edited_sku_array, is_allow_material, is_allow_material_and_depth, tags_at_all_levels, set_choosing_editing_mode, editing_common_props, set_editing_common_props, common_props_confirmation_mode, set_common_props_confirmation_mode, create_attempt_and_trigger}) => {
    
    const [	tabs , set_tabs ] = useState(1)
    const [common_tags, set_common_tags] = useState([]);
    const [common_sales_channels, set_common_sales_channels] = useState([]);
    const [common_additional_props, set_common_additional_props] = useState([]);
    // const [tags_json, set_tags_json] = useState({});
    // const [sales_channels_json, set_sales_channels_json] = useState({});
    // const [additional_props_json, set_additional_props_json] = useState({});
    const [contains_unowned_skus, set_contains_unowned_skus] = useState(false);
    const [no_common_tags, set_no_common_tags] = useState(false);
    const [no_common_sales_channels, set_no_common_sales_channels] = useState(false);
    const [no_common_additional_props, set_no_common_additional_props] = useState(false);
    const [removing_common_tags_json, set_removing_common_tags_json] = useState({});
    const [removing_common_sales_channels_json, set_removing_common_sales_channels_json] = useState({});
    const [modifying_common_sales_channels_json, set_modifying_common_sales_channels_json] = useState({});
    const [new_tags_added, set_new_tags_added] = useState([]);
    const [new_sales_channels_added_from_multiselect, set_new_sales_channels_added_from_multiselect] = useState([]);
    const [new_sales_channels_added, set_new_sales_channels_added] = useState([]);
    const [modified_sales_channel_values, set_modified_sales_channel_values] = useState([]);
    const [modifying_all_common_sales_channels, set_modifying_all_common_sales_channels] = useState(false);
    const [new_additional_props_default, set_new_additional_props_default] = useState([]);
    const [new_additional_props_custom, set_new_additional_props_custom] = useState([]);
    const [common_additional_props_default, set_common_additional_props_default] = useState([]);
    const [common_additional_props_custom, set_common_additional_props_custom] = useState([]);
    const [common_additional_props_default_1, set_common_additional_props_default_1] = useState([]);
    const [common_additional_props_custom_1, set_common_additional_props_custom_1] = useState([]);
    const [new_placement_id, set_new_placement_id] = useState('existing_values');
    const [new_brand_id, set_new_brand_id] = useState('existing_values');
    const [new_height, set_new_height] = useState('Mixed');
    const [new_width, set_new_width] = useState('Mixed');
    const [new_depth, set_new_depth] = useState('Mixed');
    const [bulk_delete_checkboxes_default, set_bulk_delete_checkboxes_default] = useState({});
    const [bulk_delete_checkboxes_custom, set_bulk_delete_checkboxes_custom] = useState({});
    const [bulk_modify_checkboxes_default, set_bulk_modify_checkboxes_default] = useState({});
    const [bulk_modify_checkboxes_custom, set_bulk_modify_checkboxes_custom] = useState({});
    const [show_new_sales_channel_warning, set_show_new_sales_channel_warning] = useState();
    const [show_sku_props_in_confirmation, set_show_sku_props_in_confirmation] = useState();
    const [show_brands_in_confirmation, set_show_brands_in_confirmation] = useState();
    const [show_tags_in_confirmation, set_show_tags_in_confirmation] = useState();
    const [show_sales_channels_in_confirmation, set_show_sales_channels_in_confirmation] = useState();
    const [show_additional_props_in_confirmation, set_show_additional_props_in_confirmation] = useState();
    const alert = useAlert()


    const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

    const is_duplicate_key = (idx, item_json) => {
        var x_value = item_json.filter(x=>x.key===item_json[idx].key).length>1?true:false
        // var x_value = keys.includes(item_json[idx].key)?true:false
        return x_value;
    }

    const is_valid_add_key_1 = (item_json) => {
        for(var i=0;i<item_json.length;i++){
            if(item_json[i].key==='' || is_duplicate_key(i, item_json)){
                return false;
            }
        }
        return true;
    }

    const update_removing_common_tags_json = (tag_id, value) => {
        let a = {...removing_common_tags_json}
        a[tag_id] = value
        set_removing_common_tags_json(a)
    }

    const update_removing_common_sales_channels_json = (sales_channel_id, value) => {
        let a = {...removing_common_sales_channels_json}
        a[sales_channel_id] = value
        set_removing_common_sales_channels_json(a)
    }

    const update_modifying_common_sales_channels_json = (sales_channel_id, value) => {
        let a = {...modifying_common_sales_channels_json}
        a[sales_channel_id] = value
        set_modifying_common_sales_channels_json(a)
    }

    const update_bulk_delete_checkboxes_default = (id, val) => {
        let a = {...bulk_delete_checkboxes_default}
        a[id] = val
        set_bulk_delete_checkboxes_default(a)
    }

    const update_bulk_delete_checkboxes_custom = (id, val) => {
        let a = {...bulk_delete_checkboxes_custom}
        a[id] = val
        set_bulk_delete_checkboxes_custom(a)
    }

    const update_bulk_modify_checkboxes_default = (id, val) => {
        let a = {...bulk_modify_checkboxes_default}
        a[id] = val
        set_bulk_modify_checkboxes_default(a)
    }

    const update_bulk_modify_checkboxes_custom = (id, val) => {
        let a = {...bulk_modify_checkboxes_custom}
        a[id] = val
        set_bulk_modify_checkboxes_custom(a)
    }

    const update_sales_channel_values = (sales_channel_values, set_sales_channel_values,sales_channel_id, price_type_id, property, value) => {
        let a = [...sales_channel_values]
        let b = a.find(o => o.id === sales_channel_id)
        let c = undefined
        if(b){
            c = b.price_types.find(o => o.id === price_type_id)
        }
        if(c) c[property] = value
        console.log('update_sales_channel_values', a)
        set_sales_channel_values(a)
    }

    const verify_main_checkbox_sales_channel = (value, sales_channel_id) => {
        if(!value && modifying_all_common_sales_channels){
            set_modifying_all_common_sales_channels(false)
            return
        }
        let myArr = [...common_sales_channels]
        let temp=0
        if(myArr && myArr.length){
            for(let i=0;i<myArr.length;i++){
                if(myArr[i] != sales_channel_id && !modifying_common_sales_channels_json[myArr[i]]){
                    temp = 1
                    break
                }
            }
        }
        if(!temp) set_modifying_all_common_sales_channels(true)
    }

    const click_main_checkbox_sales_channel = (value) => {
        set_modifying_all_common_sales_channels(value)

        let a = {...modifying_common_sales_channels_json}
        if(common_sales_channels && common_sales_channels.length){
            for(let i=0;i<common_sales_channels.length;i++){
                a[common_sales_channels[i]] = value
            }
            set_modifying_common_sales_channels_json(a)
        }
    }

    const reset_dimensions = () => {
        set_new_height('Mixed')
        set_new_width('Mixed')
        set_new_depth('Mixed')
    }

    const set_mixed_values_sales_channels = () => {
        if(all_sales_channels && all_sales_channels.length && common_sales_channels && common_sales_channels.length){
            let temp_array = JSON.parse(JSON.stringify(all_sales_channels.filter(x => common_sales_channels.includes(x.id))))
            if(temp_array && temp_array.length){
                for(let i=0;i<temp_array.length;i++){
                    let single_sales_channel = temp_array[i]
                    let price_types = single_sales_channel.price_types
                    if(price_types && price_types.length){
                        for(let j=0;j<price_types.length;j++){
                            let single_price_type = price_types[j]
                            single_price_type['tax'] = 'Mixed'
                            single_price_type['margin'] = 'Mixed'
                        }
                    }
                }
            }
            set_modified_sales_channel_values(temp_array)
        }
    }

    const reset_modified_sales_channel_values = () => {
        set_mixed_values_sales_channels();
        set_modifying_common_sales_channels_json({});
        set_modifying_all_common_sales_channels(false);
    }

    const check_new_sales_channels_added = () => {
        let a = [...new_sales_channels_added]
        if(!new_sales_channels_added_from_multiselect || !new_sales_channels_added_from_multiselect.length){
            set_new_sales_channels_added([])
            return
        }
        if(!a || !a.length){
            set_new_sales_channels_added(new_sales_channels_added_from_multiselect)
            return
        }

        if(new_sales_channels_added_from_multiselect && new_sales_channels_added_from_multiselect.length){
            for(let i=0;i<new_sales_channels_added_from_multiselect.length;i++){
                console.log("check_new_sales_channels_added", new_sales_channels_added, new_sales_channels_added_from_multiselect)
                if(!(a.find(o => o.id === new_sales_channels_added_from_multiselect[i].id))){
                    a.push(new_sales_channels_added_from_multiselect[i])
                }
            }
        }

        for(let i=0;i<a.length;i++){
            if(!new_sales_channels_added_from_multiselect.find(o => o.id === a[i].id)){
                a.splice(i, 1)
                break
            }
        }
        set_new_sales_channels_added(a)
    }

    const reset_modified_values_in_additional_props = () => {
        let a = [...common_additional_props_default]
        a = a && a.length ? a.map(x => ({key:x.key, value:''})) : []
        set_common_additional_props_default(a)

        a = [...common_additional_props_custom]
        a = a && a.length ? a.map(x => ({key:x.key, value:''})) : []
        set_common_additional_props_custom(a)
    }

    const reset_additional_props_tab = () => {
        set_new_additional_props_default([])
        set_new_additional_props_custom([])
        set_bulk_modify_checkboxes_default({})
        set_bulk_modify_checkboxes_custom({})
        reset_modified_values_in_additional_props()
        set_bulk_delete_checkboxes_default({})
        set_bulk_delete_checkboxes_custom({})
    }

    const checks_before_bulk_edit = () => {
        if(common_sales_channels && common_sales_channels.length){
            for(let i=0;i<common_sales_channels.length;i++){
                if(modifying_common_sales_channels_json[common_sales_channels[i]] && removing_common_sales_channels_json[common_sales_channels[i]]){
                    alert.error("You are trying to modify and delete the same sales channel !")
                    return
                }
            }
        }

        if(common_additional_props_default && common_additional_props_default.length){
            for(let i=0;i<common_additional_props_default.length;i++){
                if(bulk_modify_checkboxes_default[i] && bulk_delete_checkboxes_default[i]){
                    alert.error("You are trying to modify and delete the same additional property !")
                    return
                }
            }
        }

        if(common_additional_props_custom && common_additional_props_custom.length){
            for(let i=0;i<common_additional_props_custom.length;i++){
                if(bulk_modify_checkboxes_custom[i] && bulk_delete_checkboxes_custom[i]){
                    alert.error("You are trying to modify and delete the same additional property !")
                    return
                }
            }
        }

        if(new_additional_props_default && new_additional_props_default.length>1 && !is_valid_add_key(new_additional_props_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
            return
		}else if(new_additional_props_custom && new_additional_props_custom.length>1 && !is_valid_add_key(new_additional_props_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
            return
		}

        bulk_edit_function()
    }

    const bulk_edit_function = async() => {
        // try{
            let final_sku_array = []
            if(original_sku_array && original_sku_array.length){
                for(let i=0;i<original_sku_array.length;i++){
                    let current_sku = original_sku_array[i]
                    let edited_sku = {}

                    edited_sku['id'] = current_sku['id']
                    if(new_brand_id != 'existing_values') edited_sku['brand_id'] = new_brand_id

                    if(is_allow_material){
                        if(new_placement_id != 'existing_values') edited_sku['placement_id'] = new_placement_id
                        if(new_height != 'Mixed') edited_sku['height'] = new_height
                        if(new_width != 'Mixed') edited_sku['width'] = new_width
                        if(new_depth != 'Mixed') edited_sku['depth'] = new_depth
                    }else{
                        current_sku && current_sku.component_file && current_sku.component_file.map(o =>{
                            if(o.type == "cabinet" || o.type == "corner_unit"){
                                o.data.height = new_height != 'Mixed' ? Number(new_height) : o.data.height
                                o.data.width = new_width != 'Mixed' ? Number(new_width) : o.data.width
                                o.data.depth = new_depth != 'Mixed' ? Number(new_depth) : o.data.depth
                                o.data.attached_plane_type = new_placement_id != 'existing_values' ? new_placement_id : o.data.attached_plane_type
                            }
                        })
                        edited_sku['component_file'] = current_sku.component_file
                    }

                    //tags
                    let current_tags = tags_at_all_levels && tags_at_all_levels[current_sku.id] && tags_at_all_levels[current_sku.id].sku_tags ? tags_at_all_levels[current_sku.id].sku_tags : []
                    current_tags = current_tags && current_tags.length ? current_tags.filter(x => !removing_common_tags_json[x.id]) : []
                    current_tags = current_tags && current_tags.length ? current_tags.filter(x => !(new_tags_added.find(o => o.id === x.id))) : []
                    if(new_tags_added){
                        current_tags = JSON.parse(JSON.stringify([...current_tags, ...new_tags_added]))
                    }
                    current_tags = current_tags && current_tags.length ? current_tags.map(x => x.id) : []
                    edited_sku['tag_ids'] = current_tags


                    //sales_channels
                    let current_sales_channels = original_sales_channels[current_sku.id]
                    current_sales_channels = current_sales_channels && current_sales_channels.length ? current_sales_channels.filter(x => !(removing_common_sales_channels_json[x.id])) : []
                    current_sales_channels = current_sales_channels && current_sales_channels.length ? current_sales_channels.filter(x => !(new_sales_channels_added.find(o => o.id === x.id))) : []
                    current_sales_channels = JSON.parse(JSON.stringify([...current_sales_channels, ...new_sales_channels_added]))
                    if(current_sales_channels && current_sales_channels.length){
                        for(let j=0;j<current_sales_channels.length;j++){
                            let single_sales_channel = current_sales_channels[j]
                            let price_types = single_sales_channel.price_types
                            let modified_single_sales_channel = modified_sales_channel_values.find(o => o.id === single_sales_channel.id)
                            let modified_price_types = modified_single_sales_channel ? modified_single_sales_channel.price_types : []
                            for(let k=0;k<price_types.length;k++){
                                let single_price_type = price_types[k]
                                let modified_single_price_type = modified_price_types && modified_price_types.length ? modified_price_types.find(o => o.id === single_price_type.id) : {}
                                if(modifying_common_sales_channels_json[single_sales_channel.id]){
                                    if((modified_single_price_type['tax'] && modified_single_price_type['tax'] != 'Mixed') || (modified_single_price_type['margin'] && modified_single_price_type['margin'] != 'Mixed')){
                                        let current_prices = original_prices[current_sku.id].sales_channel_prices
                                        let current_sales_channel_prices = current_prices[single_sales_channel.id]
                                        let current_price_type_prices = current_sales_channel_prices[modified_single_price_type.id]
                                        single_price_type['price'] = current_price_type_prices['immediate_price'].price
                                        single_price_type['display_unit'] = current_price_type_prices['immediate_price'].display_unit
                                        if(modified_single_price_type['tax'] && modified_single_price_type['tax'] != 'Mixed'){
                                            single_price_type['tax'] = Number(modified_single_price_type['tax'])
                                        }else{
                                            single_price_type['tax'] = current_price_type_prices['immediate_price'].tax
                                        }
                                        if(modified_single_price_type['margin'] && modified_single_price_type['margin'] != 'Mixed'){
                                            single_price_type['margin'] = Number(modified_single_price_type['margin'])
                                        }else{
                                            single_price_type['margin'] = current_price_type_prices['immediate_price'].margin
                                        }
                                    }else{
                                        single_price_type['dont_update'] = 1
                                    }
                                }else{
                                    if(single_price_type['tax'] || single_price_type['margin']){
                                        // console.log("update_sales_channel_values  ooook", current_sku.name, single_sales_channel.name)
                                        let current_prices = original_prices[current_sku.id].sales_channel_prices
                                        // console.log( current_sku.name, single_sales_channel.name, single_sales_channel.id, 'current_prices', single_price_type.name, current_prices)
                                        let current_sales_channel_prices = current_prices && current_prices[single_sales_channel.id] ? current_prices[single_sales_channel.id] : {}
                                        // console.log( current_sku.name, single_sales_channel.name, single_sales_channel.id, 'current_prices current_sales_channel_prices', single_price_type.name, current_sales_channel_prices)
                                        let current_price_type_prices = current_sales_channel_prices && current_sales_channel_prices[single_price_type.id] ? current_sales_channel_prices[single_price_type.id] : {}
                                        // console.log( current_sku.name, single_sales_channel.name, single_sales_channel.id, 'current_prices current_price_type_prices', single_price_type.name, current_price_type_prices)
                                        single_price_type['price'] = current_price_type_prices['immediate_price'] ? current_price_type_prices['immediate_price'].price : 0
                                        single_price_type['display_unit'] = current_price_type_prices['immediate_price']? current_price_type_prices['immediate_price'].display_unit : 'none'
                                        if(!single_price_type['tax']){
                                            single_price_type['tax'] = current_price_type_prices['immediate_price'] ? current_price_type_prices['immediate_price'].tax : 0
                                        }else{
                                            single_price_type['tax'] = Number(single_price_type['tax'])
                                        }
                                        if(!single_price_type['margin']){
                                            single_price_type['margin'] = current_price_type_prices['immediate_price'] ? current_price_type_prices['immediate_price'].margin : 0
                                        }else{
                                            single_price_type['margin'] = Number(single_price_type['margin'])
                                        }
                                    }else{
                                        single_price_type['dont_update'] = 1
                                    }
                                }
                            }
                            // console.log('price_types dont update', price_types)
                            single_sales_channel.price_types = price_types && price_types.length ? price_types.filter(x => !x['dont_update']) : []
                            // console.log('price_types dont updateddddd', price_types)
                        }
                    }
                    edited_sku['sales_channels'] = current_sales_channels


                    //additional_properties
                    let current_additional_props = JSON.parse(JSON.stringify(current_sku.additional_properties))
                    let current_default_props = []
                    let current_custom_props = []
                    if(current_additional_props && current_additional_props.length){
                        for(let j=0;j<current_additional_props.length;j++){
                            if(all_allowed_key[current_additional_props[j].key]){
                                current_default_props.push(current_additional_props[j])
                            }else{
                                current_custom_props.push(current_additional_props[j])
                            }
                        }
                    }
                    current_default_props = current_default_props && current_default_props.length ? current_default_props.filter((x,idx) => !bulk_delete_checkboxes_default[common_additional_props_default_1.findIndex(o => o.key === x.key)]) : []
                    current_custom_props = current_custom_props && current_custom_props.length ? current_custom_props.filter((x,idx) => !bulk_delete_checkboxes_custom[common_additional_props_custom_1.findIndex(o => o.key === x.key)]) : []
                    
                    if(common_additional_props_default && common_additional_props_default.length){
                        for(let j=0;j<common_additional_props_default.length;j++){
                            if(bulk_modify_checkboxes_default[j]){
                                let temp_prop = current_default_props.find(o => o.key === common_additional_props_default[j].key)
                                temp_prop['value'] = common_additional_props_default[j].value
                            }
                        }
                    }
                    
                    if(common_additional_props_custom && common_additional_props_custom.length){
                        for(let j=0;j<common_additional_props_custom.length;j++){
                            if(bulk_modify_checkboxes_custom[j]){
                                let temp_prop = current_custom_props.find(o => o.key === common_additional_props_custom[j].key)
                                temp_prop['value'] = common_additional_props_custom[j].value
                            }
                        }
                    }

                    let x_addi_prop = [];
                    for(let j=0;j<new_additional_props_default.length;j++){
                        let x = new_additional_props_default[j]
                        if(new_additional_props_default[0].key){
                            if(common_additional_props.includes(x.key)){
                                alert.error("You have added an already existing additional property! Try Modifying it instead")
                                return
                            }else{
                                x_addi_prop.push(x)
                            }

                            let index = current_default_props.findIndex(o => o.key === x.key)    
                            if(index >= 0) current_default_props.splice(index, 1)
                        }
                    }

                    for(let j=0;j<new_additional_props_custom.length;j++){
                        let x = new_additional_props_custom[j]
                        if (new_additional_props_custom[0].key) {
                            if(common_additional_props.includes(x.key)){
                                alert.error("You have added an already existing additional property! Try Modifying it instead")
                                return
                            }else{
                                x_addi_prop.push(x)
                            }

                            let index = current_custom_props.findIndex(o => o.key === x.key)    
                            if(index >= 0) current_custom_props.splice(index, 1)
                        }
                    }
                    current_additional_props = [...current_default_props, ...current_custom_props, ...x_addi_prop]
                    // console.log("bulk_edit_function not returned", current_additional_props, current_default_props, current_custom_props, x_addi_prop)
                    edited_sku['additional_properties'] = current_additional_props
                    final_sku_array.push(edited_sku)
                }
                set_edited_sku_array(final_sku_array)
                set_editing_common_props(false)
                set_tabs(1)
                set_common_props_confirmation_mode(true)
            }
        // }catch(err){
        //     alert.error("Bulk Edit Failed !")
        //     console.log(err)
        // }
    }

    const publish_changes_and_exit = () => {
        if(new_sales_channels_added && new_sales_channels_added.length){
            set_edited_sku_array(edited_sku_array)
            set_show_new_sales_channel_warning(true)
        }else{
            create_attempt_and_trigger(edited_sku_array)
        }
    }

    useEffect(() => {
        if(original_sku_array && original_sku_array.length){
            let tags_json = {}
            let sales_channels_json = {}
            let additional_props_json = {}

            for(let i=0;i<original_sku_array.length;i++){
                let current_sku = original_sku_array[i]
                if(current_sku.store_id != user.store_id && !contains_unowned_skus) set_contains_unowned_skus(true)

                let current_tags = tags_at_all_levels && tags_at_all_levels[current_sku.id] && tags_at_all_levels[current_sku.id].sku_tags ? tags_at_all_levels[current_sku.id].sku_tags : []
                if(!current_tags || !current_tags.length) set_no_common_tags(true)
                if(!no_common_tags && current_tags && current_tags.length){
                    for(let j=0;j<current_tags.length;j++){
                        if(tags_json[current_tags[j].id]){
                            tags_json[current_tags[j].id]++
                        }else{
                            tags_json[current_tags[j].id] = 1
                        }
                    }
                }

                let current_sales_channels = original_sales_channels[current_sku.id]
                if(!current_sales_channels || !current_sales_channels.length) set_no_common_sales_channels(true)
                if(!no_common_sales_channels && current_sales_channels && current_sales_channels.length){
                    for(let j=0;j<current_sales_channels.length;j++){
                        if(sales_channels_json[current_sales_channels[j].id]){
                            sales_channels_json[current_sales_channels[j].id]++
                        }else{
                            sales_channels_json[current_sales_channels[j].id] = 1
                        }
                    }
                }

                let current_additional_props = current_sku.additional_properties
                if(!current_additional_props || !current_additional_props.length) set_no_common_additional_props(true)
                if(!no_common_additional_props && current_additional_props && current_additional_props.length){
                    for(let j=0;j<current_additional_props.length;j++){
                        if(additional_props_json[current_additional_props[j].key]){
                            additional_props_json[current_additional_props[j].key]++
                        }else{
                            additional_props_json[current_additional_props[j].key] = 1
                        }
                    }
                }
            }

            if(!no_common_tags){
                let all_tag_ids = Object.keys(tags_json)
                if(all_tag_ids && all_tag_ids.length){
                    let temp_common_tags = []
                    for(let i=0;i<all_tag_ids.length;i++){
                        if(tags_json[all_tag_ids[i]] == original_sku_array.length)
                            temp_common_tags.push(all_tag_ids[i])
                    }
                    set_common_tags(temp_common_tags)
                }
            }

            if(!no_common_sales_channels){
                let all_sales_channel_ids = Object.keys(sales_channels_json)
                if(all_sales_channel_ids && all_sales_channel_ids.length){
                    let temp_common_sales_channels = []
                    for(let i=0;i<all_sales_channel_ids.length;i++){
                        if(sales_channels_json[all_sales_channel_ids[i]] == original_sku_array.length)
                            temp_common_sales_channels.push(all_sales_channel_ids[i])
                    }
                    set_common_sales_channels(temp_common_sales_channels)
                }
            }

            if(!no_common_additional_props){
                let all_additional_property_keys = Object.keys(additional_props_json)
                if(all_additional_property_keys && all_additional_property_keys.length){
                    let temp_common_additional_props = []
                    for(let i=0;i<all_additional_property_keys.length;i++){
                        if(additional_props_json[all_additional_property_keys[i]] == original_sku_array.length)
                            temp_common_additional_props.push(all_additional_property_keys[i])
                    }
                    set_common_additional_props(temp_common_additional_props)
                }
            }
        }
    }, [original_sku_array, original_sales_channels]);

    useEffect(() => {
            set_mixed_values_sales_channels()
    }, [all_sales_channels, common_sales_channels]);

    useEffect(() => {
        check_new_sales_channels_added()
    }, [new_sales_channels_added_from_multiselect]);

    useEffect(() => {
        if(common_additional_props && common_additional_props.length){
            let temp_default_props = []
            let temp_custom_props = []
            for(let i=0;i<common_additional_props.length;i++){
                if(all_allowed_key[common_additional_props[i]]){
                    temp_default_props.push({key:common_additional_props[i], value:''})
                }else{
                    temp_custom_props.push({key:common_additional_props[i], value:''})
                }
            }
            set_common_additional_props_default(JSON.parse(JSON.stringify(temp_default_props)))
            set_common_additional_props_default_1(JSON.parse(JSON.stringify(temp_default_props)))
            set_common_additional_props_custom(JSON.parse(JSON.stringify(temp_custom_props)))
            set_common_additional_props_custom_1(JSON.parse(JSON.stringify(temp_custom_props)))
        }
    }, [common_additional_props]);

    useEffect(() => {
        if(common_props_confirmation_mode){
            if(new_height != 'Mixed' || new_width != 'Mixed' || new_depth != 'Mixed' || new_placement_id != 'existing_values')
                set_show_sku_props_in_confirmation(true)
            if(new_brand_id != 'existing_values')
                set_show_brands_in_confirmation(true)

            if(new_tags_added && new_tags_added.length){
                set_show_tags_in_confirmation(true)
            }else{
                if(common_tags && common_tags.length){
                    for(let i=0;i<common_tags.length;i++){
                    if(removing_common_tags_json[common_tags[i]]){
                            set_show_tags_in_confirmation(true)
                            break
                        }
                    }
                }
            }

            if(new_sales_channels_added && new_sales_channels_added.length){
                set_show_sales_channels_in_confirmation(true)
            }else{
                let sales_channels_removing = false
                if(common_sales_channels && common_sales_channels.length){
                    for(let i=0;i<common_sales_channels.length;i++){
                        if(removing_common_sales_channels_json[common_sales_channels[i]]){
                            sales_channels_removing = true
                            break
                        }
                    }
                }

                if(sales_channels_removing){
                    set_show_sales_channels_in_confirmation(true)
                }else{
                    if(common_sales_channels && common_sales_channels.length){
                        for(let i=0;i<common_sales_channels.length;i++){
                            if(modifying_common_sales_channels_json[common_sales_channels[i]]){
                                set_show_sales_channels_in_confirmation(true)
                                break
                            }
                        }
                    }
                }
            }

            if((new_additional_props_default && new_additional_props_default.length && is_valid_add_key_1(new_additional_props_default)) || (new_additional_props_custom && new_additional_props_custom.length && is_valid_add_key_1(new_additional_props_custom))){
                console.log('show_additional_props_in_confirmation1111', new_additional_props_default, new_additional_props_custom)
                set_show_additional_props_in_confirmation(true)
            }else{
                let default_length = common_additional_props_default ? common_additional_props_default.length : 0
                let custom_length = common_additional_props_custom? common_additional_props_custom.length : 0
                let show_additional_props = false
                for(let i=0;i<default_length;i++){
                    if(bulk_delete_checkboxes_default[i] || bulk_modify_checkboxes_default[i]){
                        set_show_additional_props_in_confirmation(true)
                        show_additional_props = true
                        break
                    }
                }
                if(!show_additional_props){
                    for(let i=0;i<custom_length;i++){
                        if(bulk_delete_checkboxes_custom[i] || bulk_modify_checkboxes_custom[i]){
                            set_show_additional_props_in_confirmation(true)
                            break
                        }
                    }
                }
            }
        }else{
            set_show_sku_props_in_confirmation(false)
            set_show_brands_in_confirmation(false)
            set_show_tags_in_confirmation(false)
            set_show_sales_channels_in_confirmation(false)
            set_show_additional_props_in_confirmation(false)
        }
    }, [common_props_confirmation_mode]);

    // useEffect(() => {
    //     console.log('new_sales_channels_added', new_sales_channels_added)
    // }, [new_sales_channels_added]);

    // useEffect(() => {
    //     console.log('show_additional_props_in_confirmation', show_additional_props_in_confirmation)
    // }, [show_additional_props_in_confirmation]);

    useEffect(() => {
        if(show_sku_props_in_confirmation){
            set_tabs(1)
        }else if(show_brands_in_confirmation){
            set_tabs(2)    
        }else if(show_tags_in_confirmation){
            set_tabs(3)
        }else if(show_sales_channels_in_confirmation){
            set_tabs(4)
        }else if(show_additional_props_in_confirmation){
            set_tabs(5)
        }
    }, [show_sku_props_in_confirmation, show_brands_in_confirmation, show_tags_in_confirmation, show_sales_channels_in_confirmation, show_additional_props_in_confirmation]);
    
    return(
        <>
            <NewSalesChannelWarning
                open = {show_new_sales_channel_warning}
                handleClose = {() => set_show_new_sales_channel_warning(false)}
                create_attempt_and_trigger = {create_attempt_and_trigger}
                edited_sku_array = {edited_sku_array}
            />
            <CardBody style={{backgroundColor:'#F5F6F8', height:'calc(100% - 84px)'}}>
                <div className='flex_property' style={{justifyContent:'space-between', backgroundColor:'white', height:'40px', fontSize:'15px'}}>
                    <span className='flex_property'>
                        <span className='flex_property' style={{backgroundColor:'rgba(103, 104, 120, 0.1)', height:'24px', fontWeight:'900', margin:'0px 4px 0px 8px', padding:'4px', borderRadius:'4px'}}>{common_props_confirmation_mode?'Confirm Edit Changes ':'Editing All SKUs at Once '}</span>
                        <span> {' from Total of'} <span style={{fontWeight:'900'}}>{original_sku_array ? original_sku_array.length : 0} SKUs</span> selected from, <span style={{fontWeight:'900'}}>{sub_cats_included.length} {sub_cats_included.length==1 ? 'SubCategory' : 'SubCategories'}</span>, in Category<span style={{fontWeight:'900'}}> {sku_category_name}</span></span>
                    </span>
                </div>   
                <div className="nav-wrapper" style={{padding:'2px 8px', marginTop:'20px', backgroundColor:'white', fontSize:'15px'}}>
                    <Nav 
                        style={{display:'flex', alignItems:'center'}}
                        className="flex-md-row"
                        id="tabs-icons-text" 
                        pills
                        role="tablist"
                    >   
                        {
                            (common_props_confirmation_mode && show_sku_props_in_confirmation) || editing_common_props?
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 1}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 1
                                    })}
                                    onClick={e => toggleNavs(e, 1)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>SKU Properties</span>
                                </NavLink>
                            </NavItem>:''
                        }
                        {
                            (common_props_confirmation_mode && show_brands_in_confirmation) || editing_common_props?
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 2}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 2
                                    })}
                                    onClick={e => toggleNavs(e, 2)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Brands</span>
                                </NavLink>
                            </NavItem>:''
                        }
                        {
                            (common_props_confirmation_mode && show_tags_in_confirmation) || editing_common_props?
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 3}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 3
                                    })}
                                    onClick={e => toggleNavs(e, 3)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Tags</span>
                                </NavLink>
                            </NavItem>:''
                        }
                        {
                            (common_props_confirmation_mode && show_sales_channels_in_confirmation) || editing_common_props?
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 4}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 4
                                    })}
                                    onClick={e => toggleNavs(e, 4)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Sales Channels</span>
                                </NavLink>
                            </NavItem>:''
                        }
                        {
                            (common_props_confirmation_mode && show_additional_props_in_confirmation) || editing_common_props?
                            <NavItem>
                                <NavLink
                                    aria-selected={tabs === 5}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: tabs === 5
                                    })}
                                    onClick={e => toggleNavs(e, 5)}
                                    href="#pablo"
                                    role="tab"
                                >
                                    <span>Additional Properties</span>
                                </NavLink>
                            </NavItem>:''
                        }
                    </Nav>
                </div>
                <TabContent activeTab={"tabs" + tabs} style={{height:'85%', fontSize:'15px'}}>
                    <TabPane tabId="tabs1" className='editing_all_skus_tabpane' style={{display:(common_props_confirmation_mode && !show_sku_props_in_confirmation) || (tabs != 1) ? 'none' : 'block'}}>
                    {
                        contains_unowned_skus?
                        <div className='flex_property' style={{justifyContent:'center', fontSize:'16px', height:'100%', alignItems:'center', color:'#A7A8B2', flexDirection:'column'}}>
                            <div>Some/all of the selected SKUs are not owned. SKU Properties cannot be edited</div>
                            <div>Try editing Tags or Sales Channels</div>
                        </div>
                        :
                        <>
                            <div className='flex_property bulk_edit_of_property'>
                                <span style={{fontWeight:'900'}}>{common_props_confirmation_mode?'Preview Edits':'Bulk Edit'} of SKU Properties</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_dimensions();set_new_placement_id('existing_values')}}>Reset Changes</span>}
                            </div>
                            <div className='flex_property grey_background_property_heading' >
                                <span style={{fontWeight:'900'}}>SKU Dimensions</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_dimensions()}}>Reset Changes</span>}
                            </div>
                            <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                            {
                                is_allow_material?
                                <>
                                    <div>Thickness</div>
                                    <Input style={{width:'50%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF'}} value={new_height} placeholder='Mixed' onChange = {(e) => Number(e.target.value)>=0 ? set_new_height(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                    {
                                        is_allow_material_and_depth?
                                        <>
                                            <div style={{marginTop:'16px'}}>{sku_category_type==='edge_band_hardware'?'Tape Width':'Height'}</div>
                                            <Input style={{width:'50%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF'}} value={new_depth} placeholder='Mixed' onChange = {(e) => Number(e.target.value)>=0 ? set_new_depth(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                        </>:''
                                    }
                                    {
                                        sku_category_type == 'tile_finish' ?
                                        <>
                                            <div style={{marginTop:'16px'}}>Width</div>
                                            <Input style={{width:'50%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF'}} value={new_width} placeholder='Mixed' onChange = {(e) => Number(e.target.value)>=0 ? set_new_width(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                            <div style={{marginTop:'16px'}}>Height</div>
                                            <Input style={{width:'50%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF'}} value={new_depth} placeholder='Mixed' onChange = {(e) => Number(e.target.value)>=0 ? set_new_depth(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                        </>:''
                                    }
                                </>:
                                <>
                                    <Row>
                                        <Col>Height(mm)</Col>
                                        <Col>Width(mm)</Col>
                                        <Col>Depth(mm)</Col>
                                    </Row>
                                    <Row>
                                        <Input style={{flex:'1', width:'25%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF', margin:'0px 16px'}} placeholder='Mixed' value={new_height} onChange = {(e) => Number(e.target.value)>=0 ? set_new_height(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                        
                                        <Input style={{flex:'1', width:'25%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF', margin:'0px 16px'}} placeholder='Mixed' value={new_width} onChange = {(e) => Number(e.target.value)>=0 ? set_new_width(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                        
                                        <Input style={{flex:'1', width:'25%', height:'28px', borderRadius:'2px', fontSize:'14px', border:'1px solid #C5C7CF', margin:'0px 16px'}} placeholder='Mixed' value={new_depth} onChange = {(e) => Number(e.target.value)>=0 ? set_new_depth(e.target.value) : alert.error("Negative numbers are not allowed!")} type='number' />
                                    </Row>
                                </>
                            }
                            </div>
                            {
                                active_division_id != 'finish'?
                                <>
                                    <div className='flex_property grey_background_property_heading' >
                                        <span style={{fontWeight:'900'}}>Placement</span>
                                        {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_new_placement_id('existing_values') }>Reset Changes</span>}
                                    </div>
                                    <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                                        <Input type='select' value={new_placement_id} onChange = {(e) => set_new_placement_id(e.target.value)} style={{width:'50%', fontSize:'14px', height:'32px', border:'1px solid #C5C7CF'}}>
                                            <option value='none'>None</option>
                                            <option value='existing_values'>Existing Placement Values (Mixed)</option>
                                            <option value='base'>Base</option>
                                            <option value='base_corner'>Base Corner</option>
                                            <option value='wall'>Wall</option>
                                            <option value='wall_corner'>Corner Wall</option>
                                            <option value='ceiling'>Ceiling</option>
                                            <option value='default'>Default</option>
                                        </Input>
                                    </div>
                                </>:''
                            }
                        </>
                    }
                    </TabPane>
                    <TabPane tabId="tabs2" className='editing_all_skus_tabpane' style={{display:(common_props_confirmation_mode && !show_brands_in_confirmation) || tabs!=2 ? 'none' : 'block'}}>
                    {
                        contains_unowned_skus?
                        <div className='flex_property' style={{justifyContent:'center', fontSize:'16px', height:'100%', alignItems:'center', color:'#A7A8B2', flexDirection:'column'}}>
                            <div>Some/all of the selected SKUs are not owned. Brands cannot be edited</div>
                            <div>Try editing Tags or Sales Channels</div>
                        </div>
                        :
                        <>
                            <div className='flex_property bulk_edit_of_property'>
                                <span style={{fontWeight:'900'}}>Bulk Edit of Brands</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_new_brand_id('existing_values')}>Reset Changes</span>}
                            </div>
                            <div className='flex_property grey_background_property_heading' >
                                <span style={{fontWeight:'900'}}>Modify Brands</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_new_brand_id('existing_values')}>Reset Changes</span>}
                            </div>
                            <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                                <Input type='select' value={new_brand_id} onChange = {(e) => set_new_brand_id(e.target.value)} style={{width:'50%', fontSize:'14px', height:'32px', border:'1px solid #C5C7CF'}}>
                                    <option value='none'>None</option>
                                    <option value='existing_values'>Existing Brand Values (Mixed)</option>
                                    {
                                        all_brands && all_brands.length ? all_brands.map((list_value, idx)=>
                                            <option key={idx} value={list_value.id}>{list_value.name}</option>
                                        ):''
                                    }
                                </Input>
                            </div>
                        </>
                    }
                    </TabPane>
                    <TabPane tabId="tabs3" className='editing_all_skus_tabpane' style={{display:(common_props_confirmation_mode && !show_tags_in_confirmation)|| tabs!=3 ? 'none' : 'block'}}>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>Bulk Edit of Tags</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_new_tags_added([]);set_removing_common_tags_json({})}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Add Tags</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_new_tags_added([])}>Reset Changes</span>}
                        </div>
                        <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                            <div style={{marginBottom:'4px'}}>Select the tags to be added to all selected SKUs</div>
                            <div>
                                <Multiselect
                                    options={eligible_tags && eligible_tags.length && eligible_tags.filter(x => !(common_tags.includes(x.id))) ? eligible_tags.filter(x => !(common_tags.includes(x.id))) : []} 
                                    selectedValues={new_tags_added} 
                                    onSelect={(selectedList) => set_new_tags_added(selectedList)} 
                                    onRemove={(selectedList) => set_new_tags_added(selectedList)} 
                                    displayValue="tag" // Property name 
                                    placeholder='Select Tag'
                                />
                            </div>
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span><span style={{fontWeight:'900'}}>Remove Tags </span>(Showing only those tags which are common to all selection)</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_removing_common_tags_json({})}>Reset Changes</span>}
                        </div>
                        <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                        {
                            common_tags && common_tags.length ? common_tags.map((single_tag, idx) => (
                                <div className='flex_property' style={{marginBottom:'4px'}}>
                                    <span style={{marginRight:'12px'}}>{idx+1}. </span>
                                    <input style={{marginRight:'8px'}} type='checkbox' checked={removing_common_tags_json[single_tag]} onClick={() => {update_removing_common_tags_json(single_tag, !(removing_common_tags_json[single_tag]))}}/>
                                    <span>{orignal_tags && orignal_tags.length && orignal_tags.find(o => o.id == single_tag) ? orignal_tags.find(o => o.id == single_tag).tag : ''}</span>
                                </div>
                            )):
                            <span>No common tags to remove</span>
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs4" className='editing_all_skus_tabpane' style={{display:(common_props_confirmation_mode && !show_sales_channels_in_confirmation) || (tabs != 4) ? 'none' : 'block'}}>
                        <div className='flex_property bulk_edit_of_property'>
                            <span style={{fontWeight:'900'}}>Bulk Edit of Sales Channels</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_new_sales_channels_added_from_multiselect([]);set_removing_common_sales_channels_json({});reset_modified_sales_channel_values()}}>Reset Changes</span>}
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Map sales channels to all selected SKUs</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_new_sales_channels_added_from_multiselect([])}>Reset Changes</span>}
                        </div>
                        <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                            <div style={{marginBottom:'4px'}}>Select the sales channels to be added</div>
                            <div>
                                <Multiselect
                                    options = {all_sales_channels && all_sales_channels.length && all_sales_channels.filter(x => !(common_sales_channels.includes(x.id))) ? all_sales_channels.filter(x => !(common_sales_channels.includes(x.id))): []}
                                    selectedValues = {new_sales_channels_added_from_multiselect}
                                    onSelect = {(selectedList) => set_new_sales_channels_added_from_multiselect(selectedList)}
                                    onRemove = {(selectedList) => set_new_sales_channels_added_from_multiselect(selectedList)}
                                    displayValue = 'name'
                                    placeholder='Select Sales Channel'
                                />
                            </div>
                            {
                                new_sales_channels_added && new_sales_channels_added.length?
                                <table className="text-left flex_property table_style">
                                    <thead style={{color:'#C5C7CF', width:'100%', marginTop:'8px'}}>
                                        <tr className='tr_style'>
                                            <th className='flex_property th_style' style={{flex:'1', fontSize:'14px'}}></th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>
                                                <span>Sales Channels</span>
                                            </th>
                                            <th className='flex_property th_style' style={{flex:'6', fontSize:'14px'}}>Price Field</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Price</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Unit</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Tax %</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Margin %</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{width:'100%'}}>
                                    {
                                        new_sales_channels_added && new_sales_channels_added.length ? new_sales_channels_added.map((single_sales_channel, idx) => (
                                            single_sales_channel && single_sales_channel.price_types && single_sales_channel.price_types.length ? single_sales_channel.price_types.map((single_price_type, id) => (
                                                <tr className='tr_style' style={{width:'100%'}}>
                                                    <td className='flex_property td_style' style={{flex:'1'}}>{id==0?idx+1 + '.':''}</td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <span>{id == 0 ? single_sales_channel.name : ''}</span>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'6'}}>
                                                        <span>{single_price_type.name}</span>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <div className='flex_property' style={{backgroundColor:'#F5F6F8', width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', justifyContent:'space-between', padding:'0px 12px', overflow:'auto'}}>
                                                            <span>SKU default value</span>
                                                            <i className='fa fa-lock' style={{color:'#A7A8B3'}}/>
                                                        </div>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <div className='flex_property' style={{backgroundColor:'#F5F6F8', width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', justifyContent:'space-between', padding:'0px 12px', overflow:'auto'}}>
                                                            <span>SKU default value</span>
                                                            <i className='fa fa-lock' style={{color:'#A7A8B3'}}/>
                                                        </div>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <Input type='number' placeholder='SKU Default Value' value={single_price_type['tax']?single_price_type['tax']:''} onChange={(e) => Number(e.target.value)>=0 ? update_sales_channel_values(new_sales_channels_added, set_new_sales_channels_added, single_sales_channel.id, single_price_type.id, 'tax', e.target.value) : alert.error("Negative numbers are not allowed!")} style={{width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', fontSize:'14px'}}/>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <Input type='number' placeholder='SKU Default Value' value={single_price_type['margin']?single_price_type['margin']:''} onChange={(e) => Number(e.target.value)>=0 ? update_sales_channel_values(new_sales_channels_added, set_new_sales_channels_added, single_sales_channel.id, single_price_type.id, 'margin', e.target.value) : alert.error("Negative numbers are not allowed!")} style={{width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', fontSize:'14px'}} />
                                                    </td>
                                                </tr>
                                            )):''
                                        )):''
                                    }
                                    </tbody>
                                </table>:''
                            }
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span><span style={{fontWeight:'900'}}>Unmap sales channels to all selected SKUs </span>(Showing only those sales channels which are common to all selection)</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => set_removing_common_sales_channels_json({})}>Reset Changes</span>}
                        </div>
                        <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                        {
                            common_sales_channels && common_sales_channels.length ? common_sales_channels.map((single_sales_channel, idx) => (
                                <div className='flex_property' style={{marginBottom:'4px'}}>
                                    <span style={{marginRight:'12px'}}>{idx+1}. </span>
                                    <input style={{marginRight:'8px'}} type='checkbox' checked={removing_common_sales_channels_json[single_sales_channel]} onClick={() => {update_removing_common_sales_channels_json(single_sales_channel, !(removing_common_sales_channels_json[single_sales_channel]))}}/>
                                    <span>{all_sales_channels && all_sales_channels.length && all_sales_channels.find(o => o.id == single_sales_channel) ? all_sales_channels.find(o => o.id == single_sales_channel).name : ''}</span>
                                </div>
                            )):
                            <span>Selected SKUs are not mapped to any common sales channel</span>
                        }
                        </div>
                        <div className='flex_property grey_background_property_heading' >
                            <span style={{fontWeight:'900'}}>Modify all mapped sales channel values</span>
                            {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_modified_sales_channel_values()}}>Reset Changes</span>}
                        </div>
                        <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                        {
                            common_sales_channels && common_sales_channels.length?
                            <>
                                <div style={{marginBottom:'4px'}}>Select the sales channels to be modified. Showing only those sales channels which are common to all selection.</div>
                                <table className="text-left flex_property table_style">
                                    <thead style={{color:'#C5C7CF', width:'100%'}}>
                                        <tr className='tr_style'>
                                            <th className='flex_property th_style' style={{flex:'1', fontSize:'14px'}}></th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>
                                                <input style={{marginRight:'8px'}} type='checkbox' checked={modifying_all_common_sales_channels} onClick={() => click_main_checkbox_sales_channel(!modifying_all_common_sales_channels)} />
                                                <span>Sales Channels</span>
                                            </th>
                                            <th className='flex_property th_style' style={{flex:'6', fontSize:'14px'}}>Price Field</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Price</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Unit</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Tax %</th>
                                            <th className='flex_property th_style' style={{flex:'8', fontSize:'14px'}}>Margin %</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{width:'100%'}}>
                                    {
                                        modified_sales_channel_values && modified_sales_channel_values.length ? modified_sales_channel_values.map((single_sales_channel, idx) => {
                                            return(single_sales_channel && single_sales_channel.price_types && single_sales_channel.price_types.length ? single_sales_channel.price_types.map((single_price_type, id) => (
                                                <tr className='tr_style' style={{width:'100%'}}>
                                                    <td className='flex_property td_style' style={{flex:'1'}}>{id==0?idx+1 + '.':''}</td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                    {
                                                        id==0?
                                                        <>
                                                            <input style={{marginRight:'8px'}} type='checkbox' checked={modifying_common_sales_channels_json[single_sales_channel.id]} onClick={() => {let value = !(modifying_common_sales_channels_json[single_sales_channel.id]);update_modifying_common_sales_channels_json(single_sales_channel.id, value);verify_main_checkbox_sales_channel(value, single_sales_channel.id)}}/>
                                                            <span>{single_sales_channel.name }</span>
                                                        </>:''
                                                    }
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'6'}}>
                                                        <span>{single_price_type.name}</span>
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <div className='flex_property' style={{backgroundColor:'#F5F6F8', width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', justifyContent:'space-between', padding:'0px 12px', overflow:'auto'}}>
                                                            <span>Mixed</span>
                                                            <i className='fa fa-lock' style={{color:'#A7A8B3'}}/>
                                                        </div>
                                                        
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <div className='flex_property' style={{backgroundColor:'#F5F6F8', width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', justifyContent:'space-between', padding:'0px 12px', overflow:'auto'}}>
                                                            <span>Mixed</span>
                                                            <i className='fa fa-lock' style={{color:'#A7A8B3'}}/>
                                                        </div>
                                                        
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <Input disabled={modifying_common_sales_channels_json[single_sales_channel.id]?false:true} type='number' placeholder='Mixed' value={single_price_type['tax']} onChange={(e) => Number(e.target.value)>=0 ? update_sales_channel_values(modified_sales_channel_values, set_modified_sales_channel_values, single_sales_channel.id, single_price_type.id, 'tax', e.target.value) : alert.error("Negative numbers are not allowed!")} style={{width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', fontSize:'14px'}} />
                                                    </td>
                                                    <td className='flex_property td_style' style={{flex:'8'}}>
                                                        <Input disabled={modifying_common_sales_channels_json[single_sales_channel.id]?false:true} type='number' placeholder='Mixed' value={single_price_type['margin']} onChange={(e) => Number(e.target.value)>=0 ? update_sales_channel_values(modified_sales_channel_values, set_modified_sales_channel_values, single_sales_channel.id, single_price_type.id, 'margin', e.target.value) : alert.error("Negative numbers are not allowed!")} style={{width:'75%', height:'28px', borderRadius:'2px', border:'1px solid #C5C7CF', fontSize:'14px'}} />
                                                    </td>
                                                </tr>
                                            )):'')
                                        }):''
                                    }
                                    </tbody>
                                </table>
                            </>:
                            <span>Selected SKUs are not mapped to any common sales channel</span>
                            
                        }
                        </div>
                    </TabPane>
                    <TabPane tabId="tabs5" className='editing_all_skus_tabpane' style={{display:(common_props_confirmation_mode && !show_additional_props_in_confirmation) || tabs!=5 ? 'none' : 'block'}}>
                    {
                        contains_unowned_skus?
                        <div className='flex_property' style={{justifyContent:'center', fontSize:'16px', height:'100%', alignItems:'center', color:'#A7A8B2', flexDirection:'column'}}>
                            <div>Some/all of the selected SKUs are not owned. Additional Properties cannot be edited</div>
                            <div>Try editing Tags or Sales Channels</div>
                        </div>
                        :
                        <>
                            <div className='flex_property bulk_edit_of_property'>
                                <span style={{fontWeight:'900'}}>Bulk Edit of Additional properties</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {reset_additional_props_tab()}}>Reset Changes</span>}
                            </div>
                            <div className='flex_property grey_background_property_heading' >
                                <span style={{fontWeight:'900'}}>Add Additional properties</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_new_additional_props_default([]);set_new_additional_props_custom([])}}>Reset Changes</span>}
                            </div>
                            <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                                <KeyValuePair item_json={new_additional_props_default} set_item_json={set_new_additional_props_default} mode='default' all_allowed_key={all_allowed_key}/>
                                <KeyValuePair item_json={new_additional_props_custom} set_item_json={set_new_additional_props_custom} mode='custom' all_allowed_key={all_allowed_key}/>
                            </div>
                            <div className='flex_property grey_background_property_heading' >
                                <span><span style={{fontWeight:'900'}}>Modify Additional Properties </span>(Select a property to edit it's value)</span>
                                <span className='reset_changes' onClick={() => {set_bulk_modify_checkboxes_default({});set_bulk_modify_checkboxes_custom({});reset_modified_values_in_additional_props()}}>Reset Changes</span>
                            </div>
                            <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                            {
                                common_additional_props && common_additional_props.length?
                                <>
                                    {
                                        common_additional_props_default && common_additional_props_default.length?
                                        <KeyValuePair item_json={common_additional_props_default} set_item_json={set_common_additional_props_default} mode='default' all_allowed_key={all_allowed_key} bulk_edit_modify = {true} bulk_operation_checkboxes={bulk_modify_checkboxes_default} update_bulk_operation_checkboxes={update_bulk_modify_checkboxes_default}/>
                                        :''
                                    }
                                    {
                                        common_additional_props_custom && common_additional_props_custom.length?
                                        <KeyValuePair item_json={common_additional_props_custom} set_item_json={set_common_additional_props_custom} mode='custom' all_allowed_key={all_allowed_key} bulk_edit_modify = {true} bulk_operation_checkboxes={bulk_modify_checkboxes_custom} update_bulk_operation_checkboxes={update_bulk_modify_checkboxes_custom}/>
                                        :''
                                    }
                                </>:
                                <span>No common additional properties to modify</span>
                            }
                            </div>
                            <div className='flex_property grey_background_property_heading' >
                                <span><span style={{fontWeight:'900'}}>Delete Additional Properties </span>(Select a property to delete it)</span>
                                {common_props_confirmation_mode?'':<span className='reset_changes' onClick={() => {set_bulk_delete_checkboxes_default({});set_bulk_delete_checkboxes_custom({})}}>Reset Changes</span>}
                            </div>
                            <div className='common_property_editing' style={{pointerEvents:common_props_confirmation_mode?'none':'auto'}}>
                            {
                                common_additional_props && common_additional_props.length?
                                <>
                                    {
                                        common_additional_props_default_1 && common_additional_props_default_1.length?
                                        <KeyValuePair item_json={common_additional_props_default_1} set_item_json={set_common_additional_props_default_1} mode='default' all_allowed_key={all_allowed_key} bulk_edit_delete = {true} bulk_operation_checkboxes={bulk_delete_checkboxes_default} update_bulk_operation_checkboxes={update_bulk_delete_checkboxes_default}/>
                                        :''
                                    }
                                    {
                                        common_additional_props_custom_1 && common_additional_props_custom_1.length?
                                        <KeyValuePair item_json={common_additional_props_custom_1} set_item_json={set_common_additional_props_custom_1} mode='custom' all_allowed_key={all_allowed_key} bulk_edit_delete = {true} bulk_operation_checkboxes={bulk_delete_checkboxes_custom} update_bulk_operation_checkboxes={update_bulk_delete_checkboxes_custom}/>
                                        :''
                                    }
                                </>:
                                <span>No common additional properties to delete</span>
                            }
                            </div>
                        </>
                    }
                    </TabPane>
                    {
                        common_props_confirmation_mode && !show_sku_props_in_confirmation && !show_brands_in_confirmation && !show_tags_in_confirmation && !show_sales_channels_in_confirmation && !show_additional_props_in_confirmation?
                        <div className='flex_property' style={{justifyContent:'center', flexDirection:'column', marginTop:'20%', fontSize:'16px', color:'#A7A8B2'}}>
                            Edit any property to publish changes
                        </div>:''
                    }
                </TabContent>
            </CardBody>
            <CardFooter className='global_modal_footer'>
                <Button className='white_button' onClick={() => {
                                                                    if(editing_common_props){
                                                                        set_choosing_editing_mode(true)
                                                                        set_editing_common_props(false)
                                                                    }else if(common_props_confirmation_mode){
                                                                        set_common_props_confirmation_mode(false)
                                                                        set_editing_common_props(true)
                                                                        set_tabs(1)
                                                                    }
                                                                }}>Go back</Button>
                {
                    common_props_confirmation_mode?
                    <Button disabled={!show_sku_props_in_confirmation && !show_brands_in_confirmation && !show_tags_in_confirmation && !show_sales_channels_in_confirmation && !show_additional_props_in_confirmation} className='blue_button' onClick={() => {publish_changes_and_exit()}}>Publish Changes and exit</Button>
                    :
                    <Button className='blue_button' onClick={() => {checks_before_bulk_edit()}}>Proceed to confirmation</Button>
                }
            </CardFooter>
        </>
    )
}

export default EditCommonProperties