import Main_Grid from '../../components/Main_Grid';
import React , { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Pegasus_Paginator from '../../components/Pegasus_Paginator/';
import { useAlert } from 'react-alert'
import name_sort from '../../utils/name_sort';
import LazyLoad from 'react-lazyload';
import split_cat_type from '../../utils/split_cat_type';
import StatusDialogBox from './StatusDialogBox';

const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	add_brand_style:{
		backgroundColor: '#007EF4',
		boxShadow: 'inset 0 1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20)',
		borderRadius: '3px',
		height:'32px',
		padding:'0px 24px',
		border:'0px',
		color:'white',
	},
};

const Filler = ({ initialized }) => {
	const [fetch_progress, set_fetch_progress] = useState(60);
	return (
	<Table className="align-items-center table-flush text-center" responsive>
		<thead className="thead-light text-center">
			<tr>
				{ initialized ? (
					<th scope="col">
						<span style={styles.table_header}><b>No Inventory to list  </b></span>&nbsp;
					</th>
				):(
					<th scope="col">
						<Row>
							<Col xs={12} className="text-center">
								<img src="/load2.gif" />
							</Col>
							<Col xs={12} className="text-center">
								<span style={styles.table_header}><b>Fetching Project Statuses </b></span><br/> <br /> Please wait...<br /> <br />
							</Col>
						</Row>
						<Progress max="100" value={fetch_progress} color="info" />&nbsp;
					</th>
				)}
			</tr>
		</thead>
	</Table>	
	)
};

const ProjectStatuses = ({user, store_details, get_store_details, set_active_tab, submit_for_delete_confirm, set_active_division_id_sidebar, send_analytics_event}) => {

	const [display_statuses , set_display_statuses] = useState([]);
	const [search_string , set_search_string] = useState(null);
	const [initialized , set_initialized] = useState(false);
    const [active_index, set_active_index] = useState(null);
	const [open_status_Dialog_Box, set_open_status_Dialog_Box] = useState(false);
	const [key , set_key] = useState(null);
    const [project_statuses , set_project_statuses] = useState([]);
    const alert = useAlert();

    const fetch_projectStatuses_details = async () => {
        try {
            var resp = await general_fetch({ url: 'project_status/get'});
            set_project_statuses(resp);
        } catch(err) {
            console.log(err);
        }
    }

    const filter_projectStatuses = () => {
        console.log(project_statuses)
        console.log("project statuses")
		var filtered_statuses =[...project_statuses]
        console.log(filtered_statuses)
		var lower_search_string = search_string ? search_string.toLowerCase():'';
		if(lower_search_string){
			filtered_statuses = filtered_statuses.filter( project_statuses => project_statuses.status.toLowerCase().includes(lower_search_string))
		}
		set_display_statuses(filtered_statuses)
	}

    useEffect(() => {
		set_display_statuses(project_statuses);
	}, [project_statuses]);

	useEffect(() => {
		if (!initialized && display_statuses && display_statuses.length) {
			set_initialized(true);
		}
	}, [display_statuses]);

	useEffect(() => {
			filter_projectStatuses();
	}, [search_string]);

    useEffect(() => {
        fetch_projectStatuses_details();
		set_active_tab('preferences_settings');
		set_active_division_id_sidebar('ProjectStatuses')
		send_analytics_event('auto', 'Project Statuses', 'Project Statuses', 1, window.location.href)
		// if(all_category_type_data && !all_category_type_data.length){
		// 	fetch_categories_type()
		// }
	}, []);

    const close_status_Dialog_Box = () => {
		set_open_status_Dialog_Box(false);
		set_key(null);
	}

	const show_status_add_dialog = () => {
		set_open_status_Dialog_Box(true);
		set_key('add');
	}

    const show_status_update_dialog = (idx) => {
        set_active_index(idx);
		set_open_status_Dialog_Box(true);
		set_key('edit');
	}

	const show_status_delete_dialog = (idx) => {
        set_active_index(idx);
		set_open_status_Dialog_Box(true);
		set_key('delete');
	}

    const ProjectStatuses_Table = () => (
		<table style={{height:'100%'}} className="text-left flex_property table_style">
            <thead style={{width:"100%"}}>
			<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6',height:'40px'}}>
				<th className='th_style flex_property' style={{flex:'6'}}><span>Project Status</span></th>
				{/* <th className='th_style flex_property' style={{flex:'7'}}><span>Global</span></th> */}
				<th className='th_style ' style={{border:0, flex:'4'}}><span></span></th>
			</tr>
			</thead>
            
			<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
            {
                display_statuses && display_statuses.length && display_statuses.map((single_status, idx) => (
                    <tr className='tr_style display_onhover'>
                        <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_status.status}</span></td>   
                        {/* <td className='td_style flex_property' style={{flex:'6'}}><span className='table_text_overflow'>{single_status.global}</span></td>  */}
                        <td className='td_style flex_property x1' style={{ justifyContent:'flex-end',overflow:'hidden', flex:'4'}}>
                        {
                            (!single_status.global) ? (
								<span className='td_style flex_property x1' style={{ justifyContent:'flex-end'}}>
									<span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={() => show_status_update_dialog(idx)} className='fa fa-edit' aria-hidden="true"/></span>
									<span className='hide' style={{marginRight:'20px', cursor:'pointer'}}><i onClick ={()=> {show_status_delete_dialog(idx)}} className='fa fa-trash'/></span>
								</span>
							):''
                        }
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    );
    
    return(
		<Main_Grid active_tab="Project Statuses" user={user} store_details={store_details} get_store_details={get_store_details}>
            <StatusDialogBox open = {open_status_Dialog_Box} fetch_projectStatuses_details = {fetch_projectStatuses_details} handleClose = {close_status_Dialog_Box} mode = {key} project_statuses = {project_statuses} idx = {active_index} submit_for_delete_confirm = {submit_for_delete_confirm}/>
                <Row style={{padding:'10px 10px', color:'#22272e'}}>
                    <Col xs={6} className='flex_property' style={{fontFamily:'Source_Sans_Pro-SemiBold', paddingLeft:'20px', fontSize:'14px'}}>
                        Viewing {display_statuses && display_statuses.length ?display_statuses.length:'0'} Project Statuses
                    </Col>
                    <Col xs={6} style={{textAlign:'right'}}>
                        <button onClick = {() => show_status_add_dialog()} style={styles.add_brand_style}><span style={{fontFamily:'Source_Sans_Pro-SemiBold', fontSize:'12px', display:'flex', alignItems:'center',}}>Add Status</span></button>
                    </Col>
                </Row>
                <Row style={{height:'calc(100% - 90px)'}}>
                    <Col style={{height:'100%'}}>
                        <Card style={{borderRadius: 0, height:'100%'}}>
                            <div className='flex_property' style={{height:"40px", backgroundColor:'white', width:'100%', padding:'0px 20px'}}><i style={{paddingRight:'11px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/><input onChange={(e) => set_search_string(e.target.value)} style={{fontSize:'14px', width:"100%" ,color:'#9aa5b5', backgroundColor:'white', border:0, paddingLeft:0}} type='text' placeholder='Search by Project Status Name' /></div>
                            <div id='design-paginator' style={{height:'calc(100% - 40px)'}}>{display_statuses.length ? (<ProjectStatuses_Table/>) : (<Filler initialized={initialized}/>)}</div>
                        </Card>
                    </Col>
                </Row>
        </Main_Grid>
    )
}
export default ProjectStatuses;